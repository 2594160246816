import create from "zustand";

export interface EffectsStore {
    allEffectsEnabled: boolean;
    setAllEffectsEnabled(allEffectsEnabled: boolean): void;

    depthOfFieldEnabled: boolean;
    setDepthOfFieldEnabled(depthOfFieldEnabled: boolean): void;

    focusDistance: number;
    setFocusDistance(focusDistance: number): void;

    focalLength: number;
    setFocalLength(focalLength: number): void;

    bokehScale: number;
    setBokehScale(bokehScale: number):void
}

export const createEffectsStore = () =>
    create<EffectsStore>((set) => ({
        allEffectsEnabled: true,
        setAllEffectsEnabled: (allEffectsEnabled: boolean) =>
            set({ allEffectsEnabled }),
            
        depthOfFieldEnabled: false,
        setDepthOfFieldEnabled: (depthOfFieldEnabled: boolean) =>
            set({ depthOfFieldEnabled }),

        focusDistance: 0.0,
        setFocusDistance: (focusDistance: number) =>
            set({focusDistance}),

        focalLength: 0.16,
        setFocalLength: (focalLength: number) =>
            set({focalLength}),

        bokehScale: 2,
        setBokehScale: (bokehScale: number) =>
            set({bokehScale})

    }));
