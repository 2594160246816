import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
// import FormHelperText from "@material-ui/core/FormHelperText";
// import {TabPanel} from "./Knobs";
// import FormControl from "@material-ui/core/FormControl";
import Slider from "@material-ui/core/Slider";
import { ColorPicker } from "../ColorPicker";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  offsetIntervalSliderRoot: {
    width: 200,
  },
  sliderRoot: {
    width: 200,
  },
}));

interface ISwingFlairKnobs {
  useSwingFlairStore: any;
}

export const SwingFlairKnobs = ({ useSwingFlairStore }: ISwingFlairKnobs) => {
  let classes = useStyles();
  let swingFlairEnabled = useSwingFlairStore(($: any) => $.swingFlairEnabled);
  let setSwingFlairEnabled = useSwingFlairStore(
    ($: any) => $.setSwingFlairEnabled
  );
  let trailCount = useSwingFlairStore(($: any) => $.trailCount);
  let setTrailCount = useSwingFlairStore(($: any) => $.setTrailCount);
  let lineWidth = useSwingFlairStore(($: any) => $.lineWidth);
  let setLineWidth = useSwingFlairStore(($: any) => $.setLineWidth);
  let trailDuration = useSwingFlairStore(($: any) => $.trailDuration);
  let setTrailDuration = useSwingFlairStore(($: any) => $.setTrailDuration);
  let trailDurationVariation = useSwingFlairStore(
    ($: any) => $.trailDurationVariation
  );
  let setTrailDurationVariation = useSwingFlairStore(
    ($: any) => $.setTrailDurationVariation
  );
  let lineColor = useSwingFlairStore(($: any) => $.lineColor);
  let setLineColor = useSwingFlairStore(($: any) => $.setLineColor);

  let handleTrailCountChange = (event: any, value: any) => {
    setTrailCount(+value);
  };

  let handleLineWidthChange = (event: any, value: any) => {
    setLineWidth(+value);
  };

  let handleTrailDurationChange = (event: any, value: any) => {
    setTrailDuration(+value);
  };

  let handleTrailDurationVariationChange = (event: any, value: any) => {
    setTrailDurationVariation(+value);
  };

  let handleLineColorChange = (color: string) => {
    setLineColor(color);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={swingFlairEnabled}
            onChange={() => setSwingFlairEnabled(!swingFlairEnabled)}
            name="swing-flair-enabled"
            size="small"
          />
        }
        label="Swing Trails"
      />
      {/*<FormHelperText>Swing Track & Bat</FormHelperText>*/}

      <br />
      <br />

      <div className={classes.sliderRoot}>
        Trail Line Count
        <Slider
          min={2}
          max={100}
          value={trailCount}
          aria-labelledby="line-count-slider"
          step={1}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleTrailCountChange}
        />
        {/*<FormHelperText>- speed ➔ CCW<br/> + speed ➔ CW</FormHelperText>*/}
      </div>

      <br />
      {/*<br />*/}

      <div className={classes.sliderRoot}>
        Trail Line Width
        <Slider
          min={1}
          max={20}
          value={lineWidth}
          aria-labelledby="line-width-slider"
          step={1}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleLineWidthChange}
        />
        {/*<FormHelperText>- speed ➔ CCW<br/> + speed ➔ CW</FormHelperText>*/}
      </div>

      <br />
      {/*<br />*/}

      <div className={classes.sliderRoot}>
        Trail Duration (ms)
        <Slider
          min={0}
          max={2000}
          value={trailDuration}
          aria-labelledby="trail-duration-slider"
          step={50}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleTrailDurationChange}
        />
        {/*<FormHelperText>- speed ➔ CCW<br/> + speed ➔ CW</FormHelperText>*/}
      </div>

      <br />
      {/*<br />*/}

      <div className={classes.sliderRoot}>
        Trail Duration Variance (ms)
        <Slider
          min={0}
          max={500}
          value={trailDurationVariation}
          aria-labelledby="trail-duration-variance-slider"
          step={50}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleTrailDurationVariationChange}
        />
        {/*<FormHelperText>- speed ➔ CCW<br/> + speed ➔ CW</FormHelperText>*/}
      </div>

      <br />

      <ColorPicker color={lineColor} onChange={handleLineColorChange} />
    </div>
  );
};
