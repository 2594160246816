import React from "react";
import { fenceMap } from "./fences-lidar";
import { toPolePoints } from "./VenueFieldGroup";
import { PolesGroup } from "./PolesGroup";

const refPoleColor = "#f00";

interface ReferencePolesGroupProps {
  venueId: number;
}
export const ReferencePolesGroup = ({ venueId }: ReferencePolesGroupProps) => {
  let points = fenceMap[venueId];
  let polePoints = toPolePoints(points, venueId);

  return polePoints && <PolesGroup points={polePoints} color={refPoleColor} />;
};
