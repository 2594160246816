import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _forEach from "lodash/forEach";
import _meanBy from "lodash/meanBy";
import _uniq from "lodash/uniq";
import _isEmpty from "lodash/isEmpty"
import { magnitude, Point3D } from "./geometry-util";
import { HALF_PI, TWO_PI } from "../constants/math";
import { divide, interpolateJoint, smoothJoint } from "./dataCleanupUtil";
import { toEpoch, toIS08601Timestamp } from "./time-util";
import {
  toBallPointSeries,
  toExtendedBallPointSeries,
} from "./polynomial-util";
import { getGenericSegment } from "../components/FlyCam/flycam-util";

import { Vector3, Scene, Object3D } from "three";

// TODO: do we want to bring in dependencies from src to utils?
import {
  JointsMapType,
  figureNamePrefix,
  groupNamePrefix,
 } from "field-of-things/src/components/Figure";
import { JointsLengthType } from "../stores/figurePoseStore";
import { modelNamePrefix } from "field-of-things/src/components/FigureModel";
import { SkeletalLengths, SkeletalLengthAll } from "../types/statsApi"

export let unpackTracking = ({ packedTracking }: { packedTracking: any }) => {
  let { jointsMap, trackingFrames } = packedTracking;

  return trackingFrames.map((frame: any) => {
    // handle the rare possibility that there are no tracked figures
    let players = frame.players || [];

    let unpackedPlayers = players.map((player: any) => {
      let { joints } = player;
      let unpackedPlayer = { ...player };
      let unpackedJoints: { [index: string]: any } = {};

      _forEach(jointsMap, (i, joint) => {
        let coords = joints[i];

        if (coords) {
          unpackedJoints[joint] = coords;
        }
      });

      unpackedPlayer.joints = unpackedJoints;

      if (unpackedPlayer.id) {
        unpackedPlayer.trackId = unpackedPlayer.id;
        delete unpackedPlayer.id;
      }

      return unpackedPlayer;
    });

    return { ...frame, players: unpackedPlayers };
  });
};

const offset = 0; //-25200000// +2000 + 231

export let unpackTracking2 = ({ packedTracking }: { packedTracking: any }) => {
  let packedTrackingCopy = _cloneDeep(packedTracking);
  // let {jointsMap, trackingFrames} = packedTracking
  let { jointIndexNameMap, trackingFrames } = packedTrackingCopy;

  return trackingFrames.map((frame: any) => {
    frame.time += offset;

    // handle the rare possibility that there are no tracked figures
    let players = frame.players || [];

    let unpackedPlayers = players.map((player: any) => {
      let { jointIds, jointPositions } = player;
      let unpackedPlayer = { ...player };
      let joints: { [index: string]: any } = {};

      jointIds.forEach((jointId: any, i: number) => {
        let jointName = jointIndexNameMap[jointId];
        // get coords from jointPositions
        let j = i * 3;

        joints[jointName] = [
          jointPositions[j],
          jointPositions[j + 1],
          jointPositions[j + 2],
        ];
      });

      unpackedPlayer.joints = joints;

      return unpackedPlayer;
    });

    return { ...frame, players: unpackedPlayers };
  });
};

/**
 * Temporary transformation to anticipate newer HE data format
 * so far, just renames player.id to player.trackId
 * @param frames
 */
export const bridgeTrackingFrames = (frames: any[]) => {
  frames.forEach((frame) => {
    let { players } = frame;

    players.forEach((player: any) => {
      if (player.id) {
        player.trackId = player.id;
        delete player.id;
      }
    });
  });
};

/* Pose Mode stuff */
export const boneLengthWindow = 10;
const rigSkelLengths = {} as SkeletalLengthAll;

/**
 * Builds an analogue of jointMap for the mesh armature
 */
const generateRigPoints =
  (root: Object3D): JointsMapType =>
{
  let rigMap = {} as JointsMapType;
  root.traverse((obj: Object3D) => {
    let worldPos = new Vector3();
    obj.getWorldPosition(worldPos);

    // MLB coord system is different from three.js
    rigMap[obj.name.toLowerCase().replace(/-|_/g, "")] =
      [worldPos.x, -worldPos.z, worldPos.y];
  });

  return rigMap;
}

const calcCentralizedJoints = (joints: any) => {
  if ((joints.lhip == null) || (joints.rhip == null)) {
    console.error("Missing hips in posedPersonId", joints);
    return joints;
  }

  // Clone joints as we don't want to overwrite for the replay
  let nJoints = _cloneDeep(joints);

  let zIndex = 2;
  let midHip = [];
  for (var i = 0; i < zIndex; i++) {
    midHip.push((nJoints.lhip[i] + nJoints.rhip[i]) / 2);
  }

  for (let name in nJoints) {
    for (i = 0; i < zIndex; i++) {
      nJoints[name][i] -= midHip[i];
    }
  }
  return nJoints;
}

export const processScene =
  (scene: Scene,
   setRawPoints: any,
   setRiggedPoints: any,
   setRiggedBoneLengths: any,
   posedPositionId: number,
   centralize: boolean): void =>
{
  // TODO: memoise this somehow?
  let group = scene.getObjectByName(
    groupNamePrefix + posedPositionId.toString());
  if (!group) {
    console.error("No group found for posedPositionId " + posedPositionId);
    return;
  }

  let model = group.getObjectByName(
    modelNamePrefix + posedPositionId.toString());
  let figure = group.getObjectByName(
    figureNamePrefix + posedPositionId.toString());

  if (!figure) {
    return;
  }

  let jointsMap: JointsMapType = figure.userData?.jointsMap;
  if (!jointsMap) {
    console.error("No jointsMap in figure for posedPositionId " + posedPositionId);
    return;
  }
  let rawPoints = generateRawPoints(jointsMap);
  if (centralize) {
    rawPoints = calcCentralizedJoints(rawPoints);
  }
  setRawPoints(rawPoints);

  if (model) {
    let rigPoints = generateRigPoints(model);
    if (centralize) {
      rigPoints = calcCentralizedJoints(rigPoints);
    }
    setRiggedPoints(rigPoints);

    if (!rigSkelLengths.hasOwnProperty(posedPositionId)) {
      rigSkelLengths[posedPositionId] =
        { lengths: {}, frameCount: 0} as SkeletalLengths;
    }

    if (rigSkelLengths[posedPositionId].frameCount < boneLengthWindow) {
      let [ len, success ] = calculateBoneLengths(
        rigPoints, rigSkelLengths[posedPositionId].lengths);
      if (success) {
        rigSkelLengths[posedPositionId].lengths = len;
        rigSkelLengths[posedPositionId].frameCount++;
      }

      setRiggedBoneLengths({});
    }
    else {
      setRiggedBoneLengths(rigSkelLengths[posedPositionId].lengths);
    }
  }
}

// export const toPlayTracking2 = ({packedTracking}: { packedTracking: any }) => {
//     let trackingFrames = unpackTracking2({packedTracking})
//
//     bridgeTrackingFrames(trackingFrames)
//
//
//     // TODOHI  sapi - map to sapi shape here?
//
//
//     return {trackingFrames}
// }

/**
 * Averages given points to a single point
 * @param points
 */
export const meanPoint = (points: number[][]) => {
  // return [
  //     _meanBy(points, p => p[0]),
  //     _meanBy(points, p => p[1]),
  //     _meanBy(points, p => p[2]),
  // ]

  return {
    x: _meanBy(points, "x"),
    y: _meanBy(points, "y"),
    z: _meanBy(points, "z"),
  };
};

export const toPolar = (p: Point3D) => {
  let x = p.x;
  let y = p.y;
  let radius = magnitude({ x, y });
  let theta = Math.atan2(y, x) - HALF_PI;

  // keep in [-pi .. +pi] range
  theta = theta < -Math.PI ? theta + TWO_PI : theta;

  return { radius, theta };
};

export const toLocation = (joints: any) => {
  // let points: number[][] = Object.values(joints)
  let p = meanPoint(joints);
  let { radius: dist, theta: angle } = toPolar(p);
  let angleDeg = (angle * 180) / Math.PI;

  return { ...p, dist, angle, angleDeg };
};

// const jointIndexNameMap = {
//     '0': 'Nose',
//     '1': 'Neck',
//     '2': 'RShoulder',
//     '3': 'RElbow',
//     '4': 'RWrist',
//     '5': 'LShoulder',
//     '6': 'LElbow',
//     '7': 'LWrist',
//     '8': 'MidHip',
//     '9': 'RHip',
//     '10': 'RKnee',
//     '11': 'RAnkle',
//     '12': 'LHip',
//     '13': 'LKnee',
//     '14': 'LAnkle',
//     '15': 'REye',
//     '16': 'LEye',
//     '17': 'REar',
//     '18': 'LEar'
// }

const jointNames = [
  "Nose", // 0
  "Neck", // 1
  "RShoulder", // 2
  "RElbow", // 3
  "RWrist", // 4
  "LShoulder", // 5
  "LElbow", // 6
  "LWrist", // 7
  "MidHip", // 8
  "RHip", // 9
  "RKnee", // 10
  "RAnkle", // 11
  "LHip", // 12
  "LKnee", // 13
  "LAnkle", // 14
  "REye", // 15
  "LEye", // 16
  "REar", // 17
  "LEar", // 18
];

const unknownPlayerPositionId = 0;

const hasValidPositionId = (figure: any) =>
  figure.positionId !== undefined &&
  figure.positionId !== unknownPlayerPositionId;

interface prepTrackingArgs {
  playData: any;
  playTracking: any;
  jointCleanup: boolean;
  upsample: boolean;
  truncatedHitExtensionEnabled?: boolean;
}

/**
 * Filters out figures with undefined positionId or positionId 0 in order to avoid problems with multiple figures
 * sharing positionId 0. When players are assigned positionId undefined or 0 it represents a possible tagging issue
 * that needs to be addressed upstream.
 * @param playTracking
 */
export const prepTracking = ({
  playData: _playData,
  playTracking: _playTracking,
  jointCleanup,
  upsample,
  truncatedHitExtensionEnabled = false,
}: prepTrackingArgs): [any, any] => {
  if ((_playData == null) || (_playTracking == null)) {
    return [_playTracking, _playData]
  }
  const playTracking = _cloneDeep(_playTracking);
  const playData = _cloneDeep(_playData);
  let { frames } = playTracking.skeletalData;

  if (frames === undefined) {
    console.error("frames undefined in prepTracking");
    return [undefined, undefined];
  }

  // filter out figures with invalid positionIds
  for (let frame of frames) {
    frame.positions = frame.positions.filter(hasValidPositionId);
  }

  // drop any frame with no figures
  playTracking.skeletalData.frames = frames.filter(
    (frame: any) => frame.positions.length !== 0
  );

  if (jointCleanup) {
    cleanupData(playTracking, { upsample });
  }

  addDerivedData({ playData, playTracking, truncatedHitExtensionEnabled });

  let genericHitSegment = getGenericSegment(playData, "BaseballHit");

  if (genericHitSegment) {
    let { endData } = genericHitSegment;

    // set/reset time with endData.extendedTime or endData.originalTime
    endData.time = truncatedHitExtensionEnabled
      ? endData.extendedTime
      : endData.originalTime;
  }

  return [playTracking, playData];
};

/**
 * interpolates missing data, smooths with a low pass filter
 * TODO: expose parameters of interpolation and smoothing to the frontend as well
 * @param playTracking
 */
const cleanupData = (playTracking: any, options: { upsample?: boolean }) => {
  const jointMap = trackingToJointMap(playTracking);
  let times = playTracking.skeletalData.frames.map(
    (frame: any) => frame.timeStamp
  );

  if (options.upsample) {
    times = times.map(toEpoch).flatMap(divide).map(toIS08601Timestamp);
  }
  _forEach(jointMap, (player: any) => {
    _forEach(player, (joint: any, key: string) => {
      interpolateJoint(joint, times, key);
      smoothJoint(joint, times);
    });
  });

  const interpolatedFrames = jointMapToFrames(jointMap, times);
  playTracking.skeletalData.frames = interpolatedFrames;
};

const compareNumbers = (a: number, b: number) => a - b;

// temporary
/**
 * Checks for duplicate positionIds
 * @param playTracking
 */
export const analyzeTracking = ({ playTracking }: any) => {
  let { frames } = playTracking.skeletalData;

  for (let [i, frame] of frames.entries()) {
    let positionIds = frame.positions.map((p: any) => p.positionId);
    let uniquePositionIds = _uniq(positionIds);

    if (positionIds.length !== uniquePositionIds.length) {
      console.log(`frame: ${i}`, positionIds.sort(compareNumbers));
    }
  }
};

interface addExtendedHitSegmentTimeArgs {
  playData: any;
  // truncatedHitExtensionEnabled?: boolean;
  extensionHeightThreshold?: number;
}

// note: We could use landingData.time instead of calculating an extended time here. landingData.time corresponds to
// close to 0 height, where  addExtendedHitSegmentTime defaults to 10' height.
// For now, we are setting/resetting endData.time when truncatedHitExtensionEnabled is toggled.
// Otherwise, we would to pass truncatedHitExtensionEnabled through to and handle it in multiple places.

/**
 * If there is a hit segment, adds an extendedEndTime to *.endData that extends the hit polynomial if it is truncated.
 * If it is not truncated, sets *.endData.extendedEndTime to *.endData.time
 * @param playData
 */
const addExtendedHitSegmentTime = ({
  playData,
  // truncatedHitExtensionEnabled = false,
  extensionHeightThreshold = 10,
}: addExtendedHitSegmentTimeArgs) => {
  let { hitSegment, genericSegments } = playData.ballSegments;

  if (!hitSegment) {
    return;
  }

  // find generic hit segment, if any
  let genericHitSegment = _find(genericSegments, {
    segmentType: "BaseballHit",
  });

  if (!genericHitSegment) {
    return;
  }

  if (!genericHitSegment.endData.originalTime) {
    // assuming here that .endData.time has not been altered if .endData.originalTime doesn't exist yet
    genericHitSegment.endData.originalTime = genericHitSegment.endData.time;
  }

  // generate series and check end height
  let hitSeries = toBallPointSeries({ segments: [genericHitSegment] });
  let pLast = hitSeries[hitSeries.length - 1];

  // if already at or beyond threshold, just copy endData.time to endData.extendedTime
  if (pLast.z <= extensionHeightThreshold) {
    genericHitSegment.endData.extendedTime = genericHitSegment.endData.time;
  }

  // otherwise, generate extended series and set endData.extendedTime
  let extendedHitSeries = toExtendedBallPointSeries({
    segments: [genericHitSegment],
    extensionHeightThreshold,
  });
  pLast = extendedHitSeries[extendedHitSeries.length - 1];

  genericHitSegment.endData.extendedTime = pLast.t;
};

interface addDerivedDataArgs {
  playData: any;
  playTracking: any;
  truncatedHitExtensionEnabled?: boolean;
}

export const addDerivedData = ({
  playData,
  playTracking,
  truncatedHitExtensionEnabled = false,
}: addDerivedDataArgs) => {
  addExtendedHitSegmentTime({ playData });

  // TODO    set/reset endData.time based on truncatedHitExtensionEnabled

  let { frames } = playTracking.skeletalData;

  if (frames === undefined) {
    console.error("frames undefined in addDerivedData");
    return;
  }

  // TODO: work out idiomatic way of doing this nested instantiation
  if (!playTracking.hasOwnProperty("skeletalData")) {
    playTracking.skeletalData = {};
  }
  playTracking.skeletalData.skeletalLen = {} as SkeletalLengthAll;
  var skelLen = playTracking.skeletalData.skeletalLen;

  for (let frame of frames) {
    // add ms time
    frame.time = new Date(`${frame.timeStamp}`).getTime();

    // calculate cartesian and polar location of each figure
    for (let figure of frame.positions) {
      if (!_isEmpty(figure.joints)) {
        figure.location = toLocation(figure.joints);
      }

      // construct named joints map
      figure.jointsMap = figure.joints.reduce((map: any, joint: any) => {
        map[jointNames[joint.id]] = [joint.x, joint.y, joint.z];

        return map;
      }, {});

      // update bone length calcs
      if (!skelLen.hasOwnProperty(figure.positionId)) {
        skelLen[figure.positionId] = { lengths: {}, frameCount: 0} as SkeletalLengths;
      }

      let curPos = skelLen[figure.positionId];
      if (curPos.frameCount < boneLengthWindow) {
        let rawPoints = generateRawPoints(figure.jointsMap);
        let [ len, success ] = calculateBoneLengths(
          rawPoints, curPos.lengths);

        if (success) {
          curPos.lengths = len;
          curPos.frameCount++;
        }
      }
    }
  }
};

/**
* Makes names consistent with rigPoints
*
* TODO: need to work out wh different naming conventions were used in
*       the first place
*/
export const generateRawPoints =
 (jointMap: JointsMapType): JointsMapType =>
{
 let rawMap = {} as JointsMapType;

 for (let name in jointMap) {
   rawMap[name.toLowerCase()] = jointMap[name];
 }

 return rawMap;
}

export const calculateBoneLengths =
  (joints: JointsMapType,
   prev: JointsLengthType): [JointsLengthType, boolean] =>
{
  let lengths = {} as JointsLengthType;
  let success = true;

  const handedJoints =
    [
      ["shoulder", "elbow", "wrist"],
      ["hip", "knee", "ankle"]
    ];

  // handed bone lengths
  const head = new Vector3();
  const tail = new Vector3();
  const midhip = new Vector3();
  const neck = new Vector3();
  for (var j = 0; (j < handedJoints.length) && success; j++) {
    let list = handedJoints[j];
    for (var i = 0; i < list.length - 1; i++) {
      let headName = list[i];
      let tailName = list[i + 1];

      try {
        head.set(
            (joints["l" + headName][0] + joints["r" + headName][0]) / 2,
            (joints["l" + headName][1] + joints["r" + headName][1]) / 2,
            (joints["l" + headName][2] + joints["r" + headName][2]) / 2,
          );
        tail.set(
            (joints["l" + tailName][0] + joints["r" + tailName][0]) / 2,
            (joints["l" + tailName][1] + joints["r" + tailName][1]) / 2,
            (joints["l" + tailName][2] + joints["r" + tailName][2]) / 2,
          );
        lengths[(list[i]).toLowerCase()] = Math.abs(head.distanceTo(tail));
      } catch (e) {
        success = false;
        break;
      }
    }

    // conglomerated spine bone length
    // Note that the hip is offset -5cm in the rigged model, so we
    // can't use that for comparision without using a bodge factor
    midhip.set(
      (joints["lhip"][0] + joints["rhip"][0]) / 2,
      (joints["lhip"][1] + joints["rhip"][1]) / 2,
      (joints["lhip"][2] + joints["rhip"][2]) / 2,
    )
    neck.set(
      joints["neck"][0],
      joints["neck"][1],
      joints["neck"][2],
    );

    lengths["spine"] = Math.abs(midhip.distanceTo(neck));
  }

  // TODO: improve this - currently just take moving average
  if (success && !_isEmpty(prev)) {
    for (let name in lengths) {
      lengths[name] = (lengths[name] + prev[name]) / 2;
    }
  }

  return [lengths, success];
}

const trackingToJointMap = (
  playTracking: any
): {
  [positionId: number]: {
    [jointId: number]: {
      [timestamp: string]: { x: number; y: number; z: number; id: number };
    };
  };
} => {
  let ret: any = {};

  playTracking.skeletalData.frames.forEach((frame: any) => {
    const { timeStamp } = frame;
    frame.positions.forEach((position: any) => {
      const { positionId } = position;
      position.joints.forEach((joint: any) => {
        const { id: jointId } = joint;
        if (!ret[positionId]) ret[positionId] = {};
        if (!ret[positionId][jointId]) ret[positionId][jointId] = {};

        ret[positionId][jointId][timeStamp] = joint;
      });
    });
  });

  return ret;
};

const jointMapToFrames = (jointMap: any, times: string[]): any[] => {
  return times.map((time) => {
    const frame: any = {};
    frame["timeStamp"] = time;
    let players: any[] = [];

    _forEach(jointMap, (player: any, positionId: string) => {
      const newPlayer: any = {};
      newPlayer["positionId"] = Number(positionId);
      const joints: any[] = [];
      _forEach(player, (joint: any, jointId: string) => {
        if (jointMap[positionId] && jointMap[positionId][jointId]) {
          const newJoint = jointMap[positionId][jointId][time];
          if (newJoint) {
            joints.push(newJoint);
          }
        }
      });
      newPlayer["joints"] = joints;
      players.push(newPlayer);
    });
    frame["positions"] = players;
    return frame;
  });
};
