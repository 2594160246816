import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import NativeSelect from "@material-ui/core/NativeSelect";
import Divider from "@material-ui/core/Divider";
import { ColorPicker } from "../ColorPicker";
import { makeStyles } from "@material-ui/core/styles";
import { TabPanel } from "./Knobs";
import { allPositions } from "field-of-things/src/models/positions";
import { UseStore } from "zustand";
import { FigurePositionStore } from "field-of-things/src/stores/figurePositionStore";
import { PlaySummaryStore } from "field-of-things/src/stores/playSummaryStore";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  sliderRoot: {
    width: 200,
  },
  multiSelect: {
    width: 160,
  },
  multiSelectControl: {
    alignItems: "flex-start",
  },
  figureButton: {
    margin: theme.spacing(0.5),
  },
}));

interface Props {
  value: any;
  index: number;
  useFigurePositionStore: UseStore<FigurePositionStore>;
  usePlaySummaryStore: UseStore<PlaySummaryStore>;
}

export const PathKnobsPanel = (props: Props) => {
  let { index, value, useFigurePositionStore, usePlaySummaryStore } = props;

  const classes = useStyles();

  const {
    playerPathsVisible,
    togglePlayerPathsVisible,
    playerPositionVisible,
    togglePlayerPositionVisible,
    playerPositionBaseVisible,
    togglePlayerPositionBaseVisible,
    playerPositionPointType,
    setPlayerPositionPointTypeNone,
    setPlayerPositionPointTypePoint,
    setPlayerPositionPointTypeNeedle,
  } = useFigurePositionStore();

  const {
    battingPathColor,
    setBattingPathColor,
    setFieldingPathColor,
    fieldingPathColor,
    setGenericPathColor,
    genericPathColor,
    playerOverridePathColor,
    setPlayerOverridePathColor,
    playerOverrideId,
    setPlayerOverrideId,
    pathsEnabled,
    togglePathsEnabled,
    stepsEnabled,
    toggleStepsEnabled,
  } = usePlaySummaryStore();

  const handlePositionPointTypeChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const pointType = event.target.value;

    switch (pointType) {
      case "point":
        setPlayerPositionPointTypePoint();
        break;
      case "needle":
        setPlayerPositionPointTypeNeedle();
        break;
      case "none":
      default:
        setPlayerPositionPointTypeNone();
        break;
    }
  };

  const trackablePlayers = allPositions.filter(
    (position) => position.type === "defense" || position.type === "runner"
  );

  return (
    <TabPanel value={value} index={index}>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Switch
              checked={stepsEnabled}
              onChange={toggleStepsEnabled}
              name="figure-steps-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Player Steps"
        />

        <br />

        <FormControlLabel
          control={
            <Switch
              checked={pathsEnabled}
              onChange={togglePathsEnabled}
              name="figure-paths-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Player Paths"
        />

        <br />

        <h3>Path and Step Colors</h3>
        <FormControl component="fieldset">
          Batting team
          <ColorPicker
            color={battingPathColor}
            onChange={(color) => setBattingPathColor(color)}
          />
        </FormControl>
        <br />

        <FormControl component="fieldset">
          Fielding team
          <ColorPicker
            color={fieldingPathColor}
            onChange={(color) => setFieldingPathColor(color)}
          />
        </FormControl>
        <br />

        <FormControl component="fieldset">
          Generic player
          <ColorPicker
            color={genericPathColor}
            onChange={(color) => setGenericPathColor(color)}
          />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          Highlighted player
          <NativeSelect
            onChange={(event) =>
              setPlayerOverrideId(parseInt(event.target.value))
            }
            value={playerOverrideId}
            name="player-name-plates-enabled-per-player"
            color="secondary"
          >
            {[
              <option key={0} value={0}>
                None
              </option>,
            ].concat(
              trackablePlayers.map((position) => (
                <option key={position.id} value={position.id}>
                  {position.label}
                </option>
              ))
            )}
          </NativeSelect>
          <br />
          <FormControl component="fieldset">
            <ColorPicker
              color={playerOverridePathColor}
              onChange={(color) => setPlayerOverridePathColor(color)}
            />
          </FormControl>
        </FormControl>

        <br />
        <Divider />
        <br />

        <FormControlLabel
          control={
            <Switch
              checked={playerPathsVisible}
              onChange={togglePlayerPathsVisible}
              name="player-paths-toggle"
              color="secondary"
              size="small"
            />
          }
          label="MPD Player Paths"
        />
        <FormHelperText>Merged Positional Data</FormHelperText>
        <br />
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={playerPositionVisible}
              onChange={togglePlayerPositionVisible}
              name="position-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Player Positions"
        />
        <FormHelperText>Merged Positional Data</FormHelperText>
        <br />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="player-point-type">Position Point</InputLabel>
          <NativeSelect
            disabled={!playerPositionVisible}
            value={playerPositionPointType}
            onChange={handlePositionPointTypeChange}
            inputProps={{
              name: "player-point-type",
              id: "player-point-type",
            }}
          >
            <option value="point">Dot</option>
            <option value="needle">Needle</option>
            <option value="none">None</option>
          </NativeSelect>
        </FormControl>
        <br />
        <FormControlLabel
          control={
            <Switch
              disabled={!playerPositionVisible}
              checked={playerPositionBaseVisible}
              onChange={togglePlayerPositionBaseVisible}
              name="player-position-base-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Position Base"
        />
      </FormControl>
    </TabPanel>
  );
};
