import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Slider from "@material-ui/core/Slider";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import { playerPositions } from "field-of-things/src/constants/play";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import {
  hannWindowSizeRange,
  hannWindowSizeStep,
  alphaRange,
  alphaStep,
  movingAverageRange,
  movingAverageStep,
} from "field-of-things/src/stores/povCameraStore";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  offsetIntervalSliderRoot: {
    width: 200,
  },
  sliderRoot: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const HannKnobs = ({ usePOVCameraStore }: { usePOVCameraStore: any }) => {
  let classes = useStyles();
  let { hannWindowSize, setHannWindowSize } = usePOVCameraStore();

  let handleWindowSizeChange = (event: any, value: any) => {
    setHannWindowSize(value);
  };

  return (
    <div className={classes.sliderRoot}>
      {`Window Size: ${hannWindowSize}`}
      <Slider
        min={hannWindowSizeRange[0]}
        max={hannWindowSizeRange[1]}
        value={hannWindowSize}
        aria-labelledby="hann-window-size"
        step={hannWindowSizeStep}
        valueLabelDisplay="auto"
        onChange={handleWindowSizeChange}
      />
    </div>
  );
};

const MovingAverageKnobs = ({
  usePOVCameraStore,
}: {
  usePOVCameraStore: any;
}) => {
  let classes = useStyles();
  let {
    movingAverageWindowSize,
    setMovingAverageWindowSize,
  } = usePOVCameraStore();

  let handleWindowSizeChange = (event: any, value: any) => {
    setMovingAverageWindowSize(value);
  };

  return (
    <div className={classes.sliderRoot}>
      {`Window Size: ${movingAverageWindowSize}`}
      <Slider
        min={movingAverageRange[0]}
        max={movingAverageRange[1]}
        value={movingAverageWindowSize}
        aria-labelledby="-moving-average-window-size"
        step={movingAverageStep}
        valueLabelDisplay="auto"
        onChange={handleWindowSizeChange}
      />
    </div>
  );
};

const AlphaKnobs = ({ usePOVCameraStore }: { usePOVCameraStore: any }) => {
  let classes = useStyles();
  let { alpha, setAlpha } = usePOVCameraStore();

  let handleAlphaChange = (event: any, value: any) => {
    setAlpha(value);
  };

  return (
    <div className={classes.sliderRoot}>
      {`Alpha ${alpha}`}
      <Slider
        min={alphaRange[0]}
        max={alphaRange[1]}
        value={alpha}
        aria-labelledby="-hanning-window-size"
        step={alphaStep}
        valueLabelDisplay="auto"
        onChange={handleAlphaChange}
      />
    </div>
  );
};

const HeadPoseKnobs = ({ usePOVCameraStore }: { usePOVCameraStore: any }) => {
  let classes = useStyles();
  let { tiltDegrees, setTiltDegrees } = usePOVCameraStore();

  let handleTiltChange = (event: any, value: any) => {
    setTiltDegrees(+value);
  };

  return (
    <>
      <Tooltip
        title="Rotational compensation applied to head-pose"
        placement="top"
      >
        <div className={classes.sliderRoot}>
          {`Head-Pose Tilt: ${tiltDegrees}°`}
          <Slider
            min={-90}
            max={90}
            value={tiltDegrees}
            aria-labelledby="tilt-degrees"
            step={1}
            valueLabelDisplay="auto"
            onChange={handleTiltChange}
          />
        </div>
      </Tooltip>
      <br />
    </>
  );
};

interface Props {
  usePOVCameraStore: any;
}

export const POVCamKnobs = ({ usePOVCameraStore }: Props) => {
  let classes = useStyles();
  let {
    positionId,
    setPositionId,
    smoothingEnabled,
    setSmoothingEnabled,
    smoothingType,
    setSmoothingType,
    ballDirectionMix,
    setBallDirectionMix,
    backOffset,
    setBackOffset,
    verticalOffset,
    setVerticalOffset,
    horizontalOffset,
    setHorizontalOffset,
  } = usePOVCameraStore();

  let handlePositionIdChange = (event: any) => {
    setPositionId(+event.target.value);
  };

  const handleSmoothingTypeChange = (event: any) => {
    setSmoothingType(event.target.value);
  };

  const handleBallDirectionMixChange = (event: any, value: any) => {
    setBallDirectionMix(+value / 100);
  };

  let handleBackOffsetChange = (event: any, value: any) => {
    setBackOffset(value);
  };

  let handleVerticaOffsetChange = (event: any, value: any) => {
    setVerticalOffset(value);
  };

  let handleHorizontalOffsetChange = (event: any, value: any) => {
    setHorizontalOffset(value);
  };

  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="position-id">Figure</InputLabel>
        <NativeSelect
          value={positionId}
          onChange={handlePositionIdChange}
          inputProps={{
            name: "position-id",
            id: "position-id",
          }}
        >
          {playerPositions.map((p: any) => (
            <option key={p.positionId} value={p.positionId}>
              {p.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <br />
      <br />

      <HeadPoseKnobs usePOVCameraStore={usePOVCameraStore} />

      <Tooltip
        title="Mix head-pose and ball-position to get gaze direction"
        placement="top"
      >
        <div className={classes.sliderRoot}>
          {`Head-Pose/Ball Mix: ${Math.round(ballDirectionMix * 100)}% ball`}

          <Slider
            min={0}
            max={100}
            value={Math.round(ballDirectionMix * 100)}
            aria-labelledby="ball-pos-mix"
            step={1}
            valueLabelDisplay="auto"
            onChange={handleBallDirectionMixChange}
          />

          <FormHelperText>
            0%: Purely Head-Pose <br /> 100%: Purely Ball Position
          </FormHelperText>
        </div>
      </Tooltip>
      <br />

      <FormControlLabel
        control={
          <Switch
            checked={smoothingEnabled}
            onChange={() => setSmoothingEnabled(!smoothingEnabled)}
            name="smoothing-enabled-toggle"
            color="secondary"
            size="small"
          />
        }
        label="Smoothing"
      />

      <br />
      <br />

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="smoothing-type">Smoothing Type</InputLabel>
        <NativeSelect
          value={smoothingType}
          onChange={handleSmoothingTypeChange}
          inputProps={{
            name: "smoothing-type",
            id: "smoothing-type",
          }}
        >
          <option key="hann" value="hann">
            Hann
          </option>
          <option key="moving-average" value="moving-average">
            Moving Average
          </option>
          <option key="alpha" value="alpha">
            Alpha Filter
          </option>
        </NativeSelect>
      </FormControl>
      <br />
      <br />
      {smoothingType === "hann" && (
        <HannKnobs usePOVCameraStore={usePOVCameraStore} />
      )}
      {smoothingType === "moving-average" && (
        <MovingAverageKnobs usePOVCameraStore={usePOVCameraStore} />
      )}
      {smoothingType === "alpha" && (
        <AlphaKnobs usePOVCameraStore={usePOVCameraStore} />
      )}

      <br />

      <Tooltip title="Move the camera behind the head" placement="top">
        <h4>Offsets</h4>
      </Tooltip>

      <div className={classes.sliderRoot}>
        {`Back Offset: ${backOffset}'`}
        <Slider
          min={0}
          max={10}
          value={backOffset}
          aria-labelledby="back-offset"
          step={0.1}
          valueLabelDisplay="auto"
          onChange={handleBackOffsetChange}
        />
      </div>

      <div className={classes.sliderRoot}>
        {`Vertical Offset: ${verticalOffset}'`}
        <Slider
          min={-3}
          max={5}
          value={verticalOffset}
          aria-labelledby="vertical-offset"
          step={0.1}
          valueLabelDisplay="auto"
          onChange={handleVerticaOffsetChange}
        />
      </div>

      <div className={classes.sliderRoot}>
        {`Horizontal Offset: ${horizontalOffset}'`}
        <Slider
          min={-5}
          max={5}
          value={horizontalOffset}
          aria-labelledby="horizontal-offset"
          step={0.1}
          valueLabelDisplay="auto"
          onChange={handleHorizontalOffsetChange}
        />
      </div>

      <Button
        className={classes.button}
        size="small"
        variant="contained"
        disabled={
          backOffset === 0 && verticalOffset === 0 && horizontalOffset === 0
        }
        onClick={() => {
          setBackOffset(0);
          setHorizontalOffset(0);
          setVerticalOffset(0);
        }}
      >
        ClearOffsets
      </Button>
    </div>
  );
};
