import React, { useMemo } from "react";
import { PinMark } from "./PinMark";
import { RingMark } from "./RingMark";

interface Props {
  useMarksStore: any;
}

export const MarksGroup = ({ useMarksStore }: Props) => {
  let {
    pinVisible,
    pinPosition,
    ringVisible,
    ringRadius,
    ringHeight,
  } = useMarksStore();

  let pinMark = useMemo(() => {
    return pinVisible ? <PinMark position={pinPosition} /> : null;
  }, [pinVisible, pinPosition]);
  let ringMark = ringVisible ? (
    <RingMark radius={ringRadius} height={ringHeight} />
  ) : null;

  return (
    <group name="marks">
      {pinMark}
      {ringMark}
    </group>
  );
};
