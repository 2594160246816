import React from "react";
import { MathUtils } from "three";
import FormControl from "@material-ui/core/FormControl";
import { TabPanel } from "./Knobs";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { magnitude } from "field-of-things/src/util/geometry-util";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

// redefining toPolar here was easier than using the version of toPolar in /util/tracking-data-util
const toPolar = (x: number, y: number) => {
  let radius = magnitude({ x, y });
  let theta = Math.atan2(x, y);

  return { radius, theta };
};

const toCartesian = (theta: number, radius: number) => {
  let x = radius * Math.sin(theta);
  let y = radius * Math.cos(theta);

  return { x, y };
};

const useStyles = makeStyles((theme) => ({
  narrowButton: {
    width: 100,
  },
}));

interface Props {
  value: any;
  index: number;
  useZoneProjectionStore: any;
  useMarksStore: any;
}

export const MarksKnobsPanel = (props: Props) => {
  let { index, value, useZoneProjectionStore, useMarksStore } = props;
  let {
    zoneProjectionVisible,
    toggleZoneProjectionVisible,
  } = useZoneProjectionStore();
  let {
    pinVisible,
    setPinVisible,
    pinPosition,
    setPinPosition,
    ringVisible,
    setRingVisible,
    ringRadius,
    setRingRadius,
    ringHeight,
    setRingHeight,
  } = useMarksStore();
  let { radius, theta } = toPolar(pinPosition[0], pinPosition[1]);
  let thetaDegrees = MathUtils.radToDeg(theta);
  let thetaDegreesFormatted = +thetaDegrees.toFixed(1);
  let xFormatted = +pinPosition[0].toFixed(1);
  let yFormatted = +pinPosition[1].toFixed(1);
  const classes = useStyles();

  let handleXChange = (evt: any) => {
    if (evt.target.value.length > 0) {
      let num = +evt.target.value;

      setPinPosition([num, pinPosition[1]]);
    }
  };

  let handleYChange = (evt: any) => {
    if (evt.target.value.length > 0) {
      let num = +evt.target.value;

      setPinPosition([pinPosition[0], num]);
    }
  };

  let handleRadiusChange = (evt: any) => {
    if (evt.target.value.length > 0) {
      let radius = +evt.target.value;
      let { x, y } = toCartesian(theta, radius);

      setPinPosition([x, y]);
    }
  };

  let handleAngleChange = (evt: any) => {
    if (evt.target.value.length > 0) {
      let degrees = +evt.target.value;
      let radians = MathUtils.degToRad(degrees);
      let { x, y } = toCartesian(radians, radius);

      setPinPosition([x, y]);
    }
  };

  let handleRingRadiusChange = (evt: any) => {
    let num = +evt.target.value;

    num > 0 && setRingRadius(num);
  };

  let handleRingHeightChange = (evt: any) => {
    let num = +evt.target.value;

    num > 0 && setRingHeight(num);
  };

  let handle2BClick = () => {
    setPinPosition([0, 127.2813]);
  };

  return (
    <TabPanel value={value} index={index}>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Switch
              checked={pinVisible}
              onChange={() => setPinVisible(!pinVisible)}
              name="pin-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Pin"
        />
        <FormHelperText>Show a pin at the following location</FormHelperText>
        <br />
        <TextField
          id="pin-x"
          style={{ width: 100 }}
          label="X"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          onChange={handleXChange}
          value={xFormatted}
        />
        <br />
        <TextField
          id="pin-y"
          style={{ width: 100 }}
          label="Y"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={yFormatted}
          onChange={handleYChange}
        />
        <br />
        <TextField
          id="pin-angle"
          style={{ width: 100 }}
          label="Angle"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={thetaDegreesFormatted}
          onChange={handleAngleChange}
        />
        <br />
        <TextField
          id="pin-distance"
          style={{ width: 100 }}
          label="Distance"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={+radius.toFixed(2)}
          onChange={handleRadiusChange}
        />

        <br />
        <Button
          size="small"
          onClick={handle2BClick}
          className={classes.narrowButton}
        >
          Pin at 2B
        </Button>

        <br />
        <br />

        <FormControlLabel
          control={
            <Switch
              checked={ringVisible}
              onChange={() => setRingVisible(!ringVisible)}
              name="ring-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Ring"
        />
        <FormHelperText>
          Show a ring with the following radius at the given height
        </FormHelperText>

        <br />

        <TextField
          id="ring-radius"
          style={{ width: 100 }}
          label="Ring Radius"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          onChange={handleRingRadiusChange}
          value={ringRadius}
        />
        <br />
        <TextField
          id="ring-height"
          style={{ width: 100 }}
          label="Ring Z Value"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={ringHeight}
          onChange={handleRingHeightChange}
        />

        <br />
        <br />

        <FormControlLabel
          control={
            <Switch
              checked={zoneProjectionVisible}
              onChange={toggleZoneProjectionVisible}
              name="zone-projection-switch"
              color="secondary"
              size="small"
            />
          }
          label="Zone Projection"
        />
        <FormHelperText>
          Projects stringer zone top/bottom onto batter
        </FormHelperText>
      </FormControl>
    </TabPanel>
  );
};
