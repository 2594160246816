// TODOHI  need to handle stepping through frame samples? handle here, or elsewhere?
export type milliseconds = "milliseconds";
export type seconds = "seconds";
export type TimeUnits = seconds | milliseconds; // | 'microseconds'

interface TimebaseListener {
  (timebase: Timebase): void;
}

// const nowMicroseconds = () => Date.now() * 1000
const nowMilliseconds = () => Date.now();
const nowSeconds = () => Date.now() / 1000;

const now = (units: TimeUnits) =>
  units === "milliseconds" ? nowMilliseconds() : nowSeconds();

const normalizedDecimals = 3;

// TODOHI  handle seconds units
// TODO    handle negative rates?
export class Timebase {
  private _timeRange = [0, 0];
  private _time = 0;
  private _timeAtPlayStart = 0;
  private _rate = 1; // intrinsic rate, regardless of play state
  private _isLooping = false;
  private _currentRate = 0; // play state rate
  private _tPlayStart = 0; // clock time at play start
  private _listeners: TimebaseListener[] = [];
  private _stopListeners: TimebaseListener[] = [];
  private _startListeners: TimebaseListener[] = [];
  private _loopListeners: TimebaseListener[] = [];

  constructor(public units: TimeUnits = "milliseconds") {}

  clampTime(t: number) {
    return Math.max(this._timeRange[0], Math.min(this._timeRange[1], t));
  }

  get timeRange() {
    return [...this._timeRange];
  }

  // TODO    need to call notifyListeners here?
  set timeRange(range: number[]) {
    this._timeRange = range;
    this.time = Math.max(range[0], Math.min(range[1], this._time));
  }

  get loop() {
    return this._isLooping;
  }

  set loop(isLooping) {
    this._isLooping = isLooping;
    this.notifyListeners();
  }

  get time() {
    return this._time;
  }

  set time(t: number) {
    // let {_timeRange} = this
    this._time = this.clampTime(t);
    this.notifyListeners();
  }

  slipToTime(time: number, duration: number = 400) {
    let that = this;
    const wasPlaying = this.isPlaying;

    this.stop();
    let startTime = this.time;
    // let span = 400
    let start = Date.now();
    let intervalId = setInterval(function () {
      let elapsed = Date.now() - start;

      if (elapsed > duration) {
        clearInterval(intervalId);
        that.time = time;
        that.notifyStopListeners();
        if (wasPlaying) {
          that.start();
        }
      } else {
        let f = elapsed / duration;
        that.time = startTime + f * (time - startTime);
      }
    }, 16);
  }

  get relativeTime() {
    return this.time - this.timeRange[0];
  }

  get normalizedTime() {
    return (
      (this.time - this.timeRange[0]) /
      (this.timeRange[1] - this.timeRange[0])
    ).toFixed(normalizedDecimals);
  }

  // TODOHI   implement set relativeTime
  // TODOHI   implement set normalizedTime

  addListener(listener: TimebaseListener) {
    if (
      !this._listeners.some((existingListener) => existingListener === listener)
    ) {
      this._listeners.push(listener);
    }
  }

  removeListener(listener: TimebaseListener) {
    this._listeners = this._listeners.filter((d) => d !== listener);
  }

  protected notifyListeners() {
    this._listeners.forEach((listener) => listener(this));
  }

  addStopListener(listener: TimebaseListener) {
    if (
      !this._stopListeners.some(
        (existingListener) => existingListener === listener
      )
    ) {
      this._stopListeners.push(listener);
    }
  }

  removeStopListener(listener: TimebaseListener) {
    this._stopListeners = this._stopListeners.filter((d) => d !== listener);
  }

  protected notifyStopListeners() {
    this._stopListeners.forEach((listener) => listener(this));
  }

  addStartListener(listener: TimebaseListener) {
    if (
      !this._startListeners.some(
        (existingListener) => existingListener === listener
      )
    ) {
      this._startListeners.push(listener);
    }
  }

  removeStartListener(listener: TimebaseListener) {
    this._startListeners = this._startListeners.filter((d) => d !== listener);
  }

  protected notifyStartListeners() {
    this._startListeners.forEach((listener) => listener(this));
  }

  addLoopListener(listener: TimebaseListener) {
    if (
      !this._loopListeners.some(
        (existingListener) => existingListener === listener
      )
    ) {
      this._loopListeners.push(listener);
    }
  }

  removeLoopListener(listener: TimebaseListener) {
    this._loopListeners = this._loopListeners.filter((d) => d !== listener);
  }

  protected notifyLoopListeners() {
    this._loopListeners.forEach((listener) => listener(this));
  }

  get rate() {
    return this._rate;
  }

  // TODO    need to call notifyListeners here?
  set rate(rate: number) {
    this._rate = rate;
    if (this._currentRate !== 0) {
      this._currentRate = rate;
    }
    if (this._currentRate !== 0) {
      this.start(); // just to resync
    }
  }

  get isPlaying() {
    return this._currentRate !== 0;
  }

  // isUnitsMilliseconds = () => this.units === 'milliseconds'

  start() {
    let { _time, _timeRange, units } = this;

    this._timeAtPlayStart = _time;
    this._tPlayStart = now(units);

    // TODOHI  if _currentRate < 0, check time against timeRange[0]
    if (_time < _timeRange[1]) {
      this._currentRate = this.rate;
    }
    this.notifyStartListeners();
    this.notifyListeners();
  }

  stop() {
    this._currentRate = 0;
    this.notifyListeners();
    this.notifyStopListeners();
  }

  togglePlay() {
    if (this._currentRate !== 0) {
      this.stop();
    } else {
      this.start();
    }
  }

  update() {
    let { _currentRate, _timeRange, _isLooping, units } = this;
    if (_currentRate === 0) {
      return;
    }
    // else _currentRate !== 0

    let elapsed = now(units) - this._tPlayStart;
    let newTime;
    let modTime;

    if (_isLooping) {
      let rangeInterval = _timeRange[1] - _timeRange[0];

      // elapsed = elapsed % (rangeInterval / _currentRate)

      let scaledElapsed = _currentRate * elapsed;

      modTime = this._timeAtPlayStart + scaledElapsed - _timeRange[0];

      if (modTime > rangeInterval) {
        modTime = modTime % rangeInterval;
        this._tPlayStart = now(units) - modTime;
      }

      newTime = _timeRange[0] + modTime;
    } else {
      newTime = this._timeAtPlayStart + _currentRate * elapsed;
    }

    this.time = this.clampTime(newTime);

    if (modTime && modTime <= 33) {
      this.notifyLoopListeners();
    }

    if (!_isLooping) {
      if (this.time <= _timeRange[0] || this._time >= _timeRange[1]) {
        this._currentRate = 0;
        this.notifyStopListeners();
      }
    }

    // if (this.time <= _timeRange[0] || this._time >= _timeRange[1]) {
    //     this._currentRate = 0
    // }

    // if (_currentRate > 0 && this._time >= _timeRange[1]) {
    //     this._currentRate = 0
    // } else if (_currentRate < 0 && this._time <= _timeRange[0]) {
    //     this._currentRate = 0
    // }

    // this.trace({tag: 'update', verbose: true})

    // this.notifyListeners()
  }

  trace({ tag = "", verbose = false }) {
    let {
      _currentRate,
      _timeRange,
      _isLooping,
      relativeTime,
      normalizedTime,
    } = this;
    let elapsed = now(this.units) - this._tPlayStart;

    if (verbose) {
      console.log(
        tag,
        "time",
        this.time,
        "_currentRate",
        _currentRate,
        "elapsed",
        elapsed,
        "_timeRange",
        _timeRange,
        "relativeTime",
        relativeTime,
        "normalizedTime",
        normalizedTime,
        "_isLooping",
        _isLooping
      );
    } else {
      console.log(
        tag,
        "timebase.update – time",
        this.time,
        "relativeTime",
        relativeTime,
        "normalizedTime",
        normalizedTime
      );
    }
  }
}
