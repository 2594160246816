import React from "react";
import { Swing } from "../Swing";

interface Props {
  useSwingStore: any;
  playData: any;
  playTracking: any;
  frame: any;
}

// SwingTrack mostly serves as a wrapper to Swing to handle the enabled flag and to bind Swing to a play
export const SwingTrack: React.FC<Props> = ({
  useSwingStore,
  playData,
  playTracking,
  frame,
}) => {
  // let enabled = useSwingStore(($: any) => $.swingTrackEnabled)
  //
  // if (!enabled) {
  //     return null
  // }

  let { frames } = playTracking.skeletalData;

  // keying by playId to ensure Swing and it's parts are flushed across plays
  return (
    <group>
      <Swing
        key={playData.playId}
        useSwingStore={useSwingStore}
        playData={playData}
        frames={frames}
        frame={frame}
      />
    </group>
  );
};
