import React from "react";

interface IPlayContextProps {
  playData?: any;
}

// TODOHI  display runner context
export const PlayContext = ({ playData }: IPlayContextProps) => {
  let { count } = playData.metaData.stat.play;
  // let {outs, runnerOn1b, runnerOn2b, runnerOn3b} = count

  let { balls, strikes, outs } = count;
  let countText = `${balls}-${strikes}`;
  let outText = `${outs} out`;

  return (
    <div>
      {countText} &nbsp;{outText}
    </div>
  );
};
