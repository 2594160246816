import React, { useEffect, useMemo, useRef } from "react";
import * as THREE from "three";
import { Sprite, Vector3 } from "three";
import { StatsApiNS } from "../../types";
import { positionIdMap } from "../../models/positions";
import { JointsMapType } from "./FigureGroup";
import {
  ColorManager,
  JerseyOptions,
} from "../../components/FigureModel/ColorManager";
import { useFrame, useThree } from "@react-three/fiber";

function makeLabelCanvas(
  baseWidth: number,
  size: number,
  positionId: number,
  teamOptions: JerseyOptions,
  playerInfo: StatsApiNS.Player
) {
  const ctx = document.createElement("canvas").getContext("2d");
  const font = `normal ${size}px PLAYBALL`;
  if (ctx) {
    ctx.font = font;

    const nameDisplayed = playerInfo.person.initLastName.toUpperCase();
    const positionDisplayed =
      positionIdMap[positionId].type !== "runner" &&
      positionIdMap[positionId].displayShort
        ? positionIdMap[positionId].displayShort
        : "";
    const positionLength = positionDisplayed
      ? ctx.measureText(positionDisplayed).width
      : 0;

    const width = baseWidth;
    const height = width / (500 / 75);
    const nameLengthAdjustment = width / 500;
    const borderSize = 20;
    ctx.canvas.width = width;
    ctx.canvas.height = height;

    const logoUrl = `https://www.mlbstatic.com/team-logos/team-cap-on-dark/${playerInfo.parentTeamId}.svg`;
    const logoImg = new Image();
    logoImg.crossOrigin = "anonymous";
    logoImg.onload = function () {
      const logoRatio = Math.min(60 / logoImg.width, 60 / logoImg.height);
      ctx.drawImage(
        logoImg,
        -width / 2 + (borderSize / 2) * nameLengthAdjustment,
        -height / 2 + (borderSize / 2) * nameLengthAdjustment,
        logoImg.width * logoRatio * nameLengthAdjustment,
        logoImg.height * logoRatio * nameLengthAdjustment
      );
    };
    logoImg.src = logoUrl;

    // need to set font again after resizing canvas
    ctx.font = font;
    ctx.textBaseline = "middle";
    ctx.textAlign = "left";

    ctx.fillStyle = teamOptions.cap;
    ctx.fillRect(0, 0, 80 * nameLengthAdjustment, height);

    ctx.fillStyle = teamOptions.jersey;
    ctx.fillRect(80 * nameLengthAdjustment, 0, width, height);

    ctx.translate(width / 2, height / 2);

    ctx.fillStyle = teamOptions.name.color;
    ctx.fillText(nameDisplayed, -width / 2 + 100 * nameLengthAdjustment, 10);

    if (positionDisplayed !== "") {
      ctx.lineWidth = 4;
      ctx.strokeStyle = teamOptions.name.color;
      ctx.moveTo(width / 2 - 100 * nameLengthAdjustment, -height / 2);
      ctx.lineTo(width / 2 - 100 * nameLengthAdjustment, height);
      ctx.stroke();
    }

    ctx.fillStyle = teamOptions.name.color;
    ctx.fillText(
      positionDisplayed || "",
      width / 2 - positionLength * nameLengthAdjustment - borderSize,
      10
    );

    return ctx.canvas;
  } else {
    console.warn("Name plate canvas context wasn't available!");
  }
}

interface NamePlateProps {
  joints: JointsMapType;
  positionId: number;
  playerInfo: StatsApiNS.Player;
  side: "home" | "away";
  colorManager: ColorManager;
  playerNamePlateScale: number;
}

const scaleVector = new Vector3();
const neckVector = new Vector3();
const cameraConversionVector = new Vector3();

export const NamePlate = ({
  joints,
  positionId,
  playerInfo,
  colorManager,
  side,
  playerNamePlateScale,
}: NamePlateProps) => {
  const ref = useRef<Sprite>();
  const { camera } = useThree();

  const texturedCanvas = useMemo(() => {
    if (playerInfo) {
      const teamOptions = colorManager.get(playerInfo.parentTeamId, side);
      const nameLength = playerInfo.person.initLastName.length;
      const canvas = makeLabelCanvas(
        nameLength * 72,
        64,
        positionId,
        teamOptions,
        playerInfo
      );
      return canvas ? new THREE.CanvasTexture(canvas) : null;
    }
  }, [playerInfo, positionId, colorManager, side]);

  useEffect(() => {
    // dispose of our THREE.CanvasTexture on cleanup
    return function cleanup() {
      texturedCanvas?.dispose();
    };
  }, [texturedCanvas]);

  useFrame(() => {
    if (texturedCanvas) {
      texturedCanvas.needsUpdate = true;
    }
    let neck = joints.Neck;

    if (!neck) {
      return null;
    }

    const [x, y, z] = neck;
    neckVector.set(...neck);
    const scaleFactor = playerNamePlateScale;
    const scale =
      scaleVector
        .subVectors(
          neckVector,
          cameraConversionVector.set(
            camera.position.x,
            -camera.position.z,
            camera.position.y
          )
        )
        .length() / scaleFactor;
    if (ref && ref.current) {
      ref.current.position.set(x, y, z + Math.max(2, (2 * scale) / 3));
      ref.current.scale.set(scale * 4, scale * 0.5, 1);
    }
  });

  return texturedCanvas ? (
    <sprite
      ref={ref}
      rotation={[1.5, 0, 0]} // 1.5 is about 90 degrees, which rotates the nameplate to face the camera
    >
      <spriteMaterial transparent opacity={1} map={texturedCanvas} />
    </sprite>
  ) : null;
};
