import React from "react";
import { Color, Object3D, Vector3 } from "three";
import { JointsMapType } from "../Figure";
import { Sphere } from "./HeadJoints";
import { ArrayPoint3, arrayPoint3Distance } from "../../util/geometry-util";
import { setVector3 } from "../../util/three-util";

export const hasStickHeadRequiredJoints = (jointsMap: JointsMapType) => {
  let { Neck, Nose, LEye, REye, LEar, REar } = jointsMap;

  return Neck && LEar && REar && ((LEye && REye) || Nose);
};

export const noseSpokeColor = new Color("#b02828");
export const eyeSpokeColor = new Color("#8d8d8d");

export const noseSpokeMaterial = (
  <meshStandardMaterial
    attach="material"
    color={noseSpokeColor}
    emissive={noseSpokeColor}
    roughness={0.2}
    metalness={1}
  />
);

export const eyeSpokeMaterial = (
  <meshStandardMaterial
    attach="material"
    color={eyeSpokeColor}
    emissive={eyeSpokeColor}
    roughness={0.2}
    metalness={1}
  />
);

const secondaryEarColor = new Color("#011954");
const secondaryEarMaterial = (
  <meshStandardMaterial
    attach="material"
    color={secondaryEarColor}
    emissive={secondaryEarColor}
    roughness={0.2}
    metalness={1}
  />
);

const secondaryEyeColor = new Color("#8d8d8d");
const secondaryEyeMaterial = (
  <meshStandardMaterial
    attach="material"
    color={secondaryEyeColor}
    emissive={secondaryEyeColor}
    roughness={0.2}
    metalness={1}
  />
);

const _vUp = new Vector3(0, 1, 0);
const _vP = new Vector3();
const _vQ = new Vector3();
const _vStem = new Vector3();
const _vMid = new Vector3();
const _vDir = new Vector3();

interface CylinderFromPTowardQArgs {
  p: ArrayPoint3;
  q: ArrayPoint3;
  len?: number;
  radius?: number;
  material: any;
}

/**
 * Creates a mesh with a cylinder of an arbitrarylength that starts at point p and is oriented toward point q.
 *
 * Could stand to be generalized to handle any geometry. Maybe also to calculate the math separately to share
 * vector and quaternion results.
 *
 * @param p – root point (ArrayPoint3)
 * @param q - direction point (ArrayPoint3)
 * @param len – how far to extend from p toward q
 * @param radius
 * @param material
 */
export const RayCylinder = ({
  p,
  q,
  len = 1,
  radius = 0.5,
  material,
}: CylinderFromPTowardQArgs) => {
  let update = (obj: Object3D) => {
    setVector3(_vP, p);
    setVector3(_vQ, q);
    _vStem.subVectors(_vQ, _vP).normalize();

    _vMid.addVectors(_vP, _vDir.copy(_vStem).multiplyScalar(0.5 * len));

    obj.quaternion.setFromUnitVectors(_vUp, _vStem);
    obj.position.copy(_vMid);
  };

  return (
    <mesh onUpdate={update}>
      <cylinderBufferGeometry
        attach="geometry"
        args={[radius, radius, len, 16]}
      />
      {material}
    </mesh>
  );
};

const StemRadius = 0.02;

interface StemProps {
  neck: any;
  midEar: any;
  material: any;
}

const Stem = ({ neck, midEar, material }: StemProps) => {
  let len = 1.3 * arrayPoint3Distance(neck, midEar);

  return (
    neck && (
      <RayCylinder
        p={neck}
        q={midEar}
        radius={StemRadius}
        len={len}
        material={material}
      />
    )
  );
};

interface EarSpanProps {
  LEar: ArrayPoint3;
  REar: ArrayPoint3;
}

const EarSpan = ({ LEar, REar }: EarSpanProps) => {
  let len = arrayPoint3Distance(LEar, REar);

  return (
    <RayCylinder
      p={LEar}
      q={REar}
      radius={0.5 * StemRadius}
      len={len}
      material={secondaryEarMaterial}
    />
  );
};

interface EyeSpanProps {
  LEye: ArrayPoint3;
  REye: ArrayPoint3;
}

const EyeSpan = ({ LEye, REye }: EyeSpanProps) => {
  let len = arrayPoint3Distance(LEye, REye);

  return (
    <RayCylinder
      p={LEye}
      q={REye}
      radius={0.5 * StemRadius}
      len={len}
      material={secondaryEyeMaterial}
    />
  );
};

interface SpokeProps {
  p1: ArrayPoint3;
  p2: ArrayPoint3;
  material: any;
}

const Spoke = ({ p1, p2, material }: SpokeProps) => {
  let len = arrayPoint3Distance(p1, p2);

  return (
    <RayCylinder
      p={p1}
      q={p2}
      radius={0.75 * StemRadius}
      len={len}
      material={material}
    />
  );
};

const defaultSpokeToNose = true; // false defaults to eyes when present

interface Props {
  jointsMap: JointsMapType;
  material: any;
}

// TODO    calculate head radius from ears distance, when both ears present
// TODO    calculate tilt, based on ears. maybe eyes or nose?
// TODO    add a hat brim for players?
export const StickHead = ({ jointsMap, material }: Props) => {
  let neck = jointsMap.Neck;
  let { Neck, Nose, LEye, REye, LEar, REar } = jointsMap;

  let hasEars = LEar && REar;
  let hasEyes = LEye && REye;

  if (!neck || !hasEars) {
    // can't calculate stem, and therefore the rest
    return null;
  }

  let midEar: ArrayPoint3 = [
    0.5 * (LEar[0] + REar[0]),
    0.5 * (LEar[1] + REar[1]),
    0.5 * (LEar[2] + REar[2]),
  ];

  let midEye: ArrayPoint3 | undefined = hasEyes && [
    0.5 * (LEye[0] + REye[0]),
    0.5 * (LEye[1] + REye[1]),
    0.5 * (LEye[2] + REye[2]),
  ];

  let p2;
  let spokeMaterial;

  if (defaultSpokeToNose) {
    p2 = Nose || midEye;
    spokeMaterial = Nose ? noseSpokeMaterial : eyeSpokeMaterial;
  } else {
    p2 = midEye || Nose;
    spokeMaterial = midEye ? eyeSpokeMaterial : noseSpokeMaterial;
  }

  return (
    <group>
      <Stem neck={Neck} midEar={midEar} material={material} />
      <Sphere
        position={midEar}
        radius={1.5 * StemRadius}
        material={secondaryEarMaterial}
      />
      {p2 && <Spoke p1={midEar} p2={p2} material={spokeMaterial} />}
      <EarSpan LEar={LEar} REar={REar} />
      {hasEyes && <EyeSpan LEye={LEye} REye={REye} />}
      {midEye && (
        <Sphere
          position={midEye}
          radius={1.5 * StemRadius}
          material={secondaryEyeMaterial}
        />
      )}
    </group>
  );
};
