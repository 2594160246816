import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { TabPanel } from "./Knobs";
import Divider from "@material-ui/core/Divider";
import { MaterialKnobs } from "./MaterialKnobs";

interface Props {
  value: any;
  index: number;
  useVenueStore: any;
  useFieldAppearanceStore: any;
}

export const FieldDesignKnobsPanel = (props: Props) => {
  let { index, value, /*useVenueStore,*/ useFieldAppearanceStore } = props;

  let grassMaterial = useFieldAppearanceStore(($: any) => $.grassMaterial);
  let setGrassMaterial = useFieldAppearanceStore(
    ($: any) => $.setGrassMaterial
  );

  let sandMaterial = useFieldAppearanceStore(($: any) => $.sandMaterial);
  let setSandMaterial = useFieldAppearanceStore(($: any) => $.setSandMaterial);

  return (
    <TabPanel value={value} index={index}>
      {/*Field Options*/}
      {/*<ul>*/}
      {/*    <li>colors</li>*/}
      {/*    <li>materials</li>*/}
      {/*</ul>*/}

      {/*<br/>*/}

      <FormControl component="fieldset">
        <MaterialKnobs
          title="Grass"
          params={grassMaterial}
          setParams={setGrassMaterial}
        />

        <br />
        <br />

        <Divider />

        <MaterialKnobs
          title="Sand"
          params={sandMaterial}
          setParams={setSandMaterial}
        />
      </FormControl>
    </TabPanel>
  );
};
