import create from "zustand";

export type SmoothingType = "hann" | "alpha" | "moving-average";

export const hannWindowSizeRange = [3, 121];
export const hannWindowSizeStep = 2;
export const alphaRange = [0.01, 1];
export const alphaStep = 0.01;
export const movingAverageRange = [1, 91];
export const movingAverageStep = 1;

export interface POVCameraStore {
  positionId: number;

  setPositionId(positionId: number): void;

  tiltDegrees: number;

  setTiltDegrees(tiltDegrees: number): void;

  smoothingEnabled: boolean;

  setSmoothingEnabled(smoothingEnabled: boolean): void;

  smoothingType: SmoothingType;

  setSmoothingType(smoothingType: SmoothingType): void;

  hannWindowSize: number;

  setHannWindowSize(hannWindowSize: number): void;

  alpha: number;

  setAlpha(alpha: number): void;

  movingAverageWindowSize: number;

  setMovingAverageWindowSize(movingAverageWindowSize: number): void;

  ballDirectionMix: number;

  setBallDirectionMix(ballDirectionMix: number): void;

  backOffset: number;

  setBackOffset(backOffset: number): void;

  verticalOffset: number;

  setVerticalOffset(verticalOffset: number): void;

  horizontalOffset: number;

  setHorizontalOffset(horizontalOffset: number): void;
}

export const createPOVCameraStore = () =>
  create<POVCameraStore>((set) => ({
    positionId: 1,
    setPositionId: (positionId: number) => set({ positionId }),

    tiltDegrees: 23,
    setTiltDegrees: (tiltDegrees: number) => set({ tiltDegrees }),

    smoothingEnabled: true,
    setSmoothingEnabled: (smoothingEnabled: boolean) =>
      set({ smoothingEnabled }),

    smoothingType: "hann",
    setSmoothingType: (smoothingType: SmoothingType) => set({ smoothingType }),

    hannWindowSize: 31, // must be an odd integer
    setHannWindowSize: (hannWindowSize: number) => set({ hannWindowSize }),

    alpha: 0.95,
    setAlpha: (alpha: number) => set({ alpha }),

    movingAverageWindowSize: 31,
    setMovingAverageWindowSize: (movingAverageWindowSize: number) =>
      set({ movingAverageWindowSize }),

    ballDirectionMix: 0,

    setBallDirectionMix: (ballDirectionMix: number) =>
      set({ ballDirectionMix }),

    backOffset: 0,

    setBackOffset: (backOffset: number) => set({ backOffset }),

    verticalOffset: 0,

    setVerticalOffset: (verticalOffset: number) => set({ verticalOffset }),

    horizontalOffset: 0,

    setHorizontalOffset: (horizontalOffset: number) =>
      set({ horizontalOffset }),
  }));
