import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { TabPanel } from "./Knobs";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import Divider from "@material-ui/core/Divider";
import { ColorPicker } from "../ColorPicker";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface AmbientKnobsProps {
  useLightingStore: any;
  classes: any;
}

const AmbientKnobs = ({ useLightingStore, classes }: AmbientKnobsProps) => {
  let params = useLightingStore(($: any) => $.ambientLightParams);
  let setParams = useLightingStore(($: any) => $.setAmbientLightParams);
  let { color, intensity } = params;

  let handleColorChange = (color: string) => {
    setParams({ ...params, color });
  };

  let handleIntensityChange = (event: any, value: any) => {
    setParams({ ...params, intensity: value });
  };

  return (
    <FormControl component="fieldset">
      Ambient Light Color
      <ColorPicker color={color} onChange={handleColorChange} />
      <br />
      <div className={classes.sliderRoot}>
        Ambient Light Intensity
        <Slider
          min={0}
          max={1}
          value={intensity}
          aria-labelledby="intensity-slider"
          step={0.01}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleIntensityChange}
        />
        {/*<FormHelperText>- speed ➔ CCW<br/> + speed ➔ CW</FormHelperText>*/}
      </div>
      {/*<FormHelperText>needs tuning</FormHelperText>*/}
    </FormControl>
  );
};

interface DirectionalKnobsProps {
  useLightingStore: any;
  classes: any;
}

const DirectionalKnobs = ({
  useLightingStore,
  classes,
}: DirectionalKnobsProps) => {
  let params = useLightingStore(($: any) => $.directionalLightParams);
  let setParams = useLightingStore(($: any) => $.setDirectionalLightParams);
  let { color, intensity, position } = params;

  let handleColorChange = (color: string) => {
    setParams({ ...params, color });
  };

  let handleIntensityChange = (event: any, value: any) => {
    setParams({ ...params, intensity: value });
  };

  let handleXPositionChange = (event: any, value: any) => {
    setParams({ ...params, position: [value, position[1], position[2]] });
  };

  let handleYPositionChange = (event: any, value: any) => {
    setParams({ ...params, position: [position[0], position[1], -value] });
  };

  let handleHeightChange = (event: any, value: any) => {
    setParams({ ...params, position: [position[0], value, position[2]] });
  };

  return (
    <FormControl component="fieldset">
      Directional Light Color
      <ColorPicker color={color} onChange={handleColorChange} />
      <br />
      <div className={classes.sliderRoot}>
        Directional Light Intensity
        <Slider
          min={0}
          max={1}
          value={intensity}
          aria-labelledby="intensity-slider"
          step={0.01}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleIntensityChange}
        />
        {/*<FormHelperText>- speed ➔ CCW<br/> + speed ➔ CW</FormHelperText>*/}
      </div>
      <br />
      <h4>Directional Light Position (Statcast coordinates)</h4>
      <i>eventually, will use an x-y D-pad control here</i>
      <br />
      <div className={classes.sliderRoot}>
        X
        <Slider
          min={-500}
          max={500}
          value={position[0]}
          aria-labelledby="directional-light-position-x-slider"
          step={1}
          valueLabelDisplay="auto"
          onChange={handleXPositionChange}
        />
      </div>
      <br />
      <div className={classes.sliderRoot}>
        Y
        <Slider
          min={-500}
          max={500}
          step={1}
          value={-position[2]}
          aria-labelledby="directional-light-position-y-slider"
          valueLabelDisplay="auto"
          onChange={handleYPositionChange}
        />
      </div>
      <br />
      <div className={classes.sliderRoot}>
        Directional Light Height
        <Slider
          min={1}
          max={500}
          step={1}
          value={position[1]}
          aria-labelledby="directional-light-position-height-slider"
          valueLabelDisplay="auto"
          onChange={handleHeightChange}
        />
      </div>
    </FormControl>
  );
};

interface Props {
  value: any;
  index: number;
  useLightingStore: any;
}

export const LightingKnobsPanel = (props: Props) => {
  let { index, value, useLightingStore } = props;
  let shadowsEnabled = useLightingStore(($: any) => $.shadowsEnabled);
  let setShadowsEnabled = useLightingStore(($: any) => $.setShadowsEnabled);
  let skyEnabled = useLightingStore(($: any) => $.skyEnabled);
  let setSkyEnabled = useLightingStore(($: any) => $.setSkyEnabled);
  let glClearColor = useLightingStore(($: any) => $.glClearColor);
  let setGlClearColor = useLightingStore(($: any) => $.setGlClearColor);

  const classes = useStyles();

  return (
    <TabPanel value={value} index={index}>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Switch
              checked={shadowsEnabled}
              onChange={() => setShadowsEnabled(!shadowsEnabled)}
              name="ball-segments"
              // color='primary'
              color="secondary"
              size="small"
            />
          }
          label="Shadows"
        />
        <FormHelperText>needs tuning</FormHelperText>
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={skyEnabled}
              onChange={() => setSkyEnabled(!skyEnabled)}
              name="ball-segments"
              // color='primary'
              color="secondary"
              size="small"
            />
          }
          label="Sky"
        />
        <br />
        Background Color
        <ColorPicker color={glClearColor} onChange={setGlClearColor} />
        <br />
        <Divider />
        <br />
        <br />
        <AmbientKnobs useLightingStore={useLightingStore} classes={classes} />
        <br />
        <Divider />
        <br />
        <br />
        <DirectionalKnobs
          useLightingStore={useLightingStore}
          classes={classes}
        />
      </FormControl>
    </TabPanel>
  );
};
