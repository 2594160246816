import React, { useState } from "react";
import { PlayList } from "../PlayList";
import { StatsAPIPlayPicker } from "../StatsAPIPlayPicker";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { TabPanel } from "../Knobs";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { styled } from "@material-ui/core/styles";

const MyTab = styled(Tab)({
  // backgroundColor: '#fff',
  // color: '#333',
  minWidth: 60,
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const stopgapTabValue = 0;
const sapiTabValue = 1;

interface PlayPickerDialogProps {
  // gamePk?: string,
  // playId?: string,
  onClose: any;
  open: boolean;
  onPlaySelected: (gamePk: string, playId: string) => void;
  // onGameSelected: (gamePk: string) => void,
}

export const PlayPicker: React.FC<PlayPickerDialogProps> = (props) => {
  let {
    // gamePk,
    // playId,
    onClose,
    open,
    onPlaySelected,
  } = props;

  let [showStatsAPI, setShowStatsAPI] = useState(true);
  let handleChange = (event: any, newValue: number) => {
    setShowStatsAPI(newValue === sapiTabValue);
  };

  const handleClose = () => {
    // onClose(selectedValue)
    onClose && onClose();
  };

  let value = showStatsAPI ? sapiTabValue : stopgapTabValue;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Pick a play</DialogTitle>
      <DialogContent dividers>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="main knobs tabs"
        >
          <MyTab label="Select Plays" {...a11yProps(stopgapTabValue)} />
          <MyTab label="Calendar" {...a11yProps(sapiTabValue)} />
        </Tabs>

        <TabPanel value={value} index={stopgapTabValue}>
          <PlayList onPlaySelected={onPlaySelected} />
        </TabPanel>

        <TabPanel value={value} index={sapiTabValue}>
          <StatsAPIPlayPicker onPlaySelected={onPlaySelected} />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};
