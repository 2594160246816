import React from "react";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";

import { playerPositions } from "field-of-things/src/constants/play";
import {
  gammaWrap,
  thetaLims,
  deltaDist,
  minDist,
} from "field-of-things/src/components/GameCam"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  offsetIntervalSliderRoot: {
    width: 200,
  },
}));

interface FigureSpinCamKnobsProps {
  useCameraStore: any;
}

export const FigureSpinCamKnobs = ({
  useCameraStore,
}: FigureSpinCamKnobsProps) => {
  let classes = useStyles();
  let {
    gameCamParams,
    setGameCamParams,
  } = useCameraStore();

  let {
    positionId,
    distance,
    gamma,
    theta,
    alphaXY,
    alphaZ,
    panOffsetX,
    panOffsetY,
  } = gameCamParams;

  // TODO: work out if there's a nicer way to curry in ts
  const handleParamChange = (key: string, value: any) => {
    setGameCamParams({ ...gameCamParams, [key]: value });
  };

  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="position-id">Figure to Follow</InputLabel>
        <NativeSelect
          value={positionId}
          onChange={
            (event: any) => handleParamChange("positionId", +event.target.value)
          }
          inputProps={{
            name: "position-id",
            id: "position-id",
          }}
        >
          {playerPositions.map((p: any) => (
            <option key={p.positionId} value={p.positionId}>
              {p.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>

      <br />
      <br />
      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Cam Distance from Figure (ft)
        <Slider
          min={minDist}
          max={50}
          value={distance}
          aria-labelledby="camera-distance-slider"
          step={deltaDist}
          valueLabelDisplay="auto"
          onChange={
            (event: any, value: any) => handleParamChange("distance", value)
          }
        />
      </div>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Yaw (deg)
        <Slider
          min={0}
          max={gammaWrap}
          value={gamma}
          aria-labelledby="camera-gamma-slider"
          valueLabelDisplay="auto"
          onChange={
            (event: any, value: any) => handleParamChange("gamma", value)
          }
        />
      </div>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Pitch (deg)
        <Slider
          min={thetaLims.min}
          max={thetaLims.max}
          value={theta}
          aria-labelledby="camera-theta-slider"
          valueLabelDisplay="auto"
          onChange={
            (event: any, value: any) => handleParamChange("theta", value)
          }
        />
      </div>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        XY filter alpha
        <Slider
          min={0}
          max={1}
          value={alphaXY}
          aria-labelledby="camera-alphaXY-slider"
          step={0.01}
          valueLabelDisplay="auto"
          onChange={
            (event: any, value: any) => handleParamChange("alphaXY", value)
          }
        />
        <FormHelperText>0: keep inital value, 1: no filter </FormHelperText>
      </div>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Z filter alpha
        <Slider
          min={0}
          max={1}
          value={alphaZ}
          aria-labelledby="camera-alphaZ-slider"
          step={0.01}
          valueLabelDisplay="auto"
          onChange={
            (event: any, value: any) => handleParamChange("alphaZ", value)
          }
        />
        <FormHelperText>0: keep inital value, 1: no filter </FormHelperText>
      </div>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Pan X
        <Slider
          min={-20}
          max={20}
          value={panOffsetX}
          aria-labelledby="camera-panX-slider"
          step={0.5}
          valueLabelDisplay="auto"
          onChange={
            (event: any, value: any) => handleParamChange("panOffsetX", value)
          }
        />
      </div>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Pan Y
        <Slider
          min={-20}
          max={5}
          value={panOffsetY}
          aria-labelledby="camera-panY-slider"
          step={0.5}
          valueLabelDisplay="auto"
          onChange={
            (event: any, value: any) => handleParamChange("panOffsetY", value)
          }
        />
      </div>

      <br />
    </div>
  );
};
