import React, { useMemo } from "react";
import { BatTrackGroup } from "./BatTrackGroup";
import { WristTrackGroup } from "./WristTrackGroup";
import { GripTrackGroup } from "./GripTrackGroup";
import { BatAtTime } from "./BatAtTime";
import { toSwingSeriesSet } from "./swing-util";
import { toReleaseTime } from "../../util/time-util";

const animatedBatCylinderRadius = 0.06;

interface Props {
  playData: any;
  useSwingStore: any;
  frames: any;
  frame: any;
}

// TODO    any significant efficiency to gain by sharing calculations in toSwingEndTime here and downstream?
// TODO    e.g., share wrist joint series and velocities

export const Swing: React.FC<Props> = ({
  playData,
  useSwingStore,
  frames,
  frame,
}) => {
  let releaseTime = toReleaseTime({ playData });
  let swingTrackEnabled = useSwingStore(($: any) => $.swingTrackEnabled);
  let gripsEnabled = useSwingStore(($: any) => $.gripsEnabled);
  let batAnimationEnabled = useSwingStore(($: any) => $.batAnimationEnabled);

  let capSwingEnabled = useSwingStore(($: any) => $.capSwingEnabled);
  let wristSeparationSwingEndEnabled = useSwingStore(
    ($: any) => $.wristSeparationSwingEndEnabled
  );
  let wristGripExtension = useSwingStore(($: any) => $.wristGripExtension);

  let { wristSeries, gripSeries, batSeries } = toSwingSeriesSet({
    playData,
    frames,
    gripsEnabled,
    capSwingEnabled,
    wristSeparationSwingEndEnabled,
    wristGripExtension,
  });

  let wristTrackGroup = useMemo(
    () =>
      swingTrackEnabled && (
        <WristTrackGroup
          wristSeries={wristSeries}
          time={frame.time}
          useSwingStore={useSwingStore}
        />
      ),
    [swingTrackEnabled, wristSeries, useSwingStore, frame]
  );

  let gripTrackGroup = useMemo(
    () =>
      swingTrackEnabled &&
      gripsEnabled && (
        <GripTrackGroup
          jointSeries={gripSeries}
          useSwingStore={useSwingStore}
        />
      ),
    [swingTrackEnabled, gripsEnabled, gripSeries, useSwingStore]
  );

  let batTrackGroup = useMemo(
    () =>
      swingTrackEnabled && (
        <BatTrackGroup
          batSeries={batSeries}
          releaseTime={releaseTime}
          useSwingStore={useSwingStore}
        />
      ),
    [swingTrackEnabled, batSeries, releaseTime, useSwingStore]
  );

  let animatedBat = useMemo(
    () =>
      batAnimationEnabled && (
        <BatAtTime
          key={frame.time}
          batSeries={batSeries}
          time={frame.time}
          radius={animatedBatCylinderRadius}
        />
      ),
    [batAnimationEnabled, batSeries, frame.time]
  );

  let swingTrack = useMemo(() => {
    return (
      swingTrackEnabled && (
        <group>
          {wristTrackGroup}
          {gripTrackGroup}
          {batTrackGroup}
        </group>
      )
    );
  }, [swingTrackEnabled, batTrackGroup, gripTrackGroup, wristTrackGroup]);

  return (
    <group>
      {swingTrack}

      {animatedBat}
    </group>
  );
};
