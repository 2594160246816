import React, { useEffect, useState } from "react";
import * as THREE from "three";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

interface Props {
  fieldModelUrl: string;
  fallbackFieldModelUrl: string;
  visible: boolean;
  onProgress(progress: number | undefined): void;
}

const loader = new GLTFLoader();

const scale = 3.28; // aligns bases, but not the rubber

const manipulateVenueModel = (
  model: GLTF,
  setModel: React.Dispatch<React.SetStateAction<GLTF | undefined>>,
  onProgress: (progress: number | undefined) => void
) => {
  // Rotate venue so it matches our coordinate system
  model.scene.setRotationFromEuler(
    new THREE.Euler(Math.PI / 2, Math.PI, 0, "XYZ")
  );
  // Scale it up (trial and error got me to here)
  model.scene.scale.set(scale, scale, scale);

  model.scene.traverse((obj: any) => {
    if (obj.hasOwnProperty("receiveShadow")) {
      obj.receiveShadow = true;
    }
    if (obj.isMesh) {
      obj.material.dispose();
      let mat = new THREE.MeshLambertMaterial({
        map: obj.material.map,
      });
      obj.material = mat;
    }
  });
  setModel(model);
  onProgress(undefined);
};

export const GLTFField = ({
  fieldModelUrl,
  fallbackFieldModelUrl,
  visible,
  onProgress,
}: Props) => {
  const [model, setModel] = useState<GLTF>();
  const [modelUrl, setModelUrl] = useState<string>();

  useEffect(() => {
    if (visible && modelUrl !== fieldModelUrl) {
      let contentLength = 0;

      fetch(fieldModelUrl, { method: "HEAD" }).then((response) => {
        const contentLengthHeader = response.headers.get("Content-Length");
        if (contentLengthHeader !== null) {
          contentLength = parseInt(contentLengthHeader, 10);
        }
      });
      setModelUrl(fieldModelUrl);
      loader.load(
        fieldModelUrl,
        (model) => {
          manipulateVenueModel(model, setModel, onProgress);
        },
        (xhr) => {
          if (xhr.lengthComputable) {
            onProgress(xhr.loaded / xhr.total);
          } else if (contentLength !== 0) {
            onProgress(xhr.loaded / contentLength);
          }
        },
        // Above venue failed? Load fallback venue
        () => {
          loader.load(
            fallbackFieldModelUrl,
            (model) => {
              manipulateVenueModel(model, setModel, onProgress);
            },
            (xhr) => {
              if (xhr.lengthComputable) {
                onProgress(xhr.loaded / xhr.total);
              } else if (contentLength !== 0) {
                onProgress(xhr.loaded / contentLength);
              }
            }
          );
        }
      );
    }
  }, [
    visible,
    fieldModelUrl,
    model,
    modelUrl,
    onProgress,
    fallbackFieldModelUrl,
  ]);

  return model ? <primitive visible={visible} object={model.scene} /> : null;
};
