import React from "react";
import { QUARTER_PI } from "../../constants/math";
import { baseColor } from "../../constants/colors";

/*
    plate-side corner of 1B: 62.756, 62.756
    plate-side corner of 3B: -62.756, 62.756
    for center of 2B: 0, 127.279
    for center of plate-side of rubber: 0, 60.5
 */

// see 2019 rule book page 158
const baseDimension = 15 / 12; // 15" square
const halfBaseDimension = 0.5 * baseDimension;
// baseOuterRadius: center of base to corner
const baseOuterRadius = Math.sqrt(2 * halfBaseDimension * halfBaseDimension);
const baseThickness = 4 / 12; // rule book base thickness: 3" to 5'
const baseCenterZ = 0.5 * baseThickness;
const diamondOuterRadius = 90 / Math.sqrt(2); // length of sides where where hypotenuse = 90'
const emissiveIntensity = 0.4;
const baseTransparent = false; // temporarily toggle true when comparing with field model
const baseOpacity = 0.5;
const secondBaseCenterY = 127.2813;

export const Bases = () => {
  let pos1B: [number, number, number] = [
    diamondOuterRadius - baseOuterRadius,
    diamondOuterRadius,
    baseCenterZ,
  ];
  let pos2B: [number, number, number] = [0, secondBaseCenterY, baseCenterZ];
  let pos3B: [number, number, number] = [
    -diamondOuterRadius + baseOuterRadius,
    diamondOuterRadius,
    baseCenterZ,
  ];

  return (
    <group name="bases">
      <mesh
        receiveShadow
        name="1B"
        position={pos1B}
        rotation={[0, 0, QUARTER_PI]}
      >
        <boxBufferGeometry
          attach="geometry"
          args={[baseDimension, baseDimension, baseThickness]}
        />
        <meshStandardMaterial
          attach="material"
          color={baseColor}
          // @ts-ignore
          emissive={baseColor}
          emissiveIntensity={emissiveIntensity}
          transparent={baseTransparent}
          opacity={baseOpacity}
        />
      </mesh>
      <mesh
        receiveShadow
        name="2B"
        position={pos2B}
        rotation={[0, 0, QUARTER_PI]}
      >
        <boxBufferGeometry
          attach="geometry"
          args={[baseDimension, baseDimension, baseThickness]}
        />
        <meshStandardMaterial
          attach="material"
          color={baseColor}
          // @ts-ignore
          emissive={baseColor}
          emissiveIntensity={emissiveIntensity}
          transparent={baseTransparent}
          opacity={baseOpacity}
        />
      </mesh>
      <mesh
        receiveShadow
        name="3B"
        position={pos3B}
        rotation={[0, 0, QUARTER_PI]}
      >
        <boxBufferGeometry
          attach="geometry"
          args={[baseDimension, baseDimension, baseThickness]}
        />
        <meshStandardMaterial
          attach="material"
          color={baseColor}
          // @ts-ignore
          emissive={baseColor}
          emissiveIntensity={emissiveIntensity}
          transparent={baseTransparent}
          opacity={baseOpacity}
        />
      </mesh>
    </group>
  );
};
