import React, { useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import { TabPanel } from "./Knobs";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import { LabIcon } from "../icons/LabIcon";

interface Props {
  value: any;
  index: number;
  useFigureModelStore: any;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "block",
    paddingBottom: 15,
  },
}));

export const FigureModelKnobsPanel = (props: Props) => {
  let { index, value, useFigureModelStore } = props;

  const {
    figureModelsEnabled,
    setFigureModelsEnabled,
    figureModelOpacity,
    setFigureModelOpacity,
    figureModelScale,
    setFigureModelScale,
    setFigureModelOverrideUrl,
    figureModelVerticalOffset,
    setFigureModelVerticalOffset,
    torsoTwistEnabled,
    setTorsoTwistEnabled,
    stretchEnabled,
    setStretchEnabled,
    wireframeEnabled,
    setWireframeEnabled,
    headTiltEnabled,
    setHeadTiltEnabled,
    headTurnEnabled,
    setHeadTurnEnabled,
    headTurnDiagnosticsVizEnabled,
    setHeadTurnDiagnosticsVizEnabled,
    headTurnFirstEnabled,
    setHeadTurnFirstEnabled,
  } = useFigureModelStore();

  let handleModelScaleChange = (event: any, value: any) => {
    setFigureModelScale(value);
  };

  let handleVerticalOffsetChange = (event: any, value: any) => {
    setFigureModelVerticalOffset(value);
  };

  let handleModelOpacityChange = (event: any, value: any) => {
    setFigureModelOpacity(value);
  };

  const fileRef = useRef<HTMLInputElement>(null);

  const classes = useStyles();

  return (
    <TabPanel value={value} index={index}>
      <FormControl component="fieldset">
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                checked={figureModelsEnabled}
                onChange={() => setFigureModelsEnabled(!figureModelsEnabled)}
                name="figure-models"
                // color='primary'
                color="secondary"
                size="small"
              />
            }
            label="Figure Models"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <input
            ref={fileRef}
            type="file"
            onInput={() => {
              if (fileRef.current && fileRef.current.files) {
                const file = fileRef.current.files[0];
                const url = file ? URL.createObjectURL(file) : "";
                setFigureModelOverrideUrl(url);
              }
            }}
            style={{ height: 20 }}
          />
          <FormHelperText>
            Can also drop .glb files onto the <i>Choose File</i> button
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formControl}>
          Scale
          <Slider
            min={0.0}
            max={10}
            step={0.001}
            value={figureModelScale}
            onChange={handleModelScaleChange}
            valueLabelDisplay="auto"
            aria-labelledby="figure-model-scale"
          />
          <div>
            Vertical Offset
            <Slider
              min={-10}
              max={10}
              step={0.01}
              value={figureModelVerticalOffset}
              onChange={handleVerticalOffsetChange}
              valueLabelDisplay="auto"
              aria-labelledby="figure-model-vertical-offset"
            />
          </div>
          <div>
            Opacity
            <Slider
              min={0}
              max={1}
              step={0.01}
              value={figureModelOpacity}
              onChange={handleModelOpacityChange}
              valueLabelDisplay="auto"
              aria-labelledby="figure-model-opacity"
            />
          </div>
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                checked={torsoTwistEnabled}
                onChange={() => setTorsoTwistEnabled(!torsoTwistEnabled)}
                name="figure-model-torso-twist"
                color="secondary"
                size="small"
              />
            }
            label="Torso Twist"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                checked={headTiltEnabled}
                onChange={() => setHeadTiltEnabled(!headTiltEnabled)}
                name="figure-model-head-tilt"
                color="secondary"
                size="small"
              />
            }
            label="Head Tilt"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                checked={headTurnEnabled}
                onChange={() => setHeadTurnEnabled(!headTurnEnabled)}
                name="figure-model-head-turn"
                color="secondary"
                size="small"
              />
            }
            label="Head Turn"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                checked={headTurnFirstEnabled}
                onChange={() => setHeadTurnFirstEnabled(!headTurnFirstEnabled)}
                name="calculate-head-turn-first"
                color="secondary"
                size="small"
              />
            }
            label="Calculate Head Turn Before Torso Twist"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                disabled={!headTurnEnabled}
                checked={headTurnDiagnosticsVizEnabled}
                onChange={() =>
                  setHeadTurnDiagnosticsVizEnabled(
                    !headTurnDiagnosticsVizEnabled
                  )
                }
                name="figure-model-head-turn-diagnostics-viz"
                color="secondary"
                size="small"
              />
            }
            label="Head Turn Viz"
          />
          <FormHelperText>Diagnostic visuals of head-turn math</FormHelperText>
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                checked={stretchEnabled}
                onChange={() => setStretchEnabled(!stretchEnabled)}
                name="figure-model-stretch"
                color="secondary"
                size="small"
              />
            }
            label="Stretch bones to match tracking"
          />
          <FormHelperText>
            <LabIcon /> &nbsp; Work in Progress
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                checked={wireframeEnabled}
                onChange={() => setWireframeEnabled(!wireframeEnabled)}
                name="figure-model-wireframe"
                color="secondary"
                size="small"
              />
            }
            label="Wireframe"
          />
        </FormControl>
      </FormControl>
    </TabPanel>
  );
};
