import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";

const targetOptions = [
  {
    name: "Mid-Pitch",
    value: [0, 5, -30],
  },
  {
    name: "Mound",
    value: [0, 5, -60.5],
  },
  {
    name: "Plate",
    value: [0, 5, 0],
  },
  {
    name: "1st Base",
    value: [63.64, 5, -63.64],
  },
  {
    name: "2nd Base",
    value: [0, 5, -127.28],
  },
  {
    name: "3rd Base",
    value: [-63.64, 5, -63.64],
  },
  {
    name: "Shallow RF",
    value: [83, 5, -184],
  },
  {
    name: "Shallow CF",
    value: [0, 5, -225],
  },
  {
    name: "Shallow LF",
    value: [-83, 5, -184],
  },
];

const playerOptions = [
  {
    name: "Pitcher",
    value: 1,
  },
  {
    name: "Catcher",
    value: 2,
  },
  {
    name: "1st Baseman",
    value: 3,
  },
  {
    name: "2nd Baseman",
    value: 4,
  },
  {
    name: "3rd Baseman",
    value: 5,
  },
  {
    name: "Shortstop",
    value: 6,
  },
  {
    name: "Left Fielder",
    value: 7,
  },
  {
    name: "Center Fielder",
    value: 8,
  },
  {
    name: "Right Fielder",
    value: 9,
  },
  {
    name: "Batter",
    value: 10,
  },
  {
    name: "Runner on 1st",
    value: 11,
  },
  {
    name: "Runner on 2nd",
    value: 12,
  },
  {
    name: "Runner on 3rd",
    value: 13,
  },
];

// nameForTargetValue is necessary for decoupling from cameraStore so that cameraStore can take arbitrary targets
const nameForTargetValue = (value: number | number[]) => {
  if (typeof value === "object") {
    return targetOptions.find(
      (opt) =>
        opt.value[0] === value[0] &&
        opt.value[1] === value[1] &&
        opt.value[2] === value[2]
    );
  } else if (typeof value === "number") {
    return playerOptions.find((opt) => opt.value === value);
  }
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  sliderRoot: {
    width: 200,
  },
}));

interface OrbitControlsKnobsProps {
  useCameraStore: any;
}

export const OrbitControlsKnobs = ({
  useCameraStore,
}: OrbitControlsKnobsProps) => {
  let classes = useStyles();
  let orbitControlsParams = useCameraStore(($: any) => $.orbitControlsParams);
  let { autoRotate, autoRotateSpeed, target, positionId } = orbitControlsParams;
  let setOrbitControlsParams = useCameraStore(
    ($: any) => $.setOrbitControlsParams
  );
  let targetName = nameForTargetValue(target || positionId)?.name;

  let orbitTargetOptions = targetOptions
    .map((v: any) => (
      <option key={v.name} value={v.name}>
        {v.name}
      </option>
    ))
    .concat(
      playerOptions.map((v: any) => (
        <option key={v.name} value={v.name}>
          {v.name}
        </option>
      ))
    );

  let toggleOrbitControls = () => {
    setOrbitControlsParams({ ...orbitControlsParams, autoRotate: !autoRotate });
  };
  let handleAutoRotateSpeedChange = (event: any, value: any) => {
    setOrbitControlsParams({ ...orbitControlsParams, autoRotateSpeed: value });
  };
  let handleTargetChange = (event: any) => {
    let name = event.target.value;
    let target = targetOptions.find((opt) => opt.name === name)?.value;

    let positionId = playerOptions.find((opt) => opt.name === name)?.value;

    setOrbitControlsParams({ ...orbitControlsParams, target, positionId });
  };

  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="venue-select">Rotation Center</InputLabel>
        <NativeSelect
          // disabled={!autoRotate}
          value={targetName}
          onChange={handleTargetChange}
          inputProps={{
            name: "venue-select",
            id: "venue-select",
          }}
        >
          {orbitTargetOptions}
        </NativeSelect>
      </FormControl>

      <br />
      <br />

      <FormControlLabel
        control={
          <Switch
            checked={autoRotate}
            onChange={toggleOrbitControls}
            name="orbit-controls-switch"
            // color='primary'
            color="secondary"
            size="small"
          />
        }
        label="Auto-Rotate"
      />
      {/*<FormHelperText>Merged Positional Data</FormHelperText>*/}

      <br />
      <br />
      <br />

      <div className={classes.sliderRoot}>
        Rotation Speed (rpm)
        <Slider
          disabled={!autoRotate}
          min={-10}
          max={10}
          value={autoRotateSpeed}
          aria-labelledby="auto-rotate-speed"
          step={0.1}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleAutoRotateSpeedChange}
        />
        <FormHelperText>
          - speed ➔ CCW
          <br /> + speed ➔ CW
        </FormHelperText>
      </div>

      <br />
    </div>
  );
};
