type PositionType = "defense" | "runner" | "umpire" | "coach" | "fieldObject";

export interface EntityPosition {
  label: string;
  labelShort: string;
  displayShort?: string;
  id: string;
  type: PositionType;
  typ?: number;
}

export const allPositions: Array<EntityPosition> = [
  {
    label: "Pitcher",
    labelShort: "1",
    displayShort: "P",
    id: "1",
    type: "defense",
    typ: 1,
  },
  {
    label: "Catcher",
    labelShort: "2",
    displayShort: "C",
    id: "2",
    type: "defense",
    typ: 1,
  },
  {
    label: "1st Base",
    labelShort: "3",
    displayShort: "1B",
    id: "3",
    type: "defense",
    typ: 1,
  },
  {
    label: "2nd Base",
    labelShort: "4",
    displayShort: "2B",
    id: "4",
    type: "defense",
    typ: 1,
  },
  {
    label: "3rd Base",
    labelShort: "5",
    displayShort: "3B",
    id: "5",
    type: "defense",
    typ: 1,
  },
  {
    label: "Shortstop",
    labelShort: "6",
    displayShort: "SS",
    id: "6",
    type: "defense",
    typ: 1,
  },
  {
    label: "Left Field",
    labelShort: "7",
    displayShort: "LF",
    id: "7",
    type: "defense",
    typ: 1,
  },
  {
    label: "Center Field",
    labelShort: "8",
    displayShort: "CF",
    id: "8",
    type: "defense",
    typ: 1,
  },
  {
    label: "Right Field",
    labelShort: "9",
    displayShort: "RF",
    id: "9",
    type: "defense",
    typ: 1,
  },
  { label: "Batter", displayShort: "Batter", labelShort: "B", id: "10", type: "runner", typ: 1 },
  {
    label: "1st Base Runner",
    displayShort: "Runner on 1st",
    labelShort: "R1",
    id: "11",
    type: "runner",
    typ: 1,
  },
  {
    label: "2nd Base Runner",
    displayShort: "Runner on 2nd",
    labelShort: "R2",
    id: "12",
    type: "runner",
    typ: 1,
  },
  {
    label: "3rd Base Runner",
    displayShort: "Runner on 3rd",
    labelShort: "R3",
    id: "13",
    type: "runner",
    typ: 1,
  },
  { label: "Plate Umpire", labelShort: "PU", id: "14", type: "umpire", typ: 2 },
  { label: "1B Umpire", labelShort: "U1", id: "15", type: "umpire", typ: 2 },
  { label: "2B Umpire", labelShort: "U2", id: "16", type: "umpire", typ: 2 },
  { label: "3B Umpire", labelShort: "U3", id: "17", type: "umpire", typ: 2 },
  { label: "LF Umpire", labelShort: "LU", id: "20", type: "umpire", typ: 2 },
  { label: "RF Umpire", labelShort: "RU", id: "21", type: "umpire", typ: 2 },
  { label: "1B Coach", labelShort: "C1", id: "18", type: "coach", typ: 3 },
  { label: "3B Coach", labelShort: "C3", id: "19", type: "coach", typ: 3 },
];

export const fieldObjects: Array<EntityPosition> = [
  { label: "1st Base", labelShort: "1B", id: "0", type: "fieldObject" },
  { label: "2nd Base", labelShort: "2B", id: "0", type: "fieldObject" },
  { label: "3rd Base", labelShort: "3B", id: "0", type: "fieldObject" },
  { label: "Pitcher's Mound", labelShort: "PM", id: "0", type: "fieldObject" },
];

export const defensePositions = allPositions.filter(
  (p) => p.type === "defense"
);
export const runnerPositions = allPositions.filter((p) => p.type === "runner");
export const umpirePositions = allPositions.filter((p) => p.type === "umpire");
export const coachPositions = allPositions.filter((p) => p.type === "coach");

export const positionIdMap: {
  [key: string]: EntityPosition;
} = allPositions.reduce(
  (acc, position) => ({
    ...acc,
    [position.id]: position,
  }),
  {}
);
