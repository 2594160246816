import React from "react";
import * as THREE from "three";
import _values from "lodash/values";
import { Box } from "../Box";
import { RootStore } from "../../stores";
// import {HTML} from 'drei'

const defaultBBoxPadding = 1; // 1 foot
const defaultBBoxColor = "#fa0";

interface Props {
  joints: any[];
  rootStore: RootStore;
  userData?: any;
  padding?: number;
  onClick?: () => void;
  onHoverChange?: (hover: boolean) => void;
  // figureData?: any,
  children?: any;

  [key: string]: any;
}

// TODO    refactor to a generalized BoundingBox, not directly dependent on figure joints?
// TODO    use a Box3 AABB instead?
//  https://threejs.org/docs/#api/en/math/Box3
//  https://threejs.org/docs/#api/en/helpers/Box3Helper
export const FigureBoundingBox = (props: Props) => {
  let {
    joints,
    rootStore,
    userData = null,
    padding = defaultBBoxPadding,
    onClick,
    onHoverChange,
    children,
    ...otherProps
  } = props;
  let coords = _values(joints);
  let xs = coords.map((p) => p[0]);
  let ys = coords.map((p) => p[1]);
  let zs = coords.map((p) => p[2]);
  let vMin = new THREE.Vector3(
    Math.min(...xs),
    Math.min(...ys),
    Math.min(...zs)
  );
  let vMax = new THREE.Vector3(
    Math.max(...xs),
    Math.max(...ys),
    Math.max(...zs)
  );

  vMin.subScalar(padding);
  vMax.addScalar(padding);

  let vSize = vMax.clone().sub(vMin);
  let dir = vSize.clone().divideScalar(2);
  let vCenter = vMin.clone().add(dir);

  return (
    <Box
      size={[vSize.x, vSize.y, vSize.z]}
      position={[vCenter.x, vCenter.y, vCenter.z]}
      name={userData ? userData.id : null}
      userData={userData}
      rootStore={rootStore}
      color={defaultBBoxColor}
      onClick={onClick}
      onHoverChange={onHoverChange}
      {...otherProps}
    >
      {/*<HTML scaleFactor={30}>*/}
      {/*    <div style={{color: '#000'}}>*/}
      {/*        hello*/}
      {/*    </div>*/}
      {/*</HTML>*/}
      {children}
    </Box>
  );
};
