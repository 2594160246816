import { useEffect, useState } from "react";
import { Timebase } from "../models/timebase";

export const useTimebaseRelativeTime = (timebase: Timebase) => {
  let [relativeTime, setTime] = useState(timebase.relativeTime);

  useEffect(() => {
    let listener = (timebase: Timebase) => setTime(timebase.relativeTime);

    timebase.addListener(listener);

    return () => timebase.removeListener(listener);
  }, [timebase]);

  return relativeTime;
};

export const useTimebaseTime = (timebase: Timebase) => {
  let [time, setTime] = useState(timebase.time);

  useEffect(() => {
    let listener = (timebase: Timebase) => setTime(timebase.time);

    timebase.addListener(listener);

    return () => timebase.removeListener(listener);
  }, [timebase]);

  return time;
};

// TODOHI  need to share state for multiple calls to useTimebaseState for same Timebase?
export const useTimebaseState = (timebase: Timebase) => {
  let { time, relativeTime, loop, rate, normalizedTime, isPlaying } = timebase;
  let [state, setState] = useState({
    time,
    relativeTime,
    loop,
    rate,
    normalizedTime,
    isPlaying,
  });

  useEffect(() => {
    let listener = (timebase: Timebase) => {
      let {
        time,
        relativeTime,
        loop,
        rate,
        normalizedTime,
        isPlaying,
      } = timebase;

      setState({ time, relativeTime, loop, rate, normalizedTime, isPlaying });
    };

    timebase.addListener(listener);

    return () => timebase.removeListener(listener);
  }, [timebase]);

  return state;
};
