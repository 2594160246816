import React, { forwardRef, Ref, ReactElement } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import { TransitionProps } from "@material-ui/core/transitions";
import { FaLightbulb } from "react-icons/fa";
import { isiPad } from "../../util";
import ipadPhoto from "./scout_on_ipad.jpg";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  onClose: any;
}

export const TipsDialog = ({ onClose }: Props) => (
  <div>
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="tips-dialog-title"
      aria-describedby="tips-dialog-description"
    >
      <DialogTitle id="tips-dialog-title">
        <FaLightbulb style={{ height: "1rem" }} /> Tips
      </DialogTitle>
      <DialogContent>
        <h4>Hotkeys</h4>
        <ul>
          <li>space – toggle playback</li>
          <li>left/right – step back/ahead one frame</li>
          <li>down/up – step back/ahead one second</li>
          <li>p – go to pitch release</li>
          <li>h – high-home view</li>
          <li>1 – 1st base view</li>
          <li>2 – 2nd base view</li>
          <li>3 – 3rd base view</li>
          <li>u – umpire view</li>
        </ul>

        <Divider />

        <h4>Orbit Camera Control</h4>
        <ul>
          <li>
            <h5>Mouse</h5>
            <ul>
              <li>rotate – click & drag</li>
              <li>zoom – scroll-wheel</li>
              <li>pan – right-click & drag</li>
            </ul>
          </li>
          <li>
            <h5>Trackpad</h5>
            <ul>
              <li>rotate – 1-finger click & drag</li>
              <li>zoom – 2-finger pinch-zoom</li>
              <li>pan – 2-finger click & dragg</li>
            </ul>
          </li>
          <li>
            <h5>Mobile</h5>
            <ul>
              <li>rotate – 1-finger drag</li>
              <li>zoom – 2-finger pinch-zoom</li>
              <li>pan – 3-finger drag</li>
            </ul>
          </li>
        </ul>

        {!isiPad() && (
          <>
            <Divider />

            <h4>Check it out on iPad too</h4>
            <img src={ipadPhoto} width="300px" alt="Scout on iPad" />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  </div>
);
