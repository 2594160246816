import create from "zustand";
import { StatsApiNS } from "../types";

export interface StatsApiStore {
  boxscoreData?: StatsApiNS.Boxscore;
  playData?: StatsApiNS.PlayData;
  trackingData?: StatsApiNS.SkeletalData;
  setBoxscoreData(boxscoreData?: StatsApiNS.Boxscore): void;
  setPlayData(playData?: StatsApiNS.PlayData): void;
  setTrackingData(trackingData?: StatsApiNS.SkeletalData): void;
  getPlayerById(id: number): StatsApiNS.Player | undefined;
}

export const createStatsApiStore = () =>
  create<StatsApiStore>((set, get) => ({
    boxscoreData: undefined,
    playData: undefined,
    trackingData: undefined,
    setBoxscoreData: (boxscoreData: StatsApiNS.Boxscore) => {
      set({ boxscoreData });
    },
    setPlayData: (playData: StatsApiNS.PlayData) => {
      set({ playData });
    },
    setTrackingData: (trackingData: StatsApiNS.SkeletalData) => {
      set({ trackingData });
    },
    getPlayerById: (id: number) => {
      const boxscoreTeams = get().boxscoreData?.teams;
      let player = boxscoreTeams?.away.players["ID" + id];
      if (!player) {
        player = boxscoreTeams?.home.players["ID" + id];
      }
      return player;
    },
  }));
