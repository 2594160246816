import { toTrackedEventTimeStampAsMillis } from "./trackedEvent-util";
import _find from "lodash/find";

const numberCompare = (a: number, b: number) => a - b;

export const toTimeRange = (trackedEvents: any[]) => {
  if (!trackedEvents || trackedEvents.length === 0) {
    return [0, 0];
  }

  let times = trackedEvents
    .map(toTrackedEventTimeStampAsMillis)
    .sort(numberCompare);

  return [times[0], times[times.length - 1]];
};

export const toReleaseTime = ({ playData }: { playData: any }) => {
  let bwpEvent = _find(playData.trackedEvents, {
    playEvent: "BALL_WAS_PITCHED",
  });

  if (bwpEvent) {
    return toEpoch(`${bwpEvent.timeStamp}Z`);
  }

  let pobrEvent = _find(playData.trackedEvents, {
    playEvent: "PICK_OFF_BALL_RELEASED",
  });

  if (pobrEvent) {
    return toEpoch(`${pobrEvent.timeStamp}Z`);
  }

  let bopEvent = _find(playData.trackedEvents, { playEvent: "BEGIN_OF_PLAY" });
  if (bopEvent) {
    return toEpoch(`${bopEvent.timeStamp}Z`) + 3000;
  }
  
  return -1;
};

export const toEpoch = (time: string) => {
  return new Date(time).valueOf();
};

export const toIS08601Timestamp = (epoch: number) => {
  return new Date(epoch).toISOString();
};
