export const teamColors = [
  {
    team_code: "ari",
    team_name: "D-backs",
    team_abbrev: "ARI",
    team_id: "109",
    parent_color: "C51230",
    secondary_color: "D9C89E",
  },
  {
    team_code: "atl",
    team_name: "Braves",
    team_abbrev: "ATL",
    team_id: "144",
    parent_color: "01487E",
    secondary_color: "BA0C2F",
  },
  {
    team_code: "bal",
    team_name: "Orioles",
    team_abbrev: "BAL",
    team_id: "110",
    parent_color: "DF4601",
    secondary_color: "000000",
  },
  {
    team_code: "chn",
    team_name: "Cubs",
    team_abbrev: "CHC",
    team_id: "112",
    parent_color: "0E3386",
    secondary_color: "C8102E",
  },
  {
    team_code: "cha",
    team_name: "White Sox",
    team_abbrev: "CWS",
    team_id: "145",
    parent_color: "000000",
    secondary_color: "DCDDDF",
  },
  {
    team_code: "cin",
    team_name: "Reds",
    team_abbrev: "CIN",
    team_id: "113",
    parent_color: "EB184B",
    secondary_color: "FFFFFF",
  },
  {
    team_code: "cle",
    team_name: "Indians",
    team_abbrev: "CLE",
    team_id: "114",
    parent_color: "023465",
    secondary_color: "0C2340",
  },
  {
    team_code: "col",
    team_name: "Rockies",
    team_abbrev: "COL",
    team_id: "115",
    parent_color: "333366",
    secondary_color: "000000",
  },
  {
    team_code: "det",
    team_name: "Tigers",
    team_abbrev: "DET",
    team_id: "116",
    parent_color: "10293F",
    secondary_color: "FA4614",
  },
  {
    team_code: "hou",
    team_name: "Astros",
    team_abbrev: "HOU",
    team_id: "117",
    parent_color: "F59317",
    secondary_color: "072854",
  },
  {
    team_code: "kca",
    team_name: "Royals",
    team_abbrev: "KC",
    team_id: "118",
    parent_color: "000572",
    secondary_color: "74B4FA",
  },
  {
    team_code: "ana",
    team_name: "Angels",
    team_abbrev: "LAA",
    team_id: "108",
    parent_color: "CE1141",
    secondary_color: "0C2344",
  },
  {
    team_code: "lan",
    team_name: "Dodgers",
    team_abbrev: "LAD",
    team_id: "119",
    parent_color: "005596",
    secondary_color: "ffffff",
  },
  {
    team_code: "mil",
    team_name: "Brewers",
    team_abbrev: "MIL",
    team_id: "158",
    parent_color: "C4953B",
    secondary_color: "012143",
  },
  {
    team_code: "min",
    team_name: "Twins",
    team_abbrev: "MIN",
    team_id: "142",
    parent_color: "042462",
    secondary_color: "BA0C2E",
  },
  {
    team_code: "nyn",
    team_name: "Mets",
    team_abbrev: "NYM",
    team_id: "121",
    parent_color: "004685",
    secondary_color: "FC4C00",
  },
  {
    team_code: "nya",
    team_name: "Yankees",
    team_abbrev: "NYY",
    team_id: "147",
    parent_color: "132448",
    secondary_color: "ffffff",
  },
  {
    team_code: "oak",
    team_name: "Athletics",
    team_abbrev: "OAK",
    team_id: "133",
    parent_color: "00483A",
    secondary_color: "FFBE00",
  },
  {
    team_code: "phi",
    team_name: "Phillies",
    team_abbrev: "PHI",
    team_id: "143",
    parent_color: "CA1F2C",
    secondary_color: "003086",
  },
  {
    team_code: "pit",
    team_name: "Pirates",
    team_abbrev: "PIT",
    team_id: "134",
    parent_color: "FFB40B",
    secondary_color: "000000",
  },
  {
    team_code: "sln",
    team_name: "Cardinals",
    team_abbrev: "STL",
    team_id: "138",
    parent_color: "C41E3A",
    secondary_color: "0A2252",
  },
  {
    team_code: "sdn",
    team_name: "Padres",
    team_abbrev: "SD",
    team_id: "135",
    parent_color: "1C3465",
    secondary_color: "FEC325",
  },
  {
    team_code: "sfn",
    team_name: "Giants",
    team_abbrev: "SF",
    team_id: "137",
    parent_color: "FB5B1F",
    secondary_color: "FFFDD0",
  },
  {
    team_code: "sea",
    team_name: "Mariners",
    team_abbrev: "SEA",
    team_id: "136",
    parent_color: "1C8B85",
    secondary_color: "003166",
  },
  {
    team_code: "tba",
    team_name: "Rays",
    team_abbrev: "TB",
    team_id: "139",
    parent_color: "79BDEE",
    secondary_color: "00285D",
  },
  {
    team_code: "tex",
    team_name: "Rangers",
    team_abbrev: "TEX",
    team_id: "140",
    parent_color: "01317B",
    secondary_color: "BD1021",
  },
  {
    team_code: "tor",
    team_name: "Blue Jays",
    team_abbrev: "TOR",
    team_id: "141",
    parent_color: "0067A6",
    secondary_color: "DA291C",
  },
  {
    team_code: "bos",
    team_name: "Red Sox",
    team_abbrev: "BOS",
    team_id: "111",
    parent_color: "BD3039",
    secondary_color: "002244",
  },
  {
    team_code: "was",
    team_name: "Nationals",
    team_abbrev: "WSH",
    team_id: "120",
    parent_color: "BA122B",
    secondary_color: "14225A",
  },
  {
    team_code: "mia",
    team_name: "Marlins",
    team_abbrev: "MIA",
    team_id: "146",
    parent_color: "00A3E0",
    secondary_color: "EF3340",
  },
];
