import create from "zustand";

export interface VenueStore {
  selectedVenueId?: number;
  playDataVenueId?: number;
  useNexusModel: boolean;
  gltfDownloadedPercentage?: number;
  venueModelOverrideUrl?: string;
  venueModelUrlDomain?: string;
  showLidarReference: boolean;

  setSelectedVenueId(selectedVenueId: number): void;

  setPlayDataVenueId(playDataVenueId: number): void;

  toggleUseNexusModel(): void;

  setVenueModelOverrideUrl(venueModelOverrideUrl: string): void;

  setGltfDownloadedPercentage(percentage: number): void;

  toggleShowLidarReference(): void;

  referencePolesEnabled: boolean;

  setReferencePolesEnabled(referencePolesEnabled: boolean): void;

  setVenueModelUrlDomain(venueModelUrlDomain: string): void;
}

// TODOHI  add an initial values argument to createVenueStore
export const createVenueStore = () =>
  create<VenueStore>((set) => ({
    selectedVenueId: undefined,
    playDataVenueId: undefined,
    useNexusModel: false,
    gltfDownloadedPercentage: undefined,
    venueModelOverrideUrl: undefined,
    venueModelUrlDomain: undefined,
    showLidarReference: false,
    setSelectedVenueId: (selectedVenueId: number) => {
      set({ selectedVenueId });
    },
    setPlayDataVenueId: (playDataVenueId: number) => {
      set({ playDataVenueId });
    },
    toggleUseNexusModel: () => {
      set(($) => ({ useNexusModel: !$.useNexusModel }));
    },
    setGltfDownloadedPercentage: (gltfDownloadedPercentage: number) => {
      set({ gltfDownloadedPercentage });
    },
    setVenueModelOverrideUrl: (venueModelOverrideUrl: string) => {
      set({ venueModelOverrideUrl, useNexusModel: true });
    },
    setVenueModelUrlDomain: (venueModelUrlDomain: string) => {
      set({ venueModelUrlDomain });
    },
    toggleShowLidarReference: () => {
      set(($) => ({ showLidarReference: !$.showLidarReference }));
    },
    referencePolesEnabled: false,
    setReferencePolesEnabled: (referencePolesEnabled: boolean) => {
      set({ referencePolesEnabled });
    },
  }));
