import React from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { RootStore } from "../../stores";

interface Props {
  rootStore: RootStore;
}

export const ProductionModeButton: React.FC<Props> = ({ rootStore }) => {
  const {
    useVenueStore,
    useFigureModelStore,
    useFigurePositionStore,
    useBallStore,
    useFigureAppearanceStore,
  } = rootStore;

  const { useNexusModel, toggleUseNexusModel } = useVenueStore();
  const {
    setFigureModelsEnabled,
    setTorsoTwistEnabled,
    setHeadTiltEnabled,
    setHeadTurnEnabled,
  } = useFigureModelStore();
  const { jointCleanup, toggleJointCleanup } = useFigurePositionStore();
  const {
    recoBallVisible,
    toggleRecoBallVisible,
    ballSegmentsVisible,
    toggleBallSegmentsVisible,
    mergedBallVisible,
    toggleMergedBallVisible,
    setBallType,
    setTruncatedHitExtensionEnabled,
  } = useBallStore();
  const { setFiguresEnabled } = useFigureAppearanceStore();

  const handleClick = () => {
    if (!useNexusModel) toggleUseNexusModel();
    setFigureModelsEnabled(true);
    setTorsoTwistEnabled(true);
    setHeadTiltEnabled(true);
    setHeadTurnEnabled(true);
    setFiguresEnabled(false);
    setBallType("rbi");
    if (!jointCleanup) toggleJointCleanup();
    if (!recoBallVisible) toggleRecoBallVisible();
    if (ballSegmentsVisible) toggleBallSegmentsVisible();
    if (mergedBallVisible) toggleMergedBallVisible();
    setTruncatedHitExtensionEnabled(true);
  };

  return (
    <div>
      <Tooltip title="Production Mode Presets" placement="left">
        <Button
          variant="contained"
          size="small"
          onClick={handleClick}
          aria-controls="view-presets-menu"
          aria-haspopup="true"
        >
          PM
        </Button>
      </Tooltip>
    </div>
  );
};
