import React, { useLayoutEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import { Vector3 } from "three";

interface Props {
  initialCameraMatrixArray: number[];
  storedMatrix: number[];
  cameraRef: any;
  position?: Vector3;
  fov?: number;
}

export const Camera = ({
  initialCameraMatrixArray,
  storedMatrix,
  cameraRef,
  ...props
}: Props) => {
  let { camera } = useThree();
  const set = useThree((state) => state.set);
  // initialization
  useLayoutEffect(() => {
    // if given a camera matrix array, and one isn't already saved in the store, apply it
    if (
      storedMatrix.length === 0 &&
      initialCameraMatrixArray &&
      initialCameraMatrixArray.length === 16
    ) {
      camera.matrix.fromArray(initialCameraMatrixArray);
      camera.matrix.decompose(camera.position, camera.quaternion, camera.scale);
    }
  }, [camera, storedMatrix, cameraRef, initialCameraMatrixArray, set]);

  // make the camera known to the system
  useLayoutEffect(() => set({ camera: cameraRef.current }), [cameraRef, set]);
  // update  every frame
  useFrame(() => {
    cameraRef.current.updateMatrixWorld();
  });

  return <PerspectiveCamera ref={cameraRef} {...props} />;
};
