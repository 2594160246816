import React, { useMemo } from "react";
import { Ball, defaultBallRadius } from "../Ball";
import { timeToSample } from "../../util/tracking-util";
// import {toReleaseTime} from '../../util/time-util'
import { KnottedTrack } from "../KnottedTrack";
import { BallSegmentGroup } from "../BallSegmentGroup";
import { RecoBall } from "../RecoBall";
import { BallPad } from "../BallPad";
import { BallTrailsGroup } from "../BallTrail";
import { TWO_PI } from "../../constants/math";

const playDataBallColor = "#fff";
const playDataBallTrackBallColor = "#333";
const ballPadColor = "#fff";
let ballTrackLineWidth = 1;

const origin = [0, 0, 0];

interface Props {
  playRelativeTimeSeconds: number;
  time?: number;
  mpdBallPositions: any[];
  segments?: any[];
  useBallStore: any;
}

export const BallTrackingGroup = (props: Props) => {
  let {
    playRelativeTimeSeconds,
    time = 0,
    mpdBallPositions,
    segments = [],
    useBallStore,
  } = props;
  let {
    ballType,
    ballSize,
    playDataBallTrackVisible,
    mergedBallVisible,
    ballSegmentsVisible,
    recoBallVisible,
    ballPadVisible,
    ballTrailsEnabled,
  } = useBallStore();
  let ballRadius = ballSize * defaultBallRadius;

  // find ball position for time in mpdBallPositions
  let mpdBallPosition = useMemo(() => {
    return timeToSample({ time, series: mpdBallPositions, epsilon: 33 });
  }, [mpdBallPositions, time]);
  let ballVisible = mergedBallVisible && !!mpdBallPosition;
  let ballPosition = mpdBallPosition
    ? [mpdBallPosition.x, mpdBallPosition.y, mpdBallPosition.z]
    : origin;
  let ballRotation = TWO_PI * playRelativeTimeSeconds * -5;
  let mpdBall = (
    <Ball
      type={ballType}
      position={ballPosition}
      rotation={[ballRotation, 0, 0]}
      radius={ballRadius}
      color={playDataBallColor}
      visible={ballVisible}
      textured
    />
  );

  let mpdBallTrack = useMemo(
    () =>
      playDataBallTrackVisible && (
        <KnottedTrack
          positions={mpdBallPositions}
          knotRadius={defaultBallRadius}
          lineWidth={ballTrackLineWidth}
          lineColor={playDataBallTrackBallColor}
        />
      ),
    [mpdBallPositions, playDataBallTrackVisible]
  );

  let ballSegments = useMemo(
    () => ballSegmentsVisible && <BallSegmentGroup segments={segments} />,
    [segments, ballSegmentsVisible]
  );

  let recoBall = recoBallVisible && (
    <RecoBall
      type={ballType}
      segments={segments}
      time={playRelativeTimeSeconds}
      radius={ballRadius}
      rotation={[ballRotation, 0, 0]}
    />
  );

  let ballPad =
    ballPadVisible && mpdBallPosition ? (
      <BallPad
        position={[mpdBallPosition.x, mpdBallPosition.y, 0.2]}
        radius={ballRadius * 5}
        color={ballPadColor}
      />
    ) : null;

  let ballTrails = ballTrailsEnabled && (
    <BallTrailsGroup
      useBallStore={useBallStore}
      segments={segments}
      time={playRelativeTimeSeconds}
    />
  );

  return (
    <group name="ball-tracking">
      {mpdBall}
      {mpdBallTrack}
      {ballSegments}
      {recoBall}
      {ballPad}
      {ballTrails}
    </group>
  );
};
