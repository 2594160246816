import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { BiBaseball } from "react-icons/bi";
import { AiFillDatabase } from "react-icons/ai";
import Tooltip from "@material-ui/core/Tooltip";
import { AiOutlineTool } from "react-icons/ai";

export const appSpaces = {
  main: "main",
  storyboard: "storyboard",
  diagnostics: "diagnostics",
};

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      margin: 0,
    },
  },
}));

interface Props {
  space: string;
  onChange: (space: string) => void;
}

export const SpaceSelect = ({ space, onChange }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip title="Main" placement="left">
        <IconButton
          color={space === appSpaces.main ? "secondary" : "default"}
          aria-label="main"
          size="small"
          onClick={() => onChange(appSpaces.main)}
        >
          <BiBaseball />
        </IconButton>
      </Tooltip>

      <br />

      <Tooltip title="Storyboard" placement="left">
        <span>
          <IconButton
            disabled
            color={space === appSpaces.storyboard ? "secondary" : "default"}
            aria-label="storyboard"
            size="small"
            onClick={() => onChange(appSpaces.storyboard)}
          >
            <AiFillDatabase />
          </IconButton>
        </span>
      </Tooltip>

      <br />

      <Tooltip title="Diagnostics" placement="left">
        <IconButton
          color={space === appSpaces.diagnostics ? "secondary" : "default"}
          aria-label="diagnostics"
          size="small"
          onClick={() => onChange(appSpaces.diagnostics)}
        >
          <AiOutlineTool />
        </IconButton>
      </Tooltip>
    </div>
  );
};
