import create from "zustand";

export interface SwingFlairStore {
  swingFlairEnabled: boolean;

  setSwingFlairEnabled(enabled: boolean): void;

  trailCount: number;

  setTrailCount(count: number): void;

  lineWidth: number;

  setLineWidth(width: number): void;

  trailDuration: number;

  setTrailDuration(duration: number): void;

  trailDurationVariation: number;

  setTrailDurationVariation(variation: number): void;

  lineColor: string;

  setLineColor(color: string): void;
}

export const createSwingFlairStore = () =>
  create<SwingFlairStore>((set) => ({
    swingFlairEnabled: false, // xxx
    setSwingFlairEnabled: (enabled: boolean) =>
      set({ swingFlairEnabled: enabled }),

    trailCount: 10,
    setTrailCount: (trailCount: number) => set({ trailCount }),

    lineWidth: 1,
    setLineWidth: (lineWidth: number) => set({ lineWidth }),

    trailDuration: 500,
    setTrailDuration: (trailDuration: number) => set({ trailDuration }),

    trailDurationVariation: 0,
    setTrailDurationVariation: (trailDurationVariation: number) =>
      set({ trailDurationVariation }),

    lineColor: "#fff",
    setLineColor: (lineColor: string) => set({ lineColor }),
  }));
