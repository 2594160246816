import create from "zustand";

export interface MarksStore {
  pinVisible: boolean;

  setPinVisible(pinVisible: boolean): void;

  pinPosition: number[];

  setPinPosition(pinPosition: number[]): void;

  ringVisible: boolean;

  setRingVisible(ringVisible: boolean): void;

  ringRadius: number;
  ringHeight: number;

  setRingRadius(ringRadius: number): void;

  setRingHeight(ringHeight: number): void;
}

export const createMarksStore = () =>
  create<MarksStore>((set) => ({
    pinVisible: false,
    setPinVisible: (pinVisible: boolean) => set({ pinVisible }),
    pinPosition: [0, 0],
    setPinPosition: (pinPosition: number[]) => set({ pinPosition }),
    ringVisible: false,
    setRingVisible: (ringVisible: boolean) => set({ ringVisible }),
    ringRadius: 60.5,
    setRingRadius: (ringRadius: number) => set({ ringRadius }),
    ringHeight: 5,
    setRingHeight: (ringHeight: number) => set({ ringHeight }),
  }));
