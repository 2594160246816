import { Vector3 } from "three";
import { toFigureFrames, toJointSeries } from "../../util/tracking-util";
import { toReleaseTime } from "../../util/time-util";
import { arrayPointsDistance3D } from "../../util/geometry-util";
import { toEventTime } from "../../util/play-util";
import { defaultGripExtension } from "../../stores/swingStore";

export const toBatSide = (figureFrames: any[]) =>
  figureFrames.length ? (figureFrames[0].location.x < 0 ? "R" : "L") : "U";

// const toDeltaZSeries = (jointSeries: any[]) =>
//   jointSeries.map((joint, i) =>
//     i === 0 ? 0 : joint.pos[2] - jointSeries[i - 1].pos[2]
//   );
//
// const dzIsNegative = (dz: number) => dz < 0;
//
// // calculates z-velocity, vz, time series of each wrist, and find time of first frame where vz is negative
// const toWristDropSwingEndIndex = (
//   leftWristSeries: any,
//   rightWristSeries: any
// ) => {
//   // TODOHI  calculate z-velocity, vz, time series of each wrist, and find time of first frame where vz is negative
//   let leftDeltaZSeries = toDeltaZSeries(leftWristSeries);
//   let rightDeltaZSeries = toDeltaZSeries(rightWristSeries);
//   // @ts-ignore
//   let firstDropIndex = Math.min(
//     leftDeltaZSeries.findIndex(dzIsNegative),
//     rightDeltaZSeries.findIndex(dzIsNegative)
//   );
//
//   if (firstDropIndex < 1) {
//     return -1;
//   }
//
//   return firstDropIndex - 1;
// };

const wristSeparationThreshold = 1.5;

// calculates wrist separation distances and finds last frame before an increase
const toWristSeparationSwingEndIndex = (
  leftWristSeries: any,
  rightWristSeries: any,
  pitchInterval: [number, number]
) => {
  let separations = leftWristSeries.map((leftWrist: any, i: number) => {
    let rightWrist = rightWristSeries[i];

    return {
      time: leftWrist.time,
      sec: (leftWrist.time - pitchInterval[0]) / 1000,
      separation: arrayPointsDistance3D(leftWrist.pos, rightWrist.pos),
    };
  });

  // // TODO    eventually…
  // // filter to release .. plate-crossing span
  // let pitchSeparations = separations.filter(
  //   (sep: any) => pitchInterval[0] <= sep.time && sep.time <= pitchInterval[1]
  // );
  //
  // console.log(JSON.stringify(separations));
  // //
  // // TODO    find mean separation in pitch span
  // //
  // // TODO    find first separation > (mean + epsilon) after pitchInterval[1] time

  // for now, simply using a hard coded wristSeparationThreshold
  return separations.findIndex(
    (sep: any) => sep.separation >= wristSeparationThreshold
  );
};

// // TODO    later, find swing end based on deceleration instead of wrist drop?
// /**
//  * Finds a swing end time based on first wrist drop
//  * @param playData
//  * @param frames - requires both wrists present in each frame
//  * @return -1 if no end time found
//  */
// const toSwingEndTime = (playData: any, frames: any[]) => {
//   // TODOHI  need to find firstDropIndex after…
//   // TODOHI  upswing?  <-- most general, but most work
//   // TODOHI  maybe also use velocity drop and/or deceleration? e.g., 10% drop in inferred bat-tip velocity
//   // TODOHI  plate crossing time?  <-- slightly more general than BALL_WAS_HIT time
//   // TODOHI  BALL_WAS_HIT time?  <-- easiest, to start?
//   // TODOHI  or end when wrists separation increases beyond some delta
//
//   ////////////////////////////////////
//
//   // TODOHI  really need to generalize beyond BALL_WAS_HIT
//
//   let bwhTime = toEventTime(playData, "BALL_WAS_HIT");
//
//   if (bwhTime === -1) {
//     return -1;
//   }
//
//   // trim to time >= BALL_WAS_HIT time
//   let bwhIndex = frames.findIndex((f) => f.time >= bwhTime);
//   let hitFrames = frames.slice(bwhIndex);
//
//   ////////////////////////////////////
//
//   // TODOHI  get wrist strings
//   let leftWristSeries = toJointSeries({
//     figureFrames: hitFrames,
//     jointName: "LWrist",
//   });
//   let rightWristSeries = toJointSeries({
//     figureFrames: hitFrames,
//     jointName: "RWrist",
//   });
//
//   // swing end time is the time of the frame before the first wrist drop (for now)
//   let swingEndIndex = toWristDropSwingEndIndex(
//     leftWristSeries,
//     rightWristSeries
//   );
//
//   // ballSegments.pitchSegment.trajectoryData.measuredTimeInterval
//
//   let measuredInterval =
//     playData.ballSegments.pitchSegment?.trajectoryData.measuredTimeInterval;
//
//   if (!measuredInterval) {
//     return -1;
//   }
//
//   return hitFrames[swingEndIndex].time;
// };

const toWristSeparationSwingEndTime = (
  playData: any,
  frames: any[],
  releaseTime: number,
  pitchInterval: [number, number]
) => {
  let leftWristSeries = toJointSeries({
    figureFrames: frames,
    jointName: "LWrist",
  });
  let rightWristSeries = toJointSeries({
    figureFrames: frames,
    jointName: "RWrist",
  });

  let separationSwingEndIndex = toWristSeparationSwingEndIndex(
    leftWristSeries,
    rightWristSeries,
    pitchInterval as [number, number] // TS being a pest
  );
  if (separationSwingEndIndex === -1) return -1;
  return frames[separationSwingEndIndex].time;
};

const hasWrists = (f: any) => f.jointsMap.LWrist && f.jointsMap.RWrist;
const hasElbows = (f: any) => f.jointsMap.LElbow && f.jointsMap.RElbow;

const toSwingFrames = ({
  playData,
  figureFrames,
  capSwingEnabled = true,
  wristSeparationSwingEndEnabled = true,
  requireElbows = false,
}: {
  playData: any;
  figureFrames: any[];
  capSwingEnabled?: boolean;
  wristSeparationSwingEndEnabled?: boolean;
  requireElbows?: boolean;
}) => {
  let bopTime = toEventTime(playData, "BEGIN_OF_PLAY");
  let releaseTime = toReleaseTime({ playData });

  if (releaseTime === -1) {
    return [];
  }

  let measuredInterval =
    playData.ballSegments.pitchSegment?.trajectoryData.measuredTimeInterval;

  if (!measuredInterval) {
    return [];
  }

  let pitchInterval = [releaseTime, releaseTime + 1000 * measuredInterval[1]];
  // // TODO    set startTime to time when lead ankle rises instead?
  // let startTime = releaseTime - 500 // arbitrarily back-time .5 sec
  let startTime = bopTime;

  let wellJointedFrames = requireElbows
    ? figureFrames.filter((f: any) => hasWrists(f) && hasElbows(f))
    : figureFrames.filter(hasWrists);
  let wristSeparationSwingEndTime = toWristSeparationSwingEndTime(
    playData,
    wellJointedFrames,
    releaseTime,
    pitchInterval as [number, number] // TS nag
  );

  if (wristSeparationSwingEndTime === -1) {
    return [];
  }

  let maxTime = pitchInterval[1] + 1000;
  let swingCapTime = pitchInterval[1] + 500;
  let swingEndTime = maxTime;

  if (capSwingEnabled) {
    // trim to earliest of wrist-sep and .5 sec after pitch end
    swingEndTime = Math.min(swingCapTime, swingEndTime);
  }

  if (wristSeparationSwingEndEnabled) {
    swingEndTime = Math.min(swingCapTime, swingEndTime);
  }

  return wellJointedFrames.filter(
    (f: any) => startTime <= f.time && f.time <= swingEndTime
  );
};

export const toWristSeries = ({
  playData,
  figureFrames,
  capSwingEnabled = true,
  wristSeparationSwingEndEnabled = true,
}: {
  playData: any;
  figureFrames: any[];
  capSwingEnabled?: boolean;
  wristSeparationSwingEndEnabled?: boolean;
}) => {
  if (figureFrames.length === 0) {
    return { leftWrist: [], rightWrist: [] };
  }
  let batterSwingFrames = toSwingFrames({
    playData,
    figureFrames,
    capSwingEnabled,
    wristSeparationSwingEndEnabled,
  });

  if (!batterSwingFrames || batterSwingFrames.length === 0) {
    return { leftWrist: [], rightWrist: [] };
  }

  let leftJointSeries = toJointSeries({
    figureFrames: batterSwingFrames,
    jointName: "LWrist",
  });
  let rightJointSeries = toJointSeries({
    figureFrames: batterSwingFrames,
    jointName: "RWrist",
  });

  return { leftJointSeries, rightJointSeries };
};

export const toBatSeries = ({
  figureFrames,
  jointSeries,
}: {
  figureFrames: any[];
  jointSeries: any;
}) => {
  let batSide = toBatSide(figureFrames);
  let { leftJointSeries, rightJointSeries } = jointSeries;

  if (!leftJointSeries || !rightJointSeries) return [];

  return leftJointSeries.map((leftJointNode: any, i: number) => {
    let rightJointNode = rightJointSeries[i];
    let topGripNode = batSide === "R" ? rightJointNode : leftJointNode;
    let bottomGripNode = batSide === "R" ? leftJointNode : rightJointNode;
    let vTopGrip = new Vector3(...topGripNode.pos);
    let vBottomGrip = new Vector3(...bottomGripNode.pos);
    // TODOHI  check this vector math
    let vBat = new Vector3().subVectors(vTopGrip, vBottomGrip).normalize();
    let vBatKnob = vBottomGrip.clone().addScaledVector(vBat, -2 / 12);
    let vBatEnd = vBottomGrip.clone().addScaledVector(vBat, 32 / 12);
    let { time, timeStamp } = leftJointNode;

    return {
      time,
      timeStamp,
      batKnob: vBatKnob.toArray(),
      batEnd: vBatEnd.toArray(),
    };
  });
};

// const defaultGripExtension = 1.5 / 12;

interface toExtrapolatedGripSeriesArgs {
  figureFrames: any[];
  gripSide: string;
  wristGripExtension?: number;
}

export const toExtrapolatedGripSeries = ({
  figureFrames,
  gripSide,
  wristGripExtension = defaultGripExtension,
}: toExtrapolatedGripSeriesArgs) => {
  let elbowJointName = gripSide === "LGrip" ? "LElbow" : "RElbow";
  let wristJointName = gripSide === "LGrip" ? "LWrist" : "RWrist";
  return figureFrames.map((frame) => {
    let { jointsMap, time, timeStamp } = frame;
    let elbowPos = jointsMap[elbowJointName];
    let wristPos = jointsMap[wristJointName];

    // extrapolate gripPos from elbow and wrist
    // g = w + gripExtension * norm(w - e)
    let w = new Vector3(...wristPos);
    let e = new Vector3(...elbowPos);
    let v = new Vector3().subVectors(w, e).normalize();
    let g = new Vector3().copy(w).addScaledVector(v, wristGripExtension);

    let gripPos = g.toArray();

    if (!elbowPos || !wristPos) {
      return null;
    }

    return {
      gripSide,
      time,
      timeStamp,
      elbowPos,
      wristPos,
      pos: gripPos,
    };
  });
};

const emptyGripSeries = { leftGripSeries: [], rightGripSeries: [] };

const defaultWristGripExtension = 3 / 12;

/**
 * Calculates left and right grip series
 * @param playData
 * @param figureFrames
 * @param wristGripExtension
 * @param capSwingEnabled
 * @param wristSeparationSwingEndEnabled
 */
export const toGripSeries = ({
  playData,
  figureFrames,
  wristGripExtension = defaultWristGripExtension,
  capSwingEnabled = true,
  wristSeparationSwingEndEnabled = true,
}: {
  playData: any;
  figureFrames: any[];
  wristGripExtension?: number;
  capSwingEnabled?: boolean;
  wristSeparationSwingEndEnabled?: boolean;
}) => {
  if (figureFrames.length === 0) {
    return { ...emptyGripSeries };
  }
  let requireElbows = true;
  let batterSwingFrames = toSwingFrames({
    playData,
    figureFrames,
    capSwingEnabled,
    wristSeparationSwingEndEnabled,
    requireElbows,
  });

  if (!batterSwingFrames || batterSwingFrames.length === 0) {
    return { ...emptyGripSeries };
  }

  let leftJointSeries = toExtrapolatedGripSeries({
    figureFrames: batterSwingFrames,
    gripSide: "LGrip",
    wristGripExtension,
  });
  let rightJointSeries = toExtrapolatedGripSeries({
    figureFrames: batterSwingFrames,
    gripSide: "RGrip",
    wristGripExtension,
  });

  return { leftJointSeries, rightJointSeries };
};

interface toSwingSeriesSetArgs {
  playData: any;
  frames: any[];
  gripsEnabled: boolean;
  capSwingEnabled: boolean;
  wristSeparationSwingEndEnabled: boolean;
  wristGripExtension: number;
}

export const toSwingSeriesSet = ({
  playData,
  frames,
  gripsEnabled,
  capSwingEnabled,
  wristSeparationSwingEndEnabled,
  wristGripExtension,
}: toSwingSeriesSetArgs) => {
  let figureFrames = toFigureFrames({ frames, positionId: 10 });
  let wristSeries = toWristSeries({
    playData,
    figureFrames,
    capSwingEnabled,
    wristSeparationSwingEndEnabled,
  });
  let gripSeries = toGripSeries({
    playData,
    figureFrames,
    wristGripExtension,
    capSwingEnabled,
    wristSeparationSwingEndEnabled,
  });
  let batSeries = toBatSeries({
    figureFrames,
    jointSeries: gripsEnabled ? gripSeries : wristSeries,
  });

  return { figureFrames, wristSeries, gripSeries, batSeries };
};
