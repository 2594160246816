import React, { useMemo, useRef, useLayoutEffect } from "react";
import { BufferGeometry, DoubleSide, Vector3 } from "three";
import { Node } from "./Node";
import { CylinderSpan } from "../shapes/CylinderSpan";
import { MeshSeriesGroup } from "../MeshSeriesGroup";
import { TexMapBall } from "../Ball";
import { pairs as d3Pairs } from "d3-array";

// TODO    there must be a less kludgey way to update CylinderSpan
// id is a forever increasing value, used as a hack to force CylinderSpans to re-render.
let id = 1;

const defaultBatCylinderRadius = 0.01;

const batKnobColor = "#5b4a33";
const batEndColor = "#ae906b";
const batKnobStringColor = "#453827";
const batEndStringColor = "#6d5b45";
const batKnobMaterial = (
  <meshStandardMaterial attach="material" color={batKnobColor} />
);
const batEndMaterial = (
  <meshStandardMaterial attach="material" color={batEndColor} />
);
const batColor = "#543c1e";
const batMaterial = <meshBasicMaterial attach="material" color={batColor} />;

const defaultTipNodeRadius = 0.05;
const defaultTipStringRadius = 0.01;

interface BatTipNodesGroupProps {
  batTipSeries: any;
  radius?: number;
  color: any;
  releaseTime: number;
}

// TODOHI  add plateCrossingTime
const BatTipNodesGroup = ({
  batTipSeries,
  radius = defaultTipNodeRadius,
  color,
}: BatTipNodesGroupProps) => {
  let points = batTipSeries.map((bat: any) => ({
    x: bat.pos[0],
    y: bat.pos[1],
    z: bat.pos[2],
  }));

  // TODOHI  separate into pre-release, pitch, and post-pitch points and corresponding MeshSeriesGroups

  // TODO    add slightly larger colored spheres at recognition (100 ms after release) and commit times (167 ms before plate)

  return (
    <MeshSeriesGroup
      Mesh={TexMapBall}
      props={{ radius, color }}
      positions={points}
    />
  );
};

interface BatTipStringGroupProps {
  batTipSeries: any;
  radius?: number;
  color: any;
}

const BatTipStringGroup = ({
  batTipSeries,
  radius = defaultTipStringRadius,
  color,
}: BatTipStringGroupProps) => {
  let points = batTipSeries.map((tip: any) => tip.pos);
  let pointPairs = d3Pairs(points);
  let material = (
    <meshStandardMaterial color={color} metalness={1} roughness={0} />
  );

  return (
    <group>
      {pointPairs.map((pair: any) => (
        <CylinderSpan
          key={id++}
          p1={pair[0]}
          p2={pair[1]}
          radius={radius}
          material={material}
        />
      ))}
    </group>
  );
};

const defaultStrobeColor = "#444";
const defaultStrobeRadius = 0.005;

interface BatStrobeGroupProps {
  batSeries: any;
  radius?: number;
  color?: any;
}

const BatStrobeGroup = (props: BatStrobeGroupProps) => {
  let {
    batSeries,
    radius = defaultStrobeRadius,
    color = defaultStrobeColor,
  } = props;
  let leftPoints = batSeries.map((bat: any) => bat.batKnob);
  let rightPoints = batSeries.map((bat: any) => bat.batEnd);
  let material = (
    <meshStandardMaterial color={color} metalness={1} roughness={0} />
  );
  let edges = leftPoints.map((p: any, i: number) => {
    let q = rightPoints[i];
    return (
      <CylinderSpan
        key={id++}
        p1={p}
        p2={q}
        radius={radius}
        material={material}
      />
    );
  });

  return <group>{edges}</group>;
};

interface BatFanProps {
  batSeries: any;
}

let fanId = 1;
let vertices: Vector3[] = [];

export const BatFan = ({ batSeries }: BatFanProps) => {
  batSeries.forEach((bat: any) => {
    vertices.push(new Vector3(...bat.batKnob));
    vertices.push(new Vector3(...bat.batEnd));
  });
  const ref = useRef(new BufferGeometry());

  useLayoutEffect(() => {
    ref.current?.computeVertexNormals();
  });

  return (
    <mesh key={fanId} castShadow={false}>
      <bufferGeometry ref={ref} />
      <meshStandardMaterial
        color="#000"
        roughness={0.3}
        metalness={0.8}
        side={DoubleSide}
        opacity={0.4}
        transparent={true}
        flatShading={true}
      />
      {/*<meshPhongMaterial color='#888' side={DoubleSide} opacity={.5} transparent={true}/>*/}
      {/*<meshLambertMaterial color="#000" side={DoubleSide} />*/}
    </mesh>
  );
};

interface BatGroupProps {
  bat: any;
  radius?: number;
}

export const BatGroup = ({
  bat,
  radius = defaultBatCylinderRadius,
}: BatGroupProps) => {
  let { batKnob, batEnd } = bat;

  return (
    <group>
      <Node pos={batKnob} material={batKnobMaterial} />
      <Node pos={batEnd} material={batEndMaterial} />
      <CylinderSpan
        key={id++}
        p1={batKnob}
        p2={batEnd}
        radius={radius}
        material={batMaterial}
      />
    </group>
  );
};

interface Props {
  batSeries: any[];
  releaseTime: number;
  useSwingStore: any;
}

export const BatTrackGroup = ({
  batSeries,
  releaseTime,
  useSwingStore,
}: Props) => {
  let batTipsVisible = useSwingStore(($: any) => $.batTipsVisible);
  let batTipStringsVisible = useSwingStore(($: any) => $.batTipStringsVisible);
  let batStrobeVisible = useSwingStore(($: any) => $.batStrobeVisible);
  let batFanVisible = useSwingStore(($: any) => $.batFanVisible);

  // TODO    include time
  let batKnobSeries = batSeries.map((bat: any) => ({
    time: bat.time,
    pos: bat.batKnob,
  }));
  let batEndSeries = batSeries.map((bat: any) => ({
    time: bat.time,
    pos: bat.batEnd,
  }));

  let batTipNodesGroup = useMemo(() => {
    return batTipsVisible || batTipStringsVisible || batStrobeVisible ? (
      <group>
        <BatTipNodesGroup
          batTipSeries={batKnobSeries}
          releaseTime={releaseTime}
          color={batKnobColor}
        />
        <BatTipNodesGroup
          batTipSeries={batEndSeries}
          releaseTime={releaseTime}
          color={batEndColor}
        />
      </group>
    ) : null;
  }, [
    batTipsVisible,
    batTipStringsVisible,
    batStrobeVisible,
    batKnobSeries,
    batEndSeries,
    releaseTime,
  ]);

  let batTipStringsGroup = useMemo(() => {
    return batTipStringsVisible ? (
      <group>
        <BatTipStringGroup
          batTipSeries={batKnobSeries}
          color={batKnobStringColor}
        />
        <BatTipStringGroup
          batTipSeries={batEndSeries}
          color={batEndStringColor}
        />
      </group>
    ) : null;
  }, [batTipStringsVisible, batKnobSeries, batEndSeries]);

  let batStrobeGroup = useMemo(() => {
    return batStrobeVisible ? (
      <group>
        <BatStrobeGroup batSeries={batSeries} />
      </group>
    ) : null;
  }, [batStrobeVisible, batSeries]);

  let batFan = useMemo(
    () => batFanVisible && <BatFan key={id++} batSeries={batSeries} />,
    [batFanVisible, batSeries]
  );

  return batSeries ? (
    <group>
      {batFan}
      {batTipNodesGroup}
      {batTipStringsGroup}
      {batStrobeGroup}
    </group>
  ) : null;
};
