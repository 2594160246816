import React, { useState } from "react";
import _includes from "lodash/includes";
import _remove from "lodash/remove";
import _some from "lodash/some";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { playListData, tags } from "./playListData";

interface TagToggleProps {
  tag: string;
  label: string;
  filters: string[];
  toggleFilterTag: (tag: string) => void;
}

const TagToggle = ({
  tag,
  label,
  filters,
  toggleFilterTag,
}: TagToggleProps) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={_includes(filters, tag)}
        onChange={() => toggleFilterTag(tag)}
      />
    }
    label={label}
  />
);

interface Props {
  onPlaySelected: (gamePk: string, playId: string) => void;
}

export const PlayList = ({ onPlaySelected }: Props) => {
  let [filters, setFilters] = useState<string[]>([]);

  let handleClick = (d: any) => {
    onPlaySelected(d.gamePk, d.playId);
  };

  let toggleFilterTag = (tag: string) => {
    if (_includes(filters, tag)) {
      _remove(filters, (f) => f === tag);
    } else {
      filters.push(tag);
    }
    setFilters([...filters]);
  };

  let filteredPlays =
    filters.length === 0
      ? playListData
      : playListData.filter((play) => {
          return _some(play.tags, (tag) => _includes(filters, tag));
        });

  return (
    <div>
      <FormGroup row>
        <TagToggle
          tag={tags.hr}
          label="HR"
          filters={filters}
          toggleFilterTag={toggleFilterTag}
        />

        <TagToggle
          tag={tags.triple}
          label="Triple"
          filters={filters}
          toggleFilterTag={toggleFilterTag}
        />

        <TagToggle
          tag={tags.double}
          label="Double"
          filters={filters}
          toggleFilterTag={toggleFilterTag}
        />

        <TagToggle
          tag={tags.single}
          label="Single"
          filters={filters}
          toggleFilterTag={toggleFilterTag}
        />

        <TagToggle
          tag={tags.dp}
          label="Double Play"
          filters={filters}
          toggleFilterTag={toggleFilterTag}
        />

        <TagToggle
          tag={tags.tp}
          label="Triple Play"
          filters={filters}
          toggleFilterTag={toggleFilterTag}
        />

        <TagToggle
          tag={tags.steal}
          label="Steal"
          filters={filters}
          toggleFilterTag={toggleFilterTag}
        />

        <TagToggle
          tag={tags.throw}
          label="Throw"
          filters={filters}
          toggleFilterTag={toggleFilterTag}
        />

        <TagToggle
          tag={tags.sal}
          label="Salt River"
          filters={filters}
          toggleFilterTag={toggleFilterTag}
        />
      </FormGroup>

      <List component="nav">
        {filteredPlays.map((d: any) => (
          <ListItem key={d.playId} button onClick={() => handleClick(d)}>
            <ListItemText primary={`${d.description}`} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
