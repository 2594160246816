import { useEffect, useRef } from "react";
import { PlayFrameState } from "./PlayFrame";

interface UsePlayFrameStateHandler {
  timebase: any;
  useCameraStore: any;
  onStateChange?: (state: PlayFrameState) => void;
}

const MATRIX_ARRAY_SEPARATOR = "_";

export const deserializeMatrixArray = (str: string) =>
  str.length ? str.split(MATRIX_ARRAY_SEPARATOR).map((n) => +n) : [];

export const serializeMatrixArray = (arr: number[]) =>
  `${arr.join(MATRIX_ARRAY_SEPARATOR)}`;

const isValidCameraMatrix = (matrix: number[]) => matrix.length === 16;

export const usePlayFrameStateHandler = (args: UsePlayFrameStateHandler) => {
  let { timebase, useCameraStore, onStateChange } = args;
  let cameraMatrix = useCameraStore(($: any) => $.cameraMatrix);

  let stateRef = useRef({
    time: timebase.time,
    cam: serializeMatrixArray(cameraMatrix),
    namePlates: undefined
  });
  let camRef = useRef();

  useEffect(() => {
    let listener = () => {
      stateRef.current = { ...stateRef.current, time: timebase.time };
      onStateChange && onStateChange(stateRef.current);
    };

    timebase.addStopListener(listener);

    return () => timebase.removeListener(listener);
  }, [timebase, onStateChange]);

  // if the matrix has changed, update the cam state
  if (camRef.current !== cameraMatrix) {
    camRef.current = cameraMatrix;

    if (isValidCameraMatrix(cameraMatrix)) {
      let str = serializeMatrixArray(cameraMatrix);

      stateRef.current = { ...stateRef.current, cam: str };
      onStateChange && onStateChange(stateRef.current);
    }
  }
};
