// @ts-ignore
import React from "react";
import { Timebase } from "../../models/timebase";
import { TagGroup } from "./TagGroup";
import useMeasure from "react-use-measure";
// @ts-ignore
import styles from "./TaggedTimeline.module.css";

const isTimeRangeNonzero = (timeRange: number[]) =>
  timeRange[1] !== timeRange[0];

const xScale = ({ time, timeRange, width, x0 }: any) => {
  let offset = 8;
  x0 += offset;
  width -= 2 * offset;
  return x0 + (width * (time - timeRange[0])) / (timeRange[1] - timeRange[0]);
};

const epsilon = 33;

export interface TimedTag {
  id?: any;
  text: string;
  time: number;
  color: string;
  backgroundColor: string;
  outlineColor: string;
}

interface TaggedTimelineProps {
  timebase: Timebase;
  tags: TimedTag[];
  onTagClick?: (id: any) => any;
}

export const TaggedTimeline = ({
  timebase,
  tags = [],
  onTagClick,
}: TaggedTimelineProps) => {
  const [ref, { /*x, y,*/ width }] = useMeasure();
  let { time, timeRange } = timebase;
  // TODOHI  lift this up to ancestor component?
  let handleChange = (t: number) => {
    const wasPlaying = timebase.isPlaying;
    timebase.stop();
    timebase.time = t;

    if (wasPlaying) {
      timebase.start();
    }
  };

  // let handleTimelineClick = () => {
  //     console.log('timeline clicked')
  // }
  // let handleTimelineMouseMove = () => {
  //     console.log('timeline mouse moved')
  // }
  let handleTagClick = (id: any) => {
    onTagClick && onTagClick(id);
  };
  let tagGroups = tags.map((tag) => {
    let { id, text, time, backgroundColor, color, outlineColor } = tag;
    let x = isTimeRangeNonzero(timeRange)
      ? xScale({ time, timeRange, width, x0: 10 })
      : 0;
    let delta = Math.abs(time - timebase.time);
    let highlight = delta <= epsilon;

    return (
      <TagGroup
        key={id}
        text={text}
        x={x}
        color={color}
        backgroundColor={backgroundColor}
        outlineColor={outlineColor}
        highlight={highlight}
        onClick={() => handleTagClick(id)}
      />
    );
  });

  return (
    <div className={styles.taggedTimelineContainer}>
      <input
        ref={ref}
        className={styles.taggedTimelineInput}
        type="range"
        min={timeRange[0]}
        max={timeRange[1]}
        step={33}
        value={time}
        onChange={(e) => handleChange(+e.target.value)}
      />

      <svg
        width="100%"
        height="60px"
        style={{ position: "absolute", top: 0, left: 0, pointerEvents: "none" }}
      >
        {tagGroups}
      </svg>
    </div>
  );
};
