import create from "zustand";

export const NO_OVERRIDE_SELECTED = "No override selected";

export interface FigureModelStore {
  figureModelsEnabled: boolean;

  setFigureModelsEnabled(figureModelsEnabled: boolean): void;

  figureModelOpacity: number;

  setFigureModelOpacity(figureModelOpacity: number): void;

  figureModelScale: number;

  setFigureModelScale(figureModelScale: number): void;

  figureModelVerticalOffset: number;

  setFigureModelVerticalOffset(figureModelVerticalOffset: number): void;

  // TODO    this is also used for stick figures. maybe move to a more general store?
  figureModelCastShadow: boolean;

  setFigureModelCastShadow(figureModelCastShadow: boolean): void;

  figureModelFrustumCullingEnabled: boolean;

  setFigureModelFrustumCullingEnabled(
    figureModelFrustumCullingEnabled: boolean
  ): void;

  figureModelUrlDomain?: string;

  setFigureModelUrlDomain(domain: string): void;

  figureModelOverrideUrl?: string;

  setFigureModelOverrideUrl(url: string): void;

  torsoTwistEnabled: boolean;

  setTorsoTwistEnabled(torsoTwistEnabled: boolean): void;

  setStretchEnabled(stretchEnabled: boolean): void;

  stretchEnabled: boolean;

  wireframeEnabled: boolean;

  setWireframeEnabled(wireframeEnabled: boolean): void;

  headTiltEnabled: boolean;

  setHeadTiltEnabled(headTiltEnabled: boolean): void;

  headTurnEnabled: boolean;

  setHeadTurnEnabled(headTurnEnabled: boolean): void;

  headTurnDiagnosticsVizEnabled: boolean;

  setHeadTurnDiagnosticsVizEnabled(
    headTurnDiagnosticsVizEnabled: boolean
  ): void;

  headTurnFirstEnabled: boolean;

  setHeadTurnFirstEnabled(headTurnFirstEnabled: boolean): void;
}

export const createFigureModelStore = () =>
  create<FigureModelStore>((set) => ({
    figureModelsEnabled: false,
    setFigureModelsEnabled: (figureModelsEnabled: boolean) =>
      set({ figureModelsEnabled }),

    figureModelOpacity: 1,
    setFigureModelOpacity: (figureModelOpacity: number) =>
      set({ figureModelOpacity }),

    figureModelScale: 3.35,
    setFigureModelScale: (figureModelScale: number) =>
      set({ figureModelScale }),

    figureModelVerticalOffset: 0.34,
    setFigureModelVerticalOffset: (figureModelVerticalOffset: number) =>
      set({ figureModelVerticalOffset }),

    figureModelCastShadow: true,
    setFigureModelCastShadow: (figureModelCastShadow: boolean) =>
      set({ figureModelCastShadow }),

    figureModelFrustumCullingEnabled: false,
    setFigureModelFrustumCullingEnabled: (
      figureModelFrustumCullingEnabled: boolean
    ) => set({ figureModelFrustumCullingEnabled }),

    figureModelUrlDomain: undefined,
    setFigureModelUrlDomain: (domain) =>
      set(() => ({ figureModelUrlDomain: domain })),

    figureModelOverrideUrl: NO_OVERRIDE_SELECTED,
    setFigureModelOverrideUrl: (url) =>
      set(() => ({ figureModelOverrideUrl: url })),

    torsoTwistEnabled: true,
    setTorsoTwistEnabled: (torsoTwistEnabled: boolean) =>
      set({ torsoTwistEnabled }),

    stretchEnabled: false,
    setStretchEnabled: (stretchEnabled) => set(() => ({ stretchEnabled })),

    wireframeEnabled: false,
    setWireframeEnabled: (wireframeEnabled: boolean) =>
      set({ wireframeEnabled }),

    headTiltEnabled: false, // for now
    setHeadTiltEnabled: (headTiltEnabled: boolean) => set({ headTiltEnabled }),

    headTurnEnabled: false, // for now
    setHeadTurnEnabled: (headTurnEnabled: boolean) => set({ headTurnEnabled }),

    headTurnDiagnosticsVizEnabled: false,
    setHeadTurnDiagnosticsVizEnabled: (
      headTurnDiagnosticsVizEnabled: boolean
    ) => set({ headTurnDiagnosticsVizEnabled }),

    headTurnFirstEnabled: false,
    setHeadTurnFirstEnabled: (headTurnFirstEnabled: boolean) =>
      set({ headTurnFirstEnabled }),
  }));
