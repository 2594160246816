const HANNING_WINDOW_SIZE = 5;

export const interpolateJoint = (
  jointByTimeMap: {
    [time: string]: { x: number; y: number; z: number; id: number };
  },
  frameList: string[],
  jointId: string
) => {
  let start: number | undefined = undefined;
  let gaps: number[][] = [];

  frameList.forEach((frame, idx) => {
    if (jointByTimeMap[frame]) {
      if (start !== undefined) {
        gaps.push([start, idx]);
        start = undefined;
      }
    } else {
      if (start === undefined) {
        start = idx - 1;
      }
    }
  });

  gaps.forEach((gap) => {
    const [f0, f1] = gap;
    if (f0 === -1) return;

    const ts0 = frameList[f0];
    const ts1 = frameList[f1];

    const t0 = new Date(ts0).valueOf();
    const t1 = new Date(ts1).valueOf();

    const { x: x0, y: y0, z: z0 } = jointByTimeMap[ts0];
    const { x: x1, y: y1, z: z1 } = jointByTimeMap[ts1];

    const fx = lerp(t0, x0, t1, x1);
    const fy = lerp(t0, y0, t1, y1);
    const fz = lerp(t0, z0, t1, z1);

    let q = f0 + 1;
    while (q < f1) {
      const tsToInterp = frameList[q];
      const epochToInterp = new Date(tsToInterp).valueOf();

      const newX = fx(epochToInterp);
      const newY = fy(epochToInterp);
      const newZ = fz(epochToInterp);

      jointByTimeMap[tsToInterp] = {
        x: newX,
        y: newY,
        z: newZ,
        id: Number(jointId),
      };
      q++;
    }
  });
};

export const smoothJoint = (
  jointByTimeMap: {
    [time: string]: { x: number; y: number; z: number; id: number };
  },
  frameList: string[]
) => {
  const x: number[] = [];
  const y: number[] = [];
  const z: number[] = [];
  const times: string[] = [];

  frameList.forEach((frame) => {
    const joint = jointByTimeMap[frame];
    if (joint) {
      x.push(joint.x);
      y.push(joint.y);
      z.push(joint.z);
      times.push(frame);
    }
  });

  const smoothX = convolve(x, normalize(hanning(HANNING_WINDOW_SIZE)));
  const smoothY = convolve(y, normalize(hanning(HANNING_WINDOW_SIZE)));
  const smoothZ = convolve(z, normalize(hanning(HANNING_WINDOW_SIZE)));

  times.forEach((time, idx) => {
    jointByTimeMap[time]["x"] = smoothX![idx];
    jointByTimeMap[time]["y"] = smoothY![idx];
    jointByTimeMap[time]["z"] = smoothZ![idx];
  });
};

export const divide = (
  curr: number,
  index: number,
  arr: number[]
): [number] | [number, number] => {
  if (index === arr.length - 1) {
    return [curr];
  }
  const next = arr[index + 1];
  const mid = (curr + next) / 2;
  return [curr, mid];
};

const lerp = (x0: number, y0: number, x1: number, y1: number) => {
  return (x: number) => (y0 * (x1 - x) + y1 * (x - x0)) / (x1 - x0);
};

const padArray = (data: number[], padSize: number): number[] => {
  const ret = new Array(data.length + 2 * padSize);
  for (let i = 0; i < padSize; i++) {
    ret[i] = data[0];
    ret[ret.length - 1 - i] = data[data.length - 1];
  }

  for (let i = 0; i < data.length; i++) {
    ret[i + padSize] = data[i];
  }

  return ret;
};

export const convolve = (data: number[], window: number[]) => {
  if (window.length % 2 === 0) {
    console.error("Hann window length must be odd");
    return;
  }

  const padSize = Math.floor(window.length / 2);
  const paddedData: number[] = padArray(data, padSize);

  const ret: number[] = new Array(data.length);

  for (let i = padSize; i < paddedData.length - padSize; i++) {
    let sum: number = 0.0;
    for (let j = 0; j < window.length; j++) {
      sum += paddedData[i - padSize + j] * window[j];
    }
    ret[i - padSize] = sum;
  }

  return ret;
};

// Implemented the definition from here: https://en.wikipedia.org/wiki/Hann_function
export const hanning = (size: number) => {
  const ret: number[] = new Array(size);
  for (let i = 0; i < size; i++) {
    ret[i] = 0.5 - 0.5 * Math.cos((2.0 * Math.PI * i) / (size - 1));
  }
  return ret;
};

// Normalizes an array so the sum of the array is 1
export const normalize = (arr: number[]) => {
  let sum = 0.0;
  for (let i = 0; i < arr.length; i++) {
    sum += arr[i];
  }

  const ret = new Array(arr.length);
  for (let i = 0; i < arr.length; i++) {
    ret[i] = arr[i] / sum;
  }
  return ret;
};
