import React, { useMemo } from "react";
import { pairs as d3Pairs } from "d3-array";
import { TexMapBall } from "../Ball";
import { CylinderSpan } from "../shapes/CylinderSpan";
import { MeshSeriesGroup } from "../MeshSeriesGroup";

const nodeRadius = 0.03;

const leftColor = "#d7262c";
const leftEdgeColor = "#530c0f";
const rightColor = "#32bf2e";
const rightEdgeColor = "#0f3e0d";

// TODO    there must be a less kludgey way to update CylinderSpan
// id is a forever increasing value, used as a hack to force CylinderSpans to re-render.
let id = 1;

interface WristNodesGroupProps {
  wristSeries: any;
  radius?: number;
  color: any;
}

const WristNodesGroup = ({
  wristSeries,
  radius = nodeRadius,
  color,
}: WristNodesGroupProps) => {
  let points = wristSeries.map((frame: any) => {
    return {
      x: frame.pos[0],
      y: frame.pos[1],
      z: frame.pos[2],
    };
  });
  return (
    <MeshSeriesGroup
      Mesh={TexMapBall}
      props={{ radius, color }}
      positions={points}
    />
  );
};

interface WristStringGroupProps {
  wristSeries: any;
  radius?: number;
  color: any;
}

const WristStringGroup = ({
  wristSeries,
  radius = 0.3 * nodeRadius,
  color,
}: WristStringGroupProps) => {
  let pointSeries = wristSeries.map((wrist: any) => wrist.pos);
  let pointPairs = d3Pairs(pointSeries);
  let material = <meshBasicMaterial attach="material" color={color} />;

  return (
    <group>
      {pointPairs.map((pair: any, i: number) => (
        <CylinderSpan
          key={id++}
          p1={pair[0]}
          p2={pair[1]}
          radius={radius}
          material={material}
        />
      ))}
    </group>
  );
};

const defaultStrobeColor = "#444";
const defaultStrobeRadius = 0.005;

interface WristStrobeGroupProps {
  leftWristSeries: any;
  rightWristSeries: any;
  radius?: number;
  color?: any;
}

const WristStrobeGroup = (props: WristStrobeGroupProps) => {
  let {
    leftWristSeries,
    rightWristSeries,
    radius = defaultStrobeRadius,
    color = defaultStrobeColor,
  } = props;
  let leftPoints = leftWristSeries.map((wrist: any) => wrist.pos);
  let rightPoints = rightWristSeries.map((wrist: any) => wrist.pos);
  let material = <meshBasicMaterial attach="material" color={color} />;
  let edges = leftPoints.map((p: any, i: number) => {
    let q = rightPoints[i];
    return (
      <CylinderSpan
        key={id++}
        p1={p}
        p2={q}
        radius={radius}
        material={material}
      />
    );
  });

  return <group>{edges}</group>;
};

// TODOHI  is the time prop necessary?
interface Props {
  wristSeries: any;
  time: number;
  useSwingStore: any;
}

export const WristTrackGroup = ({
  wristSeries,
  time,
  useSwingStore,
}: Props) => {
  let wristsVisible = useSwingStore(($: any) => $.wristsVisible);
  let wristStringsVisible = useSwingStore(($: any) => $.wristStringsVisible);
  let wristStrobesVisible = useSwingStore(($: any) => $.wristStrobesVisible);
  let {
    leftJointSeries: leftWristSeries,
    rightJointSeries: rightWristSeries,
  } = wristSeries;

  let wristNodesGroup = useMemo(() => {
    return wristsVisible || wristStringsVisible || wristStrobesVisible ? (
      <group>
        <WristNodesGroup wristSeries={leftWristSeries} color={leftColor} />
        <WristNodesGroup wristSeries={rightWristSeries} color={rightColor} />
      </group>
    ) : null;
  }, [
    wristsVisible,
    wristStringsVisible,
    wristStrobesVisible,
    leftWristSeries,
    rightWristSeries,
  ]);

  let wristStringsGroup = useMemo(() => {
    return wristStringsVisible ? (
      <group>
        <WristStringGroup wristSeries={leftWristSeries} color={leftEdgeColor} />
        <WristStringGroup
          wristSeries={rightWristSeries}
          color={rightEdgeColor}
        />
      </group>
    ) : null;
  }, [wristStringsVisible, leftWristSeries, rightWristSeries]);

  let wristStrobesGroup = useMemo(() => {
    return wristStrobesVisible ? (
      <group>
        <WristStrobeGroup
          leftWristSeries={leftWristSeries}
          rightWristSeries={rightWristSeries}
        />
      </group>
    ) : null;
  }, [wristStrobesVisible, leftWristSeries, rightWristSeries]);

  return (
    <group>
      {wristNodesGroup}
      {wristStringsGroup}
      {wristStrobesGroup}
    </group>
  );
};
