import React from "react";
import { Html } from "@react-three/drei";
import { distance } from "../../util/geometry-util";
import styles from "./FigureCaption.module.css";

const htmlZIndexRange = [100, 0];

interface TrackingCaptionProps {
  figureProps: any;
}

const TrackingCaption = ({ figureProps }: TrackingCaptionProps) => {
  let { figureData } = figureProps;

  return (
    <div className={styles.figureCaptionContents}>
      positionId:&nbsp;{figureData.positionId || "NA"}
    </div>
  );
};

interface CaptionProps {
  figureProps: any;
}

const JointsCaption = ({ figureProps }: CaptionProps) => {
  let { jointsMap } = figureProps;

  return (
    <div className={styles.figureCaptionContents}>
      joint&nbsp;count:&nbsp;{Object.keys(jointsMap).length}
    </div>
  );
};

const PositionCaption = ({ figureProps }: CaptionProps) => {
  let { ballPosition } = figureProps;
  let { location } = figureProps.figureData;
  let { x, y, dist, angleDeg } = location;
  let displayX = x.toFixed(1);
  let displayY = y.toFixed(1);
  let displayDist = dist.toFixed(1);
  let displayAngle = angleDeg.toFixed(0);

  let ballDistance = ballPosition && distance(location, ballPosition);
  let formattedBallDistance = ballDistance
    ? `${ballDistance.toFixed(1)}'`
    : "NA";

  if (displayAngle === "-0") {
    displayAngle = "0";
  }

  return (
    <div className={styles.figureCaptionContents}>
      {`x: ${displayX}', y: ${displayY}'`}
      <br />
      {`distance: ${displayDist}'`}
      <br />
      {`angle: ${displayAngle}°`}
      <br />
      {`ball: ${formattedBallDistance}`}
    </div>
  );
};

export const trackingMetadataCaption = "Tracking Metadata";
export const jointsCaption = "Joints Info";
export const positionCaption = "Position";

export const captionTypes = [
  trackingMetadataCaption,
  jointsCaption,
  positionCaption,
];

const captionsMap: { [index: string]: any } = {
  [trackingMetadataCaption]: TrackingCaption,
  [jointsCaption]: JointsCaption,
  [positionCaption]: PositionCaption,
};

interface Props {
  figureProps: any;
  ballPosition: any;
}

export const FigureCaption = ({ figureProps, ballPosition }: Props) => {
  let { useFigureCaptionsStore } = figureProps.rootStore;
  let { captionSet } = useFigureCaptionsStore();

  // figureProps.ballPosition = ballPosition
  let props = { ...figureProps, ballPosition };

  if (captionSet.length === 0) {
    return null;
  }

  return (
    captionSet.length && (
      <Html zIndexRange={htmlZIndexRange}>
        <div className={styles.figureCaption}>
          {captionSet.map((caption: string, i: number) => {
            let Caption = captionsMap[caption];

            // return <Caption key={i} figureProps={figureProps}/>
            return <Caption key={i} figureProps={props} />;
          })}
        </div>
      </Html>
    )
  );
};
