export const testBoneDefs = [
  {
    name: "spine_bone",
    joints: ["MidHip", "Neck"],
  },
  {
    name: "midhip_bone",
    joints: ["MidHip", "Neck"],
  },

  {
    name: "head_bone",
    joints: ["Neck", "Nose"],
  },

  {
    name: "left_torso_bone",
    joints: ["LShoulder", "LHip"],
  },
  {
    name: "right_torso_bone",
    joints: ["RShoulder", "RHip"],
  },

  {
    name: "left_upperarm_bone",
    joints: ["LShoulder", "LElbow"],
  },
  {
    name: "left_forearm_bone",
    joints: ["LElbow", "LWrist"],
  },
  {
    name: "left_thigh_bone",
    // joints: ["LHip", "LKnee"],
    joints: ["MidHip", "LHip"],
  },
  {
    name: "left_shin_bone",
    joints: ["LKnee", "LAnkle"],
  },

  {
    name: "right_upperarm_bone",
    joints: ["RShoulder", "RElbow"],
  },
  {
    name: "right_forearm_bone",
    joints: ["RElbow", "RWrist"],
  },
  {
    name: "right_thigh_bone",
    // joints: ["RHip", "RKnee"],
    joints: ["MidHip", "RHip"],
  },
  {
    name: "right_shin_bone",
    joints: ["RKnee", "RAnkle"],
  },

  // {
  //   name: "left_thigh_axes",
  //   joints: ["LHip", "LKnee"],
  // },
  // {
  //   name: "right_thigh_axes",
  //   joints: ["RHip", "RKnee"],
  // },
];

export const test4BoneDefs = [
  {
    name: "spine_bone",
    joints: ["MidHip", "Neck"],
  },
  {
    name: "right_shoulder_bone",
    joints: ["RShoulder", "RElbow"],
  },
  {
    name: "right_elbow_bone",
    joints: ["RElbow", "RWrist"],
  },
  {
    name: "left_shoulder_bone",
    joints: ["LShoulder", "LElbow"],
  },
  {
    name: "left_elbow_bone",
    joints: ["LElbow", "LWrist"],
  },
  {
    name: "right_hip_bone",
    joints: ["RHip", "RKnee"],
  },
  {
    name: "right_knee_bone",
    joints: ["RKnee", "RAnkle"],
  },
  {
    name: "left_hip_bone",
    joints: ["LHip", "LKnee"],
  },
  {
    name: "left_knee_bone",
    joints: ["LKnee", "LAnkle"],
  },
];

export const nexusTestFigureRigBoneDefs = [
  {
    name: "spine_bone",
    joints: ["MidHip", "Neck"],
  },
  {
    name: "right_clavicle_bone",
    joints: ["Neck", "RShoulder"],
  },
  {
    name: "right_upperarm_bone",
    joints: ["RShoulder", "RElbow"],
  },
];

export const nexusTestFigure2RigBoneDefs = [
  {
    name: "spine01",
    joints: ["MidHip", "Neck"],
  },
  {
    name: "R_shoulder",
    joints: ["RShoulder", "RElbow"],
  },
  {
    name: "R_elbow",
    joints: ["RElbow", "RWrist"],
  },
  {
    name: "L_shoulder",
    joints: ["LShoulder", "LElbow"],
  },
  {
    name: "L_elbow",
    joints: ["LElbow", "LWrist"],
  },

  {
    name: "R_hip",
    joints: ["RHip", "RKnee"],
  },
  {
    name: "R_knee",
    joints: ["RKnee", "RAnkle"],
  },
  {
    name: "L_hip",
    joints: ["LHip", "LKnee"],
  },
  {
    name: "L_knee",
    joints: ["LKnee", "LAnkle"],
  },
];

export const nexusRigBoneDefs = [
  {
    // name: "spine01",
    name: "neck",
    joints: ["MidHip", "MidShoulder"],
  },
  {
    name: "R_shoulder",
    joints: ["RShoulder", "RElbow"],
  },
  {
    name: "R_elbow",
    joints: ["RElbow", "RWrist"],
  },
  {
    name: "L_shoulder",
    joints: ["LShoulder", "LElbow"],
  },
  {
    name: "L_elbow",
    joints: ["LElbow", "LWrist"],
  },

  {
    name: "R_hip",
    joints: ["RHip", "RKnee"],
  },
  {
    name: "R_knee",
    joints: ["RKnee", "RAnkle"],
  },
  {
    name: "L_hip",
    joints: ["LHip", "LKnee"],
  },
  {
    name: "L_knee",
    joints: ["LKnee", "LAnkle"],
  },
];
