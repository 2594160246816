const qaGamePks = ["567633", "566452"];

export const toGumboUrl = (gamePk: string) => {
  let isQa = qaGamePks.includes(gamePk);

  return isQa
    ? `https://qa-statsapi.mlb.com/api/v1.1/game/${gamePk}/feed/live?hydrate=alignment`
    : `https://statsapi.mlb.com/api/v1.1/game/${gamePk}/feed/live?hydrate=alignment`;
};

/*
    game definitions for early tracking development, with 2 games of tracking (ASG and 9/29 OAK @ SEA)
 */

export const games: { [index: string]: any } = {
  "567633": {
    name: "ASG",
    gamePk: "567633",
    // venueId: 5,
    // url: './data/gumbo-567633.json',
    atBatIndexRange: [7, 56], // 2T Bellinger through 8T DeJong
    trackingTimeOffset: 0, //-2700,//-2500,//-2434,//0,
    playIdBlacklist: [],
    defaultPlayId: "647bca1b-c7ba-48e7-8032-f51516b82600",
  },
  "566452": {
    name: "9/29/19 OAK @SEA",
    gamePk: "566452",
    venueId: 680,
    url: "./data/qa-gumbo-566452.json",
    atBatIndexRange: [0, 21], // 1T Profar through T Profar
    trackingTimeOffset: 0, //-2500,//-2490,  // delta from play time to tracking time (tracking time = play time + offset)
    playIdBlacklist: ["155f8623-815e-4f25b7404a9d"],
    defaultPlayId: "",
  },
  "631377": {
    name: "7/23/20 SFG @ LAD",
    gamePk: "631377",
    venueId: 22,
    url: "./data/gumbo-631377.json",
    atBatIndexRange: [0, 75],
    trackingTimeOffset: 0, //-2500,  // delta from play time to tracking time (tracking time = play time + offset)
    defaultPlayId: "",
  },
  "631222": {
    name: "7/31/20 BOS @ NYY",
    gamePk: "631222",
    venueId: 22,
    url: "./data/gumbo-631222.json",
    atBatIndexRange: [0, 70],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "630856": {
    name: "7/26/20 NYY @ WSH",
    gamePk: "630856",
    venueId: 3309,
    url: "./data/gumbo-630856.json",
    // atBatIndexRange: [0, 71],
    atBatIndexRange: [0, 45],
    trackingTimeOffset: 0,
    defaultPlayId: "98753f33-ae79-468c-8b57-17e1f5f246c7",
  },
  "630899": {
    name: "7/25/20 NYY @ WSH",
    gamePk: "630899",
    venueId: 3309,
    url: "./data/gumbo-630899.json",
    atBatIndexRange: [0, 75],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "630962": {
    name: "8/8/20 NYY @ TB, game 1",
    gamePk: "630962",
    venueId: 12,
    url: "./data/gumbo-630962.json",
    atBatIndexRange: [0, 26], // partial game
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "630973": {
    name: "8/8/20 NYY @ TB, game 2",
    gamePk: "630973",
    venueId: 12,
    url: "./data/gumbo-630973.json",
    atBatIndexRange: [0, 61],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },

  "631085": {
    name: "8/5/20 – LAD @ SD",
    gamePk: "631085",
    venueId: 2680,
    url: "./data/gumbo-631085.json",
    atBatIndexRange: [0, 75],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "630201": {
    name: "7/31/20 – LAD @ ARI",
    gamePk: "630201",
    venueId: 15,
    url: "./data/gumbo-630201.json",
    atBatIndexRange: [0, 74],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "631594": {
    name: "7/29/20 – CHC @ CIN",
    gamePk: "631594",
    venueId: 2602,
    url: "./data/gumbo-631594.json",
    atBatIndexRange: [0, 80],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "630960": {
    name: "8/21/20 – TOR @ TB",
    gamePk: "630960",
    venueId: 12,
    url: "./data/gumbo-630960.json",
    atBatIndexRange: [0, 81],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "630897": {
    name: "8/23/20 – MIA @ WSH",
    gamePk: "630897",
    venueId: 3309,
    url: "./data/gumbo-630897.json",
    atBatIndexRange: [0, 74],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "631323": {
    name: "8/24/20 – MIA @ WSH",
    gamePk: "631323",
    venueId: 3309,
    url: "./data/gumbo-631323.json",
    atBatIndexRange: [0, 51],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "631261": {
    name: "9/1/20 – CWS @ MIN",
    gamePk: "631261",
    venueId: 3312,
    url: "./data/gumbo-631261.json",
    atBatIndexRange: [0, 71],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "631044": {
    name: "9/5/20 – TEX @ SEA",
    gamePk: "631044",
    venueId: 680,
    url: "./data/gumbo-631044.json",
    atBatIndexRange: [0, 72],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "631146": {
    name: "9/3/20 – WSH @ PHI",
    gamePk: "631146",
    venueId: 2681,
    url: "./data/gumbo-631146.json",
    atBatIndexRange: [0, 85],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "631618": {
    name: "9/15/20 – CLE @ CHI",
    gamePk: "631618",
    venueId: 17,
    url: "./data/gumbo-631618.json",
    atBatIndexRange: [0, 80],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
  "635897": {
    name: "10/24/20 – LAD @ TB",
    gamePk: "635897",
    venueId: "17",
    url: "./data/gumbo-631618.json",
    atBatIndexRange: [0, 83],
    trackingTimeOffset: 0,
    defaultPlayId: "",
  },
};
