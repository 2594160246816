import React, { useMemo } from "react";
import _compact from "lodash/compact";
import _omit from "lodash/omit";
import _values from "lodash/values";
import { InstancedMeshSpheres, InstancedMeshCylinderSpans } from "../shapes";
import { Head } from "./Head";
import { HeadJoints } from "./HeadJoints";
import { StickHead } from "./StickHead";

import { JointsMapType } from "../Figure";

export const figureNamePrefix = "figure-joint-";

const defaultNodeRadius = 0.1;
const defaultSegmentRadius = 0.1;
const defaultNodeColor = "#fff";
const defaultSegmentColor = "#01203b";
const maxNodeCount = 25; // no more than 25 joints in a Figure
const maxSegmentCount = 20; // no more than 20 segments in a Figure

const stickHeadColor = "#f00";

const headJointNames = ["LEye", "REye", "LEar", "REar", "Nose"];

const filterHeadJoints = (joints: JointsMapType) => _omit(joints, headJointNames);

interface Props {
  useFigureAppearanceStore: any;
  positionId: string | number;
  figureData?: any;
  joints: JointsMapType;
  skeleton: any;
  nodeSize?: number;
  stickSize?: number;
  color?: any;
  opacity?: number;
  castShadow?: boolean;

  [key: string]: any;
}

export const Figure = (props: Props) => {
  let {
    useFigureAppearanceStore,
    positionId,
    figureData,
    joints,
    skeleton,
    nodeSize = 1,
    stickSize = 1,
    color = defaultSegmentColor,
    opacity = 1,
    castShadow = true,
  } = props;

  let {
    blobHeadEnabled,
    headPointsEnabled,
    stickHeadEnabled,
  } = useFigureAppearanceStore();

  let { jointsMap } = figureData;

  // don't render head part nodes
  joints = filterHeadJoints(joints);

  let nodes = _values(joints);
  // TODOHI  make segments a constant to avoid useMemo overhead? keep flexible in case skeleton changes upstream?
  let segments = useMemo(() => _values(skeleton), [skeleton]);
  let segmentPoints = _compact(
    segments.map((edge) => {
      let p1 = joints[edge[0]];
      let p2 = joints[edge[1]];

      return p1 && p2 ? [p1, p2] : null;
    })
  );
  let segmentColor = color;

  let headMaterial = useMemo(
    () => (
      <meshStandardMaterial
        attach="material"
        emissive={color}
        roughness={0.8}
        metalness={1}
        transparent={opacity !== 1}
        opacity={opacity}
      />
    ),
    [opacity, color]
  );
  let stickHeadMaterial = useMemo(
    () => (
      <meshStandardMaterial
        attach="material"
        color={stickHeadColor}
        roughness={0.8}
        metalness={1}
        transparent={opacity !== 1}
        opacity={opacity}
      />
    ),
    [opacity]
  );
  let segmentMaterial = useMemo(
    () => (
      <meshStandardMaterial
        attach="material"
        emissive={color}
        roughness={0}
        metalness={1}
        transparent={opacity !== 1}
        opacity={opacity}
      />
    ),
    [opacity, color]
  );

  let nodesMesh = (
    <InstancedMeshSpheres
      name="nodes"
      nodes={nodes}
      maxCount={maxNodeCount}
      radius={nodeSize * defaultNodeRadius}
      color={defaultNodeColor}
      opacity={opacity}
      castShadow={castShadow}
    />
  );
  let segmentsMesh = (
    <InstancedMeshCylinderSpans
      name="segments"
      pointPairs={segmentPoints}
      maxCount={maxSegmentCount}
      radius={stickSize * defaultSegmentRadius}
      color={segmentColor}
      material={segmentMaterial}
      castShadow={castShadow}
    />
  );

  return (
    <group name={`${figureNamePrefix}${positionId}`} userData={figureData}>
      {nodesMesh}
      {segmentsMesh}
      {blobHeadEnabled && (
        <Head
          joints={joints}
          material={headMaterial}
          size={1}
          castShadow={castShadow}
        />
      )}
      {headPointsEnabled && <HeadJoints jointsMap={jointsMap} />}
      {stickHeadEnabled && (
        <StickHead jointsMap={jointsMap} material={stickHeadMaterial} />
      )}
    </group>
  );
};
