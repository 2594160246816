import React, { useMemo } from "react";
import { pairs as d3Pairs } from "d3-array";
import { TexMapBall } from "../Ball";
import { CylinderSpan } from "../shapes/CylinderSpan";
import { MeshSeriesGroup } from "../MeshSeriesGroup";

const nodeRadius = 0.03;

const leftColor = "#cb26d7";
const leftEdgeColor = "#66156b";
const rightColor = "#2e7bbf";
const rightEdgeColor = "#183f62";

// TODO    there must be a less kludgey way to update CylinderSpan
// id is a forever increasing value, used as a hack to force CylinderSpans to re-render.
let id = 1;

interface GripNodesGroupProps {
  jointSeries: any;
  radius?: number;
  color: any;
}

const GripNodesGroup = ({
  jointSeries,
  radius = nodeRadius,
  color,
}: GripNodesGroupProps) => {
  let points = jointSeries.map((frame: any) => {
    return {
      x: frame.pos[0],
      y: frame.pos[1],
      z: frame.pos[2],
    };
  });
  return (
    <MeshSeriesGroup
      Mesh={TexMapBall}
      props={{ radius, color }}
      positions={points}
    />
  );
};

interface GripStringGroupProps {
  jointSeries: any;
  radius?: number;
  color: any;
}

const GripStringGroup = ({
  jointSeries,
  radius = 0.3 * nodeRadius,
  color,
}: GripStringGroupProps) => {
  let pointSeries = jointSeries.map((joint: any) => joint.pos);
  let pointPairs = d3Pairs(pointSeries);
  let material = <meshBasicMaterial attach="material" color={color} />;

  return (
    <group>
      {pointPairs.map((pair: any, i: number) => (
        <CylinderSpan
          key={id++}
          p1={pair[0]}
          p2={pair[1]}
          radius={radius}
          material={material}
        />
      ))}
    </group>
  );
};

const defaultStrobeColor = "#444";
const defaultStrobeRadius = 0.005;

interface GripStrobeGroupProps {
  leftJointSeries: any;
  rightJointSeries: any;
  radius?: number;
  color?: any;
}

const GripStrobeGroup = (props: GripStrobeGroupProps) => {
  let {
    leftJointSeries,
    rightJointSeries,
    radius = defaultStrobeRadius,
    color = defaultStrobeColor,
  } = props;
  let leftPoints = leftJointSeries.map((joint: any) => joint.pos);
  let rightPoints = rightJointSeries.map((joint: any) => joint.pos);
  let material = <meshBasicMaterial attach="material" color={color} />;
  let edges = leftPoints.map((p: any, i: number) => {
    let q = rightPoints[i];
    return (
      <CylinderSpan
        key={id++}
        p1={p}
        p2={q}
        radius={radius}
        material={material}
      />
    );
  });

  return <group>{edges}</group>;
};

// TODOHI  is the time prop necessary?
interface Props {
  jointSeries: any;
  time?: number;
  useSwingStore: any;
}

export const GripTrackGroup = ({ jointSeries, time, useSwingStore }: Props) => {
  let gripsVisible = useSwingStore(($: any) => $.gripsVisible);
  let gripStringsVisible = useSwingStore(($: any) => $.gripStringsVisible);
  let gripStrobesVisible = useSwingStore(($: any) => $.gripStrobesVisible);
  let { leftJointSeries, rightJointSeries } = jointSeries;

  let gripNodesGroup = useMemo(() => {
    return gripsVisible || gripStringsVisible || gripStrobesVisible ? (
      <group>
        <GripNodesGroup jointSeries={leftJointSeries} color={leftColor} />
        <GripNodesGroup jointSeries={rightJointSeries} color={rightColor} />
      </group>
    ) : null;
  }, [
    gripsVisible,
    gripStringsVisible,
    gripStrobesVisible,
    leftJointSeries,
    rightJointSeries,
  ]);

  let gripStringsGroup = useMemo(() => {
    return gripStringsVisible ? (
      <group>
        <GripStringGroup jointSeries={leftJointSeries} color={leftEdgeColor} />
        <GripStringGroup
          jointSeries={rightJointSeries}
          color={rightEdgeColor}
        />
      </group>
    ) : null;
  }, [gripStringsVisible, leftJointSeries, rightJointSeries]);

  let gripStrobesGroup = useMemo(() => {
    return gripStrobesVisible ? (
      <group>
        <GripStrobeGroup
          leftJointSeries={leftJointSeries}
          rightJointSeries={rightJointSeries}
        />
      </group>
    ) : null;
  }, [gripStrobesVisible, leftJointSeries, rightJointSeries]);

  return (
    <group>
      {gripNodesGroup}
      {gripStringsGroup}
      {gripStrobesGroup}
    </group>
  );
};
