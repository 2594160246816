import React from "react";
import { GameTreeView } from "../GameTreeView";
import { VscCalendar } from "react-icons/vsc";
import { toAbbrevTeamAtTeam } from "field-of-things/src/util/gumbo-util";
import styles from "./StatsAPIGameTreeView.module.css";

interface Props {
  gumbo: any;
  onCalClick: () => void;
  onPlaySelected: (gamePk: string, playId: string) => void;
}

export const StatsAPIGameTreeView: React.FC<Props> = ({
  gumbo,
  onCalClick,
  onPlaySelected,
}) => {
  let date = gumbo?.gameData?.datetime?.dateTime;
  // TODOHI  need to do anything to handle if dateTime is a makeup date?
  let dateStr = date ? new Date(date).toLocaleDateString() : "";

  return (
    <div>
      <div className={styles.headerRow}>
        <button className={styles.calendarButton} onClick={onCalClick}>
          <VscCalendar />
        </button>

        {gumbo && (
          <span>
            {dateStr}, {toAbbrevTeamAtTeam(gumbo)}
          </span>
        )}
      </div>
      <div>
        {gumbo && (
          <GameTreeView gumbo={gumbo} onPlaySelected={onPlaySelected} />
        )}
      </div>
    </div>
  );
};
