import _uniq from "lodash/uniq";
import { StatsApiNS } from "field-of-things/src/types";
import { runnerPositions } from "field-of-things/src/models/positions";

const NON_KEY_PLAY_EVENT_TYPES = [
  0, // BEGIN_OF_PLAY
  2, // BALL_WAS_PITCHED
  1, // PITCHER_GOING_TO_WINDUP
  10, // END_OF_PLAY
  11, // REMOVED
];

const isPlayerEvent = (e: any) => 1 <= e.positionId && e.positionId <= 13;

export type positionIdType = number | null;

export const isPlayerPositionId = (positionId: positionIdType) =>
  positionId && 1 <= positionId && positionId <= 13;
export const isFieldingTeamPositionId = (positionId: positionIdType) =>
  positionId && 1 <= positionId && positionId <= 9;
export const isBattingTeamPositionId = (positionId: positionIdType) =>
  positionId && 10 <= positionId && positionId <= 13;

export const toKeyPositionIds = ({ playData }: { playData: StatsApiNS.PlayData }) => {
  let { trackedEvents } = playData;
  let keyEvents = trackedEvents.filter(
    (e: StatsApiNS.TrackedEvent) => !NON_KEY_PLAY_EVENT_TYPES.includes(e.playEventType)
  );
  let keyPositionIds: number[] = keyEvents
    .filter(isPlayerEvent)
    .map((e: any) => e.positionId);

  // add all possible runners not in trackedEvents
  keyPositionIds = keyPositionIds.concat(runnerPositions.map(position => parseInt(position.id)));

  keyPositionIds = _uniq(keyPositionIds);

  return keyPositionIds;
};

// const toPositionPoints = ({playData, positionId}: any) => {
//     let {targetPositions} = playData
//     let target = targetPositions.find((t: any) => t.positionId === positionId)
//
//     let points = target.positions.map((p: any) => [p.x, p.y])
//
//     debugger
//
//     return points
// }

export const toEventTime = (playData: any, eventType: string) => {
  let event = playData.trackedEvents.find(
    (trackedEvent: any) => trackedEvent.playEvent === eventType
  );

  return event ? new Date(event.timeStamp + "Z").valueOf() : -1;
};
