import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { TabPanel } from "./Knobs";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
// @ts-ignore
import CCapture from "ccapture.js-npmfixed";

interface Props {
  value: any;
  index: number;
  useRenderingStore: any;
  timebase: any;
}

interface RecorderOptions {
  framerate: string;
  verbose: boolean;
  display: boolean;
}

const createRecorder = (options: Partial<RecorderOptions>) => {
  const recorder = new CCapture({
    verbose: options.verbose || false,
    display: options.display || false,
    framerate: options.framerate || 30,
    quality: 100,
    format: "webm",
    timeLimit: 0,
    frameLimit: 0,
    autoSaveTime: 0,
  });
  return recorder;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "block",
    paddingBottom: 15,
  },
  button: {
    margin: theme.spacing(1)
  }
}));

export const RenderPanel = (props: Props) => {
  let { index, value, useRenderingStore, timebase } = props;

  const {
    setRecorder,
    renderer,
    width,
    setWidth,
    height,
    setHeight,
    parentElementRef,
  } = useRenderingStore();

  const classes = useStyles();

  const [framerate, setFramerate] = useState("30");
  const [verbose, setVerbose] = useState(false);
  const [isRendering, setIsRendering] = useState(false);

  return (
    <TabPanel value={value} index={index}>
      <FormControl className={classes.formControl}>
        <InputLabel>Width</InputLabel>
        <Input value={width} onChange={(e) => setWidth(e.target.value)} />
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Height</InputLabel>
        <Input value={height} onChange={(e) => setHeight(e.target.value)} />
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Frame Rate</InputLabel>
        <Input
          value={framerate}
          onChange={(e) => setFramerate(e.target.value)}
        />
      </FormControl>

      <FormControlLabel
        control={
          <Switch
            checked={verbose}
            onChange={(e) => setVerbose(e.target.checked)}
            color="secondary"
            size="small"
          />
        }
        label="Verbose?"
      />
      <FormHelperText>Add verbose CCapture logs to the console</FormHelperText>
      <FormControl className={classes.formControl}>
        <Button className={classes.button}
          variant="contained"
          disabled={isRendering}
          onClick={() => {
            setIsRendering(true);
            const recorder = createRecorder({ framerate, verbose });
            setRecorder(recorder);
            timebase.loop = false;
            timebase.time = 0;
            parentElementRef.current.style.height = `${height}px`;
            parentElementRef.current.style.width = `${width}px`;
            timebase.start();
            setTimeout(() => {
              recorder.start();
            }, 500);

            const stopCallback = () => {
              recorder.stop();
              recorder.save();
              timebase.removeStopListener(stopCallback);
              setIsRendering(false);
            };
            timebase.addStopListener(stopCallback);
          }}
        >
          Save Video
        </Button>
        <Button className={classes.button} variant="contained" onClick={() => {
          const canvas = renderer.domElement;
          const oldWidth = canvas.width;
          const oldHeight = canvas.height;
          renderer.setSize(width, height);
          setTimeout(() => {
            const canvas = renderer.domElement;
            const image = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = 'scout_image.png';
            a.href = image;
            a.click();
            renderer.setSize(oldWidth, oldHeight);
          }, 100)

        }}>Take Screenshot</Button>
      </FormControl>
    </TabPanel>
  );
};
