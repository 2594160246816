import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import { TabPanel } from "./Knobs";
import { playerPositions } from "field-of-things/src/constants/play";
// note: the figure-export functionality is currently disabled until the time prop is active again
// import {getJoints} from '../../util/figure-util'

interface Props {
  value: any;
  index: number;
  useGlStatsStore: any;
  // disabled the time prop for now, until we have a better way to access the timebase without prop-drilling time
  // time: number;
  playData: any;
  playTracking: any;
}

export const DevKnobsPanel = (props: Props) => {
  let {
    index,
    value,
    useGlStatsStore,
    // time,
    playData,
    playTracking,
  } = props;

  let glStatsVisible = useGlStatsStore(($: any) => $.glStatsVisible);
  let toggleGlStatsVisible = useGlStatsStore(
    ($: any) => $.toggleGlStatsVisible
  );

  let glRenderStatsVisible = useGlStatsStore(
    ($: any) => $.glRenderStatsVisible
  );
  let toggleGlRenderStatsVisible = useGlStatsStore(
    ($: any) => $.toggleGlRenderStatsVisible
  );

  let [positionId, setPositionId] = useState(1);

  let handleLogPlayDataClicked = () => {
    let { gamePk } = playData.metaData?.game;

    console.log("playData", gamePk, playData.playId, playData);
  };

  let handleLogPlayTrackingClicked = () => {
    console.log("playTracking", playTracking);
  };

  let handleExportFigureButtonClicked = () => {
    // let joints = getJoints({ playTracking, time, positionId });
    // let json = JSON.stringify(joints);
    // // @ts-ignore
    // let positionName = _find(positions, { positionId }).name;
    //
    // console.log(`${positionName} joints`);
    // console.log(joints);
    // console.log("joints json");
    // console.log(json);
  };

  const handlePositionIdChange = (event: any) => {
    setPositionId(+event.target.value);
  };

  return (
    <TabPanel value={value} index={index}>
      Temporary Dev Controls
      <br />
      <br />
      <br />
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Switch
              checked={glStatsVisible}
              onChange={toggleGlStatsVisible}
              name="gl-stats-toggle"
              color="secondary"
              size="small"
            />
          }
          label="GL FPS Stats"
        />
        <FormHelperText>FPS and Frame Times</FormHelperText>

        <br />

        <FormControlLabel
          control={
            <Switch
              checked={glRenderStatsVisible}
              onChange={toggleGlRenderStatsVisible}
              name="gl-render-stats-toggle"
              color="secondary"
              size="small"
            />
          }
          label="GL Render Stats"
        />
        <FormHelperText>Memory and Renderer</FormHelperText>

        <br />
        <br />
        <br />

        <Button variant="contained" onClick={handleLogPlayDataClicked}>
          log PlayData to dev tools console
        </Button>
        <br />
        <Button variant="contained" onClick={handleLogPlayTrackingClicked}>
          log PlayTracking to dev tools console
        </Button>
        <br />
        <Button
          disabled={true}
          variant="contained"
          onClick={handleExportFigureButtonClicked}
        >
          Log figure to dev tools console
        </Button>
        <br />
        <FormHelperText>
          <i>May not work in plays missing HE metadata</i>
        </FormHelperText>

        <br />
        <select
          disabled={true}
          value={positionId}
          onChange={handlePositionIdChange}
        >
          {playerPositions.map((p: any) => (
            <option key={p.positionId} value={p.positionId}>
              {p.name}
            </option>
          ))}
        </select>

        <br />
        <br />
        <br />

        <div>
          <h3>
            <a
              style={{ color: "#fff" }}
              href="./dev-notes.html"
              target="_blank"
            >
              dev notes
            </a>
          </h3>
        </div>
      </FormControl>
    </TabPanel>
  );
};
