import create from "zustand";

export interface FigurePositionStore {
  playerPathsVisible: boolean;
  togglePlayerPathsVisible(): void;
  playerPositionVisible: boolean;
  togglePlayerPositionVisible(): void;
  playerPositionBaseVisible: boolean;
  togglePlayerPositionBaseVisible(): void;
  playerPositionPointType: string;
  setPlayerPositionPointTypeNone(): void;
  setPlayerPositionPointTypePoint(): void;
  setPlayerPositionPointTypeNeedle(): void;
  figureShellVisible: boolean;
  toggleFigureShellVisible(): void;
  jointCleanup: boolean;
  toggleJointCleanup(): void;
  upsample: boolean;
  toggleUpsample(): void;
}

// TODOHI  add an initial values argument to createFigurePositionStore
export const createFigurePositionStore = () =>
  create<FigurePositionStore>((set) => ({
    playerPathsVisible: false,
    togglePlayerPathsVisible: () =>
      set(($) => ({ playerPathsVisible: !$.playerPathsVisible })),

    playerPositionVisible: false,
    togglePlayerPositionVisible: () =>
      set(($) => ({ playerPositionVisible: !$.playerPositionVisible })),

    playerPositionBaseVisible: true,
    togglePlayerPositionBaseVisible: () =>
      set(($) => ({ playerPositionBaseVisible: !$.playerPositionBaseVisible })),

    playerPositionPointType: "point", // or 'none' or 'needle'
    setPlayerPositionPointTypeNone: () =>
      set(($) => ({ playerPositionPointType: "none" })),
    setPlayerPositionPointTypePoint: () =>
      set(($) => ({ playerPositionPointType: "point" })),
    setPlayerPositionPointTypeNeedle: () =>
      set(($) => ({ playerPositionPointType: "needle" })),

    figureShellVisible: false,
    toggleFigureShellVisible: () =>
      set(($) => ({ figureShellVisible: !$.figureShellVisible })),
    // TODO: Move these to their own store
    jointCleanup: false,
    toggleJointCleanup: () => set(($) => ({ jointCleanup: !$.jointCleanup })),
    upsample: false,
    toggleUpsample: () => set(($) => ({ upsample: !$.upsample })),
  }));
