import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormHelperText from "@material-ui/core/FormHelperText";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import { playerPositions } from "field-of-things/src/constants/play";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  // selectEmpty: {
  //     marginTop: theme.spacing(2),
  // },
  offsetIntervalSliderRoot: {
    width: 200,
  },
}));

interface FigureChaseCamKnobsProps {
  trackIds: number[];
  useCameraStore: any;
}

export const FigureChaseCamKnobs = ({
  trackIds,
  useCameraStore,
}: FigureChaseCamKnobsProps) => {
  let classes = useStyles();
  let figureChaseCamParams = useCameraStore(($: any) => $.figureChaseCamParams);
  let {
    positionId,
    trackId,
    offsetInterval,
    cameraHeight,
    lookAtHeight,
  } = figureChaseCamParams;
  let setFigureChaseCamParams = useCameraStore(
    ($: any) => $.setFigureChaseCamParams
  );

  const handlePositionIdChange = (event: any) => {
    setFigureChaseCamParams({
      ...figureChaseCamParams,
      positionId: +event.target.value,
    });
  };
  const handleTrackIdChange = (event: any) => {
    let newValue =
      event.target.value === "none" ? event.target.value : +event.target.value;

    setFigureChaseCamParams({ ...figureChaseCamParams, trackId: newValue });
  };
  const handleOffsetIntervalChange = (event: any, value: any) => {
    setFigureChaseCamParams({ ...figureChaseCamParams, offsetInterval: value });
  };
  const handleCameraHeightChange = (event: any, value: any) => {
    setFigureChaseCamParams({ ...figureChaseCamParams, cameraHeight: +value });
  };
  const handleLookAtHeightChange = (event: any, value: any) => {
    setFigureChaseCamParams({ ...figureChaseCamParams, lookAtHeight: +value });
  };

  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="trackId">TrackId to Chase</InputLabel>
        <NativeSelect
          value={trackId}
          onChange={handleTrackIdChange}
          inputProps={{
            name: "trackId",
            id: "trackId",
          }}
        >
          <option value="none">None</option>

          {trackIds.map((trackId: any) => (
            <option key={trackId} value={trackId}>
              {trackId}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <FormHelperText>
        Select <i>None</i> to use player position below
      </FormHelperText>

      <br />

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="position-id">Figure to Chase</InputLabel>
        <NativeSelect
          disabled={trackId !== "none" && trackId !== undefined}
          value={positionId}
          onChange={handlePositionIdChange}
          inputProps={{
            name: "position-id",
            id: "position-id",
          }}
        >
          {playerPositions.map((p: any) => (
            <option key={p.positionId} value={p.positionId}>
              {p.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <FormHelperText>So far, tends to work best for batter</FormHelperText>
      <FormHelperText>
        <i>
          Note: some games don't have the metadata
          <br />
          needed for identifying players
        </i>
      </FormHelperText>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Offset Interval (ms)
        <Slider
          min={-2000}
          max={2000}
          value={offsetInterval}
          aria-labelledby="offset-interval-slider"
          step={100}
          valueLabelDisplay="auto"
          onChange={handleOffsetIntervalChange}
        />
        <FormHelperText>
          - offset ➔ Chase Cam
          <br /> + offset ➔ Lead Cam
        </FormHelperText>
      </div>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Camera Height (feet)
        <Slider
          min={0}
          max={30}
          value={cameraHeight}
          aria-labelledby="camera-height-slider"
          step={0.5}
          valueLabelDisplay="auto"
          onChange={handleCameraHeightChange}
        />
      </div>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Look-At Height (feet)
        <Slider
          min={0}
          max={10}
          value={lookAtHeight}
          aria-labelledby="lookat-height-slider"
          step={0.5}
          valueLabelDisplay="auto"
          onChange={handleLookAtHeightChange}
        />
        <FormHelperText>Camera Gaze Target Height</FormHelperText>
      </div>
    </div>
  );
};
