import React from "react";
import { StatsApiStore } from "../../stores/statsApiStore";
import { FigureGroup } from "../Figure";

// import {RandomGroup} from '../../scratch/RandomGroup'

interface Props {
  children?: any;
  rootStore: any;
  frame: any;
  skeleton: any[];
  positionMap: { id: number }[];
  homeTeamId: number;
  battingTeamId: number;
  fieldingTeamId: number;
  batSide: string;
  onClick: (clicked: any) => void;
}

// more args? highlighted-figures, joint/segment visibility/styling, etc. in a store?
export const TrackingGroup = (props: Props) => {
  let {
    children,
    rootStore,
    frame,
    skeleton,
    homeTeamId,
    positionMap,
    battingTeamId,
    fieldingTeamId,
    batSide,
    onClick,
  } = props;

  let {
    useFigureAppearanceStore,
    useFigureModelStore,
    useStatsApiStore,
  } = rootStore;
  const getPlayerById = useStatsApiStore(($: StatsApiStore) => $.getPlayerById);
  let { positions = [] } = frame || { positions: [] };
  let figureModelOpacity = useFigureModelStore(
    ($: any) => $.figureModelOpacity
  );
  let figureModelCastShadow = useFigureModelStore(
    ($: any) => $.figureModelCastShadow
  );

  // TODOHI  can we use i for key? does it matter if the players array order changes?

  let {
    // figureModelCastShadow,
    stickFigureOpacity,
    figuresEnabled: stickFigureEnabled,
  } = useFigureAppearanceStore();
  let { figureModelsEnabled: figureModelEnabled } = useFigureModelStore();

  let figures = positions.map((figure: any, i: number) => {
    let { positionId, jointsMap, trackId } = figure;
    const playerId = positionMap[positionId]?.id;
    const playerInfo = playerId ? getPlayerById(playerId) : undefined;

    // TODOHI  temporarily falling back to trackId for stopgap tracking
    return (
      <FigureGroup
        key={positionId || trackId || i}
        frameNum={frame?.num}
        // key={i}
        rootStore={rootStore}
        stickFigureEnabled={stickFigureEnabled}
        figureModelEnabled={figureModelEnabled}
        positionId={positionId}
        figureData={figure}
        playerInfo={playerInfo}
        jointsMap={jointsMap}
        skeleton={skeleton}
        onClick={onClick}
        homeTeamId={homeTeamId}
        battingTeamId={battingTeamId}
        fieldingTeamId={fieldingTeamId}
        batSide={batSide}
        stickFigureOpacity={stickFigureOpacity}
        modelOpacity={figureModelOpacity}
        castShadow={figureModelCastShadow}
      />
    );

    // return <RandomGroup key={i}/>
  });

  return (
    <group name="tracking-group">
      {children}
      {figures}
    </group>
  );
};
