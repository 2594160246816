import React from "react";
import { Line } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { Vector2 } from "three";

export const flattenPointsArray = (points: any) => {
  return points.reduce((acc: any, p: any) => {
    acc.push(p.x);
    acc.push(p.y);
    acc.push(p.z);

    return acc;
  }, []);
};

const defaultColor = "#000";

export const LineTrack = (props: any) => {
  let { points, linewidth = 1, color = defaultColor } = props;
  let { size } = useThree();
  let resolution: Vector2 = new Vector2(size.width, size.height);
  return (
    <Line
      points={points}
      color={color}
      lineWidth={linewidth}
      resolution={resolution}
    />
  );
};
