import React from "react";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tooltip from "@material-ui/core/Tooltip";
import { CameraKnobsPanel } from "./CameraKnobsPanel";
import { EffectsKnobsPanel } from "./EffectsKnobsPanel";

const MyTab = styled(Tab)({
  minWidth: 60,
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

interface Props {
  value: any;
  index: number;
  trackIds: number[];
  rootStore: any;
}

export const CameraTabPanel = (props: Props) => {
  let { value, index, trackIds, rootStore} = props;
  let {
    useKnobsStore
  } = rootStore;
  let { cameraTabPanelTabValue, setCameraTabPanelTabValue } = useKnobsStore();
  let handleChange = (event: any, newValue: number) => {
    setCameraTabPanelTabValue(newValue);
  };

  return (
    <TabPanel value={value} index={index}>
      <Tabs
        value={cameraTabPanelTabValue}
        onChange={handleChange}
        aria-label="camera knobs tabs"
      >
        <Tooltip title="Camera view options" placement="top">
          <MyTab label="View" {...a11yProps(0)} />
        </Tooltip>
        <Tooltip title="Post processing Effects" placement="top">
          <MyTab label="Effects" {...a11yProps(1)} />
        </Tooltip>
      </Tabs>

      <CameraKnobsPanel
        index={0}
        value={cameraTabPanelTabValue}
        trackIds={trackIds}
        rootStore={rootStore}
      />

      <EffectsKnobsPanel
        index={1}
        value={cameraTabPanelTabValue}
        rootStore={rootStore}
      />

    </TabPanel>
  );
};
