import { useEffect, useState, useRef } from "react";
import _find from "lodash/find";
import { Camera, Vector2, Vector3, WebGLRenderer, MOUSE } from "three";
import { timeToFrame, alphaFilter } from "../../util/tracking-util";
import { GameCamParams } from "../../stores/cameraStore";
import { StatsApiNS } from "../../types";

enum ManipMode {
  IDLE,
  ROTATE,
  PAN,
  ZOOM,
}

export const gammaWrap = 360;
export const thetaLims = { min: 5, max: 85 };
export const deltaDist = 1;
export const minDist = deltaDist * 2;
const zMin = 0.5;

const keys = {
  LEFT: "a",
  UP: "w",
  RIGHT: "d",
  DOWN: "s",
  YAW_RIGHT: "e",
  YAW_LEFT: "q",
  PITCH_UP: "r",
  PITCH_DOWN: "f",
};

const rotateSpeed = 300;
const panSpeed = 50;
const keyPan = 1;
const keyRot = 5;

const zAxis = new Vector3(0, 0, 1);

const clipTheta = (theta: number) => {
  return theta < thetaLims.min
    ? thetaLims.min
    : theta > thetaLims.max
    ? thetaLims.max
    : theta;
};

const windowSize = new Vector2();
interface Props {
  camera: Camera;
  renderer: WebGLRenderer;
  setGameCamParams: any;
  playTracking: StatsApiNS.SkeletalData;
  time: number;
  gameCamParams: GameCamParams;
}

export const GameCamera = (props: Props) => {
  let {
    camera,
    renderer,
    setGameCamParams,
    playTracking,
    time,
    gameCamParams,
  } = props;

  let domElement = renderer.domElement;
  renderer.getSize(windowSize);

  let {
    positionId,
    distance,
    gamma,
    theta,
    alphaXY,
    alphaZ,
    enabled,
    panOffsetX,
    panOffsetY,
  } = gameCamParams;

  // position references
  // initialise positions
  const posIdRef = useRef<number>(-1);
  const camPosRef = useRef(new Vector3());
  const targetPosRef = useRef(new Vector3());
  const targetOffsetRef = useRef(new Vector3());
  const rotOffsetRef = useRef(new Vector3());

  let camPos = camPosRef.current;
  let targetPos = targetPosRef.current;
  let targetOffset = targetOffsetRef.current;
  let rotOffset = rotOffsetRef.current;

  // register user callbacks
  let [manipMode, setManipMode] = useState<ManipMode>(ManipMode.IDLE);

  let rotatePosRef = useRef({
    start: new Vector2(),
    delta: new Vector2(),
    end: new Vector2(),
  });
  let zoomPosRef = useRef({
    start: 0,
    delta: 0,
    end: 0,
  });
  let panPosRef = useRef({
    start: new Vector2(),
    delta: new Vector2(),
    end: new Vector2(),
  });

  const registered = useRef<boolean>(false);

  // TODO: add touch controls
  useEffect(() => {
    // TODO: do we want to useCallback here to de-bloat this useEffect call?
    // utils
    const cameraUnderground = () => {
      return targetPos.z + targetOffset.z + rotOffset.z < zMin;
    };

    // camera transform callbacks
    // rotate
    const handleRotateStart = (x: number, y: number) => {
      rotatePosRef.current.start.set(x, y);
      setManipMode(ManipMode.ROTATE);
    };

    const handleRotateMove = (x: number, y: number) => {
      let rotateEnd = rotatePosRef.current.end;
      let rotateStart = rotatePosRef.current.start;
      let rotateDelta = rotatePosRef.current.delta;

      rotateEnd.set(x, y);
      rotateDelta.subVectors(rotateEnd, rotateStart);

      rotateDelta.setX((rotateDelta.x / windowSize.x) * rotateSpeed);
      rotateDelta.setY((rotateDelta.y / windowSize.y) * rotateSpeed);

      rotateStart.copy(rotateEnd);

      rotate(rotateDelta.x, rotateDelta.y);
    };

    const rotate = (dGamma: number, dTheta: number) => {
      let nGamma = (gameCamParams.gamma - dGamma) % gammaWrap;
      let nTheta = gameCamParams.theta;

      // limit camera z
      if (dTheta > 0 || !cameraUnderground()) {
        nTheta = clipTheta(gameCamParams.theta - dTheta);
      }

      setGameCamParams({
        ...gameCamParams,
        gamma: nGamma,
        theta: nTheta,
      });
    };

    // zoom
    const handleZoomStart = (y: number) => {
      zoomPosRef.current.start = y;
      setManipMode(ManipMode.ZOOM);
    };

    const handleZoomMove = (y: number) => {
      zoomPosRef.current.end = y;
      let delta = zoomPosRef.current.end - zoomPosRef.current.start;

      zoom(delta);

      zoomPosRef.current.delta = delta;
      zoomPosRef.current.start = y;
    };

    const zoom = (deltaY: number) => {
      let zoom = deltaY < 0 ? -deltaDist : deltaDist;

      let nDistance = Math.max(minDist, gameCamParams.distance + zoom);

      setGameCamParams({
        ...gameCamParams,
        distance: nDistance,
      });
    };

    // pan
    const handlePanStart = (x: number, y: number) => {
      panPosRef.current.start.set(x, y);
      setManipMode(ManipMode.PAN);
    };

    const handlePanMove = (x: number, y: number) => {
      let panEnd = panPosRef.current.end;
      let panStart = panPosRef.current.start;
      let panDelta = panPosRef.current.delta;

      panEnd.set(x, y);
      panDelta.subVectors(panEnd, panStart);

      // TODO: account for distance from target here?
      panDelta.setX((panDelta.x / windowSize.x) * panSpeed);
      panDelta.setY((panDelta.y / windowSize.y) * panSpeed);

      panStart.copy(panEnd);

      pan(panDelta.x, -panDelta.y);
    };

    const pan = (dx: number, dy: number) => {
      let nPanX = gameCamParams.panOffsetX + dx;
      let nPanY = gameCamParams.panOffsetY;

      // limit camera z
      if (dy < 0 || !cameraUnderground()) {
        nPanY += dy;
      }

      setGameCamParams({
        ...gameCamParams,
        panOffsetX: nPanX,
        panOffsetY: nPanY,
      });
    };

    // mouse event handlers
    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault();

      switch (event.button) {
        case MOUSE.LEFT:
          if (enabled.rotate) {
            handleRotateStart(event.clientX, event.clientY);
          }
          break;

        case MOUSE.MIDDLE:
          if (enabled.zoom) {
            handleZoomStart(event.clientY);
          }
          break;

        case MOUSE.RIGHT:
          if (enabled.pan) {
            handlePanStart(event.clientX, event.clientY);
          }
          break;

        default:
          break;
      }
    };

    const handleMouseUp = (event: MouseEvent) => {
      event.preventDefault();
      setManipMode(ManipMode.IDLE);
    };

    const handleMouseMove = (event: MouseEvent) => {
      event.preventDefault();

      switch (+manipMode) {
        case ManipMode.ROTATE:
          if (enabled.rotate) {
            handleRotateMove(event.clientX, event.clientY);
          }
          break;

        case ManipMode.ZOOM:
          if (enabled.zoom) {
            handleZoomMove(event.clientY);
          }
          break;

        case ManipMode.PAN:
          if (enabled.rotate) {
            handlePanMove(event.clientX, event.clientY);
          }
          break;

        default:
          break;
      }
    };

    const handleMouseWheel = (event: WheelEvent) => {
      event.preventDefault();
      zoom(event.deltaY);
    };

    const handleSuppress = (event: Event) => {
      event.preventDefault();
    };

    // key press listener
    const handleKeyDown = (event: any) => {
      switch (event.key) {
        case keys.LEFT:
          if (enabled.pan) {
            pan(keyPan, 0);
          }
          break;

        case keys.RIGHT:
          if (enabled.pan) {
            pan(-keyPan, 0);
          }
          break;

        case keys.UP:
          if (enabled.pan) {
            pan(0, -keyPan);
          }
          break;

        case keys.DOWN:
          if (enabled.pan) {
            pan(0, keyPan);
          }
          break;

        case keys.YAW_RIGHT:
          if (enabled.rotate) {
            rotate(-keyRot, 0);
          }
          break;

        case keys.YAW_LEFT:
          if (enabled.rotate) {
            rotate(keyRot, 0);
          }
          break;

        case keys.PITCH_UP:
          if (enabled.rotate) {
            rotate(0, keyRot);
          }
          break;

        case keys.PITCH_DOWN:
          if (enabled.rotate) {
            rotate(0, -keyRot);
          }
          break;

        default:
          break;
      }
    };

    const handleTouchDown = (event: TouchEvent) => {
      if (!enabled.touch) {
        return;
      }

      let numFingers = event.touches.length;
      switch (numFingers) {
        case 1:
          handleRotateStart(event.touches[0].clientX, event.touches[0].clientY);
          break;
        case 2: {
          let t0 = event.touches[0];
          let t1 = event.touches[1];
          let distance = Math.sqrt(
            (t0.pageX - t1.pageX) ** 2 + (t0.pageY - t1.pageY) ** 2
          );
          handleZoomStart(-distance);
          break;
        }
        case 3:
          handlePanStart(event.touches[0].clientX, event.touches[0].clientY);
          break;

        default:
          break;
      }
    };

    const handleTouchMove = (event: TouchEvent) => {
      if (!enabled.touch) {
        return;
      }

      switch (+manipMode) {
        case ManipMode.ROTATE:
          handleRotateMove(event.touches[0].clientX, event.touches[0].clientY);
          break;
        case ManipMode.ZOOM: {
          let t0 = event.touches[0];
          let t1 = event.touches[1];
          let distance = Math.sqrt(
            (t0.pageX - t1.pageX) ** 2 + (t0.pageY - t1.pageY) ** 2
          );
          handleZoomMove(-distance);
          break;
        }
        case ManipMode.PAN:
          handlePanMove(event.touches[0].clientX, event.touches[0].clientY);
          break;

        default:
          break;
      }
    };

    const handleTouchEnd = (event: TouchEvent) => {
      event.preventDefault();
      setManipMode(ManipMode.IDLE);
    };

    if (!registered.current) {
      domElement.addEventListener("contextmenu", handleSuppress, false);

      domElement.addEventListener("mousedown", handleMouseDown, false);
      domElement.addEventListener("mouseup", handleMouseUp, false);
      domElement.addEventListener("mouseleave", handleMouseUp, false);
      domElement.addEventListener("mousemove", handleMouseMove, false);

      domElement.addEventListener("wheel", handleMouseWheel, false);

      domElement.addEventListener("touchstart", handleTouchDown, {
        passive: true,
      });
      domElement.addEventListener("touchmove", handleTouchMove, {
        passive: true,
      });
      domElement.addEventListener("touchend", handleTouchEnd, false);

      window.addEventListener("keydown", handleKeyDown, { passive: true });
    }

    return () => {
      domElement.removeEventListener("contextmenu", handleSuppress);

      domElement.removeEventListener("mousedown", handleMouseDown);
      domElement.removeEventListener("mouseup", handleMouseUp);
      domElement.removeEventListener("mouseleave", handleMouseUp);
      domElement.removeEventListener("mousemove", handleMouseMove);

      domElement.removeEventListener("wheel", handleMouseWheel);

      domElement.removeEventListener("touchstart", handleTouchDown);
      domElement.removeEventListener("touchmove", handleTouchMove);
      domElement.removeEventListener("touchend", handleTouchEnd);

      window.removeEventListener("keydown", handleKeyDown);

      registered.current = false;
    };
  }, [
    camera,
    domElement,
    gameCamParams,
    setGameCamParams,
    enabled,
    manipMode,
    targetOffset,
    rotOffset,
    targetPos,
  ]);

  // get closest frame and drive camera position
  let frame = timeToFrame({ time, playTracking });
  let figure = _find(frame.positions, { positionId });

  if (!figure) {
    return null;
  }

  const rotDir = new Vector3();
  const rotLat = new Vector3();
  const rotUp = new Vector3();

  // Note: all in mlb coords until we set three.js things
  // filter position to reduce noise
  let { location } = figure;
  if (posIdRef.current === positionId) {
    targetPos.set(
      alphaFilter(targetPos.x, location.x, alphaXY),
      alphaFilter(targetPos.y, location.y, alphaXY),
      alphaFilter(targetPos.z, location.z, alphaZ)
    );
  } else {
    targetPos.set(location.x, location.y, location.z);
    posIdRef.current = positionId;
  }

  let rGamma = (gamma % gammaWrap) * (Math.PI / 180);
  let rTheta = clipTheta(theta) * (Math.PI / 180);

  rotOffset.set(
    distance * Math.cos(rGamma) * Math.sin(rTheta),
    distance * Math.sin(rGamma) * Math.sin(rTheta),
    distance * Math.cos(rTheta)
  );

  rotDir.copy(rotOffset).normalize();
  rotLat.copy(rotDir).cross(zAxis).normalize();
  rotUp.copy(rotDir).cross(rotLat).normalize();

  targetOffset.set(0, 0, 0);
  targetOffset.add(rotLat.multiplyScalar(panOffsetX));
  targetOffset.add(rotUp.multiplyScalar(panOffsetY));

  camPos.set(
    targetPos.x + targetOffset.x + rotOffset.x,
    targetPos.y + targetOffset.y + rotOffset.y,
    targetPos.z + targetOffset.z + rotOffset.z
  );

  camera.updateMatrix();

  // set camera position and lookAt, converting to gl coords
  camera.position.set(camPos.x, camPos.z, -camPos.y);
  camera.lookAt(
    targetPos.x + targetOffset.x,
    targetPos.z + targetOffset.z,
    -targetPos.y - targetOffset.y
  );

  camera.up.set(0, 1, 0);

  camera.updateMatrix();

  return null;
};
