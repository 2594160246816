import { Mesh, Vector3 } from "three";

export const makeVector3 = (p: number[], rotate = false) => {
  return rotate
    ? new Vector3(p[0], p[2], -p[1])
    : new Vector3(p[0], p[1], p[2]);
};

export const setVector3 = (v: Vector3, p: number[], rotate = false) => {
  return rotate ? v.set(p[0], p[2], -p[1]) : v.set(p[0], p[1], p[2]);
};

/// borrowed from https://github.com/aframevr/aframe/issues/3137#issuecomment-451915708
export const disposeObject3D = (object: any) => {
  object.traverse((obj: any) => {
    if (obj instanceof Mesh) {
      if (obj.material) {
        if (obj.material.map) {
          obj.material.map.dispose();
          obj.material.map = null;
        }
        if (obj.material.lightMap) {
          obj.material.lightMap.dispose();
          obj.material.lightMap = null;
        }
        if (obj.material.aoMap) {
          obj.material.aoMap.dispose();
          obj.material.aoMap = null;
        }
        if (obj.material.emissiveMap) {
          obj.material.emissiveMap.dispose();
          obj.material.emissiveMap = null;
        }
        if (obj.material.bumpMap) {
          obj.material.bumpMap.dispose();
          obj.material.bumpMap = null;
        }
        if (obj.material.normalMap) {
          obj.material.normalMap.dispose();
          obj.material.normalMap = null;
        }
        if (obj.material.displacementMap) {
          obj.material.displacementMap.dispose();
          obj.material.displacementMap = null;
        }
        if (obj.material.roughnessMap) {
          obj.material.roughnessMap.dispose();
          obj.material.roughnessMap = null;
        }
        if (obj.material.metalnessMap) {
          obj.material.metalnessMap.dispose();
          obj.material.metalnessMap = null;
        }
        if (obj.material.alphaMap) {
          obj.material.alphaMap.dispose();
          obj.material.alphaMap = null;
        }
        if (obj.material.envMaps) {
          obj.material.envMaps.dispose();
          obj.material.envMaps = null;
        }
        if (obj.material.envMap) {
          obj.material.envMap.dispose();
          obj.material.envMap = null;
        }
        if (obj.material.specularMap) {
          obj.material.specularMap.dispose();
          obj.material.specularMap = null;
        }
        if (obj.material.gradientMap) {
          obj.material.gradientMap.dispose();
          obj.material.gradientMap = null;
        }
        obj.material.dispose();
      }
      if (obj.geometry) {
        obj.geometry.dispose();
      }
      // are obj.texture and obj.bufferGeometry left over from an earlier version of three?
      // @ts-ignore
      if (obj.texture) {
        // @ts-ignore
        obj.texture.dispose();
        // @ts-ignore
        obj.texture = {};
      }
      // @ts-ignore
      if (obj.bufferGeometry) {
        // @ts-ignore
        obj.bufferGeometry.dispose();
      }
    }
  });
};
