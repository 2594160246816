import { useEffect, useRef } from "react";

// Lifted from https://reactjs.org/docs/hooks-faq.html
export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
