import React, { useMemo } from "react";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import { Segment } from "../BallSegmentGroup";
import { BallTrail } from "./BallTrail";
import { segmentToPoints } from "../../util/polynomial-util";
import { ballRadius } from "../../constants/dimensions";

interface Props {
  useBallStore: any;
  segments: Segment[];
  time: number;
}

export const BallTrailsGroup = ({ useBallStore, segments, time }: Props) => {
  let {
    ballSize,
    visibleSegmentTypes,
    ballTrailThickness,
    ballTrailColor,
  } = useBallStore();
  let visibleSegments = useMemo(
    () =>
      _filter(segments, (segment: any) =>
        _includes(visibleSegmentTypes, segment.segmentType)
      ),
    [segments, visibleSegmentTypes]
  );
  let pointsList: any[] = useMemo(() => {
    return visibleSegments.map((segment: any) => segmentToPoints(segment));
  }, [visibleSegments]);

  return (
    <group>
      {visibleSegments.map((segment: any, i: number) => (
        <BallTrail
          key={i}
          segment={segment}
          segments={segments}
          points={pointsList[i]}
          time={time}
          radius={ballTrailThickness * ballSize * ballRadius}
          ballTrailColor={ballTrailColor}
        />
      ))}
    </group>
  );
};
