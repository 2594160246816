import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { TabPanel } from "./Knobs";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import { makeStyles } from "@material-ui/core/styles";
import { OrbitControlsKnobs } from "./OrbitControlsKnobs";
import { FigureChaseCamKnobs } from "./FigureChaseCamKnobs";
import { FigureSideCamKnobs } from "./FigureSideCamKnobs";
import { FigureSpinCamKnobs } from "./FigureSpinCamKnobs";
import { BallChaseCamKnobs } from "./BallChaseCamKnobs";
import { FlyCamKnobs } from "./FlyCamKnobs";
import { POVCamKnobs } from "./POVCameraKnobs";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  offsetIntervalSliderRoot: {
    width: 200,
  },
}));

interface Props {
  value: any;
  index: number;
  rootStore: any;
  trackIds: number[];
}

export const CameraKnobsPanel = (props: Props) => {
  let { index, value, trackIds, rootStore } = props;
  let { useCameraStore, useFlyCamStore, usePOVCameraStore } = rootStore;
  let classes = useStyles();
  let {
    cameraType,
    setCameraTypeOrbitControls,
    setCameraTypeFigureChaseCam,
    setCameraTypeFigureSideCam,
    setCameraTypeFigureSpinCam,
    setCameraTypeBallChaseCam,
    setCameraTypeFlyCam,
    setCameraTypePOVCam,
  } = useCameraStore();

  let handleSelectedCameraChange = (event: any) => {
    switch (event.target.value) {
      case "ball-chase-cam":
        setCameraTypeBallChaseCam();
        break;
      case "figure-chase-cam":
        setCameraTypeFigureChaseCam();
        break;
      case "figure-side-cam":
        setCameraTypeFigureSideCam();
        break;
      case "figure-spin-cam":
        setCameraTypeFigureSpinCam();
        break;
      case "fly-cam":
        setCameraTypeFlyCam();
        break;
      case "pov-cam":
        setCameraTypePOVCam();
        break;
      case "orbit-controls":
      default:
        setCameraTypeOrbitControls();
    }
  };

  return (
    <TabPanel value={value} index={index}>
      <FormControl component="fieldset">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="camera-control-select">
            Camera Control
          </InputLabel>
          <NativeSelect
            value={cameraType}
            onChange={handleSelectedCameraChange}
            inputProps={{
              name: "camera-control-select",
              id: "camera-control-select",
            }}
          >
            <option value="orbit-controls">Orbit</option>
            <option value="ball-chase-cam">Ball Chase-Cam</option>
            <option value="figure-chase-cam">Figure Chase-Cam</option>
            <option value="figure-side-cam">Figure Side-Cam</option>
            <option value="figure-spin-cam">Figure Spin-Cam</option>
            <option value="fly-cam">Fly-Cam</option>
            <option value="pov-cam">POV-Cam</option>
          </NativeSelect>
        </FormControl>

        <br />
        {cameraType === "orbit-controls" && (
          <OrbitControlsKnobs useCameraStore={useCameraStore} />
        )}
        {cameraType === "figure-chase-cam" && (
          <FigureChaseCamKnobs
            trackIds={trackIds}
            useCameraStore={useCameraStore}
          />
        )}
        {cameraType === "figure-side-cam" && (
          <FigureSideCamKnobs useCameraStore={useCameraStore} />
        )}
        {cameraType === "figure-spin-cam" && (
          <FigureSpinCamKnobs useCameraStore={useCameraStore} />
        )}
        {cameraType === "ball-chase-cam" && (
          <BallChaseCamKnobs useCameraStore={useCameraStore} />
        )}
        {cameraType === "fly-cam" && (
          <FlyCamKnobs useFlyCamStore={useFlyCamStore} />
        )}
        {cameraType === "pov-cam" && (
          <POVCamKnobs usePOVCameraStore={usePOVCameraStore} />
        )}
      </FormControl>
      <br />
    </TabPanel>
  );
};
