import React from "react";
import { RootStore } from "../../stores";
import { StatsApiNS } from "../../types";
import { HitterReactionStrobes } from "./HitterReactionStrobes";
import { UniformIntervalStrobes } from "./UniformIntervalStrobes";

interface Props {
  rootStore: RootStore;
  playData: StatsApiNS.PlayData;
  playTracking: StatsApiNS.SkeletalData;
  time: number;
  skeleton: any;
}

export const Strobes = ({
  rootStore,
  playData,
  playTracking,
  time,
  skeleton,
}: Props) => {
  let { useFigureStrobeStore } = rootStore;
  let { strobeType, strobesEnabled } = useFigureStrobeStore();

  switch (strobeType) {
    case "uniform-intervals":
      return (
        strobesEnabled && (
          <UniformIntervalStrobes
            time={time}
            rootStore={rootStore}
            playData={playData}
            playTracking={playTracking}
            skeleton={skeleton}
          />
        )
      );
    case "hitter-reaction":
      return (
        <HitterReactionStrobes
          rootStore={rootStore}
          playData={playData}
          playTracking={playTracking}
          skeleton={skeleton}
        />
      );
    default:
      return null;
  }
};
