import { useMemo } from "react";
import { Vector3 } from "three";
import {
  toPositionIdMap,
  toGazePointGL,
  toBallSeries,
  toOffsetCameraPositionGL,
} from "./pov-util";
import { toSampleAtTime } from "../../util/tracking-util";

const _pOffsetCameraPositionGL = new Vector3();

interface Props {
  camera: any;
  playData: any;
  playTracking: any;
  time: number;
  usePOVCameraStore: any;
}

export const POVCamera = (props: Props) => {
  let { camera, playData, playTracking, time, usePOVCameraStore } = props;
  let {
    positionId,
    tiltDegrees,
    smoothingEnabled,
    smoothingType,
    hannWindowSize,
    alpha,
    movingAverageWindowSize,
    ballDirectionMix,
    backOffset,
    verticalOffset,
    horizontalOffset,
  } = usePOVCameraStore();
  let { frames } = playTracking.skeletalData;

  let ballSeries = useMemo(() => toBallSeries({ playData, playTracking }), [
    playData,
    playTracking,
  ]);

  let positionIdMap = useMemo(
    () =>
      toPositionIdMap({
        frames,
        tiltDegrees,
        smoothingEnabled,
        smoothingType,
        hannWindowSize,
        alpha,
        movingAverageWindowSize,
      }),
    [
      frames,
      tiltDegrees,
      smoothingEnabled,
      smoothingType,
      hannWindowSize,
      alpha,
      movingAverageWindowSize,
    ]
  );
  let series = positionIdMap[positionId];
  let frame = useMemo(() => toSampleAtTime({ series, time }), [series, time]);
  let { vectors } = frame;
  let { pCameraPositionGL } = vectors;
  let pGazeGL = useMemo(
    () => toGazePointGL({ frame, ballSeries, time, ballDirectionMix }),
    [frame, ballSeries, time, ballDirectionMix]
  );
  let pOffsetCameraPositionGL = toOffsetCameraPositionGL({
    pCameraPositionGL,
    pGazeGL,
    backOffset,
    verticalOffset,
    horizontalOffset,
    pOffsetCameraPositionGL: _pOffsetCameraPositionGL,
  });

  if (!camera || !frame || !pGazeGL) {
    return null;
  }

  // set camera position and gaze
  camera.position.copy(pOffsetCameraPositionGL);
  camera.lookAt(pGazeGL);

  camera.updateMatrix();
  // TODO    set camera.up with calculated vUpGL? does vUpGL need to be smoothed?
  camera.up.set(0, 1, 0);

  return null;
};
