export interface JerseyOptions {
  name: {
    color: string;
    outlineColor: string | null;
  };
  number: {
    color: string;
    outlineColor: string | null;
    borderColor: string | null;
  };
  jersey: string;
  cap: string;
}
interface TeamColorMap {
  home: JerseyOptions;
  away: JerseyOptions;
}

const DEFAULT_TEAM = {
  home: {
    name: { color: "#000000", outlineColor: null },
    number: {
      color: "#000000",
      borderColor: "#FFFFFF",
      outlineColor: null,
    },
    jersey: "#FFF",
    cap: "#000",
  },
  away: {
    name: { color: "#FFFFFF", outlineColor: null },
    number: {
      color: "#FFFFFF",
      borderColor: "#000000",
      outlineColor: null,
    },
    jersey: "#000",
    cap: "#FFF",
  },
};

export class ColorManager {
  private _colorOptions: { [key: string]: TeamColorMap };
  constructor(colorOptions: { [key: string]: TeamColorMap }) {
    this._colorOptions = colorOptions;
  }

  public get(teamId: number, side: "home" | "away"): JerseyOptions {
    if (this._colorOptions[teamId] === undefined) {
      return DEFAULT_TEAM[side];
    } else {
      return this._colorOptions[teamId][side];
    }
  }
}
