import { useState } from "react";
import { ThreeEvent } from "@react-three/fiber";
import { RootStore } from "../../stores";

interface Props {
  size: [number, number, number];
  rootStore: RootStore;
  position?: [number, number, number];
  color?: string | number;
  onClick?: () => void;
  onHoverChange?: (hover: boolean) => void;

  children?: any;

  [key: string]: any;
}

const defaultColor = "#fff";

export function Box(props: Props) {
  let {
    size,
    rootStore,
    position,
    color = defaultColor,
    onClick,
    onHoverChange,
    children,
    ...otherProps
  } = props;
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);

  let handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    setActive(!active);
    onClick && onClick();
  };

  let handlePointer = (hover: boolean, e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    setHover(hover);
    onHoverChange && onHoverChange(hover);
  };

  return (
    <mesh
      {...otherProps}
      position={position}
      // scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
      // onClick={e => setActive(!active)}
      onClick={(e) => handleClick(e)}
      // onPointerOver={e => setHover(true)}
      // onPointerOut={e => setHover(false)}>
      onPointerOver={(e) => handlePointer(true, e)}
      onPointerOut={(e) => handlePointer(false, e)}
    >
      <boxBufferGeometry attach="geometry" args={size} />
      <meshLambertMaterial
        attach="material"
        color={hovered ? "hotpink" : color}
        opacity={0.5}
        transparent={true}
      />
      {children}
    </mesh>
  );
}
