import _ from "lodash";

export const toFramesFromTimes = (times: number[], playTracking: any) => {
  let { frames } = playTracking.skeletalData;

  return frames.filter((frame: any) => _.includes(times, frame.time));
};

// Returns an array of times for the frames in skeletalData
export const toFrameTimes = (playTracking: any) => {
  let { frames } = playTracking.skeletalData;

  return frames.map((frame: any) => frame.time);
};

// Finds the time in the series closest to the given time, regardless of delta
export const toClosestTime = (times: number[], time: number) =>
  _.minBy(times, (t: number) => Math.abs(t - time));

// Finds the entries in frameTimes nearest to strobeTimes, within an epsilon tolerance
export const toClosestFrameTimes = ({
  strobeTimes,
  frameTimes,
  epsilon = 33.3,
}: {
  strobeTimes: number[];
  frameTimes: number[];
  epsilon?: number;
}) => {
  // TODO    is there a more efficient way?
  let closestFrameTimes = strobeTimes.map((time: number) =>
    toClosestTime(frameTimes, time)
  );

  return closestFrameTimes.filter((t: number | undefined, i: number) => {
    return t && Math.abs(t - strobeTimes[i]) <= epsilon;
  });
};
