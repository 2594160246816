import React, { useMemo } from "react";
import { MeshProps } from "@react-three/fiber";
import * as THREE from "three";
import ballImage from "./ball.jpg";

const ballColor = "#fff";

export const defaultBallRadius = (0.5 * 2.9) / 12;

const texture = new THREE.TextureLoader().load(ballImage);

interface Props extends MeshProps {
  color?: string;
  radius?: number;
  textured?: boolean;
  visible?: boolean;
}

export const TexMapBall = (props: Props) => {
  let {
    color = "#fff",
    radius = defaultBallRadius,
    visible = true,
    textured,
    ...otherProps
  } = props;
  let material = useMemo(
    () => <meshStandardMaterial attach="material" color={color || ballColor} />,
    [color]
  );
  let texturedMaterial = useMemo(
    () => (
      <meshStandardMaterial
        attach="material"
        color={color || ballColor}
        map={texture}
      />
    ),
    [color]
  );

  return (
    <mesh visible={visible} castShadow {...otherProps}>
      <sphereBufferGeometry attach="geometry" args={[radius, 32, 16]} />
      {textured ? texturedMaterial : material}
    </mesh>
  );
};
