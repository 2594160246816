import create from "zustand";

export const defaultGripExtension = 1.2 / 12; // 1.2"

export interface SwingStore {
  swingTrackEnabled: boolean;

  setSwingTrackEnabled(enabled: boolean): void;

  wristsVisible: boolean;

  setWristsVisible(wristsVisible: boolean): void;

  wristStringsVisible: boolean;

  setWristStringsVisible(wristStringsVisible: boolean): void;

  wristStrobesVisible: boolean;

  setWristStrobesVisible(wristStrobesVisible: boolean): void;

  gripsEnabled: boolean;

  setGripsEnabled(gripsEnabled: boolean): void;

  wristGripExtension: number;

  setWristGripExtension(wristGripExtension: number): void;

  gripsVisible: boolean;

  setGripsVisible(gripsVisible: boolean): void;

  gripStringsVisible: boolean;

  setGripStringsVisible(gripStringsVisible: boolean): void;

  gripStrobesVisible: boolean;

  setGripStrobesVisible(gripStrobesVisible: boolean): void;

  batTipsVisible: boolean;

  setBatTipsVisible(batTipsVisible: boolean): void;

  batTipStringsVisible: boolean;

  setBatTipStringsVisible(batTipStringsVisible: boolean): void;

  batStrobeVisible: boolean;

  setBatStrobeVisible(batStrobeVisible: boolean): void;

  batFanVisible: boolean;

  setBatFanVisible(batFanVisible: boolean): void;

  // TODOHI  consolidate batShapeVisible and batAnimationEnabled? drop batShapeVisible?
  batShapeVisible: boolean;

  setBatShapeVisible(batShapeVisible: boolean): void;

  batAnimationEnabled: boolean;

  setBatAnimationEnabled(batAnimationEnabled: boolean): void;

  batLength: number;

  setBatLength(batLength: number): void;

  chokeLength: number;

  setChokeLength(chokeLength: number): void;

  capSwingEnabled: boolean;

  setCapSwingEnabled(capSwingEnabled: boolean): void;

  wristSeparationSwingEndEnabled: boolean;

  setWristSeparationSwingEndEnabled(
    wristSeparationSwingEndEnabled: boolean
  ): void;
}

export const createSwingStore = () =>
  create<SwingStore>((set) => ({
    swingTrackEnabled: false, // xxx
    setSwingTrackEnabled: (enabled: boolean) =>
      set({ swingTrackEnabled: enabled }),

    wristsVisible: false,
    setWristsVisible: (wristsVisible: boolean) => set({ wristsVisible }),

    wristStringsVisible: false,
    setWristStringsVisible: (wristStringsVisible: boolean) =>
      set({ wristStringsVisible }),

    wristStrobesVisible: false,
    setWristStrobesVisible: (wristStrobesVisible: boolean) =>
      set({ wristStrobesVisible }),

    gripsEnabled: true,
    setGripsEnabled: (gripsEnabled: boolean) => set({ gripsEnabled }),

    wristGripExtension: defaultGripExtension,
    setWristGripExtension: (wristGripExtension: number) =>
      set({ wristGripExtension }),

    gripsVisible: false,
    setGripsVisible: (gripsVisible: boolean) => set({ gripsVisible }),

    gripStringsVisible: false,
    setGripStringsVisible: (gripStringsVisible: boolean) =>
      set({ gripStringsVisible }),

    gripStrobesVisible: false,
    setGripStrobesVisible: (gripStrobesVisible: boolean) =>
      set({ gripStrobesVisible }),

    batTipsVisible: false,
    setBatTipsVisible: (batTipsVisible: boolean) => set({ batTipsVisible }),

    batTipStringsVisible: false,
    setBatTipStringsVisible: (batTipStringsVisible: boolean) =>
      set({ batTipStringsVisible }),

    batStrobeVisible: false,
    setBatStrobeVisible: (batStrobeVisible: boolean) =>
      set({ batStrobeVisible }),

    batFanVisible: false,
    setBatFanVisible: (batFanVisible: boolean) => set({ batFanVisible }),

    batShapeVisible: true,
    setBatShapeVisible: (batShapeVisible: boolean) => set({ batShapeVisible }),

    batAnimationEnabled: false,
    setBatAnimationEnabled: (batAnimationEnabled: boolean) =>
      set({ batAnimationEnabled }),

    batLength: 36,
    setBatLength: (batLength: number) => set({ batLength }),

    chokeLength: 3 / 12,
    setChokeLength: (chokeLength: number) => set({ chokeLength }),

    capSwingEnabled: true,
    setCapSwingEnabled: (capSwingEnabled: boolean) => set({ capSwingEnabled }),

    wristSeparationSwingEndEnabled: true,
    setWristSeparationSwingEndEnabled: (
      wristSeparationSwingEndEnabled: boolean
    ) => set({ wristSeparationSwingEndEnabled }),
  }));
