import React from "react";

export const defaultNodeRadius = .05

interface Props {
    pos: [number, number, number],
    radius?: number,
    material: any,
}

export const Node = ({pos, radius=defaultNodeRadius, material}: Props) => (
    <mesh position={pos}>
        <sphereBufferGeometry attach='geometry' args={[radius, 32, 16]}/>
        {material}
    </mesh>
)
