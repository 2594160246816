import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Slider from "@material-ui/core/Slider";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { TabPanel } from "./Knobs";
import { UseStore } from "zustand";
import { FigureCaptionsStore } from "field-of-things/src/stores/figureCaptionsStore";
import { FigureAppearanceStore } from "field-of-things/src/stores/figureAppearanceStore";
import { FigurePositionStore } from "field-of-things/src/stores/figurePositionStore";
import { PlaySummaryStore } from "field-of-things/src/stores/playSummaryStore";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  sliderRoot: {
    width: 200,
  },
  multiSelect: {
    width: 160,
  },
  multiSelectControl: {
    alignItems: "flex-start",
  },
  figureButton: {
    margin: theme.spacing(0.5),
  },
}));

interface Props {
  value: any;
  index: number;
  useFigurePositionStore: UseStore<FigurePositionStore>;
  useFigureAppearanceStore: UseStore<FigureAppearanceStore>;
  useFigureCaptionsStore: UseStore<FigureCaptionsStore>;
  usePlaySummaryStore: UseStore<PlaySummaryStore>;
}

export const FigureKnobsPanel = (props: Props) => {
  let { index, value, useFigureAppearanceStore } = props;

  const classes = useStyles();

  const {
    figuresEnabled,
    setFiguresEnabled,
    figureNodeSize,
    setFigureNodeSize,
    figureStickSize,
    setFigureStickSize,
    figureColoring,
    setFigureColoring,
    blobHeadEnabled,
    setBlobHeadEnabled,
    stickHeadEnabled,
    setStickHeadEnabled,
    headPointsEnabled,
    setHeadPointsEnabled,
    stickFigureOpacity,
    setStickFigureOpacity,
  } = useFigureAppearanceStore();

  let handleNodeSizeChange = (event: any, value: any) => {
    setFigureNodeSize(value);
  };

  let handleStickSizeChange = (event: any, value: any) => {
    setFigureStickSize(value);
  };

  let handleFigureOpacityChange = (event: any, value: any) => {
    setStickFigureOpacity(value);
  };

  return (
    <TabPanel value={value} index={index}>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Switch
              checked={figuresEnabled}
              onChange={() => setFiguresEnabled(!figuresEnabled)}
              name="figures-enabled-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Stick Figures"
        />
        <br />

        <Divider />
        <br />

        <FormControlLabel
          control={
            <Switch
              disabled={!figuresEnabled}
              checked={blobHeadEnabled}
              onChange={() => setBlobHeadEnabled(!blobHeadEnabled)}
              name="blob-head-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Blob Heads"
        />
        <FormControlLabel
          control={
            <Switch
              disabled={!figuresEnabled}
              checked={headPointsEnabled}
              onChange={() => setHeadPointsEnabled(!headPointsEnabled)}
              name="head-points-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Head Points"
        />
        <FormControlLabel
          control={
            <Switch
              disabled={!figuresEnabled}
              checked={stickHeadEnabled}
              onChange={() => setStickHeadEnabled(!stickHeadEnabled)}
              name="stick-head-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Stick Heads"
        />
        <br />
        <Divider />
        <br />

        <Tooltip title="Color stick figures by team" placement="top">
          <FormControlLabel
            control={
              <Switch
                checked={figureColoring !== "none"}
                onChange={() =>
                  setFigureColoring(
                    figureColoring === "none" ? "position" : "none"
                  )
                }
                name="figure-coloring-enabled-toggle"
                color="secondary"
                size="small"
              />
            }
            label="Stick Figure Coloring"
          />
        </Tooltip>

        <br />
        <Divider />
        <br />

        <div className={classes.sliderRoot}>
          Joint Node Size
          <Slider
            min={0.1}
            max={15}
            value={figureNodeSize}
            aria-labelledby="stick-figure-node-size"
            step={0.1}
            valueLabelDisplay="auto"
            onChange={handleNodeSizeChange}
          />
        </div>
        <div className={classes.sliderRoot}>
          Stick Thickness
          <Slider
            min={0.1}
            max={15}
            value={figureStickSize}
            aria-labelledby="stick-figure-stick-size"
            step={0.1}
            valueLabelDisplay="auto"
            onChange={handleStickSizeChange}
          />
        </div>

        <div>
          Opacity
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={stickFigureOpacity}
            onChange={handleFigureOpacityChange}
            valueLabelDisplay="auto"
            aria-labelledby="figure-model-opacity"
          />
        </div>
      </FormControl>
    </TabPanel>
  );
};
