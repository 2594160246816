import React, { useMemo } from "react";
import { flattenPointsArray, LineTrack } from "../Line";

interface Props {
  playData: any;
  useFigurePositionStore: any;
}

const PLAYER_TYP = 1;

const PATH_COLOR = "#666";
const PATH_LINE_WIDTH = 5;

// TODO    for now, filtering to players (typ 1). later, support mergedPositionalData type, and support filtering
export const FigurePathGroup = ({
  playData,
  useFigurePositionStore,
}: Props) => {
  let { playerPathsVisible } = useFigurePositionStore();

  let paths = useMemo(() => {
    if (!playerPathsVisible) {
      return null;
    }
    let { targetPositions } = playData;
    let targets = targetPositions.filter((p: any) => p.typ === PLAYER_TYP);

    return targets.map((t: any, i: number) => {
      let positions = t.positions.map((p: any) => ({ ...p, z: 0.1 }));
      let points = flattenPointsArray(positions);

      return (
        points.length && (
          <LineTrack
            key={i}
            points={points}
            linewidth={PATH_LINE_WIDTH}
            color={PATH_COLOR}
          />
        )
      );
    });
  }, [playData, playerPathsVisible]);

  return <group name="figure-paths">{paths}</group>;
};
