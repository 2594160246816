import React, { useMemo } from "react";
import { StatsApiNS } from "../../types";
import { timeToSample, toMPDTimeSeries } from "../../util/tracking-util";

const HALF_PI = 0.5 * Math.PI;

const BASE_DISC_RADIUS = 1.5;
const BASE_DISC_HEIGHT = 0.05;
const BASE_DISC_COLOR = "#000";
const BASE_DISC_OPACITY = 0.3;
// @ts-ignore
const BASE_DISC_TRANSPARENT = BASE_DISC_OPACITY !== 1;

const POS_POINT_RADIUS = 0.1;
const POS_CYL_RADIUS = 0.05;
// const POS_CYL_HEIGHT = 12
const POS_CYL_COLOR = "#000";
const POS_CYL_OPACITY = 0.9;
// @ts-ignore
const POS_CYL_TRANSPARENT = POS_CYL_OPACITY !== 1;

const POINT_POS_CYL_HEIGHT = 0.3;
const NEEDLE_POS_CYL_HEIGHT = 12;

const none = "none";
const point = "point";
const needle = "needle";

interface PositionMarkProps {
  position: StatsApiNS.Position;
  baseVisible: boolean;
  baseColor?: string | number;
  pointType?: "none" | "point" | "needle";
}

const PositionMark = ({
  position,
  baseVisible,
  baseColor = BASE_DISC_COLOR,
  pointType = point,
}: PositionMarkProps) => {
  let pointCylinderHeight; //= pointType === point ? POINT_POS_CYL_HEIGHT : NEEDLE_POS_CYL_HEIGHT
  switch (pointType) {
    case point:
      pointCylinderHeight = POINT_POS_CYL_HEIGHT;
      break;
    case needle:
      pointCylinderHeight = NEEDLE_POS_CYL_HEIGHT;
      break;
    default:
  }

  return (
    <group>
      {baseVisible && (
        <mesh
          name="base"
          position={[position.x, position.y, 0]}
          rotation-x={HALF_PI}
        >
          <cylinderBufferGeometry
            attach="geometry"
            args={[BASE_DISC_RADIUS, BASE_DISC_RADIUS, BASE_DISC_HEIGHT, 32]}
          />
          <meshStandardMaterial
            attach="material"
            color={baseColor}
            transparent={BASE_DISC_TRANSPARENT}
            opacity={BASE_DISC_OPACITY}
          />
        </mesh>
      )}

      {pointType !== none && (
        <mesh
          name="position"
          position={[position.x, position.y, 0]}
          rotation-x={HALF_PI}
        >
          {pointType === point ? (
            <sphereBufferGeometry
              attach="geometry"
              args={[POS_POINT_RADIUS, 16, 8]}
            />
          ) : (
            <cylinderBufferGeometry
              attach="geometry"
              args={[POS_CYL_RADIUS, POS_CYL_RADIUS, pointCylinderHeight, 16]}
            />
          )}
          <meshStandardMaterial
            attach="material"
            color={POS_CYL_COLOR}
            transparent={POS_CYL_TRANSPARENT}
            opacity={POS_CYL_OPACITY}
          />
        </mesh>
      )}
    </group>
  );
};

interface Target {
  positionId?: number;
  typ: number;
  positions: any[];
}

const toPlayerColor = (t: Target) => {
  let { typ, positionId } = t;
  let pid = positionId as number;
  let isDefense = typ === 1 && 1 <= pid && pid <= 9;
  let isOffense = typ === 1 && 10 <= pid && pid <= 13;

  if (isDefense) {
    return "#333";
  }
  if (isOffense) {
    return "#fff";
  }
  if (isDefense) {
    return "#000";
  }
};

interface Props {
  playData: any;
  time: number;
  useFigurePositionStore: any;
}

const PLAYER_TYP = 1;

// TODO    for now, filtering to players (typ 1). later, support mergedPositionalData type, and support filtering
export const FigurePositionGroup = ({
  playData,
  time,
  useFigurePositionStore,
}: Props) => {
  // let {playerPathsVisible} = useFigurePathStore()
  let playerPositionVisible = useFigurePositionStore(
    ($: any) => $.playerPositionVisible
  );
  let baseVisible = useFigurePositionStore(
    ($: any) => $.playerPositionBaseVisible
  );
  let pointType = useFigurePositionStore(($: any) => $.playerPositionPointType);

  let targets = useMemo(() => {
    let { targetPositions } = playData;

    return targetPositions.filter((p: any) => p.typ === PLAYER_TYP);
  }, [playData]);

  // calculate positionIdSeriesMap once up front
  let positionIdSeriesMap = useMemo(() => {
    let map: { [index: number]: any } = {};

    for (let item of targets) {
      let { positions } = item;

      map[item.positionId] = toMPDTimeSeries(positions);
    }

    return map;
  }, [targets]);

  let marks = useMemo(() => {
    if (!playerPositionVisible) {
      return null;
    }

    return targets.map((t: any, i: number) => {
      let { positionId } = t;
      let series = positionIdSeriesMap[positionId];

      // let series = useMemo(() => toMPDTimeSeries({positions}), [positions])
      let position = timeToSample({ time, series });
      let baseColor = toPlayerColor(t);

      return (
        position && (
          <PositionMark
            key={positionId || i}
            position={position}
            baseVisible={baseVisible}
            baseColor={baseColor}
            pointType={pointType}
          />
        )
      );
    });
  }, [
    time,
    targets,
    playerPositionVisible,
    baseVisible,
    pointType,
    positionIdSeriesMap,
  ]);

  return <group name="figure-positions">{marks}</group>;
};
