import React from "react";

const RING_COLOR = "#f00";

interface Props {
  radius: number;
  height: number;
}

const tubeRadius = 0.05;
const radialSegments = 8;
const tubularSegments = 128;

export const RingMark = ({ radius, height }: Props) => (
  <mesh name="ring-mark" position={[0, 0, height]} castShadow>
    <torusBufferGeometry
      attach="geometry"
      args={[radius, tubeRadius, radialSegments, tubularSegments]}
    />
    <meshStandardMaterial attach="material" color={RING_COLOR} />
  </mesh>
);
