import create from "zustand";

interface ambientLightParamsParams {
  color: string | number;
  intensity: number;
}

interface directionalLightParams {
  color: string | number;
  intensity: number;
  position: number[];
}

export interface LightingStore {
  shadowsEnabled: boolean;
  setShadowsEnabled(shadowsEnabled: boolean): void;
  skyEnabled: boolean;
  setSkyEnabled(skyEnabled: boolean): void;
  glClearColor: string;
  setGlClearColor(glClearColor: any): void;
  ambientLightParams: ambientLightParamsParams;
  setAmbientLightParams(params: ambientLightParamsParams): void;
  directionalLightParams: directionalLightParams;
  setDirectionalLightParams(params: directionalLightParams): void;
}

export const createLightingStore = () =>
  create<LightingStore>((set) => ({
    shadowsEnabled: true,
    setShadowsEnabled: (shadowsEnabled: boolean) => set({ shadowsEnabled }),

    skyEnabled: true,
    setSkyEnabled: (skyEnabled: boolean) => set({ skyEnabled }),

    glClearColor: "#4f6374",
    setGlClearColor: (glClearColor: any) => set({ glClearColor }),

    ambientLightParams: {
      color: "#fff",
      intensity: 0.3,
    },
    setAmbientLightParams: (params: ambientLightParamsParams) =>
      set({ ambientLightParams: params }),

    directionalLightParams: {
      color: "#fff",
      intensity: 1,
      position: [240, 250, 210], // gl coords
    },
    setDirectionalLightParams: (params: directionalLightParams) =>
      set({ directionalLightParams: params }),
  }));
