import React from "react";
import { Path } from "./Path";
import {
  decimatePointsSpatially /* simplifyArrayPoints*/,
} from "../../util/geometry-util";
import { RoleMaterials, PlayRole } from "field-of-things/src/components/PlaySummary";

interface PadProps {
  material: any;
  radius?: number;

  [key: string]: any;
}

const Pad: React.FC<PadProps> = ({ material, radius = 2, ...other }) => {
  return (
    <mesh rotation={[0.5 * Math.PI, 0, 0]} {...other}>
      <cylinderBufferGeometry
        attach="geometry"
        args={[radius, radius, 0.2, 64]}
      />
      {material}
    </mesh>
  );
};

// note: BEGIN_OF_PLAY events may have playEventType 10, the END_OF_PLAY playType, so best to search by playEvent
// instead of playEventType when using event type instead of simply array position

/**
 * Trims frame series to times from 2nd event to penultimate event
 * @param frames
 * @param playData
 */ const trimFrames = ({
  frames,
  playData,
}: {
  frames: any[];
  playData: any;
}) => {
  let { trackedEvents } = playData;
  let secondEvent = trackedEvents[1];
  let startTime = new Date(`${secondEvent.timeStamp}Z`).valueOf();
  let penultimateEvent = trackedEvents[trackedEvents.length - 2];
  // let lastEvent = trackedEvents[trackedEvents.length - 1]
  let endTime = new Date(`${penultimateEvent.timeStamp}Z`).valueOf();

  return frames.filter(
    (frame: any) => startTime <= frame.time && frame.time <= endTime
  );
};

const startPadRadius = 1.2;
const endPadRadius = 2.5;

const toStartPad = ({
  playRole,
  position,
  roleMaterials,
}: {
  playRole: PlayRole;
  position: number[];
  roleMaterials: RoleMaterials
}) =>
  position && (
    <Pad
      radius={startPadRadius}
      // @ts-ignore
      material={roleMaterials[playRole].pathEndpointMaterial}
      position={position}
    />
  );

const toEndPad = ({
  playRole,
  position,
  roleMaterials,
}: {
  playRole: PlayRole;
  position: number[];
  roleMaterials: RoleMaterials
}) =>
  position && (
    <Pad
      radius={endPadRadius}
      // @ts-ignore
      material={roleMaterials[playRole].pathEndpointMaterial}
      position={position}
    />
  );

interface FigurePathGroupProps {
  frames: any[];
  positionId?: number;
  playData: any;
  time: number;
  playRole: PlayRole;
  roleMaterials: RoleMaterials;
  [key: string]: any;
}

/**
 * Wraps Path in order to encapsulate point calculations
 * @param positionId
 * @param frames
 * @param playRole
 * @param playData
 * @param time
 * @param other
 * @constructor
 */
export const FigurePathGroup: React.FC<FigurePathGroupProps> = ({
  positionId,
  frames,
  playRole,
  playData,
  time,
  roleMaterials,
  ...other
}) => {
  // TODOHI  why only 32 frames? how do they have a duration of 800 ms?
  // debugger // why trimmed and clipped ending up empty? just because at BoP?

  let trimmedFrames = trimFrames({ frames, playData });
  let clippedFrames = trimmedFrames.filter((f: any) => f.time <= time);
  let clippedFramePoints = clippedFrames.filter(f => f.location).map((f: any) => f.location);

  // TODOHI  pre-calculate points for full path, then clip temporally here?
  // TODOHI  or instead pass pre-calculated full path points and a fraction to Path?
  let points = decimatePointsSpatially({ points: clippedFramePoints });

  if (points.length < 2) {
    return null;
  }

  let p1 = points[0];
  let startPoint = [p1.x, p1.y, 0];
  let p2 = points[points.length - 1];
  let endPoint = [p2.x, p2.y, 0];

  // @ts-ignore
  let material = roleMaterials[playRole].pathMaterial;

  let startPad = toStartPad({ playRole, position: startPoint, roleMaterials });
  let endPad = toEndPad({ playRole, position: endPoint, roleMaterials });

  return (
    <group {...other}>
      <Path points={points} material={material} />
      {startPad}
      {endPad}
    </group>
  );
};
