import { csvParse } from "d3-dsv";

// extracted from https://docs.google.com/spreadsheets/d/1Q_Y-ciRt5HDrKpfHgfo1zeW2bAZ47keINKJQZe7VuBs/edit#gid=966363448
const csv = `venue_id,team,left_x,left_y,left_z,right_x,right_y,right_z
15,Arizona,-233.990,234.160,44.410,237.120,236.880,44.890
4705,Atlanta,-237.810,237.920,45.010,230.820,230.630,45.380
2,Baltimore,-235.560,236.090,33.280,224.590,224.550,34.660
3,Boston,-218.510,217.900,38.380,213.23,213.65,32.13
17,Chi Cubs,-247.710,247.370,53.640,248.460,248.610,45.620
4,Chi White Sox,-233.550,232.930,45.020,237.610,237.060,45.450
2602,Cincinnati,-233.290,233.620,45.850,230.820,230.670,45.550
5,Cleveland,-230.640,230.660,44.960,230.410,230.430,44.970
19,Colorado,-246.440,246.560,39.390,249.200,249.330,39.220
2394,Detroit,-242.9,243.03,45.01,234,233.77,44.95
2392,Houston,-223.360,223.290,53.090,231.160,230.930,50.280
7,Kansas City,-230.810,235.900,44.800,230.620,235.550,45.000
1,LA Angels,-235.560,235.740,44.480,235.850,236.160,44.940
22,LA Dodgers,-231.450,231.510,51.500,231.740,231.750,51.500
4169,Miami,-243.420,243.560,45.470,237.030,236.930,45.220
32,Milwaukee,-243.36,243.31,49.62,244.59,244.56,49.65
3312,Minnesota,-239.860,240.070,45.030,232.320,232.140,45.080
3289,NY Mets,-238.800,238.740,35.710,235.220,235.050,35.270
3313,NY Yankees,-225.360,225.370,45.250,222.400,222.350,45.090
10,Oakland,-233.860,234.350,30.360,233.290,233.630,30.200
2681,Philadelphia,-232.990,233.020,44.550,233.050,233.190,44.990
31,Pittsburgh,-231.160,231.560,45.040,227.790,228.050,45.880
4249,Salt River,-244.970,243.670,22.140,243.590,244.850,22.170
2680,San Diego,-236.210,237.070,37.050,227.710,227.110,45.010
2395,San Francisco,-241.190,241.840,45.230,218.820,218.680,54.050
680,Seattle,-236.490,236.480,45.280,232.560,232.490,45.020
2889,St. Louis,-240.84,240.72,45.13,239.2,239.12,45
12,Tampa Bay,-223.850,223.770,40.030,227.130,227.110,39.990
5325,Texas,-233.620,233.800,44.830,228.380,228.960,44.990
14,Toronto,-232.22,232.8,48.49,232.21,232.83,48.22
3309,Washington,-238.610,238.530,42.710,236.830,236.740,42.740
2756,Toronto-Buffalo,-230.590,231.480,45.050,229.730,230.650,44.990
2536,Toronto-Dunedin,-232.671,232.899,19.423,231.921,232.043,19.406
0,average,-235.281,235.526,43.306,232.734,232.950,43.378`;

const toRow = (d: any) => {
  let left =
    d.left_x && d.left_y && d.left_z
      ? {
          x: +d.left_x,
          y: +d.left_y,
          z: +d.left_z,
        }
      : undefined;
  let right =
    d.right_x && d.right_y && d.right_z
      ? {
          x: +d.right_x,
          y: +d.right_y,
          z: +d.right_z,
        }
      : undefined;
  let row: any = {
    venueId: +d.venue_id,
    team: d.team,
  };

  if (left) {
    row.left = left;
  }

  if (right) {
    row.right = right;
  }

  return row;
};

export const lidarFoulPoles = csvParse(csv, toRow);
