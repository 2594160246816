import { useMemo } from "react";
import _findIndex from "lodash/findIndex";
import _maxBy from "lodash/maxBy";
import { toBallPointSeries, toPointInSeries } from "../../util/polynomial-util";
import { lerpPoints } from "../../util/geometry-util";
import { toReleaseTime } from "../../util/time-util";

interface toBallPointProps {
  t: number;
  series: any[];
  // clamp?: boolean,
}

/**
 * Returns a point from a time series of points for the given time. if the time falls between two points,
 * we interpolate between the points.
 * @param t
 * @param series
 * @param clamp
 */
const toPoint = ({ t, series /*, clamp = true*/ }: toBallPointProps) => {
  // TODO    implement optional clamping

  let lastIndex = series.length - 1;

  // find the first sample, u, where its time <= t
  let j = _findIndex(series, (u, i) => {
    if (i === lastIndex) {
      return true;
    } else {
      let v = series[i + 1];

      return u.t <= t && t < v.t;
    }
  });

  if (j === lastIndex) {
    return { ...series[j] };
  } else {
    let p = series[j];
    let q = series[j + 1];
    let n = (t - p.t) / (q.t - p.t);

    return { ...lerpPoints(p, q, n), t };
  }
};

const toHeight = ({
  series,
  targetPoint,
  releaseRelativeTimeSec,
  ballChaseCamParams,
}: any) => {
  let {
    heightMethod,
    constantHeightMethod,
    fixedHeight,
    // constantHeightApexOffset,
    apexMultiple,
    constantHeightOffset,
    // heightOffsetFactor,
    multiplePoint,
    heightMultiple,

    offsetInterval,
  } = ballChaseCamParams;
  // let ballPoint = toPoint({t: releaseRelativeTimeSec, series})

  switch (heightMethod) {
    case "constant-height":
      switch (constantHeightMethod) {
        case "apex-relative-height":
          // TODOHI  memoize
          let apexHeight = _maxBy(series, (p: any) => p.z).z;

          // return apexHeight + constantHeightApexOffset
          return apexMultiple * apexHeight;
        case "set-height":
        default:
          return fixedHeight;
      }

    case "constant-height-offset":
      // no lower than 1 foot off the ground
      return Math.max(1, targetPoint.z + constantHeightOffset);

    case "variable-height":
      let t0 = series[0].t;
      let offsetIntervalSec = offsetInterval / 1000;
      let releaseRelativeFollowTimeSec = Math.max(
        t0,
        releaseRelativeTimeSec + offsetIntervalSec
      );
      // map times to series to get positions
      let offsetPoint = toPoint({ t: releaseRelativeFollowTimeSec, series });

      return (
        heightMultiple *
        (multiplePoint === "ball" ? targetPoint.z : offsetPoint.z)
      );

    default:
      return 10;
  }
};

interface Props {
  camera: any;
  time: number;
  playData: any;
  ballChaseCamParams: any;
}

export const BallChaseCamera = (props: Props) => {
  let { camera, playData, time, ballChaseCamParams } = props;
  let { offsetInterval } = ballChaseCamParams;
  let releaseTime = toReleaseTime({ playData });
  let releaseRelativeTimeSec = (time - releaseTime) / 1000;
  let segments = useMemo(() => playData?.ballSegments?.genericSegments || [], [
    playData?.ballSegments?.genericSegments,
  ]);
  let series = useMemo(() => toBallPointSeries({ segments }), [segments]);
  let t0 = series[0].t;
  let offsetIntervalSec = offsetInterval / 1000;
  let releaseRelativeFollowTimeSec = Math.max(
    t0,
    releaseRelativeTimeSec + offsetIntervalSec
  );
  let p = toPointInSeries({ t: releaseRelativeFollowTimeSec, series });
  let ballPoint = toPointInSeries({ t: releaseRelativeTimeSec, series });

  // useEffect(() => {
  //     console.log('time', time)
  //     console.log('offsetInterval', offsetInterval)
  //     console.log('offsetIntervalSec', offsetIntervalSec)
  //     console.log('t0', t0)
  //     console.log('releaseTime', releaseTime)
  //     console.log('releaseRelativeTimeSec', releaseRelativeTimeSec)
  //     console.log('releaseRelativeFollowTimeSec', releaseRelativeFollowTimeSec)
  //     console.log('camera', p)
  //     console.log('ball', ballPoint)
  // }, [time])

  let cameraHeight = toHeight({
    series,
    targetPoint: ballPoint,
    releaseRelativeTimeSec,
    ballChaseCamParams,
  });

  if (!camera) {
    return null;
  }

  camera.position.set(p.x, cameraHeight, -p.y);
  camera.lookAt(ballPoint.x, ballPoint.z, -ballPoint.y);
  camera.updateMatrix();
  camera.up.set(0, 1, 0);

  return null;
};
