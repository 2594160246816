import React from "react";
import _find from "lodash/find";
import { fenceMap } from "./fences-lidar";
import { VenueFieldShapeGroup } from "./VenueFieldShapeGroup";
import { Fence } from "./Fence";
import { PolesGroup } from "./PolesGroup";
import { FoulLinesGroup } from "./FoulLinesGroup";
import { lidarFoulPoles } from "./foulPoleData";

// let defaultVenueId = 15 // ARI
// let defaultVenueId = 4705 // ATL
// let defaultVenueId = 2 // BAL
// let defaultVenueId = 3 // BOS
let defaultVenueId = 17; // CHC
// let defaultVenueId = 2602 // CIN
// let defaultVenueId = 5 // CLE
// let defaultVenueId = 19 // COL
// let defaultVenueId = 4 // CWS
// let defaultVenueId = 2394 // DET

// let defaultVenueId = 2392 // HOU
// let defaultVenueId = 7 // KCR
// let defaultVenueId = 1 // LAA
// let defaultVenueId = 22 // LAD
// let defaultVenueId = 4169 // MIA
// let defaultVenueId = 32 // MIL
// let defaultVenueId = 3312 // MIN
// let defaultVenueId = 3289 // NYM
// let defaultVenueId = 3313 // NYY
// let defaultVenueId = 10 // OAK

// let defaultVenueId = 2681 // PHI
// let defaultVenueId = 31 // PIT
// let defaultVenueId = 2680 // SDP
// let defaultVenueId = 680 // SEA
// let defaultVenueId = 2395; // SFG
// let defaultVenueId = 2889 // STL
// let defaultVenueId = 12 // TBR
// let defaultVenueId = 13 // TEX
// let defaultVenueId = 5325 // TEX
// let defaultVenueId = 14 // TOR
// let defaultVenueId = 2756 // BUF
// let defaultVenueId = 3309 // WSH

// let defaultVenueId = 3140 // Polo Grounds

const defaultPoleHeight = 44;

/**
 * Returns a pair of pole {x,y,height} values.
 * If available uses venue specific x,y,z from lidarFoulPoles, the isolated lidar foul pole data.
 * Otherwise, uses the endpoints from the 91 fence points lidar data.
 * @param fencePoints
 * @param venueId
 */
export const toPolePoints = (fencePoints: any, venueId: number) => {
  // from complete lidar fence data:
  let polePoints = [fencePoints[0], fencePoints[fencePoints.length - 1]];
  // from isolated lidar pole data
  let lidarPoles = _find(lidarFoulPoles, { venueId });

  if (!lidarPoles) {
    return null;
  }

  let { left: leftLidarPole, right: rightLidarPole } = lidarPoles;
  let leftPole = { ...polePoints[0] };
  let rightPole = { ...polePoints[1] };

  if (leftLidarPole) {
    leftPole.x = leftLidarPole.x;
    leftPole.y = leftLidarPole.y;
    leftPole.z = leftLidarPole.z;
  } else {
    leftPole.z = defaultPoleHeight;
  }

  if (rightLidarPole) {
    rightPole.x = rightLidarPole.x;
    rightPole.y = rightLidarPole.y;
    rightPole.z = rightLidarPole.z;
  } else {
    rightPole.z = defaultPoleHeight;
  }

  return [leftPole, rightPole];
};

interface Props {
  useFieldAppearanceStore: any;
  venueId?: number;
  shadows?: boolean;
}

export const VenueFieldGroup = ({
  venueId = defaultVenueId,
  useFieldAppearanceStore,
  shadows = false,
}: Props) => {
  let points = fenceMap[venueId];
  let polePoints = toPolePoints(points, venueId);
  let fenceMaterial = useFieldAppearanceStore(($: any) => $.fenceMaterial);

  return (
    <group name="venue-field-group">
      <VenueFieldShapeGroup
        venueId={venueId}
        useFieldAppearanceStore={useFieldAppearanceStore}
        shadows={shadows}
      />
      <Fence points={points} material={fenceMaterial} />
      {polePoints && <PolesGroup points={polePoints} />}
      <FoulLinesGroup points={points} />
    </group>
  );
};
