import React from "react";
import { Color } from "three";
import { JointsMapType } from "../Figure";
import { ArrayPoint3 } from "../../util/geometry-util";

interface SphereProps {
  position: ArrayPoint3;
  radius?: number;
  material: any;
  visible?: boolean;
}

export const Sphere = ({
  position,
  radius = 0.025,
  material,
  visible = true,
}: SphereProps) => {
  return (
    <mesh position={position} visible={visible}>
      <sphereBufferGeometry args={[radius, 32, 32]} />
      {material}
    </mesh>
  );
};

export const earColor = new Color("#012591");
export const eyeColor = new Color("#fff");
export const noseColor = new Color("#bc0303");

export const earMaterial = (
  <meshStandardMaterial
    attach="material"
    color={earColor}
    emissive={earColor}
    roughness={0.2}
    metalness={1}
  />
);
export const eyeMaterial = (
  <meshStandardMaterial
    attach="material"
    color={eyeColor}
    emissive={eyeColor}
    roughness={0.2}
    metalness={1}
  />
);
export const noseMaterial = (
  <meshStandardMaterial
    attach="material"
    color={noseColor}
    emissive={noseColor}
    roughness={0.3}
    metalness={1}
  />
);

interface Props {
  jointsMap: JointsMapType;
}

export const HeadJoints = ({ jointsMap }: Props) => {
  let { Nose, LEye, REye, LEar, REar } = jointsMap;

  return (
    <group>
      <Sphere position={LEar} material={earMaterial} visible={!!LEar} />
      <Sphere position={REar} material={earMaterial} visible={!!REar} />
      <Sphere position={LEye} material={eyeMaterial} visible={!!LEye} />
      <Sphere position={REye} material={eyeMaterial} visible={!!REye} />
      <Sphere position={Nose} material={noseMaterial} visible={!!Nose} />
    </group>
  );
};
