import { Euler, Object3D, Vector3 } from "three";
import simplify from "simplify-js";
import _findIndex from "lodash/findIndex";

export const origin = { x: 0, y: 0, z: 0 };

export type XYZT = { t: number; x: number; y: number; z: number };
export type XYZTSeries = XYZT[];

export interface Point2D {
  x: number;
  y: number;
}

export interface Point3D {
  x: number;
  y: number;
  z: number;
}

export type ArrayPoint2 = [number, number];
export type ArrayPoint3 = [number, number, number];
export type ArrayPoint = number[]; // any number of numbers

export const distance = (p: Point2D, q: Point2D) => {
  let dx = p.x - q.x;
  let dy = p.y - q.y;

  return Math.sqrt(dx * dx + dy * dy);
};

export const arrayPointsDistance = (p: ArrayPoint2, q: ArrayPoint2) => {
  let dx = p[0] - q[0];
  let dy = p[1] - q[1];

  return Math.sqrt(dx * dx + dy * dy);
};

export const arrayPoint3Distance = (p: ArrayPoint3, q: ArrayPoint3) => {
  let dx = p[0] - q[0];
  let dy = p[1] - q[1];
  let dz = p[2] - q[2];

  return Math.sqrt(dx * dx + dy * dy + dz * dz);
};

export const arrayPointsDistance3D = (p: ArrayPoint3, q: ArrayPoint3) => {
  let dx = p[0] - q[0];
  let dy = p[1] - q[1];
  let dz = p[2] - q[2];

  return Math.sqrt(dx * dx + dy * dy + dz * dz);
};

export const arrayPoints3DAverage = (points: ArrayPoint3[]) => {
  let len = points.length;

  return [
    points.reduce((sum, p) => sum + p[0], 0) / len,
    points.reduce((sum, p) => sum + p[1], 0) / len,
    points.reduce((sum, p) => sum + p[2], 0) / len,
  ];
};

export const magnitude = ({ x, y }: { x: number; y: number }) =>
  Math.sqrt(x * x + y * y);

// rotate 1/2 pi about x to get from GL's Y-up to MLB's Z-up
export const eulerGLtoMLB = new Euler(0.5 * Math.PI, 0, 0);
export const eulerMLBtoGL = new Euler(-0.5 * Math.PI, 0, 0);

interface Args {
  points: ArrayPoint2[];
  tolerance?: number;
  highQuality?: boolean;
}

/**
 * Wraps simplify to handle a series of [x, y] points
 * @param points – array of [x, y]
 * @param tolerance
 * @param highQuality
 */
export const simplifyArrayPoints = ({
  points,
  tolerance = 1,
  highQuality = false,
}: Args) => {
  let xyPoints = points.map((p: any) => ({ x: p[0], y: p[1] }));
  let simplifiedXYPoints = simplify(xyPoints, tolerance, highQuality);

  return simplifiedXYPoints.map((p: any) => [p.x, p.y]);
};
/**
 * Reduces the number of samples in a time series
 * @param points
 * @param interval – max distance interval, in feet
 */
export const decimatePointsSpatially = ({
  points,
  interval = 6,
}: {
  points: Point2D[];
  interval?: number;
}) => {
  if (points.length < 3) {
    // nothing to decimate
    return [...points];
  }

  let lastIndex = points.length - 1;
  let penultimateIndex = lastIndex - 1;
  let anchorIndex = 0;
  let anchor = points[0];
  let decimated = [anchor];
  let meetsMinDistance = (p: any) => distance(anchor, p) > interval;

  while (anchorIndex < penultimateIndex) {
    let firstBeyondIndex = _findIndex(
      points.slice(anchorIndex + 1),
      meetsMinDistance
    );

    if (firstBeyondIndex === -1) {
      // done scanning
      break;
    }

    // adding to anchorIndex since firstBeyondIndex is relative to a slice of points starting at anchorIndex
    anchorIndex = anchorIndex + Math.max(1, firstBeyondIndex - 1);
    anchor = points[anchorIndex];
    decimated.push(anchor);
  }
  decimated.push(points[lastIndex]);

  return decimated;
};

export type CoordinatePoint3 = {
  x: number;
  y: number;
  z?: number;
};

export const lerpPoints = (
  p: CoordinatePoint3,
  q: CoordinatePoint3,
  n: number
) =>
  p.z && q.z
    ? {
        x: p.x + n * (q.x - p.x),
        y: p.y + n * (q.y - p.y),
        z: p.z + n * (q.z - p.z),
      }
    : {
        x: p.x + n * (q.x - p.x),
        y: p.y + n * (q.y - p.y),
      };

export const lerpArrayPointsToCoordinatePoint = (
  p: ArrayPoint,
  q: ArrayPoint,
  n: number
) =>
  p.length > 2 && q.length > 2
    ? {
        x: p[0] + n * (q[0] - p[0]),
        y: p[1] + n * (q[1] - p[1]),
        z: p[2] + n * (q[2] - p[2]),
      }
    : {
        x: p[0] + n * (q[0] - p[0]),
        y: p[1] + n * (q[1] - p[1]),
      };

export const lerpArrayPoints = (p: ArrayPoint, q: ArrayPoint, n: number) =>
  p.length > 2 && q.length > 2
    ? [
        p[0] + n * (q[0] - p[0]),
        p[1] + n * (q[1] - p[1]),
        p[2] + n * (q[2] - p[2]),
      ]
    : [p[0] + n * (q[0] - p[0]), p[1] + n * (q[1] - p[1])];

let _axis = new Vector3(); // scratch vector object

interface spanObjectArgs {
  obj: Object3D;
  p1: ArrayPoint;
  p2: ArrayPoint;
}

export const spanObject = ({ obj, p1, p2 }: spanObjectArgs) => {
  let v1 = new Vector3(...p1);
  let v2 = new Vector3(...p2);
  let dir = v2.clone();

  dir.sub(v1);

  // let len = dir.length();

  // let length = dir.length();
  dir.normalize();

  if (dir.y > 0.99999) {
    obj.quaternion.set(0, 0, 0, 1);
  } else if (dir.y < -0.99999) {
    obj.quaternion.set(1, 0, 0, 0);
  } else {
    let radians = Math.acos(dir.y);

    _axis.set(dir.z, 0, -dir.x).normalize();
    obj.quaternion.setFromAxisAngle(_axis, radians);

    // TODOHI  still need to set position? and scale?

    // obj.position.set(0, len / 2, 0);
    // @ts-ignore
    obj.position.set(...p1);
  }
};
