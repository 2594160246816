import React from "react";
import { Plate } from "./Plate";
import { Bases } from "./Bases";
import { PlateBoxes } from "./PlateBoxes";
import { Mound } from "./Mound";

interface Props {
  useFieldAppearanceStore: any;
  children?: any;
  shadows?: boolean;
}

export const FixedField = (props: Props) => {
  let { children, useFieldAppearanceStore, shadows = false } = props;

  return (
    <group>
      {children}
      <Plate />
      <Bases />
      <PlateBoxes />
      <Mound
        useFieldAppearanceStore={useFieldAppearanceStore}
        shadows={shadows}
      />
    </group>
  );
};
