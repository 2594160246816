export const playerPositions = [
  {
    positionId: 1,
    name: "Pitcher",
  },
  {
    positionId: 2,
    name: "Catcher",
  },
  {
    positionId: 3,
    name: "1st Baseman",
  },
  {
    positionId: 4,
    name: "2nd Baseman",
  },
  {
    positionId: 5,
    name: "3rd Baseman",
  },
  {
    positionId: 6,
    name: "Shortstop",
  },
  {
    positionId: 7,
    name: "Left Fielder",
  },
  {
    positionId: 8,
    name: "Center Fielder",
  },
  {
    positionId: 9,
    name: "Right Fielder",
  },
  {
    positionId: 10,
    name: "Batter",
  },
  {
    positionId: 11,
    name: "Runner on 1st",
  },
  {
    positionId: 12,
    name: "Runner on 2nd",
  },
  {
    positionId: 13,
    name: "Runner on 3rd",
  },
  {
    positionId: 14,
    name: "Home Plate Umpire",
  },
  {
    positionId: 15,
    name: "1st Base Umpire",
  },
  {
    positionId: 16,
    name: "2nd Base Umpire",
  },
  {
    positionId: 17,
    name: "3rd Base Umpire",
  },
  {
    positionId: 18,
    name: "1st Base Coach",
  },
  {
    positionId: 19,
    name: "3rd Base Coach",
  },
];

export const positionIdLabel: { [index: string]: any } = {
  0: " ", //'UN',
  1: "P",
  2: "C",
  3: "1B",
  4: "2B",
  5: "3B",
  6: "SS",
  7: "LF",
  8: "CF",
  9: "RF",
  10: "B",
  11: "1R",
  12: "2R",
  13: "3R",
};

// export const eventTypeLabel: { [index: string]: any } = {
//     BEGIN_OF_PLAY: 'BEGIN', // 0
//     PITCHER_GOING_TO_WINDUP: 'WINDUP', // 1
//     BALL_WAS_PITCHED: 'PITCH', // 2
//     BALL_WAS_HIT: 'HIT', // 3
//     BALL_WAS_CAUGHT: 'CAUGHT', // 4
//     BALL_WAS_CAUGHT_OUT: 'CAUGHT OUT', // 5
//     BALL_WAS_RELEASED: 'RELEASED', // 6
//     BALL_WAS_DEFLECTED: 'DEFLECTED', // 7
//     TAG_WAS_APPLIED: 'TAG', // 8
//     PICK_OFF_BALL_RELEASED: 'PICK OFF', // 9
//     END_OF_PLAY: 'END', // 10
//     REMOVED: 'REMOVED', // 11
//     // BALL_WAS_RELEASED // 12
//     // BALL_WAS_DEFLECTED // 13
//     BALL_WAS_CATCHER_RELEASED: 'CATCHER RELEASED', // 14
//     // PICK_OFF_BALL_RELEASED // 15
//     BALL_BOUNCE: 'BOUNCE', // 16
//     BALL_WAS_FIELDED: 'FIELDED', // 17
// }

// also see playEventTypes.ts
export const eventTypeLabel: { [index: string]: any } = {
  BEGIN_OF_PLAY: "Begin", // 0
  PITCHER_FIRST_MOVEMENT: "First Movement", // 1
  BALL_WAS_PITCHED: "Pitch", // 2
  BALL_WAS_HIT: "Hit", // 3
  BALL_WAS_CAUGHT: "Caught", // 4
  BALL_WAS_CAUGHT_OUT: "Caught Oou", // 5
  BALL_WAS_RELEASED: "Released", // 6
  BALL_WAS_DEFLECTED: "Deflected", // 7
  TAG_WAS_APPLIED: "Tag", // 8
  PICK_OFF_BALL_RELEASED: "Pick Off", // 9
  END_OF_PLAY: "End", // 10
  REMOVED: "Removed", // 11
  // BALL_WAS_RELEASED // 12
  // BALL_WAS_DEFLECTED // 13
  BALL_WAS_CATCHER_RELEASED: "Catcher Released", // 14
  // PICK_OFF_BALL_RELEASED // 15
  BALL_BOUNCE: "Bounce", // 16
  BALL_WAS_FIELDED: "Fielded", // 17

  OFF_THE_WALL: "Wall", // 23
};
