import FormHelperText from "@material-ui/core/FormHelperText";
import Slider from "@material-ui/core/Slider";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  // selectEmpty: {
  //     marginTop: theme.spacing(2),
  // },
  sliderRoot: {
    width: 200,
  },
}));

const ConstantHeightKnobs = ({
  ballChaseCamParams,
  setBallChaseCamParams,
  classes,
}: any) => {
  let {
    constantHeightMethod, // 'set-height' | 'apex-relative-height'
    fixedHeight,
    apexMultiple, // 0 to 2, step .01
  } = ballChaseCamParams;

  let handleConstantHeightMethodChange = (event: any) => {
    setBallChaseCamParams({
      ...ballChaseCamParams,
      constantHeightMethod: event.target.value,
    });
  };

  let handleFixedHeightChange = (event: any, value: any) => {
    setBallChaseCamParams({ ...ballChaseCamParams, fixedHeight: +value });
  };

  let handleApexMultipleChange = (event: any, value: any) => {
    setBallChaseCamParams({
      ...ballChaseCamParams,
      apexMultiple: +value / 100,
    });
  };

  let pct = 100 * apexMultiple;
  let pctString = pct.toFixed(0);
  let interpretation =
    apexMultiple === 1
      ? "at height of apex"
      : apexMultiple < 1
      ? "below apex"
      : "above apex";

  // TODOHI  select, slider, slider (disable slider(s) based on constantHeightMethod?)
  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="camera-control-select">Height Method</InputLabel>
        <NativeSelect
          value={constantHeightMethod}
          onChange={handleConstantHeightMethodChange}
          inputProps={{
            name: "constant-height-method-select",
            id: "constant-height-method-select",
          }}
        >
          <option value="set-height">Set Height</option>
          <option value="apex-relative-height">Apex-Relative Height</option>
        </NativeSelect>
      </FormControl>
      <FormHelperText>Set height or calculate relative to apex</FormHelperText>

      <br />

      <div className={classes.sliderRoot}>
        Fixed Height: {fixedHeight}'
        <Slider
          disabled={constantHeightMethod !== "set-height"}
          min={1}
          max={100}
          value={fixedHeight}
          aria-labelledby="fixed-height-slider"
          step={1}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleFixedHeightChange}
        />
        {/*<FormHelperText>The camera rides at a set height</FormHelperText>*/}
      </div>

      <br />

      {/*TODOHI tweak for pct*/}
      <div className={classes.sliderRoot}>
        Apex Height Multiple: {pctString}%
        <Slider
          disabled={constantHeightMethod !== "apex-relative-height"}
          min={0}
          max={200}
          value={pct}
          aria-labelledby="apex-multiple-slider"
          step={1}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleApexMultipleChange}
        />
        <FormHelperText>
          {pctString}% ➔ {interpretation}
        </FormHelperText>
      </div>
    </div>
  );
};

const ConstantHeightOffsettKnobs = ({
  ballChaseCamParams,
  setBallChaseCamParams,
  classes,
}: any) => {
  let {
    constantHeightOffset, // -50 to 50
  } = ballChaseCamParams;

  let handleConstantHeightOffsetChange = (event: any, value: any) => {
    setBallChaseCamParams({
      ...ballChaseCamParams,
      constantHeightOffset: +value,
    });
  };

  let offsetString = `${constantHeightOffset}' ➔ `;
  let interpretation =
    constantHeightOffset === 0
      ? `${offsetString}at height of ball`
      : constantHeightOffset < 0
      ? `${offsetString}below ball`
      : `${offsetString}above ball`;

  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <div className={classes.sliderRoot}>
        Offset Above/Below Ball: {constantHeightOffset}'
        <Slider
          min={-50}
          max={50}
          value={constantHeightOffset}
          aria-labelledby="constant-height-offset-slider"
          step={1}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleConstantHeightOffsetChange}
        />
        <FormHelperText>{interpretation}</FormHelperText>
      </div>
    </div>
  );
};

const VariableHeightKnobs = ({
  ballChaseCamParams,
  setBallChaseCamParams,
  classes,
}: any) => {
  let {
    multiplePoint, // 'ball' | 'offset'
    heightMultiple, // 0 to 2, step .01
  } = ballChaseCamParams;

  let handleMultiplePointChange = (event: any) => {
    setBallChaseCamParams({
      ...ballChaseCamParams,
      multiplePoint: event.target.value,
    });
  };

  let handleHeightMultipleChange = (event: any, value: any) => {
    setBallChaseCamParams({
      ...ballChaseCamParams,
      heightMultiple: +value / 100,
    });
  };

  let pct = 100 * heightMultiple;
  let pctString = pct.toFixed(0);
  let interpretation =
    heightMultiple === 1
      ? "at height of ball/offset"
      : heightMultiple < 1
      ? "below of ball/offset"
      : "above of ball/offset";

  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="camera-control-select">Ref Point</InputLabel>
        <NativeSelect
          value={multiplePoint}
          onChange={handleMultiplePointChange}
          inputProps={{
            name: "height-method-select",
            id: "height-method-select",
          }}
        >
          <option value="ball">Ball Point</option>
          <option value="offset">Chase/Lead Point</option>
        </NativeSelect>
      </FormControl>
      <FormHelperText>Cam height based on ball or ball-offset</FormHelperText>

      <br />

      <div className={classes.sliderRoot}>
        Height Multiple: {pctString}%
        <Slider
          min={0}
          max={200}
          value={pct}
          aria-labelledby="height-multiple-slider"
          step={1}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleHeightMultipleChange}
        />
        <FormHelperText>
          {pctString}% ➔ {interpretation}
        </FormHelperText>
      </div>
    </div>
  );
};

const methodKnobsMap = {
  "constant-height": ConstantHeightKnobs,
  "constant-height-offset": ConstantHeightOffsettKnobs,
  "variable-height": VariableHeightKnobs,
};

const methodInterpretationMap = {
  "constant-height": "The camera stays at a fixed height",
  "constant-height-offset":
    "The camera rides at constant height above/below the ball or ball-offset",
  "variable-height":
    "The camera rides at a multiple of the ball or ball-offset height",
};

interface BallChaseCamKnobsProps {
  useCameraStore: any;
}

export const BallChaseCamKnobs = ({
  useCameraStore,
}: BallChaseCamKnobsProps) => {
  let classes = useStyles();
  let ballChaseCamParams = useCameraStore(($: any) => $.ballChaseCamParams);
  let { heightMethod, offsetInterval } = ballChaseCamParams;
  let setBallChaseCamParams = useCameraStore(
    ($: any) => $.setBallChaseCamParams
  );

  let handleOffsetIntervalChange = (event: any, value: any) => {
    setBallChaseCamParams({ ...ballChaseCamParams, offsetInterval: value });
  };
  let handleHeightMethodChange = (event: any) => {
    setBallChaseCamParams({
      ...ballChaseCamParams,
      heightMethod: event.target.value,
    });
  };
  // @ts-ignore
  let interpretation = methodInterpretationMap[heightMethod];

  // @ts-ignore
  let Knobs = methodKnobsMap[heightMethod];

  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <div className={classes.sliderRoot}>
        Offset Interval (ms)
        <Slider
          min={-2000}
          max={2000}
          value={offsetInterval}
          aria-labelledby="offset-interval-slider"
          step={100}
          valueLabelDisplay="auto"
          // marks={marks}
          onChange={handleOffsetIntervalChange}
        />
        <FormHelperText>
          - offset ➔ Chase Cam
          <br /> + offset ➔ Lead Cam
        </FormHelperText>
      </div>

      <br />

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="camera-control-select">Height Method</InputLabel>
        <NativeSelect
          value={heightMethod}
          onChange={handleHeightMethodChange}
          inputProps={{
            name: "height-method-select",
            id: "height-method-select",
          }}
        >
          <option value="constant-height">Constant Height</option>
          <option value="constant-height-offset">Height Offset</option>
          <option value="variable-height">Variable Height</option>
        </NativeSelect>
      </FormControl>
      {/*<FormHelperText>How the camera height is calculated</FormHelperText>*/}
      <FormHelperText>{interpretation}</FormHelperText>

      <br />

      <Knobs
        ballChaseCamParams={ballChaseCamParams}
        setBallChaseCamParams={setBallChaseCamParams}
        classes={classes}
      />

      <h4>
        <a
          style={{ color: "#fff" }}
          href="./dev-notes.html#ball-chase-cam"
          target="_blank"
        >
          Ball Chase-Cam explanation in dev notes
        </a>
      </h4>
    </div>
  );
};
