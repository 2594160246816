import React, { SyntheticEvent, useMemo } from "react";
import { styled } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { BallKnobsPanel } from "./BallKnobsPanel";
import { FigureTabPanel } from "./FigureTabPanel";
import { FieldKnobsPanel } from "./FieldKnobsPanel";
import { CameraTabPanel } from "./CameraTabPanel";
import { PlusKnobsPanel } from "./PlusKnobsPanel";
import { MarksKnobsPanel } from "./MarksKnobsPanel";
import { RenderPanel } from "./RenderPanel";
import { toTrackIds } from "field-of-things/src/util/tracking-util";
import { makeStyles } from "@material-ui/core/styles";
import { RootStore } from "field-of-things/src/stores";
import { StatsApiNS } from "field-of-things/src/types";

const useStyles = makeStyles((theme) => ({
  drawer: {
    // TODO    nest styling for drawer.modal.backdrop to lower its opacity
  },
}));

const noop = (event: SyntheticEvent<{}, Event>) => {};

const MyTab = styled(Tab)({
  minWidth: 60,
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

interface Props {
  isOpen: boolean;
  onClose?: (event: SyntheticEvent<{}, Event>) => void;
  rootStore: RootStore;
  playTracking: StatsApiNS.SkeletalData;
  playData?: StatsApiNS.PlayData;
  timebase: any;
}

export const Knobs = (props: Props) => {
  let {
    isOpen = false,
    onClose = noop,
    rootStore,
    playData,
    playTracking,
    timebase,
    ...other
  } = props;
  let useBallStore = rootStore.useBallStore;
  let useVenueStore = rootStore.useVenueStore;
  let useZoneProjectionStore = rootStore.useZoneProjectionStore;
  const useRenderingStore = rootStore.useRenderingStore;
  let useMarksStore = rootStore.useMarksStore;
  let [value, setValue] = React.useState(0);
  let classes = useStyles();

  let trackIds = useMemo(() => toTrackIds(playTracking), [playTracking]);

  let handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  // TODO

  return (
    <SwipeableDrawer
      className={classes.drawer}
      anchor="right"
      open={isOpen}
      onClose={onClose}
      onOpen={noop}
      {...other}
    >
      <Tabs value={value} onChange={handleChange} aria-label="main knobs tabs">
        <MyTab label="Ball" {...a11yProps(0)} />
        <MyTab label="Players +" {...a11yProps(1)} />
        <MyTab label="Camera +" {...a11yProps(2)} />
        <MyTab label="Field" {...a11yProps(3)} />
        <MyTab label="Marks" {...a11yProps(4)} />
        <MyTab label="Render" {...a11yProps(5)} />
        <MyTab label="+" {...a11yProps(6)} />
      </Tabs>

      {
        // TODO    how to remove this @ts-ignore hack and keep TS happy?
        // @ts-ignore
        <BallKnobsPanel value={value} index={0} useBallStore={useBallStore} />
      }

      <FigureTabPanel
        value={value}
        index={1}
        rootStore={rootStore}
        timebase={timebase}
      />

      <CameraTabPanel
        value={value}
        index={2}
        trackIds={trackIds}
        rootStore={rootStore}
      />

      <FieldKnobsPanel value={value} index={3} useVenueStore={useVenueStore} />

      <MarksKnobsPanel
        value={value}
        index={4}
        useZoneProjectionStore={useZoneProjectionStore}
        useMarksStore={useMarksStore}
      />

      <RenderPanel
        value={value}
        index={5}
        useRenderingStore={useRenderingStore}
        timebase={timebase}
      />

      <PlusKnobsPanel
        value={value}
        index={6}
        rootStore={rootStore}
        playData={playData}
        playTracking={playTracking}
      />
    </SwipeableDrawer>
  );
};
