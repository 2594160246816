import { positionIdMap } from "../../models/positions";

/*
 * Team specific player model builder:
 * ${teamId}_LHB_HOME.glb
 * ${teamId}_RHB_HOME.glb
 * ${teamId}_LHB_AWAY.glb
 * ${teamId}_RHB_AWAY.glb
 * ${teamId}_HOME.glb
 * ${teamId}_AWAY.glb
 * umpire.glb
 */
export const figureModelUrl = (
  positionId: number,
  battingTeamId: number,
  fieldingTeamId: number,
  homeTeamId: number,
  batSide: string,
  playersParentTeamId?: number,
  figureModelUrlDomain?: string
) => {
  let baseUrl = `${figureModelUrlDomain}/people/`;
  let teamId = playersParentTeamId;
  let handedness = "";
  let homeOrAway = "";
  const battingHomeOrAway = battingTeamId === homeTeamId ? "HOME" : "AWAY";
  const fieldingHomeOrAway = fieldingTeamId === homeTeamId ? "HOME" : "AWAY";
  if (positionIdMap[positionId].type === "defense") {
    homeOrAway = fieldingHomeOrAway;
  } else if (
    positionIdMap[positionId].type === "runner" ||
    positionIdMap[positionId].type === "coach"
  ) {
    if (positionId === 10) {
      handedness = `_${batSide}HB`;
    } else {
      // For now, defaulting to RHB batting helmet for all non-batting offensive models
      handedness = "_RHB";
    }
    if (positionIdMap[positionId].type === "coach") {
      // 1st and 3rd base coaches don't have parentTeamIds set, but we know they'll always associated with battingTeamId
      teamId = battingTeamId;
    }
    homeOrAway = battingHomeOrAway;
  } else {
    return baseUrl + "umpire.glb";
  }
  return `${baseUrl}${teamId}${handedness}_${homeOrAway}.glb`;
};
