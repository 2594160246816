import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";
import { TabPanel } from "./Knobs";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  offsetIntervalSliderRoot: {
    width: 200,
  },
}));

interface Props {
  value: any;
  index: number;
  rootStore: any;
}

export const EffectsKnobsPanel = (props: Props) => {
  let { index, value, rootStore } = props;
  let { useEffectsStore } = rootStore;

  const classes = useStyles();

  let {
    depthOfFieldEnabled,
    setDepthOfFieldEnabled,
    focusDistance,
    setFocusDistance,
    focalLength,
    setFocalLength,
    bokehScale,
    setBokehScale,
  } = useEffectsStore();

  let handleChangeFocusDistance = (event: any, value: any) => {
    setFocusDistance(value);
  }
  let handleChangeFocalLength = (event: any, value: any) => {
    setFocalLength(value);
  }
  let handleChangeBokehScale = (event:any, value: any) => {
    setBokehScale(value);
  }

  return (
    <TabPanel value={value} index={index}>
      <FormControl component="fieldset">
        <FormControl className={classes.formControl}>
            <FormControlLabel
            control={
                <Switch
                checked={depthOfFieldEnabled}
                onChange={() => {
                    setDepthOfFieldEnabled(!depthOfFieldEnabled);
                    }
                }
                name="depth-of-field"
                color="secondary"
                size="small"
                />
            }
            label="Depth of Field"
            />
            <br />
            <div style={{ marginLeft: "1.5rem" }}>
                <div className={classes.offsetIntervalSliderRoot}>
                    Focus Distance
                    <Slider
                    disabled={!depthOfFieldEnabled}
                    min={0.0}
                    max={1.0}
                    value={focusDistance}
                    aria-labelledby="focus"
                    step={0.001}
                    valueLabelDisplay="auto"
                    onChange={handleChangeFocusDistance}
                    />
                </div>
                <div className={classes.offsetIntervalSliderRoot}>
                    Focal Length
                    <Slider
                    disabled={!depthOfFieldEnabled}
                    min={0.0}
                    max={1.0}
                    value={focalLength}
                    aria-labelledby="focal"
                    step={0.0001}
                    valueLabelDisplay="auto"
                    onChange={handleChangeFocalLength}
                    />
                </div>
                <div className={classes.offsetIntervalSliderRoot}>
                    Bokeh Scale
                    <Slider
                    disabled={!depthOfFieldEnabled}
                    min={0.0}
                    max={10.0}
                    value={bokehScale}
                    aria-labelledby="bokeh"
                    step={1.0}
                    valueLabelDisplay="auto"
                    onChange={handleChangeBokehScale}
                    />
                </div>
            </div>
          </FormControl>
      </FormControl>
      <br />
    </TabPanel>
  );
};
