import React from "react";
import create from "zustand";
import { WebGLRenderer, Scene } from "three";

export interface RenderingStore {
  recorder?: any;
  setRecorder(recorder: any): any;
  renderer?: WebGLRenderer;
  setRenderer(renderer: WebGLRenderer): void;
  scene?: Scene;
  setScene(scene: Scene): void;
  width: number;
  setWidth(width: number): void;
  height: number;
  setHeight(height: number): void;
  fullScreen: boolean;
  toggleFullScreen(fullscreen?: boolean): void;
  parentElementRef?: any;
}

// TODOHI  add an initial values argument to createVenueStore
export const createRenderingStore = () =>
  create<RenderingStore>((set) => ({
    setRecorder: (recorder: any) => {
      set({ recorder });
    },
    setRenderer: (renderer: WebGLRenderer) => {
      set({ renderer });
    },
    setScene: (scene: Scene) => {
      set({ scene });
    },
    width: 1280,
    setWidth: (width: number) => {
      set({ width });
    },
    height: 720,
    setHeight: (height: number) => {
      set({ height });
    },
    fullScreen: false,
    toggleFullScreen: (setFullScreen?: boolean) => {
      if (setFullScreen === undefined) {
        set((state) => ({ fullScreen: !state.fullScreen  }));
      }
      else
      {
        set({ fullScreen: setFullScreen});
      }
    },
    parentElementRef: React.createRef(),
  }));
