import React from "react";
import { toPoints } from "../../util/polynomial-util";
import { defaultBallRadius } from "../Ball";
import { KnottedTrack } from "../KnottedTrack";

interface EndPoint {
  time: number;
}

export interface Segment {
  startData: EndPoint;
  endData: EndPoint;
  segmentType: string;
  trajectoryPolynomialX: number[];
  trajectoryPolynomialY: number[];
  trajectoryPolynomialZ: number[];
}

const SEGMENT_LINE_WIDTH = 2;

export const segmentColorMap: any = {
  BaseballPitch: "#fff",
  BaseballHit: "#67d24c",
  BaseballBounce: "#ec4d4d",
  // BaseballThrow: '#efe51a',
  BaseballThrow: "#e2d808",

  BaseballPickoff: "#aaa",
  BaseballCatcherThrow: "#aaa",
  BaseballDeflection: "#aaa",
  Unknown: "#aaa",
};
const defaultSegmentColor = "#aaa";

const defaultStep = 0.0303030303; // ~33 Hz

interface BallSegmentProps {
  segment: Segment;
  step?: number;
}

export const BallSegment = ({
  segment,
  step = defaultStep,
}: BallSegmentProps) => {
  let n1 = segment.startData.time;
  let n2 = segment.endData.time;
  let xCoef = segment.trajectoryPolynomialX;
  let yCoef = segment.trajectoryPolynomialY;
  let zCoef = segment.trajectoryPolynomialZ;
  let color = segmentColorMap[segment.segmentType] || defaultSegmentColor;

  let points = toPoints({ n1, n2, step, xCoef, yCoef, zCoef });

  return (
    <KnottedTrack
      positions={points}
      knotRadius={defaultBallRadius}
      lineWidth={SEGMENT_LINE_WIDTH}
      lineColor={color}
    />
  );
};

interface Props {
  segments: Segment[];
}

export const BallSegmentGroup = ({ segments }: Props) => (
  <group name="ball-segments">
    {segments.map((segment, i) => (
      <BallSegment key={i} segment={segment} />
    ))}
  </group>
);
