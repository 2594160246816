import { defensePositions, EntityPosition } from "./positions";

export const BEGIN_OF_PLAY_ID = 0;
export const PITCHER_FIRST_MOVEMENT = 1;
export const BALL_WAS_PITCHED_ID = 2;
export const BALL_WAS_HIT = 3;
export const BALL_WAS_RELEASED_ID = 6;
export const TAG_WAS_APPLIED_ID = 8;
export const PICKOFF_BALL_RELEASED_ID = 9;
export const END_OF_PLAY_ID = 10;
export const WALL_BALL_ID = 23;

export const PITCHER_FIRST_MOVEMENT_EVENT = "PITCHER_FIRST_MOVEMENT";
export const BEGIN_OF_PLAY_EVENT = "BEGIN_OF_PLAY";
export const BALL_WAS_PITCHED_EVENT = "BALL_WAS_PITCHED";
export const END_OF_PLAY_EVENT = "END_OF_PLAY";

/**
 * id: ID associated with play event
 * label: display string associated with play event
 * availableOptions: Optional, passes an array of possible positions that
 * could be associated with given play event (EntityPosition)
 * abbreviation: display name on play timeline
 */
interface PlayEventType {
  id: number;
  label: string;
  abbreviation?: string;
  availableOptions?: Array<EntityPosition>;
}

interface PlayEventClassifiers {
  canAdd?: boolean;
  hasPosition?: boolean;
  isUnique?: boolean;
}

const allPlayEventTypes: Array<PlayEventType & PlayEventClassifiers> = [
  { id: BEGIN_OF_PLAY_ID, label: "Begin of Play", abbreviation: "BoP" },
  {
    id: PITCHER_FIRST_MOVEMENT,
    label: "Pitcher First Movement",
    abbreviation: "PFM",
    hasPosition: false,
    isUnique: true,
    canAdd: false,
  },
  {
    id: BALL_WAS_PITCHED_ID,
    label: "Ball was Pitched",
    abbreviation: "P",
    canAdd: true,
    isUnique: true,
    hasPosition: true,
  },
  {
    id: BALL_WAS_HIT,
    label: "Ball was Hit",
    abbreviation: "H",
    canAdd: true,
    isUnique: true,
    hasPosition: true,
  },
  {
    id: 4,
    label: "Ball was Caught",
    abbreviation: "BC",
    canAdd: true,
    hasPosition: true,
    availableOptions: defensePositions,
  },
  {
    id: 5,
    label: "Ball was Caught Out",
    abbreviation: "BCO",
    canAdd: true,
    hasPosition: true,
    availableOptions: defensePositions,
  },
  {
    id: BALL_WAS_RELEASED_ID,
    label: "Ball was Released",
    abbreviation: "BR",
    canAdd: true,
    hasPosition: true,
    availableOptions: defensePositions,
  },
  {
    id: 7,
    label: "Ball was Deflected",
    abbreviation: "DEF",
    canAdd: true,
    hasPosition: true,
  },
  {
    id: TAG_WAS_APPLIED_ID,
    label: "Tag was Applied",
    abbreviation: "Tag", // "TAG",
    canAdd: true,
    hasPosition: true,
    availableOptions: defensePositions,
  },
  {
    id: PICKOFF_BALL_RELEASED_ID,
    label: "Pickoff Ball Release",
    abbreviation: "PO",
    canAdd: true,
    isUnique: true,
    hasPosition: true,
  },
  { id: END_OF_PLAY_ID, label: "End of Play", abbreviation: "EoP" },
  { id: 11, label: "Removed", abbreviation: "Rem" },
  { id: 12, label: "TM Ball was Released", abbreviation: "BwR" },
  { id: 13, label: "TM Ball was Deflected", abbreviation: "BwD" },
  { id: 14, label: "TM Ball was Catcher Released", abbreviation: "BCR" },
  { id: 15, label: "TM Pickoff Ball Release", abbreviation: "PBR" },
  { id: 16, label: "TM Ball Bounce", abbreviation: "BB" },
  {
    id: 17,
    label: "Hit was Fielded",
    abbreviation: "HwF",
    canAdd: true,
    hasPosition: true,
    availableOptions: defensePositions,
    isUnique: true,
  },
  { id: 18, label: "Unknown", abbreviation: "UN" },
  { id: 19, label: "Ball Bounce", abbreviation: "BB" },
  { id: 22, label: "Operator Input Not Found" },
  {
    id: WALL_BALL_ID,
    label: "Wall Ball",
    abbreviation: "OTW",
    canAdd: true,
    isUnique: true,
  },
];

type Map<V> = { [k: string]: V };

function toMap<T>(acc: Map<T>, curr: PlayEventType) {
  return {
    ...acc,
    [curr.id]: curr.label,
  };
}

export const playEventTypesMap: Map<string> = allPlayEventTypes.reduce(
  toMap,
  {}
);

export const playEventTypesToAdd: PlayEventType[] = allPlayEventTypes
  .filter((p) => p.canAdd)
  .sort((a, b) => (a.label > b.label ? 1 : -1));

export const uniquePlayEventTypes: Map<string> = allPlayEventTypes
  .filter((p) => p.isUnique)
  .reduce(toMap, {} as Map<string>);

export const positionRelatedPlayEvents: Map<string> = allPlayEventTypes
  .filter((p) => p.hasPosition)
  .reduce(toMap, {} as Map<string>);

export const idToPlayEventMap: {
  [key: number]: PlayEventType;
} = allPlayEventTypes.reduce(
  (acc, playEvent) => ({
    ...acc,
    [playEvent.id]: playEvent,
  }),
  {}
);
