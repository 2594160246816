// import React from 'react'
import _find from "lodash/find";
import { timeToFrame } from "../../util/tracking-util";

// TODO    listen to timebase directly?

interface Props {
  camera: any;
  playTracking: any;
  time: number;
  figureChaseCamParams: any;
}

export const FigureChaseCamera = (props: Props) => {
  let { camera, playTracking, time, figureChaseCamParams } = props;
  let {
    positionId,
    offsetInterval,
    cameraHeight,
    lookAtHeight,
  } = figureChaseCamParams;

  if (!camera) {
    return null;
  }

  let { frames } = playTracking.skeletalData;
  let t0 = frames[0].time;

  // TODOHI  need to verify we can do anything? e.g., time is with frames time span, figure with positionId exists,
  // TODOHI  figure at positionId exists in all frames

  // reset time if within offsetInterval sec of start
  time = Math.max(t0 - offsetInterval, time);
  let followTime = time + offsetInterval;

  // find frames at time
  let frame = timeToFrame({ time, playTracking });
  let followFrame = timeToFrame({ time: followTime, playTracking });

  // find figure in each frame
  let followFigure = _find(followFrame.positions, { positionId });
  let figure = _find(frame.positions, { positionId });

  if (!figure || !followFigure) {
    return null;
  }

  // get joint coords, p (cam pos) and q (look at), at each frame
  let p = followFigure.jointsMap["Neck"];
  let q = figure.jointsMap["Neck"];

  if (!p || !q) {
    return null;
  }

  // set camera position and lookAt
  // camera.position.set(p[0], p[2] + verticalOffset, -p[1])
  camera.position.set(p[0], cameraHeight, -p[1]);
  // camera.lookAt(q[0], q[2], -q[1])
  camera.lookAt(q[0], lookAtHeight, -q[1]);
  camera.updateMatrix();
  camera.up.set(0, 1, 0);

  return null;
};
