import { toReleaseTime } from "../../util/time-util";
import { toPointInSeries } from "../../util/polynomial-util";
import { Vector3 } from "three";
import _findIndex from "lodash/findIndex";
import { lerpPoints } from "../../util/geometry-util";

// TODO    more general location to hold these?
export type XYZT = { t: number; x: number; y: number; z: number };
export type XYZTSeries = XYZT[];

export type TimeSpan = {
  name?: string;
  tIn: number;
  tOut: number;
  markIn: any;
  markOut: any;
  tween?: any;
  [key: string]: any;
};

export const getGenericSegment = (playData: any, name: string) => {
  let segments = playData?.ballSegments?.genericSegments || [];

  return segments.find((segment: any) => segment.segmentType === name);
};

export const mlbToGl = (vMLB: Vector3, vGL = new Vector3()) =>
  vGL.set(vMLB.x, vMLB.z, -vMLB.y);

interface toBallPointProps {
  t: number;
  series: any[];
  // clamp?: boolean,
}

/**
 * Returns a point from a time series of points for the given time. if the time falls between two points,
 * we interpolate between them.
 * @param t
 * @param series
 * @param clamp
 */
export const toPoint = ({ t, series /*, clamp = true*/ }: toBallPointProps) => {
  // TODO    implement optional clamping

  let lastIndex = series.length - 1;

  // find the first sample, u, where its time <= t
  let j = _findIndex(series, (u, i) => {
    if (i === lastIndex) {
      return true;
    } else {
      let v = series[i + 1];

      return u.t <= t && t < v.t;
    }
  });

  if (j === lastIndex) {
    return { ...series[j] };
  } else {
    let p = series[j];
    let q = series[j + 1];
    let n = (t - p.t) / (q.t - p.t);

    return { ...lerpPoints(p, q, n), t };
  }
};

interface toBallPointArgs {
  time: number;
  playData: any;
  series: any[];
}

export const toBallPoint = ({ time, playData, series }: toBallPointArgs) => {
  let releaseTime = toReleaseTime({ playData });
  let releaseRelativeTimeSec = (time - releaseTime) / 1000;

  return toPointInSeries({ t: releaseRelativeTimeSec, series });
};
