import React, { useEffect, useRef, useState } from "react";
import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _remove from "lodash/remove";
import { Mesh, Object3D, Quaternion, SkinnedMesh, Vector3 } from "three";
import { useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { JointsMapType } from "../Figure";
import { applyTwist } from "./twist";
import { applyHeadTurn } from "./headPose";
import {
  isMesh,
  isMeshy,
  setObjectTreeFrustumCulled,
  traceModel,
  traceObjectVerbose,
} from "field-of-things/src/util/object-util";
import { arrayPoints3DAverage } from "field-of-things/src/util/geometry-util";
import { nexusRigBoneDefs } from "./boneDefs";
import {
  FigureModelStore,
  NO_OVERRIDE_SELECTED,
} from "field-of-things/src/stores/figureModelStore";
import { TurnValues } from "./TurnValues";
import PlayerTexture from "./PlayerTexture";
import { StatsApiNS } from "field-of-things/src/types";
import { ColorManager } from "./ColorManager";
import { UseStore } from "zustand";

export const modelNamePrefix = "figure-model-";

const state = {
  globalOptions: {
    name: {
      fontSize: 118 / 2,
      fontWeight: "normal",
      fontFamily: "PLAYBALL",
      x: 750 / 2,
      y: 2215 / 2,
      letterSpacing: 0,
      outlineWidth: 12 / 2,
      radius: 1500 / 2,
      maxWidth: 750 / 2,
    },
    number: {
      fontSize: 555 / 2,
      fontWeight: "normal",
      fontFamily: "PLAYBALL",
      x: 750 / 2,
      y: 2605 / 2,
      letterSpacing: -55 / 2,
      outlineWidth: 18 / 2,
      borderWidth: 6 / 2,
    },
  },
};

// TODO    possible optimizations
// update matrixWorlds as needed rather than whole subtrees at each step?
// any significant savings by creating a boneDefs map for O(1) lookup? currently O(n) with _find

const loader = new GLTFLoader();

let modelRec = {
  rootBoneName: "root",
  boneDefs: nexusRigBoneDefs,
  flipHorizontally: true,
};

let { boneDefs, rootBoneName, flipHorizontally } = modelRec;

let once = true;
let plantRootOnce = true;
let useFrameOnce = true;
const traceModelEnabled = false;
const tracePlantRootEnabled = false;
const addHeadTip = true;

const isPlantable = (jointsMap: JointsMapType): boolean => {
  return (
    !!jointsMap.RHip &&
    !!jointsMap.LHip &&
    !!jointsMap.MidHip &&
    !!jointsMap.Neck
  );
};

// example of using multiple instances of a gltf model (can they be posed independently?):
// https://codesandbox.io/s/r3f-bones-7zw1c?file=/src/Stacy.js:119-346

// TODO    if cloneDeep is expensive for every figure and frame,
//  clone once at beginning and just update MidHip each frame
/**
 * Returns a copy of figureData.jointsMap, with an added MidHip joint if there is none.
 * @param figureData
 */
const augmentJointsMap = (_jointsMap: any) => {
  let jointsMap = _cloneDeep(_jointsMap);
  let {
    LHip,
    RHip,
    MidHip,
    LShoulder,
    RShoulder,
    LEar,
    REar,
    LEye,
    REye,
  } = jointsMap;

  if (LHip && RHip && !MidHip) {
    jointsMap.MidHip = arrayPoints3DAverage([LHip, RHip]);
  }

  if (LShoulder && RShoulder) {
    jointsMap.MidShoulder = arrayPoints3DAverage([LShoulder, RShoulder]);
  }

  if (LEar && REar) {
    jointsMap.MidEar = arrayPoints3DAverage([LEar, REar]);
  }

  if (LEye && REye) {
    jointsMap.MidEye = arrayPoints3DAverage([LEye, REye]);
  }

  return jointsMap;
};

/**
 * Produces a map of bone objects from a model, indexed by bone name.
 * @param model
 */
const toBonesMap = (model: any) => {
  // for each boneDef, finds the corresponding bone in the model by name
  return boneDefs.reduce(
    (boneRecs, boneDef) => ({
      ...boneRecs,
      [boneDef.name]: model.scene.getObjectByName(boneDef.name),
    }),
    {}
  );
};

/**
 * Stores original bind-pose matrices and positions
 * @param root
 */
const saveInitialState = (root: Object3D) => {
  root.traverse((obj: Object3D) => {
    obj.userData.initialMatrix = obj.matrix.clone();
    obj.userData.initialPosition = obj.position.clone();
    obj.userData.initialQuaternion = obj.quaternion.clone();
  });
};

/**
 * Restores original bind matrices and positions
 * @param root
 */
const restoreInitialState = (root: Object3D) => {
  root.traverse((obj: Object3D) => {
    obj.matrix.copy(obj.userData.initialMatrix);
    obj.position.copy(obj.userData.initialPosition);
    obj.quaternion.copy(obj.userData.initialQuaternion);
  });
};

const initBones = (root: Object3D | null) => {
  if (root) {
    // root.traverse((obj: Object3D) => {
    //   // matrixAutoUpdate false on all but root
    //   obj.matrixAutoUpdate = obj === root;
    // });

    if (addHeadTip) {
      // add a leaf node to head
      let head = root.getObjectByName("head");
      let tip = head?.clone();

      if (tip) {
        tip.name = "head-tip";
        head?.add(tip);
      }
    }

    saveInitialState(root);
  }
};

const adjustOpacity = (root: Object3D | null, opacity = 1) => {
  if (root) {
    root.traverse((obj: any) => {
      if (isMesh(obj)) {
        obj.material.transparent = true;
        obj.material.opacity = opacity;
      }
    });
  }
};

const isBone = (obj: any) => {
  let isBoneType = obj.type === "Bone";
  let name = obj.name.toLowerCase();

  return isBoneType || (name.includes("bone") && !name.includes("tip"));
};

let _GPn = new Vector3();
let _GPnext = new Vector3();
let _GTn = new Vector3();
let _LRn = new Quaternion();
let _LPnextNormalized = new Vector3();
let _parentWorldQuaternion = new Quaternion();

const poseBone = (
  bone: any,
  boneDefs: any,
  jointsMap: JointsMapType,
  scale = 1,
  stretchEnabled = false
) => {
  if (!bone || !isBone(bone)) {
    return;
  }

  // find boneDef for bone
  let boneDef = _find(boneDefs, { name: bone.name });

  if (!boneDef) {
    return;
  }

  let headJointPos = jointsMap[boneDef.joints[0]];
  let tailJointPos = jointsMap[boneDef.joints[1]];

  if (!headJointPos || !tailJointPos) {
    // missing joint(s)
    // TODO    once we ensure complete joint coverage, assert head and tail joints present?
    return;
  }

  // get first child bone, to use for its position
  let childBones = _filter(bone.children, { type: "Bone" });
  let childObjects = _filter(bone.children, { type: "Object3D" });
  let childBone =
    childBones.length > 0
      ? childBones[0]
      : childObjects.length > 0
      ? childObjects[0]
      : null;

  if (!childBone) {
    // TODO    assert?
    return;
  }

  // GPn = tracking point n
  _GPn.set(headJointPos[0], headJointPos[2], -headJointPos[1]);

  // GPn+1 = child tracking point
  _GPnext.set(tailJointPos[0], tailJointPos[2], -tailJointPos[1]);

  // GR(n-1) = global rotation of parent bone
  // get quaternion from parent's world matrix
  _parentWorldQuaternion = bone.parent.getWorldQuaternion(
    _parentWorldQuaternion
  );

  // ensure normalized
  // TODO    switch to invert() after updating three version
  let GRparentInverse = _parentWorldQuaternion.normalize().invert();

  // 1. GTn = GP(n+1)
  // GTn = global vector from GPn and GPnext
  _GTn.subVectors(_GPnext, _GPn);

  // 2. LTn = !GR(n-1) * GTn
  // GR(n-1): parent world quaternion  ( need to decompose parent.matrixWorld?)
  let LTn = _GTn.applyQuaternion(GRparentInverse); // _GTn is just pointing to reusable _GTn
  let LTnNormalized = LTn.normalize();

  // 3. LRn is set to the quaternion that takes normalized(LP(n+1)) to normalized(LTn)
  _LPnextNormalized.copy(childBone.position).normalize();
  _LRn.setFromUnitVectors(
    _LPnextNormalized, // from
    LTnNormalized // to
  );

  // 4. optional, for later: LPn = normalized(LPn) * GT(n-1)
  if (stretchEnabled) {
    childBone.position.normalize().multiplyScalar(_GTn.length() / scale);
  }

  // 5. update GRn = GR(n-1) * LRn
  bone.quaternion.copy(_LRn);
  // ensuring matrixWorld is fresh here, even though .matrixAutoUpdate true
  bone.updateMatrixWorld();
};

const relativeTarget = true; // relative seems to work. absolute does not. why?

const _vOrigin = new Vector3(0, 0, 0);
let _vX = new Vector3();
let _vY = new Vector3();
let _vZ = new Vector3();
let _vMidHip = new Vector3();
let _vNeck = new Vector3();
let _vRightHip = new Vector3();
let _vLeftHip = new Vector3();
let _vYGL = new Vector3();
let _vZGL = new Vector3();
let _vTarget = new Vector3();

/**
 * Orients and positions an armature root based on hips, midhip, and neck from tracking.
 * @param root
 * @param jointsMap
 */
const plantRoot = (root: any, jointsMap: JointsMapType) => {
  if (!isPlantable(jointsMap)) {
    return;
  }

  let traceVerboseFirstCall = (msg?: string) => {
    if (tracePlantRootEnabled && plantRootOnce) {
      msg && console.log(msg);
      console.log("--------------------------------------------");
      console.log(root);
      traceObjectVerbose(root);
      console.log("--------------------------------------------");
    }
  };

  traceVerboseFirstCall("***** at head of plantRoot *****");

  _vMidHip.set(...jointsMap.MidHip);
  _vRightHip.set(...jointsMap.RHip);
  _vLeftHip.set(...jointsMap.LHip);
  _vNeck.set(...jointsMap.Neck);

  _vZ.subVectors(_vNeck, _vMidHip);
  if (flipHorizontally) {
    _vX.subVectors(_vLeftHip, _vRightHip);
  } else {
    _vX.subVectors(_vRightHip, _vLeftHip);
  }
  _vY.crossVectors(_vZ, _vX);

  // temp: verify with contrived vY and vZ
  // vY = new Vector3(0, 1, 0);
  // vZ = new Vector3(0, 0, 1);

  // convert vY and vZ to gl coordinates (i.e., rotate 90 deg about x axis)
  // vY = new Vector3(0, 0, -1);
  // vZ = new Vector3(0, 1, 0);
  _vYGL.set(_vY.x, _vY.z, -_vY.y);
  _vZGL.set(_vZ.x, _vZ.z, -_vZ.y);

  if (relativeTarget) {
    // relative, local target
    let vUp = _vZGL;
    _vTarget.copy(_vYGL).negate();

    root.position.copy(_vOrigin); // move to origin for relative orientation
    root.up.copy(vUp);
    root.lookAt(_vTarget);

    root.position.copy(_vMidHip);
  } else {
    // absolute, world target (not working)
    let vUp = _vZGL;
    _vTarget.copy(_vMidHip).sub(_vYGL);

    root.position.copy(_vMidHip);

    root.up.copy(vUp);
    root.lookAt(_vTarget);
  }

  tracePlantRootEnabled &&
    traceVerboseFirstCall(
      "***** at tail of plantRoot, before any updates *****"
    );

  // root.matrixAutoUpdate = false; // haven't figured out why this breaks planting the root
  root.updateMatrixWorld(); // seems to be necessary here, even with .matrixAutoUpdate true

  tracePlantRootEnabled &&
    traceVerboseFirstCall(
      "***** at tail of plantRoot, after updateMatrixWorld *****"
    );

  plantRootOnce = false;
};

const poseArms = (root: Object3D, boneDefs: any, jointsMap: JointsMapType) => {
  let leftShoulder = root.getObjectByName("L_shoulder");
  let rightShoulder = root.getObjectByName("R_shoulder");

  leftShoulder?.traverse((obj: any) => {
    poseBone(obj, boneDefs, jointsMap);
  });
  rightShoulder?.traverse((obj: any) => {
    poseBone(obj, boneDefs, jointsMap);
  });
};

const isHead = (d: any) => d.name === "head";

const addHeadBoneDef = (boneDefs: any) => {
  if (!boneDefs.find(isHead)) {
    boneDefs.push({
      name: "head",
      joints: ["MidShoulder", "MidEar"],
    });
  }
};

const removeHeadBoneDef = (boneDefs: any) => {
  _remove(boneDefs, isHead);
};

interface poseRigArgs {
  root: Object3D;
  boneDefs: any;
  jointsMap: JointsMapType;
  torsoTwistEnabled?: boolean;
  scale?: number;
  headTurnEnabled?: boolean;
  headTurnFirstEnabled?: boolean;
  stretchEnabled?: boolean;
  turnVizEnabled?: boolean;
  poseModeEnabled?: boolean;
}

/**
 * Positions and orients the root, then traverses the rig to pose individual bones.
 * @param root
 * @param boneDefs
 * @param jointsMap
 * @param torsoTwistEnabled
 * @param scale
 * @param headTurnEnabled
 * @param stretchEnabled
 * @param turnValues
 */
const poseRig = ({
  root,
  boneDefs,
  jointsMap,
  torsoTwistEnabled = true,
  scale = 1,
  headTurnEnabled = true,
  headTurnFirstEnabled = false,
  stretchEnabled = false,
  turnVizEnabled = false,
}: poseRigArgs) => {
  restoreInitialState(root);

  scale && root.scale.set(scale, scale, scale);

  plantRoot(root, jointsMap);

  // TODO    skip posing shoulders, elbows, and wrists, which will be posed after applying twist?
  root.children.forEach((child: any) => {
    child.traverse((obj: any) => {
      poseBone(obj, boneDefs, jointsMap, scale, stretchEnabled);
    });
  });

  if (headTurnEnabled && headTurnFirstEnabled) {
    return applyHeadTurn(root, jointsMap, turnVizEnabled);
  }

  if (torsoTwistEnabled) {
    applyTwist(root, jointsMap);

    poseArms(root, boneDefs, jointsMap);
  }

  if (headTurnEnabled && !headTurnFirstEnabled) {
    return applyHeadTurn(root, jointsMap, turnVizEnabled);
  }
};

const init = (model: any, bonesMapRef: any, rootRef: any) => {
  if (traceModelEnabled && once) {
    console.log("----- model when loaded -----");
    traceModel(model);
  }

  bonesMapRef.current = toBonesMap(model);
  rootRef.current = model.scene.getObjectByName(rootBoneName);
  initBones(rootRef.current);

  // let root = rootRef.current;
  // if (root) {
  //   console.log("***** after initBones *****");
  //   console.log("--------------------------------------------");
  //   console.log(root);
  //   traceObjectVerbose(root);
  //   console.log("--------------------------------------------");
  // }
};

const shiftVertically = (root: Object3D, delta = 0) => {
  root.position.setZ(root.position.z + delta);
};

interface Props {
  frameNum: number;
  positionId: number;
  figureData: any;
  joints: number[];
  playerInfo?: StatsApiNS.Player;
  side: "home" | "away";
  colorManager: ColorManager;
  teamPlayerModelUrl: string;
  useFigureModelStore: UseStore<FigureModelStore>;
  opacity?: number;
  castShadow?: boolean;

  [key: string]: any;
}

export const FigureModel = ({
  frameNum,
  positionId,
  figureData,
  joints,
  teamPlayerModelUrl,
  useFigureModelStore,
  playerInfo,
  side,
  colorManager,
  opacity = 1,
  castShadow = true,
  ...props
}: Props) => {
  let renderCountRef = useRef(0);
  let [model, setModel] = useState<any>();
  let bonesMapRef = useRef<any>(); // collection of bones, indexed by name
  let rootRef = useRef<Object3D>(null);
  let meshRef = useRef<Mesh | SkinnedMesh | null>(null);
  let primitiveRef = useRef<Object3D>();
  let jointsMap = augmentJointsMap(joints);
  const {
    figureModelOverrideUrl,
    figureModelScale,
    figureModelFrustumCullingEnabled,
    figureModelVerticalOffset,
    torsoTwistEnabled,
    stretchEnabled,
    wireframeEnabled,
    headTiltEnabled,
    headTurnEnabled,
    headTurnDiagnosticsVizEnabled,
    headTurnFirstEnabled,
  } = useFigureModelStore();

  useEffect(() => {
    if (
      figureModelOverrideUrl &&
      figureModelOverrideUrl !== NO_OVERRIDE_SELECTED
    ) {
      loader.load(figureModelOverrideUrl, setModel);
    } else {
      // Attempt to load team specific model.
      loader.load(teamPlayerModelUrl, setModel, undefined, (error) => {
        console.log(error, `error loading ${teamPlayerModelUrl}`);
      });
    }
  }, [teamPlayerModelUrl, figureModelOverrideUrl]); // on url change

  // find the armature when the model is available
  useEffect(() => {
    if (model) {
      init(model, bonesMapRef, rootRef);
      model.scene.traverse((obj: any) => {
        if (isMeshy(obj) && obj.material.map) {
          if (playerInfo) {
            const playerOptions = {
              number: playerInfo.jerseyNumber,
              name: playerInfo.person.lastName,
            };
            const teamOptions = colorManager.get(playerInfo.parentTeamId, side);
            obj.material.map = new PlayerTexture(
              obj.material.map.image,
              state.globalOptions,
              teamOptions,
              playerOptions
            );
          }
          meshRef.current = obj;
        }
      });

      once = false;
    }
  }, [model, playerInfo, side, colorManager]);

  useEffect(() => {
    if (rootRef.current) {
      adjustOpacity(rootRef.current, opacity);
    }
  }, [rootRef, opacity]);

  useEffect(() => {
    if (model) {
      setObjectTreeFrustumCulled(model.scene, figureModelFrustumCullingEnabled);
    }
  }, [figureModelFrustumCullingEnabled, model]);

  // update material opacities in sync with opacity
  useEffect(() => {
    let root = rootRef.current;
    if (root) {
      root.traverse((obj: any) => {
        if (isMesh(obj)) {
          obj.material.transparent = opacity !== 1;
          obj.material.opacity = opacity;
        }
      });

      if (meshRef.current) {
        // @ts-ignore
        meshRef.current.material.transparent = opacity !== 1;
        // @ts-ignore
        meshRef.current.material.opacity = opacity;
      }
    }
  }, [rootRef, opacity]);

  useEffect(() => {
    if (meshRef.current) {
      // @ts-ignore
      meshRef.current.material.wireframe = wireframeEnabled;
    }
  }, [wireframeEnabled]);

  useEffect(() => {
    let root = rootRef.current;
    if (root) {
      if (meshRef.current) {
        meshRef.current.castShadow = castShadow;
      }
    }
  }, [model, castShadow]);

  useEffect(() => {
    if (headTiltEnabled) {
      addHeadBoneDef(boneDefs);
    } else {
      removeHeadBoneDef(boneDefs);
    }
  }, [headTiltEnabled]);

  let [turnValues, setTurnValues] = useState<any>();
  useFrame(() => {
    renderCountRef.current++;
    if (!rootRef.current) {
      return;
    }

    if (useFrameOnce && !plantRootOnce) {
      // console.log("***** root in useFrame *****");
      // // @ts-ignore
      // traceObjectVerbose(rootRef.current);

      useFrameOnce = false;
    }

    if (primitiveRef.current) {
      primitiveRef.current.visible = isPlantable(jointsMap);
    }

    let turnValues = poseRig({
      root: rootRef.current,
      boneDefs,
      jointsMap,
      torsoTwistEnabled,
      scale: figureModelScale,
      headTurnEnabled,
      headTurnFirstEnabled,
      stretchEnabled,
      turnVizEnabled: headTurnDiagnosticsVizEnabled,
    });

    setTurnValues(turnValues);

    shiftVertically(rootRef.current, figureModelVerticalOffset);

    if (traceModelEnabled && renderCountRef.current === 1) {
      console.log("----- model after first poseRig call -----");
      traceModel(model);
    }
  });

  return model ? (
    <group name={modelNamePrefix + positionId.toString()}>
      <primitive ref={primitiveRef} object={model.scene} {...props} />
      {headTurnDiagnosticsVizEnabled && <TurnValues turnValues={turnValues} />}
    </group>
  ) : null;
};
