export const midPitchLocation = {
  name: "Mid-Pitch",
  arr: [0, 30, 0], // mlb
  point: { x: 0, y: 30, z: 0 }, // mlb
  gl: [0, 0, -30],
};

export const moundLocation = {
  name: "Mound",
  arr: [0, 60.5, 0],
  point: { x: 0, y: 60.5, z: 0 },
  gl: [0, 0, -60.5],
};

export const plateLocation = {
  name: "Plate",
  arr: [0, 0, 0],
  point: { x: 0, y: 0, z: 0 },
  gl: [0, 0, 0],
};

export const firstBaseLocation = {
  name: "1st Base",
  arr: [63.64, 63.64, 0],
  point: { x: 63.64, y: 63.64, z: 0 },
  gl: [63.64, 0, -63.64],
};

export const secondBaseLocation = {
  name: "2nd Base",
  arr: [0, 127.28, 0],
  point: { x: 0, y: 127.28, z: 0 },
  gl: [0, 0, -127.28],
};

export const thirdBaseLocation = {
  name: "3rd Base",
  arr: [-63.64, 63.64, 0],
  point: { x: -63.64, y: 63.64, z: 0 },
  gl: [-63.64, 0, -63.64],
};

export const locations = [
  midPitchLocation,
  moundLocation,
  plateLocation,
  firstBaseLocation,
  secondBaseLocation,
  thirdBaseLocation,
];
