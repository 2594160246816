import React, { useMemo, useEffect, useRef, useLayoutEffect } from "react";
import * as THREE from "three";

interface ExtrudeSettings {
  extrudePath: THREE.CatmullRomCurve3;
  geometry: THREE.ExtrudeBufferGeometry;
  steps: number;
  bevelEnabled: boolean;
}

const crateExtrusionShape = () => {
  let height = 0.05;
  let width = 2;
  let halfWidth = 0.5 * width;
  let shape = new THREE.Shape();

  shape.moveTo(0, -halfWidth);
  shape.lineTo(0, halfWidth);
  shape.lineTo(height, halfWidth);
  shape.lineTo(height, 0);
  shape.lineTo(0, -halfWidth);

  return shape;
};

const extrusionShape = crateExtrusionShape();

let flag = false;

interface PathMeshProps {
  points: any;
  material: any;
}

// added PathMesh outside of Path so that Path can have a conditional return (useUpdate hook here in Path)
const PathMesh = ({ points, material, ...other }: PathMeshProps) => {
  // TODOHI  create geometry once and instead use .setDrawRange to 'grow' across the shape
  // given n points and x = 0 to n-1, .setDrawRange(0, 12 + 24 * Math.floor(x))
  // 12 = initial positions for 4-vertex face from rectangle shape?

  // this is essentially a 2D curve with z=0, but can't use THREE.SplineCurve because ExtrudeBufferGeometry
  // expects a 3D curve for its extrudePath  setting?
  let curve = useMemo(
    () =>
      new THREE.CatmullRomCurve3(
        points.map((p: any) => new THREE.Vector3(p.x, p.y, 0))
      ),
    [points]
  );

  let meshRef:
    | React.MutableRefObject<ExtrudeSettings>
    | React.MutableRefObject<undefined>
    | undefined = useRef(undefined);

  useLayoutEffect(() => {
    let extrudeSettings = {
      extrudePath: curve,
      // TODOHI  base steps on length of points series
      // steps: 50,
      steps: points.length,
      bevelEnabled: false,
    };

    if (meshRef && meshRef.current) {
      let oldGeometry = meshRef.current.geometry;

      meshRef.current.geometry = new THREE.ExtrudeBufferGeometry(
        extrusionShape,
        extrudeSettings
      );

      if (flag) {
        console.log("points.length", points.length);
        console.log("meshRef.current.geometry", meshRef.current.geometry);
        flag = false;
      }

      oldGeometry && oldGeometry.dispose();
    } else {
      console.log("----- points", points);
      debugger;
    }
  }, [points, curve]);

  // dispose the geometry at unmount
  useEffect(
    () => () =>
      meshRef?.current?.geometry && meshRef?.current?.geometry.dispose(),
    [meshRef]
  );

  return (
    <mesh ref={meshRef} position={[0, 0, 0.1]} {...other}>
      {/*<extrudeBufferGeometry shape={shape} extrudeSettings={extrudeSettings}/>*/}
      {material}
    </mesh>
  );
};

interface Props {
  points: any[];
  material: any;
}

export const Path = ({ points, material, ...other }: Props) => {
  // TODOHI  need at least 3 points for CatmullRomCurve3 or ExtrudeBufferGeometry?
  if (points.length < 3) {
    return null;
  }

  return <PathMesh points={points} material={material} {...other} />;
};
