import React, { useMemo } from "react";
import { FigureGroup } from "../Figure";
import { StatsApiStore } from "../../stores/statsApiStore";

interface Props {
  homeTeamId: any;
  battingTeamId: any;
  fieldingTeamId: any;
  batSide: any;
  frame: any;
  skeleton: any;
  opacity?: number;
  positionMap: any;
  rootStore: any;
  stickFigureEnabled?: boolean;
  figureModelEnabled?: boolean;
  visiblePositionIds: string[];
  castShadow?: boolean;
  ref: any;
  nameplateEnabled?: boolean;
}

// TODO    pass in Figure's optional props

// TODO    combine FigureGroup with FrameFiguresGroup and use for both strobes and normal rendering?

// Displays all the figures for a tracking frame using FigureGroup, which handles both
// stick figures and figure models
export const FrameFiguresGroup = React.forwardRef((props: Props, ref: any) => {
  let {
    homeTeamId,
    battingTeamId,
    fieldingTeamId,
    batSide,
    frame,
    skeleton,
    opacity = 0.2,
    positionMap,
    rootStore,
    stickFigureEnabled = true,
    figureModelEnabled = true,
    visiblePositionIds,
    castShadow = false,
    nameplateEnabled = true,
  } = props;

  let { useStatsApiStore } = rootStore;
  const getPlayerById = useStatsApiStore(($: StatsApiStore) => $.getPlayerById);
  let { positions } = frame;
  let stickFigures = useMemo(() => {
    let visiblePositions = positions.filter((p: any) =>
      visiblePositionIds.includes(`${p.positionId}`)
    );

    return visiblePositions.map((position: any) => {
      let { jointsMap, positionId } = position;
      const playerId = positionMap[positionId]?.id;
      const playerInfo = playerId ? getPlayerById(playerId) : undefined;

      return (
        <FigureGroup
          frameNum={frame?.num}
          key={positionId}
          rootStore={rootStore}
          stickFigureEnabled={stickFigureEnabled}
          figureModelEnabled={figureModelEnabled}
          positionId={positionId}
          figureData={position}
          playerInfo={playerInfo}
          jointsMap={jointsMap}
          skeleton={skeleton}
          homeTeamId={homeTeamId}
          battingTeamId={battingTeamId}
          fieldingTeamId={fieldingTeamId}
          batSide={batSide}
          stickFigureOpacity={opacity}
          modelOpacity={opacity}
          castShadow={castShadow}
          nameplateEnabled={nameplateEnabled}
        />
      );
    });
  }, [
    stickFigureEnabled,
    figureModelEnabled,
    frame,
    visiblePositionIds,
    positions,
    skeleton,
    opacity,
    rootStore,
    castShadow,
    batSide,
    battingTeamId,
    fieldingTeamId,
    homeTeamId,
    getPlayerById,
    positionMap,
  ]);

  return <group ref={ref}>{stickFigures}</group>;
});
