/*
    PlayFrameOverlay holds a default overlay layer. Can be swapped for another overlay in PlayFrame's Overlay prop.
 */
import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { PlaySummaryView } from "../PlaySummaryView";
import { ViewPresetMenu } from "../ViewPresetMenu";
import { ProductionModeButton } from "../ProductionModeButton";
import { Timebase } from "../../models/timebase";

const cornerMargin = 2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      pointerEvents: "none",
      "& > *": {
        // margin: theme.spacing(1),
        pointerEvents: "all",
      },
    },
    topLeft: {
      position: "absolute",
      top: cornerMargin,
      left: cornerMargin,
    },
    topRight: {
      position: "absolute",
      top: cornerMargin,
      right: cornerMargin,
      "& div": {
        marginTop: 5
      }
    },
    bottomRight: {
      position: "absolute",
      bottom: cornerMargin,
      right: cornerMargin,
    },
  })
);

export interface OverlayProps {
  rootStore: any;
  playData: any;
  timebase: Timebase;
  onViewChange: any;
}

export const PlayFrameOverlay: React.FC<OverlayProps> = ({
  rootStore,
  playData,
  timebase,
  onViewChange,
}) => {
  let classes = useStyles();
  let usePlaySummaryStore = rootStore.usePlaySummaryStore;

  // TODOHI  move fullscreen button here. probably requires moving fullscreen state to a store
  // will need to add update PlayFrame to use the store flag too (currently uses useToggle state)

  return (
    <div className={classes.root}>
      <div className={classes.topLeft}>
        <PlaySummaryView
          usePlaySummaryStore={usePlaySummaryStore}
          playData={playData}
          timebase={timebase}
        />
      </div>

      {/*<div className={classes.bottomRight}>*/}
      {/*    <button className={styles.fsButton} onClick={toggleIsFullscreen}>*/}
      {/*        {isFullscreen*/}
      {/*            ? <BsFullscreenExit className={styles.fsIcon}/>*/}
      {/*            : <BsFullscreen className={styles.fsIcon}/>}*/}
      {/*    </button>*/}
      {/*</div>*/}

      <div className={classes.topRight}>
        <ViewPresetMenu hotkeys={true} onViewSelected={onViewChange} />
        <ProductionModeButton rootStore={rootStore} />
      </div>
    </div>
  );
};
