import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Slider from "@material-ui/core/Slider";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import {
  easingTypes,
  flycamParams,
} from "field-of-things/src/stores/flyCamStore";
import { IoIosShuffle } from "react-icons/io";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";

const ParamLabel = ({
  text,
  cb,
  locked,
}: {
  text: string;
  cb: any;
  locked: boolean;
}) => {
  let handleClick = () => {
    cb && cb();
  };

  return (
    <span>
      {locked ? (
        <AiFillLock onClick={handleClick} />
      ) : (
        <AiFillUnlock onClick={handleClick} />
      )}
      &nbsp; {text}
    </span>
  );
};

const gazeTargetDot = (
  <span style={{ fontSize: 30, color: "#f0f", marginRight: 10 }}>•</span>
);
const startRefDot = (
  <span style={{ fontSize: 30, color: "#4ed54f", marginRight: 10 }}>•</span>
);
const rampEndRefDot = (
  <span style={{ fontSize: 30, color: "#168617", marginRight: 10 }}>•</span>
);
const midRefDot = (
  <span style={{ fontSize: 30, color: "#056ddb", marginRight: 10 }}>•</span>
);
const tailRefDot = (
  <span style={{ fontSize: 30, color: "#ce0543", marginRight: 10 }}>•</span>
);

const randomFromRange = (range: number[]) =>
  range[0] + Math.round(Math.random() * (range[1] - range[0]));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sliderRoot: {
    width: 300,
  },
  narrowButton: {
    width: 100,
  },
}));

interface FlyCamKnobsProps {
  useFlyCamStore: any;
}

export const FlyCamKnobs = ({ useFlyCamStore }: FlyCamKnobsProps) => {
  let classes = useStyles();
  let {
    startPosDistance,
    startPosAngle,
    startPosHeight,
    setStartPosDistance,
    setStartPosAngle,
    setStartPosHeight,
    startLookAtY,
    startLookAtZ,
    setStartLookAtY,
    setStartLookAtZ,
    launchDistance,
    setLaunchDistance,
    launchRampDuration,
    setLaunchRampDuration,
    midpointCameraDistance,
    setMidpointCameraDistance,
    restDuration,
    setRestDuration,
    restCameraDistance,
    setRestCameraDistance,
    referenceMarksVisible,
    setReferenceMarksVisible,
    easingType,
    setEasingType,
    paramLocks,
    setParamLocks,
  } = useFlyCamStore();
  let {
    startPosDistanceLocked,
    startPosAngleLocked,
    startPosHeightLocked,
    startLookAtYLocked,
    startLookAtZLocked,
    launchDistanceLocked,
    launchRampDurationLocked,
    midpointCameraDistanceLocked,
    restDurationLocked,
    restCameraDistanceLocked,
  } = paramLocks;

  let handleStartPosDistanceChange = (event: any, value: any) =>
    setStartPosDistance(+value);

  let handleStartPosAngleChange = (event: any, value: any) =>
    setStartPosAngle(+value);

  let handleStartPosHeightChange = (event: any, value: any) =>
    setStartPosHeight(+value);

  let handleStartLookAtYChange = (event: any, value: any) =>
    setStartLookAtY(+value);

  let handleStartLookAtZChange = (event: any, value: any) =>
    setStartLookAtZ(+value);

  let handleLaunchDistanceChange = (event: any, value: any) =>
    setLaunchDistance(+value);

  let handleLaunchRampDurationChange = (event: any, value: any) =>
    setLaunchRampDuration(1000 * +value);

  let handleMidpointCameraDistanceChange = (event: any, value: any) =>
    setMidpointCameraDistance(+value);

  let handleRestDurationChange = (event: any, value: any) =>
    setRestDuration(1000 * +value);

  let handleRestCameraDistanceChange = (event: any, value: any) =>
    setRestCameraDistance(+value);

  let handleEasingTypeChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const easingType = event.target.value;

    setEasingType(easingType);
  };

  let handleResetClick = () => {
    !startPosDistanceLocked &&
      setStartPosDistance(flycamParams.startPosDistance.default);
    !startPosAngleLocked &&
      setStartPosAngle(flycamParams.startPosAngle.default);
    !startPosHeightLocked &&
      setStartPosHeight(flycamParams.startPosHeight.default);
    !startLookAtYLocked && setStartLookAtY(flycamParams.startLookAtY.default);
    !startLookAtZLocked && setStartLookAtZ(flycamParams.startLookAtZ.default);
    !launchDistanceLocked &&
      setLaunchDistance(flycamParams.launchDistance.default);
    !launchRampDurationLocked &&
      setLaunchRampDuration(flycamParams.launchRampDuration.default);
    !midpointCameraDistanceLocked &&
      setMidpointCameraDistance(flycamParams.midpointCameraDistance.default);
    !restDurationLocked && setRestDuration(flycamParams.restDuration.default);
    !restCameraDistanceLocked &&
      setRestCameraDistance(flycamParams.restCameraDistance.default);
  };

  let handleShuffleClick = () => {
    !startPosDistanceLocked &&
      setStartPosDistance(randomFromRange(flycamParams.startPosDistance.range));
    !startPosAngleLocked &&
      setStartPosAngle(randomFromRange(flycamParams.startPosAngle.range));
    !startPosHeightLocked &&
      setStartPosHeight(randomFromRange(flycamParams.startPosHeight.range));
    !startLookAtYLocked &&
      setStartLookAtY(randomFromRange(flycamParams.startLookAtY.range));
    !startLookAtZLocked &&
      setStartLookAtZ(randomFromRange(flycamParams.startLookAtZ.range));
    !launchDistanceLocked &&
      setLaunchDistance(randomFromRange(flycamParams.launchDistance.range));
    !launchRampDurationLocked &&
      setLaunchRampDuration(
        randomFromRange(flycamParams.launchRampDuration.range)
      );
    !midpointCameraDistanceLocked &&
      setMidpointCameraDistance(
        randomFromRange(flycamParams.midpointCameraDistance.range)
      );
    !restDurationLocked &&
      setRestDuration(randomFromRange(flycamParams.restDuration.range));
    !restCameraDistanceLocked &&
      setRestCameraDistance(
        randomFromRange(flycamParams.restCameraDistance.range)
      );
  };

  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <Tooltip title="Shuffle" placement="top">
        <Button
          size="small"
          onClick={handleShuffleClick}
          className={classes.narrowButton}
        >
          <IoIosShuffle />
        </Button>
      </Tooltip>

      <Tooltip title="Restore Defaults" placement="top">
        <Button
          size="small"
          onClick={handleResetClick}
          className={classes.narrowButton}
        >
          Reset
        </Button>
      </Tooltip>

      <br />

      <h3>Pitch – camera position</h3>
      <div className={classes.sliderRoot}>
        <ParamLabel
          text={`Cam Start-Pos Ground Distance: ${startPosDistance}`}
          locked={startPosDistanceLocked}
          cb={() =>
            setParamLocks({
              ...paramLocks,
              startPosDistanceLocked: !startPosDistanceLocked,
            })
          }
        />
        <Slider
          disabled={startPosDistanceLocked}
          min={flycamParams.startPosDistance.range[0]}
          max={flycamParams.startPosDistance.range[1]}
          value={startPosDistance}
          aria-labelledby="cam-start-pos-distance-slider"
          step={1}
          valueLabelDisplay="auto"
          onChange={handleStartPosDistanceChange}
        />
        <FormHelperText>
          Camera's starting (x,y) distance from the plate
        </FormHelperText>
      </div>
      <br />
      <div className={classes.sliderRoot}>
        <ParamLabel
          text={`Cam Start-Pos Angle: ${startPosAngle} °`}
          locked={startPosAngleLocked}
          cb={() =>
            setParamLocks({
              ...paramLocks,
              startPosAngleLocked: !startPosAngleLocked,
            })
          }
        />
        <Slider
          disabled={startPosAngleLocked}
          min={flycamParams.startPosAngle.range[0]}
          max={flycamParams.startPosAngle.range[1]}
          value={startPosAngle}
          aria-labelledby="cam-start-pos-angle-slider"
          step={1}
          valueLabelDisplay="auto"
          onChange={handleStartPosAngleChange}
        />
        <FormHelperText>
          Camera's starting angle, relative to field centerline
        </FormHelperText>
        <FormHelperText>
          + angles: opposite batter; - angles: behind batter
        </FormHelperText>
      </div>
      <br />
      <div className={classes.sliderRoot}>
        <ParamLabel
          text={`Cam Start-Pos Height: ${startPosHeight}'`}
          locked={startPosHeightLocked}
          cb={() =>
            setParamLocks({
              ...paramLocks,
              startPosHeightLocked: !startPosHeightLocked,
            })
          }
        />
        <Slider
          disabled={startPosHeightLocked}
          min={flycamParams.startPosHeight.range[0]}
          max={flycamParams.startPosHeight.range[1]}
          value={startPosHeight}
          aria-labelledby="cam-start-pos-height-slider"
          step={0.1}
          valueLabelDisplay="auto"
          onChange={handleStartPosHeightChange}
        />
        <FormHelperText>Camera's starting height</FormHelperText>
      </div>
      <h3>
        {referenceMarksVisible && gazeTargetDot}Pitch – camera gaze target
      </h3>
      <div className={classes.sliderRoot}>
        <ParamLabel
          text={`Cam Start LookAt Y: ${startLookAtY}'`}
          locked={startLookAtYLocked}
          cb={() =>
            setParamLocks({
              ...paramLocks,
              startLookAtYLocked: !startLookAtYLocked,
            })
          }
        />
        <Slider
          disabled={startLookAtYLocked}
          min={flycamParams.startLookAtY.range[0]}
          max={flycamParams.startLookAtY.range[1]}
          value={startLookAtY}
          aria-labelledby="cam-start-lookAt-y-slider"
          step={1}
          valueLabelDisplay="auto"
          onChange={handleStartLookAtYChange}
        />
      </div>
      <br />
      <div className={classes.sliderRoot}>
        <ParamLabel
          text={`Cam Start LookAt Z: ${startLookAtZ}'`}
          locked={startLookAtZLocked}
          cb={() =>
            setParamLocks({
              ...paramLocks,
              startLookAtZLocked: !startLookAtZLocked,
            })
          }
        />
        <Slider
          disabled={startLookAtZLocked}
          min={flycamParams.startLookAtZ.range[0]}
          max={flycamParams.startLookAtZ.range[1]}
          value={startLookAtZ}
          aria-labelledby="cam-start-lookAt-z-slider"
          step={1}
          valueLabelDisplay="auto"
          onChange={handleStartLookAtZChange}
        />
        <FormHelperText>
          Where the camera is pointed during the pitch
        </FormHelperText>
      </div>

      <h3>Launch</h3>

      <div className={classes.sliderRoot}>
        {referenceMarksVisible && startRefDot}
        <ParamLabel
          text={`Launch Distance: ${launchDistance}'`}
          locked={launchDistanceLocked}
          cb={() =>
            setParamLocks({
              ...paramLocks,
              launchDistanceLocked: !launchDistanceLocked,
            })
          }
        />
        <Slider
          disabled={launchDistanceLocked}
          min={flycamParams.launchDistance.range[0]}
          max={flycamParams.launchDistance.range[1]}
          value={launchDistance}
          aria-labelledby="launch-distance-slider"
          step={1}
          valueLabelDisplay="auto"
          onChange={handleLaunchDistanceChange}
        />
        <FormHelperText>
          Ball's distance from the plate when the camera begins moving
        </FormHelperText>
      </div>
      <br />
      <div className={classes.sliderRoot}>
        {referenceMarksVisible && rampEndRefDot}
        <ParamLabel
          text={`Launch Ramp Duration: ${launchRampDuration / 1000} sec`}
          locked={launchRampDurationLocked}
          cb={() =>
            setParamLocks({
              ...paramLocks,
              launchRampDurationLocked: !launchRampDurationLocked,
            })
          }
        />
        <Slider
          disabled={launchRampDurationLocked}
          min={flycamParams.launchRampDuration.range[0] / 1000}
          max={flycamParams.launchRampDuration.range[1] / 1000}
          value={launchRampDuration / 1000}
          aria-labelledby="launch-ramp-duration-slider"
          step={0.01}
          valueLabelDisplay="auto"
          onChange={handleLaunchRampDurationChange}
        />
        <FormHelperText>
          Interval beginning at at launch-distance to ramp the gaze target from
          the start gaze point to the ball
        </FormHelperText>
      </div>
      <h3>{referenceMarksVisible && midRefDot}Midpoint</h3>
      <div className={classes.sliderRoot}>
        <ParamLabel
          text={`Midpoint Camera Distance: ${midpointCameraDistance}'`}
          locked={midpointCameraDistanceLocked}
          cb={() =>
            setParamLocks({
              ...paramLocks,
              midpointCameraDistanceLocked: !midpointCameraDistanceLocked,
            })
          }
        />
        <Slider
          disabled={midpointCameraDistanceLocked}
          min={flycamParams.midpointCameraDistance.range[0]}
          max={flycamParams.midpointCameraDistance.range[1]}
          value={midpointCameraDistance}
          aria-labelledby="midpoint-camera-distance-slider"
          step={1}
          valueLabelDisplay="auto"
          onChange={handleMidpointCameraDistanceChange}
        />
        <FormHelperText>
          Camera's distance from the ball at the midpoint of its flight
        </FormHelperText>
      </div>
      <h3>{referenceMarksVisible && tailRefDot}Rest</h3>
      <div className={classes.sliderRoot}>
        <ParamLabel
          text={`Rest Duration: ${restDuration / 1000} sec`}
          locked={restDurationLocked}
          cb={() =>
            setParamLocks({
              ...paramLocks,
              restDurationLocked: !restDurationLocked,
            })
          }
        />
        <Slider
          disabled={restDurationLocked}
          min={flycamParams.restDuration.range[0] / 1000}
          max={flycamParams.restDuration.range[1] / 1000}
          value={restDuration / 1000}
          aria-labelledby="rest-duration-slider"
          step={0.1}
          valueLabelDisplay="auto"
          onChange={handleRestDurationChange}
        />
      </div>
      <FormHelperText>
        Camera's rest interval at the tail of the ball's flight
      </FormHelperText>
      <br />
      <div className={classes.sliderRoot}>
        <ParamLabel
          text={`Rest Camera Distance: ${restCameraDistance}'`}
          locked={restCameraDistanceLocked}
          cb={() =>
            setParamLocks({
              ...paramLocks,
              restCameraDistanceLocked: !restCameraDistanceLocked,
            })
          }
        />
        <Slider
          disabled={restCameraDistanceLocked}
          min={flycamParams.restCameraDistance.range[0]}
          max={flycamParams.restCameraDistance.range[1]}
          value={restCameraDistance}
          aria-labelledby="rest-camera-distance-slider"
          step={1}
          valueLabelDisplay="auto"
          onChange={handleRestCameraDistanceChange}
        />
      </div>
      <FormHelperText>
        Camera's distance to ball when the camera comes to rest
      </FormHelperText>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="easing-type">Easing</InputLabel>
        <NativeSelect
          value={easingType}
          onChange={handleEasingTypeChange}
          inputProps={{
            name: "easing-type",
            id: "easing-type",
          }}
        >
          {easingTypes.map((easingType: string) => (
            <option key={easingType} value={easingType}>
              {easingType}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <FormHelperText>Type of easing used in ascent and descent</FormHelperText>
      <br />

      <Divider />
      <br />
      <FormControlLabel
        control={
          <Switch
            checked={referenceMarksVisible}
            onChange={() => setReferenceMarksVisible(!referenceMarksVisible)}
            name="reference-marks-toggle"
            color="secondary"
            size="small"
          />
        }
        label="Reference Marks"
      />
      <FormHelperText>
        Displays the ball's position at the launch (G), midpoint (B), and rest
        (R) times
      </FormHelperText>
    </div>
  );
};
