import create from "zustand";

export type StrobeVisibilityType = "omni" | "leading" | "trailing";

export interface KnobsStore {
  figureTabPanelTabValue: number;

  setFigureTabPanelTabValue(figureTabPanelTabValue: number): void;

  cameraTabPanelTabValue: number;

  setCameraTabPanelTabValue(cameraTabPanelTabValue: number): void;
}

export const createKnobsStore = () =>
  create<KnobsStore>((set) => ({
    figureTabPanelTabValue: 0,
    setFigureTabPanelTabValue: (figureTabPanelTabValue: number) =>
      set({ figureTabPanelTabValue }),

    cameraTabPanelTabValue: 0,
    setCameraTabPanelTabValue: (cameraTabPanelTabValue: number) =>
      set({cameraTabPanelTabValue}),
  }));
