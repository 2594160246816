/*
    Renders a group of meshes at a series of positions given as an array of {x, y, z}
 */
import React from "react";

interface Position {
  x: number;
  y: number;
  z: number;
}

interface Props {
  Mesh: any;
  props: any;
  positions: Position[];
}

export const MeshSeriesGroup = ({ Mesh, props, positions }: Props) => (
  <group>
    {positions.map((p, i) => {
      return <Mesh key={i} castShadow {...props} position={[p.x, p.y, p.z]} />;
    })}
  </group>
);
