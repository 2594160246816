import { Vector3 } from "three";
import create from "zustand";
import { IFigureSideCamParams } from "../components/FigureSideCam";

type CameraType =
  | "orbit-controls"
  | "figure-chase-cam"
  | "figure-side-cam"
  | "ball-chase-cam"
  | "fly-cam"
  | "pov-cam";

export interface CameraStore {
  cameraMatrix: number[];

  setCameraMatrix(matrix: number[]): void;

  cameraType: CameraType;

  setCameraTypeOrbitControls(): void;

  setCameraTypeFigureChaseCam(): void;

  setCameraTypeBallChaseCam(): void;

  setCameraTypeFigureSideCam(): void;

  setCameraTypeFigureSpinCam(): void;

  setCameraTypePOVCam(): void;

  orbitControlsParams: OrbitControlsParams;

  setOrbitControlsParams(params: OrbitControlsParams): void;

  figureChaseCamParams: FigureChaseCamParams;

  setFigureChaseCamParams(params: FigureChaseCamParams): void;

  figureSideCamParams: IFigureSideCamParams;

  setFigureSideCamParams(params: IFigureSideCamParams): void;

  gameCamParams: GameCamParams;

  setGameCamParams(params: GameCamParams): void;

  ballChaseCamParams: BallChaseCamParams;

  setBallChaseCamParams(params: BallChaseCamParams): void;

  perspectiveCameraFov: number;

  setPerspectiveCameraFov(figureStickSize: number): void;
}

export interface OrbitControlsParams {
  autoRotate: boolean;
  autoRotateSpeed: number;
  target: number[];
  relocDest: Vector3;
  relocating: boolean;
  relocStadium: boolean;
  positionId?: number;
}

interface FigureChaseCamParams {
  positionId: number;
  offsetInterval: number;
  cameraHeight: number;
  lookAtHeight: number;
}

export interface BallChaseCamParams {
  heightMethod: string;
  constantHeightMethod: string;
  fixedHeight: number;
  apexMultiple: number;
  constantHeightOffset: number;
  multiplePoint: string;
  heightMultiple: number;
  offsetInterval: number;
}

export interface GameCamParams {
  positionId: number;
  distance: number;
  gamma: number;
  theta: number;
  alphaXY: number;
  alphaZ: number;
  enabled: {
    pan: boolean;
    rotate: boolean;
    zoom: boolean;
    touch: boolean;
  }
  panOffsetX: number;
  panOffsetY: number;
}

// TODOHI  add an initial values argument to createCameraStore
export const createCameraStore = () =>
  create<CameraStore>((set) => ({
    cameraMatrix: [],
    setCameraMatrix: (matrix: number[]) => set({ cameraMatrix: matrix }),

    // TODO    need a single setter for cameraType and cameraParams to set synchronously?

    cameraType: "orbit-controls",
    // cameraType: 'figure-chase-cam',
    // cameraType: "figure-side-cam",
    // cameraType: "figure-spin-cam",
    // cameraType: 'ball-chase-cam',
    // cameraType: "fly-cam",
    // cameraType: "pov-cam",

    setCameraTypeOrbitControls: () =>
      set(() => ({ cameraType: "orbit-controls" })),
    setCameraTypeFigureChaseCam: () =>
      set(() => ({ cameraType: "figure-chase-cam" })),
    setCameraTypeFigureSideCam: () =>
      set(() => ({ cameraType: "figure-side-cam" })),
    setCameraTypeFigureSpinCam: () =>
      set(() => ({ cameraType: "figure-spin-cam" })),
    setCameraTypeBallChaseCam: () =>
      set(() => ({ cameraType: "ball-chase-cam" })),
    setCameraTypeFlyCam: () => set(() => ({ cameraType: "fly-cam" })),
    setCameraTypePOVCam: () => set(() => ({ cameraType: "pov-cam" })),

    // OrbitControls
    orbitControlsParams: {
      autoRotate: false,
      autoRotateSpeed: -4,
      //target: [0, 5, -30], // mid-pitch
      relocDest: new Vector3(),
      relocating: false,
      relocStadium: false,
      positionId: undefined,
      target: [0, 5, 0], // plate
      // target: [0, 5, -60.5], // mound
    },
    setOrbitControlsParams: (params: OrbitControlsParams) =>
      set({ orbitControlsParams: params }),

    // figure chase-cam
    figureChaseCamParams: {
      positionId: 10,
      offsetInterval: -500,
      cameraHeight: 7,
      lookAtHeight: 3,
    },
    setFigureChaseCamParams: (params: FigureChaseCamParams) =>
      set({ figureChaseCamParams: params }),

    // figure side-cam
    figureSideCamParams: {
      positionId: 10,
      pivotType: "Location",
      pivotName: "Mid-Pitch",
      pivot: { x: 0, y: 30, z: 0 },
      distance: 28,
      cameraHeight: 8,
      lookAtHeight: 3,
    },
    setFigureSideCamParams: (params: IFigureSideCamParams) =>
      set({ figureSideCamParams: params }),

    // figure spin-cam
    gameCamParams: {
      positionId: 10,
      distance: 28,
      gamma: 0,
      theta: 60,
      alphaXY: 0.85,
      alphaZ: 0.05,
      enabled: {
        pan: true,
        rotate: true,
        zoom: true,
        touch: true,
      },
      panOffsetX: 0,
      panOffsetY: 0,
    },
    setGameCamParams: (params: GameCamParams) =>
      set({ gameCamParams: params }),

    // ball chase-cam
    ballChaseCamParams: {
      heightMethod: "constant-height", // fixed height, either set or relative to apex height
      // heightMethod: 'constant-height-offset', // constant added/subtracted to/from ball height
      // heightMethod: 'variable-height', // % of ball height

      // constant-height params
      constantHeightMethod: "set-height",
      // constantHeightMethod: 'apex-relative-height',

      fixedHeight: 60, // constant-height:set-height height value
      // constantHeightApexOffset: -10, // constant-height:apex-relative-height apex height offset value
      apexMultiple: 1.1, // constant-height:apex-relative-height apex height multiple, [0..2]

      // constant-height-offset params
      constantHeightOffset: 10, // constant-height-offset offset value

      // variable-height-offset params
      // heightOffsetFactor: -.5, // variable-height-offset offset factor
      multiplePoint: "ball",
      // multiplePoint: 'offset',
      heightMultiple: 1.1, // variable-height

      offsetInterval: -500,
    },

    setBallChaseCamParams: (params: BallChaseCamParams) =>
      set({ ballChaseCamParams: params }),

    perspectiveCameraFov: 50,
    setPerspectiveCameraFov: (perspectiveCameraFov: number) =>
      set({ perspectiveCameraFov }),
  }));
