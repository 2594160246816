import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { TabPanel } from "./Knobs";
import { FpsTurtle } from "../icons";
import { captionTypes } from "field-of-things/src/components/FigureCaption";
import { allPositions } from "field-of-things/src/models/positions";
import { UseStore } from "zustand";
import { FigureCaptionsStore } from "field-of-things/src/stores/figureCaptionsStore";
import { FigureAppearanceStore } from "field-of-things/src/stores/figureAppearanceStore";
import { FigurePositionStore } from "field-of-things/src/stores/figurePositionStore";
import { PlaySummaryStore } from "field-of-things/src/stores/playSummaryStore";
import { FigureModelStore } from "field-of-things/src/stores/figureModelStore";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  sliderRoot: {
    width: 200,
  },
  multiSelect: {
    width: 160,
  },
  multiSelectControl: {
    alignItems: "flex-start",
  },
  figureButton: {
    margin: theme.spacing(0.5),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PLAYERS_POSITION_IDS = allPositions
  .filter((p) => !(p.type === "coach" || p.type === "umpire"))
  .map((p) => p.id);
const ALL_POSITION_IDS = allPositions.map((p) => p.id);

interface Props {
  value: any;
  index: number;
  useFigurePositionStore: UseStore<FigurePositionStore>;
  useFigureAppearanceStore: UseStore<FigureAppearanceStore>;
  useFigureCaptionsStore: UseStore<FigureCaptionsStore>;
  usePlaySummaryStore: UseStore<PlaySummaryStore>;
  useFigureModelStore: UseStore<FigureModelStore>;
}

export const FigureGeneralKnobsPanel = (props: Props) => {
  let {
    index,
    value,
    useFigurePositionStore,
    useFigureAppearanceStore,
    useFigureCaptionsStore,
    useFigureModelStore,
  } = props;

  const classes = useStyles();

  const {
    playerNamePlatesEnabled,
    setPlayerNamePlatesEnabled,
    playerNamePlatesEnabledPerPlayer,
    setPlayerNamePlatesEnabledPerPlayer,
    playerNamePlateScale,
    setPlayerNamePlateScale,
    visiblePositionIds,
    setVisiblePositionIds,
  } = useFigureAppearanceStore();

  const {
    jointCleanup,
    toggleJointCleanup,
    upsample,
    toggleUpsample,
  } = useFigurePositionStore();

  const { captionSet, setCaptionSet } = useFigureCaptionsStore();
  let {
    figureModelCastShadow,
    setFigureModelCastShadow,
  } = useFigureModelStore();

  let handleCaptionsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCaptionSet(event.target.value as string[]);
  };

  const handleNamePlateScaleChange = (event: any, value: any) => {
    setPlayerNamePlateScale(value);
  };

  const handleMultiSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    cb: (values: string[]) => void
  ) => {
    const { options } = event.target as HTMLSelectElement;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    cb(value);
  };

  const trackablePlayers = allPositions.filter(
    (position) => position.type === "defense" || position.type === "runner"
  );

  return (
    <TabPanel value={value} index={index}>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Switch
              checked={playerNamePlatesEnabled}
              onChange={() =>
                setPlayerNamePlatesEnabled(!playerNamePlatesEnabled)
              }
              name="player-name-plates-enabled-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Nameplates"
        />
        <br />
        <FormControl className={classes.formControl}>
          <InputLabel id="nameplate-multiple-label">
            Toggle Nameplates by position
          </InputLabel>
          <Select
            labelId="nameplate-multiple-label"
            multiple
            native
            input={<Input />}
            className={classes.multiSelect}
            onChange={(e) =>
              handleMultiSelectChange(e, setPlayerNamePlatesEnabledPerPlayer)
            }
            value={playerNamePlatesEnabledPerPlayer}
            name="player-name-plates-enabled-per-player"
            color="secondary"
          >
            {trackablePlayers.map((position) => (
              <option key={position.id} value={position.id}>
                {position.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <br />
        <div className={classes.sliderRoot}>
          Nameplate Scale
          <Slider
            min={10}
            max={30}
            value={playerNamePlateScale}
            aria-labelledby="name-plate-scale"
            valueLabelDisplay="auto"
            track={"inverted"}
            onChange={handleNamePlateScaleChange}
          />
        </div>

        <br />
        <Divider />
        <br />

        <FormControl className={classes.formControl}>
          <InputLabel id="visible-player-multiple-label">
            Visible Figures
          </InputLabel>
          <Select
            labelId="visible-player-multiple-label"
            multiple
            native
            input={<Input />}
            className={classes.multiSelect}
            onChange={(e) => handleMultiSelectChange(e, setVisiblePositionIds)}
            value={visiblePositionIds}
            name="enabled-figures"
            color="secondary"
          >
            {allPositions.map((position) => (
              <option key={position.id} value={position.id}>
                {position.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <br />
        <div>
          <Button
            onClick={() => setVisiblePositionIds(PLAYERS_POSITION_IDS)}
            className={classes.figureButton}
            variant="contained"
          >
            Players Only
          </Button>
          <Button
            onClick={() => setVisiblePositionIds(ALL_POSITION_IDS)}
            className={classes.figureButton}
            variant="contained"
          >
            All
          </Button>
        </div>
        <br />
        <Divider />
        <br />

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                checked={figureModelCastShadow}
                onChange={() =>
                  setFigureModelCastShadow(!figureModelCastShadow)
                }
                name="figure-model-shadows"
                color="secondary"
                size="small"
              />
            }
            label="Shadows"
          />
        </FormControl>

        <br />
        <Divider />
        <br />

        <FormControlLabel
          control={
            <Switch
              checked={jointCleanup}
              onChange={toggleJointCleanup}
              name="joint-cleanup"
              color="secondary"
              size="small"
            />
          }
          label="Joint Cleanup"
        />
        <FormHelperText>
          Linearly interpolate missing joints. Smooth joints with a low pass
          filter
        </FormHelperText>
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={upsample}
              onChange={toggleUpsample}
              name="upsample-60hz"
              color="secondary"
              size="small"
              disabled={!jointCleanup}
            />
          }
          label="Upsample 60Hz"
        />
        <FormHelperText>Upsample data to 60Hz</FormHelperText>
        <br />
        <Divider />

        <FormControl className={classes.formControl}>
          <InputLabel id="captions-multiple-checkbox-label">
            Figure Info
          </InputLabel>
          <Select
            labelId="captions-multiple-checkbox-label"
            id="captions-multiple-checkbox"
            multiple
            value={captionSet}
            onChange={handleCaptionsChange}
            input={<Input />}
            renderValue={(selected) => (selected as string[]).join(",")}
            MenuProps={MenuProps}
          >
            {captionTypes.map((name: any) => (
              <MenuItem key={name} value={name}>
                {/*<Checkbox checked={captionSet.indexOf(name) > -1}/>*/}
                {/*<ListItemText primary={name}/>*/}
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormHelperText>
          Can select multiple items &nbsp; <FpsTurtle />
        </FormHelperText>
      </FormControl>
    </TabPanel>
  );
};
