import {
  Euler,
  MathUtils,
  Matrix4,
  Object3D,
  Quaternion,
  Vector3,
} from "three";

const { radToDeg } = MathUtils;

// from https://threejsfundamentals.org/threejs/lessons/threejs-load-gltf.html

const formatVec3 = (v3: Vector3, precision = 2) => {
  return `${v3.x.toFixed(precision)}, ${v3.y.toFixed(
    precision
  )}, ${v3.z.toFixed(precision)}`;
};

const formatDegreesVec3 = (v3: Vector3, precision = 1) => {
  return `${radToDeg(v3.x).toFixed(precision)}°, ${radToDeg(v3.y).toFixed(
    precision
  )}°, ${radToDeg(v3.z).toFixed(precision)}°`;
};

const formatQuaternion = (q: Quaternion, precision = 3) => {
  return `${q.x.toFixed(precision)}, ${q.y.toFixed(precision)}, ${q.z.toFixed(
    precision
  )}, ${q.w.toFixed(precision)}`;
};

/**
 * Traces pos/qua/scl decomposed from obj.matrix, not the object's convenience properties.
 * @param args
 */
export const traceObjectMatrix = (args: traceObjectArgs) => {
  let {
    obj,
    lines = [],
    isLast = true,
    prefix = "",
    traceTransforms = false,
    traceLengths = false,
    recursive = true,
  } = args;
  const localPrefix = isLast ? "└─" : "├─";

  let translation = new Vector3();
  let quaternion = new Quaternion();
  let scale = new Vector3();
  // TODOHI  translate quaternion to Euler and trace?
  let euler = new Euler().setFromQuaternion(quaternion);
  let rotation = euler.toVector3();

  obj.matrix.decompose(translation, quaternion, scale);

  lines.push(
    `${prefix}${prefix ? localPrefix : ""}${obj.name || "*no-name*"} [${
      obj.type
    }]`
  );

  const dataPrefix = obj.children.length
    ? isLast
      ? "  │ "
      : "│ │ "
    : isLast
    ? "    "
    : "│   ";

  if (traceTransforms) {
    lines.push(`${prefix}${dataPrefix}  pos: ${formatVec3(translation)}`);
    lines.push(`${prefix}${dataPrefix}  rot: ${formatDegreesVec3(rotation)}`);
    lines.push(`${prefix}${dataPrefix}  qua: ${formatQuaternion(quaternion)}`);
    lines.push(`${prefix}${dataPrefix}  scl: ${formatVec3(scale)}`);
  }

  if (traceLengths) {
    lines.push(
      `${prefix}${dataPrefix}  len: ${obj.position.length().toFixed(2)}`
    );
  }

  const newPrefix = prefix + (isLast ? "  " : "│ ");
  const lastNdx = obj.children.length - 1;
  recursive &&
    obj.children.forEach((child: any, ndx: number) => {
      const isLast = ndx === lastNdx;

      traceObjectTransformProperties({
        obj: child,
        lines,
        isLast,
        prefix: newPrefix,
        traceTransforms,
        traceLengths,
      });
    });
  return lines;
};

interface traceObjectArgs {
  obj: any;
  lines?: any[];
  isLast?: boolean;
  prefix?: string;
  traceName?: boolean;
  noPrefix?: boolean;
  traceTransforms?: boolean;
  traceLengths?: boolean;
  recursive?: boolean;
}

/**
 * Traces object convenience properties
 * @param args
 */
export const traceObjectTransformProperties = (args: traceObjectArgs) => {
  let {
    obj,
    lines = [],
    isLast = true,
    prefix = "",
    traceName = true,
    noPrefix = false,
    traceTransforms = false,
    traceLengths = false,
    recursive = true,
  } = args;
  const localPrefix = isLast ? "└─" : "├─";

  let childrenStr =
    obj.children.length === 1
      ? "(1 child)"
      : `(${obj.children.length} children)`;

  traceName &&
    lines.push(
      `${prefix}${prefix ? localPrefix : ""}${obj.name || "*no-name*"} [${
        obj.type
      }] ${childrenStr}`
    );

  let space = noPrefix ? "" : "  ";

  const dataPrefix = noPrefix
    ? ""
    : obj.children.length
    ? isLast
      ? "  │ "
      : "│ │ "
    : isLast
    ? "    "
    : "│   ";

  if (!isMeshy(obj)) {
    if (traceTransforms) {
      if (traceLengths) {
        lines.push(
          `${prefix}${dataPrefix}${space}pos: ${formatVec3(
            obj.position
          )} (len: ${obj.position.length().toFixed(2)})`
        );
      } else {
        lines.push(
          `${prefix}${dataPrefix}${space}pos: ${formatVec3(obj.position)}`
        );
      }
      lines.push(
        `${prefix}${dataPrefix}${space}rot: ${formatDegreesVec3(obj.rotation)}`
      );
      lines.push(
        `${prefix}${dataPrefix}${space}qua: ${formatQuaternion(obj.quaternion)}`
      );
      lines.push(`${prefix}${dataPrefix}${space}scl: ${formatVec3(obj.scale)}`);
    }
  }

  const newPrefix = prefix + (isLast ? "  " : "│ ");
  const lastNdx = obj.children.length - 1;
  recursive &&
    obj.children.forEach((child: any, ndx: number) => {
      const isLast = ndx === lastNdx;

      traceObjectTransformProperties({
        obj: child,
        lines,
        isLast,
        prefix: newPrefix,
        traceTransforms,
        traceLengths,
      });
    });
  return lines;
};

export const traceObjectVerbose = (obj: Object3D) => {
  let lines = traceObjectTransformProperties({
    obj,
    traceName: false,
    noPrefix: true,
    traceTransforms: true,
    recursive: false,
  });
  let target = new Vector3();

  obj.getWorldDirection(target);

  console.log(".name:", obj.name);
  console.log(".matrixAutoUpdate:", obj.matrixAutoUpdate);
  console.log(".matrixWorldNeedsUpdate:", obj.matrixWorldNeedsUpdate);
  console.log(
    "world direction:",
    formatVec3(target),
    " (obj's +Z in world space)"
  );
  obj.up && console.log("up: ", formatVec3(obj.up));
  console.log("object transform properties:");
  console.log(lines.join("\n"));
  traceDecomposedMatrix(obj.matrix, ".matrix decomposed:");
  traceDecomposedMatrix(obj.matrixWorld, ".matrixWorld decomposed:");
};

export const isMesh = (obj: any) => obj.type === "Mesh";

export const isMeshy = (obj: any) =>
  obj.type === "Mesh" || obj.type === "SkinnedMesh";

export const setObjectTreeFrustumCulled = (obj: any, value: boolean) => {
  if (isMeshy(obj)) {
    obj.frustumCulled = value;
  }

  obj.children.forEach((child: any) => {
    setObjectTreeFrustumCulled(child, value);
  });
};

interface traceComponentsArgs {
  translation?: Vector3;
  rotation?: Vector3;
  quaternion?: Quaternion;
  scale?: Vector3;
  traceTransforms?: boolean;
  traceLength?: boolean;
}

export const traceComponents = (args: traceComponentsArgs) => {
  let { translation, rotation, quaternion, scale, traceLength = false } = args;
  let lines = [];

  translation && lines.push(`T: ${formatVec3(translation)}`);
  rotation && lines.push(`R: ${formatDegreesVec3(rotation)}`);
  quaternion && lines.push(`Q: ${formatQuaternion(quaternion)}`);
  scale && lines.push(`S: ${formatVec3(scale)}`);

  if (traceLength && translation) {
    lines.push(`L: ${translation.length().toFixed(2)}`);
  }

  console.log(lines.join("\n"));
};

/**
 * Traces components decomposed from a matrix, not  an objects convenience properties.
 * @param m - Matrix4
 */
export const traceDecomposedMatrix4 = (m: Matrix4) => {
  if (!m) {
    return;
  }

  let translation = new Vector3();
  let quaternion = new Quaternion();
  let euler = new Euler();
  let rotation;
  let scale = new Vector3();

  m.decompose(translation, quaternion, scale);
  euler.setFromQuaternion(quaternion);
  rotation = euler.toVector3();

  traceComponents({ translation, quaternion, rotation, scale });
};

export const traceObjectTree = (obj: any, recursive = true) => {
  let lines = [];

  if (obj) {
    lines = traceObjectTransformProperties({
      obj,
      traceLengths: true, // not really lengths? just magnitudes of positions?
      traceTransforms: true,
      recursive,
    });

    console.log(lines.join("\n"));
  }
};

export const traceModel = (model: any, recursive = true) => {
  console.log("model", model);

  if (model && model.scene) {
    traceObjectTree(model.scene, recursive);
  }
};

export const traceDecomposedMatrix = (matrix: Matrix4, label?: string) => {
  label && console.log(label);
  traceDecomposedMatrix4(matrix);
};

// just verifying my understanding is correct
export const checkMath = () => {
  let m1 = new Matrix4();
  let m2 = new Matrix4();
  let m3 = new Matrix4();
  let v1 = new Vector3(1, 0, 0);
  let v2 = v1.clone();

  m1.setPosition(1, 0, 0);

  m2.makeRotationZ(0.5 * Math.PI);

  m3.multiplyMatrices(m1, m2);
  v2.applyMatrix4(m3);

  console.log("m1");
  traceDecomposedMatrix4(m1);
  console.log("-----------------------------------------");
  console.log("m2");
  traceDecomposedMatrix4(m2);
  console.log("-----------------------------------------");
  console.log("m3 = m1 * m2");
  traceDecomposedMatrix4(m3);
  console.log("-----------------------------------------");
  console.log("v1", v1);
  console.log("v2 = m3 * v1", v2);

  let m4 = new Matrix4();
  let v3 = v1.clone();

  m4.multiplyMatrices(m2, m1); // opposite m3's order
  v3.applyMatrix4(m4);

  console.log("-----------------------------------------");
  console.log("m4 = m2 * m1");
  traceDecomposedMatrix4(m4);
  console.log("-----------------------------------------");
  console.log("v1", v1);
  console.log("v3 = m4 * v1", v3);

  let m5 = m2.clone();

  m5.premultiply(m1); // should be same as m3
  console.log("-----------------------------------------");
  console.log("m5 = m1 * m2 = m3?");
  traceDecomposedMatrix4(m5);
};

const doCheckMath = false;

doCheckMath && checkMath();
