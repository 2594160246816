import { UseStore } from "zustand";
import { createBallStore, BallStore } from "./ballStore";
import {
  createFigurePositionStore,
  FigurePositionStore,
} from "./figurePositionStore";
import { createGlStatsStore, GlStatesStore } from "./glStatsStore";
import { createCameraStore, CameraStore } from "./cameraStore";
import { createVenueStore, VenueStore } from "./venueStore";
import {
  createFigureAppearanceStore,
  FigureAppearanceStore,
  FigureAppearanceStoreDefaults,
} from "./figureAppearanceStore";
import {
  FigureCaptionsStore,
  figureCaptionsStore,
} from "./figureCaptionsStore";
import { createLightingStore, LightingStore } from "./lightingStore";
import {
  createFieldAppearanceStore,
  FieldAppearanceStore,
} from "./fieldApperanceStore";
import { createPlaySummaryStore, PlaySummaryStore } from "./playSummaryStore";
import {
  createZoneProjectionStore,
  ZoneProjectionStore,
} from "./zoneProjectionStore";
import { createSwingStore, SwingStore } from "./swingStore";
import { createSwingFlairStore, SwingFlairStore } from "./swingFlairStore";
import { RenderingStore, createRenderingStore } from "./renderingStore";
import { FigureModelStore, createFigureModelStore } from "./figureModelStore";
import { MarksStore, createMarksStore } from "./marksStore";
import { createStatsApiStore, StatsApiStore } from "./statsApiStore";
import { FlyCamStore, createFlyCamStore } from "./flyCamStore";
import { KnobsStore, createKnobsStore } from "./knobsStore";
import {
  FigureStrobeStore,
  createFigureStrobeStore,
} from "./figureStrobeStore";
import { FigurePoseStore, createFigurePoseStore } from "./figurePoseStore";
import { POVCameraStore, createPOVCameraStore } from "./povCameraStore";
import { EffectsStore, createEffectsStore } from "./effectsStore";
import {
  createTimelineStore,
  TimelineStore,
  TimelineStoreDefaults,
} from "./timelineStore";

export interface RootStore {
  useKnobsStore: UseStore<KnobsStore>;
  useBallStore: UseStore<BallStore>;
  useCameraStore: UseStore<CameraStore>;
  useFigurePositionStore: UseStore<FigurePositionStore>;
  useGlStats: UseStore<GlStatesStore>;
  useVenueStore: UseStore<VenueStore>;
  useFigureAppearanceStore: UseStore<FigureAppearanceStore>;
  useFigureCaptionsStore: UseStore<FigureCaptionsStore>;
  useLightingStore: UseStore<LightingStore>;
  useFieldAppearanceStore: UseStore<FieldAppearanceStore>;
  usePlaySummaryStore: UseStore<PlaySummaryStore>;
  useZoneProjectionStore: UseStore<ZoneProjectionStore>;
  useRenderingStore: UseStore<RenderingStore>;
  useFigureModelStore: UseStore<FigureModelStore>;
  useMarksStore: UseStore<MarksStore>;
  useSwingStore: UseStore<SwingStore>;
  useSwingFlairStore: UseStore<SwingFlairStore>;
  useStatsApiStore: UseStore<StatsApiStore>;
  useFlyCamStore: UseStore<FlyCamStore>;
  useFigureStrobeStore: UseStore<FigureStrobeStore>;
  useFigurePoseStore: UseStore<FigurePoseStore>;
  usePOVCameraStore: UseStore<POVCameraStore>;
  useEffectsStore: UseStore<EffectsStore>;
  useTimelineStore: UseStore<TimelineStore>;
}

export interface StoreDefaults {
  timeline?: TimelineStoreDefaults;
  figureAppearance?: FigureAppearanceStoreDefaults;
}

export const createPlayViewRootStore = (
  defaults?: StoreDefaults
): RootStore => ({
  useKnobsStore: createKnobsStore()[0],
  useBallStore: createBallStore()[0],
  useCameraStore: createCameraStore()[0],
  useFigurePositionStore: createFigurePositionStore()[0],
  useGlStats: createGlStatsStore()[0],
  useVenueStore: createVenueStore()[0],
  useFigureAppearanceStore: createFigureAppearanceStore(
    defaults?.figureAppearance
  )[0],
  useFigureCaptionsStore: figureCaptionsStore()[0],
  useLightingStore: createLightingStore()[0],
  useFieldAppearanceStore: createFieldAppearanceStore()[0],
  usePlaySummaryStore: createPlaySummaryStore()[0],
  useZoneProjectionStore: createZoneProjectionStore()[0],
  useRenderingStore: createRenderingStore()[0],
  useFigureModelStore: createFigureModelStore()[0],
  useMarksStore: createMarksStore()[0],
  useSwingStore: createSwingStore()[0],
  useSwingFlairStore: createSwingFlairStore()[0],
  useStatsApiStore: createStatsApiStore()[0],
  useFlyCamStore: createFlyCamStore()[0],
  useFigureStrobeStore: createFigureStrobeStore()[0],
  useFigurePoseStore: createFigurePoseStore()[0],
  usePOVCameraStore: createPOVCameraStore()[0],
  useEffectsStore: createEffectsStore()[0],
  useTimelineStore: createTimelineStore(defaults?.timeline)[0],
});
