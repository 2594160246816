import React, { useEffect, useMemo, useRef, useState } from "react";
import _isNumber from "lodash/isNumber";
import { PlayView } from "../PlayView";
import { PlayTime } from "../PlayTime";
import { Timebase } from "../../models/timebase";
import { createPlayViewRootStore } from "../../stores";
import { PlayFrameOverlay } from "../PlayFrameOverlay";
import {
  offsetPlayEventTimestamps,
  toOrderedTrackedEvents,
  toTimeRange,
} from "../../util/trackedEvent-util";
import { useTimebaseState } from "../../hooks";
import { OverlayProps } from "../PlayFrameOverlay";
import { useFullscreen } from "react-use";
import styles from "./PlayFrame.module.css";
import { RootStore } from "../../stores";
import { CameraStore } from "../../stores/cameraStore";
// import { PlayFrameStateHandler } from "./PlayFrameStateHandler";
import {
  deserializeMatrixArray,
  usePlayFrameStateHandler,
} from "./usePlayFrameStateHandler";
import { ErrorBoundary } from "../ErrorBoundary";
import { StatsApiNS } from "../../types";

// TODOHI  might need to apply offset upstream to playEvents instead of just to timeRange here?
// TODOHI  is this offset just an anomaly of this play or this game?
// TODOHI  offset may need further tweaking
const playEventToGumboOffset = 0; //2452 // offset from play json's play event start to gumbo-synced HE's start

export const LAYOUT_STACKED = "stacked";
export const LAYOUT_HUD = "hud";

// TODO    later, may switch Overlay to an overlays: any[] prop to handle multiple overlays

export interface PlayFrameState {
  time: number;
  cam: string; // serialized camera matrix; serialization/deserialization handled internally
  namePlates?: string;
}

interface Props {
  rootStore?: RootStore;
  timebase?: Timebase;
  playTracking: StatsApiNS.SkeletalData;
  playData: StatsApiNS.PlayData;
  layout?: "stacked" | "hud";
  fullscreenLayout?: "stacked" | "hud";
  initialView?: string;
  venueId?: number;
  trackingTimeOffset?: number;
  platform?: string; // 'mobile' | undefined
  Overlay?: React.FC<OverlayProps> | null;
  initialState?: PlayFrameState;
  onStateChange?: (state: PlayFrameState) => void;
}

export const PlayFrame: React.FC<Props> = (props) => {
  let {
    rootStore,
    timebase,
    playTracking,
    playData,
    layout = LAYOUT_STACKED,
    fullscreenLayout = LAYOUT_HUD,
    initialView = "hh",
    venueId,
    trackingTimeOffset = 0,
    platform,
    Overlay,
    initialState,
    onStateChange,
  } = props;

  // use the given rootStore, if any, or create one
  rootStore = useMemo(() => rootStore || createPlayViewRootStore(), [
    rootStore,
  ]);

  let { trackedEvents } = playData;
  // apply offset from play-json playEvent timeStamps to gumbo/HE times (temporary?)
  let offsetTrackedEvents = useMemo(() => {
    let orderedTrackedEvents = toOrderedTrackedEvents(trackedEvents);

    return offsetPlayEventTimestamps(
      orderedTrackedEvents,
      playEventToGumboOffset
    );
  }, [trackedEvents]);

  const { useRenderingStore } = rootStore;
  const {
    fullScreen,
    toggleFullScreen,
    parentElementRef,
  } = useRenderingStore();

  let ref = useRef(null);
  useFullscreen(ref, fullScreen, {
    onClose: () => toggleFullScreen(false),
  });
  let currentLayoutRef = useRef(layout);
  let [view, setView] = useState(initialView);

  let timebaseRef = useRef(timebase || new Timebase());

  timebase = timebaseRef.current;

  useEffect(() => {
    currentLayoutRef.current = fullScreen ? fullscreenLayout : layout;
  }, [fullScreen, layout, fullscreenLayout]);

  // TODO    move to a TimebaseContextProvider instead?
  // call useTimebaseState to invoke rerender on timebase changes
  useTimebaseState(timebase);

  // init timebase
  useEffect(() => {
    if (timebase) {
      timebase.timeRange = toTimeRange(offsetTrackedEvents);

      // note: with the timeRange set, setting time to 0 will actually clamp time to the beginning of the range
      timebase.time =
        initialState && _isNumber(initialState?.time) ? initialState?.time : 0;

      timebase.loop = true;
    }
  }, [initialState, offsetTrackedEvents, timebase]);

  // // wait for all data before automatically starting playback
  // useEffect(() => {
  //     let hasAllData = playTracking && playData
  //
  //     if (hasAllData) {
  //         timebase.start()
  //     }
  // }, [playTracking, playData, timebase])

  const { useTimelineStore } = rootStore;
  const { transparent: timelineTransparent } = useTimelineStore();

  let isHudLayout = currentLayoutRef.current === LAYOUT_HUD;
  let gridStyle = styles.grid;
  let playViewContainerStyle = isHudLayout
    ? styles.playViewHud
    : styles.playViewStacked;
  let playTimeContainerStyle =
    isHudLayout && !timelineTransparent
      ? styles.playTimeHud
      : styles.playTimeStacked;

  let useCameraStore = rootStore.useCameraStore;
  let setCameraMatrix = useCameraStore(($: CameraStore) => $.setCameraMatrix);
  let cameraRef = useRef(); // forwarded to PlayView
  let EffectiveOverlay = Overlay
    ? Overlay // use given overlay
    : Overlay === undefined
    ? PlayFrameOverlay // use default overlay
    : null; // no overlay

  let handleViewChange = (view: string) => {
    setView(view);

    if (cameraRef.current !== undefined) {
      // @ts-ignore
      let matrixArray = cameraRef.current.matrix
        .toArray()
        .map((n: number) => +n.toFixed(2));
      // console.log('---------- handleViewChange - matrixArray', matrixArray)
      setCameraMatrix(matrixArray);
    }
  };

  usePlayFrameStateHandler({ timebase, useCameraStore, onStateChange });

  let initialCameraMatrixArray = deserializeMatrixArray(
    initialState?.cam || ""
  );

  return (
    <ErrorBoundary>
      <div ref={ref} id="play-frame-grid" className={gridStyle}>
        <div ref={parentElementRef} className={playViewContainerStyle}>
          <PlayView
            timebase={timebase}
            playTracking={playTracking}
            playData={playData}
            rootStore={rootStore}
            cameraRef={cameraRef}
            view={view}
            venueId={venueId ? venueId : playData.metaData.venue.id}
            initialCameraMatrixArray={initialCameraMatrixArray}
            trackingTimeOffset={trackingTimeOffset}
          />
        </div>

        <div className={playTimeContainerStyle}>
          <PlayTime
            timebase={timebase}
            trackedEvents={offsetTrackedEvents}
            rootStore={rootStore}
          />
        </div>

        {EffectiveOverlay && (
          <EffectiveOverlay
            rootStore={rootStore}
            playData={playData}
            timebase={timebase}
            onViewChange={handleViewChange}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};
