import React, { useMemo } from "react";
import * as THREE from "three";
import { skeletons } from "../../constants/skeleton";
import { ArrayPoint, lerpArrayPointsToCoordinatePoint } from "../../util/geometry-util";

const skeleton = skeletons.boxTorsoSkeleton3;

/**
 * Returns the point between p and q, if any, stabbed (intersected) by the given z plane.
 * Returns null when z is above or below both.
 * @param z
 * @param p
 * @param q
 */
const zStabPoint = ({
  z,
  p,
  q,
}: {
  p: ArrayPoint;
  q: ArrayPoint;
  z: number;
}) => {
  let pZ = p[2];
  let qZ = q[2];
  let n = (z - pZ) / (qZ - pZ);

  return 0 <= n && n <= 1 ? lerpArrayPointsToCoordinatePoint(p, q, n) : null;
};

const stabColor = "#f00";
const stabMaterial = (
  <meshStandardMaterial attach="material" color={stabColor} />
);

const planeColor = "#000";
const planeOpacity = 0.2;
const planeMaterial = (
  <meshStandardMaterial
    attach="material"
    color={planeColor}
    side={THREE.DoubleSide}
    transparent={true}
    opacity={planeOpacity}
  />
);

interface Props {
  playData: any;
  figure: any;
}

export const ZoneProjectionGroup: React.FC<Props> = ({ playData, figure }) => {
  let zone = useMemo(() => {
    let pitchData = playData?.metaData?.stat?.play?.pitchData;

    return pitchData
      ? {
          top: pitchData.strikeZoneTop,
          bottom: pitchData.strikeZoneBottom,
        }
      : { top: 4.5, bottom: 2.5 };
  }, [playData]);

  if (!figure) {
    return null;
  }

  let { jointsMap } = figure;
  let jointPairs = Object.values(skeleton);
  // @ts-ignore
  let projections: any[] = [];
  let key = 1;
  // let radius = .15
  let boxSize: [number, number, number] = [0.5, 0.5, 0.01];

  jointPairs.forEach((pair: any[]) => {
    let pJointName = pair[0];
    let qJointName = pair[1];
    let p = jointsMap[pJointName];
    let q = jointsMap[qJointName];

    if (p && q) {
      let topPoint = zStabPoint({ z: zone.top, p, q });
      let bottomPoint = zStabPoint({ z: zone.bottom, p, q });

      if (topPoint) {
        // @ts-ignore
        let position: number[] = [topPoint.x, topPoint.y, topPoint.z];

        projections.push(
          <mesh key={key++} position={position as [number, number, number]}>
            <boxBufferGeometry attach="geometry" args={boxSize} />
            {stabMaterial}
          </mesh>
        );
      }

      if (bottomPoint) {
        // @ts-ignore
        let position: number[] = [bottomPoint.x, bottomPoint.y, bottomPoint.z];

        projections.push(
          <mesh key={key++} position={position as [number, number, number]}>
            <boxBufferGeometry attach="geometry" args={boxSize} />
            {stabMaterial}
          </mesh>
        );
      }
    }
  });

  let planeY2 = (0.5 * 17) / 12;
  let planeDimensions = [10, 6];

  return projections.length ? (
    <group>
      {projections}
      <mesh name="sz-top" position={[0, planeY2, zone.top]}>
        <planeBufferGeometry
          attach="geometry"
          args={planeDimensions as [number, number]}
        />
        {planeMaterial}
      </mesh>
      <mesh name="sz-top" position={[0, planeY2, zone.bottom]}>
        <planeBufferGeometry
          attach="geometry"
          args={planeDimensions as [number, number]}
        />
        {planeMaterial}
      </mesh>
    </group>
  ) : null;
};
