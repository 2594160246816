import React, { useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Switch from "@material-ui/core/Switch";
import { TabPanel } from "./Knobs";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import { venues } from "field-of-things/src/constants/venues";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "block",
    paddingBottom: 15,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  value: any;
  index: number;
  useVenueStore: any;
}

export const FieldKnobsPanel = (props: Props) => {
  let { index, value, useVenueStore } = props;
  let classes = useStyles();

  const {
    selectedVenueId,
    setSelectedVenueId,
    playDataVenueId,
    useNexusModel,
    toggleUseNexusModel,
    gltfDownloadedPercentage,
    setVenueModelUrl,
    showLidarReference,
    toggleShowLidarReference,
    referencePolesEnabled,
    setReferencePolesEnabled,
  } = useVenueStore();

  const fileRef = useRef<HTMLInputElement>(null);

  return (
    <TabPanel value={value} index={index}>
      <FormControl component="fieldset">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="venue-select">Venue Model</InputLabel>
          <NativeSelect
            // disabled={!playerPositionVisible}
            value={selectedVenueId}
            onChange={(e) => setSelectedVenueId(parseInt(e.target.value))}
            inputProps={{
              name: "venue-select",
              id: "venue-select",
            }}
          >
            <option value={playDataVenueId}>Auto (game venue)</option>
            {venues.map((v: any, i: number) => (
              <option key={i} value={v.venueId}>
                {v.abbrev}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </FormControl>
      <FormControl className={classes.formControl}>
        <FormControlLabel
          control={
            <Switch
              checked={useNexusModel}
              onChange={toggleUseNexusModel}
              name="selected-figures-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Use Nexus model"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <input
          ref={fileRef}
          type="file"
          onInput={() => {
            if (fileRef.current && fileRef.current.files) {
              const file = fileRef.current.files[0];
              const url = file ? URL.createObjectURL(file) : "";
              setVenueModelUrl(url);
              if (!useNexusModel) {
                toggleUseNexusModel();
              }
            }
          }}
          style={{ height: 20 }}
        />
        <FormHelperText>
          Can also drop .glb files onto the <i>Choose File</i> button
        </FormHelperText>
      </FormControl>
      {gltfDownloadedPercentage !== undefined ? (
        <LinearProgress
          variant="determinate"
          value={gltfDownloadedPercentage * 100}
        />
      ) : null}
      <FormControl className={classes.formControl}>
        <FormControlLabel
          control={
            <Switch
              checked={showLidarReference}
              onChange={toggleShowLidarReference}
              name="reference-lidar-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Show LIDAR reference"
        />
      </FormControl>

      <FormControl className={classes.formControl}>
        <FormControlLabel
          control={
            <Switch
              checked={referencePolesEnabled}
              onChange={() => setReferencePolesEnabled(!referencePolesEnabled)}
              name="reference-poles-toggle"
              color="secondary"
              size="small"
            />
          }
          label="Reference Poles"
        />
        <FormHelperText>
          Foul pole positions and heights from LIDAR
        </FormHelperText>
      </FormControl>
    </TabPanel>
  );
};
