import React from "react";
// import Button from '@material-ui/core/Button'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Slider from "@material-ui/core/Slider";
import { TabPanel } from "./Knobs";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { SwingFlairKnobs } from "./SwingFlairKnobs";
import { LabIcon } from "../icons/LabIcon";

const useStyles = makeStyles({
  sliderRoot: {
    width: 200,
  },
});

interface Props {
  value: any;
  index: number;
  useBallStore?: any;
  useSwingStore: any;
  useSwingFlairStore: any;
}

export const SwingKnobsPanel = (props: Props) => {
  let { index, value, useSwingStore, useSwingFlairStore } = props;
  let classes = useStyles();

  let capSwingEnabled = useSwingStore(($: any) => $.capSwingEnabled);
  let setCapSwingEnabled = useSwingStore(($: any) => $.setCapSwingEnabled);
  let wristSeparationSwingEndEnabled = useSwingStore(
    ($: any) => $.wristSeparationSwingEndEnabled
  );
  let setWristSeparationSwingEndEnabled = useSwingStore(
    ($: any) => $.setWristSeparationSwingEndEnabled
  );

  let wristsVisible = useSwingStore(($: any) => $.wristsVisible);
  let setWristsVisible = useSwingStore(($: any) => $.setWristsVisible);
  let wristStringsVisible = useSwingStore(($: any) => $.wristStringsVisible);
  let setWristStringsVisible = useSwingStore(
    ($: any) => $.setWristStringsVisible
  );
  let wristStrobesVisible = useSwingStore(($: any) => $.wristStrobesVisible);
  let setWristStrobesVisible = useSwingStore(
    ($: any) => $.setWristStrobesVisible
  );

  let batTipsVisible = useSwingStore(($: any) => $.batTipsVisible);
  let setBatTipsVisible = useSwingStore(($: any) => $.setBatTipsVisible);
  let batTipStringsVisible = useSwingStore(($: any) => $.batTipStringsVisible);
  let setBatTipStringsVisible = useSwingStore(
    ($: any) => $.setBatTipStringsVisible
  );
  let batStrobeVisible = useSwingStore(($: any) => $.batStrobeVisible);
  let setBatStrobeVisible = useSwingStore(($: any) => $.setBatStrobeVisible);

  let batFanVisible = useSwingStore(($: any) => $.batFanVisible);
  let setBatFanVisible = useSwingStore(($: any) => $.setBatFanVisible);

  let swingTrackEnabled = useSwingStore(($: any) => $.swingTrackEnabled);
  let setSwingTrackEnabled = useSwingStore(($: any) => $.setSwingTrackEnabled);
  let batAnimationEnabled = useSwingStore(($: any) => $.batAnimationEnabled);
  let setBatAnimationEnabled = useSwingStore(
    ($: any) => $.setBatAnimationEnabled
  );

  let gripsEnabled = useSwingStore(($: any) => $.gripsEnabled);
  let setGripsEnabled = useSwingStore(($: any) => $.setGripsEnabled);
  let wristGripExtension = useSwingStore(($: any) => $.wristGripExtension);
  let setWristGripExtension = useSwingStore(
    ($: any) => $.setWristGripExtension
  );

  let wristGripExtensionInches = wristGripExtension * 12;
  let formattedWristGripExtensionInches = wristGripExtensionInches.toFixed(1);

  let gripsVisible = useSwingStore(($: any) => $.gripsVisible);
  let setGripsVisible = useSwingStore(($: any) => $.setGripsVisible);

  let gripStringsVisible = useSwingStore(($: any) => $.gripStringsVisible);
  let setGripStringsVisible = useSwingStore(
    ($: any) => $.setGripStringsVisible
  );

  let gripStrobesVisible = useSwingStore(($: any) => $.gripStrobesVisible);
  let setGripStrobesVisible = useSwingStore(
    ($: any) => $.setGripStrobesVisible
  );

  // let batLength = useSwingStore(($: any) => $.batLength);
  // let setBatLength = useSwingStore(($: any) => $.setBatLength);
  // let chokeLength = useSwingStore(($: any) => $.chokeLength);
  // let setChokeLength = useSwingStore(($: any) => $.setChokeLength);

  return (
    <TabPanel value={value} index={index}>
      <h4>
        <LabIcon />
        &nbsp;Work in progress
      </h4>

      <FormControlLabel
        control={
          <Switch
            checked={batAnimationEnabled}
            // disabled={!swingTrackEnabled}
            onChange={() => setBatAnimationEnabled(!batAnimationEnabled)}
            name="animated-bat"
            size="small"
          />
        }
        label="Animated Bat"
      />
      {/*<FormHelperText>---</FormHelperText>*/}

      <br />
      <br />
      <Divider />
      <br />

      <FormControlLabel
        control={
          <Switch
            checked={swingTrackEnabled}
            onChange={() => setSwingTrackEnabled(!swingTrackEnabled)}
            name="swing-track-enabled"
            size="small"
          />
        }
        label="Swing Viz"
      />
      <FormHelperText>Swing Track & Bat</FormHelperText>

      <br />
      <Divider />
      <br />

      <FormControlLabel
        control={
          <Switch
            disabled={!swingTrackEnabled}
            checked={wristSeparationSwingEndEnabled}
            onChange={() =>
              setWristSeparationSwingEndEnabled(!wristSeparationSwingEndEnabled)
            }
            name="wrist-sep-swing-end-enabled"
            size="small"
          />
        }
        label="End Swing at Wrist-Separation"
      />
      <FormHelperText>
        End swing when wrists separate at least 2'
      </FormHelperText>
      {/*<br />*/}
      <FormControlLabel
        control={
          <Switch
            disabled={!swingTrackEnabled}
            checked={capSwingEnabled}
            onChange={() => setCapSwingEnabled(!capSwingEnabled)}
            name="cap-swing-enabled"
            size="small"
          />
        }
        label="Cap Swing End"
      />
      <FormHelperText>Max Swing End: .5 sec after pitch end</FormHelperText>

      <br />
      <Divider />
      <br />

      <FormControlLabel
        control={
          <Switch
            checked={wristsVisible}
            disabled={!swingTrackEnabled}
            onChange={() => setWristsVisible(!wristsVisible)}
            name="wrist-points"
            size="small"
          />
        }
        label="Wrist Positions"
      />

      <br />

      <FormControlLabel
        control={
          <Switch
            checked={wristStringsVisible}
            disabled={!swingTrackEnabled}
            onChange={() => setWristStringsVisible(!wristStringsVisible)}
            name="wrist-strings"
            size="small"
          />
        }
        label="Wrist Strings"
      />

      <br />

      <FormControlLabel
        control={
          <Switch
            checked={wristStrobesVisible}
            disabled={!swingTrackEnabled}
            onChange={() => setWristStrobesVisible(!wristStrobesVisible)}
            name="wrist-strobes"
            size="small"
          />
        }
        label="Strobed Wrist Pairs"
      />

      <br />
      <br />
      <Divider />
      <br />

      <FormControlLabel
        control={
          <Switch
            checked={gripsEnabled}
            disabled={!swingTrackEnabled}
            onChange={() => setGripsEnabled(!gripsEnabled)}
            name="grips-enabled"
            size="small"
          />
        }
        label="Extrapolate Grips"
      />

      <br />
      <br />

      <FormControl component="fieldset">
        <div className={classes.sliderRoot}>
          {`Grip Extension from Wrist: ${formattedWristGripExtensionInches}"`}
          <Slider
            disabled={!swingTrackEnabled || !gripsEnabled}
            min={0}
            max={4}
            value={+formattedWristGripExtensionInches}
            aria-labelledby="wrist-grip-extension"
            step={0.1}
            valueLabelDisplay="auto"
            // marks={marks}
            onChange={(e, value) => setWristGripExtension(+value / 12)}
          />
          <FormHelperText>Extrapolation, in inches</FormHelperText>
        </div>
      </FormControl>

      <br />
      <br />

      <FormControlLabel
        control={
          <Switch
            checked={gripsVisible}
            disabled={!swingTrackEnabled || !gripsEnabled}
            onChange={() => setGripsVisible(!gripsVisible)}
            name="grip-positions"
            size="small"
          />
        }
        label="Grip Positions"
      />

      <br />

      <FormControlLabel
        control={
          <Switch
            checked={gripStringsVisible}
            disabled={!swingTrackEnabled || !gripsEnabled}
            onChange={() => setGripStringsVisible(!gripStringsVisible)}
            name="grip-strings"
            size="small"
          />
        }
        label="Grip Strings"
      />

      <br />

      <FormControlLabel
        control={
          <Switch
            checked={gripStrobesVisible}
            disabled={!swingTrackEnabled || !gripsEnabled}
            onChange={() => setGripStrobesVisible(!gripStrobesVisible)}
            name="grip-strobes"
            size="small"
          />
        }
        label="Strobed Grip Pairs"
      />

      <br />
      <br />
      <Divider />
      <br />

      <FormControlLabel
        control={
          <Switch
            checked={batTipsVisible}
            disabled={!swingTrackEnabled}
            onChange={() => setBatTipsVisible(!batTipsVisible)}
            name="bat-tips"
            size="small"
          />
        }
        label="Bat Tip Positions"
      />

      <br />

      <FormControlLabel
        control={
          <Switch
            checked={batTipStringsVisible}
            disabled={!swingTrackEnabled}
            onChange={() => setBatTipStringsVisible(!batTipStringsVisible)}
            name="bat-tip-strings"
            size="small"
          />
        }
        label="Bat Tip Strings"
      />

      <br />

      <FormControlLabel
        control={
          <Switch
            checked={batStrobeVisible}
            disabled={!swingTrackEnabled}
            onChange={() => setBatStrobeVisible(!batStrobeVisible)}
            name="animated-bat"
            size="small"
          />
        }
        label="Strobed Bat"
      />

      <br />
      <br />

      <FormControlLabel
        control={
          <Switch
            checked={batFanVisible}
            disabled={!swingTrackEnabled}
            onChange={() => setBatFanVisible(!batFanVisible)}
            name="bat-fan"
            size="small"
          />
        }
        label="Bat Fan"
      />

      <br />
      <br />
      <Divider />

      <h2>Swing Trails</h2>

      <SwingFlairKnobs useSwingFlairStore={useSwingFlairStore} />
    </TabPanel>
  );
};
