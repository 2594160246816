import { Vector3 } from "three";
import _minBy from "lodash/minBy";
import { XYZT, XYZTSeries } from "./flycam-util";

const _vMound = new Vector3(0, 60.5, 1);
const _vBall = new Vector3();
const _vSightLine = new Vector3();

interface toRestTimeArgs {
  hitSeries: XYZTSeries;
  restDuration: number;
}

export const toRestTime = ({ hitSeries, restDuration }: toRestTimeArgs) => {
  let t = Math.max(0, hitSeries[hitSeries.length - 1].t - restDuration);

  // ball position closest to rest time
  // @ts-ignore
  let pBall: XYZT = _minBy(hitSeries, (d: XYZT) => Math.abs(d.t - t));

  return {
    t: pBall?.t || t,
    pBall,
  };
};

interface toCameraRestPosArgs {
  hitSeries: XYZTSeries;
  restDuration: number;
  restCameraDistance: number;
  restPos?: Vector3;
}

export const toCameraRestPos = ({
  hitSeries,
  restDuration,
  restCameraDistance,
  restPos = new Vector3(),
}: toCameraRestPosArgs) => {
  let { t, pBall } = toRestTime({ hitSeries, restDuration });

  _vBall.set(pBall.x, pBall.y, pBall.z);
  _vSightLine.subVectors(_vBall, _vMound).normalize();
  restPos.copy(_vBall).addScaledVector(_vSightLine, restCameraDistance);

  return {
    t,
    p: restPos,
    ballPos: pBall,
  };
};
