import React, { useEffect, useRef, useState } from "react";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { disposeObject3D } from "../../util/three-util";

const url = "/models/rbi-ball.glb";

const loader = new GLTFLoader();

const ballRadius = (0.5 * 2.9) / 12; // 2.9" diameter

interface Props {
  visible?: boolean;

  [key: string]: any;
}

export const RBIBall = ({ visible = true, ...otherProps }: Props) => {
  let modelRef = useRef<GLTF | null>();
  let [model, setModel] = useState<GLTF>();

  useEffect(() => {
    loader.load(url, (model) => {
      setModel(model);
      modelRef.current = model;
    });
    return () => {
      // explicitly release the model's resources
      disposeObject3D(modelRef.current?.scene);
      modelRef.current = null;
    };
  }, []);

  let { radius = ballRadius, ...primitiveProps } = otherProps;
  let scale = radius / ballRadius;

  model?.scene.scale.set(scale, scale, scale);

  return model ? (
    <primitive object={model.scene} visible={visible} {...primitiveProps} />
  ) : null;
};
