import React, { useMemo } from "react";
import { JointTimeline } from "./JointTimeline";
import styles from "./JointTimelines.module.css";
import _ from "lodash";
import { StatsApiNS } from "field-of-things/src/types";

interface Props {
  playTracking: StatsApiNS.SkeletalData;
  positionId: number;
}

const toFigureFrameSeries = (
  playTracking: StatsApiNS.SkeletalData,
  positionId: number
): StatsApiNS.FigureFrameTimeSeries[] => {
  return playTracking.skeletalData.frames.map(
    (frame: StatsApiNS.SkeletalFrame) => {
      const { positions, timeStamp } = frame;
      const figure = _.find(positions, (p) => p.positionId === positionId);

      return {
        figure,
        timeStamp,
      };
    }
  );
};

export const JointTimelines = ({ playTracking, positionId }: Props) => {
  const timelines = useMemo(() => {
    if (!playTracking) {
      return null;
    }
    const jointsPerFrameForPositionId = toFigureFrameSeries(
      playTracking,
      positionId
    );
    return (
      <JointTimeline
        data={jointsPerFrameForPositionId}
        skeleton={playTracking.skeletalData.jointMetaData}
      />
    );
  }, [playTracking, positionId]);
  return <div className={styles.jointTimelineContainer}>{timelines}</div>;
};
