import _compact from "lodash/compact";
import _cloneDeep from "lodash/cloneDeep";
import _findIndex from "lodash/findIndex";
import _flatMap from "lodash/flatMap";
import _indexOf from "lodash/indexOf";
import _last from "lodash/last";
import _map from "lodash/map";
import _min from "lodash/min";
import _orderBy from "lodash/orderBy";
import _uniq from "lodash/uniq";
import { arrayPointsDistance } from "./geometry-util";
import { StatsApiNS } from "field-of-things/src/types";

interface timeToFrameProps {
  time: number;
  playTracking: any;
}

const traceTimes = false;

// TODO    use timeToSample instead? incorporate timeToFrame's clamping in timeToSample?
export const timeToFrame = ({ time, playTracking }: timeToFrameProps) => {
  if (!playTracking) {
    return {};
  }

  traceTimes && console.log("---------- timeToFrame() ----------");
  traceTimes && console.log("-----              time", time);

  let { frames } = playTracking.skeletalData;

  if (!frames || frames.length === 0) {
    return null;
  }

  let firstFrame = frames[0];
  let lastFrame = frames[frames.length - 1];
  let duration = lastFrame.time - firstFrame.time;

  traceTimes && console.log("-----  first frame time", firstFrame.time);
  traceTimes && console.log("-----   last frame time", lastFrame.time);
  traceTimes && console.log("-----          duration", duration);
  traceTimes && console.log("-----     relative time", time - firstFrame.time);

  if (time <= firstFrame.time) {
    return firstFrame;
  }
  if (time >= lastFrame.time) {
    return lastFrame;
  }

  let i = _findIndex(frames, (frame: any) => frame.time >= time);
  let rightFrame = frames[i];
  let rightDelta = rightFrame.time - time;
  let foundFrame = rightFrame;

  traceTimes && console.log("-----             index", i);

  foundFrame.num = i;

  if (i > 0) {
    let leftFrame = frames[i - 1];
    let leftDelta = time - leftFrame.time;

    traceTimes && console.log("----- left/right frames", leftFrame, rightFrame);

    if (leftDelta <= rightDelta) {
      foundFrame = leftFrame;
      foundFrame.num = i - 1;
    } else {
      foundFrame = rightFrame;
    }
  }

  traceTimes && console.log("---------------------------------");

  // i++
  //
  // // console.log('trackingFrames.length', trackingFrames.length, 'i', i)
  //
  // // let f = i++ % 600
  // let f = Math.floor((i++ / 6)) % 600
  //
  // return trackingFrames[f]
  // // return trackingFrames[0]

  return foundFrame;
};

const defaultEpsilon = 66; //33

interface timeToSampleProps {
  time: number;
  series: any[];
  epsilon?: number;
}

export const timeToSample = ({
  time,
  series,
  epsilon = defaultEpsilon,
}: timeToSampleProps) => {
  let times = series.map((p: any) => p.time); // ms
  let absDeltas = times.map((t: number) => Math.abs(t - time));
  let minDelta = _min(absDeltas);

  if (minDelta && minDelta <= epsilon) {
    let index = _indexOf(absDeltas, minDelta);

    return series[index];
  }
};

/**
 * Maps {x, y, z?, timeStamp} to {x, y, z?, timeStamp, time}
 * @param positions - array of {x, y, z?, timeStamp}
 */
export const toMPDTimeSeries = (positions: StatsApiNS.Position[]) =>
  positions.map((p) => ({
    ...p,
    time: new Date(`${p.timeStamp}Z`).getTime(),
  }));

export const toTrackIds = (playTracking: any) =>
  _uniq(
    _flatMap(playTracking.trackingFrames, (frame: any) =>
      _map(frame.players, "trackId")
    )
  );

/**
 * Generates a time series of z-minima, points where z <= prev z and next z
 * @param frames – array of {p, ...}
 */
const toZMinima = (frames: any[]) => {
  let lastIndex = frames.length - 1;

  return frames.filter((frame, i) => {
    let z = frame.p[2];
    let prevZ = i > 0 ? frames[i - 1].p[2] : Number.POSITIVE_INFINITY;
    let nextZ = i < lastIndex ? frames[i + 1].p[2] : Number.POSITIVE_INFINITY;

    return prevZ >= z && z <= nextZ;
  });
};

/**
 * Simple IIR filer for smoothing of noisy data
 * @param prevEst previous state estimation
 * @param value measured value
 * @param alpha filter coefficient
 */
export const alphaFilter = (prevEst: number, value: number, alpha: number) => {
  return prevEst + alpha * (value - prevEst);
};

/**
 * Creates a time  series for the given joint, skipping frames where the joint is not present.
 * @param frames
 * @param jointName
 * @returns [] array of timestamped points
 */
const toJointTracking = (frames: any[], jointName: string) => {
  return _compact(
    _map(frames, (frame) => {
      let { jointsMap, time } = frame;
      let joint = jointsMap[jointName];

      return joint && { joint: jointName, time, p: [...joint] };
    })
  );
};

// TODOHI  implement
const toFramesMean = (frames: any[]) => {
  return frames[0]; // for now
};

const temporalDistance = (frame1: any, frame2: any) =>
  Math.abs(frame2.time - frame1.time);

const spatialDistance = (frame1: any, frame2: any) =>
  arrayPointsDistance(frame1.p, frame2.p);

interface mergeArgs {
  frames: any[];
  distanceFunc: (p: any, q: any) => any;
  epsilon: number;
}

const merge = ({ frames, distanceFunc, epsilon }: mergeArgs) => {
  let merged: any[] = [];
  let groups: any[] = [];

  frames.forEach((frame, i) => {
    let prevFrame = i === 0 ? null : frames[i - 1];
    let delta = prevFrame ? distanceFunc(frame, prevFrame) : Infinity;

    if (delta >= epsilon) {
      groups.push([frame]);
    } else {
      _last(groups).push(frame);
    }
  });

  for (let group of groups) {
    merged.push(toFramesMean(group));
  }

  return merged;
};

interface mergeFramesArgs {
  frames: any[];
  timeEpsilon?: number;
  distEpsilon?: number;
}

// TODOHI  compare time AND distance together for each pair of frames, rather than in separate passes?
const mergeFrames = ({
  frames,
  timeEpsilon = 300,
  distEpsilon = 0.5,
}: mergeFramesArgs) => {
  let merged = _cloneDeep(frames);

  // merge by time
  merged = merge({
    frames: merged,
    distanceFunc: temporalDistance,
    epsilon: timeEpsilon,
  });

  // merge by position
  merged = merge({
    frames: merged,
    distanceFunc: spatialDistance,
    epsilon: distEpsilon,
  });

  return merged;
};

const filterZ = (frames: any[], zThreshold = 1) =>
  _cloneDeep(frames).filter((point) => point.p[2] <= zThreshold);

/**
 * Generates a time series of left and right steps from an array of figure tracking frames
 * @param frames – array of {time, joints}
 */
export const toSteps = (frames: any) => {
  // pull LAnkle and RAnkle time series
  let leftAnkleFrames = toJointTracking(frames, "LAnkle");
  let rightAnkleFrames = toJointTracking(frames, "RAnkle");

  // filter out high local minima
  leftAnkleFrames = filterZ(leftAnkleFrames);
  rightAnkleFrames = filterZ(rightAnkleFrames);

  // generate left and right step time series
  let leftSteps = toZMinima(leftAnkleFrames);
  let rightSteps = toZMinima(rightAnkleFrames);

  // merge temporally and spatially close steps
  leftSteps = mergeFrames({ frames: leftSteps });
  rightSteps = mergeFrames({ frames: rightSteps });

  // merge left and right, ordered by time
  return _orderBy(leftSteps.concat(rightSteps), "time");
};

interface Args {
  playTracking: any;
  positionIds?: number[];
}

/**
 * Maps playTracking to a set of figures, each with their own tracking time series
 * @param playTracking - tracking data
 * @param positionIds – optional pass list of positionIds
 */
export const toFiguresTracking = ({ playTracking, positionIds }: Args) => {
  let { frames } = playTracking.skeletalData;
  let figuresTrackingMap: { [index: string]: any } = {};

  for (let frame of frames) {
    let { time } = frame;

    for (let position of frame.positions) {
      let { joints, jointsMap, location, positionId } = position;
      let figure = figuresTrackingMap[positionId] || { positionId, frames: [] };

      // include if positionId or positionIds not given, or if positionId in positionIds
      // also excludes if positionId === 0 (can be multiple figures with pid 0)
      if (!positionId || !positionIds || positionIds.includes(positionId)) {
        figure.frames.push({ time, joints, jointsMap, location });
        figuresTrackingMap[positionId] = figure;
      }
    }
  }

  let figuresTracking = Object.values(figuresTrackingMap);

  for (let figureTracking of figuresTracking) {
    figureTracking.steps = toSteps(figureTracking.frames);
  }

  return figuresTracking;
};

interface toPositionIdFramesArgs {
  frames: any[];
  positionId: number;
}

const isBatterLocation = (figure: any) => {
  let { location } = figure;

  // not precisely the batter's box bounds, but close enough
  return Math.abs(location.x) <= 5 && Math.abs(location.y) <= 3.5;
};

export const toInferredBatterFrames = ({ frames }: { frames: any[] }) => {
  return _compact(
    frames.map((frame) => {
      let position = frame.positions.find((figure: any) =>
        isBatterLocation(figure)
      );

      return (
        position && {
          time: frame.time,
          timeStamp: frame.timeStamp,
          ...position,
        }
      );
    })
  );
};

/**
 * Returns a series of frames for the figure with the given positionId. This assumes positionId is unique in each frame.
 * @param frames
 * @param positionId
 */
export const toFigureFrames = ({
  frames,
  positionId,
}: toPositionIdFramesArgs) => {
  let figureFrames = _compact(
    frames.map((frame) => {
      let position = frame.positions.find(
        (pos: any) => pos.positionId === positionId
      );

      return (
        position && {
          time: frame.time,
          timeStamp: frame.timeStamp,
          ...position,
        }
      );
    })
  );

  // if compact result is empty and positionId === 10, infer batter
  if (figureFrames.length === 0) {
    if (positionId === 10) {
      return toInferredBatterFrames({ frames });
    }
  }

  return figureFrames;
};

interface toJointSeriesArgs {
  figureFrames: any[];
  jointName: string;
}

/**
 * Maps figureFrames to a time series for the given joint
 * @param figureFrames
 * @param jointName
 */
export const toJointSeries = ({
  figureFrames,
  jointName,
}: toJointSeriesArgs) => {
  return figureFrames.map((frame) => {
    let { jointsMap, time, timeStamp } = frame;
    let pos = jointsMap[jointName];

    return pos
      ? {
          jointName,
          time,
          timeStamp,
          pos,
        }
      : null;
  });
};

///////////////////////////////

// interface toFrameAtTimeArgs {
//   time: number;
//   frames: any;
// }
//
// // TODO    move to tracking-util and refactor timeToFrame
// export const toFrameAtTime = ({ time, frames }: toFrameAtTimeArgs) => {
//   if (!frames) {
//     return {};
//   }
//
//   traceTimes && console.log("---------- timeToFrame() ----------");
//   traceTimes && console.log("-----              time", time);
//
//   // let { frames } = playTracking.skeletalData;
//
//   if (!frames || frames.length === 0) {
//     return null;
//   }
//
//   let firstFrame = frames[0];
//   let lastFrame = frames[frames.length - 1];
//   let duration = lastFrame.time - firstFrame.time;
//
//   traceTimes && console.log("-----  first frame time", firstFrame.time);
//   traceTimes && console.log("-----   last frame time", lastFrame.time);
//   traceTimes && console.log("-----          duration", duration);
//   traceTimes && console.log("-----     relative time", time - firstFrame.time);
//
//   if (time <= firstFrame.time) {
//     return firstFrame;
//   }
//   if (time >= lastFrame.time) {
//     return lastFrame;
//   }
//
//   let i = _findIndex(frames, (frame: any) => frame.time >= time);
//   let rightFrame = frames[i];
//   let rightDelta = rightFrame.time - time;
//   let foundFrame = rightFrame;
//
//   traceTimes && console.log("-----             index", i);
//
//   // foundFrame.num = i;
//
//   if (i > 0) {
//     let leftFrame = frames[i - 1];
//     let leftDelta = time - leftFrame.time;
//
//     traceTimes && console.log("----- left/right frames", leftFrame, rightFrame);
//
//     if (leftDelta <= rightDelta) {
//       foundFrame = leftFrame;
//       // foundFrame.num = i - 1;
//     } else {
//       foundFrame = rightFrame;
//     }
//   }
//
//   traceTimes && console.log("---------------------------------");
//
//   return foundFrame;
// };

interface TimeSample {
  time: number;
  [key: string]: any;
}

export type TimeSeries = TimeSample[];

interface toFrameAtTimeArgs {
  time: number;
  // series: TimeSeries;
  series: any;
}

// TODO    reconcile with timeToSample. possible to drop timeToSample?
export const toSampleAtTime = ({ time, series }: toFrameAtTimeArgs) => {
  if (!series) {
    return {};
  }

  traceTimes && console.log("---------- toSampleAtTime() ----------");
  traceTimes && console.log("-----              time", time);

  if (!series || series.length === 0) {
    return null;
  }

  let firstSample = series[0];
  let lastSample = series[series.length - 1];
  let duration = lastSample.time - firstSample.time;

  traceTimes && console.log("----- first sample time", firstSample.time);
  traceTimes && console.log("-----  last sample time", lastSample.time);
  traceTimes && console.log("-----          duration", duration);
  traceTimes && console.log("-----     relative time", time - firstSample.time);

  if (time <= firstSample.time) {
    return firstSample;
  }
  if (time >= lastSample.time) {
    return lastSample;
  }

  let i = _findIndex(series, (frame: any) => frame.time >= time);
  let rightFrame = series[i];
  let rightDelta = rightFrame.time - time;
  let foundSample = rightFrame;

  traceTimes && console.log("-----             index", i);

  if (i > 0) {
    let leftSample = series[i - 1];
    let leftDelta = time - leftSample.time;

    traceTimes &&
      console.log("----- left/right samples", leftSample, rightFrame);

    if (leftDelta <= rightDelta) {
      foundSample = leftSample;
    } else {
      foundSample = rightFrame;
    }
  }

  traceTimes && console.log("---------------------------------");

  return foundSample;
};
