const HawkEyeSkeleton = [
  ["Neck", "MidHip"],
  ["Neck", "RShoulder"],
  ["Neck", "LShoulder"],
  ["RShoulder", "RElbow"],
  ["RElbow", "RWrist"],
  ["LShoulder", "LElbow"],
  ["LElbow", "LWrist"],
  ["MidHip", "RHip"],
  ["RHip", "RKnee"],
  ["RKnee", "RAnkle"],
  ["MidHip", "LHip"],
  ["LHip", "LKnee"],
  ["LKnee", "LAnkle"],
  ["Neck", "Nose"],
  ["Nose", "REye"],
  ["REye", "REar"],
  ["Nose", "LEye"],
  ["LEye", "LEar"],
  ["RShoulder", "REar"],
  ["LShoulder", "LEar"],
  ["LAnkle", "LBigToe"],
  ["LBigToe", "LSmallToe"],
  ["LAnkle", "LHeel"],
  ["RAnkle", "RBigToe"],
  ["RBigToe", "RSmallToe"],
  ["RAnkle", "RHeel"],
];

const boxTorsoSkeleton = [
  // ['Neck', 'MidHip'],
  ["Neck", "RShoulder"],
  ["Neck", "LShoulder"],
  ["RShoulder", "RElbow"],
  ["RElbow", "RWrist"],
  ["LShoulder", "LElbow"],
  ["LElbow", "LWrist"],
  ["MidHip", "RHip"],
  ["RHip", "RKnee"],
  ["RKnee", "RAnkle"],
  ["MidHip", "LHip"],
  ["LHip", "LKnee"],
  ["LKnee", "LAnkle"],
  // ['REye', 'REar'],
  // ['LEye', 'LEar'],
  ["Neck", "REar"],
  ["Neck", "LEar"],
  ["REar", "LEar"],

  // ['Neck', 'Nose'],
  ["Nose", "REye"],
  ["Nose", "LEye"],
  // ['REye', 'REar'],
  // ['LEye', 'LEar'],

  ["LAnkle", "LBigToe"],
  ["LBigToe", "LSmallToe"],
  ["LAnkle", "LHeel"],
  ["RAnkle", "RBigToe"],
  ["RBigToe", "RSmallToe"],
  ["RAnkle", "RHeel"],

  ["LHip", "LShoulder"],
  ["RHip", "RShoulder"],
];

// based on boxTorsoSkeleton, connects LHip and RHip since MidHip no longer included in tracking
const boxTorsoSkeleton2 = [
  // ['Neck', 'MidHip'],
  ["Neck", "RShoulder"],
  ["Neck", "LShoulder"],
  ["RShoulder", "RElbow"],
  ["RElbow", "RWrist"],
  ["LShoulder", "LElbow"],
  ["LElbow", "LWrist"],
  ["RHip", "RKnee"],
  ["RKnee", "RAnkle"],
  ["LHip", "LKnee"],
  ["LKnee", "LAnkle"],
  // ['REye', 'REar'],
  // ['LEye', 'LEar'],
  ["Neck", "REar"],
  ["Neck", "LEar"],
  ["REar", "LEar"],

  // ['MidHip', 'RHip'],
  // ['MidHip', 'LHip'],
  ["LHip", "RHip"],

  // ['Neck', 'Nose'],
  ["Nose", "REye"],
  ["Nose", "LEye"],
  // ['REye', 'REar'],
  // ['LEye', 'LEar'],

  ["LAnkle", "LBigToe"],
  ["LBigToe", "LSmallToe"],
  ["LAnkle", "LHeel"],
  ["RAnkle", "RBigToe"],
  ["RBigToe", "RSmallToe"],
  ["RAnkle", "RHeel"],

  ["LHip", "LShoulder"],
  ["RHip", "RShoulder"],
];

// based on boxTorsoSkeleton2, skips head parts
const boxTorsoSkeleton3 = [
  // ['Neck', 'MidHip'],
  ["Neck", "RShoulder"],
  ["Neck", "LShoulder"],
  ["RShoulder", "RElbow"],
  ["RElbow", "RWrist"],
  ["LShoulder", "LElbow"],
  ["LElbow", "LWrist"],
  ["RHip", "RKnee"],
  ["RKnee", "RAnkle"],
  ["LHip", "LKnee"],
  ["LKnee", "LAnkle"],

  // // ['REye', 'REar'],
  // // ['LEye', 'LEar'],
  //
  // ['Neck', 'REar'],
  // ['Neck', 'LEar'],
  // ['REar', 'LEar'],
  //
  // // ['Neck', 'Nose'],
  // ['Nose', 'REye'],
  // ['Nose', 'LEye'],
  // // ['REye', 'REar'],
  // // ['LEye', 'LEar'],

  // ['MidHip', 'RHip'],
  // ['MidHip', 'LHip'],
  ["LHip", "RHip"],

  ["LAnkle", "LBigToe"],
  ["LBigToe", "LSmallToe"],
  ["LAnkle", "LHeel"],
  ["RAnkle", "RBigToe"],
  ["RBigToe", "RSmallToe"],
  ["RAnkle", "RHeel"],

  ["LHip", "LShoulder"],
  ["RHip", "RShoulder"],
];

const vTorsoSkeleton = [
  // ['Neck', 'MidHip'],
  ["Neck", "RShoulder"],
  ["Neck", "LShoulder"],
  ["RShoulder", "RElbow"],
  ["RElbow", "RWrist"],
  ["LShoulder", "LElbow"],
  ["LElbow", "LWrist"],
  ["MidHip", "RHip"],
  ["RHip", "RKnee"],
  ["RKnee", "RAnkle"],
  ["MidHip", "LHip"],
  ["LHip", "LKnee"],
  ["LKnee", "LAnkle"],
  // ['REye', 'REar'],
  // ['LEye', 'LEar'],
  ["Neck", "REar"],
  ["Neck", "LEar"],
  ["REar", "LEar"],

  // ['Neck', 'Nose'],
  ["Nose", "REye"],
  ["Nose", "LEye"],
  // ['REye', 'REar'],
  // ['LEye', 'LEar'],

  ["LAnkle", "LBigToe"],
  ["LBigToe", "LSmallToe"],
  ["LAnkle", "LHeel"],
  ["RAnkle", "RBigToe"],
  ["RBigToe", "RSmallToe"],
  ["RAnkle", "RHeel"],

  // ['LHip', 'LShoulder'],
  // ['RHip', 'RShoulder'],

  ["MidHip", "RShoulder"],
  ["MidHip", "LShoulder"],
];

const crossTorsoSkeleton = [
  // ['Neck', 'MidHip'],
  ["Neck", "RShoulder"],
  ["Neck", "LShoulder"],
  ["RShoulder", "RElbow"],
  ["RElbow", "RWrist"],
  ["LShoulder", "LElbow"],
  ["LElbow", "LWrist"],
  ["MidHip", "RHip"],
  ["RHip", "RKnee"],
  ["RKnee", "RAnkle"],
  ["MidHip", "LHip"],
  ["LHip", "LKnee"],
  ["LKnee", "LAnkle"],
  // ['REye', 'REar'],
  // ['LEye', 'LEar'],
  ["Neck", "REar"],
  ["Neck", "LEar"],
  ["REar", "LEar"],

  // ['Neck', 'Nose'],
  ["Nose", "REye"],
  ["Nose", "LEye"],
  // ['REye', 'REar'],
  // ['LEye', 'LEar'],

  ["LAnkle", "LBigToe"],
  ["LBigToe", "LSmallToe"],
  ["LAnkle", "LHeel"],
  ["RAnkle", "RBigToe"],
  ["RBigToe", "RSmallToe"],
  ["RAnkle", "RHeel"],

  ["LHip", "RShoulder"],
  ["RHip", "LShoulder"],
];

export const skeletons = {
  HawkEyeSkeleton,
  boxTorsoSkeleton,
  boxTorsoSkeleton2,
  boxTorsoSkeleton3,
  vTorsoSkeleton,
  crossTorsoSkeleton,
};
