import { useEffect, useRef } from "react";
import RendererStats from "../../lib/threex.renderstats";
import { useFrame } from "@react-three/fiber";

export const GLStats = ({ visible = true }) => {
  let rendererStatsRef = useRef();

  useEffect(() => {
    let rendererStats;

    if (visible) {
      rendererStats = rendererStatsRef.current = new RendererStats();

      rendererStats.domElement.style.position = "absolute";
      // rendererStats.domElement.style.left = '0px'
      // rendererStats.domElement.style.bottom = '0px'
      rendererStats.domElement.style.left = "0px";
      rendererStats.domElement.style.top = "60px";
      rendererStats.domElement.style.width = "100px";
      document.body.appendChild(rendererStats.domElement);
    }
    return () => {
      rendererStats && document.body.removeChild(rendererStats.domElement);
    };
  }, [visible]);
  useFrame(({ gl }) => {
    if (rendererStatsRef.current) {
      rendererStatsRef.current.update(gl);
    }
  });

  return null;
};
