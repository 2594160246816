import React, { useMemo } from "react";
import * as THREE from "three";
// import {randomColor} from '../../util/color'

const defaultMaxCount = 100;
const defaultRadius = 1;
const defaultColor = "#fff";

// scratch object
let _transform = new THREE.Object3D();

let updateMesh = (mesh: any, nodes: any) => {
  mesh.count = nodes.length;
  nodes.forEach((p: any[], i: any) => {
    _transform.position.set(p[0], p[1], p[2]);
    _transform.updateMatrix();
    mesh.setMatrixAt(i, _transform.matrix);
  });
  mesh.instanceMatrix.needsUpdate = true;
  mesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
};

interface Props {
  name: string;
  nodes: any[];
  radius?: number;
  color?: string;
  maxCount?: number;
  opacity?: number;
  castShadow?: boolean;
}

export const InstancedMeshSpheres = (props: Props) => {
  let {
    name = "",
    nodes,
    radius = defaultRadius,
    color = defaultColor,
    maxCount = defaultMaxCount,
    opacity = 1,
    castShadow = true,
  } = props;
  let material = useMemo(
    () => (
      <meshStandardMaterial
        attach="material"
        color={color}
        transparent={opacity !== 1}
        opacity={opacity}
      />
    ),
    [color, opacity]
  );

  return (
    <instancedMesh
      castShadow={castShadow}
      name={name}
      //@ts-ignore
      args={[null, null, maxCount]}
      onUpdate={(mesh) => updateMesh(mesh, nodes)}
    >
      <sphereBufferGeometry attach="geometry" args={[radius, 32, 16]} />
      {/*<meshStandardMaterial attach="material" color={color} />*/}
      {material}
    </instancedMesh>
  );
};
