import React, { useRef, useState } from "react";
import omit from "lodash/omit";
import { useFrame } from "@react-three/fiber";
import { Vector3 } from "three";

const s = 0.2;
const scale = [s, s, s];
const activeScale: Vector3 = new Vector3(
  1.5 * scale[0],
  1.5 * scale[1],
  1.5 * scale[2]
);

const shapeProps = ["_id", "_onClick"];

export function Box(props: any) {
  let {
    _id = null,
    color = "orange",
    size,
    visible = true,
    isHovered = false,
    _onClick,
    _onHover,
  } = props;
  let meshProps = omit(props, shapeProps);
  let myScale: Vector3 = size
    ? new Vector3(size, size, size)
    : new Vector3(scale[0], scale[1], scale[2]);

  // This reference will give us direct access to the mesh
  const mesh = useRef();

  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(isHovered);
  const [active, setActive] = useState(false);

  if (hovered) {
    // console.log('hovered')
  }

  // Rotate mesh every frame, this is outside of React without overhead
  //@ts-ignore
  useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.02));

  let handlePointerOver = (e: any) => {
    e.stopPropagation();
    setHover(true);
    _onHover && _onHover(_id);
  };
  let handlePointerOut = (e: any) => {
    e.stopPropagation();
    setHover(false);
    _onHover && _onHover(null);
  };
  let handleClick = (e: any) => {
    e.stopPropagation();
    setActive(!active);
    _onClick && _onClick(_id);
  };

  return (
    <mesh
      {...meshProps}
      ref={mesh}
      scale={active ? activeScale : myScale}
      // onClick={handleClick}
      onPointerDown={handleClick}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
    >
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial
        attach="material"
        color={hovered || isHovered ? "hotpink" : color}
        visible={visible}
      />
    </mesh>
  );
}
