import React from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { PlayEventList } from "../PlayEventList";
import { Timebase } from "../../models/timebase";
import { ReactComponent as PathIcon } from "./path.svg";
import { ReactComponent as PathLightIcon } from "./path-light.svg";
import { ReactComponent as StepsIcon } from "./steps.svg";
import { ReactComponent as StepsLightIcon } from "./steps-light.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      pointerEvents: "none",
      "& > *": {
        // margin: theme.spacing(1),
        pointerEvents: "all",
      },

      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    marksGroup: {
      marginTop: 2,
      marginBottom: 10,
    },
    button: {
      margin: "2px 1px",
      // backgroundColor: '#666',
    },

    buttonContainedPrimary: {
      backgroundColor: "#5f5449",
      "&:hover": {
        backgroundColor: "#7e7368",
      },
    },

    // // for tooltip hack when wrapping a disabled button, https://github.com/mui-org/material-ui/issues/8416
    // tooltipHelperDiv: {
    //     display: 'inline',
    // }
  })
);

interface Props {
  usePlaySummaryStore: any;
  playData: any;
  timebase: Timebase;
}

export const PlaySummaryView: React.FC<Props> = ({
  usePlaySummaryStore,
  playData,
  timebase,
}) => {
  let classes = useStyles();
  // let usePlaySummaryStore = rootStore.usePlaySummaryStore
  let playSummaryEnabled = usePlaySummaryStore(
    ($: any) => $.playSummaryEnabled
  );
  let setPlaySummaryEnabled = usePlaySummaryStore(
    ($: any) => $.setPlaySummaryEnabled
  );
  let pathsEnabled = usePlaySummaryStore(($: any) => $.pathsEnabled);
  // let setPathsEnabled = usePlaySummaryStore(($: any) => $.setPathsEnabled)
  let togglePathsEnabled = usePlaySummaryStore(
    ($: any) => $.togglePathsEnabled
  );
  let stepsEnabled = usePlaySummaryStore(($: any) => $.stepsEnabled);
  // let setStepsEnabled = usePlaySummaryStore(($: any) => $.setStepsEnabled)
  let toggleStepsEnabled = usePlaySummaryStore(
    ($: any) => $.toggleStepsEnabled
  );

  let handlePlaySummaryButtonClick = () => {
    setPlaySummaryEnabled(!playSummaryEnabled);

    // if (!playSummaryEnabled) { // toggling on
    //     // TODO    drop or keep this automatically enabling paths and steps when neither enabled?
    //     if (!pathsEnabled && !stepsEnabled) {
    //         setPathsEnabled(true)
    //         setStepsEnabled(true)
    //     }
    // }
  };

  let buttonClasses = {
    containedPrimary: classes.buttonContainedPrimary,
  };

  let stepsButton = playSummaryEnabled && (
    <Tooltip className={classes.button} title="Steps" placement="right">
      <Button
        className={classes.button}
        classes={buttonClasses}
        variant="contained"
        size="small"
        onClick={toggleStepsEnabled}
        color={stepsEnabled ? "primary" : "default"}
        // disabled={!playSummaryEnabled}
      >
        {stepsEnabled ? <StepsLightIcon /> : <StepsIcon />}
      </Button>
    </Tooltip>
  );
  let pathsButton = playSummaryEnabled && (
    <Tooltip title="Paths" placement="right">
      <Button
        className={classes.button}
        classes={buttonClasses}
        variant="contained"
        size="small"
        onClick={togglePathsEnabled}
        color={pathsEnabled ? "primary" : "default"}
        // disabled={!playSummaryEnabled}
      >
        {pathsEnabled ? <PathLightIcon /> : <PathIcon />}
      </Button>
    </Tooltip>
  );

  let playEventList = <PlayEventList playData={playData} timebase={timebase} />;

  return (
    <div className={classes.root}>
      <Tooltip title="Play Summary" placement="right">
        <Button
          variant="contained"
          size="small"
          onClick={handlePlaySummaryButtonClick}
          color={playSummaryEnabled ? "primary" : "default"}
        >
          PS
        </Button>
      </Tooltip>

      <div className={classes.marksGroup}>
        {stepsButton}
        <br />
        {pathsButton}
      </div>

      {playSummaryEnabled && playEventList}
    </div>
  );
};
