import React from "react";
import { TexMapBall } from "./TexMapBall";
import { RBIBall } from "./RBIBall";
import { NexusBall } from "./NexusBall";
import { BallType } from "../../stores/ballStore";

interface Props {
  type?: BallType;

  [key: string]: any;
}

const defaultType = "texmap";
// const defaultType = "rbi";
// const defaultType = "nexus";

export const Ball = ({ type = defaultType, ...otherProps }: Props) => {
  switch (type) {
    case "rbi":
      return <RBIBall {...otherProps} />;
    case "nexus":
      return <NexusBall {...otherProps} />;
    case "texmap":
    default:
      return <TexMapBall {...otherProps} />;
  }
};
