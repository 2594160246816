import React from "react";
import { Color, Vector3 } from "three";
import { Sphere } from "../Figure/HeadJoints";
import { RayCylinder } from "../Figure/StickHead";
import { ArrayPoint3 } from "../../util/geometry-util";

const toMaterial = (color: string) => (
  <meshStandardMaterial
    attach="material"
    color={new Color(color)}
    // emissive={new Color(color)}
    roughness={1}
    metalness={0}
  />
);

interface VectorObjectProps {
  p: Vector3;
  q: Vector3;
  material: any;
  size?: number;
}

const VectorObject = ({ p, q, material, size = 1 }: VectorObjectProps) => {
  return (
    <group>
      <RayCylinder
        p={p.toArray() as ArrayPoint3}
        q={q.toArray() as ArrayPoint3}
        radius={size * 0.02}
        len={p.distanceTo(q)}
        material={material}
      />
      <Sphere
        position={q.toArray() as ArrayPoint3}
        radius={size * 0.05}
        material={material}
      />
    </group>
  );
};

// const mat0_black = toMaterial("#000");
const mat1_red = toMaterial("#f00");
const mat2_green = toMaterial("#024f00");
const mat3_blue = toMaterial("#0055ff");
// const mat4_orange = toMaterial("#ff6600");
const mat5_cyan = toMaterial("#0ff");
const mat6_magenta = toMaterial("#f0f");
// const may7_yellow = toMaterial("#ff0");
const mat8_brown = toMaterial("#563500");

let _vStem_ = new Vector3();
let _v1_ = new Vector3();
let _vForwardPosed = new Vector3();
let _vForwardPosedR = new Vector3();

export const TurnValues = ({ turnValues }: { turnValues: any }) => {
  if (!turnValues) {
    return null;
  }

  let {
    pNeck,
    // p1, // spoke point absolute pos
    p2, // projection of p1 onto stem, relative to head pos
    // p3, // projection of vForwardPosed onto vStem, relative to head pos
    p4,
    vStem,
    vForwardPosed,
    vForwardPosedR,
    v1,
    vSpoke,
  } = turnValues;

  _vStem_.copy(pNeck).add(vStem);
  _v1_.copy(pNeck).add(v1);
  _vForwardPosed.copy(pNeck).add(vForwardPosed);
  // _vForwardPosedR.copy(p3).add(vForwardPosedR);
  _vForwardPosedR.copy(p4).add(vForwardPosedR);

  return (
    <group>
      {/*----- head bone pivot point -----*/}
      {/*<Sphere position={pNeck} radius={0.1} material={mat1_red} />{" "}*/}
      {/*----- nose|midEye -----*/}
      {/*<Sphere position={p2} radius={radius} material={material2} />{" "}*/}
      {/*----- pNeck  -----*/}
      {/*<Sphere position={pNeck} radius={0.1} material={may7_yellow} />*/}
      {/*----- p1  -----*/}
      {/*<Sphere position={p1} radius={0.05} material={mat0_black} />*/}
      {/*----- vStem_ : vStem translated to pNeck -----*/}
      <VectorObject p={pNeck} q={_vStem_} material={mat3_blue} />
      {/*----- vForwardPosed -----*/}
      <VectorObject p={pNeck} q={_vForwardPosed} material={mat2_green} />
      {/*----- vForwardPosedR -----*/}
      {/*<VectorObject p={p3} q={_vForwardPosedR} material={mat5_cyan} />*/}
      <VectorObject p={p4} q={_vForwardPosedR} material={mat5_cyan} />
      {/*----- v1 -----*/}
      <VectorObject p={pNeck} q={_v1_} material={mat8_brown} />
      {/*----- p2  -----*/}
      <Sphere position={p2} radius={0.05} material={mat6_magenta} />
      {/*----- vSpoke -----*/}
      <VectorObject p={p2} q={p2.clone().add(vSpoke)} material={mat1_red} />
      {/*<VectorObject p={p3} q={p3.clone().add(vSpoke)} material={mat4_orange} />*/}
    </group>
  );
};
