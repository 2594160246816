import create from "zustand";

export interface TimelineStore {
  hotkeysEnabled: boolean;
  setHotKeysEnabled(hotkeysEnabled: boolean): void;

  tagsEnabled: boolean;
  setTagsEnabled(tagsEnabled: boolean): void;

  transparent: boolean;
  setTransparent(transparent: boolean): void;
}

export interface TimelineStoreDefaults {
  hotkeysEnabled?: boolean;
  tagsEnabled?:    boolean;
  transparent?:    boolean;
}

export const createTimelineStore = (defaults?: TimelineStoreDefaults) =>
  create<TimelineStore>((set) => ({
    hotkeysEnabled: (defaults && (defaults.hotkeysEnabled !== undefined))
      ? defaults.hotkeysEnabled : true,
    setHotKeysEnabled: (hotkeysEnabled: boolean) =>
      set({ hotkeysEnabled }),

    tagsEnabled: (defaults && (defaults.tagsEnabled !== undefined))
      ? defaults.tagsEnabled : true,
    setTagsEnabled: (tagsEnabled: boolean) =>
      set({ tagsEnabled }),

    transparent: (defaults && (defaults.transparent !== undefined))
      ? defaults.transparent : false,
    setTransparent: (transparent: boolean) =>
      set({ transparent }),
  }));
