import React, { useState, useEffect, useRef } from "react";
import useAxios from "axios-hooks";
import { DayGamePicker } from "./DayGamePicker";
import { StatsAPIGameTreeView } from "./StatsAPIGameTreeView";
import { toGumboUrl } from "../../util/gameUtil";

interface Props {
  onPlaySelected: (gamePk: string, playId: string) => void;
}

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const StatsAPIPlayPicker: React.FC<Props> = ({ onPlaySelected }) => {
  let [showDayGamePicker, setShowDayGamePicker] = useState(true);
  let [gamePk, setGamePk] = useState("");

  let handleCalClick = () => {
    setShowDayGamePicker(true);
  };

  // TODOHI   handle '' gamePk. for now, just swallowing the error for temporary '' state
  let [{ data: gumbo }, refetch] = useAxios(toGumboUrl(gamePk), {
    useCache: false,
  });

  useInterval(() => {
    refetch();
  }, 10000);

  let handleGameSelected = (gamePk: string) => {
    setGamePk(gamePk);
    setShowDayGamePicker(false);
  };

  // // TODOHI  handle gumboError
  // console.log('gumboError', gumboError)

  return (
    <div style={{ minHeight: "370px" }}>
      {showDayGamePicker ? (
        <DayGamePicker onGameSelected={handleGameSelected} />
      ) : (
        <StatsAPIGameTreeView
          gumbo={gumbo}
          onCalClick={handleCalClick}
          onPlaySelected={onPlaySelected}
        />
      )}
    </div>
  );
};
