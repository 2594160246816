import create from "zustand";

export interface FigureCaptionsStore {
  captionSet: string[];
  setCaptionSet(captionSet: string[]): void;
  captionFigureIds: string[];
  setCaptionFigureIds(captionFigureIds: string[]): void;
}

// TODOHI  add an initial values argument to figureCaptionsStore?
export const figureCaptionsStore = () =>
  create<FigureCaptionsStore>((set) => ({
    captionSet: [],
    setCaptionSet: (captionSet: string[]) => set({ captionSet }),

    captionFigureIds: [], // empty implies all figures
    setCaptionFigureIds: (captionFigureIds: string[]) =>
      set({ captionFigureIds }),
  }));
