import React from "react";
import * as THREE from "three";
import skyImage from "./sky.jpg";

const texture = new THREE.TextureLoader().load(skyImage, texture => {
  texture.encoding = THREE.sRGBEncoding;
});
export const Sky = () => {
  return (
    <mesh visible={true} rotation={new THREE.Euler(0, Math.PI / 2, 0)}>
      <sphereBufferGeometry attach="geometry" args={[1500]} />
      <meshBasicMaterial
        depthWrite={false}
        depthTest={false}
        attach="material"
        side={THREE.DoubleSide}
        map={texture}
      />
    </mesh>
  );
};
