import React from "react";
import _find from "lodash/find";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormHelperText from "@material-ui/core/FormHelperText";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import { playerPositions } from "field-of-things/src/constants/play";
import { locations } from "field-of-things/src/constants/locations";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  // selectEmpty: {
  //     marginTop: theme.spacing(2),
  // },
  offsetIntervalSliderRoot: {
    width: 200,
  },
}));

/**
const isPointsEqual = (p: any, q: any) => p.x === q.x && p.y === q.y;
const toLocationForPoint = (p: any) =>
  _find(locations, (location) => isPointsEqual(p, location.point));
*/

interface FigureSideCamKnobsProps {
  useCameraStore: any;
}

export const FigureSideCamKnobs = ({
  useCameraStore,
}: FigureSideCamKnobsProps) => {
  let classes = useStyles();
  let figureSideCamParams = useCameraStore(($: any) => $.figureSideCamParams);
  let {
    positionId,
    pivotName,
    distance,
    cameraHeight,
    lookAtHeight,
  } = figureSideCamParams;
  let setFigureSideCamParams = useCameraStore(
    ($: any) => $.setFigureSideCamParams
  );

  const handlePositionIdChange = (event: any) => {
    setFigureSideCamParams({
      ...figureSideCamParams,
      positionId: +event.target.value,
    });
  };
  const handlePivotChange = (event: any) => {
    let newPivotName = event.target.value;
    let isLocation = _find(locations, { name: newPivotName });
    let isPlayer = _find(playerPositions, { name: newPivotName });

    if (isLocation) {
      setFigureSideCamParams({ ...figureSideCamParams, pivotType: "Location", pivotName: newPivotName, pivot: isLocation.point  });
    } else if (isPlayer) {
      setFigureSideCamParams({ ...figureSideCamParams, pivotType: "Player", pivotName: newPivotName, pivot: isPlayer.positionId  });
    } else {
      setFigureSideCamParams({ ...figureSideCamParams, pivotType: "Ball", pivotName: newPivotName, pivot: undefined});
    }

  };
  const handleDistanceChange = (event: any, value: any) => {
    setFigureSideCamParams({ ...figureSideCamParams, distance: value });
  };
  const handleCameraHeightChange = (event: any, value: any) => {
    setFigureSideCamParams({ ...figureSideCamParams, cameraHeight: +value });
  };
  const handleLookAtHeightChange = (event: any, value: any) => {
    setFigureSideCamParams({ ...figureSideCamParams, lookAtHeight: +value });
  };

  return (
    <div style={{ marginLeft: "1.5rem" }}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="pivot">Pivot Point</InputLabel>
        <NativeSelect
          value={pivotName}
          onChange={handlePivotChange}
          inputProps={{
            name: "pivot",
            id: "pivot",
          }}
        >
          <option key={"Ball"} value={"Ball"}> {"Ball"} </option>
          {locations.map((location: any) => (
            <option key={location.name} value={location.name}>
              {location.name}
            </option>
          ))}
          
          {playerPositions.map((p: any) => (
            <option key={p.name} value={p.name}>
              {p.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <FormHelperText>
        The side cam aligns with the pivot and the figure
      </FormHelperText>

      <br />

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="position-id">Figure to Follow</InputLabel>
        <NativeSelect
          value={positionId}
          onChange={handlePositionIdChange}
          inputProps={{
            name: "position-id",
            id: "position-id",
          }}
        >
          {playerPositions.map((p: any) => (
            <option key={p.positionId} value={p.positionId}>
              {p.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <FormHelperText>So far, tends to work best for batter</FormHelperText>
      <FormHelperText>
        <i>
          Note: some games don't have the metadata
          <br />
          needed for identifying players
        </i>
      </FormHelperText>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Cam Distance from Fig (feet)
        <Slider
          min={0}
          max={50}
          value={distance}
          aria-labelledby="camera-distance-slider"
          step={1}
          valueLabelDisplay="auto"
          onChange={handleDistanceChange}
        />
      </div>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Camera Height (feet)
        <Slider
          min={0}
          max={30}
          value={cameraHeight}
          aria-labelledby="camera-height-slider"
          step={0.5}
          valueLabelDisplay="auto"
          onChange={handleCameraHeightChange}
        />
      </div>

      <br />

      <div className={classes.offsetIntervalSliderRoot}>
        Look-At Height (feet)
        <Slider
          min={0}
          max={10}
          value={lookAtHeight}
          aria-labelledby="lookat-height-slider"
          step={0.5}
          valueLabelDisplay="auto"
          onChange={handleLookAtHeightChange}
        />
        <FormHelperText>Camera Gaze Target Height</FormHelperText>
      </div>

      <br />
    </div>
  );
};
