import { Quaternion, Vector3 } from "three";
import { makeVector3, setVector3 } from "../../util/three-util";
import { JointsMapType } from "../Figure";

// TODO    grab objects with getObjectByName once up front and cache

const xWorld = new Vector3(1, 0, 0);
const zWorld = new Vector3(0, 0, 1);
// const vForward = new Vector3(0, -1, 0); // bind-pose model's forward direction

// TODO    reuse vectors to avoid thrashing the GC (replace makeVector3 and .clone())
/**
 * Calculates Qgs according to algorithm line 4 in Pablo's twist notes.
 * Although, it doesn't appear to be working yet
 * @param jointsMap
 * @param qGS
 */
const calculateMidShoulderWorldQuaternion = (
  jointsMap: JointsMapType,
  qGS = new Quaternion()
) => {
  let { MidHip, MidShoulder, LShoulder, RShoulder } = jointsMap;

  let hasRequiredJoints = MidHip && MidShoulder && RShoulder && LShoulder;

  if (!hasRequiredJoints) {
    return new Quaternion();
  }

  let rotate = true;
  let mh = makeVector3(MidHip, rotate);
  let ms = makeVector3(MidShoulder, rotate);
  let lShoulder = makeVector3(LShoulder, rotate);
  let rShoulder = makeVector3(RShoulder, rotate);

  // Xs is the unit vector from left shoulder and right shoulder
  let xS = new Vector3().subVectors(rShoulder, lShoulder).normalize();

  // vUp = unit vector between MH and MS
  let vUp = new Vector3().subVectors(ms, mh).normalize();

  // Zs = Xs x vUp
  let zS = new Vector3().crossVectors(xS, vUp);

  // Q(X,Xs)
  //  Q(X,Xs) is the quaternion that takes world X to target X of shoulders
  //  q1 = quaternion from X to Xs
  let q1 = new Quaternion().setFromUnitVectors(xWorld, xS);

  // Q(X,Xs)[Z]
  //  Q(X,Xs)[Z] is this same quaternion applied to world Z, so we have a new Z but one that is not quite
  //  where we want it.
  let zI = zWorld.clone().applyQuaternion(q1);
  // let zI = vForward.clone().applyQuaternion(q1);

  // Q(Q(X,Xs)[Z], Zs)
  //  Q(Q(X,Xs)[Z], Zs) is the quaternion that takes the transformed Z to the target Z of shoulders.
  //  Now it is where we want it.
  let q2 = new Quaternion().setFromUnitVectors(zI, zS);

  // Q(Q(X,Xs)[Z], Zs) * Q(X, Xs)
  //  Q(Q(X,Xs)[Z], Zs) * Q(X, Xs) is the quaternion that takes world X to target X, followed by the quaternion
  //  that takes the transformed Z to the target Z. Everything is now where we want it.
  qGS.multiplyQuaternions(q2, q1);

  return qGS;
};

const _Qgr = new Quaternion();
const _Qgs = new Quaternion();
const _Qidentity = new Quaternion();
const Q_slerp = new Quaternion();

/**
 * Calculates Qs with Pablo's early suggestion to pose with Ik and get world quaternions from root and
 * neck(posed with MS)
 * @param root
 */
const getQsWithNeck = (root: any) => {
  let ms = root.getObjectByName("neck");

  root.getWorldQuaternion(_Qgr);
  ms.getWorldQuaternion(_Qgs);

  return _Qgr.invert().multiply(_Qgs);
};

/**
 * Calculates Qs from Pablo's more recent suggestion:
 * derive the Qs (local) by treating Qroot as parent and either Lshoulder or RShoulder as child
 * @param root
 */
const getQsWithShoulder = (root: any) => {
  let shoulder = root.getObjectByName("R_shoulder");

  root.getWorldQuaternion(_Qgr);
  shoulder.getWorldQuaternion(_Qgs);

  return _Qgr.invert().multiply(_Qgs);
};

const _vLHip = new Vector3();
const _vRHip = new Vector3();
const _vLShoulder = new Vector3();
const _vRShoulder = new Vector3();
const _vH = new Vector3();
const _vS = new Vector3();

/**
 * Calculates Qs using vH and vS. May be over simplified?
 * @param jointsMap
 * @param qS
 */
const calculateSimpleQs = (jointsMap: JointsMapType, qS = new Quaternion()) => {
  let { LHip, RHip, LShoulder, RShoulder } = jointsMap;

  let hasRequiredJoints = LHip && RHip && RShoulder && LShoulder;

  if (!hasRequiredJoints) {
    return new Quaternion();
  }

  let rotate = true;

  setVector3(_vLHip, LHip, rotate);
  setVector3(_vRHip, RHip, rotate);
  setVector3(_vLShoulder, LShoulder, rotate);
  setVector3(_vRShoulder, RShoulder, rotate);
  _vH.subVectors(_vRHip, _vLHip).normalize();
  _vS.subVectors(_vRShoulder, _vLShoulder).normalize();

  qS.setFromUnitVectors(_vH, _vS);

  return qS;
};

/**
 * Uses the method for calculating Qgs and Qs in Pablo's notes on Twisting
 * @param root
 * @param jointsMap
 */
const calculateQs = (root: any, jointsMap: JointsMapType) => {
  root.getWorldQuaternion(_Qgr);
  calculateMidShoulderWorldQuaternion(jointsMap, _Qgs);

  return _Qgr.invert().multiply(_Qgs);
};

export const qSMethods = {
  simple: "simple", // <-- only method that works so far
  notes: "notes",
  fromIKNeck: "fromIKNeck",
  fromIKShoulder: "fromIKShoulder",
};

const _Qs = new Quaternion();

/**
 * Calculate qs using one of four methods
 * @param root
 * @param jointsMap
 * @param qSMethod – defaults to qSMethods.simple
 */
const toQs = (
  root: any,
  jointsMap: JointsMapType,
  qSMethod = qSMethods.simple
) => {
  switch (qSMethod) {
    case qSMethods.notes:
      return calculateQs(root, jointsMap);
    case qSMethods.fromIKNeck:
      return getQsWithNeck(root);
    case qSMethods.fromIKShoulder:
      return getQsWithShoulder(root);
    case qSMethods.simple:
    default:
      return calculateSimpleQs(jointsMap, _Qs);
  }
};

export const applyTwist = (root: any, jointsMap: JointsMapType) => {
  // note: the rig's 'neck' bone corresponds to the MidShoulder point

  let ms = root.getObjectByName("neck");
  let spine1 = root.getObjectByName("spine01");
  let spine2 = root.getObjectByName("spine02");

  // example of temporarily hard coding Qs for diagnostics:
  // _Qgs.setFromAxisAngle(new Vector3(0, 1, 0), 1.1 * Math.PI);

  let Qs = toQs(root, jointsMap);

  // subdivide Qs and apply to spine01, spine02, and neck/ms
  _Qs.slerpQuaternions(_Qidentity, Qs, 0.3333);
  spine1.quaternion.copy(Q_slerp);
  spine2.quaternion.copy(Q_slerp);
  ms.quaternion.copy(Q_slerp);

  // TODO    need to explicitly update matrices?
  // spine1.updateWorldMatrix(true, true);
};
