import React from "react";

const HALF_PI = 0.5 * Math.PI;

const PIN_CYL_RADIUS = 0.5 / 12;
const PIN_CYL_COLOR = "#f00";
const PIN_CYL_HEIGHT = 10;

interface Props {
  position: number[];
}

export const PinMark = ({ position }: Props) => {
  return (
    <group>
      <mesh
        name="pin-mark"
        position={[position[0], position[1], 0.5 * PIN_CYL_HEIGHT]}
        rotation-x={HALF_PI}
        castShadow
      >
        <cylinderBufferGeometry
          attach="geometry"
          args={[PIN_CYL_RADIUS, PIN_CYL_RADIUS, PIN_CYL_HEIGHT, 16]}
        />
        <meshStandardMaterial attach="material" color={PIN_CYL_COLOR} />
      </mesh>
    </group>
  );
};
