import { appSpaces } from "../SpaceSelect";
import React, { useMemo } from "react";
import { StoryboardSpace } from "../Storyboard";
import { createPlayViewRootStore } from "field-of-things/src/stores";
import { prepTracking } from "field-of-things/src/util/tracking-data-util";

interface WrappedStoryboardSpaceProps {
  rootStore: any;
  appSpace: string;
  setAppSpace: any;
  playData: any;
  playTracking: any;
  openPlayPicker: any;
}

// wrapping StoryboardSpace here to prep the tracking and create a separate sbsRootStore
// this may be temporary
export const WrappedStoryboardSpace = (props: WrappedStoryboardSpaceProps) => {
  const {
    rootStore,
    appSpace,
    setAppSpace,
    playData,
    playTracking,
    openPlayPicker,
  } = props;
  const isStoryboardSpace = appSpace === appSpaces.storyboard;

  // TODOHI  create rootStore inside StoryboardSpace instead? need any values from main rootStore?
  const sbsRootStore = useMemo(
    () => isStoryboardSpace && createPlayViewRootStore(),
    [isStoryboardSpace]
  );

  const jointCleanup = rootStore.useFigurePositionStore(
    ($: any) => $.jointCleanup
  );

  const upsample = rootStore.useFigurePositionStore(($: any) => $.upsample);

  const preppedPlayTracking = useMemo(
    () =>
      playTracking &&
      prepTracking({ playData, playTracking, jointCleanup, upsample }),
    [playData, playTracking, jointCleanup, upsample]
  );

  // TODOHI  need actual venueId; or can drop the venueId prop and derive from playData in StoryboardSpace?

  return (
    <StoryboardSpace
      rootStore={sbsRootStore}
      appSpace={appSpace}
      onAppSpaceChange={setAppSpace}
      venueId={5}
      playTracking={preppedPlayTracking}
      playData={playData}
      onPlayClicked={openPlayPicker}
    />
  );
};
