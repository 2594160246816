import React from "react";
import { HALF_PI } from "../../constants/math";
import { chalkColor } from "../../constants/colors";
import {
  halfPlateWidth,
  halfPlateLength,
  CHALK_WIDTH,
  CHALK_THICKNESS,
} from "../../constants/dimensions";

const ChalkLine = (props: any) => {
  return (
    <mesh receiveShadow {...props}>
      <boxBufferGeometry attach="geometry" args={[CHALK_WIDTH, 1, 0.02]} />
      <meshStandardMaterial attach="material" color={chalkColor} />
    </mesh>
  );
};

// interface Props {
//     shadows?: boolean,
// }

// export const PlateBoxes = ({/*shadows = false*/}) => {
export const PlateBoxes = () => {
  let width = 0.25;
  let yBboxFront = halfPlateLength + 3;
  let yBboxBack = halfPlateLength - 3;
  let xBBoxLeftInner = -halfPlateWidth - 0.5;
  let xBBoxLeftOuter = xBBoxLeftInner - 4;
  let xBBoxLeftMid = 0.5 * (xBBoxLeftInner + xBBoxLeftOuter);
  let xBBoxRightInner = halfPlateWidth + 0.5;
  let xBBoxRightOuter = xBBoxRightInner + 4;
  let xBBoxRightMid = 0.5 * (xBBoxRightInner + xBBoxRightOuter);
  let cBoxWidth = 43 / 12;
  let xCBoxLeft = -0.5 * cBoxWidth;
  let xCBoxRight = 0.5 * cBoxWidth;
  let yCBoxFront = halfPlateLength - 3;
  let yCBoxBack = -8;
  let yCBoxMid = 0.5 * (yCBoxFront + yCBoxBack);
  let yCBoxLength = yCBoxFront - yCBoxBack;

  return (
    <group name="plate-boxes">
      {/* right-handed batter's Box */}
      <ChalkLine
        scale={[1, 6, 1]}
        position={[xBBoxLeftInner, halfPlateLength, CHALK_THICKNESS]}
      />
      <ChalkLine
        scale={[1, 6, 1]}
        position={[xBBoxLeftOuter, halfPlateLength, CHALK_THICKNESS]}
      />
      <ChalkLine
        scale={[1, 4 + width, 1]}
        position={[xBBoxLeftMid, yBboxFront, CHALK_THICKNESS]}
        rotation={[0, 0, HALF_PI]}
      />
      <ChalkLine
        scale={[1, 4 + width, 1]}
        position={[xBBoxLeftMid, yBboxBack, CHALK_THICKNESS]}
        rotation={[0, 0, HALF_PI]}
      />

      {/* left-handed batter's Box */}
      <ChalkLine
        scale={[1, 6, 1]}
        position={[xBBoxRightInner, halfPlateLength, CHALK_THICKNESS]}
      />
      <ChalkLine
        scale={[1, 6, 1]}
        position={[xBBoxRightOuter, halfPlateLength, CHALK_THICKNESS]}
      />
      <ChalkLine
        scale={[1, 4 + width, 1]}
        position={[xBBoxRightMid, yBboxFront, CHALK_THICKNESS]}
        rotation={[0, 0, HALF_PI]}
      />
      <ChalkLine
        scale={[1, 4 + width, 1]}
        position={[xBBoxRightMid, yBboxBack, CHALK_THICKNESS]}
        rotation={[0, 0, HALF_PI]}
      />

      {/* catcher's Box */}
      <ChalkLine
        scale={[1, yCBoxLength, 1]}
        position={[xCBoxLeft, yCBoxMid, CHALK_THICKNESS]}
      />
      <ChalkLine
        scale={[1, yCBoxLength, 1]}
        position={[xCBoxRight, yCBoxMid, CHALK_THICKNESS]}
      />
      <ChalkLine
        scale={[1, cBoxWidth + width, 1]}
        position={[0, yCBoxBack, CHALK_THICKNESS]}
        rotation={[0, 0, HALF_PI]}
      />
    </group>
  );
};
