import create from "zustand";

export const easingTypes = [
  "none",
  "sine",
  "cubic",
  "exponential",
  "^5 polynomial",
];

export type Easing =
  | "none"
  | "sine"
  | "cubic"
  | "exponential"
  | "^5 polynomial";

export const flycamParams = {
  startPosDistance: {
    default: 30,
    range: [0, 150],
  },
  startPosAngle: {
    default: 45,
    range: [-180, 180],
  },
  startPosHeight: {
    default: 5,
    range: [0, 100],
  },
  startLookAtY: {
    default: 30,
    range: [0, 60],
  },
  startLookAtZ: {
    default: 5,
    range: [0, 50],
  },
  launchDistance: {
    default: 20,
    range: [0, 50],
  },
  midpointCameraDistance: {
    default: 20,
    range: [-100, 150],
  },
  restDuration: {
    default: 500,
    range: [0, 5000],
  },
  restCameraDistance: {
    default: 50,
    range: [-200, 200],
  },
  launchRampDuration: {
    default: 250,
    range: [100, 2000],
  },
};

export interface FlyCamStore {
  startPosDistance: number;
  startPosAngle: number;
  startPosHeight: number;

  setStartPosDistance(startPosDistance: number): void;

  setStartPosAngle(startPosAngle: number): void;

  setStartPosHeight(startPosHeight: number): void;

  startLookAtY: number;
  startLookAtZ: number;

  setStartLookAtY(startLookAtY: number): void;

  setStartLookAtZ(startLookAtZ: number): void;

  launchDistance: number;

  setLaunchDistance(launchDistance: number): void;

  midpointCameraDistance: number;

  setMidpointCameraDistance(midpointCameraDistance: number): void;

  restDuration: number;

  setRestDuration(restDuration: number): void;

  restCameraDistance: number;

  setRestCameraDistance(restCameraDistance: number): void;

  cameraFov: number;

  setCameraFov(cameraFov: number): void;

  referenceMarksVisible: boolean;

  setReferenceMarksVisible(referenceMarksVisible: boolean): void;

  launchRampDuration: number;

  setLaunchRampDuration(launchRampDuration: number): void;

  easingType: Easing;

  setEasingType(easingType: Easing): void;

  paramLocks: object;

  setParamLocks(paramLocks: object): void;
}

export const createFlyCamStore = () =>
  create<FlyCamStore>((set) => ({
    startPosDistance: flycamParams.startPosDistance.default,
    startPosAngle: flycamParams.startPosAngle.default,
    startPosHeight: flycamParams.startPosHeight.default,
    setStartPosDistance: (startPosDistance: number) =>
      set({ startPosDistance }),
    setStartPosAngle: (startPosAngle: number) => set({ startPosAngle }),
    setStartPosHeight: (startPosHeight: number) => set({ startPosHeight }),

    startLookAtY: flycamParams.startLookAtY.default,
    startLookAtZ: flycamParams.startLookAtZ.default,
    setStartLookAtY: (startLookAtY: number) => set({ startLookAtY }),
    setStartLookAtZ: (startLookAtZ: number) => set({ startLookAtZ }),

    launchDistance: flycamParams.launchDistance.default,
    setLaunchDistance: (launchDistance: number) => set({ launchDistance }),

    midpointCameraDistance: flycamParams.midpointCameraDistance.default,
    setMidpointCameraDistance: (midpointCameraDistance: number) =>
      set({ midpointCameraDistance }),

    restDuration: flycamParams.restDuration.default,
    setRestDuration: (restDuration: number) => set({ restDuration }),

    restCameraDistance: flycamParams.restCameraDistance.default,
    setRestCameraDistance: (restCameraDistance: number) =>
      set({ restCameraDistance }),

    cameraFov: 60,
    setCameraFov: (cameraFov: number) => set({ cameraFov }),

    referenceMarksVisible: false,
    setReferenceMarksVisible: (referenceMarksVisible: boolean) =>
      set({ referenceMarksVisible }),

    launchRampDuration: flycamParams.launchRampDuration.default,
    setLaunchRampDuration: (launchRampDuration: number) =>
      set({ launchRampDuration }),

    easingType: "sine",
    setEasingType: (easingType: Easing) => set({ easingType }),

    paramLocks: {
      startPosDistanceLock: false,
      startPosAngleLock: false,
      startPosHeightLock: false,
      startLookAtYLock: false,
      startLookAtZLock: false,
      launchDistanceLock: false,
      launchRampDurationLock: false,
      midpointCameraDistanceLock: false,
      restDurationLock: false,
      restCameraDistanceLock: false,
    },
    setParamLocks: (paramLocks: object) => set({ paramLocks }),
  }));
