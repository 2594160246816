import { SyntheticEvent, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useMediaQuery } from "react-responsive";

import { Timebase } from "../../models/timebase";

import { Icon } from "@mlb/react-patterns/components";
import { IconSizes } from "@mlb/react-patterns/components/Icon/icon.types";

import styles from "./PlaybackControls.module.css";
import { RootStore } from "../../stores";

const rates = [
  {
    name: "1x",
    value: 1,
  },
  {
    name: "2x",
    value: 2,
  },
  {
    name: "3x",
    value: 3,
  },
  {
    name: "¼x",
    value: 0.25,
  },
  {
    name: "½x",
    value: 0.5,
  },
];

// TODO    more specific function signature
const RateToggle = ({ onChange }: { onChange: any }) => {
  let [rateIndex, stRateIndex] = useState(0);
  let rate = rates[rateIndex];
  let handleClick = () => {
    let newIndex = (rateIndex + 1) % rates.length;

    stRateIndex(newIndex);
    onChange && onChange(rates[newIndex].value);
  };
  let style = {
    width: "1rem",
    paddingRight: "12px",
    cursor: "pointer",
  };

  return (
    <div style={style} onMouseDown={handleClick}>
      {rate.name}
    </div>
  );
};

type LoopToggleProps = {
  handleToggleLoop: (e: SyntheticEvent) => void;
  loop: boolean;
  iconSize: IconSizes;
};

const LoopToggle = (props: LoopToggleProps) => {
  const { handleToggleLoop, loop, iconSize } = props;
  return (
    <button className={styles.playbackButton} onMouseDown={handleToggleLoop}>
      <Icon
        name="recycling"
        size={iconSize}
        fill={loop ? "#e4cb41" : "currentColor"}
      />
    </button>
  );
};

type PlaybackControlsProps = {
  timebase: Timebase;
  rootStore: RootStore;
  hotkeys?: boolean;
};

export const PlaybackControls = ({
  timebase,
  rootStore,
  hotkeys,
}: PlaybackControlsProps) => {
  let { isPlaying, loop } = timebase;

  let stepBack1Sec = () => {
    timebase.slipToTime(timebase.time - 1000, 250);
  };
  let handleStepBack1Sec = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // timebase.stop()
    // timebase.slipToTime(timebase.time - 1000, 250)
    stepBack1Sec();
  };
  let stepBack = () => {
    timebase.stop();
    // TODOHI  implement actual frame timing
    timebase.time -= 33;
  };
  let handleStepBack1Frame = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // timebase.stop()
    // // TODOHI  implement actual frame timing
    // timebase.time -= 33
    stepBack();
  };
  let togglePlay = () => {
    timebase.togglePlay();
  };
  let togglePlayback = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // timebase.togglePlay()
    togglePlay();
  };
  let stepForward = () => {
    timebase.stop();
    // TODOHI  implement actual frame timing
    timebase.time += 33;
  };
  let handleStepFoward1Frame = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // timebase.stop()
    // // TODOHI  implement actual frame timing
    // timebase.time += 33
    stepForward();
  };
  let stepForward1Sec = () => {
    timebase.slipToTime(timebase.time + 1000, 250);
  };
  let handleStepFoward1Sec = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // timebase.stop()
    // timebase.slipToTime(timebase.time + 1000, 250)
    stepForward1Sec();
  };
  let handleToggleLoop = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    timebase.loop = !timebase.loop;
  };
  let handleRateChange = (value: number) => {
    timebase.rate = value;
  };

  useHotkeys("left", () => hotkeys && stepBack(), [hotkeys]);
  useHotkeys("right", () => hotkeys && stepForward(), [hotkeys]);
  useHotkeys("space", () => hotkeys && togglePlay(), [hotkeys]);
  useHotkeys("down", () => hotkeys && stepBack1Sec(), [hotkeys]);
  useHotkeys("up", () => hotkeys && stepForward1Sec(), [hotkeys]);

  const { useRenderingStore } = rootStore;
  const { fullScreen, toggleFullScreen } = useRenderingStore();

  // TODO: put these screen dims somewhere central
  const smallScreen = useMediaQuery({ query: "(max-width: 1224px)" });
  const miniScreen = useMediaQuery({ query: "(max-width: 370px)" });

  const iconSize = smallScreen ? IconSizes.MD : IconSizes.LG;

  return (
    <div className={styles.container}>
      {smallScreen && (
        <div className={styles.playbackControls}>
          <LoopToggle
            handleToggleLoop={handleToggleLoop}
            loop={loop}
            iconSize={iconSize}
          />
        </div>
      )}
      <div className={styles.playbackControls}>
        {!miniScreen && (
          <button
            className={styles.playbackButton}
            onMouseDown={handleStepBack1Sec}
          >
            <Icon name="rewind-10" size={iconSize} fill="currentColor" />
          </button>
        )}

        <button
          className={styles.playbackButton}
          onMouseDown={handleStepBack1Frame}
        >
          <Icon name="arrow-previous" size={iconSize} fill="currentColor" />
        </button>

        <button
          className={styles.playbackButton}
          style={{ width: "3rem" }}
          onMouseDown={togglePlayback}
        >
          {isPlaying ? (
            <Icon name="pause" size={iconSize} fill="currentColor" />
          ) : (
            <Icon name="play" size={iconSize} fill="currentColor" />
          )}
        </button>

        <button
          className={styles.playbackButton}
          onMouseDown={handleStepFoward1Frame}
        >
          <Icon name="arrow-next" size={iconSize} fill="currentColor" />
        </button>

        {!miniScreen && (
          <button
            className={styles.playbackButton}
            onMouseDown={handleStepFoward1Sec}
          >
            <Icon name="fast-forward-10" size={iconSize} fill="currentColor" />
          </button>
        )}
      </div>

      <div className={styles.playbackControls}>
        {!smallScreen && (
          <LoopToggle
            handleToggleLoop={handleToggleLoop}
            loop={loop}
            iconSize={iconSize}
          />
        )}
        <RateToggle onChange={handleRateChange} />
        <button
          className={styles.playbackButton}
          onMouseDown={() => toggleFullScreen()}
        >
          {fullScreen ? (
            <Icon name="exit-fullscreen" size={iconSize} fill="currentColor" />
          ) : (
            <Icon name="enter-fullscreen" size={iconSize} fill="currentColor" />
          )}
        </button>
      </div>
    </div>
  );
};
