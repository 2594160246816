import React, { useMemo } from "react";
import _ from "lodash";
import { RootStore } from "../../stores";
import { StatsApiNS } from "../../types";
import { toEventTime } from "../../util/play-util";
import {
  PITCHER_FIRST_MOVEMENT_EVENT,
  BALL_WAS_PITCHED_EVENT,
} from "../../models/playEventTypes";
import {
  toFramesFromTimes,
  toClosestFrameTimes,
  toFrameTimes,
} from "./strobe-util";
import { StrobeFramesGroup } from "./StrobeFramesGroup";

interface Props {
  rootStore: RootStore;
  playData: StatsApiNS.PlayData;
  playTracking: StatsApiNS.SkeletalData;
  skeleton: any;
}

export const HitterReactionStrobes = ({
  rootStore,
  playData,
  playTracking,
  skeleton,
}: Props) => {
  let { useFigureStrobeStore } = rootStore;
  let {
    hitterReactionPFMEnabled,
    hitterReactionReleaseEnabled,
    hitterReactionRecoEnabled,
    hitterReactionCommitEnabled,
    hitterReactionPlateEnabled,
  } = useFigureStrobeStore();
  let strobeTrackingFrames = useMemo(() => {
    let pfmTime = toEventTime(playData, PITCHER_FIRST_MOVEMENT_EVENT);
    let releaseTime = toEventTime(playData, BALL_WAS_PITCHED_EVENT);
    let recoTime = releaseTime + 100;
    //@ts-ignore
    let { zoneTime } = playData.ballSegments.pitchSegment.trajectoryData;
    let plateCrossingTime = Math.round(releaseTime + zoneTime * 1000);
    let commitTime = plateCrossingTime - 167;
    let strobeTimes = _.filter(
      _.compact([
        hitterReactionPFMEnabled && pfmTime,
        hitterReactionReleaseEnabled && releaseTime,
        hitterReactionRecoEnabled && recoTime,
        hitterReactionCommitEnabled && commitTime,
        hitterReactionPlateEnabled && plateCrossingTime,
      ]),
      (t: number) => t >= 0
    );
    let frameTimes = toFrameTimes(playTracking);

    let strobeFrameTimes = _.compact(
      toClosestFrameTimes({ strobeTimes, frameTimes })
    );

    return toFramesFromTimes(strobeFrameTimes, playTracking);
  }, [
    playData,
    playTracking,
    hitterReactionPFMEnabled,
    hitterReactionReleaseEnabled,
    hitterReactionRecoEnabled,
    hitterReactionCommitEnabled,
    hitterReactionPlateEnabled,
  ]);

  let strobeOpacity = 1;
  let visiblePositionIds = ["10"];

  let refs = strobeTrackingFrames.map(() => React.createRef());

  // TODO    should strobeFramesGroup be memoized as well?
  let strobeFramesGroup = (
    <StrobeFramesGroup
      rootStore={rootStore}
      playData={playData}
      strobeTrackingFrames={strobeTrackingFrames}
      skeleton={skeleton}
      strobeOpacity={strobeOpacity}
      visiblePositionIds={visiblePositionIds}
      castShadow={false}
      refs={refs}
    />
  );

  return strobeFramesGroup;
};
