import { figureColoringType } from "../../stores/figureAppearanceStore";
import { interpolateHcl } from "d3-interpolate";
import { color as d3color } from "d3-color";
import _find from "lodash/find";
import { teamColors } from "../../constants/team-colors";

// const team1Color = '#ad4f03'
// const team2Color = '#107cbf'

const defensePositionIds = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const offensePositionIds = [10, 11, 12, 13];
const umpirePositionIds = [14, 15, 16, 17, 20, 21];

export const teamPrimaryColor = (teamId: string | number | undefined) => {
  if (teamId === undefined) {
    return "#000";
  }

  let team = _find(teamColors, { team_id: teamId + "" });

  // return team ? `#${team.parent_color}` : '#000'

  let c = team ? `#${team.parent_color}` : "#000";
  // @ts-ignore
  return d3color(c).darker(2).formatHex();
};

export const teamSecondaryColor = (teamId: string | number | undefined) => {
  if (teamId === undefined) {
    return "#000";
  }

  let team = _find(teamColors, { team_id: teamId + "" });

  let c = team ? `#${team.secondary_color}` : "#000";

  // TODOHI  assumes all secondary colors are on the bright side
  // @ts-ignore
  return d3color(c).darker(4).formatHex();
  //  return d3color(c).darker(3.5).formatHex()
};

interface toColorByPositionIdProps {
  positionId: number | undefined;
  homeTeamId?: string | number;
  battingTeamId: any;
  fieldingTeamId: any;
}

const toColorByPositionId = ({
  positionId,
  homeTeamId,
  battingTeamId,
  fieldingTeamId,
}: toColorByPositionIdProps) => {
  if (positionId === undefined) {
    // return '#333'
    return "#222";
  }

  if (defensePositionIds.includes(positionId)) {
    // return team1Color

    return fieldingTeamId === homeTeamId
      ? teamPrimaryColor(fieldingTeamId)
      : teamSecondaryColor(fieldingTeamId);
  }
  if (offensePositionIds.includes(positionId)) {
    // return team2Color

    return battingTeamId === homeTeamId
      ? teamPrimaryColor(battingTeamId)
      : teamSecondaryColor(battingTeamId);
  }
  if (umpirePositionIds.includes(positionId)) {
    return "#000";
  } else {
    // return '#ddd'
    return "#222";
  }
};

// const toColorByTeam = (team: number | undefined) => {
//     if (team === undefined) {
//         return '#333'
//     }
//
//     return team === 1 ? team1Color : team2Color
// }

const pickColor = "#d04503";
const tPick = 0.2;
const mixPick = (color: any) => {
  return interpolateHcl(color, pickColor)(tPick);
};

const hoverColor = "#bb9402";
const tHover = 0.4;

const mixHover = (color: any) => {
  return interpolateHcl(color, hoverColor)(tHover);
};

interface toColorProps {
  figureData: any;
  figureColoring: figureColoringType;
  homeTeamId?: string | number;
  battingTeamId?: string | number;
  fieldingTeamId?: string | number;
  hover: boolean;
  pick: boolean;
}

export const toColor = ({
  figureData,
  figureColoring,
  homeTeamId,
  battingTeamId,
  fieldingTeamId,
  hover,
  pick,
}: toColorProps) => {
  let color = "#333";

  switch (figureColoring) {
    case "position":
      let { positionId } = figureData;

      color = toColorByPositionId({
        positionId,
        homeTeamId,
        battingTeamId,
        fieldingTeamId,
      });
      break;
    // case 'team':
    //     let team = figureData?.team
    //
    //     color = toColorByTeam(team)
    //     break;
    case "none":
    default:
  }

  // modify by hover and pick
  if (pick) {
    color = mixPick(color);
  }

  if (hover) {
    return mixHover(color);
  }

  return color;
};
