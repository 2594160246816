import React, { useEffect, useMemo, useRef } from "react";
import _find from "lodash/find";
import { toCamera } from "./ViewConstants";
import { Canvas, useFrame } from "@react-three/fiber";
import { StatcastGroup } from "../StatcastGroup";
import { FieldGroup } from "../FieldGroup";
import { TrackingGroup } from "../TrackingGroup";
import { FigurePathGroup } from "../FigurePathGroup";
import { FigurePositionGroup } from "../FigurePositionGroup";
import { BallTrackingGroup } from "../BallTrackingGroup";
import { MarksGroup } from "../MarksGroup";
import { Camera } from "./Camera";
import { Lights } from "./Lights";
import { CameraControls } from "./CameraControls";
import { GLStats } from "../GLStats";
import { Stats } from "@react-three/drei";
import { skeletons } from "../../constants/skeleton";
import { timeToFrame, toMPDTimeSeries } from "../../util/tracking-util";
import { Timebase } from "../../models/timebase";
import { toReleaseTime } from "../../util/time-util";
import { PlaySummary } from "../PlaySummary";
import { ZoneProjectionGroup } from "../ZoneProjectionGroup";
import { RootStore } from "../../stores";
import { ZoneProjectionStore } from "../../stores/zoneProjectionStore";
import { GlStatesStore } from "../../stores/glStatsStore";
import { LightingStore } from "../../stores/lightingStore";
import { WebGLRenderer } from "three";
import { Sky } from "./Sky";
import { SwingTrack } from "../SwingTrack";
import { SwingFlair } from "../BatTrailFlair";
import { Strobes } from "../Strobes";
import { toEventTime } from "../../util/play-util";
import { BALL_WAS_PITCHED_EVENT } from "../../models/playEventTypes";
import { StatsApiNS } from "../../types";
import { CameraStore } from "../../stores/cameraStore";
import { Effects } from "../Effects/Effects";

const defaultSkeleton = skeletons.boxTorsoSkeleton3;

function Recorder(props: { recorder?: any; renderer?: WebGLRenderer }) {
  useFrame(() => {
    const { recorder, renderer } = props;
    if (renderer && recorder) {
      recorder.capture(renderer.domElement);
    }
  });
  return null;
}

let TimebaseDriver = ({ timebase }: { timebase: Timebase }) => {
  useFrame(() => {
    timebase && timebase.update();
  });

  return null;
};

const toBatter = (frame: StatsApiNS.SkeletalFrame) => {
  if (frame.positions === undefined) {
    debugger;
  }

  let figure = frame.positions.find(
    (p: StatsApiNS.SkeletalPosition) => p.positionId === 10
  );

  return figure;
};

const BALL_TYP = 4;

const traceTimes = false;

interface Props {
  venueId: number;
  playTracking: StatsApiNS.SkeletalData;
  playData: StatsApiNS.PlayData;
  timebase: Timebase;
  skeleton?: any;
  initialCameraMatrixArray?: any; // optional, just passed through to Camera
  vie?: string;
  cameraRef?: any; // optional, forwarded to Camera to set to camera
  onClick?: (clicked: any) => void;
  view?: string;

  // TODOHI  temp, only needed with stopgap tracking
  trackingTimeOffset?: number;

  rootStore: RootStore;
}

// TODO    view constants?
export const PlayView = (props: Props) => {
  // useWhyDidYouUpdate('PlayView', props)
  let {
    venueId,
    playTracking,
    playData,
    timebase,
    skeleton = defaultSkeleton,
    initialCameraMatrixArray, // optional, just passed through to Camera
    view = "hh",
    cameraRef, // optional, forwarded to Camera to set to camera
    onClick,

    // TODOHI  temp, only needed with stopgap tracking
    trackingTimeOffset = 0,

    rootStore,
  } = props;
  let {
    useBallStore,
    useCameraStore,
    useFigureStrobeStore,
    useFigurePositionStore,
    useGlStats: useGlStatsStore,
    useMarksStore,
    useLightingStore,
    usePlaySummaryStore,
    useZoneProjectionStore,
    useSwingStore,
    useSwingFlairStore,
    useRenderingStore,
    useEffectsStore,
  } = rootStore;
  const playsMetaData = playData.metaData;
  let playId = playData.playId;
  let { frames } = playTracking.skeletalData;
  let glStatsVisible = useGlStatsStore(($: GlStatesStore) => $.glStatsVisible);
  let glRenderStatsVisible = useGlStatsStore(
    ($: GlStatesStore) => $.glRenderStatsVisible
  );
  let shadowsEnabled = useLightingStore(($: LightingStore) => $.shadowsEnabled);
  let skyEnabled = useLightingStore(($: LightingStore) => $.skyEnabled);
  let zoneProjectionVisible = useZoneProjectionStore(
    ($: ZoneProjectionStore) => $.zoneProjectionVisible
  );

  let { time } = timebase;
  let offsetTime = time + trackingTimeOffset;
  let { position: cameraPosition } = toCamera(view);
  let battingTeamId = playsMetaData.battingTeam.id;
  let fieldingTeamId = playsMetaData.fieldingTeam.id;
  let homeTeamId = playsMetaData.homeTeam.id;
  let batSide = playsMetaData.stat.play.details.batSide.code;
  let localCameraRef = useRef();

  const positionMap = playData.positionMap;

  let { recorder, renderer, setRenderer, setScene } = useRenderingStore();

  cameraRef = cameraRef || localCameraRef;

  let {
    strobesEnabled,
    setStrobeInTime,
    setStrobeOutTime,
    setStrobeAnchorTime,
  } = useFigureStrobeStore();

  // reset strobe times when the play first loads
  useEffect(() => {
    let time = toEventTime(playData, BALL_WAS_PITCHED_EVENT);

    setStrobeAnchorTime(time);
    setStrobeInTime(-1);
    setStrobeOutTime(-1);
  }, [playData, setStrobeAnchorTime, setStrobeInTime, setStrobeOutTime]);

  let frame = useMemo(() => {
    if (!playTracking) {
      // TODOHI  create a usable fallback?
      return [{ players: [], time: 0 }, []];
    }

    traceTimes &&
      console.log("--- timebase.relativeTime", timebase.relativeTime);
    let frame = timeToFrame({ time, playTracking });

    traceTimes && console.log("time", time);
    traceTimes && console.log("frame", frame);

    // TODO: work out why this function is getting called when the
    //       result is already cached for the current dependencies
    //       i.e. why does this keep incrementing after loop
    return frame;
  }, [playTracking, time, timebase.relativeTime]);

  let inZoneVisiblePeriod = timebase.relativeTime <= 5000;

  let zone = useMemo(() => {
    if (!inZoneVisiblePeriod) {
      return null;
    }

    let pitchData = playData?.metaData?.stat?.play?.pitchData;

    return pitchData
      ? {
          top: pitchData.strikeZoneTop,
          bottom: pitchData.strikeZoneBottom,
        }
      : { top: 4.5, bottom: 2.5 };
  }, [playData, inZoneVisiblePeriod]);

  let fieldGroup = useMemo(
    () => (
      <FieldGroup
        venueId={venueId}
        rootStore={rootStore}
        shadows={shadowsEnabled}
        zone={zone}
      />
    ),
    [venueId, shadowsEnabled, zone, rootStore]
  );

  let trackingGroup = useMemo(() => {
    let handleClick = (id: any) => {
      onClick && onClick(id);
    };

    return (
      <TrackingGroup
        rootStore={rootStore}
        frame={frame}
        skeleton={skeleton}
        onClick={handleClick}
        positionMap={positionMap}
        battingTeamId={battingTeamId}
        fieldingTeamId={fieldingTeamId}
        homeTeamId={homeTeamId}
        batSide={batSide}
      />
    );
  }, [
    rootStore,
    frame,
    skeleton,
    onClick,
    positionMap,
    homeTeamId,
    battingTeamId,
    fieldingTeamId,
    batSide,
  ]);

  let playSummary = useMemo(
    () => (
      <PlaySummary
        playData={playData}
        playTracking={playTracking}
        time={time}
        usePlaySummaryStore={usePlaySummaryStore}
      />
    ),
    [playData, playTracking, time, usePlaySummaryStore]
  );

  let figureStrobes = strobesEnabled && (
    // @ts-ignore
    <Strobes
      time={time}
      rootStore={rootStore}
      playData={playData}
      playTracking={playTracking}
      skeleton={skeleton}
    />
  );

  let figurePathGroup = useMemo(() => {
    return (
      <FigurePathGroup
        playData={playData}
        useFigurePositionStore={useFigurePositionStore}
      />
    );
  }, [playData, useFigurePositionStore]);

  // TODO    drop memoizations here that dep on offsetTime since it typically changes constantly?
  let figurePositionGroup = useMemo(() => {
    return (
      <FigurePositionGroup
        time={offsetTime}
        playData={playData}
        useFigurePositionStore={useFigurePositionStore}
      />
    );
  }, [playData, offsetTime, useFigurePositionStore]);

  // get ball positions from merged positional data in playData.targetPositions
  let mpdBallPositions = useMemo(() => {
    let ballTarget = _find(playData.targetPositions, { typ: BALL_TYP });
    let positions = ballTarget ? ballTarget.positions : [];

    return toMPDTimeSeries(positions);
  }, [playData]);

  let ballSegments = useMemo(
    () => playData?.ballSegments?.genericSegments || [],
    [playData]
  );

  const matrix = useCameraStore(($: CameraStore) => $.cameraMatrix);
  let camera = useMemo(
    () => (
      <Camera
        fov={50}
        position={cameraPosition}
        storedMatrix={matrix}
        cameraRef={cameraRef}
        initialCameraMatrixArray={initialCameraMatrixArray}
      />
    ),
    [cameraPosition, matrix, initialCameraMatrixArray, cameraRef]
  );

  let controls = useMemo(() => {
    return (
      <CameraControls
        rootStore={rootStore}
        time={time}
        playData={playData}
        playTracking={playTracking}
        positionId={10}
      />
    );
  }, [playData, playTracking, time, rootStore]);

  // TODOHI  move to time-util?
  let releaseTime = toReleaseTime({ playData });
  let playRelativeTimeSeconds = (time - releaseTime) / 1000;

  let zoneProjectionGroup = useMemo(() => {
    let isVisible = timebase.relativeTime <= 3400;

    if (!isVisible) {
      return null;
    }

    if (frame.positions === undefined) {
      return null;
    }

    let figure = toBatter(frame);

    return (
      zoneProjectionVisible && (
        <ZoneProjectionGroup playData={playData} figure={figure} />
      )
    );
  }, [playData, frame, zoneProjectionVisible, timebase.relativeTime]);

  return (
    <Canvas
      shadows={shadowsEnabled}
      gl={{ preserveDrawingBuffer: true, logarithmicDepthBuffer: true }}
      onCreated={({ gl, scene }) => {
        // @ts-ignore
        gl.antiAlias = true;
        setRenderer(gl);
        setScene(scene);
      }}
      style={{ /*background: '#272727',*/ touchAction: "none" }}
      // @ts-ignore
      pixelRatio={window.devicePixelRatio}
    >
      <Recorder renderer={renderer} recorder={recorder} />
      {skyEnabled && <Sky />}

      <TimebaseDriver timebase={timebase} />

      {glStatsVisible && <Stats />}
      {glRenderStatsVisible && <GLStats />}
      {camera}
      {controls}

      <Lights useLightingStore={useLightingStore} shadows={shadowsEnabled} />

      <Effects useEffectsStore={useEffectsStore} />

      <StatcastGroup rootStore={rootStore}>
        {fieldGroup}
        {trackingGroup}
        {figureStrobes}
        {playSummary}
        {figurePathGroup}
        {figurePositionGroup}

        <BallTrackingGroup
          key={playId}
          time={offsetTime}
          playRelativeTimeSeconds={playRelativeTimeSeconds}
          mpdBallPositions={mpdBallPositions}
          useBallStore={useBallStore}
          segments={ballSegments}
        />

        {zoneProjectionGroup}

        <MarksGroup useMarksStore={useMarksStore} />

        <SwingTrack
          useSwingStore={useSwingStore}
          playData={playData}
          playTracking={playTracking}
          frame={frame}
        />

        <SwingFlair
          playData={playData}
          frames={frames}
          time={frame.time}
          useSwingFlairStore={useSwingFlairStore}
          useSwingStore={useSwingStore}
        />
      </StatcastGroup>
    </Canvas>
  );
};
