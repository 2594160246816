import _filter from "lodash/filter";
import { toShapeFromSVGPath } from "../util/svg-util";

export const toVenuePathShapes = ({ venueId }) => {
  let pathRecs = _filter(venuePaths, { venueId });

  return pathRecs.map((pathRec) => {
    let { path } = pathRec;
    let shape = toShapeFromSVGPath({ pathStr: path });

    return { ...pathRec, shape };
  });
};

const venuePaths = [
  // //---------------------------------------------------------
  //
  // {
  //     venueId: xxx, //
  //     name: 'Outfield_1_',
  //     type: 'sand',
  //     path: 'ddd',
  //     translate: [0, 0, -.2],
  // },
  // {
  //     venueId: xxx,
  //     name: 'grass_1_',
  //     type: 'grass',
  //     path: 'ddd',
  //     translate: [0, 0, -.06],
  // },
  // {
  //     venueId: xxx,
  //     name: 'infield_sand_1_',
  //     type: 'sand',
  //     path: 'ddd',
  //     translate: [80, 112, 0],
  //     scale: [1, 1, 1],
  // },
  //
  // //---------------------------------------------------------

  {
    venueId: 5, // CLE
    name: "Outfield_1_",
    type: "sand",
    path:
      "M176.2,96.3 C176.2,96.3 163.1,112.6 148.1,135 C133.1,157.4 119.3,179.3 118.4,180.3 C118.4,180.3 110.5,191.2 98.3,191.2 C86.1,191.2 77.2,180.8 77.2,180.8 L26.9,104.7 L0.3,77.1 L1.6,74.6 L2.7,72.4 L3.9,70.2 L5.1,67.9 L6.3,65.6 L7.5,63.3 L8.7,60.9 L9.9,58.5 L11.2,56.1 L12.5,53.6 L13.8,51.1 L15.1,48.5 L16.9,46.5 L19,45.1 L21.1,43.7 L23.2,42.3 L25.4,40.9 L27.6,39.5 L29.8,38.1 L32,36.6 L34,35 L36.2,33.5 L38.4,32 L40.6,30.5 L42.8,29 L45.1,27.5 L47.4,26 L49.7,24.5 L52,23 L54.3,21.4 L56.7,19.8 L59.1,18.2 L61.5,16.6 L64,15 L66.5,13.3 L69,11.6 L71.6,9.9 L74.2,8.2 L76.9,6.4 L79.6,4.6 L82.4,2.8 L85.2,0.9 L88.1,0.4 L91.1,1.5 L94.1,2.6 L97,3.7 L99.9,4.8 L102.8,5.9 L105.6,7 L108.4,8 L111.2,9 L113.9,10 L116.6,11 L119.3,12 L122,13 L124.6,14 L127.2,15 L129.8,16 L132.4,17 L135,18 L137.6,19 L140.2,20 L142.8,21 L145.4,22 L147.9,23 L150.4,24 L152.9,25 L155.4,26 L157.9,27 L160.5,28 L162.5,30.2 L164.2,32.8 L165.9,35.3 L167.6,37.8 L169.2,40.2 L170.8,42.6 L172.4,45 L174,47.3 L175.5,49.6 L177,51.9 L178.5,54.1 L180,56.3 L181.5,58.5 L182.9,60.6 L184.3,62.7 L185.7,64.8 L187.1,66.9 L188.5,69 L189.8,71.1 L191.1,73.1 L192.4,75.1 L194.3,78.1 L176.2,96.3 Z",

    translate: [22, 7, -0.2],
  },
  {
    venueId: 5,
    name: "grass_1_",
    type: "grass",
    path:
      "M98.7,185 C88.3,185 81.2,177.3 81.2,177.3 L31.8,102.2 L9.8,75.8 L21.6,53.2 L22,52.7 L87.7,8.8 L155.9,34.4 L156.2,34.7 L184.2,78.2 L172.4,89.4 C152.4,118.9 114.9,175.5 114.9,175.5 C114.5,176 108.5,185 98.7,185 Z",
    translate: [22, 7, -0.06],
  },
  {
    venueId: 5,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M135,132.8 C134.8,133 129,104.7 97.9,104.7 C64.5,104.7 58.9,130.2 58.4,132.8 L58,133.2 L72.8,148 L73.5,147.3 L91.7,165.2 C91,166.2 90.6,167.4 90.6,168.8 C90.6,170 91,171.2 91.6,172.2 C91.6,172.2 91.6,172.2 91.6,172.3 C92.7,174.1 94.7,175.3 97,175.3 C100.5,175.3 103.4,172.4 103.4,168.9 C103.4,167.4 102.9,166.1 102.1,165 L120.8,147.3 L122.3,147.8 L135.8,134.3 L135,132.8 Z M100.6,163.7 C99.6,163 98.3,162.5 96.9,162.5 C95.4,162.5 94.1,163 93,163.8 L75.4,145.6 C75.4,145.6 81.6,140 78.1,135.4 L95.5,120.9 C96,120.6 96.6,120.5 97.3,120.5 C98.2,120.5 99,121 99.6,121.6 C99.6,121.6 99.7,121.6 99.7,121.7 L115.9,137.2 C115.4,138.2 116,143.5 118.9,146.1 L100.6,163.7 Z",
    translate: [22, 8, 0],
  },

  {
    venueId: 15, // ARI
    name: "Outfield_1_",
    type: "sand",
    path:
      "M196.2,72 L195.7,73.8 L194.3,76 L194.3,83.4 L193.5,83.4 L175.7,102 L152.9,141.3 C152.9,141.3 111.8,187.2 107.2,189.4 C105.6,190.4 104.2,192.3 98.4,192.3 C93.3,192.3 90.4,190.4 88.5,189.4 C84.3,187.3 45.3,142.4 45.3,142.4 L21.5,102.1 L3,82.7 L1,82.7 L0.9,73.1 L0.9,69.7 L0.9,66.1 L0.9,62.3 L0.8,58.4 L0.7,54.3 L3.6,53.6 L6.9,53.6 L8.9,52 L10.8,50.3 L12.7,48.6 L14.6,46.8 L16.6,45 L18.6,43.2 L20.6,41.4 L22.6,39.6 L24.6,37.8 L26.6,36 L28.6,34.2 L30.7,32.3 L32.8,30.4 L34.9,28.5 L37,26.6 L39.2,24.7 L41.4,22.7 L43.6,20.7 L45.8,18.7 L48.1,16.7 L50.4,14.6 L52.7,12.5 L55.1,10.3 L57.5,8.1 L60,5.9 L62.5,3.6 L65.2,1.9 L68.1,1.3 L71.1,0.8 L74.1,0.8 L77.1,0.8 L80.1,0.8 L83.1,0.8 L86.1,0.8 L89.1,0.8 L92.1,0.8 L95.1,1.6 L98,1.6 L100.9,1.6 L103.9,0.8 L106.9,0.8 L109.9,0.8 L112.9,0.8 L115.9,0.8 L118.9,0.8 L121.9,0.8 L124.9,0.8 L127.7,1.9 L130.7,1.9 L133.4,3.8 L135.9,6.1 L138.4,8.3 L140.8,10.5 L143.2,12.6 L145.5,14.7 L147.8,16.8 L150.1,18.8 L152.3,20.8 L154.5,22.8 L156.7,24.8 L158.9,26.7 L161.1,28.4 L163.2,30.3 L165.3,32.2 L167.4,34.1 L169.4,35.9 L171.4,37.7 L173.4,39.5 L175.4,41.3 L177.4,43.1 L179.4,44.9 L181.4,46.7 L183.3,48.5 L185.2,50.2 L187.1,51.9 L189,53.6 L190.9,55.3 L194,55.6 L196.1,57.1 L196.1,61 L196.1,64.8 L196.1,68.4 L196.2,72 Z",
    translate: [21, 7, -0.2],
  },
  {
    venueId: 15,
    name: "grass_1_",
    type: "grass",
    path:
      "M98.7,187.2 C95.6,187.2 92.4,185.6 91.3,185 C87.5,182.1 68.1,160.8 50.8,142.2 L27.9,100.3 L9,81.4 L9,73.1 L9,69.6 L9.8,61.6 L14.1,58 L67.5,10 L71.8,8.9 L91.1,8.9 L105,8.9 L123.4,8.9 L128.2,10 L128.4,10.1 L187.6,63.2 L187.6,79.7 L170.4,98.1 L147.5,137.5 C130.9,156 108.7,182.2 104.7,185.2 C104.7,185.3 102.3,187.2 98.7,187.2 Z",
    translate: [21, 7, -0.06],
  },
  {
    venueId: 15,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M136,132.8 C135.8,133 130,104.7 98.9,104.7 C65.5,104.7 59.9,130.2 59.4,132.8 L59,133.2 L73.8,148 L74.5,147.3 L92.7,165.2 C92,166.2 91.6,167.4 91.6,168.8 C91.6,169.2 91.7,169.7 91.8,170.1 L91.8,170.1 L78.9,172.7 L86.1,180.7 L93.1,173 C94.3,174.4 96,175.4 98.1,175.4 C99.9,175.4 101.6,174.6 102.7,173.4 L109.4,180.8 L116.6,172.8 L104.3,170.3 L104.3,170.3 C104.4,169.9 104.4,169.4 104.4,169 C104.4,167.5 103.9,166.2 103.1,165.1 L121.8,147.4 L123.3,147.9 L136.8,134.4 L136,132.8 Z M101.6,163.7 C100.6,163 99.3,162.5 97.9,162.5 C96.4,162.5 95.1,163 94,163.8 L76.4,145.6 C76.4,145.6 82.6,140 79.1,135.4 L96.5,120.9 C97,120.6 97.6,120.5 98.3,120.5 C99.2,120.5 100,121 100.6,121.6 C100.6,121.6 100.7,121.6 100.7,121.7 L116.9,137.2 C116.4,138.2 117,143.5 119.9,146.1 L101.6,163.7 Z",
    translate: [21, 8, 0],
  },

  {
    venueId: 3309, // WSH
    name: "Outfield_1_",
    type: "sand",
    path:
      "M85.7,181 L43.6,141.1 L23.5,104.6 L23.2,97.1 L0.3,73.2 L2,70.2 L3.3,68 L4.6,65.8 L5.9,63.6 L7.2,61.4 L8.6,59.1 L10,56.8 L11.4,54.5 L12.8,52.2 L14.2,49.8 L15.6,47.4 L17.1,44.9 L18.6,42.4 L20.1,39.9 L21.9,37.8 L24.2,36.5 L26,34.2 L28.4,32.9 L30.8,31.6 L33.2,30.3 L35.6,29 L38,27.7 L40.4,26.4 L42.8,25.1 L45.2,23.8 L47.6,22.5 L50,21.2 L52.4,19.9 L54.9,18.5 L57.4,17.1 L59.9,15.7 L62.5,14.6 L65.7,16 L68.2,14.8 L70.7,13.4 L73.3,12 L75.9,10.6 L78.5,9.2 L81.2,7.7 L83.9,6.2 L86.6,4.7 L89.4,3.2 L92.2,1.6 L95.1,0 L98.1,0.6 L101,1.9 L103.9,3.2 L106.8,4.5 L109.6,5.7 L112.4,6.9 L115.1,8.1 L117.8,9.3 L120.5,10.5 L123.2,11.7 L125.8,12.9 L128.4,14 L131,15 L133.6,16.1 L136.1,17.2 L138.6,18.3 L141.1,19.4 L143.6,20.5 L146.1,21.6 L148.6,22.7 L151.1,23.8 L153.6,24.9 L156.1,26 L158.5,27.1 L160.9,28.2 L163.3,29.3 L165.7,30.4 L168.1,31.5 L170.6,32.6 L173.1,33.7 L175.6,34.8 L178.1,35.9 L180.6,37 L182.3,39.4 L183.8,42.1 L185.2,44.7 L186.6,47.3 L188,49.8 L189.4,52.3 L190.7,54.7 L192,57.1 L193.3,59.5 L194.6,61.8 L195.9,64.1 L197.2,66.4 L198.4,68.6 L199.6,70.8 L201.6,74 L176,100.4 L177.3,109 C177.3,109 160.5,137.4 159.6,139 L116.6,182.2 C114.4,184.1 101,196 85.7,181 Z",
    translate: [18, 8, -0.2],
  },
  {
    venueId: 3309,
    name: "grass_1_",
    type: "grass",
    path:
      "M101.7,182.9 C99.2,182.9 95.7,181.7 93,179.1 L92.8,178.9 L49,139 L29.2,103.5 L28.5,91.2 L9.7,73.1 L26.7,44.7 L27.1,44.2 L29.7,42.8 L31.5,40.5 L62.5,23.4 L65.7,24.8 L71.7,21.9 L96.1,8.5 L175.1,43.4 L175.3,43.6 L191.4,72.6 L171.4,92.4 L170.6,105 C165.2,117.1 158.2,128.3 155.8,133.3 L110.8,177.9 C110.2,178.3 109.7,178.7 109.4,179 C106.4,181.5 104.4,182.9 101.7,182.9 Z",
    translate: [18, 8, -0.06],
  },
  {
    venueId: 3309,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M139,131.8 C138.8,132 133,103.7 101.9,103.7 C68.5,103.7 62.9,129.2 62.4,131.8 L62,132.2 L76.8,147 L77.5,146.3 L95.7,164.2 C95,165.2 94.6,166.4 94.6,167.8 C94.6,169 95,170.2 95.6,171.2 C95.6,171.2 95.6,171.2 95.6,171.3 C96.7,173.1 98.7,174.3 101,174.3 C104.5,174.3 107.4,171.4 107.4,167.9 C107.4,166.4 106.9,165.1 106.1,164 L124.8,146.3 L126.3,146.8 L139.8,133.3 L139,131.8 Z M104.6,162.7 C103.6,162 102.3,161.5 100.9,161.5 C99.4,161.5 98.1,162 97,162.8 L79.4,144.6 C79.4,144.6 85.6,139 82.1,134.4 L99.5,119.9 C100,119.6 100.6,119.5 101.3,119.5 C102.2,119.5 103,120 103.6,120.6 C103.6,120.6 103.7,120.6 103.7,120.7 L119.9,136.2 C119.4,137.2 120,142.5 122.9,145.1 L104.6,162.7 Z",
    translate: [18, 9, 0],
  },

  {
    venueId: 14, // TOR
    name: "Outfield_1_",
    type: "sand",
    path:
      "M179.1,89 C174.3,109.4 153.9,139.2 138.3,159.6 L140.4,161.9 C140.4,161.9 119.4,182 118.2,182.8 L115.5,179.8 C115.5,179.8 108.3,184.9 97.6,184.9 C84.1,184.9 80.6,181 80.6,181 L77.9,183.6 L54.1,162.3 L57.3,159.3 C34.2,134.8 17.3,101.6 13.2,83.2 L0.3,70.5 L1.8,68.6 L3.3,66.7 L4.8,64.8 L6.3,62.8 L7.8,60.8 L9.3,58.8 L10.8,56.8 L12.3,54.8 L13.9,52.8 L15.5,50.7 L17.1,48.6 L18.7,46.5 L20.3,44.4 L21.9,42.2 L23.6,40 L25.3,37.8 L27,35.5 L28.7,33.2 L30.5,30.9 L32.3,28.5 L34.1,26.1 L36,23.6 L38.1,21.6 L40.4,20 L42.7,18.4 L45.1,16.8 L47.5,15.1 L49.9,13.4 L52.3,11.7 L54.8,10 L57.3,8.3 L59.8,6.5 L62.5,5.6 L65.3,4.8 L68.1,4 L70.9,3.2 L73.7,2.4 L76.6,1.9 L79.5,1.5 L82.4,1.1 L85.3,0.7 L88.2,0.3 L91.1,0.1 L94,0.1 L96.9,0.1 L99.8,0.1 L102.7,0.1 L105.6,0.3 L108.5,0.7 L111.4,1.1 L114.3,1.5 L117.2,1.9 L120.1,2.4 L122.9,3.2 L125.7,4 L128.5,4.8 L131.3,5.6 L134,6.5 L136.5,8.2 L139,9.9 L141.5,11.6 L143.9,13.3 L146.3,15 L148.7,16.6 L151.1,18.2 L153.4,19.8 L155.7,21.4 L157.8,23.4 L159.7,25.9 L161.5,28.3 L163.3,30.7 L165.1,33 L166.9,35.3 L168.6,37.6 L170.3,39.8 L172,42 L173.6,44.2 L175.2,46.3 L176.8,48.4 L178.4,50.5 L180,52.6 L181.6,54.6 L183.1,56.6 L184.6,58.6 L186.1,60.6 L187.6,62.6 L189.1,64.6 L190.6,66.5 L192.1,68.4 L193.6,70.3 L193.7,70.3 C193.9,71.1 181,81.1 179.1,89 Z",
    translate: [22, 10, -0.2],
  },
  {
    venueId: 14,
    name: "grass_1_",
    type: "grass",
    path:
      "M55.3,149.3 C39.3,128.5 25.7,109.2 21.9,91.9 L18.1,81.6 L9.5,71.9 L42.1,27.2 L43.3,26.1 L63.6,12 L65,11.5 L75.6,8.6 L77.9,8.2 L89,6.7 L91.4,6.6 L102.6,6.6 L105,6.7 L116.1,8.1 L118.4,8.5 L129,11.4 L130.4,11.9 L150.7,25.9 L151.9,27 L184.1,71.2 C182.7,73.9 175.9,78.4 174.8,82.9 C174.4,84.8 173.9,86.8 173.4,88.9 C168.8,108.6 134.4,154.2 134.4,154.2 C134.4,154.2 115.9,180 97,180 C78.4,180 55.3,149.3 55.3,149.3 Z",
    translate: [22, 10, -0.06],
  },
  {
    venueId: 14,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M135,129.8 C134.8,130 129,101.7 97.9,101.7 C64.5,101.7 58.9,127.2 58.4,129.8 L58,130.2 L72.8,145 L73.5,144.3 L91.7,162.2 C91,163.2 90.6,164.4 90.6,165.8 C90.6,167 91,168.2 91.6,169.2 C91.6,169.2 91.6,169.2 91.6,169.3 C92.7,171.1 94.7,172.3 97,172.3 C100.5,172.3 103.4,169.4 103.4,165.9 C103.4,164.4 102.9,163.1 102.1,162 L120.8,144.3 L122.3,144.8 L135.8,131.3 L135,129.8 Z M100.6,160.7 C99.6,160 98.3,159.5 96.9,159.5 C95.4,159.5 94.1,160 93,160.8 L75.4,142.6 C75.4,142.6 81.6,137 78.1,132.4 L95.5,117.9 C96,117.6 96.6,117.5 97.3,117.5 C98.2,117.5 99,118 99.6,118.6 C99.6,118.6 99.7,118.6 99.7,118.7 L115.9,134.2 C115.4,135.2 116,140.5 118.9,143.1 L100.6,160.7 Z",
    translate: [22, 11, 0],
  },

  {
    venueId: 13, // TEX
    name: "Outfield_1_",
    type: "sand",
    path:
      "M192.4,72.7 L191.6,70.1 L190.7,67.5 L189.8,64.8 L188.9,62.1 L188,59.3 L187.1,56.5 L186.1,53.6 L185.1,50.7 L184.1,47.7 L183.1,44.6 L181.4,42.4 L179.1,41.1 L176.7,39.8 L174.4,38.5 L172.1,37.2 L169.8,35.9 L167.5,34.6 L165.1,33.4 L162.8,32.1 L160.5,30.8 L158.1,29.5 L155.8,28.2 L153.4,26.9 L151,25.6 L148.6,24.3 L146.2,23 L143.8,21.7 L141.4,20.4 L140.5,13.7 L137.9,12.3 L135.4,10.8 L132.8,9.4 L130.2,7.9 L127.5,6.4 L124.8,4.9 L122.1,3.4 L119.3,1.9 L116.5,0.3 L113.6,0.2 L110.6,0.2 L107.7,0.2 L104.7,0.2 L101.8,0.2 L98.9,0.2 L96,0.2 L93,0.2 L90.1,0.2 L87.2,0.2 L84.2,0.2 L81.3,0.2 L78.3,0.2 L75.4,0.4 L72.8,3.1 L70.2,5.7 L67.8,8.3 L65.4,10.8 L63,13.2 L60.7,15.6 L58.4,17.9 L56.2,20.1 L54,22.1 L51,22 L48,21.6 L45,21.2 L42,20.8 L39.6,22.4 L37.5,24.6 L35.4,26.8 L33.4,28.9 L31.4,31 L29.4,33 L27.5,35 L25.6,37 L23.7,39 L21.8,40.9 L20,42.8 L18.2,44.7 L16.4,46.6 L14.6,48.5 L12.8,50.4 L11,52.3 L9.2,54 L7.5,55.9 L6.5,58.6 L5.4,61.2 L4.4,63.8 L3.4,66.3 L2.4,68.7 L1.4,71.1 L0.4,73.5 L0.4,76.2 L27.5,103.6 C29.2,108.2 28.5,114.8 32.9,122.4 C40,134.5 62.7,164 62.7,164 C62.7,164 76,179.9 77,181 C77.9,182.1 87.9,188.6 95.9,188.6 C102.2,188.6 111.9,183.1 111.9,183.1 C111.9,183.1 142.6,151.1 149.7,139 C154.7,130.4 157.8,116.2 161.5,107.1 L192.4,76.1 L192.4,72.7 Z",
    translate: [20, 12, -0.2],
  },
  {
    venueId: 13,
    name: "grass_1_",
    type: "grass",
    path:
      "M95.3,182.4 C90.7,182.4 81.4,177.2 81.4,177.1 C79.5,175 77.4,173 68.4,162.2 C65.5,158.5 45,132.5 38.7,121.7 C34.6,114.7 37.7,108.3 36,103.7 L35.4,102.1 L8.7,75.4 L10.8,69.3 L11.8,66.7 L12.8,64.2 L13.9,61.5 L14.4,60.1 L15.1,59.3 L18.5,55.7 L22.1,52 L27.7,46.2 L29.5,44.4 L35.3,38.3 L37.2,36.3 L44.2,29 L47.1,29.4 L56.6,30.6 L61.7,25.9 L66.3,21.2 L71,16.3 L73.5,13.8 L75.9,11.3 L78.4,8.7 L78.9,8.2 L113.7,8.2 L114.5,8.2 L115.7,8.9 L118.4,10.4 L121.1,11.9 L126.4,14.9 L129,16.4 L131.6,17.8 L133.2,18.7 L134.1,25.5 L140.1,28.8 L142.5,30.2 L144.8,31.4 L147.2,32.7 L151.9,35.2 L154.3,36.6 L156.7,37.9 L161.4,40.5 L163.7,41.7 L172.9,46.8 L175.3,48.1 L178,49.6 L185.9,74 L155.7,103.6 L155.1,105.1 C148.8,120.4 148.2,130.3 144.7,136.3 C138.7,146.5 108.2,179.3 108.2,179.3 C108.2,179.3 100.8,182.4 95.3,182.4 Z",
    translate: [20, 12, -0.06],
  },
  {
    venueId: 13,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M134,131.8 C133.8,132 128,103.7 96.9,103.7 C63.5,103.7 57.9,129.2 57.4,131.8 L57,132.2 L71.8,147 L72.5,146.3 L90.7,164.2 C90,165.2 89.6,166.4 89.6,167.8 C89.6,169 90,170.2 90.6,171.2 C90.6,171.2 90.6,171.2 90.6,171.3 C91.7,173.1 93.7,174.3 96,174.3 C99.5,174.3 102.4,171.4 102.4,167.9 C102.4,166.4 101.9,165.1 101.1,164 L119.8,146.3 L121.3,146.8 L134.8,133.3 L134,131.8 Z M99.6,162.7 C98.6,162 97.3,161.5 95.9,161.5 C94.4,161.5 93.1,162 92,162.8 L74.4,144.6 C74.4,144.6 80.6,139 77.1,134.4 L94.5,119.9 C95,119.6 95.6,119.5 96.3,119.5 C97.2,119.5 98,120 98.6,120.6 C98.6,120.6 98.7,120.6 98.7,120.7 L114.9,136.2 C114.4,137.2 115,142.5 117.9,145.1 L99.6,162.7 Z",
    translate: [22.5, 9, 0],
  },

  // 5325 paths created by tracing overhead photo in Sketch
  // photo here: https://www.bigcountryhomepage.com/sports/texas-rangers-reveal-dimensions-for-globe-life-field/
  {
    venueId: 5325, // TEX, Globe Life
    name: "outfield",
    type: "sand",
    path:
      "M140,617 L105,346 L129,254 L80,95 L167,1 L360,1 L376,-11 L500,-11 L666,87 L698,207 L725,220 L775,417 L753,492 L647,518 L639,532 L599,574 L350,672 L343,678 L291,700 C271,701.333333 251.333333,702 232,702 C220,697.333333 198.333333,685.333333 167,666 L140,617 Z",
    rotate: (-1.3 * Math.PI) / 8,
    // scale: [.25, .25, 1],
    // translate: [-10, 70.5, -.2],
    scale: [0.257, 0.257, 1],
    translate: [-14, 72, -0.2],
  },
  {
    venueId: 5325,
    name: "grass_1_",
    type: "grass",
    path:
      "M162,617 C155.333333,571 142.666667,483.666667 124,355 L150,249 L113,108 L181,28 L371,22 L384,9 L493,6 L642,89 L673,213 L699,222 L745,399 L718,478 L622,507 L584,549 L289,681 C270.333333,684.333333 254,685 240,683 C219,680 194,663 184,653 C174,643 168,631 162,617 Z",
    rotate: (-1.3 * Math.PI) / 8,
    // scale: [.25, .25, 1],
    // translate: [-10, 70.5, -.06],
    scale: [0.257, 0.257, 1],
    translate: [-14, 72, -0.06],
  },
  {
    venueId: 5325,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M155,0.999914591 C187,0.999914591 227,11 255,34 C283,57 296,86 308,119 C277.333333,151 232.666667,197.666667 174,259 C176.666667,262.333333 178,267 178,273 C178,282 169,294.999915 155,294.999915 C141,294.999915 130,283 130,273 C130,266.333333 131.333333,261.666667 134,259 C82,205.666667 37.6666667,159 1,119 C16,80 28,58 56,34 C84,10 123,0.999914591 155,0.999914591 Z M167,70 C165,72 163,75 156,75 C151.333333,75 147.333333,73.3333333 144,70 L144,70 L68,150 C70.6666667,152.666667 72,155.666667 72,159 C72,162.333333 70.6666667,165.666667 68,169 L68,169 L144,250 C148,248 151.666667,247 155,247 C158.333333,247 161.666667,247.666667 165,249 L165,249 L240,169 C237.333333,166.333333 236,163 236,159 C236,155 237.333333,152 240,150 C225.333333,134 201,107.333333 167,70 Z",
    // rotate: 0,
    // scale: [.25, .25, 1],
    // translate: [80, 110, 0],
    scale: [0.245, 0.245, 1],
    translate: [81, 112, 0],
  },

  // Cate's revised 5325 svg paths (10/9/20) – incorrectly parsing in svg-utils?
  // {
  //     venueId: 5325, // TEX, Globe Life
  //     name: 'outfield',
  //     type: 'sand',
  //     path:
  //         // 'M0.9,73.3 L1.4,70.4 L2,67.4 L2.6,64.3 L3.2,61.2 L3.8,58 L4.5,54.8 L5.1,51.4 L5.8,47.9 L6.5,44.3 L8.8,42.8 L11.2,41.5 L13.6,40.1 L15.9,38.8 L18.3,37.4 L20.6,36 L23,34.7 L25.3,33.4 L27.7,32 L30,30.6 L32.4,29.3 L34.8,27.9 L37.2,26.5 L39.6,25.2 L41.9,23.8 L44.3,22.4 L46.8,21 L49,18.7 L50.8,15.2 L53.3,13.7 L55.9,12.3 L58.5,10.8 L61.1,9.3 L63.7,7.8 L66.4,6.2 L69.1,4.7 L71.8,3.1 L74.5,1.5 L77.4,-7.10542736e-15 L80.4,-7.10542736e-15 L83.4,-7.10542736e-15 L86.4,0.1 L89.4,0.1 L92.4,0.1 L95.4,0.1 L98.4,0.1 L101.3,0.1 L104.3,0.1 L107.3,0.1 L110.3,0.1 L113.3,0.1 L116.3,0.1 L119.3,0.1 L122.3,0.2 L125.3,0.3 L127.9,2.9 L130.4,5.5 L132.9,8 L135.4,10.4 L137.7,12.8 L140.1,15.1 L142.3,17.3 L144.6,19.6 L146.7,21.8 L149.5,22.2 L152.6,21.6 L155.4,22 L157.6,24.2 L159.7,26.3 L161.7,28.3 L163.8,30.4 L165.8,32.4 L167.8,34.4 L169.3,37.4 L171.2,39.3 L173.1,41.2 L175,43.1 L176.9,45 L178.8,46.9 L180.6,48.7 L182.5,50.6 L184.3,52.4 L186.1,54.2 L188,56 L189.8,57.8 L190.8,60.4 L191.5,63.6 L192.1,66.6 L192.6,69.6 L193.2,72.5 L193.7,75.3 C177.9,91.7666667 167.8,102.2 163.4,106.6 C160,116 156.6,129.9 151.6,138.5 C144.5,150.6 113.8,182.6 113.8,182.6 C113.8,182.6 104.1,188.1 97.8,188.1 C89.8,188.1 79.8,181.6 78.9,180.5 C77.9,179.4 64.6,163.5 64.6,163.5 C64.6,163.5 42.2,134.1 35.1,122 C30.7,114.4 31.4,107.8 29.7,103.2 L0.9,73.3 Z',
  //
  //     // 'M27.9,107.3L27.9,107.3l0.5-2.9l0.6-3l0.6-3.1l0.6-3.1l0.6-3.2l0.7-3.2l0.6-3.4l0.7-3.5l0.7-3.6l2.3-1.5    l2.4-1.3l2.4-1.4l2.3-1.3l2.4-1.4l2.3-1.4l2.4-1.3l2.3-1.3l2.4-1.4l2.3-1.4l2.4-1.3l2.4-1.4l2.4-1.4l2.4-1.3l2.3-1.4l2.4-1.4    l2.5-1.4l2.2-2.3l1.8-3.5l2.5-1.5l2.6-1.4l2.6-1.5l2.6-1.5l2.6-1.5l2.7-1.6l2.7-1.5l2.7-1.6l2.7-1.6l2.9-1.5h3h3l3,0.1h3h3h3h3    h2.9h3l3,0h3h3h3h3l3,0.1l3,0.1l2.6,2.6l2.5,2.6l2.5,2.5l2.5,2.4l2.3,2.4l2.4,2.3l2.2,2.2l2.3,2.3l2.1,2.2l2.8,0.4l3.1-0.6    l2.8,0.4l2.2,2.2l2.1,2.1l2,2l2.1,2.1l2,2l2,2l1.5,3l1.9,1.9l1.9,1.9l1.9,1.9l1.9,1.9l1.9,1.9l1.8,1.8l1.9,1.9l1.8,1.8l1.8,1.8    l1.9,1.8l1.8,1.8l1,2.6l0.7,3.2l0.6,3l0.5,3l0.6,2.9l0.5,2.8l-30.3,31.3c-3.7,9.1-6.8,23.3-11.8,31.9    c-7.1,12.1-37.8,44.1-37.8,44.1s-9.7,5.5-16,5.5c-8,0-18-6.5-18.9-7.6c-1-1.1-14.3-17-14.3-17S69.2,168.1,62.1,156    c-4.4-7.6-3.7-14.2-5.4-18.8L27.9,107.3z',
  //
  //     // separated into individual path commands to try to pinpoint problems
  //         'M27.9,107.3' +
  //         'L27.9,107.3' +
  //         'l0.5-2.9' +
  //         'l0.6-3' +
  //         'l0.6-3.1' +
  //         'l0.6-3.1' +
  //         'l0.6-3.2' +
  //         'l0.7-3.2' +
  //         'l0.6-3.4' +
  //         'l0.7-3.5' +
  //         'l0.7-3.6' +
  //         'l2.3-1.5' +
  //         'l2.4-1.3' +
  //         'l2.4-1.4' +
  //         'l2.3-1.3' +
  //         'l2.4-1.4' +
  //         'l2.3-1.4' +
  //         'l2.4-1.3' +
  //         'l2.3-1.3' +
  //         'l2.4-1.4' +
  //         'l2.3-1.4' +
  //         'l2.4-1.3' +
  //         'l2.4-1.4' +
  //         'l2.4-1.4' +
  //         'l2.4-1.3' +
  //         'l2.3-1.4' +
  //         'l2.4-1.4' +
  //         'l2.5-1.4' +
  //         'l2.2-2.3' +
  //         'l1.8-3.5' +
  //         'l2.5-1.5' +
  //         'l2.6-1.4' +
  //         'l2.6-1.5' +
  //         'l2.6-1.5' +
  //         'l2.6-1.5' +
  //         'l2.7-1.6' +
  //         'l2.7-1.5' +
  //         'l2.7-1.6' +
  //         'l2.7-1.6' +
  //         'l2.9-1.5' +
  //         'h3' +
  //         'h3' +
  //         'l3,0.1' +
  //         'h3' +
  //         'h3' +
  //         'h3' +
  //         'h3' +
  //         'h2.9' +
  //         'h3' +
  //         'l3,0' +
  //         'h3' +
  //         'h3' +
  //         'h3' +
  //         'h3' +
  //         'l3,0.1' +
  //         'l3,0.1' +
  //         'l2.6,2.6' +
  //         'l2.5,2.6' +
  //         'l2.5,2.5' +
  //         'l2.5,2.4' +
  //         'l2.3,2.4' +
  //         'l2.4,2.3' +
  //         'l2.2,2.2' +
  //         'l2.3,2.3' +
  //         'l2.1,2.2' +
  //         'l2.8,0.4' +
  //         'l3.1-0.6' +
  //         'l2.8,0.4' +
  //         'l2.2,2.2' +
  //         'l2.1,2.1' +
  //         'l2,2' +
  //         'l2.1,2.1' +
  //         'l2,2l2,2' +
  //         'l1.5,3' +
  //         'l1.9,1.9' +
  //         'l1.9,1.9' +
  //         'l1.9,1.9' +
  //         'l1.9,1.9' +
  //         'l1.9,1.9' +
  //         'l1.8,1.8' +
  //         'l1.9,1.9' +
  //         'l1.8,1.8' +
  //         'l1.8,1.8' +
  //         'l1.9,1.8' +
  //         'l1.8,1.8' +
  //         'l1,2.6' +
  //         'l0.7,3.2' +
  //         'l0.6,3' +
  //         'l0.5,3' +
  //         'l0.6,2.9' +
  //         'l0.5,2.8' +
  //         'l-30.3,31.3' +
  //         'c-3.7,9.1-6.8,23.3-11.8,31.9' +
  //         'c-7.1,12.1-37.8,44.1-37.8,44.1' +
  //
  //         // problematic s cmd
  //         // 's-9.7,5.5-16,5.5' +
  //         // replacing with line-to cmd didn't help
  //         //'l-9.7,5.5' +
  //
  //         'c-8,0-18-6.5-18.9-7.6' +
  //         'c-1-1.1-14.3-17-14.3-17' +
  //
  //         // problematic S cmd
  //         // 'S69.2,168.1,62.1,156' +
  //         // replacing with Line-to cmd didn't help
  //         // 'L69.2,168.1' +
  //
  //         'c-4.4-7.6-3.7-14.2-5.4-18.8' +
  //         'L27.9,107.3' +
  //         'z',
  //
  //
  //     // // 'M140,617 L105,346 L129,254 L80,95 L167,1 L360,1 L376,-11 L500,-11 L666,87 L698,207 L725,220 L775,417 L753,492 L647,518 L639,532 L599,574 L350,672 L343,678 L291,700 C271,701.333333 251.333333,702 232,702 C220,697.333333 198.333333,685.333333 167,666 L140,617 Z',
  //     rotate: -1.3 * Math.PI / 8,
  //     scale: [.25, .25, 1],
  //     translate: [-10, 70.5, -.2],
  // },
  // {
  //     venueId: 5325,
  //     name: 'grass_1_',
  //     type: 'grass',
  //     path: 'M162,617 C155.333333,571 142.666667,483.666667 124,355 L150,249 L113,108 L181,28 L371,22 L384,9 L493,6 L642,89 L673,213 L699,222 L745,399 L718,478 L622,507 L584,549 L289,681 C270.333333,684.333333 254,685 240,683 C219,680 194,663 184,653 C174,643 168,631 162,617 Z',
  //     rotate: -1.3 * Math.PI / 8,
  //     scale: [.25, .25, 1],
  //     translate: [-10, 70.5, -.06],
  // },
  // {
  //     venueId: 5325,
  //     name: 'infield_sand_1_',
  //     type: 'sand',
  //     path: 'M155,0.999914591 C187,0.999914591 227,11 255,34 C283,57 296,86 308,119 C277.333333,151 232.666667,197.666667 174,259 C176.666667,262.333333 178,267 178,273 C178,282 169,294.999915 155,294.999915 C141,294.999915 130,283 130,273 C130,266.333333 131.333333,261.666667 134,259 C82,205.666667 37.6666667,159 1,119 C16,80 28,58 56,34 C84,10 123,0.999914591 155,0.999914591 Z M167,70 C165,72 163,75 156,75 C151.333333,75 147.333333,73.3333333 144,70 L144,70 L68,150 C70.6666667,152.666667 72,155.666667 72,159 C72,162.333333 70.6666667,165.666667 68,169 L68,169 L144,250 C148,248 151.666667,247 155,247 C158.333333,247 161.666667,247.666667 165,249 L165,249 L240,169 C237.333333,166.333333 236,163 236,159 C236,155 237.333333,152 240,150 C225.333333,134 201,107.333333 167,70 Z',
  //     rotate: 0 * Math.PI / 8,
  //     scale: [.25, .25, 1],
  //     translate: [80, 110, 0],
  // },

  {
    venueId: 12, // TBR
    name: "Outfield_1_",
    type: "sand",
    path:
      "M189.2,76 L181.2,89.3 L183,92 L182.3,98.7 L179.6,104.7 L173.1,111.2 L163.5,127.1 L142.4,148.6 L142.3,156.1 L120.7,177 L118.3,179.2 L113.9,178.9 C113.9,178.9 104.5,187.4 101.5,188.3 C98.5,189.2 96.3,190.2 89.8,188.6 C83.3,187 75.2,178.8 75.2,178.8 L70.4,178.8 L47.6,156.7 L47.8,148.7 L25.2,126.6 L16.2,111.4 L8,102.3 L2.8,78 L1.9,73.8 L1,69.5 L0.5,65.4 L1.6,63 L3.4,61.2 L5.2,59.4 L7,57.6 L8.8,55.8 L10.6,54 L12.4,52.2 L14.2,50.4 L16,48.6 L17.8,46.8 L19.6,44.9 L21.5,43 L23.4,41.1 L25.3,39.2 L27.2,37.3 L29.1,35.3 L31.1,33.3 L33.1,31.3 L35.1,29.3 L37.1,27.2 L39.2,25.1 L41.3,23 L43.4,20.8 L45.6,18.6 L47.8,16.4 L50.1,14.1 L52.4,11.8 L54.9,10.1 L57.7,9.4 L60.1,6.8 L62.2,2.5 L64.8,0.2 L67.9,0.4 L71,0.6 L74,0.8 L77,1 L80,1.2 L83,1.4 L86,1.6 L89,1.8 L91.9,2 L94.8,2.2 L97.7,2.4 L100.6,2.6 L103.5,2.8 L106.4,3 L109.3,3.2 L112.2,3.4 L115.1,3.6 L118,3.8 L120.9,4 L123.8,4.5 L126.6,5.4 L129.4,6.3 L132.2,7.2 L134.6,9.6 L137,12 L139.3,14.3 L141.6,16.6 L143.8,18.8 L146,21 L148.1,23.2 L150.2,25.3 L152.3,27.4 L154.4,29.5 L156.4,31.5 L158.4,33.5 L160.4,35.5 L162.3,37.5 L164.2,39.4 L166.1,41.3 L168,43.2 L169.9,45.1 L171.8,47 L173.6,48.8 L175.4,50.6 L177.2,52.4 L179,54.2 L180.8,56 L182.6,57.8 L184.4,59.6 L186.2,61.4 L188,63.2 L189.8,65 L189.3,69.2 L188.8,73.3 L189.2,76 Z",
    translate: [24, 7, -0.2],
  },
  {
    venueId: 12,
    name: "grass_1_",
    type: "grass",
    path:
      "M77.1,174.7 L72,174.8 L52.2,154.8 L52.8,146.1 L28.6,124.2 L19.6,108.4 L21.6,104.6 L17.8,100.1 C17.8,100.1 16.5,100.5 15.9,100 L10.7,76.7 L9.8,72.1 L9,68.1 L8.9,67 L67.2,8.2 L120.5,11.6 L122.4,11.9 L128.4,13.9 L129.5,15 L180.6,67 L178.1,87.2 L178.6,96.2 L176.2,102 L169,109.1 L161.5,121.6 L136.9,146.1 L136.8,154.6 L115.4,175.7 L113.1,173.6 C113.1,173.6 101.8,185 98.6,185 C95.4,185 98.2,185.2 92.4,185.2 C86.6,185.2 77.1,174.7 77.1,174.7 Z",
    translate: [24, 7, -0.06],
  },
  {
    venueId: 12,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M133,132.8 C132.8,133 127,104.7 95.9,104.7 C62.5,104.7 56.9,130.2 56.4,132.8 L56,133.2 L70.8,148 L71.5,147.3 L89.7,165.2 C89,166.2 88.6,167.4 88.6,168.8 C88.6,170 89,171.2 89.6,172.2 C89.6,172.2 89.6,172.2 89.6,172.3 C90.7,174.1 92.7,175.3 95,175.3 C98.5,175.3 101.4,172.4 101.4,168.9 C101.4,167.4 100.9,166.1 100.1,165 L118.8,147.3 L120.3,147.8 L133.8,134.3 L133,132.8 Z M98.6,163.7 C97.6,163 96.3,162.5 94.9,162.5 C93.4,162.5 92.1,163 91,163.8 L73.4,145.6 C73.4,145.6 79.6,140 76.1,135.4 L93.5,120.9 C94,120.6 94.6,120.5 95.3,120.5 C96.2,120.5 97,121 97.6,121.6 C97.6,121.6 97.7,121.6 97.7,121.7 L113.9,137.2 C113.4,138.2 114,143.5 116.9,146.1 L98.6,163.7 Z",
    translate: [24, 8, 0],
  },

  {
    venueId: 2889, // STL
    name: "Outfield_1_",
    type: "sand",
    path:
      "M198.8,71.4 L184.6,85.6 L184.2,92.5 L154.3,144.5 C154.3,144.5 136,162.9 130.1,169.5 C125.7,174.9 114.5,188 100.6,188 C88.2,188 80.1,179.8 71.2,169.5 L37,135.3 L28.6,98.3 L3.2,72 L1.3,68.4 L0.3,63.8 L0.3,60.2 L2.1,58.4 L3.9,56.6 L5.7,54.8 L7.5,53 L9.3,51.2 L11.1,49.4 L12.9,47.6 L14.8,45.7 L16.7,43.8 L18.6,41.9 L20.5,40 L22.4,38.1 L24.3,36.2 L26.2,34.3 L28.2,32.3 L30.2,30.3 L32.2,28.3 L34.2,26.3 L36.3,24.2 L38.4,22.1 L40.5,20 L42.6,17.9 L44.8,15.7 L47.1,13.7 L49.7,12.7 L52.4,11.8 L55.1,10.9 L57.8,10 L60.5,9.1 L63.2,8.2 L65.9,7.3 L68.6,6.4 L71.3,5.5 L74.1,4.6 L76.9,3.7 L79.7,2.8 L82.5,1.9 L85.3,1 L88.2,0.9 L91.1,0.9 L94,0.9 L96.9,0.9 L99.8,0.9 L102.7,0.9 L105.6,0.9 L108.5,0.9 L111.4,0.9 L114.3,0.9 L117.1,1.8 L119.9,2.7 L122.7,3.6 L125.5,4.5 L128.3,5.4 L131,6.3 L133.7,7.2 L136.4,8.1 L139.1,9 L141.8,9.9 L144.5,10.8 L147.2,11.7 L149.9,12.6 L152.6,13.6 L154.9,15.5 L157.1,17.7 L159.2,19.8 L161.3,21.9 L163.4,24 L165.5,26.1 L167.5,28.1 L169.5,30.1 L171.5,32.1 L173.5,34.1 L175.4,36 L177.3,37.9 L179.2,39.8 L181.1,41.7 L183,43.6 L184.9,45.5 L186.8,47.4 L188.6,49.2 L190.4,51 L192.2,52.8 L194,54.6 L195.8,56.4 L196.9,59 L197.4,62.2 L197.8,65.3 L198.2,68.4 L198.8,71.4 Z",
    translate: [19, 10, -0.2],
  },
  {
    venueId: 2889,
    name: "grass_1_",
    type: "grass",
    path:
      "M100.6,179.8 C92.6,179.8 82.8,170.8 72.8,159.6 L44,131.5 L34.3,92.1 L13.8,71 L11.9,59.9 L50.4,21.5 L51.4,20.6 L52.5,20.2 L86.9,9.1 L88.6,9 L111.7,9 L113.3,9 L147.6,20 L148.8,20.5 L149.8,21.4 L186.1,58.2 L186.5,67.1 L186.6,70.1 L176,81.3 L176.9,92.4 L152,135.7 C148.3,140 119.5,168.4 119.1,168.8 C114.7,173.5 109.8,179.8 100.6,179.8 Z",
    translate: [19, 10, -0.06],
  },
  {
    venueId: 2889,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M138,129.8 C137.8,130 132,101.7 100.9,101.7 C67.5,101.7 61.9,127.2 61.4,129.8 L61,130.2 L75.8,145 L76.5,144.3 L94.7,162.2 C94,163.2 93.6,164.4 93.6,165.8 C93.6,167 94,168.2 94.6,169.2 C94.6,169.2 94.6,169.2 94.6,169.3 C95.7,171.1 97.7,172.3 100,172.3 C103.5,172.3 106.4,169.4 106.4,165.9 C106.4,164.4 105.9,163.1 105.1,162 L123.8,144.3 L125.3,144.8 L138.8,131.3 L138,129.8 Z M103.6,160.7 C102.6,160 101.3,159.5 99.9,159.5 C98.4,159.5 97.1,160 96,160.8 L78.4,142.6 C78.4,142.6 84.6,137 81.1,132.4 L98.5,117.9 C99,117.6 99.6,117.5 100.3,117.5 C101.2,117.5 102,118 102.6,118.6 C102.6,118.6 102.7,118.6 102.7,118.7 L118.9,134.2 C118.4,135.2 119,140.5 121.9,143.1 L103.6,160.7 Z",
    translate: [19, 11, 0],
  },

  {
    venueId: 2395, // SFG
    name: "Outfield_1_",
    type: "sand",
    path:
      "M193.9,77.5 L192,72.5 L191.8,71.9 L190.9,69.5 L190.8,69.3 L177.4,33.4 L177.2,33.3 L175.3,31.7 L172.9,30.6 L170.5,29.5 L168.9,26.7 L167.6,23.2 L166.2,19.6 L164.8,15.9 L163.3,12 L161.8,8 L160.2,3.8 L158.8,0.1 L155.7,0.1 L152.6,0.1 L149.5,0.1 L146.4,0.1 L143.3,0.1 L140.3,0.1 L137.3,0.1 L134.3,0.1 L131.4,0.1 L128.4,0.1 L125.5,0.1 L122.6,0.1 L119.7,0.1 L116.8,0.1 L113.9,0.1 L111,0.1 L108.1,0.1 L105.2,0.1 L102.3,0.1 L99.4,0.1 L96.5,0.1 L93.6,0.1 L91,0.1 L88.1,0.1 L85.2,0.1 L82.3,0.1 L79.4,0.1 L76.5,0.1 L73.6,0.1 L70.9,0.1 L70.6,0.1 L68,2 L65.5,4.1 L63,6.2 L60.6,8.2 L58.2,10.2 L55.9,12.2 L53.6,14.1 L51.3,16 L49.1,17.9 L46.9,19.7 L44.7,21.5 L42.5,23.3 L40.4,25.1 L38.3,26.9 L36.2,28.7 L34.1,30.5 L32.1,32.2 L30,33.9 L28,35.6 L26,37.3 L24,39 L22,40.7 L20,42.4 L18,44 L16,45.7 L14,47.3 L12,48.9 L10.1,50.6 L8.1,52.2 L6.1,53.8 L4.7,56 L3.6,58.5 L2.5,61 L1.4,63.4 L1.9,63.9 L1.8,64.1 L0.4,67.8 L19,87 L41.5,122.2 L85.2,173.1 C98.7,188.5 112.5,178 112.5,178 L156.4,127.3 L168.5,112.5 C169.5,110.9 174.8,93.9 174.8,93.9 L185.1,83.9 L189.9,82.7 L188.7,80.4 L193.9,77.5 Z",
    translate: [17.5, 12, -0.2],
  },
  {
    venueId: 2395,
    name: "grass_1_",
    type: "grass",
    path:
      "M103,176 C100.9,176 93.9,175.1 89.5,169.3 C85.1,163.5 41.2,112.6 41.2,112.6 L24.9,86.3 L8.4,70.1 L9.5,67 L12.2,60.2 L12.6,59.7 L73.5,8.1 L152.7,8.2 L161.8,30.2 L165,35.8 L170.9,38.5 L184.7,73.4 L184.4,73.5 L170.8,86.7 L171.3,92.9 L165.2,98.7 L166.8,107.2 L112,170.6 C111.1,171.6 106.2,176 103,176 Z",
    translate: [17.5, 13.5, -0.06],
  },
  {
    venueId: 2395,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M140,126.8 C139.8,127 134,98.7 102.9,98.7 C69.5,98.7 63.9,124.2 63.4,126.8 L63,127.2 L77.8,142 L78.5,141.3 L96.7,159.2 C96,160.2 95.6,161.4 95.6,162.8 C95.6,164 96,165.2 96.6,166.2 C96.6,166.2 96.6,166.2 96.6,166.3 C97.7,168.1 99.7,169.3 102,169.3 C105.5,169.3 108.4,166.4 108.4,162.9 C108.4,161.4 107.9,160.1 107.1,159 L125.8,141.3 L127.3,141.8 L140.8,128.3 L140,126.8 Z M105.6,157.7 C104.6,157 103.3,156.5 101.9,156.5 C100.4,156.5 99.1,157 98,157.8 L80.4,139.6 C80.4,139.6 86.6,134 83.1,129.4 L100.5,114.9 C101,114.6 101.6,114.5 102.3,114.5 C103.2,114.5 104,115 104.6,115.6 C104.6,115.6 104.7,115.6 104.7,115.7 L120.9,131.2 C120.4,132.2 121,137.5 123.9,140.1 L105.6,157.7 Z",
    translate: [16.7, 14, 0],
  },

  {
    venueId: 680, // SEA
    name: "Outfield_1_",
    type: "sand",
    path:
      "M39.6,138 L21.2,101.1 L21.5,97.2 L1.6,76.3 L0.9,73.4 L1.5,71.8 L1.4,68.3 L3.1,66.5 L4.8,64.7 L6.5,62.9 L8.2,61.1 L9.9,59.3 L11.6,57.5 L13.3,55.6 L15,53.7 L16.7,51.8 L18.4,49.9 L20.2,48 L22,46.1 L23.8,44.2 L25.6,42.2 L27.4,40.2 L29.2,38.2 L31.1,36.2 L33,34.1 L34.9,32 L36.9,29.9 L38.9,27.7 L40.9,25.5 L43.2,23.8 L45.5,22.3 L47.9,20.8 L50.3,19.3 L52.7,17.7 L55.1,16.1 L57.6,14.5 L60.2,13.2 L62.8,12.2 L65.5,11.2 L68.2,10.2 L70.9,9.1 L73.6,8 L76.3,6.9 L79.1,5.8 L81.9,4.7 L84.7,3.6 L87.5,2.5 L90.4,1.4 L93.3,0.5 L96.3,1.4 L99.2,2.3 L102.1,3.2 L105,4.1 L107.8,5 L110.6,5.9 L113.4,6.8 L116.2,7.7 L118.9,8.6 L121.6,9.5 L124.3,10.4 L127,11.3 L129.7,12.2 L132.4,13 L135.1,13.8 L137.8,14.6 L140.5,15.4 L143.2,16.2 L145.8,17 L148.4,17.8 L151,18.6 L153.3,20.5 L155.3,23 L157.3,25.4 L159.2,27.8 L161.1,30.1 L163,32.4 L164.8,34.7 L166.6,36.9 L168.4,39.1 L170.1,41.3 L171.8,43.4 L173.5,45.5 L175.2,47.6 L176.9,49.6 L178.5,51.6 L180.1,53.6 L181.7,55.6 L183.3,57.6 L184.9,59.5 L186.5,61.4 L188,63.3 L189.5,65.2 L191,67.1 L192.5,69 L194,70.9 L195.5,72.8 L196.4,73.7 L174,95.2 L174,98.4 L154.2,137.8 L142.2,152.4 C142.2,152.4 120.4,180.4 116.1,184.9 C111.4,189.9 97.5,200.2 81.2,184.2 L39.6,138 Z",
    translate: [19.5, 8, -0.2],
  },
  {
    venueId: 680,
    name: "grass_1_",
    type: "grass",
    path:
      "M100.2,185.4 C96.5,185.4 90.3,182.9 87.1,179.8 L55.3,144.3 L45.5,134 L26.4,98.1 L26.8,92.6 L8.9,75.7 L8.9,75 L9.6,73.1 L9.6,71.3 L46.3,31.3 L47.7,30.2 L61.5,21.3 L63.4,20.4 L93,8.8 L93.2,8.7 L147,25.8 L147.5,26.2 L185.4,73.2 L166,91.8 L166,96.7 L148.4,135.7 L142.4,142.5 L112.8,179.3 C111.3,180.6 106.3,185.4 100.2,185.4 Z",
    translate: [19.5, 8, -0.06],
  },
  {
    venueId: 680,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M137,131.8 C136.8,132 131,103.7 99.9,103.7 C66.5,103.7 60.9,129.2 60.4,131.8 L60,132.2 L74.8,147 L75.5,146.3 L93.7,164.2 C93,165.2 92.6,166.4 92.6,167.8 C92.6,169 93,170.2 93.6,171.2 C93.6,171.2 93.6,171.2 93.6,171.3 C94.7,173.1 96.7,174.3 99,174.3 C102.5,174.3 105.4,171.4 105.4,167.9 C105.4,166.4 104.9,165.1 104.1,164 L122.8,146.3 L124.3,146.8 L137.8,133.3 L137,131.8 Z M102.6,162.7 C101.6,162 100.3,161.5 98.9,161.5 C97.4,161.5 96.1,162 95,162.8 L77.4,144.6 C77.4,144.6 83.6,139 80.1,134.4 L97.5,119.9 C98,119.6 98.6,119.5 99.3,119.5 C100.2,119.5 101,120 101.6,120.6 C101.6,120.6 101.7,120.6 101.7,120.7 L117.9,136.2 C117.4,137.2 118,142.5 120.9,145.1 L102.6,162.7 Z",
    translate: [20, 9, 0],
  },

  {
    venueId: 2680, // SDP
    name: "Outfield_1_",
    type: "sand",
    path:
      "M-228,314.7l0,7l2.2,1.3l1.5,2l1.4,2.2l1.4,2.2l1.4,2.2l1.6,2l1.8,1.8l1.8,1.8l1.8,1.8    l1.8,1.8l1.8,1.8l1.9,1.8l1.9,1.9l1.9,1.9l1.9,1.9l1.9,1.9l1.9,1.9l2,1.9l2,2l2,2l2,2l2.1,2l2.1,2.1l2.1,2.1l2.1,2.1l2.2,2.2    l2.2,2.2l2.3,2.3l2.4,2.1l2.6,1.5l2.6,1.5l2.6,1.5l2.6,1.5l2.7,1.5l3,0.1l3,0l3,0l2.9,0l2.9,0l2.9,0l2.9,0l2.8,1.6l2.9,1l2.9,0    l2.9,0l2.9,0l2.9,0l2.9,0l2.9,0l3,0l3,0l3,0l2.9-0.4l2.9-0.6l2.9-0.6l2.9-0.6l2.9-0.6l2.9-0.6l2.7-1.5l2.6-1.6l2.6-1.6l2.6-1.6    l2.5-1.8l2.3-2.3l2.2-2.2l2.2-2.2l2.2-2.2l2.1-2.1l2.1-2.1l2.1-2.1l2-2l2-2l2-2l2-2l2-2l1.9-1.9l1.9-1.9l1.9-1.9l1.9-1.9l1.9-1.9    l1.9-1.9l1.9-1.9l1.8-1.8l0.6-3.3l0-4l0-3.8l0-3.7l1.5-1.9l1.7-1.7l1.5-1.9l0.6-10.6l-31.5-31.5l-20.4-35l-2.4-2.4l-4.8-7.3    l-16.3-17.6l-2.7-0.5l-7.2-6.8l-8.2-4h-7.3l-8.8,3.7l-9.6,9.6l-3-0.3l-13.5,14l-4.2,8.1l-3.6,3.2l-23.4,36.5v7.2L-228,314.7z",
    translate: [246, 401.5, -0.2],
    scale: [1, -1, 1],
  },
  {
    venueId: 2680,
    name: "grass_1_",
    type: "grass",
    path:
      "M96.53,9.114 L90.748,11.564 L79.674,22.54 L62.916,40.572 L59.192,44.002 L38.024,76.93 L38.024,84.966 L12.642,111.23 L11.956,119.168 L15.092,124.656 L57.232,166.698 L58.8,168.07 L69.188,174.048 L69.972,174.048 L89.18,174.146 L93.1,174.342 L96.04,176.596 L118.286,176.694 L120.442,176.4 L133.182,173.95 L134.554,173.166 L141.708,168.756 L143.374,167.58 L179.634,128.38 C179.634,128.38 181.3,126.714 181.692,123.284 C182.084,119.854 181.692,103.292 181.692,103.292 L156.604,78.204 L136.612,43.904 L134.456,41.748 L113.582,19.306 L105.644,11.76 L100.45,9.212 L96.53,9.212 L96.53,9.114 Z",
    translate: [19, 198, -0.06],
    scale: [1, -1, 1],
  },
  {
    venueId: 2680,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M136.416,57.232 C136.22,57.036 130.438,85.358 99.274,85.358 C65.856,85.358 60.27,59.878 59.78,57.232 L59.388,56.84 L74.186,42.042 L74.872,42.728 L93.1,24.794 C92.414,23.814 92.022,22.638 92.022,21.168 C92.022,19.992 92.414,18.816 93.002,17.738 C93.002,17.738 93.002,17.738 93.002,17.64 C94.08,15.876 96.138,14.602 98.392,14.602 C101.92,14.602 104.762,17.542 104.762,20.972 C104.762,22.442 104.272,23.814 103.488,24.892 L122.206,42.63 L123.676,42.14 L137.2,55.664 L136.416,57.232 Z M102.018,26.264 C101.038,26.95 99.764,27.44 98.294,27.44 C96.824,27.44 95.452,26.95 94.374,26.166 L76.734,44.394 C76.734,44.394 82.908,49.98 79.478,54.586 L96.922,69.09 C97.412,69.384 98,69.482 98.686,69.482 C99.568,69.482 100.352,68.992 100.94,68.404 C100.94,68.404 101.038,68.404 101.038,68.306 L117.208,52.822 C116.718,51.842 117.306,46.55 120.246,43.904 L102.018,26.264 Z",
    translate: [20, 198.5, 0],
    scale: [1, -1, 1],
  },

  {
    venueId: 31, // PIT
    name: "Outfield_1_",
    type: "sand",
    path:
      "M0.3,72.8 L0.6,70.8 L0.9,67.7 L1.2,64.5 L1.5,61.2 L1.8,57.8 L2.1,54.3 L2.4,50.6 L2.7,46.8 L3,42.9 L5,41 L7.1,39.3 L9.2,37.6 L11.3,35.8 L13.4,34 L15.5,32.2 L17.6,30.4 L19.7,28.6 L21.9,26.8 L24.1,25 L26.3,23.2 L28.5,21.4 L30.7,19.5 L32.9,17.6 L35.2,15.7 L37.5,13.8 L39.8,11.9 L42.2,9.9 L44.6,7.9 L47,5.9 L49.5,3.8 L52,1.7 L56.3,6.7 L59.2,6.4 L62,5.9 L64.8,5.4 L67.6,4.9 L70.4,4.4 L73.2,3.9 L76,3.4 L78.8,2.9 L81.6,2.4 L84.4,1.9 L87.2,1.4 L90.1,0.9 L93,0.4 L95.9,0 L98.8,0.6 L101.7,1.2 L104.5,1.8 L107.3,2.4 L110.1,3 L112.9,3.6 L115.7,4.2 L118.5,4.8 L121.3,5.4 L124.1,6 L126.8,7.1 L129.4,8.4 L132,9.7 L134.6,11 L137.1,12.3 L139.6,13.6 L142.1,14.9 L144.6,16.2 L147.1,17.5 L149.6,18.7 L152,19.9 L154.4,21.1 L156.6,22.9 L158.4,25.5 L160.2,28.1 L161.9,30.6 L163.6,33.1 L165.3,35.5 L166.9,37.9 L168.5,40.2 L170.1,42.5 L171.7,44.8 L173.2,47 L174.7,49.2 L176.2,51.4 L177.7,53.5 L179.1,55.6 L180.5,57.7 L181.9,59.8 L183.3,61.8 L184.7,63.8 L186.1,65.8 L187.5,67.8 L188.9,69.8 L190.3,71.7 L192.8,75 L172.6,95.3 L163.2,123.3 C163.2,123.3 121.5,172.6 111.8,183.1 C104.3,191.2 97.9,189.9 94.9,189.9 C85.5,189.9 78.3,181.2 73.5,175 L20,106.1 L21,94.4 L0.3,72.8 Z",
    translate: [22.5, 11, -0.2],
  },
  {
    venueId: 31,
    name: "grass_1_",
    type: "grass",
    path:
      "M96.2,182.2 C95.7,182.2 95.2,182.2 94.8,182.2 C89,182.2 83.1,174.8 79.6,170.3 L28.2,103.6 L29.2,91.4 L8.7,70 L10.7,46.8 L51,13 L53,15.3 L60.3,14.4 L94.4,8.8 L95.8,8.6 L121.9,14.2 L123.6,14.9 L150,28.3 L150.6,28.8 L182.1,74.5 L165.4,91.3 L155.8,119.6 C149.4,127.1 114.3,168.6 105.7,177.9 C101.8,182.2 96.5,182.2 96.2,182.2 Z",
    translate: [22.5, 11, -0.06],
  },
  {
    venueId: 31,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M134,128.8 C133.8,129 128,100.7 96.9,100.7 C63.5,100.7 57.9,126.2 57.4,128.8 L57,129.2 L71.8,144 L72.5,143.3 L90.7,161.2 C90,162.2 89.6,163.4 89.6,164.8 C89.6,166 90,167.2 90.6,168.2 C90.6,168.2 90.6,168.2 90.6,168.3 C91.7,170.1 93.7,171.3 96,171.3 C99.5,171.3 102.4,168.4 102.4,164.9 C102.4,163.4 101.9,162.1 101.1,161 L119.8,143.3 L121.3,143.8 L134.8,130.3 L134,128.8 Z M99.6,159.7 C98.6,159 97.3,158.5 95.9,158.5 C94.4,158.5 93.1,159 92,159.8 L74.4,141.6 C74.4,141.6 80.6,136 77.1,131.4 L94.5,116.9 C95,116.6 95.6,116.5 96.3,116.5 C97.2,116.5 98,117 98.6,117.6 C98.6,117.6 98.7,117.6 98.7,117.7 L114.9,133.2 C114.4,134.2 115,139.5 117.9,142.1 L99.6,159.7 Z",
    translate: [23, 12, 0],
  },

  {
    venueId: 2681, // PHI
    name: "Outfield_1_",
    type: "sand",
    path:
      "M199.8,132l-2.4,2.3l-0.2,10.4L186.8,173l-49.7,48.3l-21.8,0.1l-49.2-47.2l-10.9-27.1 l0.1-9.4l-27.1-27.6l0.1-3.5l0-3.5l1.7-1.8l1.7-1.7l1.7-1.7l1.7-1.7l1.7-1.7l1.7-1.7l1.7-1.7l1.8-1.8l1.8-1.8l1.8-1.8l1.8-1.8 l1.8-1.8l1.8-1.8l1.8-1.8l1.8-1.8l1.9-1.9l1.9-1.9l1.9-1.9l1.9-1.9l2-2l2-2l2-2l2-2l2.1-2.1l2.1-2.1l2.1-2.1l2.2-2.2l2.2-2.2 l2.3-2.3l2.7-0.7l2.9,0l2.9,0l2.8,0l2.4-2.4l2.5-2.4l2.5-2.5l2.6-2.6l2.7-2.6l2.9-0.6l3,0.4l3,0.4l3,0.4l2.9,0.4l2.9,0.4l2.9,0.4 l2.9,0.4l2.9,0.4l2.9,0.4l2.9,0.4l2.9,0.4l2.9,0.4l2.9,0.4l2.9,0.4l2.9,0.4l2.8,0.8l2.4,2.4l2.4,2.4l2.3,2.3l2.3,2.3l2.2,2.2 l2.2,2.2l2.2,2.1l2.1,2.1l2.1,2.1l2,2l2,2l2,2l2,2l1.9,1.9l1.9,1.9l1.9,1.9l1.9,1.9l1.8,1.8l1.8,1.8l1.8,1.8l1.8,1.8l1.8,1.8 l1.8,1.8l1.8,1.7l1.7,1.7l1.7,1.7l1.7,1.7l1.7,1.7l1.7,1.7l1.7,1.7l1.7,1.7l1.7,1.7l1.7,1.7l1.7,1.7l0.1,3.5L199.8,132z",
    translate: [-6, -26, -0.2],
  },
  {
    venueId: 2681,
    name: "grass_1_",
    type: "grass",
    path:
      "M 70.2,169.7 60.4,146.2 60.6,132.8 34.5,108.8 36.5,106.4 87.5,55.4 91.9,55.4 98,55.3     111.1,42.3 152.1,48 152.4,48 215.3,109.5 191,133.3 190.5,145 181.9,167.5 135.4,214.6 116.6,214.6   z",
    translate: [-6, -26, -0.06],
  },
  {
    venueId: 2681,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M135,131.8 C134.8,132 129,103.7 97.9,103.7 C64.5,103.7 58.9,129.2 58.4,131.8 L58,132.2 L72.8,147 L73.5,146.3 L91.7,164.2 C91,165.2 90.6,166.4 90.6,167.8 C90.6,169 91,170.2 91.6,171.2 C91.6,171.2 91.6,171.2 91.6,171.3 C92.7,173.1 94.7,174.3 97,174.3 C100.5,174.3 103.4,171.4 103.4,167.9 C103.4,166.4 102.9,165.1 102.1,164 L120.8,146.3 L122.3,146.8 L135.8,133.3 L135,131.8 Z M100.6,162.7 C99.6,162 98.3,161.5 96.9,161.5 C95.4,161.5 94.1,162 93,162.8 L75.4,144.6 C75.4,144.6 81.6,139 78.1,134.4 L95.5,119.9 C96,119.6 96.6,119.5 97.3,119.5 C98.2,119.5 99,120 99.6,120.6 C99.6,120.6 99.7,120.6 99.7,120.7 L115.9,136.2 C115.4,137.2 116,142.5 118.9,145.1 L100.6,162.7 Z",
    translate: [22, 9, 0],
  },

  {
    venueId: 10, // OAK
    name: "Outfield_1_",
    type: "sand",
    path:
      "M116.9,185.2 C116.9,185.2 107.2,187.3 100.5,187.3 C93.8,187.3 84.1,185.4 84.1,185.4 L77,178 C77,178 21,158.3 16.8,95.3 L0.7,74.6 L3,69.1 L4,66.7 L5.1,64.3 L6.2,61.9 L7.3,59.4 L8.4,56.9 L9.5,54.3 L10.6,51.7 L11.8,49 L13,46.3 L14.2,43.5 L15.4,40.7 L16.7,37.8 L18.7,36 L21.1,34.9 L23.6,33.9 L26.1,32.9 L28.6,31.9 L31.1,30.9 L33.5,29.9 L35.9,28.9 L38.3,27.9 L40.7,26.9 L43,25.6 L45.2,24.1 L47.5,22.6 L50,21 L52.3,19.4 L54.6,17.8 L57,16.2 L59.4,14.6 L61.8,13 L64.2,11.3 L66.7,9.6 L69.3,8.1 L71.9,6.8 L74.6,5.5 L77.3,4.2 L80,2.9 L82.7,1.6 L85.5,0.3 L88.4,0 L91.3,0 L94.2,0 L97.1,0 L100,0 L102.9,0 L105.8,0 L108.7,0 L111.6,0 L114.5,0.3 L117.3,1.7 L120,3 L122.7,4.3 L125.4,5.6 L128.1,6.9 L130.7,8.2 L133.2,9.7 L135.7,11.4 L138.1,13.1 L140.5,14.8 L142.9,16.4 L145.3,18 L147.6,19.6 L150,21 L152.3,22.6 L154.6,24.1 L156.8,25.6 L159.2,26.8 L161.6,27.8 L164,28.8 L166.4,29.8 L168.9,30.8 L171.4,31.8 L173.9,32.8 L176.4,33.8 L178.9,34.8 L181.3,35.9 L183.3,37.8 L184.6,40.7 L185.8,43.5 L187,46.3 L188.2,49 L189.4,51.7 L190.5,54.3 L191.6,56.9 L192.7,59.4 L193.8,61.9 L194.9,64.3 L196,66.7 L197.1,69 L199.6,74 L183.7,96 C179.6,158.9 122.4,177.9 122.4,177.9 L116.9,185.2 Z",
    translate: [19, 10.5, -0.2],
  },
  {
    venueId: 10,
    name: "grass_1_",
    type: "grass",
    path:
      "M82.4,172.7 L75.5,169.6 C73.8,169.1 36.9,155.8 24.4,102.3 L29.3,98.3 L9.5,74.5 L23.4,42.9 L24.5,42.4 L44.4,34.1 L47.4,32.4 L71,16.3 L73,15.1 L87.6,8 L88.7,7.9 L111.2,7.8 L112.3,7.9 L126.9,15 L128.9,16.2 L152.7,32.5 L155.8,34.1 L177.5,42.8 L190.8,74.7 L176,95.2 L176,95.2 C180.2,121.5 159.7,146.3 142.3,160.1 C129.2,170.4 118.3,173.3 118.3,173.3 L115.6,179.2 C115.6,179.2 107.3,181.3 100.5,181.3 C93.7,181.3 87.6,179.4 87.6,179.4 L82.4,172.7 Z",
    translate: [18, 11, -0.06],
  },
  {
    venueId: 10,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M138,128.8 C137.8,129 132,100.7 100.9,100.7 C67.5,100.7 61.9,126.2 61.4,128.8 L61,129.2 L75.8,144 L76.5,143.3 L94.7,161.2 C94,162.2 93.6,163.4 93.6,164.8 C93.6,166 94,167.2 94.6,168.2 C94.6,168.2 94.6,168.2 94.6,168.3 C95.7,170.1 97.7,171.3 100,171.3 C103.5,171.3 106.4,168.4 106.4,164.9 C106.4,163.4 105.9,162.1 105.1,161 L123.8,143.3 L125.3,143.8 L138.8,130.3 L138,128.8 Z M103.6,159.7 C102.6,159 101.3,158.5 99.9,158.5 C98.4,158.5 97.1,159 96,159.8 L78.4,141.6 C78.4,141.6 84.6,136 81.1,131.4 L98.5,116.9 C99,116.6 99.6,116.5 100.3,116.5 C101.2,116.5 102,117 102.6,117.6 C102.6,117.6 102.7,117.6 102.7,117.7 L118.9,133.2 C118.4,134.2 119,139.5 121.9,142.1 L103.6,159.7 Z",
    translate: [19, 12, 0],
  },

  {
    venueId: 3313, // NYY
    name: "Outfield_1_",
    type: "sand",
    path:
      "M94.8,189.5 C81.4,189.5 72.8,178.6 72.8,178.6 L48.4,149 L38,135.9 L21.3,101.6 L2.8,84.4 L0.4,76.7 L0.5,73.4 L0.9,70.4 L1.4,67.3 L2,64.3 L2.9,61.6 L3.9,58.8 L5,56.2 L6.3,53.7 L7.6,51.2 L9.3,49.2 L11.2,47.3 L13.1,45.4 L15,43.5 L16.9,41.6 L18.8,39.7 L20.7,37.8 L22.7,35.8 L24.7,33.8 L26.7,31.8 L28.7,29.8 L30.7,27.8 L32.8,25.7 L34.9,23.6 L37,21.5 L39.2,19.3 L41.4,17.1 L43.7,15 L46.2,13.5 L48.7,12 L51.3,10.5 L53.9,8.9 L56.5,7.3 L59.1,5.7 L61.9,4.8 L64.7,3.9 L67.6,3.1 L70.5,2.4 L73.4,1.7 L76.3,1.1 L79.2,0.6 L82.1,0.2 L85.1,0.2 L88.1,0.2 L91.1,0.2 L94.1,0.2 L97.1,0.7 L100,1.2 L102.9,1.7 L105.8,2.2 L108.7,3.1 L111.5,4 L114.3,4.9 L117.1,6 L119.7,7.9 L122.3,9.8 L124.8,11.6 L127.3,13.4 L129.8,15.2 L132.2,16.9 L134.5,19.1 L136.7,21.3 L138.9,23.5 L141,25.6 L143.1,27.7 L145.2,29.8 L147.2,31.8 L149.2,33.8 L151.2,35.8 L153.1,37.7 L155,39.6 L156.9,41.5 L158.8,43.4 L160.6,45.2 L162.4,47 L164.2,48.8 L166,50.6 L167.8,52.4 L169.6,54.2 L171.3,55.9 L173,57.6 L174.7,59.3 L176.4,61 L178.1,62.7 L179.8,64.4 L181.5,66.1 L182.9,68.2 L184.2,70.2 L185.1,72.6 L186,75 L186.4,77.8 L185.7,82.1 L163.9,103.9 L139.4,147.2 L114.5,179.6 C114.6,179.7 107.2,189.5 94.8,189.5 Z",
    translate: [25, 7, -0.2],
  },
  {
    venueId: 3313,
    name: "grass_1_",
    type: "grass",
    path:
      "M94.8,183.5 C85.6,183.5 79.1,175.7 79,175.6 L46.6,138.5 L28,96.8 L9.9,79.9 L8.5,75.5 L8.5,74 L8.8,71.5 L9.2,68.7 L9.7,66.4 L11.3,61.7 L12.2,59.6 L15.2,55.7 L16.1,54.6 C16.1,54.6 42.2,27 46.9,22.9 C59.5,11.6 81.7,10.1 81.7,10.1 L90,10.1 L92.3,10.1 L100.5,11.4 L102.8,11.8 L110.6,14.4 L112.2,15 L126,25 L173,70.1 L174.1,71.2 C174.1,71.2 176.8,74.3 174.1,77.7 C171.4,81.1 162.2,92.4 162.2,92.4 L139.1,135.7 L106.6,177.7 C104.9,179.7 100.6,183.5 94.8,183.5 Z",
    translate: [25, 7, -0.06],
  },
  {
    venueId: 3313,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M132,132.8 C131.8,133 126,104.7 94.9,104.7 C61.5,104.7 55.9,130.2 55.4,132.8 L55,133.2 L69.8,148 L70.5,147.3 L88.7,165.2 C88,166.2 87.6,167.4 87.6,168.8 C87.6,170 88,171.2 88.6,172.2 C88.6,172.2 88.6,172.2 88.6,172.3 C89.7,174.1 91.7,175.3 94,175.3 C97.5,175.3 100.4,172.4 100.4,168.9 C100.4,167.4 99.9,166.1 99.1,165 L117.8,147.3 L119.3,147.8 L132.8,134.3 L132,132.8 Z M97.6,163.7 C96.6,163 95.3,162.5 93.9,162.5 C92.4,162.5 91.1,163 90,163.8 L72.4,145.6 C72.4,145.6 78.6,140 75.1,135.4 L92.5,120.9 C93,120.6 93.6,120.5 94.3,120.5 C95.2,120.5 96,121 96.6,121.6 C96.6,121.6 96.7,121.6 96.7,121.7 L112.9,137.2 C112.4,138.2 113,143.5 115.9,146.1 L97.6,163.7 Z",
    translate: [25, 8, 0],
  },

  {
    venueId: 3289, // NYM
    name: "Outfield_1_",
    type: "sand",
    path:
      "M199.3,75.9 L162.6,112.8 L157.3,140.7 C157.3,140.7 135.2,173 129.5,179.4 C121.8,190.9 108.3,191.9 100.9,191.9 C88.8,191.9 78.8,189.9 70.2,178.9 L42.6,141.1 L35.2,111 L0,75.9 L1.5,72.6 L2.7,70.3 L3.9,67.9 L5.1,65.5 L6.3,63.1 L7.6,60.7 L8.9,58.2 L10.2,55.8 L11.5,53.3 L12.8,50.8 L14.9,49.2 L17.1,47.7 L19.3,46.2 L21.5,44.7 L23.7,43.3 L26,41.9 L28.3,40.5 L30.6,39.1 L32.9,37.7 L35.2,36.3 L37.5,34.9 L39.8,33.5 L42.1,32.1 L44.4,30.7 L46.7,29.3 L49.1,27.9 L51.5,26.5 L53.9,25 L56.3,23.5 L58.7,21.8 L61.1,20.1 L63.5,18.4 L65.9,16.7 L68.4,15 L70.8,13.2 L73.3,11.4 L75.8,9.6 L78.4,7.7 L81,5.8 L83.7,3.9 L86.4,1.9 L89.2,0.4 L92.1,0.4 L95,0.4 L97.9,0.4 L100.8,0.4 L103.7,0.4 L106.6,0.4 L109.5,0.4 L112.4,0.9 L115.2,2.6 L117.9,4.3 L120.6,6 L123.2,7.6 L125.8,9.2 L128.4,10.8 L130.9,12.4 L133.4,14 L135.9,15.5 L138.3,17 L140.7,18.5 L143.1,20 L145.5,21.5 L147.9,23 L150.3,24.5 L152.7,25.9 L155.1,27.3 L157.5,28.7 L160.1,29.4 L162.7,30.1 L165.3,30.9 L167.9,31.7 L170.5,32.5 L173.1,33.3 L174.9,35.8 L176.6,38.2 L178.3,40.6 L180,43 L181.7,45.4 L183.3,47.7 L184.9,50 L186.5,52.3 L188.1,54.5 L189.5,56.9 L189.9,60.4 L190.3,63.8 L191.8,65.9 L193.3,68 L194.7,70.1 L196.1,72.2 L197.5,74.2 L199.3,75.9 Z",
    translate: [18.5, 6, -0.2],
  },
  {
    venueId: 3289,
    name: "grass_1_",
    type: "grass",
    path:
      "M100.8,184.1 C90.1,184.1 83.1,182.5 76.6,174.2 L50,137.7 L42.5,106.9 L9.7,74.3 L18.9,56.2 L19.4,55.8 L25.6,51.5 L27.6,50.2 L57.1,32.3 L59.6,30.7 L89.6,9.7 L90.3,9.3 L108.5,9.3 L109.3,9.4 L151.3,34.6 L154.5,36.5 L160.7,38.3 L163.3,39 L168.4,40.5 L181.3,58.9 L181.7,59.5 L182.6,66.8 L185.1,70.5 L188.6,75.5 L155.4,109 L150,137.7 C141.2,150.5 127.6,169.9 123.7,174.2 L123.3,174.6 L123,175.1 C118.8,181.1 111.6,184.1 100.8,184.1 Z",
    translate: [18.5, 6, -0.06],
  },
  {
    venueId: 3289,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M138,133.8 C137.8,134 132,105.7 100.9,105.7 C67.5,105.7 61.9,131.2 61.4,133.8 L61,134.2 L75.8,149 L76.5,148.3 L94.7,166.2 C94,167.2 93.6,168.4 93.6,169.8 C93.6,171 94,172.2 94.6,173.2 C94.6,173.2 94.6,173.2 94.6,173.3 C95.7,175.1 97.7,176.3 100,176.3 C103.5,176.3 106.4,173.4 106.4,169.9 C106.4,168.4 105.9,167.1 105.1,166 L123.8,148.3 L125.3,148.8 L138.8,135.3 L138,133.8 Z M103.6,164.8 C102.6,164.1 101.3,163.6 99.9,163.6 C98.4,163.6 97.1,164.1 96,164.9 L78.4,146.7 C78.4,146.7 84.6,141.1 81.1,136.5 L98.5,122 C99,121.7 99.6,121.6 100.3,121.6 C101.2,121.6 102,122.1 102.6,122.7 C102.6,122.7 102.7,122.7 102.7,122.8 L118.9,138.3 C118.4,139.3 119,144.6 121.9,147.2 L103.6,164.8 Z",
    translate: [18.5, 7.5, 0],
  },

  {
    venueId: 3312, // MIN
    name: "Outfield_1_",
    type: "sand",
    path:
      "M0,72.1 L1.3,70.4 L2.9,68.6 L4.5,66.7 L6.1,64.8 L7.8,62.9 L9.5,61 L11.2,59.1 L12.9,57.2 L14.6,55.3 L16.3,53.4 L18,51.5 L19.7,49.5 L21.4,47.5 L23.2,45.5 L25,43.5 L26.8,41.5 L28.6,39.4 L30.4,37.3 L32.3,35.2 L34.2,33.1 L36.1,30.9 L38.1,28.7 L40.1,26.5 L42.3,24.6 L44.7,23.1 L47.1,21.6 L49.5,20.1 L51.9,18.6 L54.3,17.1 L56.8,15.6 L59.3,14.1 L61.8,12.6 L64.3,11 L66.9,9.4 L69.5,7.8 L72.1,6.2 L74.8,4.6 L77.5,2.9 L80.3,1.2 L83.2,0.1 L86.2,0.4 L89.2,0.7 L92.2,1 L95.2,1.3 L98.2,1.6 L101.1,1.9 L104,2.2 L106.9,2.5 L109.8,2.8 L112.7,3.1 L115.6,3.4 L118.5,3.7 L121.3,4.8 L124,6.5 L126.7,8.2 L129.3,9.9 L131.9,11.6 L134.4,13.2 L136.9,14.8 L139.4,16.4 L141.8,18 L144.2,19.6 L146.6,21.1 L149,22.6 L151.3,24.1 L153.6,25.6 L156,27 L158.3,28.5 L160.6,30 L162.9,31.5 L164.8,33.7 L166.6,35.9 L168.4,38.1 L170.2,40.3 L172,42.4 L173.7,44.5 L175.4,46.6 L177.1,48.7 L178.8,50.7 L180.4,52.7 L182,54.7 L183.6,56.7 L185.2,58.7 L186.8,60.6 L188.4,62.5 L190,64.4 L191.5,66.3 L193,68.2 L194.5,70.1 L196,72 L197.5,73.9 L199.5,75.9 L170.1,105.6 L161.1,132.7 L151.2,146.7 C151.2,146.7 137.9,161 116.7,181.6 C100.8,197.1 84.2,180.3 84.2,180.3 L32.8,125 L18.1,90.8 L0,72.1 Z",
    translate: [18, 7, -0.2],
  },
  {
    venueId: 3312,
    name: "grass_1_",
    type: "grass",
    path:
      "M101.9,183.3 C95.2,183.3 90.2,177.9 90.1,177.8 L89.9,177.6 L89.8,177.5 L41.4,128.4 L26.8,88.2 L11,71.8 L45.7,32.4 L47,31.3 L87.3,8.4 L116.7,11.3 L117.7,11.7 L157.5,37.4 L158.6,38.7 L190.7,78 L166.1,101.9 L157.7,126.1 L145.8,143.5 C142.8,146.5 131.4,157.8 114,175.8 C109.2,180.8 106.5,183.3 101.9,183.3 Z",
    translate: [18, 7, -0.06],
  },
  {
    venueId: 3312,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M139,132.8 C138.8,133 133,104.7 101.9,104.7 C68.5,104.7 62.9,130.2 62.4,132.8 L62,133.2 L76.8,148 L77.5,147.3 L95.7,165.2 C95,166.2 94.6,167.4 94.6,168.8 C94.6,170 95,171.2 95.6,172.2 C95.6,172.2 95.6,172.2 95.6,172.3 C96.7,174.1 98.7,175.3 101,175.3 C104.5,175.3 107.4,172.4 107.4,168.9 C107.4,167.4 106.9,166.1 106.1,165 L124.8,147.3 L126.3,147.8 L139.8,134.3 L139,132.8 Z M104.6,163.7 C103.6,163 102.3,162.5 100.9,162.5 C99.4,162.5 98.1,163 97,163.8 L79.4,145.6 C79.4,145.6 85.6,140 82.1,135.4 L99.5,120.9 C100,120.6 100.6,120.5 101.3,120.5 C102.2,120.5 103,121 103.6,121.6 C103.6,121.6 103.7,121.6 103.7,121.7 L119.9,137.2 C119.4,138.2 120,143.5 122.9,146.1 L104.6,163.7 Z",
    translate: [18, 8, 0],
  },

  {
    venueId: 32, // MIL
    name: "Outfield_1_",
    type: "sand",
    path:
      "M206.1,68.1 L164.8,110.4 L165.4,112.9 L151.7,141.5 C151.7,141.5 124.7,175.3 119,181.7 C114.8,186.9 108.1,190.1 100.6,190.1 C88.5,190.1 81.5,181.7 72.9,170 L48.6,138.5 L28,106.2 L25.5,96.7 L0.4,71.8 L1.6,66.7 L2.4,64 L3.2,61.2 L4,58.4 L4.9,55.5 L6.2,53 L8,51.1 L9.9,49.2 L11.8,47.3 L13.7,45.4 L15.6,43.5 L17.5,41.6 L19.4,39.7 L21.3,37.8 L23.2,35.9 L25.2,33.9 L27.3,32.2 L29.8,31.1 L32.3,30 L34.8,28.9 L37.3,27.8 L39.8,26.7 L42.3,25.6 L44.8,24.5 L47.3,23.4 L49.7,22.1 L52.1,20.8 L54.6,19.7 L57.1,18.6 L59.6,17.5 L62.1,16.4 L64.6,15.3 L67.1,14.2 L69.7,13 L72.1,10.9 L74.4,7.8 L76.7,4.6 L79.3,2.3 L82.2,1.9 L85.1,1.6 L88,1.4 L90.9,1.1 L93.8,0.9 L96.7,0.8 L99.6,0.7 L102.5,0.7 L105.4,0.7 L108.3,0.8 L111.2,0.9 L114.1,1.1 L117,1.3 L119.9,1.6 L122.8,1.9 L125.7,2.3 L128.3,4.6 L130.6,7.9 L133.1,9.7 L135.7,10.9 L138.3,12.1 L140.9,13.3 L143.5,14.5 L146,15.6 L148.5,16.7 L151,17.8 L153.5,18.9 L156,20 L158.5,21.1 L161,22.2 L163.2,24.1 L165.3,25.9 L167.4,27.7 L169.5,29.5 L171.6,31.3 L173.7,33.1 L174.2,37.6 L175.6,40.4 L177.5,42.1 L179.4,43.8 L181.3,45.5 L183.2,47.2 L185.1,48.9 L187,50.6 L188.9,52.3 L190.8,54 L192.7,55.6 L194.6,57.2 L196,59 L197.9,60.6 L199.8,62.2 L201.7,63.8 L203.5,65.5 L206.1,68.1 Z",
    translate: [16.5, 10.5, -0.2],
  },
  {
    venueId: 32,
    name: "grass_1_",
    type: "grass",
    path:
      "M102.5,183.1 C94.8,183.1 87.9,177.1 79.3,165.2 L55.1,133.8 L35.3,102.9 L32.6,92.5 L9.1,69.3 L9.2,68.8 L12.3,58.5 L12.7,57.8 L30.4,40 L31.4,39.2 L50.4,30.8 L53,29.4 L55.2,28.3 L73.6,20 L77.6,16.6 L82.3,10.1 L82.4,10 L82.7,10 L85.4,9.7 L88.3,9.5 L91,9.2 L93.7,9 L96.5,8.9 L99.3,8.8 L102.1,8.8 L104.8,8.8 L107.5,8.9 L110.3,9 L113.1,9.2 L115.8,9.4 L118.6,9.7 L121.2,10 L121.5,10.1 L121.7,10.2 L124.3,13.8 L128.5,16.8 L156.1,29.2 L165.5,37.3 L165.8,40.1 L168.5,45.6 L194.5,68.4 L156,107.9 L156.9,111.8 L145.4,138.4 C141.7,143 113.8,176.5 113.8,176.5 C113.8,176.5 108.9,183.1 102.5,183.1 Z",
    translate: [16.5, 10.5, -0.06],
  },
  {
    venueId: 32,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M140,129.8 C139.8,130 134,101.7 102.9,101.7 C69.5,101.7 63.9,127.2 63.4,129.8 L63,130.2 L77.8,145 L78.5,144.3 L96.7,162.2 C96,163.2 95.6,164.4 95.6,165.8 C95.6,167 96,168.2 96.6,169.2 C96.6,169.2 96.6,169.2 96.6,169.3 C97.7,171.1 99.7,172.3 102,172.3 C105.5,172.3 108.4,169.4 108.4,165.9 C108.4,164.4 107.9,163.1 107.1,162 L125.8,144.3 L127.3,144.8 L140.8,131.3 L140,129.8 Z M105.6,160.7 C104.6,160 103.3,159.5 101.9,159.5 C100.4,159.5 99.1,160 98,160.8 L80.4,142.6 C80.4,142.6 86.6,137 83.1,132.4 L100.5,117.9 C101,117.6 101.6,117.5 102.3,117.5 C103.2,117.5 104,118 104.6,118.6 C104.6,118.6 104.7,118.6 104.7,118.7 L120.9,134.2 C120.4,135.2 121,140.5 123.9,143.1 L105.6,160.7 Z",
    translate: [16.5, 11.5, 0],
  },

  {
    venueId: 4169, // MIA
    name: "Outfield_1_",
    type: "sand",
    path:
      "M202.5,69.7 L201,67.8 L199.5,65.9 L198,64 L196.4,62.1 L194.8,60.1 L193.2,58.1 L192.3,57 L192,56.6 L191.9,56.5 L191.6,56.1 L190,54.1 L189.3,53.3 L188.8,52.6 L187.2,50.5 L186.5,49.6 L185.2,47.9 L183.5,45.8 L181.8,43.7 L180.1,41.6 L178.4,39.4 L176.6,37.2 L174.8,35 L173,32.7 L171.2,30.4 L169.3,28.1 L167.7,26.1 L167.7,26.1 L167.7,26.1 L165.7,23.7 L163.6,21.6 L161.2,20.1 L158.8,18.7 L156.2,17.5 L153.6,16.3 L151,15.2 L148.4,14.2 L145.7,13.4 L143,12.6 L140.3,11.8 L137.6,11 L134.9,10.1 L132.2,9.2 L129.5,8.3 L126.7,7.5 L123.9,6.8 L121.1,6.2 L118.3,5.6 L115.4,4.9 L112.5,4.2 L109.6,3.4 L106.7,2.8 L103.8,2.2 L100.9,1.8 L98,1.4 L95,1.1 L92,0.9 L89.1,0.9 L86.1,1 L83.1,1.2 L80.6,2.7 L80.3,2.9 L77.7,5.3 L77.5,5.5 L75.4,7 L72.9,8 L70.2,9.1 L67.5,9.9 L64.7,10.6 L62,11.3 L59.3,12.3 L56.7,13.5 L54.2,14.8 L53.8,15 L53.7,15.1 L51.3,16.6 L48.9,18.2 L46.5,19.8 L44,21.2 L41.7,22.8 L39.4,24.5 L37.2,26.3 L35,28.1 L32.8,29.9 L30.7,31.7 L28.6,33.5 L26.6,35.6 L24.7,37.6 L22.8,39.6 L20.9,41.6 L19,43.6 L17.2,45.7 L15.5,47.8 L13.8,49.9 L12.2,52 L10.4,54 L8.9,56.2 L7.5,58.5 L6.1,60.8 L4.7,63.1 L3.3,65.3 L2,67.5 L2.1,67.6 L0,70.8 L87.1,176.4 C105.5,198.7 121.4,176.1 121.4,176.1 L205.8,73.5 L202.5,69.7 Z",
    translate: [15, 7, -0.2],
  },
  {
    venueId: 4169,
    name: "grass_1_",
    type: "grass",
    path:
      "M104,179.2 C100.7,179.2 97.1,178.9 93.3,174.4 L9.8,72.4 L16.3,62.1 L17.6,60.2 L24,52 L25.5,50.2 L34.6,40.8 L36.4,39.3 L44.8,32.4 L46.7,31 L48.9,29.6 L51.4,28 L53.8,26.4 L56.1,24.9 L58.3,23.6 L60.3,22.5 L62.4,21.6 L64.5,20.8 L66.8,20.2 L69.6,19.5 L72.9,18.5 L76.4,17 L79.8,15.1 L83.1,12.8 L86.4,9.8 L87.3,8.8 C87.3,8.8 93.6,7.4 98.5,8.6 C103.4,9.8 145.5,20.2 145.5,20.2 L151.6,22.8 L153.6,24.2 L158,28.8 L159.5,30.4 L168.6,41.9 L194.7,74.7 L195.5,75.7 L114.8,174.6 C113.5,176.4 108.9,179.2 104,179.2 Z",
    translate: [15, 9, -0.06],
  },
  {
    venueId: 4169,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M142,130.8 C141.8,131 136,102.7 104.9,102.7 C71.5,102.7 65.9,128.2 65.4,130.8 L65,131.2 L67.6,133.8 L79.8,146 L80.5,145.3 L98.7,163.2 C98,164.2 97.6,165.4 97.6,166.8 C97.6,168 98,169.2 98.6,170.2 C98.6,170.2 98.6,170.2 98.6,170.3 C99.7,172.1 101.7,173.3 104,173.3 C107.5,173.3 110.4,170.4 110.4,166.9 C110.4,165.4 109.9,164.1 109.1,163 L127.8,145.3 L129.3,145.8 L142.8,132.3 L142,130.8 Z M107.6,161.7 C106.6,161 105.3,160.5 103.9,160.5 C102.4,160.5 101.1,161 100,161.8 L82.4,143.6 C82.4,143.6 88.6,138 85.1,133.4 L102.5,118.9 C103,118.6 103.6,118.5 104.3,118.5 C105.2,118.5 106,119 106.6,119.6 C106.6,119.6 106.7,119.6 106.7,119.7 L122.9,135.2 C122.4,136.2 123,141.5 125.9,144.1 L107.6,161.7 Z",
    translate: [15, 10, 0],
  },

  {
    venueId: 22, // LAD
    name: "Outfield_1_",
    type: "sand",
    path:
      "M2.1,74 L0.8,69 L0.8,65.6 L1,62.3 L1.4,59.1 L2.1,56.1 L3,53.2 L4.1,50.4 L5.5,48 L7.1,45.8 L8.7,43.6 L10.8,42 L13,40.5 L15,39 L17.2,37.5 L19.4,36 L21.6,34.5 L23.8,33 L26,31.6 L28.2,30.2 L30.5,28.8 L32.8,27.4 L35,26 L37.3,24.6 L39.6,23.2 L41.9,21.8 L44.3,20.4 L46.7,19.1 L49.1,17.8 L51.5,16.5 L54,15.2 L56.5,14 L59,12.8 L61.6,11.7 L64.2,10.6 L66.8,9.5 L69.4,8.4 L72,7.3 L74.7,6.3 L77.4,5.3 L80.1,4.4 L82.9,3.5 L85.7,2.6 L88.5,1.8 L91.3,1.2 L94.2,0.8 L97.1,0.5 L100,0.8 L102.9,1.2 L105.7,1.9 L108.5,2.7 L111.3,3.5 L114,4.5 L116.7,5.5 L119.4,6.4 L122.1,7.5 L124.7,8.6 L127.3,9.7 L129.9,10.7 L132.5,11.8 L135,13 L137.5,14.2 L140,15.4 L142.5,16.6 L144.9,17.9 L147.3,19.2 L149.7,20.5 L152.1,21.8 L154.4,23.2 L156.7,24.6 L159,26 L161.3,27.4 L163.6,28.8 L165.8,30.2 L168,31.6 L170.2,33 L172.4,34.4 L174.6,35.8 L176.8,37.2 L179,38.7 L181.2,40.2 L183.4,41.7 L185.5,43.3 L187.1,45.6 L188.7,47.8 L190.2,50.1 L191.1,53 L192,55.9 L192.7,58.9 L193.1,62.1 L193.4,65.3 L193.3,68.8 L191.8,77.8 L168,102.3 C168,102.3 125.1,168.3 121.1,172.8 C117.9,176.4 111.6,187.9 95.8,187.9 C77.9,187.9 69.6,169.8 69.6,169.8 L27.9,99.4 L2.1,74 Z",
    translate: [22, 11.5, -0.2],
  },
  {
    venueId: 22,
    name: "grass_1_",
    type: "grass",
    path:
      "M95.8,180.2 C79.1,180.2 72.3,164.4 71.9,163.9 L35.1,95.8 L11.2,69.9 L10.7,68 L10.7,65.9 L10.9,63.1 L11.3,60.5 L11.8,58.1 L12.5,55.8 L13.2,53.9 L14.1,52.4 L15.5,50.5 L16.5,49.2 L19.6,45.6 L21.8,44.1 L26.1,41.2 L28.2,39.8 L30.4,38.4 L32.6,37 L34.8,35.6 L37.1,34.2 L43.9,30 L46.2,28.7 L48.4,27.4 L55.5,23.6 L57.8,22.4 L60.2,21.2 L62.5,20.1 L64.9,19 L67.4,17.9 L72.6,15.7 L75,14.7 L77.7,13.7 L80.3,12.7 L82.8,11.8 L85.6,10.9 L88.2,10 L90.5,9.3 L92.7,8.8 L95.2,8.5 L97.2,8.3 L99.1,8.5 L101.3,8.8 L103.7,9.4 L106.3,10.1 L108.8,10.8 L114.1,12.6 L116.7,13.5 L124.5,16.7 L127,17.7 L129.3,18.7 L134.2,21 L136.6,22.1 L138.9,23.3 L141.3,24.5 L143.6,25.7 L145.9,27 L148.1,28.3 L150.3,29.6 L154.8,32.4 L157.1,33.8 L159.3,35.2 L163.7,38 L165.9,39.4 L170.3,42.2 L172.4,43.5 L176.6,46.4 L178.6,47.8 L179.5,48.5 L181.9,51.9 L182.7,53.2 L184.1,57.7 L184.6,60.1 L185,62.8 L185.2,65.4 L185.2,67.3 L183.5,75.6 L158.3,102 C158.3,102 120.5,163.9 120.1,164.4 C117.4,167.4 111.6,179.8 96.3,179.8 L95.8,180.2 Z",
    translate: [22, 11.5, -0.06],
  },
  {
    venueId: 22,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M135,127.8 C134.8,128 129,99.7 97.9,99.7 C64.5,99.7 58.9,125.2 58.4,127.8 L58,128.2 L72.8,143 L73.5,142.3 L91.7,160.2 C91,161.2 90.6,162.4 90.6,163.8 C90.6,165 91,166.2 91.6,167.2 C91.6,167.2 91.6,167.2 91.6,167.3 C92.7,169.1 94.7,170.3 97,170.3 C100.5,170.3 103.4,167.4 103.4,163.9 C103.4,162.4 102.9,161.1 102.1,160 L120.8,142.3 L122.3,142.8 L135.8,129.3 L135,127.8 Z M100.6,158.7 C99.6,158 98.3,157.5 96.9,157.5 C95.4,157.5 94.1,158 93,158.8 L75.4,140.6 C75.4,140.6 81.6,135 78.1,130.4 L95.5,115.9 C96,115.6 96.6,115.5 97.3,115.5 C98.2,115.5 99,116 99.6,116.6 C99.6,116.6 99.7,116.6 99.7,116.7 L115.9,132.2 C115.4,133.2 116,138.5 118.9,141.1 L100.6,158.7 Z",
    translate: [22, 13, 0],
  },

  {
    venueId: 1, // LAA
    name: "Outfield_1_",
    type: "sand",
    path:
      "M98,191.5 C84.2,191.5 80.2,184.1 73.4,175.9 C65.1,165.8 38.1,122.6 34.2,117.6 C29.8,112 25.4,106.4 20.9,100.9 C17.1,96.1 13.3,91.4 9.6,86.7 C7.7,84.3 3.6,79.2 3.6,79.1 L1.2,71 L0.3,66.6 L0.2,62.9 L0.6,59.6 L1.4,56.7 L2.6,54.1 L4,51.7 L5.6,49.5 L7.4,47.6 L9.2,45.6 L11.1,43.6 L13,41.6 L14.9,39.6 L16.8,37.6 L18.7,35.6 L20.6,33.6 L22.6,31.5 L24.6,29.4 L26.6,27.3 L28.6,25.2 L30.7,23 L32.8,20.8 L35.1,19 L37.9,18.3 L40.6,17.6 L43.3,16.9 L46,16.2 L48.7,15.5 L51.4,14.8 L54,14 L56.7,13.3 L59.4,12.6 L62.1,11.9 L64.8,11.2 L67.5,10.5 L70.2,9.8 L73,9 L75.7,8.3 L78.5,7.6 L81.3,6.9 L84.1,6.2 L86.9,5.5 L89.7,4.8 L92.5,4.1 L95.4,3.3 L98.3,2.5 L101.2,1.7 L104.1,0.9 L107,0.9 L109.8,2.4 L112.6,3.9 L115.3,5.4 L118,6.9 L120.7,8.4 L123.3,9.8 L125.9,11.2 L128.5,12.6 L131,14 L133.5,15.4 L136,16.8 L138.5,18.1 L140.9,19.4 L143.3,20.7 L145.7,22 L148.1,23.3 L150.5,24.6 L152.9,25.9 L155.2,27.2 L157.5,28.5 L159.8,29.8 L162.1,31.1 L164.4,32.4 L166.7,33.7 L169,35 L171.3,36.3 L173.6,37.6 L175.9,38.9 L178.2,40.2 L180.5,41.5 L182.8,42.8 L185.1,44.1 L187,46 L188.9,47.9 L190.8,49.8 L192.4,52 L193.8,54.4 L195,57 L195.8,60 L196.1,63.4 L196.1,67.1 L195.2,71.5 L194.8,71.9 L159.5,121.8 C159.5,121.8 122.1,177.5 115.7,182.9 C110.8,187.1 104.8,191.5 98,191.5 Z",
    translate: [20.5, 8.5, -0.2],
  },
  {
    venueId: 1,
    name: "grass_1_",
    type: "grass",
    path:
      "M97.6,185.3 C86.7,185.3 80.4,177.5 76.4,171 C71.9,163.8 58.1,142.1 55,137.7 C55,137.7 41.8,117.9 41.8,117.9 L9.7,76 L6.8,69.2 L6.1,65.9 L6,63.2 L6.3,60.8 L6.9,58.7 L7.8,56.8 L9,55 L10.2,53.4 L13.6,49.8 L36.7,25.3 L37.7,24.5 L104.9,6.8 L105.5,6.8 L180.2,47.7 L181.8,48.6 L186.6,53.4 L187.7,54.9 L188.8,56.8 L189.6,58.7 L190.1,60.8 L190.4,63.4 L190.4,66.1 L189.9,68.6 L157.2,111.6 L122.9,163.4 C122.9,163.6 110.4,185.3 97.6,185.3 Z",
    translate: [20.5, 8.5, -0.06],
  },
  {
    venueId: 1,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M136,130.8 C135.8,131 130,102.7 98.9,102.7 C65.5,102.7 59.9,128.2 59.4,130.8 L59,131.2 L73.8,146 L74.5,145.3 L92.7,163.2 C92,164.2 91.6,165.4 91.6,166.8 C91.6,168 92,169.2 92.6,170.2 C92.6,170.2 92.6,170.2 92.6,170.3 C93.7,172.1 95.7,173.3 98,173.3 C101.5,173.3 104.4,170.4 104.4,166.9 C104.4,165.4 103.9,164.1 103.1,163 L121.8,145.3 L123.3,145.8 L136.8,132.3 L136,130.8 Z M101.6,161.7 C100.6,161 99.3,160.5 97.9,160.5 C96.4,160.5 95.1,161 94,161.8 L76.4,143.6 C76.4,143.6 82.6,138 79.1,133.4 L96.5,118.9 C97,118.6 97.6,118.5 98.3,118.5 C99.2,118.5 100,119 100.6,119.6 C100.6,119.6 100.7,119.6 100.7,119.7 L116.9,135.2 C116.4,136.2 117,141.5 119.9,144.1 L101.6,161.7 Z",
    translate: [21, 10, 0],
  },

  {
    venueId: 7, // KCR
    name: "Outfield_1_",
    type: "sand",
    path:
      "M98.1,192.6 C80.4,192.6 70.4,180.3 70.4,180.3 C70.4,180.3 48,145.8 41.3,141 C29.8,132.8 2.1,76.4 2.1,76.4 L1.6,74.8 L0.2,69.9 L0,66.1 L0.4,62.8 L1.2,59.9 L2.5,57.4 L4.2,55.4 L6,53.4 L7.8,51.4 L9.6,49.4 L11.4,47.4 L13.2,45.3 L15,43.2 L16.8,41 L18.6,38.9 L20.5,36.8 L22.4,34.7 L24.5,32.8 L26.7,31 L28.9,29.2 L31.1,27.4 L33.3,25.6 L35.6,23.9 L37.9,22.2 L40.3,20.6 L42.7,19 L45.2,17.4 L47.7,15.8 L50.2,14.3 L52.8,12.9 L55.4,11.5 L58.1,10.2 L60.8,8.9 L63.5,7.7 L66.3,6.7 L69.1,5.6 L71.9,4.7 L74.8,3.9 L77.7,3.1 L80.6,2.5 L83.5,2 L86.4,1.5 L89.4,1.1 L92.4,0.9 L95.4,0.8 L98.4,0.8 L101.4,0.8 L104.4,0.9 L107.4,1.3 L110.4,1.7 L113.4,2 L116.3,2.6 L119.2,3.2 L122.1,3.8 L125,4.7 L127.8,5.7 L130.6,6.8 L133.4,7.9 L136.1,9.2 L138.7,10.6 L141.3,12 L143.9,13.4 L146.5,14.8 L149.1,16.2 L151.6,17.7 L154,19.3 L156.4,20.9 L158.8,22.5 L161.2,24.1 L163.5,25.8 L165.7,27.6 L167.9,29.4 L170.1,31.2 L172.3,33 L174.3,35 L176.2,37.1 L178.1,39.2 L180,41.3 L181.9,43.4 L183.7,45.4 L185.5,47.4 L187.3,49.4 L189.1,51.4 L190.9,53.4 L192.6,55.4 L194.3,57.4 L195.4,60.1 L196.2,63.1 L196.6,66.4 L196.3,70.3 L194.9,75.1 L193,81.4 C187.2,95.9 162.6,134 154,139.2 C145.4,144.3 127.3,179.8 127.3,179.8 C127.3,179.8 115.8,192.6 98.1,192.6 Z",
    translate: [20.5, 5, -0.2],
  },
  {
    venueId: 7,
    name: "grass_1_",
    type: "grass",
    path:
      "M99.1,184.6 C89.3,184.6 81.7,181.1 77.9,178.2 C72.9,174.4 47,134.6 47,134.6 L33.6,115.8 L9.5,73.3 L8.1,68.6 L8,66.3 L8.2,64.2 L8.6,62.7 L9.1,61.8 L10.2,60.5 L11.9,58.6 L15.5,54.5 L17.3,52.4 L19.1,50.3 L21,48.1 L22.8,46 L24.6,43.9 L26.4,41.9 L28,40.1 L29.7,38.6 L31.8,36.9 L36,33.4 L38.1,31.7 L40.3,30 L42.4,28.4 L46.9,25.3 L51.6,22.3 L54,20.9 L56.4,19.5 L58.8,18.2 L63.9,15.7 L66.3,14.6 L71.4,12.7 L73.9,11.9 L79.1,10.4 L81.5,9.9 L87.2,9 L89.8,8.6 L92.4,8.4 L95,8.3 L100.7,8.4 L103.2,8.5 L111.3,9.5 L113.8,10.1 L119.2,11.3 L121.5,12 L124.1,12.9 L129.3,14.9 L131.6,16 L144.2,22.7 L146.3,24 L155.7,30.3 L157.7,31.8 L166.1,38.6 L167.7,40.1 L169.4,42 L171.3,44.2 L173.2,46.3 L180.5,54.4 L182.3,56.4 L184,58.4 L185.6,60.3 L186.5,61.3 L186.9,62.3 L187.4,64.3 L187.7,66.3 L187.5,68.6 L186.4,72.6 L184.6,78.5 C181.6,85.9 176.2,94.3 171.9,101.1 C167.2,108.4 162.2,116.4 162.2,116.4 L149.4,132.1 L131,160.8 C131,160.8 121.3,174.3 118.7,176.7 C116.1,179.1 108.6,184.6 99.1,184.6 Z",
    translate: [20.5, 5, -0.06],
  },
  {
    venueId: 7,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M136,133.8 C135.8,134 130,105.7 98.9,105.7 C65.5,105.7 59.9,131.2 59.4,133.8 L59,134.2 L73.8,149 L74.5,148.3 L92.7,166.2 C92,167.2 91.6,168.4 91.6,169.8 C91.6,171 92,172.2 92.6,173.2 C92.6,173.2 92.6,173.2 92.6,173.3 C93.7,175.1 95.7,176.3 98,176.3 C101.5,176.3 104.4,173.4 104.4,169.9 C104.4,168.4 103.9,167.1 103.1,166 L121.8,148.3 L123.3,148.8 L136.8,135.3 L136,133.8 Z M101.6,164.7 C100.6,164 99.3,163.5 97.9,163.5 C96.4,163.5 95.1,164 94,164.8 L76.4,146.6 C76.4,146.6 82.6,141 79.1,136.4 L96.5,121.9 C97,121.6 97.6,121.5 98.3,121.5 C99.2,121.5 100,122 100.6,122.6 C100.6,122.6 100.7,122.6 100.7,122.7 L116.9,138.2 C116.4,139.2 117,144.5 119.9,147.1 L101.6,164.7 Z",
    translate: [20.5, 7, 0],
  },

  {
    venueId: 2392, // HOU
    name: "Outfield_1_",
    type: "sand",
    path:
      "M95.7,190.2 C84.3,190.2 75.9,180.5 75.9,180.5 L30.5,125.4 L31.5,112.5 L0.2,81.2 L2.5,78.5 L4,77 L5.6,75.4 L7.2,73.8 L8.8,72.2 L10.4,70.6 L12,69 L13.6,67.4 L15.3,65.8 L17,64.2 L18.7,62.6 L20.4,60.9 L22.1,59.2 L23.8,57.5 L25.5,55.8 L27.3,54.1 L29.2,52.6 L31.1,51.1 L33,49.6 L34.9,48.1 L36.8,46.6 L38.7,45 L37,34.5 L38.9,32.3 L40.9,30.1 L42.9,27.9 L64.3,12.7 L66.8,10.7 L69.4,8.6 L72,6.5 L74.7,4.4 L77.4,2.2 L80.3,0.8 L83.3,0.8 L86.3,0.8 L89.3,0.8 L92.3,0.8 L95.3,0.8 L98.3,0.8 L101.3,0.8 L104.3,0.8 L107.3,0.8 L110.3,0.8 L113.3,0.8 L116.3,0.8 L119.3,0.8 L122.3,0.8 L125.4,0.8 L128,3.3 L130.4,6.4 L133,8.2 L135.3,11 L137.5,13.8 L139.7,16.5 L141.8,19.1 L143.9,21.7 L145.9,24.2 L147.9,26.7 L150.4,27.6 L153.1,28 L155,30.4 L156.9,32.7 L158.7,35 L160.5,37.3 L162.3,39.5 L164.1,41.7 L165.8,43.9 L167.5,46 L169.2,48.1 L170.9,50.2 L172.5,52.2 L174.1,54.2 L175.7,56.2 L177.3,58.2 L178.9,60.2 L180.5,62.1 L182.1,64 L183.6,65.9 L185.1,67.8 L186.6,69.7 L188.1,71.6 L189.6,73.5 L191.1,75.4 L192.6,77 L169.1,100.2 C169.1,100.2 170.9,112.4 162.4,120.8 C157.1,126 114.7,180.7 114.7,180.7 C114.7,180.7 107.1,190.2 95.7,190.2 Z",
    translate: [24, 6, -0.2],
  },
  {
    venueId: 2392,
    name: "grass_1_",
    type: "grass",
    path:
      "M95.7,182.2 C88.5,182.2 82.4,175.9 82,175.3 L36.4,120.9 L37.4,110.5 L15.3,87.9 L15.3,77.2 L31.3,61.5 L32.8,60.1 L48.9,47 L47.1,34.9 L82,8.7 L121.8,8.8 L142.8,33.4 L148.1,35.4 L148.6,35.5 L179,73.3 L178.3,79.6 L164.8,92.5 C164.8,92.5 166.9,101.5 159.7,111.5 C155.3,117.7 115.3,166.4 108.2,175.6 L108.2,175.6 C108.2,175.7 102.9,182.2 95.7,182.2 Z",
    translate: [24, 6, -0.06],
  },
  {
    venueId: 2392,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M133,133.8 C132.8,134 127,105.7 95.9,105.7 C62.5,105.7 56.9,131.2 56.4,133.8 L56,134.2 L70.8,149 L71.5,148.3 L87,163.6 C86.6,164.7 86.3,165.8 86.3,167 C86.3,167.8 86.4,168.5 86.6,169.2 L77.4,172.9 L82,177.9 L89.1,173.5 C89.6,173.9 90.1,174.3 90.7,174.7 C91.8,175.7 93.3,176.3 94.9,176.3 C96.2,176.3 97.4,175.9 98.4,175.2 C99.5,174.7 100.6,174.1 101.4,173.2 L109,177.9 L113.6,172.9 L103.8,168.9 C103.9,168.3 104,167.6 104,167 C104,165.6 103.7,164.3 103.1,163.1 L118.7,148.3 L120.2,148.8 L133.7,135.3 L133,133.8 Z M100.7,162.7 L89,162.7 L73.4,146.6 C73.4,146.6 79.6,141 76.1,136.4 L93.5,121.9 C94,121.6 94.6,121.5 95.3,121.5 C96.2,121.5 97,122 97.6,122.6 C97.6,122.6 97.7,122.6 97.7,122.7 L113.9,138.2 C113.4,139.2 114,144.5 116.9,147.1 L100.7,162.7 Z",
    translate: [24, 7, 0],
  },

  {
    venueId: 2394, // DET
    name: "Outfield_1_",
    type: "sand",
    path:
      "M121.3,192.4 C106.6,207.5 91.1,201.3 84.6,195.1 C78.1,188.9 52.6,162.6 52.6,162.6 L17.3,104.2 L17.6,100.6 L0.2,83 L0.2,77.6 L0.5,77.8 L2.2,75.9 L4,74.1 L5.8,72.3 L7.6,70.5 L9.4,68.7 L11.2,66.9 L13,65.1 L14.8,63.3 L16.6,61.5 L18,60 L19.9,58.2 L21.8,56.3 L23.7,54.4 L25.6,52.5 L27.5,50.6 L29.4,48.7 L31.4,46.8 L33.4,44.8 L35.4,42.8 L37.4,40.8 L39.4,38.8 L41.5,36.7 L43.6,34.6 L45.7,32.5 L47.9,30.4 L50.1,28.2 L52.3,26 L54.6,23.7 L56.9,21.4 L59.3,19.1 L61.7,16.7 L64.2,14.3 L66.6,11.8 L69.1,9.3 L71.6,6.8 L74.2,4.2 L77,2.2 L80.1,2.1 L83.2,2 L86.2,1.9 L89.2,1.8 L92.2,1.7 L95.2,1.6 L98.2,1.5 L101.2,1.4 L104.2,1.3 L107.2,1.2 L110.2,1.1 L113.2,1 L116.3,0.9 L119.4,0.8 L122.5,0.7 L125.6,0.6 L128.7,0.5 L131.7,1.4 L134.3,4 L136.8,6.5 L139.3,9 L141.8,11.5 L144.2,14 L146.6,16.4 L149,18.8 L151.3,21.1 L153.6,23.4 L155.9,25.6 L157.5,29.4 L158.6,34.2 L160.1,37.7 L162.1,39.7 L164.1,41.7 L166.1,43.7 L168.1,45.7 L170,47.6 L171.9,49.5 L173.8,51.4 L175.7,53.3 L177.6,55.2 L179.5,57.1 L181.3,58.9 L183.1,60.7 L184.9,62.5 L186.7,64.3 L188.5,66.1 L190.4,67.8 L192.2,69.6 L194,71.4 L195.7,73.2 L197.4,75.1 L198.4,77.6 L198.5,80.9 L198.5,86.1 L177.7,107.1 L177.7,121 L152.2,158.8 C151.3,158.8 136,177.4 121.3,192.4 Z",
    translate: [17.2, 0, -0.2],
  },
  {
    venueId: 2394,
    name: "grass_1_",
    type: "grass",
    path:
      "M101.2,194.8 C95.6,194.8 88.5,187.4 87.3,186.3 C81.5,180.8 57.9,155.8 53.9,151.7 L31.5,114.2 L25.3,102.4 L26.1,98.9 L8.3,81 L78.7,11.1 L79,10.9 L127.8,10 L149.4,31.4 L150.1,33.1 L151.2,37.9 L153.6,43.6 L182.8,72.8 L184.6,74.4 L189.4,79.4 L189.9,80 L190,81.9 L190,83.7 L171.8,102.2 L172.1,119.3 L171.2,120.5 L144.8,156.4 C144.7,156.5 131.5,171.3 117.1,186.1 C112.5,190.4 105.6,194.8 101.2,194.8 Z",
    translate: [17.2, 0, -0.06],
  },
  {
    venueId: 2394,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M139,140.8 C138.8,141 133,112.7 101.9,112.7 C68.5,112.7 62.9,138.2 62.4,140.8 L62,141.2 L76.8,156 L77.5,155.3 L95.7,173.2 C95,174.2 94.6,175.4 94.6,176.8 C94.6,178 95,179.2 95.6,180.2 C95.6,180.2 95.6,180.2 95.6,180.3 C96.7,182.1 98.7,183.3 101,183.3 C104.5,183.3 107.4,180.4 107.4,176.9 C107.4,175.4 106.9,174.1 106.1,173 L124.8,155.3 L126.3,155.8 L139.8,142.3 L139,140.8 Z M104.6,171.8 C103.6,171.1 102.3,170.6 100.9,170.6 C99.4,170.6 98.1,171.1 97,171.9 L79.4,153.7 C79.4,153.7 85.6,148.1 82.1,143.5 L99.5,129 C100,128.7 100.6,128.6 101.3,128.6 C102.2,128.6 103,129.1 103.6,129.7 C103.6,129.7 103.7,129.7 103.7,129.8 L119.9,145.3 C119.4,146.3 120,151.6 122.9,154.2 L104.6,171.8 Z",
    translate: [18, 0, 0],
  },

  {
    venueId: 4, // CWS
    name: "Outfield_1_",
    type: "sand",
    path:
      "M0.4,71.2 L1.4,70.6 L2.8,68.6 L4.2,66.6 L5.6,64.6 L7,62.6 L8.4,60.5 L9.9,58.4 L11.4,56.3 L12.9,54.2 L14.4,52.1 L15.9,49.9 L17.4,47.7 L19.1,45.6 L21,43.8 L22.9,42 L24.8,40.2 L26.7,38.4 L28.6,36.6 L30.5,34.7 L32.5,32.8 L34.5,30.9 L36.5,29 L38.5,27 L40.6,25 L42.7,23 L44.8,21 L47,19 L49.3,17.3 L51.7,15.7 L54.1,14.1 L56.6,12.7 L59.1,11.3 L61.7,9.9 L64.3,8.8 L67,7.8 L69.7,6.8 L72.4,5.8 L75.1,4.5 L77.8,2.9 L80.5,1.3 L83.4,0.8 L86.3,0.8 L89.2,0.8 L92.1,0.8 L95,0.8 L97.9,0.8 L100.8,0.8 L103.7,0.8 L106.6,0.8 L109.5,0.8 L112.4,0.8 L115.3,1.2 L118,2.8 L120.7,4.4 L123.4,5.6 L126.1,6.6 L128.8,7.6 L131.5,8.6 L134.2,9.6 L136.7,11.1 L139.2,12.6 L141.7,14 L144.2,15.4 L146.7,16.8 L149.1,18.3 L151.3,20.2 L153.5,22 L155.7,23.8 L157.7,26 L159.7,28.1 L161.7,30.2 L163.6,32.3 L165.5,34.3 L167.4,36.3 L169.3,38.3 L171.1,40.3 L172.9,42.3 L174.7,44.3 L176.4,46.3 L178.1,48.2 L179.8,50.1 L181.5,52 L183.2,53.9 L184.9,55.8 L186.6,57.7 L188.3,59.6 L189.9,61.4 L191.5,63.2 L193.1,65 L194.7,66.8 L196.3,68.6 L197.3,70.5 L187.6,80.8 L150.4,140 L123,172.2 C123,172.2 109.6,186.5 98.4,186.8 C82.1,186.8 71.5,170.4 71.5,170.4 L46.6,141.1 L41,130.3 L11.7,81.9 L0.4,71.2 Z",
    translate: [21, 10, -0.2],
  },
  {
    venueId: 4,
    name: "grass_1_",
    type: "grass",
    path:
      "M83.1,172.5 L50.6,134.5 L18.8,80.1 C18.8,80.1 14.1,74.3 13.3,72.7 C12.4,71.1 12.5,69.3 12.5,69.3 C12.5,69.3 12.1,68.4 13.9,65.3 C15.9,61.9 19.3,56.9 25,51.2 C35.9,40.3 52.2,25.2 52.2,25.2 L54,23.8 L56.2,22.3 L58.2,21 C58.2,21 79.6,7.6 99.2,7.6 C110.6,7.6 121.3,10.7 132.8,15.5 C144.3,20.2 163.5,43.9 163.5,43.9 L183.9,66.6 L185.1,70.1 L184.4,74 L182.8,76.7 L161.5,112.7 L145.5,137 L112.8,172.1 C110.4,174.6 104.3,180.8 97.1,180.8 C89.9,180.8 85,174.8 83.1,172.5 Z",
    translate: [21, 10, -0.06],
  },
  {
    venueId: 4,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M136,129.8 C135.8,130 130,101.7 98.9,101.7 C65.5,101.7 59.9,127.2 59.4,129.8 L59,130.2 L73.8,145 L74.5,144.3 L92.7,162.2 C92,163.2 91.6,164.4 91.6,165.8 C91.6,167 92,168.2 92.6,169.2 C92.6,169.2 92.6,169.2 92.6,169.3 C93.7,171.1 95.7,172.3 98,172.3 C101.5,172.3 104.4,169.4 104.4,165.9 C104.4,164.4 103.9,163.1 103.1,162 L121.8,144.3 L123.3,144.8 L136.8,131.3 L136,129.8 Z M101.6,160.7 C100.6,160 99.3,159.5 97.9,159.5 C96.4,159.5 95.1,160 94,160.8 L76.4,142.6 C76.4,142.6 82.6,137 79.1,132.4 L96.5,117.9 C97,117.6 97.6,117.5 98.3,117.5 C99.2,117.5 100,118 100.6,118.6 C100.6,118.6 100.7,118.6 100.7,118.7 L116.9,134.2 C116.4,135.2 117,140.5 119.9,143.1 L101.6,160.7 Z",
    translate: [21, 11, 0],
  },

  {
    venueId: 19, // COL
    name: "Outfield_1_",
    type: "sand",
    path:
      "M0.4,78 L3.8,74 L5.4,72 L7,70 L8.6,68 L10.2,66 L11.9,64 L13.6,62 L15.3,60 L17,58 L18.7,56 L20.4,53.9 L22.1,51.8 L23.9,49.7 L25.7,47.6 L27.5,45.5 L29.3,43.3 L31.1,41.1 L33,38.9 L34.9,36.6 L36.8,34.3 L38.8,32 L40.8,29.6 L42.8,27.2 L44.9,24.8 L47,22.3 L49.1,19.8 L51.3,17.2 L53.5,14.6 L55.8,11.9 L58.1,9.1 L60.8,7.3 L63.9,7 L67,6.7 L70,6.4 L73,6.1 L76,5.8 L79,5.5 L82,5.2 L85,4.9 L88,4.6 L91,4.3 L94,4 L97,3.7 L100,3.4 L103,3.1 L106,2.8 L109,2.5 L112,2.2 L115,1.9 L118.1,1.6 L121.2,1.3 L124.3,1 L127.4,0.8 L130.4,1.8 L133.1,4.1 L135.8,6.3 L138.4,8.5 L141,10.7 L143.5,12.8 L146,14.9 L148.4,16.9 L150.8,18.9 L153.2,20.9 L155.5,22.8 L157.8,24.7 L160.1,26.6 L162.3,28.5 L164.5,30.3 L166.7,32.1 L168.9,33.9 L171,35.7 L173.1,37.5 L175.2,39.3 L177.3,41 L179.4,42.7 L181.5,44.4 L183.5,46.1 L185.5,47.8 L187.5,49.5 L189.5,51.2 L191.5,52.9 L193.5,54.6 L195.5,56.3 L197.5,58 L199.5,59.7 L201.5,61.4 L203.5,63.1 L205.5,64.8 L207.5,66.5 L208.5,69.2 L208.8,72.5 L209,78.3 L187.5,99.9 L187.9,103.6 L170,133.3 L124.8,186.7 C124.8,186.7 117.8,196.6 105.9,196.6 C94,196.6 90.5,190.8 86.9,187.9 L40.8,135.2 L17.2,93.9 L0.4,78 Z",
    translate: [13, 1, -0.2],
  },
  {
    venueId: 19,
    name: "grass_1_",
    type: "grass",
    path:
      "M106,188.6 C99.4,188.6 97.2,185.5 94.4,182.8 C94.2,182.6 91.3,182.1 88.5,180.2 C84.4,177.3 47.7,130.8 47.7,130.8 L31.1,101.1 L11.4,77.4 L14.9,73.2 L63.6,15.2 L63.6,15.2 L122,10.1 L125.1,9.8 L126.5,9.7 L126.5,9.7 L200.9,71.9 L201.1,73.7 L201.2,75.8 L179.5,97.6 L181,104.4 L163.7,132.4 C163.7,132.4 124.3,178.3 122.5,179.8 C120.7,181.3 116.1,182.4 116.1,182.4 C115.9,182.7 113,188.6 106,188.6 Z",
    translate: [13, 1, -0.06],
  },
  {
    venueId: 19,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M144,138.8 C143.8,139 138,110.7 106.9,110.7 C73.5,110.7 67.9,136.2 67.4,138.8 L67,139.2 L81.8,154 L82.5,153.3 L100.7,171.2 C100,172.2 99.6,173.4 99.6,174.8 C99.6,176 100,177.2 100.6,178.2 C100.6,178.2 100.6,178.2 100.6,178.3 C101.7,180.1 103.7,181.3 106,181.3 C109.5,181.3 112.4,178.4 112.4,174.9 C112.4,173.4 111.9,172.1 111.1,171 L129.8,153.3 L131.3,153.8 L144.8,140.3 L144,138.8 Z M109.6,169.7 C108.6,169 107.3,168.5 105.9,168.5 C104.4,168.5 103.1,169 102,169.8 L84.4,151.6 C84.4,151.6 90.6,146 87.1,141.4 L104.5,126.9 C105,126.6 105.6,126.5 106.3,126.5 C107.2,126.5 108,127 108.6,127.6 C108.6,127.6 108.7,127.6 108.7,127.7 L124.9,143.2 C124.4,144.2 125,149.5 127.9,152.1 L109.6,169.7 Z",
    translate: [13, 2, 0],
  },

  {
    venueId: 2602, // CIN
    name: "Outfield_1_",
    type: "sand",
    path:
      "M77.6,184.1 C74.2,182.3 51.7,149.3 48.5,144.6 L39.3,117.7 L0.7,79 L0.5,73.5 L1.1,70.7 L1.7,67.9 L3,65.6 L4.8,63.8 L6.6,62 L8.4,60.2 L10.2,58.4 L12,56.6 L13.8,54.8 L15.6,53 L17.4,51.2 L19.3,49.4 L21.2,47.6 L23.1,45.8 L25,43.9 L26.9,42 L28.8,40.1 L30.8,38.2 L32.8,36.3 L34.8,34.4 L36.8,32.4 L38.9,30.4 L41,28.4 L43.1,26.3 L45.2,24.2 L47.4,22.1 L49.6,19.9 L51.8,17.7 L54.1,15.5 L56.5,13.3 L59.1,12.4 L61.8,11.6 L64.5,10.8 L67.2,10 L69.9,9.2 L72.6,8.4 L75.3,7.6 L78,6.8 L80.7,5.9 L83.5,5 L86.3,4.1 L89.1,3.2 L91.9,2.3 L94.8,1.4 L97.7,0.5 L100.6,1.2 L103.4,2.3 L106.2,3.4 L109,4.5 L111.8,5.6 L114.5,6.8 L117.2,8 L119.9,9.2 L122.5,10.5 L125.1,11.8 L127.7,13.1 L130.2,14.5 L132.7,15.9 L135.2,17.3 L137.6,18.8 L140,20.4 L142.4,22 L144.8,23.6 L147.2,25.2 L149.5,26.8 L151.7,28.5 L153.9,30.2 L156.1,31.9 L158.2,33.7 L160.3,35.5 L162.4,37.3 L164.4,39.2 L166.3,41.1 L168.2,43 L170.1,44.9 L172,46.8 L173.8,48.7 L175.5,50.7 L177.2,52.7 L178.9,54.7 L180.6,56.7 L182.3,58.6 L184,60.5 L185.6,62.4 L187.2,64.3 L188.8,66.2 L190.3,68.2 L191.4,70.6 L192.6,72.9 L193.9,75.1 L192.8,86.5 C190.8,89.4 165,115.4 165,115.4 C165,115.4 145.6,153.4 140.7,160.3 C135.8,167.3 120.7,187.2 113.8,190.5 C106.9,193.7 102.4,194.1 97,193.4 C82.5,191.4 77.6,184.1 77.6,184.1 Z",
    translate: [21.5, 7.5, -0.2],
  },
  {
    venueId: 2602,
    name: "grass_1_",
    type: "grass",
    path:
      "M99.8,185.2 C98.9,185.2 97.9,185.1 96.7,185 C88.3,184 84.7,180.2 84.2,179.6 L83.1,177.8 L81.9,177.4 C80,175.4 74.4,168.3 56.2,141.9 L55.7,141.1 L46.2,113.5 L8.5,75.7 L8.4,74.3 L9.1,70.9 L9.2,70.7 L58.5,22.7 L59.5,21.8 L60.5,21.5 L63,20.7 L97.2,10.4 L97.6,10.5 L108.4,14.5 L111,15.6 L113.6,16.7 L116.1,17.8 L123.6,21.4 L126,22.7 L128.5,24.1 L130.9,25.4 L133.1,26.7 L140.1,31.2 L142.3,32.7 L144.4,34.2 L148.6,37.3 L150.6,38.9 L152.7,40.6 L154.8,42.3 L156.7,43.9 L158.5,45.6 L165.9,52.8 L167.5,54.5 L174.1,62.1 L177.4,65.9 L179,67.8 L182,71.3 L182.9,72.5 L183.7,74.1 L185,76.6 L185.1,76.7 L184.5,83.1 C180.6,87.4 169.1,99.2 158.7,109.6 L157.8,110.5 L157.2,111.6 C149.8,126.1 136.8,150.8 133.5,155.5 C124.2,168.5 113.1,181.5 109.7,183.1 C104.5,184.9 101.9,185.2 99.8,185.2 Z",
    translate: [21.5, 7.5, -0.06],
  },
  {
    venueId: 2602,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M135,132.8 C134.8,133 129,104.7 97.9,104.7 C64.5,104.7 58.9,130.2 58.4,132.8 L58,133.2 L72.8,148 L73.5,147.3 L91.7,165.2 C91,166.2 90.6,167.4 90.6,168.8 C90.6,170 91,171.2 91.6,172.2 C91.6,172.2 91.6,172.2 91.6,172.3 C92.7,174.1 94.7,175.3 97,175.3 C100.5,175.3 103.4,172.4 103.4,168.9 C103.4,167.4 102.9,166.1 102.1,165 L120.8,147.3 L122.3,147.8 L135.8,134.3 L135,132.8 Z M100.6,163.8 C99.6,163.1 98.3,162.6 96.9,162.6 C95.4,162.6 94.1,163.1 93,163.9 L75.4,145.7 C75.4,145.7 81.6,140.1 78.1,135.5 L95.5,121 C96,120.7 96.6,120.6 97.3,120.6 C98.2,120.6 99,121.1 99.6,121.7 C99.6,121.7 99.7,121.7 99.7,121.8 L115.9,137.3 C115.4,138.3 116,143.6 118.9,146.2 L100.6,163.8 Z",
    translate: [21.5, 8.5, 0],
  },

  {
    venueId: 17, //CHC
    name: "Outfield_1_",
    type: "sand",
    path:
      "M40.1,113.8 L22.8,86.6 L0.9,64.8 L2.2,65.4 L2.7,62.3 L4.7,60.7 L6.7,59.1 L8.7,57.5 L10.7,55.9 L12.7,54.3 L14.7,52.7 L16.7,51.1 L18.7,49.5 L20.7,47.9 L22.7,46.3 L24.7,44.7 L26.7,43.1 L28.7,41.4 L30.7,39.7 L34.2,40.7 L36.8,40.2 L38.9,38.7 L41.1,37.4 L43.3,36.1 L45.5,34.8 L47.7,33.5 L49.9,32.2 L52.1,30.9 L54.3,29.5 L56.6,28.1 L58.9,26.7 L61.2,25.3 L63.5,23.9 L65.8,22.5 L68.2,21.1 L70.6,19.7 L73,18.2 L75.4,16.7 L77.9,15.2 L80.4,13.7 L82.9,12.2 L85.5,10.6 L88.1,9 L90.8,7.4 L93.5,6 L96.3,4.9 L99.1,3.9 L102,3.1 L104.9,2.6 L107.8,2.1 L110.7,1.6 L113.6,1.3 L116.5,1.2 L119.4,1.1 L122.3,1.6 L125.2,2.3 L128.1,2.8 L130.9,3.6 L133.7,4.4 L136.5,5.4 L139.2,6.8 L142,8 L144.6,9.6 L147.1,11.2 L149.5,13 L151.8,15 L154.1,16.9 L156.3,19 L158.5,21.1 L160.6,23.2 L162.7,25.3 L164.8,27.3 L166.8,29.3 L168.8,31.3 L170.8,33.3 L172.8,35.2 L174.7,37.1 L176.6,39 L178.5,40.9 L180.4,42.8 L183,43.5 L187,42.1 L189.9,42.4 L191.8,44.4 L193.5,46.5 L195.2,48.6 L196.9,50.7 L198.6,52.8 L200.3,54.9 L202,56.9 L203.6,58.9 L205.2,60.9 L206.8,62.9 L208.4,64.9 L186.2,87.2 C186.2,87.2 144.5,153.8 140.8,160.4 C137.8,165.9 127.5,173.6 114.9,185 C107.6,191.6 99.7,192.5 92,183.3 L40.1,113.8 Z",
    translate: [14, 8.8, -0.2],
  },
  {
    venueId: 17,
    name: "grass_1_",
    type: "grass",
    path:
      "M102.5,182.3 C102.1,182.3 100.1,181.3 98.4,179.4 L45.8,109.4 C45.8,109.4 15.4,74.3 16,68.5 C16.5,62.7 38.3,48.2 38.3,48.2 L45.8,47.3 L49,45 L50.9,43.9 L94.9,15.9 L97.1,14.8 L99.3,13.9 L101.7,13.1 L103.9,12.5 L109.3,11.6 L111.9,11.2 L114.3,11 L119.1,10.9 L120.9,11.2 L123.7,11.9 L126.4,12.4 L130.4,13.9 L132.5,14.7 L134.6,15.8 L137,17.1 L139.4,18.6 L141.6,20 L143.6,21.5 L147.8,25.1 L149.8,27 L173.7,51.2 C173.7,51.2 176.6,53.8 178.5,54.3 C180.4,54.8 182.9,54.6 184,55 C185.1,55.4 194,67.8 194,69.9 C194,70.4 190.5,74.3 190.5,74.3 L173.8,90.4 L173.4,91.1 C168.9,97.9 137.7,152.1 134,158.8 C132.1,162.1 126.8,166.4 118.8,173.7 C116.8,175.5 113.7,177.4 111.5,179.4 C106.4,183.6 103.6,182.3 102.5,182.3 Z",
    translate: [14, 8.8, -0.06],
  },
  {
    venueId: 17,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M143,130.8 C142.8,131 137,102.7 105.9,102.7 C72.5,102.7 66.9,128.2 66.4,130.8 L66,131.2 L80.8,146 L81.5,145.3 L99.7,163.2 C99,164.2 98.6,165.4 98.6,166.8 C98.6,168 99,169.2 99.6,170.2 C99.6,170.2 99.6,170.2 99.6,170.3 C100.7,172.1 102.7,173.3 105,173.3 C108.5,173.3 111.4,170.4 111.4,166.9 C111.4,165.4 110.9,164.1 110.1,163 L128.8,145.3 L130.3,145.8 L143.8,132.3 L143,130.8 Z M108.6,161.7 C107.6,161 106.3,160.5 104.9,160.5 C103.4,160.5 102.1,161 101,161.8 L83.4,143.6 C83.4,143.6 89.6,138 86.1,133.4 L103.5,118.9 C104,118.6 104.6,118.5 105.3,118.5 C106.2,118.5 107,119 107.6,119.6 C107.6,119.6 107.7,119.6 107.7,119.7 L123.9,135.2 C123.4,136.2 124,141.5 126.9,144.1 L108.6,161.7 Z",
    translate: [14, 10, 0],
  },

  {
    venueId: 3, // BOS
    name: "Outfield_1_",
    type: "sand",
    path:
      "M38.1,141.9 L34.4,115.7 L0.2,80.3 L1.8,78.7 L3.4,77.1 L5,75.5 L6.6,73.9 L8.2,72.3 L9.8,70.7 L11.4,69.1 L13,67.5 L14.6,65.9 L16.2,64.3 L17.9,62.7 L19.6,61.1 L21.3,59.5 L23,58 L24.7,56.3 L26.4,54.6 L28.1,52.9 L29.9,51.2 L31.7,49.5 L33.5,47.8 L35.3,46 L37.1,44.2 L39,42.4 L40.9,40.6 L42.8,38.7 L44.7,36.8 L46.7,34.9 L48.7,32.9 L50.8,30.9 L52.9,28.9 L55,26.8 L57.2,24.7 L59.4,22.5 L61.7,20.3 L64,18 L66.4,15.7 L68.9,13.8 L71.7,13.8 L74.5,13.6 L77.2,12.9 L79.9,12.2 L82.6,11.5 L85.4,10.8 L88.2,10.1 L91,9.4 L93.8,8.7 L96.7,7.9 L99.6,7.1 L102.5,6.3 L105.5,5.5 L108.5,4.7 L111.5,3.9 L114.6,3.1 L117.7,2.3 L120.9,1.5 L124.1,0.7 L126.3,4.7 L127.9,10.8 L129.4,16.5 L131.9,18.1 L134.4,19.3 L136.9,20.5 L139.4,21.7 L141.9,22.9 L144.4,24.1 L146.9,25.3 L149.4,26.5 L151.9,27.7 L154.4,28.9 L156.9,30.1 L159.4,31.3 L161.9,32.5 L164.4,33.7 L166.9,34.9 L169.4,36.1 L171.9,37.3 L174.4,38.5 L176.9,39.7 L179.4,40.9 L181.9,42.1 L184.3,43.4 L186.5,45.1 L188.4,47.2 L189.9,49.8 L190.9,52.9 L191.1,56.8 L189.7,62.4 L185.8,70.3 L182.3,77.4 L179.2,83.7 L155.5,110.7 L137.4,146.8 C137.4,146.8 106.8,184.6 104,188.4 C101.2,192.2 83.6,192.3 80.1,188.7 C76.4,184.6 38.1,141.9 38.1,141.9 Z",
    translate: [28, 6, -0.2],
  },
  {
    venueId: 3,
    name: "grass_1_",
    type: "grass",
    path:
      "M46.2,139.3 L41.9,112 L11.4,80.5 L68,26.5 L108.4,15.1 L178.1,48.6 L179.7,49.5 L180.8,50.4 L181.7,51.4 L182.3,52.5 L182.7,53.8 L182.8,55.4 L181.9,59 L174.9,73.2 L172.2,78.7 L145.5,112 L129.8,145.5 C129.8,145.5 100.6,180.6 99.4,182.1 C97.1,185.1 87.4,185.5 84.4,182.3 C81.4,179.1 46.2,139.3 46.2,139.3 Z",
    translate: [28, 6, -0.06],
  },
  {
    venueId: 3,
    name: "infield_sand_1_",
    type: "sand",
    // path: 'M129,133.8 C128.8,134 123,105.7 91.9,105.7 C58.5,105.7 52.9,131.2 52.4,133.8 L52,134.2 L66.8,149 L67.5,148.3 L85.7,166.2 C85,167.2 84.6,168.4 84.6,169.8 C84.6,171 85,172.2 85.6,173.2 C85.6,173.2 85.6,173.2 85.6,173.3 C86.7,175.1 88.7,176.3 91,176.3 C94.5,176.3 97.4,173.4 97.4,169.9 C97.4,168.4 96.9,167.1 96.1,166 L114.8,148.3 L116.3,148.8 L129.8,135.3 L129,133.8 Z M94.6,164.7 C93.6,164 92.3,163.5 90.9,163.5 C89.4,163.5 88.1,164 87,164.8 L69.4,146.6 C69.4,146.6 75.6,141 72.1,136.4 L89.5,121.9 C90,121.6 90.6,121.5 91.3,121.5 C92.2,121.5 93,122 93.6,122.6 C93.6,122.6 93.7,122.6 93.7,122.7 L109.9,138.2 C109.4,139.2 110,144.5 112.9,147.1 L94.6,164.7 Z',
    // translate: [28, 7, 0],

    // Fenway infield shape. slightly misaligned, though
    path:
      "M87.4 82.3L97.5 92.3L94.7 95.1L88.8 89.1C85.3 101.3 68 151.1 -0.4 151.3C-69.3 151.6 -84.8 103.1 -90.3 86.8L-96.5 93L-99.2 90.3L-91.7 82.7C-91.7 82.7 -91.7 82.7 -91.7 82.7C-91.7 82.7 -91.7 82.7 -91.7 82.7L-88.5 79.5C-88.9 79.8 -84.8 69.2 -81.2 63.5C-73.6 52.1 -65.1 46.6 -58.5 49.3C-58.3 49.3 -58.1 49.3 -57.8 49.3L-14.4 6.3C-15.7 4.1 -16.4 1.3 -16.4 -1.7C-16.4 -10.8 -9.1 -18.1 0 -18.1C9.2 -18.1 16.5 -10.8 16.5 -1.7C16.5 1.5 15.6 4.7 14 7.3L55.6 50.3C56.3 50.3 62 49.8 63.4 49.8C73 50.7 82.6 64 85.6 73.4C85.8 74.3 86.5 79.8 86.3 80.7L87.4 82.3ZM11.7 10.5C8.7 13.2 4.8 14.8 0.5 14.8C-3.9 14.8 -7.7 13.2 -10.7 10.5L-53.5 53.2C-48 55.7 -45 62.1 -45 68.5C-45 70.6 -45.5 72.7 -46.2 74.5L-7.3 113.8C-5.5 112.2 -2.7 111.1 -0.4 111.1C2.5 111.1 5.1 112 7.6 113.4L45.3 75.6C44.2 73.6 43.7 71.1 43.7 68.5C43.7 61.9 47.8 56.4 53.8 53.9L11.7 10.5Z",
    scale: [0.41, -0.41, 1],
    translate: [119, 176, 0],
  },

  {
    venueId: 2, // BAL
    name: "Outfield_1_",
    type: "sand",
    path:
      "M1.2,75.8 L0,72.1 L1,69.7 L2,67.2 L3,64.7 L4,62.1 L5.1,59.5 L6.2,56.8 L7.3,54.1 L8.7,51.7 L10.8,50.2 L12.9,48.7 L15,47.2 L17.1,45.7 L19.2,44.2 L21.3,42.7 L23.4,41.2 L25.6,39.7 L27.8,38.2 L30,36.7 L32.2,35.2 L34.4,33.7 L36.6,32.2 L38.8,30.7 L41,29.2 L43.2,27.7 L45.5,26.2 L48,25 L50.3,23.4 L52.6,21.8 L55,20.2 L57.4,18.6 L59.8,17 L62.2,15.3 L64.7,13.6 L67.2,11.9 L69.8,10.2 L72.4,8.4 L75,6.6 L77.7,4.8 L80.4,2.9 L83.2,1 L86.2,0.8 L89.2,1.6 L92.2,2.4 L95.1,3.2 L98,4 L100.9,4.8 L103.8,5.6 L106.6,6.4 L109.4,7.2 L112.2,8 L115,8.8 L117.8,9.6 L120.5,10.3 L123.2,11 L125.9,11.7 L128.6,12.4 L131.3,13.1 L134,13.8 L136.7,14.5 L139.4,15.2 L142.1,15.9 L144.8,16.6 L147.5,17.3 L150.2,18 L152.6,19.5 L154.5,22.3 L156.4,25 L158.2,27.7 L160,30.3 L161.7,32.9 L163.4,35.4 L165.1,37.9 L166.7,40.3 L168.3,42.7 L169.9,45 L171.5,47.3 L173,49.6 L174.5,51.8 L176,54 L177.5,56.2 L178.9,58.3 L180.3,60.4 L181.7,62.5 L183.1,64.6 L184.5,66.6 L185.9,68.6 L187.3,70.6 L188.6,72.6 L189.9,74.6 L191.2,76.5 C191.2,76.5 198,83.6 194.9,88.2 C193.6,90.1 185.7,87.7 184,90.2 C166.4,115.9 128.9,169 122,178.8 C112.8,191.9 91.3,203.5 72.4,176.7 C53.6,150.1 1.2,75.8 1.2,75.8 Z",
    translate: [21, 7, -0.2],
  },
  {
    venueId: 2,
    name: "grass_1_",
    type: "grass",
    path:
      "M98.7,188.5 C91.3,188.5 83.4,181.1 77.2,172.3 C59.6,147.6 14,81.7 7.5,72.4 L14.5,57.7 L14.7,57.3 L86.7,9 L149,26.7 L183.9,77.5 L183.9,81 C171.6,99.9 125.1,164.2 117.3,175.2 C113.8,180.3 106.6,188.5 98.7,188.5 Z",
    translate: [21, 7, -0.06],
  },
  {
    venueId: 2,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M136,132.8 C135.8,133 130,104.7 98.9,104.7 C65.5,104.7 59.9,130.2 59.4,132.8 L59,133.2 L73.8,148 L74.5,147.3 L92.7,165.2 C92,166.2 91.6,167.4 91.6,168.8 C91.6,170 92,171.2 92.6,172.2 C92.6,172.2 92.6,172.2 92.6,172.3 C93.7,174.1 95.7,175.3 98,175.3 C101.5,175.3 104.4,172.4 104.4,168.9 C104.4,167.4 103.9,166.1 103.1,165 L121.8,147.3 L123.3,147.8 L136.8,134.3 L136,132.8 Z M101.6,163.7 C100.6,163 99.3,162.5 97.9,162.5 C96.4,162.5 95.1,163 94,163.8 L76.4,145.6 C76.4,145.6 82.6,140 79.1,135.4 L96.5,120.9 C97,120.6 97.6,120.5 98.3,120.5 C99.2,120.5 100,121 100.6,121.6 C100.6,121.6 100.7,121.6 100.7,121.7 L116.9,137.2 C116.4,138.2 117,143.5 119.9,146.1 L101.6,163.7 Z",
    translate: [21, 8, 0],
  },

  {
    venueId: 4705, // ATL
    name: "Outfield_1_",
    type: "sand",
    path:
      "M198.4,75.8 L181.6,92.4 C183.8,99.3 182.5,100.9 178.1,106.7 C172.3,113.9 118.3,179.4 118.3,179.4 C118.3,179.4 102.4,199.4 84.7,180.4 C84.7,180.4 23.5,107.7 20.8,103.3 C18.4,99.4 16,96.4 19.3,92.2 L0.3,73.8 L0.3,72.8 L1.5,70.4 L2.5,68.7 L3.6,66.3 L4.7,63.9 L5.8,61.5 L6.9,59 L8.1,56.5 L9.3,53.9 L10.5,51.3 L11.7,48.6 L12.9,45.9 L14.2,43.1 L15.5,40.3 L16.8,37.4 L18.1,34.5 L20.2,32.7 L22.5,31.1 L24.8,29.5 L27.1,27.9 L29.4,26.3 L31.7,24.7 L34,23.1 L36.3,21.5 L38.6,19.9 L41.1,18.5 L43.7,17.4 L46.3,16.3 L48.9,15.2 L51.5,14.1 L54.1,13 L56.7,11.9 L59.3,10.8 L61.9,9.7 L64.6,8.6 L67.3,7.5 L70,6.4 L72.7,5.3 L75.4,4.2 L78.1,3.1 L80.9,2 L83.7,0.8 L86.6,0.4 L89.5,0.4 L92.4,0.4 L95.3,0.4 L98.2,0.4 L101.1,0.4 L104,0.4 L106.9,0.4 L109.8,0.4 L112.7,0.4 L115.6,0.4 L118.5,0.7 L121.3,2.1 L124,3.5 L126.7,4.9 L129.4,6.3 L132,7.7 L134.6,9.1 L137.2,10.4 L139.8,11.7 L142.3,13 L144.8,14.3 L147.3,15.6 L149.8,16.9 L152.3,18.2 L154.8,19.5 L157.2,20.8 L159.6,22.1 L161.8,24 L163.7,26.5 L165.5,29 L167.3,31.4 L169,33.8 L170.7,36.1 L172.4,38.4 L174.1,40.7 L175.7,42.9 L177.3,45.1 L178.9,47.3 L180.5,49.4 L182.1,51.5 L183.6,53.6 L185.1,55.7 L186.6,57.7 L188.1,59.7 L189.6,61.7 L191.1,63.7 L192.5,65.7 L193.9,67.6 L195.3,69.5 L196.7,71.4 L197.2,71.8 L198.7,73.8 L198.4,75.8 Z",
    translate: [18, 10, -0.2],
  },
  {
    venueId: 4705,
    name: "grass_1_",
    type: "grass",
    path:
      "M100.7,180.6 C97.4,180.6 94.1,178.7 90.6,175.1 L29.6,103.2 C28.5,101.3 25.2,98.5 25.5,94.6 L26.7,90 L9.7,72 L24.6,39.3 L25.1,38.9 L42.7,26.7 L44.4,25.8 L85.6,8.7 L86.9,8.5 L115.1,8.7 L116.1,8.8 L155,29.1 L155.8,29.8 L188.6,74.3 L175.2,87.6 L176.7,91.2 C178.1,95.6 177.3,100.5 174.5,104.2 C168.2,111.9 112.7,173.6 112.1,174.3 C112.1,174.3 107.6,180.6 100.7,180.6 Z",
    translate: [18, 10, -0.06],
  },
  {
    venueId: 4705,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M139,129.8 C138.8,130 133,101.7 101.9,101.7 C68.5,101.7 62.9,127.2 62.4,129.8 L62,130.2 L76.8,145 L77.5,144.3 L95.7,162.2 C95,163.2 94.6,164.4 94.6,165.8 C94.6,167 95,168.2 95.6,169.2 C95.6,169.2 95.6,169.2 95.6,169.3 C96.7,171.1 98.7,172.3 101,172.3 C104.5,172.3 107.4,169.4 107.4,165.9 C107.4,164.4 106.9,163.1 106.1,162 L124.8,144.3 L126.3,144.8 L139.8,131.3 L139,129.8 Z M104.6,160.7 C103.6,160 102.3,159.5 100.9,159.5 C99.4,159.5 98.1,160 97,160.8 L79.4,142.6 C79.4,142.6 85.6,137 82.1,132.4 L99.5,117.9 C100,117.6 100.6,117.5 101.3,117.5 C102.2,117.5 103,118 103.6,118.6 C103.6,118.6 103.7,118.6 103.7,118.7 L119.9,134.2 C119.4,135.2 120,140.5 122.9,143.1 L104.6,160.7 Z",
    translate: [18, 11, 0],
  },

  {
    venueId: 3140, // Polo Grounds
    name: "Outfield_1_",
    type: "grass",
    path:
      "M165.9,124.9 L165.9,122.6 L165.9,119.8 L165.9,117 L165.9,114 L165.9,110.9 L165.9,107.6 L165.9,104.2 L165.9,100.6 L165.9,96.9 L165.9,93 L165.9,88.9 L165.9,84.6 L165.9,80 L165.9,78.7 L165.9,75 L165.9,69.9 L165.9,64.4 L165.9,58.6 L165.9,52.4 L165.9,45.8 L165.9,41.6 L165.9,41.7 L165.7,39 L165.4,37.1 L164.4,34.3 L163.9,33.4 L162.3,30.9 L161.6,30.1 L159.8,28.4 L158.6,27.5 L157,26.5 L155.1,25.5 L154.1,25 L151.2,24 L151,24 L147.8,23.3 L146.9,23.2 L144.5,23 L142.4,22.9 L141.2,22.9 L138.2,22.9 L137.8,22.9 L134.5,22.9 L131.2,22.9 L128,22.9 L124.8,22.9 L121.6,22.9 L118.4,22.9 L115.2,22.9 L112,22.9 L108.8,22.9 L105.7,22.9 L103.4,22.9 L103.4,12.5 L103.3,12.5 L102,12.5 L102,3.1 L103.4,3.1 L103.4,0.3 L100.6,0.3 L97,0.3 L93.5,0.3 L90.1,0.3 L90.1,1.4 L90,1.4 L88.9,1.4 L88.9,0.3 L86.5,0.3 L83,0.3 L79.5,0.3 L76.1,0.3 L76.1,1.4 L76.1,3.1 L77.4,3.1 L77.4,12.5 L76.1,12.5 L76.1,22.9 L74.5,22.9 L71.4,22.9 L68.2,22.9 L65,22.9 L61.8,22.9 L58.6,22.9 L55.4,22.9 L52.2,22.9 L48.9,22.9 L45.6,22.9 L42.3,22.9 L38.9,22.9 L36,22.9 L35.4,22.9 L32,22.9 L31.8,22.9 L31.8,22.9 L28.6,23.1 L27.7,23 L25.3,23.4 L23.6,23.8 L22.1,24.3 L19.6,25.3 L19,25.5 L16.1,27.1 L16,27.2 L13.5,29.1 L12.9,29.7 L11.1,31.7 L10.4,32.8 L9.2,35.2 L8.8,36.4 L8,40.3 L8,47.1 L8,53.4 L8,59.3 L8,64.9 L8,70 L8,75 L8,79.7 L8,82.3 L8,84.1 L8,88.3 L8,92.3 L8,96.2 L8,99.9 L8,103.4 L8,106.8 L8,110.1 L8,113.2 L8,116.2 L8,124.7 L8,166 C8,166 9.1,205 30.1,223.6 C50.9,241.9 69.5,246 86.8,247.8 C86.8,247.8 86.8,247.9 86.8,247.9 C86.9,247.9 87.1,247.9 87.2,247.9 C87.3,247.9 87.5,247.9 87.6,247.9 C87.6,247.9 87.6,247.8 87.6,247.8 C104.9,246 123.5,241.9 144.3,223.6 C165.4,205 166.4,166 166.4,166 L166.4,124.7 L165.9,124.9 Z",
    translate: [28.5, -24, -0.2],
  },
  {
    venueId: 3140,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M135,132.8 C134.8,133 129,104.7 97.9,104.7 C64.5,104.7 58.9,130.2 58.4,132.8 L58,133.2 L72.8,148 L73.5,147.3 L91.7,165.2 C91,166.2 90.6,167.4 90.6,168.8 C90.6,170 91,171.2 91.6,172.2 C91.6,172.2 91.6,172.2 91.6,172.3 C92.7,174.1 94.7,175.3 97,175.3 C100.5,175.3 103.4,172.4 103.4,168.9 C103.4,167.4 102.9,166.1 102.1,165 L120.8,147.3 L122.3,147.8 L135.8,134.3 L135,132.8 Z M100.6,163.7 C99.6,163 98.3,162.5 96.9,162.5 C95.4,162.5 94.1,163 93,163.8 L75.4,145.6 C75.4,145.6 81.6,140 78.1,135.4 L95.5,120.9 C96,120.6 96.6,120.5 97.3,120.5 C98.2,120.5 99,121 99.6,121.6 C99.6,121.6 99.7,121.6 99.7,121.7 L115.9,137.2 C115.4,138.2 116,143.5 118.9,146.1 L100.6,163.7 Z",
    translate: [22, 8, -0.06],
  },

  {
    venueId: 2756, // BUF
    name: "outfield",
    type: "sand",
    path:
      "M198.8,78.2 L198.9,75.8 L161.7,31.9 L101.2,0.2 L21.5,45.8 L1.6,75.2 C1.5,75.7 1.4,76.2 1.3,76.8 C1.3,76.9 1.3,77 1.3,77 C1.2,77.5 1.2,78 1.1,78.4 C1,79.1 1,79.7 0.9,80.8 L0.9,82 L30.3,121 L57.4,156 C68.9333333,170.333333 77,180.2 81.6,185.6 C88,193.4 94.2,196.8 100.5,197 C106.8,197.2 113.1,193.4 119.5,185.4 C124.166667,180.066667 132.266667,170.2 143.8,155.8 L170.7,120.9 L198.7,82.5 L199,78.1 L198.8,78.2 Z",
    scale: [0.975, 0.975, 1],
    translate: [20.5, 7, -0.2],
  },
  {
    venueId: 2756,
    name: "grass",
    type: "grass",
    path:
      "M204.1,80.7 L168.5,37.8 L111.3,6 L35,49.1 L14.1,79.4 L16.5,81.8 L16.8,82.6 L44.1,118.8 L69.3,151.4 L91.9,178.9 C94.8333333,182.433333 97.0333333,184.833333 98.5,186.1 C99.2,186.7 100,187.3 100.7,187.8 C101.8,188.6 102.9,189.2 104,189.7 C104.7,190 105.4,190.3 106.2,190.5 C107.3,190.8 108.4,191 109.4,191.1 C115.2,190.7 121.1,186.6 127.1,178.8 C127.633333,178.2 128.466667,177.233333 129.6,175.9 L140.5,162.7 C141.833333,161.1 142.833333,159.866667 143.5,159 C144.833333,157.333333 146.933333,154.7 149.8,151.1 L195,90.7 L198.3,87.4 L204.1,80.7 Z",
    scale: [0.975, 0.975, 1],
    translate: [13, 7, -0.06],
  },
  {
    venueId: 2756,
    name: "infield",
    type: "sand",
    path:
      "M140.5,144.7 L143.5,141 C143.5,141 134.1,114.8 110.5,115 C86.5,115.2 75.8,141 75.8,141 L79.5,144 L77,147 L102.2,172.2 C102.2,172.2 100.9,183.4 110,183.4 C119.1,183.4 117.9,173 117.8,171.9 L142.5,147.3 L140.5,144.7 Z M129.3,151.5 L112.4,168.4 L107.8,168.4 L90.8,151.4 L90.8,146.3 L107.8,129.3 L112.5,129.3 L129.4,146.2 L129.3,151.5 L129.3,151.5 Z",
    translate: [8.5, 2.5, 0],
  },

  // These paths are just approximations. The original 2020 Dunedin SVG path strings had problems here.
  // These paths are traced from the Dunedin SVG, which itself doesn't fit the lidar fence.
  {
    venueId: 2536,
    name: "outfield", // Dunedin
    type: "sand",
    path:
      "M1,72 C32.3333333,44.6666667 51.6666667,28 59,22 C70,13 86,1 106,1 C126,1 137,11 153,22 C163.666667,29.3333333 181.666667,47 207,75 L120,197 L90,197 L1,72 Z",
    rotate: (-1 * Math.PI) / 180,
    translate: [12, 4, -0.2],
  },
  {
    venueId: 2536,
    name: "grass",
    type: "grass",
    path:
      "M100,180 L78,180 L3,70 C1.66666667,68.6666667 1,66.6666667 1,64 C1,61.3333333 2.33333333,59.3333333 5,58 C24.3333333,38 38,24.6666667 46,18 C58,8 69,1 90,1 C111,1 121,7 138,20 C149.333333,28.6666667 163,43.3333333 179,64 C181,66 182,67.6666667 182,69 C182,70.3333333 181,72 179,74 L100,180 Z",
    rotate: (-1 * Math.PI) / 180,
    scale: [0.975, 0.975, 1],
    translate: [29, 20, -0.06],
  },
  {
    venueId: 2536,
    name: "infield_sand_1_",
    type: "sand",
    path:
      "M136,132.8 C135.8,133 130,104.7 98.9,104.7 C65.5,104.7 59.9,130.2 59.4,132.8 L59,133.2 L73.8,148 L74.5,147.3 L92.7,165.2 C92,166.2 91.6,167.4 91.6,168.8 C91.6,170 92,171.2 92.6,172.2 C92.6,172.2 92.6,172.2 92.6,172.3 C93.7,174.1 95.7,175.3 98,175.3 C101.5,175.3 104.4,172.4 104.4,168.9 C104.4,167.4 103.9,166.1 103.1,165 L121.8,147.3 L123.3,147.8 L136.8,134.3 L136,132.8 Z M101.6,163.7 C100.6,163 99.3,162.5 97.9,162.5 C96.4,162.5 95.1,163 94,163.8 L76.4,145.6 C76.4,145.6 82.6,140 79.1,135.4 L96.5,120.9 C97,120.6 97.6,120.5 98.3,120.5 C99.2,120.5 100,121 100.6,121.6 C100.6,121.6 100.7,121.6 100.7,121.7 L116.9,137.2 C116.4,138.2 117,143.5 119.9,146.1 L101.6,163.7 Z",
    translate: [21, 8, 0],
  },
];
