import { csvParse } from "d3-dsv";

// grabbed from https://docs.google.com/spreadsheets/d/1y_Rexw7NleTWcINwJRYuz768MsE--97a8lq3Fbjldew/edit#gid=2041282674

let csv = `angle,distance,height,coordinate_x,coordinate_y
-45,278.5,17,-196.9,196.9
-44,283.4,17,-196.9,203.9
-43,288.7,17,-196.9,211.1
-42,294.3,17,-196.9,218.7
-41,300.1,17,-196.9,226.5
-40,306.3,17,-196.9,234.6
-39,312.9,17,-196.9,243.1
-38,319.8,17,-196.9,252
-37,327.2,17,-196.9,261.3
-36,335,17,-196.9,271
-35,343.3,17,-196.9,281.2
-34,352.1,17,-196.9,291.9
-33,361.5,17,-196.9,303.2
-32,371.6,17,-196.9,315.1
-31,382.3,17,-196.9,327.7
-30,393.8,17,-196.9,341
-29,406.1,17,-196.9,355.2
-28,419.4,17,-196.9,370.3
-27,433.8,17,-197,386.5
-26.3,441.6,16,-195.5,396
-26,443.7,16,-194.5,398.8
-25.3,447.6,15,-191.5,404.5
-25,449.2,15,-189.8,407.1
-24.2,451.8,14,-185.5,411.9
-24,452.4,14,-184,413.3
-23.1,454.2,13,-177.9,418
-23,454.3,13,-177.5,418.2
-22,455.2,13,-170.5,422
-21.8,455.3,12,-169.1,422.7
-21,455.2,12,-163.1,425
-20.5,455,11,-159.5,426.1
-20,454.5,11,-155.5,427.1
-19.3,453.6,10,-149.7,428.2
-19,453.1,10,-147.5,428.4
-18.1,451.1,9,-139.9,428.9
-18.1,451.1,9,-139.9,428.9
-18,450.9,9,-139.3,428.9
-17,448.5,9,-131.1,428.9
-16.8,448.1,9,-129.7,428.9
-16,446.1,5,-123,428.9
-15,444,5,-114.9,428.9
-14,442,5,-106.9,428.9
-13,440.1,5,-99,428.9
-12,438.4,5,-91.2,428.9
-11,436.9,5,-83.4,428.9
-10,435.5,5,-75.6,428.9
-9,434.2,5,-67.9,428.9
-8,433.1,5,-60.3,428.9
-7,432.1,17,-52.7,428.9
-6,431.2,17,-45.1,428.9
-5,430.5,17,-37.5,428.9
-4.5,430.2,17,-33.6,428.9
-4.2,455,17,-33.6,453.8
-3.9,454.8,17,-30.5,453.8
-3.7,477.4,24,-30.6,476.4
-4,477.6,24,-33.6,476.4
-4,481.7,60,-33.6,480.5
-4,484.3,60,-33.5,483.2
-3,483.8,60,-25.3,483.2
-2,483.5,60,-16.9,483.2
-1,483.3,60,-8.4,483.2
-0.3,483.2,60,-2.6,483.2
-0.3,480.5,60,-2.6,480.5
0,480.5,60,0,480.5
0,480.5,60,0.3,480.5
0,483.2,60,0.3,483.1
1,483.2,60,8.4,483.2
2,483.5,60,16.9,483.2
3,483.8,60,25.3,483.2
3.8,484.2,60,32.1,483.2
3.9,477.5,24,32.1,476.4
3.4,477.3,24,28.6,476.4
3.6,454.7,17,28.6,453.8
4,454.9,17,31.7,453.8
4,454.9,17,32,453.8
4.3,430.1,17,32.1,428.9
5,430.5,17,37.5,428.9
6,431.2,17,45.1,428.9
7,432.1,17,52.7,428.9
8,433.1,5,60.3,428.9
9,434.2,5,67.9,428.9
10,435.5,5,75.6,428.9
11,436.9,5,83.4,428.9
12,438.4,5,91.2,428.9
13,440.1,5,99,428.9
14,442,5,106.9,428.9
15,444,5,114.9,428.9
15.1,444.2,11,115.8,428.9
16,446.1,11,123,428.9
16.4,447,11,126,428.9
17,448.3,11,131.1,428.7
17.7,449.5,11,136.8,428.2
18,449.9,11,139,427.9
19,450.8,11,146.8,426.2
19.1,450.8,11,147.2,426.1
20,450.9,11,154.2,423.7
20.3,450.7,11,156.7,422.6
21,450.1,11,161.3,420.2
21.6,449.2,11,165.1,417.7
22,448.2,11,167.9,415.6
22.7,446,11,172.2,411.4
23,444.7,11,173.8,409.4
23.7,440.8,11,177.5,403.5
24,439.2,11,178.6,401.2
24.7,434,11,181.1,394.5
25,430.2,11,181.8,389.9
25.4,424.6,11,182.2,383.5
25.4,424.7,11,182.2,383.6
26,415.6,11,182.2,373.5
27,401.3,11,182.2,357.5
28,388.1,11,182.2,342.6
29,375.8,11,182.2,328.7
30,364.4,11,182.2,315.5
31,353.7,11,182.2,303.2
32,343.8,11,182.2,291.5
33,334.5,11,182.2,280.5
34,325.8,11,182.2,270.1
35,317.6,11,182.2,260.2
36,309.9,11,182.2,250.7
37,302.7,11,182.2,241.8
38,295.9,11,182.2,233.2
39,289.5,11,182.2,225
40,283.4,11,182.2,217.1
41,277.7,11,182.2,209.6
42,272.3,11,182.2,202.3
43,267.1,11,182.2,195.4
44,262.3,11,182.2,188.7
45,257.6,11,182.2,182.2`;

const toRow = (d: any) => ({
  venueId: 3140,
  angle: +d.angle,
  x: +d.coordinate_x,
  y: +d.coordinate_y,
  z: +d.height,
  distance: +d.distance,
  height: +d.height,
  hrLineDistance: +d.distance,
  hrLineHeight: +d.height,
});

export const poloGroundsFencePoints = csvParse(csv, toRow);
