import React from "react";
import { MeshSeriesGroup } from "../MeshSeriesGroup";
import { TexMapBall } from "../Ball";
import { flattenPointsArray, LineTrack } from "../Line";

interface toLineTrackProps {
  positions: any[];
  lineWidth?: number;
  color?: string | number;
}

const toLineTrack = ({
  positions,
  lineWidth = 1,
  color = "#333",
}: toLineTrackProps) => {
  if (positions) {
    let points = flattenPointsArray(positions);

    // @ts-ignore until LineTrack can switch to ts or be wrapped in ts
    return (
      points.length && (
        <LineTrack points={points} linewidth={lineWidth} color={color} />
      )
    );
  } else {
    return null;
  }
};

interface Props {
  positions: any[];
  knotRadius?: number;
  knotColor?: string | number;
  lineWidth?: number;
  lineColor?: string | number;
}

// TODO    possible to pass a geometry to MeshSeriesGroup instead so it can be shared?

export const KnottedTrack = ({
  positions,
  knotRadius = 0.05,
  knotColor = "#666",
  lineWidth = 1,
  lineColor = "#333",
  ...other
}: Props) => {
  let track = toLineTrack({ positions, lineWidth, color: lineColor });
  let knots = (
    <MeshSeriesGroup
      Mesh={TexMapBall}
      props={{ radius: knotRadius, color: { knotColor } }}
      positions={positions}
    />
  );

  return (
    <group {...other}>
      {track}
      {knots}
    </group>
  );
};
