import { teamColors } from "../constants/team-colors";
import { format, parseISO } from "date-fns";

// returns play matching playId
export const getGumboPlay = ({
  gumbo,
  playId,
}: {
  gumbo: any;
  playId: string;
}) => {
  if (!gumbo || !playId) {
    return null;
  }

  let { allPlays } = gumbo.liveData.plays;

  return allPlays.find((play: any) => {
    let { playEvents } = play;

    let foundPlayEvent = playEvents.find(
      (event: any) => event.playId === playId
    );

    return foundPlayEvent !== undefined;
  });
};

export const getGameDate = (gumbo: any) => {
  if (!gumbo) {
    return null;
  }

  return format(parseISO(gumbo.gameData.datetime.dateTime), "yyyy LLL dd");
};

export const toAbbrevTeamAtTeam = (gumbo: any) => {
  if (!gumbo) {
    return null;
  }

  let { away, home } = gumbo.gameData.teams;
  let awayTeamInfo = teamColors.find((t: any) => t.team_id === away.id + "");
  let awayAbbrevName = awayTeamInfo?.team_abbrev || "–";
  let homeTeamInfo = teamColors.find((t: any) => t.team_id === home.id + "");
  let homeAbbrevName = homeTeamInfo?.team_abbrev || "–";

  return `${awayAbbrevName} @ ${homeAbbrevName}`;
};
