import { useEffect, useMemo } from "react";
import { Vector3 } from "three";
import { toCameraStateSpans, timeToCameraState } from "./flyCamTween";
import { ReferenceMarks } from "./ReferenceMarks";
import { mlbToGl } from "./flycam-util";
import { toEventTime } from "../../util/play-util";
import { BEGIN_OF_PLAY_EVENT } from "../../models/playEventTypes";

const traceSpanTimesEnabled = false;

const traceSpanTimes = (spanSet: any) => {
  let { positionSpans } = spanSet;

  let times = positionSpans.map((span: any) => `${span.name} : ${span.tIn}`);
  times.push(`end : ${positionSpans[positionSpans.length - 1].tOut}`);

  console.log(times.join(", "));
};

const _vTarget = new Vector3();

/*
  goals:

  A) potentially handle various methods for calculating camera state (e.g., keyframe, spline, or spring force)

  B) accommodate random access playback so that a frame's state is consistent regardless of play rate
  or jumps in time. for example, the camera controlled by a spring simulation shouldn't move differently
  at different playback rates. this is especially important in order for non-realtime rendering to appear
  the same as realtime playback.

  in other words, a function to map time to camera state must be a pure function


  method:

  1) pre-calculate a series of samples for the play timespan
    could be a few waypoints for a keyframe animation
    or could be a sample at each frame time for a spline or a spring simulation

  2) at each playback frame, lerp between the appropriate samples
 */

interface Props {
  camera: any;
  time: number;
  playData: any;
  useFlyCamStore: any;
}

export const FlyCam = (props: Props) => {
  let { camera, playData, time, useFlyCamStore } = props;

  console.assert(camera, "missing camera in FlyCam");

  let params = useFlyCamStore();
  let { startLookAtY, startLookAtZ, referenceMarksVisible } = params;
  let spanSet = useMemo(() => toCameraStateSpans({ playData, params }), [
    playData,
    params,
  ]);
  let tBoP = toEventTime(playData, BEGIN_OF_PLAY_EVENT);
  let tPlay = time - tBoP; // play-relative time
  let { position, target } = timeToCameraState({
    time: tPlay,
    spanSet,
  });

  let { refPoints } = spanSet;

  mlbToGl(target, _vTarget);

  useEffect(() => {
    traceSpanTimesEnabled && traceSpanTimes(spanSet);
  }, [playData, params, spanSet]);

  camera.position.copy(position);
  camera.lookAt(_vTarget);
  camera.up.set(0, 1, 0); // TODO    need before updateMatrix, or after, or at all?
  camera.updateMatrix();

  return referenceMarksVisible
    ? ReferenceMarks({
        refPoints,
        startLookAtY,
        startLookAtZ,
      })
    : null;
};
