import React /*, {useState}*/ from "react";

import FormControl from "@material-ui/core/FormControl";
// import Divider from '@material-ui/core/Divider'
// import {TabPanel} from './Knobs'
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import { makeStyles } from "@material-ui/core/styles";
import { ColorPicker } from "../ColorPicker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// import FormHelperText from "@material-ui/core/FormHelperText";
import Slider from "@material-ui/core/Slider";
import {
  meshBasicMaterialTypeName,
  meshStandardMaterialTypeName,
} from "field-of-things/src/stores/fieldApperanceStore";

const emissiveIncluded = false;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  offsetIntervalSliderRoot: {
    width: 200,
  },
  sliderRoot: {
    width: 200,
  },
}));

export interface MaterialKnobsProps {
  title?: string;
  params: any;
  setParams: (args: any) => void;
}

export const MaterialKnobs = ({
  title = "",
  params,
  setParams,
}: MaterialKnobsProps) => {
  let {
    type,
    color,
    emissive,
    metalness,
    roughness,
    wireframe,
    // wireframeLinewidth,
  } = params;
  // let isMeshBasicMaterial = type === meshBasicMaterialTypeName
  let isMeshStandardMaterial = type === meshStandardMaterialTypeName;
  const classes = useStyles();

  let handleMaterialTypeChange = (event: any) => {
    setParams({ ...params, type: event.target.value });
  };

  let handleColorChange = (color: string) => {
    setParams({ ...params, color });
  };

  let handleEmissiveChange = (color: string) => {
    setParams({ ...params, emissive: color });
  };

  let handleMetalnessChange = (event: any, value: any) => {
    setParams({ ...params, metalness: value });
  };

  let handleRoughnessChange = (event: any, value: any) => {
    setParams({ ...params, roughness: value });
  };

  let toggleWireframe = () => {
    setParams({ ...params, wireframe: !wireframe });
  };

  return (
    <div>
      <h4>{title}</h4>

      <FormControl component="fieldset">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="material-type-select">Material</InputLabel>
          <NativeSelect
            // disabled
            value={type}
            onChange={handleMaterialTypeChange}
            inputProps={{
              name: "material-type-select",
              id: "material-type-select",
            }}
          >
            <option value={meshBasicMaterialTypeName}>Basic Material</option>
            <option value={meshStandardMaterialTypeName}>
              Standard Material
            </option>
          </NativeSelect>
          {/*<FormHelperText>Experimental &nbsp;<LabIcon/></FormHelperText>*/}
        </FormControl>
        <br />
        Color <ColorPicker color={color} onChange={handleColorChange} />
        {emissiveIncluded && (
          <>
            <br />
            Emissive{" "}
            <ColorPicker color={emissive} onChange={handleEmissiveChange} />
          </>
        )}
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={wireframe}
              onChange={toggleWireframe}
              name="wireframe-switch"
              // color='primary'
              color="secondary"
              size="small"
            />
          }
          label="Wireframe"
        />
        {/*<FormHelperText>Merged Positional Data</FormHelperText>*/}
        {isMeshStandardMaterial && (
          <>
            <br />

            <div className={classes.sliderRoot}>
              Metalness
              <Slider
                min={0}
                max={1}
                value={metalness}
                aria-labelledby="metalness-slider"
                step={0.01}
                valueLabelDisplay="auto"
                // marks={marks}
                onChange={handleMetalnessChange}
              />
              {/*<FormHelperText>- speed ➔ CCW<br/> + speed ➔ CW</FormHelperText>*/}
            </div>

            <br />

            <div className={classes.sliderRoot}>
              Roughness
              <Slider
                min={0}
                max={1}
                value={roughness}
                aria-labelledby="roughness-slider"
                step={0.01}
                valueLabelDisplay="auto"
                // marks={marks}
                onChange={handleRoughnessChange}
              />
              {/*<FormHelperText>- speed ➔ CCW<br/> + speed ➔ CW</FormHelperText>*/}
            </div>
          </>
        )}
        <br />
      </FormControl>
    </div>
  );
};
