import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

export const FpsTurtle = () => (
  <Tooltip title="may affect fps" placement="top">
    <span role="img" aria-label="turtle">
      🐢
    </span>
  </Tooltip>
);
