import { appSpaces } from "../SpaceSelect";
import { useMemo } from "react";
import { DiagnosticsSpace } from "../Diagnostics";
import { createPlayViewRootStore } from "field-of-things/src/stores";

interface WrappedDiagnosticsSpaceProps {
  rootStore: any;
  appSpace: string;
  setAppSpace: any;
  playData: any;
  playTracking: any;
  openPlayPicker: any;
}

// wrappingDiagnosticsSpace here to prep the tracking and create a separate diagRootStore
// this may be temporary
export const WrappedDiagnosticsSpace = (
  props: WrappedDiagnosticsSpaceProps
) => {
  const {
    rootStore,
    appSpace,
    setAppSpace,
    playData,
    playTracking,
    openPlayPicker,
  } = props;
  const isDiagnosticsSpace = appSpace === appSpaces.diagnostics;

  const playVenueId = rootStore?.useVenueStore(($: any) => $.playDataVenueId);

  // TODOHI  create rootStore inside DiagnosticsSpace instead? need any values from main rootStore?
  const diagRootStore = useMemo(
    () => isDiagnosticsSpace && createPlayViewRootStore(),
    [isDiagnosticsSpace]
  );

  // TODOHI  need actual venueId; or can drop the venueId prop and derive from playData in DiagnosticsSpace?

  return (
    <DiagnosticsSpace
      rootStore={diagRootStore}
      appSpace={appSpace}
      onAppSpaceChange={setAppSpace}
      venueId={playVenueId}
      playTracking={playTracking}
      playData={playData}
      onPlayClicked={openPlayPicker}
    />
  );
};
