import create from "zustand";
import { GRASS_COLOR, SAND_COLOR } from "../constants/colors";

export const meshBasicMaterialTypeName = "meshBasicMaterial";
export const meshStandardMaterialTypeName = "meshStandardMaterial";

export type meshBasicMaterialType = "meshBasicMaterial";
export type meshStandardMaterialType = "meshStandardMaterial";

export interface materialParams {
  type: meshBasicMaterialType | meshStandardMaterialType;
  color: string | number;
  emissive?: string | number;
  metalness?: number;
  roughness?: number;
  wireframe?: boolean;
  wireframeLinewidth?: number;
}

export interface FieldAppearanceStore {
  fenceMaterial: materialParams;
  setFenceMaterial(params: materialParams): void;
  grassMaterial: materialParams;
  setGrassMaterial(params: materialParams): void;
  sandMaterial: materialParams;
  setSandMaterial(params: materialParams): void;
}

// TODO    later, map grass/sand material params to material objects that can be shared downstream?

// TODOHI  add an initial values argument to createFieldAppearanceStore
export const createFieldAppearanceStore = () =>
  create<FieldAppearanceStore>((set) => ({
    fenceMaterial: {
      // type: meshBasicMaterialTypeName,
      type: meshStandardMaterialTypeName,
      color: "#aaa",
      emissive: "#000",
      metalness: 0.2,
      roughness: 0.8,
      wireframe: false,
      wireframeLinewidth: 1,
    },
    setFenceMaterial: (params: materialParams) =>
      set({ fenceMaterial: params }),

    grassMaterial: {
      // type: meshBasicMaterialTypeName,
      type: meshStandardMaterialTypeName,
      color: GRASS_COLOR,
      emissive: "#000",
      metalness: 0.2,
      roughness: 0.8,
      wireframe: false,
      wireframeLinewidth: 1,
    },
    setGrassMaterial: (params: materialParams) =>
      set({ grassMaterial: params }),

    sandMaterial: {
      // type: meshBasicMaterialTypeName,
      type: meshStandardMaterialTypeName,
      color: SAND_COLOR,
      emissive: "#000",
      metalness: 0.2,
      roughness: 0.7,
      wireframe: false,
      wireframeLinewidth: 1,
    },
    setSandMaterial: (params: materialParams) => set({ sandMaterial: params }),
  }));
