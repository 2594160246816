import create from "zustand";
import _ from "lodash";

// TODO    support optional sets of arbitrary strobe times

export type StrobeVisibilityType = "omni" | "leading" | "trailing";

export type StrobeType = "uniform-intervals" | "hitter-reaction";

export type StrobeFigures = "stick-figures" | "figure-models" | "both";

const defaultStrobeFigures = "stick-figures";

export interface FigureStrobeStore {
  strobesEnabled: boolean;

  setStrobesEnabled(strobesEnabled: boolean): void;

  strobeType: StrobeType;

  setStrobeType(strobeType: StrobeType): void;

  strobeFigures: StrobeFigures;

  setStrobeFigures(strobeFigures: StrobeFigures): void;

  strobeInTime: number;

  setStrobeInTime(strobeInTime: number): void;

  strobeOutTime: number;

  setStrobeOutTime(strobeOutTime: number): void;

  strobeAnchorTime: number;

  setStrobeAnchorTime(strobeAnchorTime: number): void;

  strobeInterval: number;

  setStrobeInterval(strobeInterval: number): void;

  strobeOpacity: number;

  setStrobeOpacity(strobeOpacity: number): void;

  strobeVisibilityType: StrobeVisibilityType;

  setStrobeVisibilityType(strobeVisibilityType: StrobeVisibilityType): void;

  strobeShadowsEnabled: boolean;

  setStrobeShadowsEnabled(strobeShadowsEnabled: boolean): void;

  visiblePositionIds: string[];

  setVisiblePositionIds(visiblePositionIds: string[]): void;

  hitterReactionPFMEnabled: boolean;

  setHitterReactionPFMEnabled(hitterReactionPFMEnabled: boolean): void;

  hitterReactionReleaseEnabled: boolean;

  setHitterReactionReleaseEnabled(hitterReactionReleaseEnabled: boolean): void;

  hitterReactionRecoEnabled: boolean;

  setHitterReactionRecoEnabled(hitterReactionReleaseEnabled: boolean): void;

  hitterReactionCommitEnabled: boolean;

  setHitterReactionCommitEnabled(hitterReactionCommitEnabled: boolean): void;

  hitterReactionPlateEnabled: boolean;

  setHitterReactionPlateEnabled(hitterReactionPlateEnabled: boolean): void;
}

export const createFigureStrobeStore = () =>
  create<FigureStrobeStore>((set) => ({
    strobesEnabled: false,
    setStrobesEnabled: (strobesEnabled: boolean) => set({ strobesEnabled }),

    strobeType: "uniform-intervals",

    setStrobeType: (strobeType: StrobeType) => set({ strobeType }),

    strobeFigures: defaultStrobeFigures,

    setStrobeFigures: (strobeFigures: StrobeFigures) => set({ strobeFigures }),

    strobeInTime: -1,
    setStrobeInTime: (strobeInTime: number) => set({ strobeInTime }),

    strobeOutTime: -1,
    setStrobeOutTime: (strobeOutTime: number) => set({ strobeOutTime }),

    strobeAnchorTime: 0,
    setStrobeAnchorTime: (strobeAnchorTime: number) =>
      set({ strobeAnchorTime }),

    strobeInterval: 1000,
    setStrobeInterval: (strobeInterval: number) => set({ strobeInterval }),

    strobeOpacity: 0.2,
    setStrobeOpacity: (strobeOpacity: number) => set({ strobeOpacity }),

    strobeVisibilityType: "omni",
    setStrobeVisibilityType: (strobeVisibilityType: StrobeVisibilityType) =>
      set({ strobeVisibilityType }),

    strobeShadowsEnabled: false,
    setStrobeShadowsEnabled: (strobeShadowsEnabled: boolean) =>
      set({ strobeShadowsEnabled }),

    visiblePositionIds: _.range(1, 22).map((i: number) => `${i}`), // '1' through '21'
    setVisiblePositionIds: (visiblePositionIds: string[]) =>
      set({ visiblePositionIds }),

    hitterReactionPFMEnabled: true,
    setHitterReactionPFMEnabled: (hitterReactionPFMEnabled: boolean) =>
      set({ hitterReactionPFMEnabled }),

    hitterReactionReleaseEnabled: true,
    setHitterReactionReleaseEnabled: (hitterReactionReleaseEnabled: boolean) =>
      set({ hitterReactionReleaseEnabled }),

    hitterReactionRecoEnabled: true,
    setHitterReactionRecoEnabled: (hitterReactionRecoEnabled: boolean) =>
      set({ hitterReactionRecoEnabled }),

    hitterReactionCommitEnabled: true,
    setHitterReactionCommitEnabled: (hitterReactionCommitEnabled: boolean) =>
      set({ hitterReactionCommitEnabled }),

    hitterReactionPlateEnabled: true,
    setHitterReactionPlateEnabled: (hitterReactionPlateEnabled: boolean) =>
      set({ hitterReactionPlateEnabled }),
  }));
