import React from "react";

const headRadius = 0.3;
const headCenterZ = 1.9 * headRadius;

interface HeadProps {
  joints: any;
  material: any;
  size?: number;
  castShadow?: boolean;
}
// TODO    calculate head radius from ears distance, when both ears present
// TODO    calculate tilt, based on ears. maybe eyes or nose?
// TODO    add a hat brim for players?
export const Head = ({
  joints,
  material,
  size = 1,
  castShadow = true,
}: HeadProps) => {
  let neck = joints.Neck;

  if (!neck) {
    return null;
  }

  let [x, y, z] = neck;
  // hack for non unity size:
  let scale: [number, number, number] =
    size === 1 ? [0.9, 0.9, 1.1] : [0.6 * size, 0.6 * size, 0.75 * size];

  return (
    // @ts-ignore
    <mesh
      castShadow={castShadow}
      position={[x, y, z + headCenterZ]}
      scale={scale}
    >
      <sphereBufferGeometry attach="geometry" args={[headRadius, 32, 16]} />
      {material}
    </mesh>
  );
};
