import React from "react";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tooltip from "@material-ui/core/Tooltip";
import { FigureKnobsPanel } from "./FigureKnobsPanel";
import { FigureStrobeKnobsPanel } from "./FigureStrobeKnobsPanel";
import { FigurePoseKnobsPanel } from "./FigurePoseKnobsPanel";
import { FigureModelKnobsPanel } from "./FigureModelKnobsPanel";
import { PathKnobsPanel } from "./PathKnobsPanel";
import { FigureGeneralKnobsPanel } from "./FigureGeneralKnobsPanel";

const MyTab = styled(Tab)({
  minWidth: 60,
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

interface Props {
  value: any;
  index: number;
  rootStore: any;
  timebase: any;
}

export const FigureTabPanel = (props: Props) => {
  let { value, index, rootStore, timebase } = props;
  let {
    useKnobsStore,
    useFigurePositionStore,
    useFigureAppearanceStore,
    useFigureCaptionsStore,
    useFigureStrobeStore,
    useFigurePoseStore,
    usePlaySummaryStore,
    useFigureModelStore,
    useStatsApiStore,
    useCameraStore,
  } = rootStore;
  let { figureTabPanelTabValue, setFigureTabPanelTabValue } = useKnobsStore();
  let handleChange = (event: any, newValue: number) => {
    setFigureTabPanelTabValue(newValue);
  };

  let { trackingData } = useStatsApiStore();

  return (
    <TabPanel value={value} index={index}>
      <Tabs
        value={figureTabPanelTabValue}
        onChange={handleChange}
        aria-label="figure knobs tabs"
      >
        <Tooltip title="Stick Figures" placement="top">
          <MyTab label="Sticks" {...a11yProps(0)} />
        </Tooltip>
        <Tooltip title="Posed Models" placement="top">
          <MyTab label="Models" {...a11yProps(1)} />
        </Tooltip>
        <Tooltip
          title="Covers both stick figures and posed models"
          placement="top"
        >
          <MyTab label="General" {...a11yProps(2)} />
        </Tooltip>
        <Tooltip title="Player Paths and Footsteps" placement="top">
          <MyTab label="Paths" {...a11yProps(3)} />
        </Tooltip>
        <Tooltip title="Player Instances Across Time" placement="top">
          <MyTab label="Strobing" {...a11yProps(4)} />
        </Tooltip>
        <Tooltip title="Model Pose Debug" placement="top">
          <MyTab label="Pose" {...a11yProps(4)} />
        </Tooltip>
      </Tabs>

      <FigureKnobsPanel
        index={0}
        value={figureTabPanelTabValue}
        useFigurePositionStore={useFigurePositionStore}
        useFigureAppearanceStore={useFigureAppearanceStore}
        useFigureCaptionsStore={useFigureCaptionsStore}
        usePlaySummaryStore={usePlaySummaryStore}
      />

      <FigureModelKnobsPanel
        index={1}
        value={figureTabPanelTabValue}
        useFigureModelStore={useFigureModelStore}
      />

      <FigureGeneralKnobsPanel
        index={2}
        value={figureTabPanelTabValue}
        useFigurePositionStore={useFigurePositionStore}
        useFigureAppearanceStore={useFigureAppearanceStore}
        useFigureCaptionsStore={useFigureCaptionsStore}
        usePlaySummaryStore={usePlaySummaryStore}
        useFigureModelStore={useFigureModelStore}
      />

      <PathKnobsPanel
        index={3}
        value={figureTabPanelTabValue}
        useFigurePositionStore={useFigurePositionStore}
        usePlaySummaryStore={usePlaySummaryStore}
      />

      <FigureStrobeKnobsPanel
        index={4}
        value={figureTabPanelTabValue}
        timebase={timebase}
        useFigureStrobeStore={useFigureStrobeStore}
      />

      <FigurePoseKnobsPanel
        index={5}
        value={figureTabPanelTabValue}
        useFigureAppearanceStore={useFigureAppearanceStore}
        useFigurePoseStore={useFigurePoseStore}
        useCameraStore={useCameraStore}
        trackingData={trackingData}
      />
    </TabPanel>
  );
};
