import React, { useMemo } from "react";
import { toBallPointSeries, toPointInSeries } from "../../util/polynomial-util";
import { Ball } from "../Ball";
import { BallType } from "../../stores/ballStore";

interface RecoBallProps {
  type: BallType;
  segments: any[];
  time: number;

  [key: string]: any;
}

// ball position reconstructed ('reco') from polynomial
export const RecoBall = ({
  type,
  segments,
  time,
  ...otherProps
}: RecoBallProps) => {
  let series = useMemo(() => toBallPointSeries({ segments }), [segments]);
  const validRecoWindows: [number, number][] = useMemo(
    () =>
      segments.map((segment: any) => [
        segment.startData.time,
        segment.endData.time,
      ]),
    [segments]
  );

  const visible = validRecoWindows.some(
    ([low, high]) => time >= low && time <= high
  );

  let p = toPointInSeries({ t: time, series });

  return (
    <Ball
      type={type}
      visible={visible}
      position={[p.x, p.y, p.z]}
      textured
      {...otherProps}
    />
  );
};
