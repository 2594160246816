/*
    Displays a calendar and a list of games on the selected date. Games are dimmed and disabled when they don't
    qualify as 'valid'. valid = 300K+ rows or after 9/15/20
 */
import React, { useEffect, useState } from "react";
import _find from "lodash/find";
import useAxios from "axios-hooks";
import { csv as fetchCsv } from "d3-fetch";
import { format } from "date-fns";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { teamColors } from "field-of-things/src/constants/team-colors";
import { scaleLinear } from "d3-scale";
import styles from "./DayGamePicker.module.css";

const colorGamesByRowCount = false;

const autoIncludeDate = new Date("September 16, 2020");

const colorScale = scaleLinear()
  .domain([-1, 0, 1])
  // @ts-ignore
  .range(["#22f", "#fff"])
  // .range(["orange", "white", "green"])
  .domain([0, 300000]);

const toGameRow = (d: any) => ({
  gamePk: d.game_id,
  rows: +d.f0_,
});

// TODOHI  need to cover span of padding on calendar?
// TODOHI  correctly accounting for GMT and local time when composing date range for statsapi?
const toMonthScheduleUrl = (date: Date) => {
  let formattedStartDate = format(startOfMonth(date), "MM/dd/yyyy");
  let formattedEndDate = format(endOfMonth(date), "MM/dd/yyyy");

  return `https://statsapi.mlb.com/api/v1/schedule/?sportId=1,22&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
};

// TODOHI  correctly accounting for GMT and local time when instantiating dates?
const toDatesList: (schedule: any) => Date[] = (schedule: any) => {
  return schedule.dates.map((d: any) => {
    let [year, month, date] = d.date.split("-");

    return new Date(+year, +month - 1, +date);
  });
};

const sameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

// TODO    should memoize, dep url to avoid redundant fetches?
const useGameDatesForMonth = (date: Date) => {
  let url = toMonthScheduleUrl(date);
  let [
    { data: schedule, /*loading: isScheduleLoading,*/ error } /*refetch*/,
  ] = useAxios(url);
  let dates = schedule ? toDatesList(schedule) : [];

  return { dates, schedule, error };
};

const minFrameCount = 300000;

interface Props {
  onGameSelected: (gamePk: string) => void;
}

// TODO    set defaults to current month/year
export const DayGamePicker: React.FC<Props> = ({ onGameSelected }) => {
  let [monthDate, setMonthDate] = useState(new Date());
  let [validGamePks, setValidGamePks] = useState<string[]>([]);
  // let [invalidGames, setInvalidGames] = useState([])
  let [gameRowsData, setGameRowsData] = useState<
    { gamePk: string; rows: number }[]
  >([]);

  // let {dates: gameDates, schedule, error} = useGameDatesForMonth(monthDate)
  let { schedule, error } = useGameDatesForMonth(monthDate);

  // TODOHI  properly handle errors
  error && console.error(error);

  // TODO    disable calendar dates not in gameDates?

  let [dateGames, setDateGames] = useState([]);

  useEffect(() => {
    fetchCsv("./data/rows_by_game_bq.csv", toGameRow).then((data) => {
      let gamePks = data
        .filter((d: any) => d.rows >= minFrameCount)
        .map((d: any) => d.gamePk);

      setGameRowsData(data);
      setValidGamePks(gamePks);
    });
  }, []);

  let handleDayClick = (day: Date) => {
    // let autoInclude = autoIncludeDate.getTime() <= day.getTime()

    if (!schedule) {
      debugger;
    }

    // find games for date in schedule.dates
    let match =
      _find(schedule.dates, (d: any) => {
        let [year, month, date] = d.date.split("-");
        let monthIndex = +month - 1;

        let scheduleDate = new Date(+year, monthIndex, +date);
        return sameDay(scheduleDate, day);
      }) || {};

    let games = match?.games || [];

    // // include >= 9/16/20
    // setDateGames(games.filter((game: any) => autoInclude || validGamePks.includes(game.gamePk + '')))
    // setInvalidGames(games.filter((game: any) => !autoInclude && !validGamePks.includes(game.gamePk + '')))

    setDateGames(games);
  };

  // let invalidGamesNote = invalidGames.length > 0
  //     ? <i>{`excluded games: ${invalidGames.length}`}</i>
  //     : null

  let handleGameClick = (gamePk: string) => {
    onGameSelected(gamePk);
  };

  // TODO    possible to use toAbbrevTeamAtTeam with gumbo instead? need a gumbo prop
  let gameItems = dateGames.map((game: any) => {
    let { gamePk, teams } = game;
    let { away, home } = teams;
    let awayTeamInfo = teamColors.find(
      (t: any) => t.team_id === away.team.id + ""
    );
    let awayAbbrevName =
      awayTeamInfo?.team_abbrev ||
      away.team.name
        .split(" ")
        .map((w: string) => w[0])
        .join("");
    let homeTeamInfo = teamColors.find(
      (t: any) => t.team_id === home.team.id + ""
    );
    let homeAbbrevName =
      homeTeamInfo?.team_abbrev ||
      home.team.name
        .split(" ")
        .map((w: string) => w[0])
        .join("");

    let date = new Date(game.gameDate);
    let autoInclude = autoIncludeDate.getTime() <= date.getTime();
    let isValid = autoInclude || validGamePks.includes(game.gamePk + "");

    let gamePkStr = gamePk + "";
    let gameRows = gameRowsData.find((d) => d.gamePk === gamePkStr);
    let style = colorGamesByRowCount
      ? {
          // @ts-ignore
          color: gameRows ? colorScale(gameRows.rows) : "#fff",
        }
      : {};

    return isValid ? (
      <li
        // @ts-ignore
        style={style}
        key={gamePk}
        onClick={() => handleGameClick(gamePk)}
      >{`${awayAbbrevName} @ ${homeAbbrevName}`}</li>
    ) : (
      <li
        // @ts-ignore
        style={style}
        key={gamePk}
        className={styles.disabled}
      >{`${awayAbbrevName} @ ${homeAbbrevName}`}</li>
    );
  });

  let handleMonthChange = (month: Date) => {
    setMonthDate(month);
    setDateGames([]); // clear selected date
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <DayPicker
          month={new Date()}
          todayButton="Today"
          onDayClick={handleDayClick}
          onMonthChange={handleMonthChange}
        />
      </div>

      <div>
        {/*{invalidGamesNote}*/}

        <ul className={styles.list}>{gameItems}</ul>
      </div>
    </div>
  );
};
