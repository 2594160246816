import React from "react";
import * as THREE from "three";
import { HALF_PI, TWO_PI } from "../../constants/math";

const WHITE = "#fff";

interface Props {
  useFieldAppearanceStore: any;
  shadows?: boolean;
}

export const Mound = ({ useFieldAppearanceStore, shadows = false }: Props) => {
  let sandMaterial = useFieldAppearanceStore(($: any) => $.sandMaterial);
  let { color: sandColor, metalness, roughness, wireframe } = sandMaterial;

  return (
    <group name="mound">
      <mesh receiveShadow rotation={[HALF_PI, 0, 0]} position={[0, 60, -59]}>
        <sphereBufferGeometry
          attach="geometry"
          args={[60, 64, 32, 0, TWO_PI, 0, 0.059 * Math.PI]}
        />
        {!shadows && (
          <meshBasicMaterial
            attach="material"
            color={sandColor}
            side={THREE.FrontSide}
            wireframe={wireframe}
          />
        )}
        {shadows && (
          <meshStandardMaterial
            attach={"material"}
            color={sandColor}
            side={THREE.FrontSide}
            metalness={metalness}
            roughness={roughness}
            wireframe={wireframe}
          />
        )}
      </mesh>
      <mesh receiveShadow name="slab" position={[0, 60.75, 9.5 / 12]}>
        <boxBufferGeometry attach="geometry" args={[2, 0.5, 0.5]} />
        <meshBasicMaterial
          attach="material"
          color={WHITE}
          wireframe={wireframe}
        />
      </mesh>
    </group>
  );
};
