import React, { useMemo } from "react";
import TreeView from "@material-ui/lab/TreeView";
import { toPitcherLabel, toPitchers } from "../../util";
import TreeItem from "@material-ui/lab/TreeItem";
import _filter from "lodash/filter";
import _values from "lodash/values";
import _groupBy from "lodash/groupBy";
import _isNil from "lodash/isNil";
import _includes from "lodash/includes";
import { makeStyles } from "@material-ui/core/styles";
import _some from "lodash/some";

const inPlayCodes = ["D", "E", "X"];

const useBasicPlayStyles = makeStyles({
  root: {
    color: "#fff",
  },
});

const useInPlayPAStyles = makeStyles({
  root: {
    color: "#c0f2ff",
  },
});

const useInPlayPEStyles = makeStyles({
  root: {
    color: "#5de2ff",
  },
});

const isPAInPlay = (pa: any) => {
  let codes = pa.playEvents.map((pe: any) => pe.details?.code);
  let codesInPlay = codes.map((c: string) => inPlayCodes.includes(c));

  return _some(codesInPlay);
};

interface ToHalfInningArgs {
  gumbo: any;
  firstAB?: number;
  lastAB?: number;
}

// TODOHI  filter to playEvents with isPitch true?
let toHalfInnings = (props: ToHalfInningArgs) => {
  let { gumbo, firstAB = 0, lastAB = Infinity } = props;

  if (gumbo) {
    let plays = gumbo.liveData.plays.allPlays;
    let hePlays = _filter(
      plays,
      (p) => firstAB <= p.about.atBatIndex && p.about.atBatIndex <= lastAB
    );

    // console.log('----- hePlays', hePlays)

    return _values(
      _groupBy(
        hePlays,
        (p) => `${p.about.inning}${p.about.isTopInning ? "T" : "B"}`
      )
    );
  } else {
    return [];
  }
};

interface IsValidPlayEventArgs {
  playEvent: any;
  playIdBlacklist?: string[];
}

const isValidPlayEvent = (props: IsValidPlayEventArgs) => {
  let { playEvent, playIdBlacklist = [] } = props;
  let { playId } = playEvent;

  return !_isNil(playId) && !_includes(playIdBlacklist, playId);
};

interface Props {
  gumbo: any;
  onPlaySelected: (gamePk: string, playId: string) => void;
  // onGameSelected: (gamePk: string) => void,
}

export const GameTreeView: React.FC<Props> = ({ gumbo, onPlaySelected }) => {
  let basicPlayClasses = useBasicPlayStyles();
  let inPlayPAClasses = useInPlayPAStyles();
  let inPlayPEClasses = useInPlayPEStyles();

  let nodeId = 1;
  // let halfInnings = useMemo(() => {
  //     // console.log('gumbo', gumbo)
  //
  //     return toHalfInnings({gumbo, firstAB: atBatIndexRange[0], lastAB: atBatIndexRange[1]})
  // }, [gumbo, atBatIndexRange])
  let halfInnings = useMemo(() => toHalfInnings({ gumbo }), [gumbo]);

  let key = 1;

  // // TODOHI   temp - drop after refactoring?
  // let playIdBlacklist: any[] = []

  let treeItems = useMemo(() => {
    function handlePlayEventClick(playId: any) {
      // console.log('--------------- handlePlayEventClick - playId', playId)
      onPlaySelected && onPlaySelected(gumbo.gamePk, playId);
    }

    // if (gumbo?.gamePk !== +gamePk) {
    if (!gumbo) {
      // new gumbo not loaded yet
      // don't know wht isGumboLoading is false, though
      return null;
    }

    return halfInnings.map((pas: any /*i: number*/) => {
      let firstPa = pas[0];
      let { isTopInning, inning } = firstPa.about;
      let half = isTopInning ? "▲" : "▼";
      let pitchers = toPitchers(pas);
      let halfInningLabel = `${inning} ${half} ${pitchers}`;

      let paItems = pas.map((pa: any, j: number) => {
        let { matchup, playEvents } = pa;
        let { batter, batSide } = matchup;
        let { fullName: batterName } = batter;
        let batHandLabel = batSide.code === "L" ? "LHB" : "RHB";
        let pitcherLabel = toPitcherLabel(matchup);
        let paLabel = `• ${pitcherLabel} – ${batterName} (${batHandLabel})`;
        let paDescription = pa?.result?.description;

        let validPlayEvents = playEvents.filter((playEvent: any) =>
          isValidPlayEvent({
            playEvent,
            // playIdBlacklist
          })
        );

        let playEventItems = validPlayEvents.map((
          playEvent: any /*k: number*/
        ) => {
          let { details, playId, pitchNumber } = playEvent;

          let isInPlay = inPlayCodes.includes(playEvent.details?.code);
          let peClasses = isInPlay
            ? inPlayPEClasses.root
            : basicPlayClasses.root;

          // let pitchNum = k + 1
          // let {pitchNumber} = playEvent
          let description = details ? details.description : "---";
          let pitchLabel = `${pitchNumber} ${
            isInPlay ? paDescription : description
          }`;
          // let playEventNodeId = playId || '' + nodeId++

          // TODOHI  conditionally return dep on playId
          // TODOHI  then pass playId to handlePlayEventClick

          // note: don't know why using playId for key doesn't work here
          return (
            <TreeItem
              className={peClasses}
              key={key++}
              nodeId={playId}
              label={pitchLabel}
              onLabelClick={() => handlePlayEventClick(playId)}
            />
          );
        });

        let isInPlayPA = isPAInPlay(pa); //inPlayCodes.includes(playEvent.details?.code)
        let paClasses = isInPlayPA ? inPlayPAClasses.root : undefined;

        // TODOHI  (RHP), (LHB), sit?
        // note: odd how j works for the key here, but TreeItem fails when using i and k for keys at other levels
        return (
          <TreeItem
            className={paClasses}
            key={j}
            nodeId={"" + nodeId++}
            label={paLabel}
          >
            {playEventItems}
          </TreeItem>
        );
      });

      // TODOHI  list pitchers in half inning

      // note: had tried to use i for key, but TreeView then fails for 566452, but not 567633!
      return (
        <TreeItem key={key++} nodeId={"" + nodeId++} label={halfInningLabel}>
          {paItems}
        </TreeItem>
      );
    });
    // }, [gamePk, gumbo, halfInnings, nodeId, onPlaySelected, playIdBlacklist, key,
  }, [
    gumbo,
    halfInnings,
    nodeId,
    onPlaySelected,
    /*playIdBlacklist,*/ key,
    basicPlayClasses.root,
    inPlayPAClasses.root,
    inPlayPEClasses.root,
  ]);

  // TODOHI  memoize, or even drop
  let handleNodeSelect = (event: object, value: any) => {
    // console.log('handleNodeSelect', event, value)
  };

  return (
    <div>
      <TreeView className="play-tree" onNodeSelect={handleNodeSelect}>
        {treeItems}
      </TreeView>
    </div>
  );
};
