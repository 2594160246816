import axios, { AxiosInstance } from "axios";
import { StatsApiNS } from "field-of-things/src/types";

interface FetchGamePlayProps {
  gamePlay: StatsApiNS.GamePlay;
  playTrackingState: StatsApiNS.PlayTrackingState;
  setPlayTrackingState: (_: StatsApiNS.PlayTrackingState) => void;
}

interface getTrackingDataArg {
  gamePk: number | string;
  playId: string;
}

export class ApiClient {
  axiosClient: AxiosInstance;
  axiosInterceptor?: number;

  constructor(accessToken: string) {
    this.axiosClient = axios.create();
    this.axiosInterceptor = this.updateInterceptor(accessToken);
  }

  updateInterceptor = (accessToken: string) => {
    if (this.axiosInterceptor) {
      this.axiosClient.interceptors.request.eject(this.axiosInterceptor);
    }

    return this.axiosClient.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
  };

  get3dModelUrlDomain = () => {
    let url = `/model`;
    return this.axiosClient.post(url);
  };

  getBoxscoreData = (gamePk: string | number) => {
    let url = `/boxscore/${gamePk}/`;

    return this.axiosClient.get(url);
  };

  getPlayData = (gamePk: string | number, playId: string) => {
    let url =
      gamePk + "" === "566452"
        ? `/qa-play/${gamePk}/${playId}`
        : `/play/${gamePk}/${playId}`;

    return this.axiosClient.get(url);
  };

  getTrackingData = ({
    gamePk,
    playId
  }: getTrackingDataArg) => {
    let url = `/tracking/${gamePk}/${playId}`;
    return this.axiosClient.get(url);
  };

  fetchGamePlay = async ({
    gamePlay,
    playTrackingState,
    setPlayTrackingState,
  }: FetchGamePlayProps) => {
    let { gamePk, playId } = gamePlay;

    setPlayTrackingState({
      ...playTrackingState,
      isLoading: true,
      isReady: false,
    });
    try {
      Promise.all([
        this.getPlayData(gamePk, playId),
        this.getTrackingData({ gamePk, playId }),
        this.getBoxscoreData(gamePk),
      ]).then(([playResp, trackingResp, boxscoreResp]) => {
        let { data: playData } = playResp;
        // TODO    probably don't need to package the playId like this anymore?
        let play = { ...playData, playId };
        let { data: playTracking } = trackingResp;
        let boxscore = boxscoreResp.data;

        if (
          playTracking?.skeletalData?.frames === undefined ||
          playTracking.skeletalData.frames.length === 0
        ) {
          console.assert(
            playTracking?.skeletalData?.frames !== undefined,
            `skeletalData.frames undefined – gamePk: ${gamePk}, playId: ${playId}`
          );
          console.assert(
            playTracking.skeletalData.frames.length !== 0,
            `skeletalData.frames empty – gamePk: ${gamePk}, playId: ${playId}`
          );
          alert(
            `skeletalData.frames undefined or empty – gamePk: ${gamePk}, playId: ${playId}`
          );

          // TODO    throw exception instead, to be caught below?
          setPlayTrackingState({
            ...playTrackingState,
            play,
            boxscore,
            isLoading: false,
            isReady: false,
          });

          return; // early
        }

        setPlayTrackingState({
          ...playTrackingState,
          play,
          boxscore,
          tracking: playTracking,
          isLoading: false,
          isReady: true,
        });
      });
    } catch (error) {
      setPlayTrackingState({ ...playTrackingState, error, isReady: false });
    }
  };
}