import React, { useEffect, useRef } from "react";
// import _isNumber from "lodash/isNumber";
// import { IPlayFrameState } from "../ScoutFrame";
import styles from "./StoryboardSpace.module.css";
import { SpaceSelect } from "../SpaceSelect";
import { PlayTime } from "field-of-things/src/components/PlayTime";
import { PlayView } from "field-of-things/src/components/PlayView";
import { Timebase } from "field-of-things/src/models/timebase";
import { useTimebaseState } from "field-of-things/src/hooks";
import { toTimeRange } from "field-of-things/src/util/trackedEvent-util";
// import {TimedTag} from 'field-of-things/src/components/TaggedTimeline'
// import {
//   getTrackedEventAbbreviation,
//   toTrackedEventTimeStampAsMillis,
// } from "field-of-things/src/util/trackedEvent-util";
// import {
//   offsetPlayEventTimestamps,
//   toOrderedTrackedEvents,
//   // toTimeRange,
// } from "../../util/trackedEvent-util";

interface Props {
  rootStore: any;
  appSpace: string;
  onAppSpaceChange: (space: string) => void;
  venueId: number;
  playTracking: any;
  // trackingTimeOffset?: number;
  playData: any;
  // gumboPlay: any;
  onPlayClicked?: () => void;
  // initialPlayFrameState?: IPlayFrameState;
  // onPlayFrameStateChange?: (state: IPlayFrameState) => void;
}

export const StoryboardSpace = (props: Props) => {
  let {
    rootStore,
    appSpace,
    onAppSpaceChange,
    venueId,
    playTracking,
    // trackingTimeOffset = 0,
    playData,
    // gumboPlay,
    // onPlayClicked,
    // initialPlayFrameState,
    // onPlayFrameStateChange,
  } = props;

  let timebaseRef = useRef(new Timebase());
  let timebase = timebaseRef.current;
  let { trackedEvents } = playData;

  // init timebase
  useEffect(() => {
    timebase.timeRange = toTimeRange(trackedEvents);

    // // note: with the timeRange set, setting time to 0 will actually clamp time to the beginning of the range
    // timebase.time = _isNumber(initialState?.time) ? initialState?.time : 0;

    timebase.loop = true;
    // }, [initialState, offsetTrackedEvents, timebase]);
  }, [trackedEvents, timebase]);

  // let tags: TimedTag[] = [];

  // let tags: any[] = useMemo(
  //   () =>
  //     trackedEvents
  //       ? trackedEvents.map((event: any) => {
  //           let { /*playEventType,*/ playEventId: id } = event;
  //           let time = toTrackedEventTimeStampAsMillis(event);
  //           let text = getTrackedEventAbbreviation(event);
  //
  //           return {
  //             id,
  //             time,
  //             text,
  //           };
  //         })
  //       : [],
  //   [trackedEvents]
  // );
  //
  // let handleTagClick = () => {};

  useTimebaseState(timebase);

  return (
    <div className={styles.storyboardSpace}>
      <div className={styles.midTop}>
        <h3>
          Storyboard <span className={styles.dim}>work in progress</span>
        </h3>
      </div>

      <div className={styles.main}>
        <div className={styles.viewer}>
          <div className={styles.playViewContainer}>
            <PlayView
              timebase={timebase}
              playTracking={playTracking}
              playData={playData}
              rootStore={rootStore}
              // cameraRef={cameraRef}
              // view={view}
              venueId={venueId ? venueId : playData.metaData.venue.id}
              // initialCameraMatrixArray={initialCameraMatrixArray}
              // trackingTimeOffset={trackingTimeOffset}
            />
          </div>
          {/*<div>*/}
          {/*  <PlayTime timebase={timebase} trackedEvents={trackedEvents} />*/}
          {/*</div>*/}

          <div>
            {/*<TaggedTimeline*/}
            {/*  timebase={timebase}*/}
            {/*  tags={tags}*/}
            {/*  onTagClick={handleTagClick}*/}
            {/*/>*/}

            <PlayTime
              timebase={timebase}
              trackedEvents={trackedEvents}
              rootStore={rootStore}
              // hotkeys={hotkeys}
            />
          </div>
        </div>
      </div>
      <br />
      <div className={styles.midRight}>
        <SpaceSelect space={appSpace} onChange={onAppSpaceChange} />
      </div>
    </div>
  );
};
