import { Vector3 } from "three";
import _maxBy from "lodash/maxBy";
import { XYZT, XYZTSeries } from "./flycam-util";

const _vMound = new Vector3(0, 60.5, 1);
const _vApex = new Vector3();
const _vSightLine = new Vector3();

interface toCameraMidpointArgs {
  hitSeries: XYZTSeries;
  midpointCameraDistance: number;
  midpointPos?: Vector3;
}

export const toCameraMidpoint = ({
  hitSeries,
  midpointCameraDistance,
  midpointPos = new Vector3(),
}: toCameraMidpointArgs) => {
  // @ts-ignore
  let apex: XYZT = _maxBy(hitSeries, "z");

  _vApex.set(apex.x, apex.y, apex.z);
  _vSightLine.subVectors(_vApex, _vMound).normalize();
  midpointPos.copy(_vApex).addScaledVector(_vSightLine, midpointCameraDistance);

  return {
    t: apex.t,
    p: midpointPos,
    ballPos: apex,
  };
};
