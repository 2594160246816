import React from "react";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { FieldDesignKnobsPanel } from "./FieldDesignKnobsPanel";
import { LightingKnobsPanel } from "./LightingKnobsPanel";
import { DevKnobsPanel } from "./DevKnobsPanel";
import { SwingKnobsPanel } from "./SwingKnobsPanel";

const MyTab = styled(Tab)({
  minWidth: 60,
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

interface Props {
  value: any;
  index: number;
  rootStore: any;
  playTracking: any;
  playData?: any;
}

export const PlusKnobsPanel = (props: Props) => {
  let {
    value,
    index,
    rootStore,
    playData,
    playTracking,
    // ...other
  } = props;

  let useGlStatsStore = rootStore.useGlStats;
  let useVenueStore = rootStore.useVenueStore;
  let useLightingStore = rootStore.useLightingStore;
  let useFieldAppearanceStore = rootStore.useFieldAppearanceStore;
  let useSwingStore = rootStore.useSwingStore;
  let useSwingFlairStore = rootStore.useSwingFlairStore;
  let [tabValue, setTabValue] = React.useState(0);

  let handleChange = (event: any, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <TabPanel value={value} index={index}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="main knobs tabs"
      >
        <MyTab label="Swing" {...a11yProps(0)} />
        <MyTab label="Field Look" {...a11yProps(1)} />
        <MyTab label="Lighting Look" {...a11yProps(2)} />
        <MyTab label="Dev" {...a11yProps(3)} />
      </Tabs>

      <SwingKnobsPanel
        index={0}
        value={tabValue}
        useSwingStore={useSwingStore}
        useSwingFlairStore={useSwingFlairStore}
      />

      <FieldDesignKnobsPanel
        index={1}
        value={tabValue}
        useVenueStore={useVenueStore}
        useFieldAppearanceStore={useFieldAppearanceStore}
      />

      <LightingKnobsPanel
        useLightingStore={useLightingStore}
        index={2}
        value={tabValue}
      />

      <DevKnobsPanel
        index={3}
        value={tabValue}
        useGlStatsStore={useGlStatsStore}
        // time={time}
        playData={playData}
        playTracking={playTracking}
      />
    </TabPanel>
  );
};
