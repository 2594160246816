export const tags = {
  single: "single",
  double: "double",
  triple: "triple",
  hr: "home run",
  steal: "steal",
  run: "run",
  out: "out",
  dp: "double play",
  tp: "triple play",
  throw: "throw",
  sal: "Salt River",
};

export const playListData = [
  {
    description: "Mookie Betts throws out Ketel Marte at third",
    tags: [tags.double, tags.throw],
    gamePk: "630201",
    playId: "db5da897-cf46-43e8-89ea-e0bee4054ac0",
  },
  {
    description: "Justine Turner singles, Mookie Betts scores",
    tags: [tags.single, tags.run],
    gamePk: "631377",
    playId: "178b3900-1626-4d0a-b737-1766e7c43db5",
  },
  {
    description: "Javi Baez steal & score",
    tags: [tags.steal, tags.run],
    gamePk: "631618",
    playId: "d110907a-b9fa-45d1-8472-4f70d68f2e24",
  },
  {
    description: "Tatis leap",
    tags: [tags.out, tags.single],
    gamePk: "635932",
    playId: "9be6653a-4a55-4fa2-bd76-b465dc2e8a4c",
  },
  {
    description: "Brett Phillips WSG4 walk off",
    tags: [tags.run, tags.double],
    gamePk: "635897",
    playId: "a4894498-8428-4c56-8b74-8729771fdea6",
  },
  {
    description: "Michael Taylor grounds into double play",
    tags: [tags.out, tags.dp],
    gamePk: "630856",
    playId: "faf5e0ac-76b7-432d-9eb6-9287743c8680",
  },
  {
    description: "Asdrubal Cabrera grounds into double play",
    tags: [tags.out, tags.dp],
    gamePk: "630856",
    playId: "98753f33-ae79-468c-8b57-17e1f5f246c7",
  },
  {
    description: "TEX @ SEA, 5-3-5 double play, Sep 5, 2020",
    tags: [tags.out, tags.dp],
    gamePk: "631044",
    playId: "d1ee61c7-f8c6-4eae-af30-dd30942b1caf",
  },
  {
    description: "SFG @ LAD, 3-6 double play",
    tags: [tags.out, tags.dp],
    gamePk: "631377",
    playId: "13d9b4a8-7094-4861-aac6-e4125cfa55be",
  },
  {
    description: "Kris Bryant 'triple play'",
    tags: [tags.out, tags.tp],
    gamePk: "631594",
    playId: "3df169b6-0936-42e9-82f9-a24f5cbe9a2e",
  },
  {
    description: "Manuel Margot caught stealing home",
    tags: [tags.out],
    gamePk: "635887",
    playId: "1557b2d1-b0ab-47d8-9040-a7494bd915db",
  },
  {
    description: "Javier Baez HR",
    tags: [tags.hr, tags.run],
    gamePk: "631618",
    playId: "835cf55f-2641-406f-b3aa-6d2315366da6",
  },
  {
    description: "Francisco Lindor HR",
    tags: [tags.hr],
    gamePk: "631618",
    playId: "e254c189-133b-4aed-94bd-dd422d464aa8",
  },
  {
    description: "Michael Taylor HR",
    tags: [tags.hr],
    gamePk: "631146",
    playId: "d7f7a9c2-725f-4ae9-baef-880b4de58b46",
  },
  // broken. these two result in "skeletalData.frames undefined or empty"
  // Haven't yet had a chance to investigate why they load from the calendar, but not here.
  // {
  //   description: "Trea Turner HR",
  //   tags: [tags.hr],
  //   gamePk: "630856",
  //   playId: "630856&play=a4f07156-887b-425a-8561-9f08f95a9791",
  // },
  // {
  //   description: "Starlin Castro triple",
  //   tags: [tags.triple],
  //   gamePk: "630899",
  //   playId: "630899&play=013eba86-12ed-4c72-ac28-c3530109647b",
  // },
  {
    description: "Gleyber Torres HR",
    tags: [tags.hr],
    gamePk: "630856",
    playId: "b8796d1c-b910-4dae-a2b6-89d8e233af95",
  },
  {
    description: "Trea Turner inside-the-park HR",
    tags: [tags.hr],
    gamePk: "631146",
    playId: "230d5191-5511-4d13-a8cf-ef5e79537073",
  },
  {
    description: "Giancarlo Stanton HR",
    tags: [tags.hr],
    gamePk: "630899",
    playId: "a4030dd7-dfe5-40ee-99ee-31bfeada0dc2",
  },
  {
    description: "Victor Robles HR",
    tags: [tags.hr],
    gamePk: "630899",
    playId: "28e9b2e7-7968-4cc3-ae6e-4c2e43af3ab2",
  },
  {
    description: "Asdrubal Cabrera HR",
    tags: [tags.hr],
    gamePk: "630899",
    playId: "7d4d20d6-ef9f-4d19-baa8-38140e2ee145",
  },
  {
    description: "Juan Soto double",
    tags: [tags.double],
    gamePk: "631146",
    playId: "5d8d2f61-34e8-4e77-9891-000daa6d2a5f",
  },
  {
    description: "Giancarlo Stanton double",
    tags: [tags.double],
    gamePk: "630899",
    playId: "3c166cbd-9e0a-4a84-8a41-4ad94b515798",
  },
  {
    description: "Francisco Lindor double",
    tags: [tags.double],
    gamePk: "631618",
    playId: "c4c5312d-9ac1-4f43-949c-b9b613d6d6fd",
  },
  {
    description: "Pablo Sandoval hits into a fielder's choice double play",
    tags: [tags.out, tags.dp],
    gamePk: "631377",
    playId: "a8403a5b-bb75-416f-b53c-1beb59b084db",
  },
  {
    description: "Trea Turner grounds into double play",
    tags: [tags.dp],
    gamePk: "630899",
    playId: "b4d5763b-789f-4485-8abd-2f6a16b9351c",
  },
  {
    description: "Howie Kendrick ground-rule double",
    tags: [tags.double],
    gamePk: "630899",
    playId: "01414220-ce01-4054-b266-118906b58b20",
  },

  {
    description: "Fernando Tatis Jr Grand Slam",
    tags: [tags.hr, tags.run, tags.sal],
    gamePk: "642208",
    playId: "6af946d5-c75f-4e16-bb52-27c3fb312bb3",
  },
  {
    description: "Matt Olson double, Chad Pinder scores",
    tags: [tags.double, tags.run, tags.sal],
    gamePk: "642098",
    playId: "ec14b832-1ef1-466a-8a11-a6a2f986db49",
  },
  {
    description: "Ketel Marte double",
    tags: [tags.double, tags.sal],
    gamePk: "642174",
    playId: "543b4cef-9fd7-4cb6-b92a-8e84c63d6d94",
  },
];
