import React, { useMemo } from "react";
import _flatten from "lodash/flatten";
import _range from "lodash/range";
import { toSwingSeriesSet } from "../Swing/swing-util";
import { LineTrack } from "../Line";
import { ArrayPoint3, lerpArrayPoints } from "../../util/geometry-util";

const defaultTrailLineWidth = 3;
const defaultTrailCount = 10;

const defaultTrailColor = "#052534";

const Trail = ({
  pointSeries,
  color = defaultTrailColor,
  lineWidth = defaultTrailLineWidth,
}: {
  pointSeries: any[];
  color?: string;
  lineWidth?: number;
}) => {
  if (pointSeries.length < 3) {
    return null;
  }

  let flattenedPoints = _flatten(pointSeries.map((p: any) => p.pos));

  return (
    <group>
      <LineTrack points={flattenedPoints} linewidth={lineWidth} color={color} />
    </group>
  );
};

interface toTrailPointSeriesSetsArgs {
  batSeries: any[];
  count?: number;
}

type ArrayPoint3Series = ArrayPoint3[];

const toTrailPointSeriesSets = ({
  batSeries,
  count = defaultTrailCount,
}: toTrailPointSeriesSetsArgs) => {
  let step = 1 / (count - 1);
  let sets: any[] = Array.from(Array(count + 1), () => []);
  let stops = _range(0, 1, step);

  stops.push(1);

  batSeries.forEach((bat: any) => {
    let { time } = bat;
    let p = bat.batKnob;
    let q = bat.batEnd;

    stops.forEach((n: number, i: number) => {
      sets[i].push({
        time,
        pos: lerpArrayPoints(p, q, n),
      });
    });
  });

  return sets;
};

const msEpsilon = 30;

interface Props {
  frames: any;
  time: number;
  playData: any;
  useSwingFlairStore: any;
  useSwingStore: any;
}

export const SwingFlair = ({
  frames,
  time,
  playData,
  useSwingFlairStore,
  useSwingStore,
}: Props) => {
  let swingFlairEnabled = useSwingFlairStore(($: any) => $.swingFlairEnabled);
  let gripsEnabled = useSwingStore(($: any) => $.gripsEnabled);
  let capSwingEnabled = useSwingStore(($: any) => $.capSwingEnabled);
  let wristSeparationSwingEndEnabled = useSwingStore(
    ($: any) => $.wristSeparationSwingEndEnabled
  );
  let wristGripExtension = useSwingStore(($: any) => $.wristGripExtension);
  let trailCount = useSwingFlairStore(($: any) => $.trailCount);
  let lineWidth = useSwingFlairStore(($: any) => $.lineWidth);
  let trailDuration = useSwingFlairStore(($: any) => $.trailDuration);
  let trailDurationVariation = useSwingFlairStore(
    ($: any) => $.trailDurationVariation
  );
  let lineColor = useSwingFlairStore(($: any) => $.lineColor);

  // let {figureFrames, wristSeries, gripSeries, batSeries,} = toSwingSeriesSet({
  let { batSeries } = toSwingSeriesSet({
    playData,
    frames,
    gripsEnabled,
    capSwingEnabled,
    wristSeparationSwingEndEnabled,
    wristGripExtension,
  });
  // // TODO    possible for multiple frames to be within epsilon of time?
  // let bat = batSeries.find((b: any) => Math.abs(b.time - time) <= msEpsilon);

  // find bats in the time window
  let t0 = time - trailDuration;
  let t1 = time + msEpsilon;
  // let trailBats = batSeries.filter(
  //   (bat: any) => t0 <= bat.time && bat.time <= t1
  // );
  //
  // // TODO    initially, trail on tip of bat
  // let points = trailBats.map((bat: any) => ({
  //   time: bat.time,
  //   pos: bat.batEnd,
  // }));

  // TODO    extend to multiple trails from interpolated points on bat
  // TODOHI  call toTrailPointSeriesSets once and memoize, then window each series from results?
  let trailPointSeriesSet = useMemo(
    () => toTrailPointSeriesSets({ batSeries, count: trailCount }),
    [batSeries, trailCount]
  );

  let inTimes: any = useMemo(
    () =>
      trailPointSeriesSet.map(
        () =>
          Math.min(
            t0 -
              0.5 * trailDurationVariation +
              Math.random() * trailDurationVariation
          ),
        t1
      ),
    [trailPointSeriesSet, trailDurationVariation, t0, t1]
  );

  let trails = trailPointSeriesSet.map(
    (series: ArrayPoint3Series, i: number) => {
      let windowed = series.filter(
        (d: any) => inTimes[i] <= d.time && d.time <= t1
      );
      // let pointSeries = windowed.map((d: any) => d.pos);

      return (
        <Trail
          key={i}
          pointSeries={windowed}
          lineWidth={lineWidth}
          color={lineColor}
        />
      );
    }
  );

  // TODO    refactor so that we can bail out earlier when !swingFlairEnabled

  return (
    swingFlairEnabled && (
      <group>
        {/*<Trail pointSeries={points} />*/}
        {trails}
      </group>
    )
  );
};
