import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sliderRoot: {
    width: 200,
  },
}));

interface Props {
  useFigureStrobeStore: any;
}

export const StrobeHitterReactionKnobs = ({ useFigureStrobeStore }: Props) => {
  const classes = useStyles();
  let {
    hitterReactionPFMEnabled,
    setHitterReactionPFMEnabled,
    hitterReactionReleaseEnabled,
    setHitterReactionReleaseEnabled,
    hitterReactionRecoEnabled,
    setHitterReactionRecoEnabled,
    hitterReactionCommitEnabled,
    setHitterReactionCommitEnabled,
    hitterReactionPlateEnabled,
    setHitterReactionPlateEnabled,
  } = useFigureStrobeStore();

  return (
    <div>
      <FormControl className={classes.formControl}>
        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Switch
                checked={hitterReactionPFMEnabled}
                onChange={() =>
                  setHitterReactionPFMEnabled(!hitterReactionPFMEnabled)
                }
                name="pfm-enabled-toggle"
                color="secondary"
                size="small"
              />
            }
            label="PFM"
          />
        </FormControl>
        <FormHelperText>Pitcher First Movement</FormHelperText>

        <br />

        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Switch
                checked={hitterReactionReleaseEnabled}
                onChange={() =>
                  setHitterReactionReleaseEnabled(!hitterReactionReleaseEnabled)
                }
                name="pfm-enabled-toggle"
                color="secondary"
                size="small"
              />
            }
            label="Release"
          />
        </FormControl>
        <FormHelperText>The ball first appears to the hitter</FormHelperText>
        <br />

        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Switch
                checked={hitterReactionRecoEnabled}
                onChange={() =>
                  setHitterReactionRecoEnabled(!hitterReactionRecoEnabled)
                }
                name="reco-enabled-toggle"
                color="secondary"
                size="small"
              />
            }
            label="Recognition Point"
          />
        </FormControl>
        <FormHelperText>100 ms after release</FormHelperText>
        <br />

        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Switch
                checked={hitterReactionCommitEnabled}
                onChange={() =>
                  setHitterReactionCommitEnabled(!hitterReactionCommitEnabled)
                }
                name="commit-enabled-toggle"
                color="secondary"
                size="small"
              />
            }
            label="Commit Point"
          />
        </FormControl>
        <FormHelperText>
          167 ms (1/6th sec) before plate crossing
        </FormHelperText>
        <br />

        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Switch
                checked={hitterReactionPlateEnabled}
                onChange={() =>
                  setHitterReactionPlateEnabled(!hitterReactionPlateEnabled)
                }
                name="plate-enabled-toggle"
                color="secondary"
                size="small"
              />
            }
            label="Plate Crossing"
          />
        </FormControl>
        <FormHelperText>Ball at front of plate</FormHelperText>
      </FormControl>
    </div>
  );
};
