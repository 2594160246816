import React, { useMemo } from "react";
import { CatmullRomCurve3, DoubleSide } from "three";
import { Vector3 } from "three";
import { toBallPointSeries, toPointInSeries } from "../../util/polynomial-util";
import { ballRadius } from "../../constants/dimensions";
import { Segment, segmentColorMap } from "../BallSegmentGroup/BallSegmentGroup";

const radialSegments = 16;

// TODO    could probably forego the points prop and use the series generated here instead of points

interface Props {
  segment: Segment;
  segments: Segment[];
  points: any[];
  materialFromProps?: any;
  time: number;
  radius?: number;
  ballTrailColor: string;
}

export const BallTrail = (props: Props) => {
  let {
    segment,
    segments,
    points,
    materialFromProps,
    time,
    radius = ballRadius,
    ballTrailColor,
  } = props;

  let series = useMemo(() => toBallPointSeries({ segments }), [segments]);

  let color = segmentColorMap[segment.segmentType] || "#00f";

  const ballMaterial = useMemo(() => {
    if (materialFromProps) {
      return materialFromProps;
    } else {
      return (
        <meshStandardMaterial
          attach="material"
          color={color}
          roughness={0.3}
          side={DoubleSide}
        />
      );
    }
  }, [/*segment.segmentType,*/ materialFromProps, color]);

  let points2 = useMemo(() => {
    let timeFiltered = points.filter((p: any) => p.n <= time);
    let pTail = timeFiltered[timeFiltered.length - 1];

    // ensure tail point is at time
    if (pTail && time < segment.endData.time) {
      let p = toPointInSeries({ t: time, series });
      let { x, y, z, t } = p;

      timeFiltered.pop();
      timeFiltered.push({ n: t, x, y, z });
    }

    return timeFiltered;
  }, [points, time, series, segment.endData.time]);
  let pHead = points2[0];
  let pTail = points2[points2.length - 1];

  let curve = useMemo(() => {
    if (points2.length < 3) {
      return null;
    }

    let vectors = points2.map((p: any) => new Vector3(p.x, p.y, p.z));

    return new CatmullRomCurve3(vectors);
  }, [points2]);

  let addTailCap = pTail && time > pTail.n; // add tail cap when ball has moved past the trail

  return (
    curve && (
      <group>
        <mesh castShadow>
          <tubeBufferGeometry
            attach="geometry"
            args={[curve, points.length - 1, radius, radialSegments]}
          />
          {ballMaterial}
        </mesh>

        <mesh position={[pHead.x, pHead.y, pHead.z]}>
          <sphereBufferGeometry attach="geometry" args={[radius, 32, 16]} />
          {ballMaterial}
        </mesh>

        {/* not quite getting the right position. can debug later */}
        {addTailCap && (
          <mesh position={[pTail.x, pTail.y, pTail.z]}>
            <sphereBufferGeometry attach="geometry" args={[radius, 32, 16]} />
            {ballMaterial}
          </mesh>
        )}
      </group>
    )
  );
};
