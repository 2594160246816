import create from "zustand";

export interface ZoneProjectionStore {
  zoneProjectionVisible: boolean;
  toggleZoneProjectionVisible(): void;
}

export const createZoneProjectionStore = () =>
  create<ZoneProjectionStore>((set) => ({
    zoneProjectionVisible: false,
    toggleZoneProjectionVisible: () =>
      set(($) => ({ zoneProjectionVisible: !$.zoneProjectionVisible })),
  }));
