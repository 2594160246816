import { idToPlayEventMap } from "../models/playEventTypes";
import _cloneDeep from "lodash/cloneDeep";
import _sortBy from "lodash/sortBy";
import { StatsApiNS } from "../types";

export const toTrackedEventTimeStampAsMillis = (
  trackedEvent: StatsApiNS.TrackedEvent
) => {
  let { timeStamp } = trackedEvent;

  if (timeStamp.slice(-1) !== "Z") {
    timeStamp += "Z";
  }

  return new Date(timeStamp).getTime();
};

export const getTrackedEventAbbreviation = (
  playEvent: StatsApiNS.TrackedEvent
) => {
  const { playEventType } = playEvent;
  const playEventMetadata = idToPlayEventMap[playEventType];
  return playEventMetadata ? playEventMetadata.abbreviation : playEventType;
};

export const toOrderedTrackedEvents = (playEvents: StatsApiNS.TrackedEvent[]) =>
  _sortBy(playEvents, [toTrackedEventTimeStampAsMillis]);

export const offsetPlayEventTimestamps = (
  playEvents: StatsApiNS.TrackedEvent[],
  offset: number = 0
) => {
  if (!playEvents) {
    return playEvents;
  }

  let playEventsCopy = _cloneDeep(playEvents);

  playEventsCopy.forEach((event) => {
    let t = toTrackedEventTimeStampAsMillis(event);

    event.timeStamp = new Date(t + offset).toISOString();
  });

  return playEventsCopy;
};

const numberCompare = (a: number, b: number) => a - b;

export const toTimeRange = (playEvents: StatsApiNS.TrackedEvent[]) => {
  if (!playEvents || playEvents.length === 0) {
    return [0, 0];
  }

  let times = playEvents
    .map(toTrackedEventTimeStampAsMillis)
    .sort(numberCompare);

  return [times[0], times[times.length - 1]];
};
