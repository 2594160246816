import { ThreeEvent } from "@react-three/fiber";
import React, { useEffect, useRef } from "react";
import { RootStore } from "../../stores";

interface Props {
  rootStore: RootStore;
  children?: any;
}

/**
 * Rotates -pi/2 about x axis so z+ points up, and y+ points forward away from plate tip
 */
export const StatcastGroup = (props: Props) => {
  let { rootStore, children } = props;
  const groupRef = useRef();

  let { useCameraStore } = rootStore;

  const cameraType = useCameraStore(($: any) => $.cameraType);
  const orbitControlsParams = useCameraStore(($: any) => $.orbitControlsParams);
  const setOrbitControlsParams = useCameraStore(
    ($: any) => $.setOrbitControlsParams
  );

  const clickCnt = useRef(0);

  let handleDblClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();

    setTimeout(() => (clickCnt.current = 0), 300);
    clickCnt.current++;

    if (clickCnt.current === 2 && cameraType === "orbit-controls") {
      let { relocDest, relocating } = orbitControlsParams;

      if (!relocating) {
        relocDest.copy(e.point);
        relocDest.setY(0.5);
        setOrbitControlsParams({
          ...orbitControlsParams,
          relocating: true,
          relocStadium: false,
        });
      }
    }
  };

  useEffect(() => {
    let g = groupRef.current;

    // @ts-ignore
    g.rotateX(-0.5 * Math.PI);
  }, []);

  return (
    <group
      name="statcast-group"
      ref={groupRef}
      onClick={(e) => handleDblClick(e)}
    >
      {children}
    </group>
  );
};
