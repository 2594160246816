import create from "zustand";

import { JointsMapType } from "field-of-things/src/components/Figure";

export type JointsLengthType = { [key: string]: number };

export interface FigurePoseStore {
  poseModeEnabled: boolean;
  setPoseModeEnabled(poseModeEnabled: boolean): void;

  posedPositionId: number;
  setPosedPositionId(posedPositionId: number): void;

  centralizePoints: boolean;
  setCentralizePoints(centralizePoints: boolean): void;

  riggedBoneLengths: JointsLengthType;
  setRiggedBoneLengths(riggedBoneLengths: JointsLengthType): void;

  rawBoneLengths: JointsLengthType;
  setRawBoneLengths(rawBoneLengths: JointsLengthType): void;

  riggedPoints: JointsMapType;
  setRiggedPoints(riggedPoints: JointsMapType): void;

  rawPoints: JointsMapType;
  setRawPoints(rawPoints: JointsMapType): void;
}

export const createFigurePoseStore = () =>
  create<FigurePoseStore>((set) => ({
    poseModeEnabled: false,
    setPoseModeEnabled: (poseModeEnabled: boolean) =>
      set({ poseModeEnabled }),

    centralizePoints: true,
    setCentralizePoints: (centralizePoints: boolean) =>
      set({ centralizePoints }),

    posedPositionId: 1,
    setPosedPositionId: (posedPositionId: number) =>
      set({ posedPositionId }),

    riggedBoneLengths: {} as JointsLengthType,
    setRiggedBoneLengths: (riggedBoneLengths: JointsLengthType) =>
      set({ riggedBoneLengths }),

    rawBoneLengths: {} as JointsLengthType,
    setRawBoneLengths: (rawBoneLengths: JointsLengthType) =>
      set({ rawBoneLengths }),

    riggedPoints: {} as JointsMapType,
    setRiggedPoints: (riggedPoints: JointsMapType) =>
      set({ riggedPoints }),

    rawPoints: {} as JointsMapType,
    setRawPoints: (rawPoints: JointsMapType) =>
      set({ rawPoints }),
  }));
