import { csvParse } from "d3-dsv";
import _groupBy from "lodash/groupBy";
import _mapValues from "lodash/mapValues";
import _orderBy from "lodash/orderBy";
import { poloGroundsFencePoints } from "./polo-grounds";

const csv = `venueid,deg,distance_hr_line,height_hr_line,distance_top_of_wall,height_top_of_wall,x_hr_line,y_hr_line
1,-45,329.067,3.308,329.067,3.308,-232.685,232.685
1,-44,338.614,3.321,338.614,3.321,-235.221,243.579
1,-43,345.070,3.291,345.070,3.291,-235.337,252.368
1,-42,350.321,3.235,350.321,3.235,-234.410,260.339
1,-41,354.469,3.212,354.469,3.212,-232.553,267.521
1,-40,357.562,3.212,357.562,3.212,-229.837,273.909
1,-39,359.853,3.278,359.853,3.278,-226.463,279.658
1,-38,363.063,6.744,363.063,6.744,-223.524,286.097
1,-37,363.880,6.744,363.880,6.744,-218.988,290.607
1,-36,364.811,6.744,364.811,6.744,-214.431,295.139
1,-35,365.775,6.598,365.775,6.598,-209.800,299.625
1,-34,367.109,6.598,367.109,6.598,-205.285,304.347
1,-33,368.566,6.598,368.566,6.598,-200.736,309.106
1,-32,370.148,6.598,370.148,6.598,-196.149,313.903
1,-31,371.857,6.598,371.857,6.598,-191.521,318.744
1,-30,373.697,6.598,373.697,6.598,-186.848,323.631
1,-29,375.669,6.598,375.669,6.598,-182.128,328.568
1,-28,377.779,6.598,377.779,6.598,-177.356,333.559
1,-27,380.028,6.598,380.028,6.598,-172.529,338.607
1,-26,382.422,6.598,382.422,6.598,-167.643,343.718
1,-25,384.964,6.598,384.964,6.598,-162.693,348.896
1,-24,387.659,6.598,387.659,6.598,-157.675,354.144
1,-23,389.255,6.799,389.255,6.799,-152.094,358.311
1,-22,388.326,6.799,388.326,6.799,-145.470,360.050
1,-21,387.520,6.799,387.520,6.799,-138.875,361.781
1,-20,386.834,6.799,386.834,6.799,-132.305,363.505
1,-19,386.269,6.799,386.269,6.799,-125.757,365.224
1,-18,385.822,6.799,385.822,6.799,-119.226,366.939
1,-17,385.494,6.799,385.494,6.799,-112.708,368.650
1,-16,385.284,6.799,385.284,6.799,-106.199,370.358
1,-15,385.191,6.799,385.191,6.799,-99.695,372.066
1,-14,385.215,6.799,385.215,6.799,-93.192,373.773
1,-13,385.357,6.799,385.357,6.799,-86.686,375.480
1,-12,385.617,6.799,385.617,6.799,-80.174,377.190
1,-11,385.994,6.799,385.994,6.799,-73.651,378.902
1,-10,386.490,6.799,386.490,6.799,-67.113,380.618
1,-9,387.105,6.799,387.105,6.799,-60.557,382.339
1,-8,387.841,6.799,387.841,6.799,-53.977,384.067
1,-7,388.698,6.799,388.698,6.799,-47.370,385.801
1,-6,389.678,6.799,389.678,6.799,-40.732,387.543
1,-5,390.782,6.799,390.782,6.799,-34.059,389.295
1,-4,392.012,6.799,392.012,6.799,-27.345,391.057
1,-3,393.370,6.799,393.370,6.799,-20.587,392.831
1,-2,394.859,6.799,394.859,6.799,-13.780,394.618
1,-1,396.480,6.799,396.480,6.799,-6.920,396.419
1,0,398.236,6.799,398.236,6.799,0.000,398.236
1,1,400.130,6.799,400.130,6.799,6.983,400.069
1,2,402.165,6.799,402.165,6.799,14.035,401.920
1,3,402.535,6.659,402.535,6.659,21.067,401.983
1,4,399.232,6.659,399.232,6.659,27.849,398.259
1,5,396.103,6.659,396.103,6.659,34.523,394.595
1,6,393.141,6.659,393.141,6.659,41.094,390.987
1,7,390.341,6.659,390.341,6.659,47.571,387.432
1,8,387.698,6.659,387.698,6.659,53.957,383.925
1,9,385.208,6.659,385.208,6.659,60.260,380.465
1,10,382.864,6.659,382.864,6.659,66.484,377.048
1,11,380.665,6.659,380.665,6.659,72.634,373.671
1,12,378.605,6.659,378.605,6.659,78.716,370.332
1,13,376.682,6.659,376.682,6.659,84.735,367.027
1,14,374.891,6.659,374.891,6.659,90.694,363.755
1,15,373.231,6.659,373.231,6.659,96.599,360.514
1,16,371.698,6.659,371.698,6.659,102.454,357.299
1,17,370.290,6.659,370.290,6.659,108.262,354.110
1,18,369.005,6.659,369.005,6.659,114.029,350.944
1,19,367.840,6.659,367.840,6.659,119.757,347.799
1,20,366.794,6.659,366.794,6.659,125.451,344.673
1,21,365.864,6.659,365.864,6.659,131.114,341.564
1,22,365.051,6.659,365.051,6.659,136.751,338.469
1,23,364.352,6.659,364.352,6.659,142.364,335.388
1,24,363.766,6.659,363.766,6.659,147.957,332.317
1,25,363.292,6.659,363.292,6.659,153.534,329.255
1,26,362.931,6.659,362.931,6.659,159.098,326.200
1,27,362.680,6.659,362.680,6.659,164.653,323.150
1,28,362.540,6.659,362.540,6.659,170.202,320.104
1,29,362.510,6.659,362.510,6.659,175.748,317.058
1,30,362.591,6.659,362.591,6.659,181.295,314.013
1,31,362.782,6.659,362.782,6.659,186.847,310.965
1,32,363.085,6.659,363.085,6.659,192.406,307.913
1,33,363.498,6.659,363.498,6.659,197.975,304.855
1,34,364.024,6.659,364.024,6.659,203.560,301.789
1,35,364.662,6.659,364.662,6.659,209.162,298.714
1,36,364.456,6.659,364.456,6.659,214.222,294.851
1,37,363.444,6.659,363.444,6.659,218.726,290.260
1,38,362.549,6.659,362.549,6.659,223.207,285.692
1,39,360.178,3.218,360.178,3.218,226.667,279.911
1,40,358.071,3.287,358.071,3.287,230.163,274.298
1,41,354.893,3.287,354.893,3.287,232.831,267.841
1,42,350.840,3.302,350.840,3.302,234.758,260.725
1,43,345.578,3.272,345.578,3.272,235.683,252.740
1,44,339.125,3.269,339.125,3.269,235.576,243.946
1,45,329.829,3.286,329.829,3.286,233.225,233.225
2,-45,332.941,6.440,332.941,6.440,-235.425,235.425
2,-44,335.467,6.440,335.467,6.440,-233.035,241.315
2,-43,338.136,6.440,338.136,6.440,-230.608,247.297
2,-42,340.952,6.440,340.952,6.440,-228.141,253.376
2,-41,343.920,6.440,343.920,6.440,-225.632,259.560
2,-40,347.048,6.440,347.048,6.440,-223.078,265.854
2,-39,350.341,6.440,350.341,6.440,-220.477,272.266
2,-38,353.806,6.440,353.806,6.440,-217.824,278.803
2,-37,356.368,6.525,356.368,6.525,-214.468,284.608
2,-36,356.121,6.525,356.121,6.525,-209.322,288.108
2,-35,355.982,6.525,355.982,6.525,-204.183,291.603
2,-34,355.951,6.525,355.951,6.525,-199.045,295.097
2,-33,356.029,6.525,356.029,6.525,-193.907,298.591
2,-32,356.216,6.525,356.216,6.525,-188.765,302.088
2,-31,356.511,6.525,356.511,6.525,-183.617,305.589
2,-30,356.916,6.525,356.916,6.525,-178.458,309.098
2,-29,357.430,6.525,357.430,6.525,-173.286,312.615
2,-28,358.055,6.525,358.055,6.525,-168.097,316.144
2,-27,358.792,6.525,358.792,6.525,-162.888,319.686
2,-26,359.642,6.525,359.642,6.525,-157.657,323.244
2,-25,360.606,6.525,360.606,6.525,-152.399,326.820
2,-24,361.686,6.525,361.686,6.525,-147.111,330.416
2,-23,362.882,6.525,362.882,6.525,-141.789,334.035
2,-22,364.199,6.525,364.199,6.525,-136.431,337.679
2,-21,365.636,6.525,365.636,6.525,-131.032,341.351
2,-20,367.197,6.525,367.197,6.525,-125.589,345.053
2,-19,368.885,6.525,368.885,6.525,-120.097,348.787
2,-18,370.701,6.525,370.701,6.525,-114.553,352.558
2,-17,372.650,6.525,372.650,6.525,-108.952,356.367
2,-16,374.734,6.525,374.734,6.525,-103.291,360.217
2,-15,376.956,6.525,376.956,6.525,-97.563,364.112
2,-14,379.322,6.525,379.322,6.525,-91.766,368.054
2,-13,381.834,6.525,381.834,6.525,-85.894,372.048
2,-12,384.498,6.525,384.498,6.525,-79.942,376.096
2,-11,387.318,6.525,387.318,6.525,-73.904,380.202
2,-10,390.300,6.525,390.300,6.525,-67.775,384.370
2,-9,393.448,6.525,393.448,6.525,-61.549,388.604
2,-8,396.770,6.525,396.770,6.525,-55.220,392.909
2,-7,400.271,6.525,400.271,6.525,-48.781,397.288
2,-6,403.959,6.525,403.959,6.525,-42.225,401.746
2,-5,407.840,6.525,407.840,6.525,-35.546,406.289
2,-4,407.923,6.403,407.923,6.403,-28.455,406.929
2,-3,405.513,6.403,405.513,6.403,-21.223,404.958
2,-2,403.255,6.403,403.255,6.403,-14.073,403.009
2,-1,401.143,6.403,401.143,6.403,-7.001,401.081
2,0,399.173,6.403,399.173,6.403,0.000,399.173
2,1,397.344,6.403,397.344,6.403,6.935,397.283
2,2,395.651,6.403,395.651,6.403,13.808,395.410
2,3,394.092,6.403,394.092,6.403,20.625,393.552
2,4,392.664,6.403,392.664,6.403,27.391,391.708
2,5,391.366,6.403,391.366,6.403,34.110,389.877
2,6,390.194,6.403,390.194,6.403,40.786,388.057
2,7,389.148,6.403,389.148,6.403,47.425,386.247
2,8,388.225,6.403,388.225,6.403,54.031,384.447
2,9,387.425,6.403,387.425,6.403,60.607,382.655
2,10,386.745,6.403,386.745,6.403,67.158,380.869
2,11,386.185,6.403,386.185,6.403,73.688,379.089
2,12,385.744,6.403,385.744,6.403,80.201,377.314
2,13,385.421,6.403,385.421,6.403,86.701,375.543
2,14,385.216,6.403,385.216,6.403,93.192,373.773
2,15,385.128,6.403,385.128,6.403,99.679,372.006
2,16,385.158,6.403,385.158,6.403,106.164,370.238
2,17,385.305,6.403,385.305,6.403,112.652,368.469
2,18,385.570,6.403,385.570,6.403,119.148,366.699
2,19,385.953,6.403,385.953,6.403,125.654,364.926
2,20,384.556,6.403,384.556,6.403,131.526,361.364
2,21,379.830,6.556,379.830,6.556,136.119,354.602
2,22,375.280,6.556,375.280,6.556,140.582,347.954
2,23,370.950,6.556,370.950,6.556,144.942,341.461
2,24,366.828,6.556,366.828,6.556,149.202,335.114
2,25,362.907,6.556,362.907,6.556,153.371,328.905
2,26,359.177,6.556,359.177,6.556,157.453,322.826
2,27,355.630,6.556,355.630,6.556,161.452,316.868
2,28,352.258,6.556,352.258,6.556,165.375,311.026
2,29,349.176,19.959,349.176,19.959,169.284,305.397
2,30,346.182,19.959,346.182,19.959,173.091,299.802
2,31,343.342,19.959,343.342,19.959,176.834,294.301
2,32,340.651,19.959,340.651,19.959,180.518,288.889
2,33,338.104,19.959,338.104,19.959,184.145,283.558
2,34,335.697,19.959,335.697,19.959,187.719,278.305
2,35,333.424,19.959,333.424,19.959,191.244,273.125
2,36,331.283,19.959,331.283,19.959,194.723,268.013
2,37,329.268,19.959,329.268,19.959,198.158,262.965
2,38,327.377,19.959,327.377,19.959,201.553,257.976
2,39,325.606,19.959,325.606,19.959,204.910,253.043
2,40,323.952,19.959,323.952,19.959,208.232,248.161
2,41,322.412,19.959,322.412,19.959,211.521,243.328
2,42,320.985,19.959,320.985,19.959,214.781,238.538
2,43,319.667,19.959,319.667,19.959,218.012,233.789
2,44,318.456,19.959,318.456,19.959,221.218,229.078
2,45,317.506,19.615,317.506,19.615,224.510,224.510
3,-45,308.432,37.100,308.432,37.100,-218.094,218.094
3,-44,308.439,37.100,308.439,37.100,-214.259,221.872
3,-43,308.539,37.100,308.539,37.100,-210.423,225.651
3,-42,308.734,37.100,308.734,37.100,-206.583,229.434
3,-41,309.023,37.100,309.023,37.100,-202.738,233.223
3,-40,309.408,37.100,309.408,37.100,-198.883,237.020
3,-39,309.887,37.100,309.887,37.100,-195.018,240.828
3,-38,310.463,37.100,310.463,37.100,-191.140,244.648
3,-37,311.136,37.100,311.136,37.100,-187.246,248.484
3,-36,311.907,37.100,311.907,37.100,-183.334,252.338
3,-35,312.778,37.100,312.778,37.100,-179.402,256.212
3,-34,313.749,37.100,313.749,37.100,-175.446,260.109
3,-33,314.822,37.100,314.822,37.100,-171.464,264.032
3,-32,315.999,37.100,315.999,37.100,-167.454,267.983
3,-31,317.283,37.100,317.283,37.100,-163.413,271.964
3,-30,318.674,37.100,318.674,37.100,-159.337,275.980
3,-29,320.175,37.100,320.175,37.100,-155.224,280.031
3,-28,321.789,37.100,321.789,37.100,-151.071,284.123
3,-27,323.519,37.100,323.519,37.100,-146.874,288.257
3,-26,325.366,37.100,325.366,37.100,-142.631,292.437
3,-25,327.336,37.100,327.336,37.100,-138.338,296.667
3,-24,329.430,37.100,329.430,37.100,-133.991,300.949
3,-23,331.653,37.100,331.653,37.100,-129.587,305.288
3,-22,334.008,37.100,334.008,37.100,-125.122,309.687
3,-21,336.501,37.100,336.501,37.100,-120.591,314.150
3,-20,339.135,37.100,339.135,37.100,-115.991,318.682
3,-19,341.915,37.100,341.915,37.100,-111.317,323.287
3,-18,344.848,37.100,344.848,37.100,-106.564,327.970
3,-17,347.938,37.100,347.938,37.100,-101.727,332.734
3,-16,351.192,37.100,351.192,37.100,-96.802,337.587
3,-15,354.616,37.100,354.616,37.100,-91.781,342.533
3,-14,358.218,37.100,358.218,37.100,-86.661,347.577
3,-13,362.005,37.100,362.005,37.100,-81.433,352.727
3,-12,365.986,37.100,365.986,37.100,-76.093,357.988
3,-11,370.169,37.100,370.169,37.100,-70.632,363.368
3,-10,374.565,37.100,374.565,37.100,-65.043,368.875
3,-9,379.183,37.100,379.183,37.100,-59.317,374.515
3,-8,381.926,17.028,381.926,17.028,-53.154,378.209
3,-7,381.105,17.028,381.105,17.028,-46.445,378.265
3,-6,380.695,17.039,380.695,17.039,-39.793,378.609
3,-5,381.776,17.039,381.776,17.039,-33.274,380.323
3,-4,382.980,17.039,382.980,17.039,-26.715,382.048
3,-3,384.310,17.039,384.310,17.039,-20.113,383.783
3,-2,385.766,17.039,385.766,17.039,-13.463,385.531
3,-1,387.353,17.039,387.353,17.039,-6.760,387.294
3,0,389.071,17.039,389.071,17.039,0.000,389.071
3,1,390.924,17.039,390.924,17.039,6.823,390.864
3,2,392.915,17.039,392.915,17.039,13.713,392.676
3,3,395.047,17.039,395.047,17.039,20.675,394.506
3,4,397.325,17.039,397.325,17.039,27.716,396.357
3,5,399.751,17.039,399.751,17.039,34.841,398.230
3,6,402.331,17.039,402.331,17.039,42.055,400.127
3,7,405.068,17.039,405.068,17.039,49.365,402.048
3,8,407.967,17.039,407.967,17.039,56.778,403.997
3,9,411.035,17.039,411.035,17.039,64.300,405.975
3,10,414.276,17.039,414.276,17.039,71.938,407.983
3,11,417.698,17.039,417.698,17.039,79.700,410.023
3,12,409.996,6.959,409.996,6.959,85.243,401.036
3,13,396.050,6.879,396.050,6.879,89.092,385.900
3,14,383.145,6.879,383.145,6.879,92.691,371.764
3,15,380.998,3.899,380.998,3.899,98.610,368.016
3,16,379.831,3.899,379.831,3.899,104.696,365.117
3,17,378.787,3.899,378.787,3.899,110.746,362.235
3,18,377.862,3.899,377.862,3.899,116.766,359.369
3,19,377.057,3.899,377.057,3.899,122.758,356.515
3,20,376.370,3.899,376.370,3.899,128.726,353.672
3,21,375.799,3.899,375.799,3.899,134.674,350.839
3,22,375.345,3.899,375.345,3.899,140.607,348.014
3,23,375.005,3.899,375.005,3.899,146.526,345.194
3,24,374.781,3.899,374.781,3.899,152.437,342.379
3,25,374.670,3.899,374.670,3.899,158.342,339.566
3,26,374.674,3.899,374.674,3.899,164.246,336.755
3,27,374.792,3.899,374.792,3.899,170.152,333.942
3,28,375.024,3.899,375.024,3.899,176.063,331.127
3,29,375.371,3.899,375.371,3.899,181.983,328.307
3,30,375.833,3.899,375.833,3.899,187.917,325.481
3,31,376.411,3.899,376.411,3.899,193.866,322.647
3,32,377.106,3.899,377.106,3.899,199.836,319.804
3,33,377.919,3.899,377.919,3.899,205.829,316.949
3,34,378.851,3.899,378.851,3.899,211.851,314.082
3,35,379.903,3.899,379.903,3.899,217.904,311.199
3,36,380.873,1.840,380.873,1.840,223.871,308.133
3,37,380.454,1.719,380.454,1.719,228.963,303.844
3,38,379.039,1.799,379.039,1.799,233.360,298.687
3,39,376.439,1.810,376.439,1.810,236.901,292.548
3,40,372.148,2.159,372.148,2.159,239.212,285.082
3,41,365.428,2.700,365.428,2.700,239.742,275.792
3,42,352.403,3.570,352.403,3.570,235.804,261.887
3,43,332.717,3.659,332.717,3.659,226.913,243.334
3,44,315.196,3.659,315.196,3.659,218.954,226.733
3,45,299.515,3.659,299.515,3.659,211.789,211.789
4,-45,328.411,6.669,328.411,6.669,-232.221,232.221
4,-44,329.466,6.669,329.466,6.669,-228.866,236.998
4,-43,330.629,6.669,330.629,6.669,-225.488,241.807
4,-42,331.902,6.669,331.902,6.669,-222.086,246.651
4,-41,333.286,6.669,333.286,6.669,-218.655,251.534
4,-40,334.785,6.669,334.785,6.669,-215.195,256.460
4,-39,336.400,6.669,336.400,6.669,-211.703,261.432
4,-38,338.134,6.669,338.134,6.669,-208.176,266.453
4,-37,339.990,6.669,339.990,6.669,-204.611,271.528
4,-36,341.971,6.669,341.971,6.669,-201.006,276.661
4,-35,344.081,6.669,344.081,6.669,-197.357,281.855
4,-34,346.324,6.669,346.324,6.669,-193.662,287.115
4,-33,348.250,6.669,348.250,6.669,-189.670,292.067
4,-32,349.442,6.669,349.442,6.669,-185.176,296.343
4,-31,350.749,6.669,350.749,6.669,-180.649,300.650
4,-30,352.173,6.669,352.173,6.669,-176.087,304.991
4,-29,353.718,6.669,353.718,6.669,-171.486,309.369
4,-28,355.385,6.669,355.385,6.669,-166.843,313.786
4,-27,357.177,6.669,357.177,6.669,-162.155,318.247
4,-26,359.097,6.669,359.097,6.669,-157.418,322.754
4,-25,361.148,6.669,361.148,6.669,-152.628,327.311
4,-24,363.334,6.669,363.334,6.669,-147.781,331.922
4,-23,365.659,6.669,365.659,6.669,-142.874,336.591
4,-22,368.127,6.669,368.127,6.669,-137.903,341.321
4,-21,370.742,6.669,370.742,6.669,-132.862,346.117
4,-20,373.509,6.669,373.509,6.669,-127.748,350.983
4,-19,376.433,6.669,376.433,6.669,-122.555,355.924
4,-18,378.735,6.808,378.735,6.808,-117.035,360.198
4,-17,380.888,6.808,380.888,6.808,-111.361,364.245
4,-16,382.979,6.869,382.979,6.869,-105.563,368.143
4,-15,384.453,6.869,384.453,6.869,-99.504,371.353
4,-14,386.057,6.869,386.057,6.869,-93.396,374.589
4,-13,387.792,6.869,387.792,6.869,-87.234,377.853
4,-12,389.081,6.759,389.081,6.759,-80.895,380.579
4,-11,390.214,6.759,390.214,6.759,-74.456,383.044
4,-10,391.472,6.759,391.472,6.759,-67.978,385.525
4,-9,392.859,6.759,392.859,6.759,-61.457,388.022
4,-8,394.846,6.767,394.846,6.767,-54.952,391.003
4,-7,397.722,6.767,397.722,6.767,-48.470,394.758
4,-6,400.764,6.767,400.764,6.767,-41.891,398.569
4,-5,401.281,6.749,401.281,6.749,-34.974,399.754
4,-4,400.716,6.749,400.716,6.749,-27.953,399.739
4,-3,400.273,6.749,400.273,6.749,-20.949,399.725
4,-2,399.953,6.749,399.953,6.749,-13.958,399.710
4,-1,399.756,6.749,399.756,6.749,-6.977,399.695
4,0,399.680,6.749,399.680,6.749,0.000,399.680
4,1,399.726,6.749,399.726,6.749,6.976,399.665
4,2,399.894,6.749,399.894,6.749,13.956,399.651
4,3,400.184,6.749,400.184,6.749,20.944,399.636
4,4,400.597,6.749,400.597,6.749,27.944,399.621
4,5,401.133,6.749,401.133,6.749,34.961,399.606
4,6,400.799,6.749,400.799,6.749,41.895,398.604
4,7,397.652,6.749,397.652,6.749,48.462,394.688
4,8,394.673,6.749,394.673,6.749,54.928,390.832
4,9,392.586,6.749,392.586,6.749,61.414,387.752
4,10,391.261,6.687,391.261,6.687,67.942,385.317
4,11,390.235,6.687,390.235,6.687,74.460,383.065
4,12,389.332,6.687,389.332,6.687,80.947,380.825
4,13,388.546,6.779,388.546,6.779,87.404,378.587
4,14,386.479,6.779,386.479,6.779,93.498,374.998
4,15,384.550,6.779,384.550,6.779,99.529,371.447
4,16,382.756,6.779,382.756,6.779,105.502,367.929
4,17,381.095,6.779,381.095,6.779,111.421,364.443
4,18,379.563,6.779,379.563,6.779,117.291,360.986
4,19,377.930,6.858,377.930,6.858,123.042,357.340
4,20,375.662,6.858,375.662,6.858,128.484,353.006
4,21,373.533,6.858,373.533,6.858,133.862,348.723
4,22,371.442,6.898,371.442,6.898,139.145,344.395
4,23,368.615,6.898,368.615,6.898,144.029,339.312
4,24,365.884,7.039,365.884,7.039,148.819,334.252
4,25,363.229,7.039,363.229,7.039,153.507,329.197
4,26,360.720,7.039,360.720,7.039,158.129,324.213
4,27,358.355,7.039,358.355,7.039,162.690,319.297
4,28,356.128,7.039,356.128,7.039,167.192,314.442
4,29,354.036,7.039,354.036,7.039,171.640,309.647
4,30,351.938,6.738,351.938,6.738,175.969,304.787
4,31,349.966,6.738,349.966,6.738,180.246,299.979
4,32,348.121,6.738,348.121,6.738,184.476,295.224
4,33,346.401,6.738,346.401,6.738,188.664,290.516
4,34,344.802,6.738,344.802,6.738,192.811,285.854
4,35,343.322,6.738,343.322,6.738,196.922,281.233
4,36,341.959,6.738,341.959,6.738,200.998,276.650
4,37,340.709,6.738,340.709,6.738,205.044,272.103
4,38,339.572,6.738,339.572,6.738,209.061,267.586
4,39,338.545,6.738,338.545,6.738,213.053,263.099
4,40,337.627,6.738,337.627,6.738,217.022,258.637
4,41,336.816,6.738,336.816,6.738,220.971,254.198
4,42,336.111,6.738,336.111,6.738,224.902,249.779
4,43,335.511,6.738,335.511,6.738,228.818,245.377
4,44,335.015,6.738,335.015,6.738,232.721,240.990
4,45,334.622,6.738,334.622,6.738,236.614,236.614
5,-45,324.798,20.347,324.798,20.347,-229.667,229.667
5,-44,326.653,20.347,326.653,20.347,-226.912,234.975
5,-43,328.630,20.347,328.630,20.347,-224.125,240.345
5,-42,330.732,20.347,330.732,20.347,-221.303,245.782
5,-41,332.963,20.347,332.963,20.347,-218.444,251.291
5,-40,335.328,20.347,335.328,20.347,-215.545,256.876
5,-39,337.830,20.347,337.830,20.347,-212.603,262.543
5,-38,340.474,20.347,340.474,20.347,-209.616,268.297
5,-37,343.265,20.347,343.265,20.347,-206.582,274.143
5,-36,346.208,20.347,346.208,20.347,-203.496,280.088
5,-35,349.310,20.347,349.310,20.347,-200.356,286.138
5,-34,352.576,20.347,352.576,20.347,-197.158,292.299
5,-33,354.055,20.247,354.055,20.247,-192.832,296.935
5,-32,354.191,20.247,354.191,20.247,-187.693,300.371
5,-31,354.436,20.247,354.436,20.247,-182.548,303.811
5,-30,354.789,20.247,354.789,20.247,-177.394,307.256
5,-29,355.251,20.247,355.251,20.247,-172.229,310.710
5,-28,355.823,20.247,355.823,20.247,-167.049,314.173
5,-27,356.505,20.247,356.505,20.247,-161.850,317.649
5,-26,357.299,20.247,357.299,20.247,-156.630,321.139
5,-25,358.206,20.247,358.206,20.247,-151.385,324.645
5,-24,359.228,20.247,359.228,20.247,-146.111,328.171
5,-23,360.365,20.247,360.365,20.247,-140.806,331.718
5,-22,361.620,20.247,361.620,20.247,-135.465,335.288
5,-21,362.995,20.247,362.995,20.247,-130.086,338.885
5,-20,364.491,20.247,364.491,20.247,-124.663,342.510
5,-19,366.112,20.247,366.112,20.247,-119.195,346.166
5,-18,367.861,20.247,367.861,20.247,-113.675,349.856
5,-17,369.739,20.247,369.739,20.247,-108.101,353.583
5,-16,371.750,20.247,371.750,20.247,-102.468,357.349
5,-15,373.897,20.247,373.897,20.247,-96.772,361.157
5,-14,376.185,20.247,376.185,20.247,-91.007,365.011
5,-13,378.617,20.247,378.617,20.247,-85.170,368.913
5,-12,381.198,20.247,381.198,20.247,-79.255,372.868
5,-11,383.931,20.247,383.931,20.247,-73.258,376.878
5,-10,386.823,20.247,386.823,20.247,-67.171,380.947
5,-9,389.879,20.247,389.879,20.247,-60.990,385.079
5,-8,393.104,20.247,393.104,20.247,-54.709,389.278
5,-7,396.504,20.247,396.504,20.247,-48.322,393.549
5,-6,400.087,20.247,400.087,20.247,-41.820,397.895
5,-5,403.859,20.247,403.859,20.247,-35.199,402.322
5,-4,407.828,20.247,407.828,20.247,-28.449,406.835
5,-3,408.591,7.680,408.591,7.680,-21.384,408.031
5,-2,405.568,7.680,405.568,7.680,-14.154,405.321
5,-1,402.712,7.680,402.712,7.680,-7.028,402.650
5,0,400.016,7.680,400.016,7.680,0.000,400.016
5,1,397.477,7.680,397.477,7.680,6.937,397.417
5,2,395.090,7.680,395.090,7.680,13.788,394.849
5,3,392.849,7.680,392.849,7.680,20.560,392.311
5,4,390.753,7.680,390.753,7.680,27.258,389.801
5,5,388.796,7.680,388.796,7.680,33.886,387.317
5,6,386.977,7.680,386.977,7.680,40.450,384.857
5,7,385.291,7.680,385.291,7.680,46.955,382.419
5,8,383.736,7.680,383.736,7.680,53.406,380.001
5,9,382.310,7.680,382.310,7.680,59.806,377.603
5,10,381.009,7.680,381.009,7.680,66.162,375.221
5,11,379.833,7.680,379.833,7.680,72.476,372.855
5,12,378.779,7.680,378.779,7.680,78.753,370.502
5,13,377.846,7.680,377.846,7.680,84.997,368.162
5,14,377.032,7.680,377.032,7.680,91.212,365.833
5,15,376.336,7.680,376.336,7.680,97.403,363.513
5,16,375.757,7.680,375.757,7.680,103.573,361.200
5,17,375.293,7.680,375.293,7.680,109.725,358.895
5,18,374.945,7.680,374.945,7.680,115.864,356.594
5,19,374.711,7.680,374.711,7.680,121.994,354.296
5,20,374.592,7.680,374.592,7.680,128.118,352.001
5,21,374.587,7.680,374.587,7.680,134.240,349.707
5,22,374.696,7.680,374.696,7.680,140.364,347.412
5,23,374.919,7.680,374.919,7.680,146.493,345.115
5,24,375.257,7.680,375.257,7.680,152.631,342.815
5,25,372.505,7.664,372.505,7.664,157.427,337.604
5,26,368.649,7.664,368.649,7.664,161.605,331.340
5,27,364.983,7.664,364.983,7.664,165.699,325.202
5,28,361.498,7.664,361.498,7.664,169.713,319.183
5,29,358.186,7.664,358.186,7.664,173.652,313.277
5,30,355.042,7.664,355.042,7.664,177.521,307.476
5,31,352.059,7.664,352.059,7.664,181.324,301.774
5,32,349.232,7.664,349.232,7.664,185.065,296.165
5,33,346.554,7.664,346.554,7.664,188.747,290.644
5,34,344.021,7.664,344.021,7.664,192.374,285.206
5,35,341.627,7.664,341.627,7.664,195.949,279.845
5,36,339.370,7.664,339.370,7.664,199.477,274.556
5,37,337.244,7.664,337.244,7.664,202.959,269.335
5,38,335.246,7.664,335.246,7.664,206.398,264.178
5,39,333.477,7.745,333.477,7.745,209.864,259.160
5,40,331.701,7.745,331.701,7.745,213.214,254.098
5,41,330.044,7.745,330.044,7.745,216.529,249.088
5,42,328.504,7.745,328.504,7.745,219.812,244.126
5,43,327.077,12.096,327.077,12.096,223.066,239.209
5,44,325.773,12.096,325.773,12.096,226.301,234.342
5,45,324.578,12.096,324.578,12.096,229.512,229.512
7,-45,326.992,9.634,326.992,9.634,-231.218,231.218
7,-44,338.204,9.762,338.204,9.762,-234.936,243.284
7,-43,345.217,9.769,345.217,9.769,-235.437,252.476
7,-42,350.666,9.737,350.666,9.737,-234.641,260.595
7,-41,354.908,9.767,354.908,9.767,-232.841,267.853
7,-40,357.014,7.964,357.014,7.964,-229.484,273.489
7,-39,358.091,7.964,358.091,7.964,-225.354,278.289
7,-38,359.284,7.964,359.284,7.964,-221.197,283.119
7,-37,360.595,7.964,360.595,7.964,-217.011,287.984
7,-36,362.026,7.964,362.026,7.964,-212.794,292.885
7,-35,363.580,7.964,363.580,7.964,-208.541,297.827
7,-34,365.259,7.964,365.259,7.964,-204.250,302.814
7,-33,367.066,7.964,367.066,7.964,-199.919,307.848
7,-32,369.004,7.964,369.004,7.964,-195.542,312.933
7,-31,371.076,7.964,371.076,7.964,-191.118,318.075
7,-30,373.286,7.964,373.286,7.964,-186.643,323.275
7,-29,375.481,8.078,375.481,8.078,-182.037,328.404
7,-28,377.243,9.146,377.243,9.146,-177.105,333.086
7,-27,378.792,9.146,378.792,9.146,-171.968,337.507
7,-26,380.372,9.087,380.372,9.087,-166.744,341.876
7,-25,381.966,9.114,381.966,9.114,-161.426,346.179
7,-24,383.590,9.114,383.590,9.114,-156.020,350.426
7,-23,385.177,9.114,385.177,9.114,-150.501,354.557
7,-22,386.851,9.095,386.851,9.095,-144.917,358.682
7,-21,388.511,9.165,388.511,9.165,-139.230,362.706
7,-20,390.182,9.150,390.182,9.150,-133.450,366.651
7,-19,391.665,9.150,391.665,9.150,-127.514,370.326
7,-18,393.280,9.150,393.280,9.150,-121.530,374.031
7,-17,394.889,9.124,394.889,9.124,-115.454,377.634
7,-16,396.463,9.102,396.463,9.102,-109.280,381.105
7,-15,398.100,9.100,398.100,9.100,-103.036,384.535
7,-14,399.690,9.100,399.690,9.100,-96.694,387.817
7,-13,401.083,9.114,401.083,9.114,-90.224,390.803
7,-12,402.390,9.127,402.390,9.127,-83.662,393.597
7,-11,403.660,9.094,403.660,9.094,-77.022,396.243
7,-10,404.762,9.094,404.762,9.094,-70.286,398.613
7,-9,405.897,9.124,405.897,9.124,-63.496,400.900
7,-8,406.865,9.149,406.865,9.149,-56.625,402.905
7,-7,407.599,9.149,407.599,9.149,-49.674,404.561
7,-6,408.205,7.674,408.205,7.674,-42.669,405.969
7,-5,408.837,7.677,408.837,7.677,-35.632,407.281
7,-4,409.288,7.696,409.288,7.696,-28.550,408.291
7,-3,409.641,7.679,409.641,7.679,-21.439,409.079
7,-2,409.864,7.681,409.864,7.681,-14.304,409.615
7,-1,409.994,7.688,409.994,7.688,-7.155,409.931
7,0,410.076,7.652,410.076,7.652,0.000,410.076
7,1,410.067,7.670,410.067,7.670,7.157,410.004
7,2,410.005,7.688,410.005,7.688,14.309,409.755
7,3,409.692,7.688,409.692,7.688,21.442,409.131
7,4,409.150,7.683,409.150,7.683,28.541,408.154
7,5,408.649,7.636,408.649,7.636,35.616,407.094
7,6,408.048,7.689,408.048,7.689,42.653,405.813
7,7,407.407,9.079,407.407,9.079,49.650,404.371
7,8,406.681,9.183,406.681,9.183,56.599,402.724
7,9,405.606,9.219,405.606,9.219,63.451,400.613
7,10,404.571,9.219,404.571,9.219,70.253,398.424
7,11,403.503,9.325,403.503,9.325,76.992,396.090
7,12,402.217,9.274,402.217,9.274,83.626,393.428
7,13,400.906,9.281,400.906,9.281,90.184,390.631
7,14,399.473,9.316,399.473,9.316,96.641,387.607
7,15,397.898,9.309,397.898,9.309,102.984,384.340
7,16,396.263,9.261,396.263,9.261,109.225,380.912
7,17,394.671,9.277,394.671,9.277,115.391,377.426
7,18,393.042,9.272,393.042,9.272,121.457,373.805
7,19,391.440,9.264,391.440,9.264,127.441,370.114
7,20,389.869,9.219,389.869,9.219,133.343,366.357
7,21,388.213,9.336,388.213,9.336,139.123,362.428
7,22,386.692,9.283,386.692,9.283,144.857,358.534
7,23,385.120,9.364,385.120,9.364,150.478,354.505
7,24,383.508,9.320,383.508,9.320,155.987,350.352
7,25,381.864,9.359,381.864,9.359,161.383,346.087
7,26,380.291,9.304,380.291,9.304,166.708,341.803
7,27,378.812,9.325,378.812,9.325,171.977,337.524
7,28,377.304,9.334,377.304,9.334,177.133,333.139
7,29,375.544,8.197,375.544,8.197,182.067,328.458
7,30,373.658,7.876,373.658,7.876,186.829,323.598
7,31,371.611,7.876,371.611,7.876,191.394,318.533
7,32,369.698,7.876,369.698,7.876,195.910,313.522
7,33,367.905,8.350,367.905,8.350,200.375,308.551
7,34,366.005,8.350,366.005,8.350,204.667,303.432
7,35,364.554,8.220,364.554,8.220,209.100,298.625
7,36,363.485,8.220,363.485,8.220,213.651,294.065
7,37,361.883,8.391,361.883,8.391,217.786,289.012
7,38,360.455,8.391,360.455,8.391,221.918,284.043
7,39,359.291,8.270,359.291,8.270,226.109,279.221
7,40,358.268,10.088,358.268,10.088,230.290,274.449
7,41,355.013,10.043,355.013,10.043,232.909,267.932
7,42,350.921,10.069,350.921,10.069,234.812,260.785
7,43,345.601,10.034,345.601,10.034,235.699,252.756
7,44,338.528,10.031,338.528,10.031,235.162,243.517
7,45,328.292,10.073,328.292,10.073,232.137,232.137
10,-45,329.314,8.042,329.314,8.042,-232.860,232.860
10,-44,331.603,8.042,331.603,8.042,-230.350,238.535
10,-43,334.025,8.042,334.025,8.042,-227.805,244.291
10,-42,336.587,8.042,336.587,8.042,-225.221,250.133
10,-41,339.293,8.042,339.293,8.042,-222.596,256.068
10,-40,342.148,8.042,342.148,8.042,-219.928,262.100
10,-39,345.157,8.042,345.157,8.042,-217.214,268.237
10,-38,348.326,8.042,348.326,8.042,-214.451,274.485
10,-37,351.663,8.042,351.663,8.042,-211.636,280.850
10,-36,355.173,8.042,355.173,8.042,-208.765,287.341
10,-35,358.864,8.042,358.864,8.042,-205.836,293.964
10,-34,362.745,8.042,362.745,8.042,-202.844,300.729
10,-33,366.823,8.042,366.823,8.042,-199.786,307.644
10,-32,368.081,7.930,368.081,7.930,-195.053,312.151
10,-31,367.310,15.453,367.310,15.453,-189.179,314.846
10,-30,366.417,15.453,366.417,15.453,-183.208,317.326
10,-29,365.639,15.453,365.639,15.453,-177.265,319.795
10,-28,364.976,15.453,364.976,15.453,-171.346,322.255
10,-27,364.426,15.453,364.426,15.453,-165.446,324.706
10,-26,363.988,15.453,363.988,15.453,-159.562,327.150
10,-25,363.662,15.453,363.662,15.453,-153.690,329.590
10,-24,363.447,15.453,363.447,15.453,-147.827,332.025
10,-23,363.343,15.453,363.343,15.453,-141.969,334.459
10,-22,363.891,14.886,363.891,14.886,-136.316,337.394
10,-21,365.307,14.886,365.307,14.886,-130.914,341.043
10,-20,366.846,14.886,366.846,14.886,-125.469,344.722
10,-19,368.511,14.886,368.511,14.886,-119.975,348.434
10,-18,370.304,14.886,370.304,14.886,-114.430,352.180
10,-17,372.229,14.886,372.229,14.886,-108.829,355.965
10,-16,374.289,14.886,374.289,14.886,-103.168,359.790
10,-15,376.487,14.886,376.487,14.886,-97.442,363.658
10,-14,378.827,14.886,378.827,14.886,-91.646,367.574
10,-13,381.313,14.886,381.313,14.886,-85.777,371.540
10,-12,383.950,14.886,383.950,14.886,-79.828,375.559
10,-11,386.539,7.843,386.539,7.843,-73.755,379.437
10,-10,388.439,7.843,388.439,7.843,-67.452,382.538
10,-9,390.478,7.843,390.478,7.843,-61.084,385.671
10,-8,392.659,7.843,392.659,7.843,-54.648,388.838
10,-7,394.985,7.843,394.985,7.843,-48.137,392.041
10,-6,397.461,7.843,397.461,7.843,-41.546,395.283
10,-5,400.090,7.843,400.090,7.843,-34.870,398.568
10,-4,400.291,7.824,400.291,7.824,-27.923,399.316
10,-3,399.841,7.824,399.841,7.824,-20.926,399.293
10,-2,399.513,7.824,399.513,7.824,-13.943,399.270
10,-1,399.308,7.824,399.308,7.824,-6.969,399.247
10,0,399.224,7.824,399.224,7.824,0.000,399.224
10,1,399.262,7.824,399.262,7.824,6.968,399.202
10,2,399.422,7.824,399.422,7.824,13.940,399.179
10,3,399.704,7.824,399.704,7.824,20.919,399.156
10,4,400.108,7.824,400.108,7.824,27.910,399.133
10,5,400.002,7.662,400.002,7.662,34.862,398.480
10,6,397.467,7.662,397.467,7.662,41.547,395.290
10,7,395.084,7.662,395.084,7.662,48.149,392.139
10,8,392.848,7.662,392.848,7.662,54.674,389.025
10,9,390.755,7.662,390.755,7.662,61.128,385.945
10,10,388.803,7.662,388.803,7.662,67.515,382.896
10,11,386.987,7.662,386.987,7.662,73.841,379.877
10,12,384.622,14.879,384.622,14.879,79.967,376.217
10,13,381.937,14.879,381.937,14.879,85.917,372.148
10,14,379.404,14.879,379.404,14.879,91.786,368.134
10,15,377.018,14.879,377.018,14.879,97.579,364.171
10,16,374.776,14.879,374.776,14.879,103.302,360.257
10,17,372.672,14.879,372.672,14.879,108.959,356.388
10,18,370.705,14.879,370.705,14.879,114.554,352.561
10,19,368.870,14.879,368.870,14.879,120.092,348.773
10,20,367.164,14.879,367.164,14.879,125.578,345.022
10,21,365.585,14.879,365.585,14.879,131.014,341.303
10,22,364.130,14.879,364.130,14.879,136.406,337.616
10,23,363.555,15.328,363.555,15.328,142.052,334.654
10,24,363.656,15.328,363.656,15.328,147.912,332.216
10,25,363.867,15.328,363.867,15.328,153.777,329.776
10,26,364.191,15.328,364.191,15.328,159.651,327.332
10,27,364.625,15.328,364.625,15.328,165.536,324.884
10,28,365.173,15.328,365.173,15.328,171.438,322.428
10,29,365.833,15.328,365.833,15.328,177.359,319.965
10,30,366.608,15.328,366.608,15.328,183.304,317.492
10,31,367.498,15.328,367.498,15.328,189.276,315.007
10,32,368.141,7.904,368.141,7.904,195.085,312.201
10,33,367.009,7.902,367.009,7.902,199.888,307.800
10,34,362.949,7.902,362.949,7.902,202.959,300.899
10,35,359.086,7.902,359.086,7.902,205.964,294.146
10,36,355.412,7.902,355.412,7.902,208.906,287.534
10,37,351.918,7.902,351.918,7.902,211.790,281.054
10,38,348.597,7.902,348.597,7.902,214.618,274.698
10,39,345.443,7.902,345.443,7.902,217.394,268.459
10,40,342.448,7.902,342.448,7.902,220.122,262.331
10,41,339.608,7.902,339.608,7.902,222.803,256.305
10,42,336.916,7.902,336.916,7.902,225.441,250.377
10,43,334.367,7.902,334.367,7.902,228.038,244.541
10,44,331.957,7.902,331.957,7.902,230.597,238.790
10,45,329.682,7.902,329.682,7.902,233.120,233.120
12,-45,313.175,4.959,313.175,4.959,-221.448,221.448
12,-44,321.806,4.959,321.806,4.959,-223.546,231.488
12,-43,330.821,5.000,330.821,5.000,-225.620,241.947
12,-42,338.344,5.000,338.344,5.000,-226.396,251.439
12,-41,341.872,11.490,341.872,11.490,-224.288,258.014
12,-40,342.356,11.490,342.356,11.490,-220.062,262.260
12,-39,342.945,11.490,342.945,11.490,-215.822,266.518
12,-38,343.641,11.490,343.641,11.490,-211.567,270.793
12,-37,344.446,11.490,344.446,11.490,-207.293,275.087
12,-36,345.359,11.490,345.359,11.490,-202.997,279.401
12,-35,346.383,11.490,346.383,11.490,-198.677,283.741
12,-34,347.520,11.490,347.520,11.490,-194.331,288.107
12,-33,348.770,11.490,348.770,11.490,-189.954,292.503
12,-32,350.137,11.490,350.137,11.490,-185.544,296.933
12,-31,351.622,11.490,351.622,11.490,-181.099,301.399
12,-30,353.227,11.490,353.227,11.490,-176.614,305.904
12,-29,354.956,11.490,354.956,11.490,-172.086,310.452
12,-28,356.811,11.490,356.811,11.490,-167.513,315.046
12,-27,358.796,11.490,358.796,11.490,-162.890,319.689
12,-26,360.913,11.490,360.913,11.490,-158.214,324.386
12,-25,363.167,11.490,363.167,11.490,-153.481,329.141
12,-24,365.561,11.490,365.561,11.490,-148.687,333.956
12,-23,368.099,11.490,368.099,11.490,-143.828,338.837
12,-22,370.787,11.490,370.787,11.490,-138.899,343.788
12,-21,373.629,11.490,373.629,11.490,-133.897,348.813
12,-20,376.631,11.490,376.631,11.490,-128.815,353.917
12,-19,379.798,11.490,379.798,11.490,-123.650,359.106
12,-18,383.136,11.490,383.136,11.490,-118.396,364.384
12,-17,386.653,11.490,386.653,11.490,-113.046,369.758
12,-16,390.354,11.490,390.354,11.490,-107.596,375.232
12,-15,394.248,11.490,394.248,11.490,-102.039,380.815
12,-14,396.052,9.188,396.052,9.188,-95.814,384.287
12,-13,396.483,9.188,396.483,9.188,-89.189,386.321
12,-12,401.600,9.310,401.600,9.310,-83.497,392.824
12,-11,410.630,9.310,410.630,9.310,-78.352,403.085
12,-10,414.794,9.130,414.794,9.130,-72.028,408.492
12,-9,413.103,9.130,413.103,9.130,-64.624,408.017
12,-8,411.551,9.130,411.551,9.130,-57.277,407.546
12,-7,410.135,9.130,410.135,9.130,-49.983,407.078
12,-6,408.853,9.130,408.853,9.130,-42.737,406.613
12,-5,407.703,9.130,407.703,9.130,-35.534,406.151
12,-4,406.682,9.130,406.682,9.130,-28.369,405.692
12,-3,405.791,9.130,405.791,9.130,-21.237,405.234
12,-2,405.026,9.130,405.026,9.130,-14.135,404.779
12,-1,404.386,9.130,404.386,9.130,-7.058,404.325
12,0,403.872,9.130,403.872,9.130,0.000,403.872
12,1,403.482,9.130,403.482,9.130,7.042,403.421
12,2,403.215,9.130,403.215,9.130,14.072,402.970
12,3,403.072,9.130,403.072,9.130,21.095,402.519
12,4,403.051,9.130,403.051,9.130,28.115,402.069
12,5,403.153,9.130,403.153,9.130,35.137,401.619
12,6,403.378,9.130,403.378,9.130,42.164,401.168
12,7,403.726,9.130,403.726,9.130,49.202,400.716
12,8,404.198,9.130,404.198,9.130,56.253,400.264
12,9,404.794,9.130,404.794,9.130,63.324,399.811
12,10,404.909,9.230,404.909,9.230,70.312,398.757
12,11,403.917,9.230,403.917,9.230,77.071,396.496
12,12,403.052,9.230,403.052,9.230,83.799,394.244
12,13,402.248,9.197,402.248,9.197,90.486,391.938
12,14,398.840,11.269,398.840,11.269,96.488,386.993
12,15,394.747,11.269,394.747,11.269,102.168,381.297
12,16,390.855,11.269,390.855,11.269,107.734,375.714
12,17,387.156,11.269,387.156,11.269,113.193,370.239
12,18,383.641,11.269,383.641,11.269,118.552,364.865
12,19,380.305,11.269,380.305,11.269,123.815,359.585
12,20,377.140,11.269,377.140,11.269,128.990,354.396
12,21,374.141,11.269,374.141,11.269,134.080,349.290
12,22,371.300,11.269,371.300,11.269,139.092,344.264
12,23,368.615,11.269,368.615,11.269,144.029,339.312
12,24,366.078,11.269,366.078,11.269,148.897,334.429
12,25,363.686,11.269,363.686,11.269,153.700,329.612
12,26,361.435,11.269,361.435,11.269,158.443,324.856
12,27,359.320,11.269,359.320,11.269,163.128,320.156
12,28,357.338,11.269,357.338,11.269,167.760,315.511
12,29,355.485,11.269,355.485,11.269,172.343,310.914
12,30,353.759,11.269,353.759,11.269,176.879,306.364
12,31,352.156,11.269,352.156,11.269,181.374,301.857
12,32,350.674,11.269,350.674,11.269,185.829,297.388
12,33,349.310,11.269,349.310,11.269,190.248,292.956
12,34,348.063,11.269,348.063,11.269,194.634,288.557
12,35,346.929,11.269,346.929,11.269,198.990,284.188
12,36,345.908,11.269,345.908,11.269,203.320,279.846
12,37,344.998,11.269,344.998,11.269,207.625,275.528
12,38,344.197,11.269,344.197,11.269,211.909,271.231
12,39,343.504,11.269,343.504,11.269,216.174,266.953
12,40,342.919,11.269,342.919,11.269,220.424,262.691
12,41,342.439,11.269,342.439,11.269,224.660,258.442
12,42,342.065,11.269,342.065,11.269,228.886,254.204
12,43,333.393,9.169,333.393,9.169,227.373,243.828
12,44,325.599,9.169,325.599,9.169,226.180,234.217
12,45,320.032,9.069,320.032,9.069,226.297,226.297
14,-45,328.024,9.909,328.024,9.909,-231.948,231.948
14,-44,328.836,9.909,328.836,9.909,-228.428,236.545
14,-43,329.752,9.909,329.752,9.909,-224.890,241.166
14,-42,330.775,9.909,330.775,9.909,-221.332,245.814
14,-41,331.905,9.909,331.905,9.909,-217.750,250.492
14,-40,333.145,9.909,333.145,9.909,-214.142,255.204
14,-39,334.497,9.909,334.497,9.909,-210.506,259.953
14,-38,335.962,9.909,335.962,9.909,-206.839,264.742
14,-37,337.544,9.909,337.544,9.909,-203.139,269.575
14,-36,339.244,9.909,339.244,9.909,-199.403,274.454
14,-35,341.066,9.909,341.066,9.909,-195.628,279.385
14,-34,343.013,9.909,343.013,9.909,-191.811,284.371
14,-33,345.088,9.909,345.088,9.909,-187.948,289.415
14,-32,347.294,9.909,347.294,9.909,-184.038,294.522
14,-31,349.636,9.909,349.636,9.909,-180.076,299.697
14,-30,352.118,9.909,352.118,9.909,-176.059,304.943
14,-29,354.744,9.909,354.744,9.909,-171.984,310.266
14,-28,357.520,9.909,357.520,9.909,-167.845,315.671
14,-27,360.450,9.909,360.450,9.909,-163.641,321.163
14,-26,363.539,9.909,363.539,9.909,-159.365,326.747
14,-25,366.795,9.909,366.795,9.909,-155.014,332.430
14,-24,370.224,9.909,370.224,9.909,-150.584,338.216
14,-23,373.832,9.909,373.832,9.909,-146.068,344.114
14,-22,376.283,9.839,376.283,9.839,-140.958,348.884
14,-21,377.850,9.839,377.850,9.839,-135.409,352.753
14,-20,379.546,9.839,379.546,9.839,-129.812,356.656
14,-19,381.374,9.839,381.374,9.839,-124.163,360.596
14,-18,383.336,9.839,383.336,9.839,-118.457,364.575
14,-17,385.437,9.839,385.437,9.839,-112.691,368.596
14,-16,387.680,9.839,387.680,9.839,-106.859,372.662
14,-15,390.069,9.839,390.069,9.839,-100.957,376.778
14,-14,392.608,9.839,392.608,9.839,-94.980,380.946
14,-13,395.301,9.839,395.301,9.839,-88.923,385.170
14,-12,396.078,9.709,396.078,9.709,-82.349,387.423
14,-11,396.572,9.709,396.572,9.709,-75.670,389.286
14,-10,397.189,9.709,397.189,9.709,-68.971,391.155
14,-9,397.929,9.709,397.929,9.709,-62.250,393.030
14,-8,398.793,9.709,398.793,9.709,-55.501,394.912
14,-7,399.063,9.969,399.063,9.969,-48.634,396.088
14,-6,399.177,9.969,399.177,9.969,-41.725,396.990
14,-5,399.412,9.969,399.412,9.969,-34.811,397.893
14,-4,399.770,9.969,399.770,9.969,-27.887,398.796
14,-3,400.250,9.969,400.250,9.969,-20.947,399.702
14,-2,400.345,9.880,400.345,9.880,-13.972,400.101
14,-1,400.159,9.880,400.159,9.880,-6.984,400.098
14,0,400.095,9.880,400.095,9.880,0.000,400.095
14,1,400.152,9.880,400.152,9.880,6.984,400.092
14,2,400.332,9.880,400.332,9.880,13.971,400.088
14,3,400.246,9.909,400.246,9.909,20.947,399.698
14,4,399.809,9.909,399.809,9.909,27.889,398.836
14,5,399.495,9.909,399.495,9.909,34.818,397.975
14,6,399.303,9.909,399.303,9.909,41.739,397.116
14,7,399.233,9.909,399.233,9.909,48.654,396.257
14,8,398.959,9.939,398.959,9.939,55.524,395.076
14,9,398.109,9.939,398.109,9.939,62.278,393.208
14,10,397.384,9.939,397.384,9.939,69.005,391.347
14,11,396.783,9.939,396.783,9.939,75.710,389.493
14,12,396.303,9.939,396.303,9.939,82.396,387.643
14,13,395.491,9.738,395.491,9.738,88.966,385.355
14,14,392.828,9.738,392.828,9.738,95.034,381.159
14,15,390.319,9.738,390.319,9.738,101.022,377.019
14,16,387.958,9.738,387.958,9.738,106.936,372.929
14,17,385.743,9.738,385.743,9.738,112.780,368.888
14,18,383.670,9.738,383.670,9.738,118.560,364.891
14,19,381.734,9.738,381.734,9.738,124.280,360.936
14,20,379.932,9.738,379.932,9.738,129.945,357.020
14,21,378.263,9.738,378.263,9.738,135.557,353.139
14,22,376.722,9.738,376.722,9.738,141.122,349.290
14,23,374.258,9.940,374.258,9.940,146.234,344.506
14,24,370.648,9.940,370.648,9.940,150.756,338.604
14,25,367.218,9.940,367.218,9.940,155.193,332.813
14,26,363.961,9.940,363.961,9.940,159.550,327.126
14,27,360.870,9.940,360.870,9.940,163.832,321.538
14,28,357.939,9.940,357.939,9.940,168.042,316.042
14,29,355.163,9.940,355.163,9.940,172.186,310.633
14,30,352.536,9.940,352.536,9.940,176.268,305.305
14,31,350.054,9.940,350.054,9.940,180.291,300.055
14,32,347.711,9.940,347.711,9.940,184.259,294.876
14,33,345.504,9.940,345.504,9.940,188.175,289.764
14,34,343.429,9.940,343.429,9.940,192.043,284.716
14,35,341.482,9.940,341.482,9.940,195.866,279.726
14,36,339.660,9.940,339.660,9.940,199.647,274.791
14,37,337.960,9.940,337.960,9.940,203.389,269.907
14,38,336.378,9.940,336.378,9.940,207.095,265.070
14,39,334.913,9.940,334.913,9.940,210.768,260.276
14,40,333.562,9.940,333.562,9.940,214.409,255.523
14,41,332.322,9.940,332.322,9.940,218.023,250.807
14,42,331.192,9.940,331.192,9.940,221.611,246.124
14,43,330.170,9.940,330.170,9.940,225.175,241.471
14,44,329.254,9.940,329.254,9.940,228.719,236.846
14,45,328.443,9.940,328.443,9.940,232.244,232.244
15,-45,329.545,8.190,329.545,8.190,-233.024,233.024
15,-44,335.558,8.190,335.558,8.190,-233.098,241.380
15,-43,341.900,8.190,341.900,8.190,-233.175,250.050
15,-42,348.595,8.190,348.595,8.190,-233.255,259.056
15,-41,355.667,8.190,355.667,8.190,-233.339,268.425
15,-40,363.145,8.190,363.145,8.190,-233.425,278.186
15,-39,360.103,9.448,360.103,9.448,-226.620,279.853
15,-38,355.086,9.448,355.086,9.448,-218.613,279.812
15,-37,355.309,7.385,355.309,7.385,-213.830,283.762
15,-36,355.929,7.385,355.929,7.385,-209.210,287.953
15,-35,356.661,7.385,356.661,7.385,-204.572,292.160
15,-34,357.505,7.385,357.505,7.385,-199.914,296.385
15,-33,358.462,7.385,358.462,7.385,-195.232,300.631
15,-32,359.534,7.385,359.534,7.385,-190.524,304.902
15,-31,360.722,7.385,360.722,7.385,-185.786,309.199
15,-30,362.029,7.385,362.029,7.385,-181.015,313.527
15,-29,363.457,7.385,363.457,7.385,-176.208,317.887
15,-28,365.008,7.385,365.008,7.385,-171.361,322.283
15,-27,366.684,7.385,366.684,7.385,-166.471,326.718
15,-26,368.489,7.385,368.489,7.385,-161.535,331.195
15,-25,370.424,7.385,370.424,7.385,-156.548,335.718
15,-24,372.495,7.385,372.495,7.385,-151.507,340.291
15,-23,374.703,7.385,374.703,7.385,-146.408,344.916
15,-22,377.053,7.385,377.053,7.385,-141.246,349.597
15,-21,379.549,7.385,379.549,7.385,-136.018,354.340
15,-20,382.196,7.385,382.196,7.385,-130.719,359.146
15,-19,384.997,7.385,384.997,7.385,-125.343,364.022
15,-18,387.960,7.385,387.960,7.385,-119.886,368.972
15,-17,391.088,7.385,391.088,7.385,-114.343,373.999
15,-16,394.388,7.385,394.388,7.385,-108.708,379.110
15,-15,397.867,7.385,397.867,7.385,-102.976,384.310
15,-14,401.531,7.385,401.531,7.385,-97.139,389.604
15,-13,405.387,7.385,405.387,7.385,-91.192,394.997
15,-12,409.445,7.385,409.445,7.385,-85.128,400.498
15,-11,399.777,24.440,399.777,24.440,-76.281,392.432
15,-10,401.760,24.475,401.760,24.475,-69.765,395.656
15,-9,400.601,24.475,400.601,24.475,-62.668,395.669
15,-8,399.571,24.475,399.571,24.475,-55.610,395.682
15,-7,398.667,24.475,398.667,24.475,-48.585,395.695
15,-6,397.888,24.475,397.888,24.475,-41.591,395.708
15,-5,406.024,24.440,406.024,24.440,-35.387,404.479
15,-4,405.484,24.440,405.484,24.440,-28.285,404.497
15,-3,405.069,24.440,405.069,24.440,-21.200,404.514
15,-2,404.778,24.440,404.778,24.440,-14.127,404.531
15,-1,404.610,24.440,404.610,24.440,-7.061,404.548
15,0,404.565,24.440,404.565,24.440,0.000,404.565
15,1,404.644,24.440,404.644,24.440,7.062,404.582
15,2,404.846,24.440,404.846,24.440,14.129,404.600
15,3,405.172,24.440,405.172,24.440,21.205,404.617
15,4,405.622,24.440,405.622,24.440,28.295,404.634
15,5,406.197,24.440,406.197,24.440,35.402,404.651
15,6,398.028,24.364,398.028,24.364,41.605,395.847
15,7,398.825,24.364,398.825,24.364,48.605,395.852
15,8,399.748,24.364,399.748,24.364,55.634,395.857
15,9,400.797,24.364,400.797,24.364,62.698,395.862
15,10,401.974,24.364,401.974,24.364,69.802,395.867
15,11,399.890,24.525,399.890,24.525,76.303,392.543
15,12,408.848,6.975,408.848,6.975,85.004,399.914
15,13,404.837,6.975,404.837,6.975,91.069,394.461
15,14,401.025,6.975,401.025,6.975,97.017,389.113
15,15,397.404,6.975,397.404,6.975,102.856,383.863
15,16,393.966,6.975,393.966,6.975,108.592,378.705
15,17,390.706,6.975,390.706,6.975,114.231,373.634
15,18,387.616,6.975,387.616,6.975,119.780,368.645
15,19,384.691,6.975,384.691,6.975,125.243,363.733
15,20,381.926,6.975,381.926,6.975,130.626,358.893
15,21,379.314,6.975,379.314,6.975,135.934,354.120
15,22,376.852,6.975,376.852,6.975,141.171,349.411
15,23,374.535,6.975,374.535,6.975,146.342,344.761
15,24,372.359,6.975,372.359,6.975,151.452,340.167
15,25,370.320,6.975,370.320,6.975,156.504,335.624
15,26,368.416,6.975,368.416,6.975,161.503,331.130
15,27,366.641,6.975,366.641,6.975,166.452,326.680
15,28,364.995,6.975,364.995,6.975,171.355,322.271
15,29,363.473,6.975,363.473,6.975,176.215,317.901
15,30,362.074,6.975,362.074,6.975,181.037,313.565
15,31,360.795,6.975,360.795,6.975,185.823,309.262
15,32,359.634,6.975,359.634,6.975,190.577,304.987
15,33,358.590,6.975,358.590,6.975,195.302,300.739
15,34,357.660,6.975,357.660,6.975,200.001,296.514
15,35,356.844,6.975,356.844,6.975,204.677,292.309
15,36,356.139,6.975,356.139,6.975,209.333,288.123
15,37,355.545,6.975,355.545,6.975,213.973,283.951
15,38,355.062,6.975,355.062,6.975,218.598,279.792
15,39,354.687,6.975,354.687,6.975,223.212,275.644
15,40,359.246,6.972,359.246,6.972,230.919,275.198
15,41,359.286,8.879,359.286,8.879,235.713,271.157
15,42,352.236,8.879,352.236,8.879,235.692,261.763
15,43,345.561,8.879,345.561,8.879,235.672,252.728
15,44,339.236,8.879,339.236,8.879,235.653,244.026
15,45,333.238,8.879,333.238,8.879,235.635,235.635
17,-45,352.430,11.270,350.341,11.240,-249.205,249.205
17,-44,351.418,11.370,354.990,17.390,-244.115,252.789
17,-43,351.005,11.370,354.587,17.390,-239.385,256.709
17,-42,350.700,11.370,354.292,17.390,-234.664,260.621
17,-41,350.502,11.370,354.105,17.390,-229.950,264.527
17,-40,350.411,11.370,354.026,17.390,-225.240,268.430
17,-39,350.426,11.370,354.055,17.390,-220.530,272.332
17,-38,350.549,11.370,354.192,17.390,-215.819,276.236
17,-37,350.778,11.370,354.437,17.390,-211.104,280.144
17,-36,351.115,11.370,354.791,17.390,-206.380,284.058
17,-35,351.560,11.370,355.254,17.390,-201.646,287.981
17,-34,352.113,11.370,355.826,17.390,-196.899,291.915
17,-33,352.775,11.370,356.509,17.390,-192.135,295.862
17,-32,353.548,11.370,357.304,17.390,-187.352,299.826
17,-31,354.377,11.260,358.211,17.390,-182.517,303.760
17,-30,353.824,11.289,359.233,17.390,-176.912,306.420
17,-29,348.766,11.209,352.818,11.910,-169.085,305.038
17,-28,347.274,11.160,350.384,11.220,-163.035,306.625
17,-27,347.693,11.160,350.795,11.220,-157.849,309.797
17,-26,348.195,11.110,351.314,11.220,-152.639,312.956
17,-25,348.793,11.110,351.941,11.220,-147.406,316.114
17,-24,349.500,11.110,352.679,11.220,-142.154,319.284
17,-23,350.317,11.110,353.528,11.220,-136.880,322.468
17,-22,351.244,11.110,354.489,11.220,-131.578,325.668
17,-21,352.284,11.110,355.564,11.220,-126.247,328.886
17,-20,353.439,11.110,356.754,11.220,-120.883,332.124
17,-19,354.709,11.110,358.062,11.220,-115.482,335.384
17,-18,356.097,11.110,359.490,11.220,-110.040,338.669
17,-17,357.606,11.110,361.039,11.220,-104.554,341.980
17,-16,359.237,11.110,362.712,11.220,-99.019,345.321
17,-15,360.994,11.110,364.513,11.220,-93.432,348.693
17,-14,362.879,11.110,366.444,11.220,-87.788,352.100
17,-13,364.896,11.110,368.508,11.220,-82.084,355.544
17,-12,367.047,11.110,370.710,11.220,-76.313,359.026
17,-11,369.338,11.110,373.052,11.220,-70.473,362.552
17,-10,371.771,11.110,375.539,11.220,-64.557,366.123
17,-9,374.351,11.110,378.175,11.220,-58.561,369.742
17,-8,377.082,11.110,380.965,11.220,-52.480,373.413
17,-7,379.971,11.110,383.915,11.220,-46.307,377.139
17,-6,383.022,11.110,387.030,11.220,-40.037,380.923
17,-5,386.240,11.110,390.315,11.220,-33.663,384.771
17,-4,389.236,11.020,393.002,11.450,-27.152,388.288
17,-3,391.802,11.180,395.505,11.450,-20.505,391.265
17,-2,393.938,11.220,397.256,11.180,-13.748,393.698
17,-1,395.607,11.150,399.064,11.180,-6.904,395.547
17,0,397.224,11.039,400.681,11.000,0.000,397.224
17,1,398.484,10.980,402.002,11.000,6.954,398.423
17,2,399.518,10.980,403.098,11.380,13.943,399.274
17,3,400.182,11.079,403.794,11.380,20.944,399.633
17,4,400.670,11.150,404.251,11.340,27.949,399.694
17,5,400.809,11.020,404.796,11.340,34.933,399.284
17,6,400.714,11.110,404.183,11.370,41.886,398.519
17,7,400.497,11.120,403.557,11.370,48.808,397.512
17,8,399.939,11.310,403.055,11.370,55.661,396.047
17,9,398.936,11.220,402.114,11.210,62.407,394.024
17,10,397.786,11.200,400.937,11.210,69.075,391.742
17,11,396.397,11.180,399.685,10.980,75.636,389.114
17,12,394.709,11.200,397.867,10.980,82.065,386.084
17,13,392.861,11.200,396.186,10.980,88.374,382.792
17,14,390.580,11.148,394.192,11.370,94.490,378.978
17,15,387.949,11.148,391.677,11.370,100.409,374.730
17,16,385.157,11.080,388.923,11.380,106.164,370.237
17,17,382.003,11.020,385.894,11.380,111.687,365.311
17,18,378.698,11.160,382.662,11.398,117.024,360.163
17,19,375.508,11.160,379.425,11.398,122.254,355.050
17,20,372.485,11.160,376.356,11.470,127.397,350.021
17,21,369.621,11.160,373.450,11.470,132.460,345.071
17,22,366.911,11.160,370.700,11.470,137.447,340.194
17,23,364.352,11.160,368.102,11.470,142.364,335.388
17,24,361.937,11.160,365.651,11.470,147.213,330.646
17,25,359.663,11.160,363.342,11.470,152.000,325.965
17,26,357.525,11.160,361.171,11.470,156.729,321.342
17,27,355.521,11.160,359.135,11.470,161.403,316.771
17,28,353.646,11.160,357.230,11.470,166.027,312.251
17,29,351.897,11.160,355.453,11.470,170.603,307.776
17,30,350.272,11.160,353.800,11.470,175.136,303.344
17,31,349.599,11.299,352.483,12.799,180.057,299.665
17,32,351.092,11.400,355.277,12.900,186.050,297.743
17,33,357.824,11.150,367.308,20.659,194.885,300.097
17,34,361.726,11.039,365.748,20.609,202.275,299.885
17,35,360.701,11.020,363.936,20.609,206.889,295.469
17,36,359.041,11.090,362.251,20.609,211.039,290.470
17,37,357.475,11.090,360.692,20.609,215.134,285.493
17,38,356.032,11.090,359.254,20.609,219.195,280.557
17,39,354.707,11.090,357.937,20.609,223.224,275.659
17,40,353.500,11.090,356.738,20.609,227.225,270.796
17,41,352.407,11.090,355.655,20.609,231.200,265.965
17,42,351.429,11.090,354.685,20.609,235.152,261.162
17,43,350.562,11.090,353.829,20.609,239.083,256.385
17,44,349.805,11.090,353.084,20.609,242.995,251.629
17,45,349.159,11.090,352.450,20.609,246.892,246.892
19,-45,347.181,12.390,347.181,12.390,-245.494,245.494
19,-44,347.777,12.390,347.777,12.390,-241.586,250.170
19,-43,348.481,12.390,348.481,12.390,-237.663,254.863
19,-42,349.294,12.390,349.294,12.390,-233.724,259.576
19,-41,350.080,7.649,350.080,7.649,-229.673,264.208
19,-40,351.104,7.649,351.104,7.649,-225.686,268.962
19,-39,352.243,7.649,352.243,7.649,-221.674,273.744
19,-38,353.497,7.649,353.497,7.649,-217.634,278.559
19,-37,354.868,7.649,354.868,7.649,-213.565,283.410
19,-36,356.359,7.649,356.359,7.649,-209.462,288.300
19,-35,357.972,7.649,357.972,7.649,-205.324,293.233
19,-34,359.710,7.649,359.710,7.649,-201.147,298.213
19,-33,361.575,7.649,361.575,7.649,-196.928,303.242
19,-32,363.571,7.649,363.571,7.649,-192.663,308.326
19,-31,365.701,7.649,365.701,7.649,-188.350,313.467
19,-30,367.970,7.649,367.970,7.649,-183.985,318.671
19,-29,370.380,7.649,370.380,7.649,-179.564,323.941
19,-28,372.936,7.649,372.936,7.649,-175.083,329.283
19,-27,375.643,7.649,375.643,7.649,-170.538,334.700
19,-26,378.505,7.649,378.505,7.649,-165.926,340.198
19,-25,381.529,7.649,381.529,7.649,-161.241,345.783
19,-24,384.719,7.649,384.719,7.649,-156.480,351.459
19,-23,388.083,7.649,388.083,7.649,-151.636,357.232
19,-22,391.626,7.649,391.626,7.649,-146.706,363.110
19,-21,395.356,7.649,395.356,7.649,-141.683,369.097
19,-20,399.281,7.649,399.281,7.649,-136.562,375.201
19,-19,403.408,7.649,403.408,7.649,-131.337,381.430
19,-18,407.747,7.649,407.747,7.649,-126.001,387.791
19,-17,412.308,7.649,412.308,7.649,-120.547,394.292
19,-16,417.100,7.649,417.100,7.649,-114.968,400.942
19,-15,419.368,7.569,419.368,7.569,-108.540,405.078
19,-14,418.155,7.569,418.155,7.569,-101.161,405.734
19,-13,417.076,7.569,417.076,7.569,-93.822,406.386
19,-12,416.129,7.569,416.129,7.569,-86.518,407.036
19,-11,415.312,7.569,415.312,7.569,-79.245,407.682
19,-10,414.625,7.569,414.625,7.569,-71.999,408.326
19,-9,414.066,7.569,414.066,7.569,-64.774,408.968
19,-8,413.634,7.569,413.634,7.569,-57.567,409.609
19,-7,413.329,7.569,413.329,7.569,-50.372,410.249
19,-6,413.151,7.569,413.151,7.569,-43.186,410.887
19,-5,413.098,7.569,413.098,7.569,-36.004,411.526
19,-4,413.171,7.569,413.171,7.569,-28.821,412.164
19,-3,413.370,7.569,413.370,7.569,-21.634,412.803
19,-2,413.695,7.569,413.695,7.569,-14.438,413.443
19,-1,414.147,7.569,414.147,7.569,-7.228,414.084
19,0,414.726,7.569,414.726,7.569,0.000,414.726
19,1,415.434,7.569,415.434,7.569,7.250,415.371
19,2,416.271,7.569,416.271,7.569,14.528,416.017
19,3,417.239,7.569,417.239,7.569,21.837,416.667
19,4,418.339,7.569,418.339,7.569,29.182,417.320
19,5,419.573,7.569,419.573,7.569,36.568,417.977
19,6,420.983,16.029,420.983,16.029,44.005,418.677
19,7,422.535,16.029,422.535,16.029,51.494,419.385
19,8,420.790,16.079,420.790,16.079,58.563,416.695
19,9,416.347,16.079,416.347,16.079,65.131,411.221
19,10,412.121,16.079,412.121,16.079,71.564,405.860
19,11,408.103,16.079,408.103,16.079,77.870,400.605
19,12,404.285,16.079,404.285,16.079,84.055,395.450
19,13,400.658,16.079,400.658,16.079,90.128,390.389
19,14,397.216,16.079,397.216,16.079,96.095,385.417
19,15,393.951,16.079,393.951,16.079,101.962,380.527
19,16,390.857,16.079,390.857,16.079,107.735,375.716
19,17,387.930,16.079,387.930,16.079,113.420,370.979
19,18,385.162,16.079,385.162,16.079,119.021,366.310
19,19,382.549,16.079,382.549,16.079,124.546,361.707
19,20,380.086,16.079,380.086,16.079,129.997,357.164
19,21,377.769,16.079,377.769,16.079,135.380,352.678
19,22,375.594,16.079,375.594,16.079,140.700,348.244
19,23,373.556,16.079,373.556,16.079,145.960,343.861
19,24,371.654,16.079,371.654,16.079,151.165,339.523
19,25,369.883,16.079,369.883,16.079,156.319,335.228
19,26,368.240,16.079,368.240,16.079,161.426,330.972
19,27,366.723,16.079,366.723,16.079,166.489,326.753
19,28,365.329,16.079,365.329,16.079,171.512,322.567
19,29,364.057,16.079,364.057,16.079,176.498,318.411
19,30,362.903,16.079,362.903,16.079,181.451,314.283
19,31,361.866,16.079,361.866,16.079,186.375,310.180
19,32,360.945,16.079,360.945,16.079,191.272,306.099
19,33,360.138,16.079,360.138,16.079,196.145,302.038
19,34,359.444,16.079,359.444,16.079,200.999,297.993
19,35,358.862,16.079,358.862,16.079,205.835,293.963
19,36,358.391,16.079,358.391,16.079,210.657,289.944
19,37,358.030,16.079,358.030,16.079,215.468,285.935
19,38,357.778,16.079,357.778,16.079,220.270,281.933
19,39,357.635,16.079,357.635,16.079,225.067,277.935
19,40,357.602,16.079,357.602,16.079,229.862,273.939
19,41,357.678,16.079,357.678,16.079,234.658,269.943
19,42,357.862,16.079,357.862,16.079,239.457,265.943
19,43,358.156,16.079,358.156,16.079,244.262,261.939
19,44,355.427,15.969,355.427,15.969,246.900,255.673
19,45,350.483,15.969,350.483,15.969,247.829,247.829
22,-45,326.446,3.743,326.446,3.743,-230.832,230.832
22,-44,332.554,3.740,332.554,3.740,-231.012,239.220
22,-43,337.954,3.733,337.954,3.733,-230.484,247.164
22,-42,342.813,3.717,342.813,3.717,-229.387,254.760
22,-41,347.294,3.647,347.294,3.647,-227.845,262.106
22,-40,351.272,3.675,351.272,3.675,-225.793,269.090
22,-39,354.909,3.671,354.909,3.671,-223.352,275.816
22,-38,357.212,7.861,357.212,7.861,-219.922,281.487
22,-37,359.061,7.861,359.061,7.861,-216.088,286.759
22,-36,361.039,7.861,361.039,7.861,-212.213,292.087
22,-35,361.198,7.817,361.198,7.817,-207.174,295.876
22,-34,361.010,7.817,361.010,7.817,-201.875,299.291
22,-33,360.933,7.817,360.933,7.817,-196.578,302.704
22,-32,360.966,7.817,360.966,7.817,-191.283,306.117
22,-31,361.109,7.817,361.109,7.817,-185.985,309.531
22,-30,361.362,7.817,361.362,7.817,-180.681,312.949
22,-29,361.726,7.817,361.726,7.817,-175.368,316.373
22,-28,362.201,7.817,362.201,7.817,-170.043,319.805
22,-27,362.788,7.817,362.788,7.817,-164.702,323.247
22,-26,363.488,7.817,363.488,7.817,-159.343,326.701
22,-25,364.301,7.817,364.301,7.817,-153.960,330.169
22,-24,365.230,7.817,365.230,7.817,-148.552,333.654
22,-23,366.275,7.817,366.275,7.817,-143.115,337.158
22,-22,367.439,7.817,367.439,7.817,-137.645,340.683
22,-21,368.614,7.698,368.614,7.698,-132.099,344.131
22,-20,369.551,7.698,369.551,7.698,-126.394,347.264
22,-19,370.605,7.698,370.605,7.698,-120.657,350.414
22,-18,371.779,7.698,371.779,7.698,-114.886,353.583
22,-17,373.001,7.694,373.001,7.694,-109.055,356.703
22,-16,374.227,7.694,374.227,7.694,-103.151,359.731
22,-15,375.396,7.672,375.396,7.672,-97.160,362.605
22,-14,376.605,7.672,376.605,7.672,-91.109,365.418
22,-13,377.937,7.672,377.937,7.672,-85.017,368.250
22,-12,379.341,7.691,379.341,7.691,-78.869,371.052
22,-11,380.557,7.691,380.557,7.691,-72.614,373.565
22,-10,381.896,7.691,381.896,7.691,-66.316,376.095
22,-9,383.117,7.705,383.117,7.705,-59.933,378.400
22,-8,384.447,7.705,384.447,7.705,-53.505,380.705
22,-7,385.803,7.667,385.803,7.667,-47.018,382.927
22,-6,387.276,7.667,387.276,7.667,-40.481,385.154
22,-5,388.878,7.667,388.878,7.667,-33.893,387.399
22,-4,390.812,7.660,390.812,7.660,-27.262,389.860
22,-3,392.453,7.840,392.453,7.840,-20.539,391.916
22,-2,393.721,7.840,393.721,7.840,-13.741,393.481
22,-1,394.363,7.747,394.363,7.747,-6.883,394.303
22,0,394.828,7.747,394.828,7.747,0.000,394.828
22,1,394.261,7.731,394.261,7.731,6.881,394.201
22,2,393.545,7.750,393.545,7.750,13.735,393.306
22,3,392.218,7.763,392.218,7.763,20.527,391.681
22,4,390.815,7.809,390.815,7.809,27.262,389.863
22,5,389.252,7.721,389.252,7.721,33.926,387.771
22,6,387.780,7.721,387.780,7.721,40.534,385.655
22,7,386.387,7.681,386.387,7.681,47.089,383.507
22,8,384.734,7.681,384.734,7.681,53.545,380.990
22,9,383.237,7.689,383.237,7.689,59.951,378.519
22,10,381.823,7.706,381.823,7.706,66.303,376.022
22,11,380.494,7.706,380.494,7.706,72.602,373.504
22,12,379.290,7.706,379.290,7.706,78.859,371.002
22,13,377.941,7.705,377.941,7.705,85.018,368.254
22,14,376.534,7.705,376.534,7.705,91.092,365.350
22,15,375.252,7.705,375.252,7.705,97.122,362.466
22,16,374.092,7.705,374.092,7.705,103.114,359.600
22,17,373.053,7.705,373.053,7.705,109.070,356.752
22,18,371.764,7.731,371.764,7.731,114.881,353.568
22,19,370.504,7.731,370.504,7.731,120.624,350.319
22,20,369.366,7.731,369.366,7.731,126.331,347.090
22,21,368.346,7.731,368.346,7.731,132.003,343.880
22,22,367.443,7.731,367.443,7.731,137.647,340.687
22,23,366.471,7.720,366.471,7.720,143.192,337.338
22,24,365.709,7.801,365.709,7.801,148.747,334.092
22,25,364.773,7.801,364.773,7.801,154.160,330.597
22,26,363.953,7.801,363.953,7.801,159.546,327.119
22,27,363.247,7.801,363.247,7.801,164.911,323.655
22,28,362.653,7.801,362.653,7.801,170.255,320.204
22,29,362.172,7.801,362.172,7.801,175.585,316.763
22,30,361.803,7.801,361.803,7.801,180.901,313.330
22,31,361.544,7.801,361.544,7.801,186.209,309.903
22,32,361.395,7.801,361.395,7.801,191.510,306.480
22,33,361.357,7.801,361.357,7.801,196.809,303.059
22,34,361.377,7.662,361.377,7.662,202.079,299.595
22,35,361.437,7.662,361.437,7.662,207.312,296.072
22,36,361.281,7.803,361.281,7.803,212.356,292.283
22,37,359.269,7.846,359.269,7.846,216.213,286.925
22,38,357.298,7.846,357.298,7.846,219.975,281.555
22,39,354.904,3.697,354.904,3.697,223.348,275.812
22,40,351.340,3.689,351.340,3.689,225.837,269.142
22,41,347.311,3.689,347.311,3.689,227.857,262.119
22,42,342.913,3.689,342.913,3.689,229.454,254.834
22,43,338.032,3.709,338.032,3.709,230.537,247.221
22,44,332.588,3.733,332.588,3.733,231.035,239.244
22,45,326.420,3.702,326.420,3.702,230.814,230.814
31,-45,324.176,5.258,324.176,5.258,-229.227,229.227
31,-44,329.108,5.829,329.108,5.829,-228.618,236.741
31,-43,334.192,5.820,334.192,5.820,-227.918,244.413
31,-42,339.625,5.820,339.625,5.820,-227.253,252.390
31,-41,345.344,5.820,345.344,5.820,-226.566,260.635
31,-40,351.325,5.869,351.325,5.869,-225.828,269.131
31,-39,357.586,5.309,357.586,5.309,-225.036,277.896
31,-38,364.352,5.309,364.352,5.309,-224.317,287.113
31,-37,371.361,5.809,371.361,5.809,-223.490,296.582
31,-36,371.783,5.869,371.783,5.869,-218.529,300.779
31,-35,372.296,5.869,372.296,5.869,-213.540,304.967
31,-34,372.923,5.869,372.923,5.869,-208.536,309.167
31,-33,373.667,5.869,373.667,5.869,-203.514,313.384
31,-32,374.484,5.860,374.484,5.860,-198.446,317.581
31,-31,375.483,5.849,375.483,5.849,-193.388,321.852
31,-30,376.565,5.849,376.565,5.849,-188.282,326.115
31,-29,377.768,5.849,377.768,5.849,-183.145,330.403
31,-28,379.094,5.849,379.094,5.849,-177.974,334.720
31,-27,380.547,5.849,380.547,5.849,-172.765,339.069
31,-26,382.147,5.809,382.147,5.809,-167.522,343.471
31,-25,383.819,5.370,383.819,5.370,-162.209,347.858
31,-24,385.696,5.370,385.696,5.370,-156.877,352.351
31,-23,387.710,5.370,387.710,5.370,-151.490,356.889
31,-22,389.864,5.370,389.864,5.370,-146.046,361.476
31,-21,392.163,5.370,392.163,5.370,-140.539,366.116
31,-20,394.558,5.259,394.558,5.259,-134.947,370.763
31,-19,397.153,5.259,397.153,5.259,-129.300,375.515
31,-18,399.905,5.259,399.905,5.259,-123.577,380.332
31,-17,402.818,5.259,402.818,5.259,-117.773,385.217
31,-16,405.900,5.259,405.900,5.259,-111.881,390.176
31,-15,409.344,5.810,409.344,5.810,-105.946,395.396
31,-14,395.511,10.020,395.511,10.020,-95.683,383.763
31,-13,394.340,9.990,394.340,9.990,-88.707,384.233
31,-12,393.926,9.990,393.926,9.990,-81.902,385.318
31,-11,393.633,9.990,393.633,9.990,-75.109,386.401
31,-10,393.460,9.990,393.460,9.990,-68.324,387.483
31,-9,393.407,9.990,393.407,9.990,-61.542,388.564
31,-8,393.474,9.990,393.474,9.990,-54.761,389.645
31,-7,393.661,9.990,393.661,9.990,-47.975,390.727
31,-6,393.969,9.990,393.969,9.990,-41.181,391.810
31,-5,394.396,9.990,394.396,9.990,-34.374,392.896
31,-4,394.946,9.990,394.946,9.990,-27.550,393.984
31,-3,395.617,9.990,395.617,9.990,-20.705,395.075
31,-2,396.412,9.990,396.412,9.990,-13.835,396.170
31,-1,397.331,9.990,397.331,9.990,-6.934,397.271
31,0,398.376,9.990,398.376,9.990,0.000,398.376
31,1,396.927,9.640,396.927,9.640,6.927,396.867
31,2,395.603,9.640,395.603,9.640,13.806,395.362
31,3,394.407,9.640,394.407,9.640,20.642,393.867
31,4,393.338,9.640,393.338,9.640,27.438,392.380
31,5,392.394,9.640,392.394,9.640,34.199,390.901
31,6,391.811,9.920,391.811,9.920,40.955,389.665
31,7,391.136,9.920,391.136,9.920,47.668,388.221
31,8,390.582,9.920,390.582,9.920,54.359,386.781
31,9,390.149,9.920,390.149,9.920,61.033,385.345
31,10,389.835,9.920,389.835,9.920,67.694,383.912
31,11,388.499,10.130,388.499,10.130,74.129,381.361
31,12,386.639,10.130,386.639,10.130,80.387,378.190
31,13,384.914,10.130,384.914,10.130,86.587,375.049
31,14,383.321,10.130,383.321,10.130,92.734,371.934
31,15,381.856,10.130,381.856,10.130,98.832,368.845
31,16,380.518,10.130,380.518,10.130,104.885,365.777
31,17,379.305,10.130,379.305,10.130,110.898,362.731
31,18,378.214,10.130,378.214,10.130,116.874,359.703
31,19,377.244,10.130,377.244,10.130,122.818,356.691
31,20,376.393,10.130,376.393,10.130,128.734,353.694
31,21,375.660,10.130,375.660,10.130,134.625,350.709
31,22,375.044,10.130,375.044,10.130,140.494,347.735
31,23,372.600,22.689,372.600,22.689,145.586,342.980
31,24,368.552,22.149,368.552,22.149,149.904,336.689
31,25,364.704,22.149,364.704,22.149,154.131,330.534
31,26,361.044,22.149,361.044,22.149,158.271,324.504
31,27,357.647,22.098,357.647,22.098,162.368,318.666
31,28,354.265,22.647,354.265,22.647,166.317,312.797
31,29,351.145,22.129,351.145,22.129,170.238,307.118
31,30,348.167,22.129,348.167,22.129,174.083,301.521
31,31,345.343,22.129,345.343,22.129,177.865,296.016
31,32,342.668,22.129,342.668,22.129,181.586,290.599
31,33,340.137,22.129,340.137,22.129,185.252,285.263
31,34,337.745,22.129,337.745,22.129,188.865,280.003
31,35,335.488,22.129,335.488,22.129,192.428,274.816
31,36,333.362,22.129,333.362,22.129,195.946,269.696
31,37,331.364,22.129,331.364,22.129,199.420,264.639
31,38,329.488,22.129,329.488,22.129,202.853,259.640
31,39,327.733,22.129,327.733,22.129,206.249,254.697
31,40,326.096,22.129,326.096,22.129,209.610,249.804
31,41,324.573,22.129,324.573,22.129,212.939,244.958
31,42,323.162,22.129,323.162,22.129,216.238,240.156
31,43,321.861,22.129,321.861,22.129,219.509,235.394
31,44,320.668,22.129,320.668,22.129,222.755,230.669
31,45,319.581,22.129,319.581,22.129,225.978,225.978
32,-45,341.100,6.930,341.100,6.930,-241.194,241.194
32,-44,344.486,6.930,344.486,6.930,-239.300,247.802
32,-43,348.046,6.930,348.046,6.930,-237.367,254.545
32,-42,351.790,6.930,351.790,6.930,-235.393,261.431
32,-41,355.724,6.930,355.724,6.930,-233.376,268.468
32,-40,358.117,6.649,358.117,6.649,-230.193,274.333
32,-39,358.748,6.649,358.748,6.649,-225.768,278.800
32,-38,359.491,6.649,359.491,6.649,-221.325,283.283
32,-37,360.348,6.649,360.348,6.649,-216.863,287.787
32,-36,361.319,6.649,361.319,6.649,-212.378,292.313
32,-35,362.405,6.649,362.405,6.649,-207.867,296.865
32,-34,363.610,6.649,363.610,6.649,-203.328,301.446
32,-33,364.934,6.649,364.934,6.649,-198.757,306.059
32,-32,366.379,6.649,366.379,6.649,-194.152,310.707
32,-31,367.949,6.649,367.949,6.649,-189.508,315.394
32,-30,369.645,6.649,369.645,6.649,-184.823,320.122
32,-29,370.624,6.719,370.624,6.719,-179.682,324.155
32,-28,370.091,6.719,370.091,6.719,-173.747,326.771
32,-27,369.673,6.719,369.673,6.719,-167.828,329.381
32,-26,369.368,6.719,369.368,6.719,-161.920,331.986
32,-25,369.177,6.719,369.177,6.719,-156.021,334.588
32,-24,369.097,6.719,369.097,6.719,-150.125,337.187
32,-23,369.130,6.719,369.130,6.719,-144.231,339.786
32,-22,369.276,6.719,369.276,6.719,-138.333,342.387
32,-21,369.534,6.719,369.534,6.719,-132.429,344.990
32,-20,370.391,6.410,370.391,6.410,-126.681,348.054
32,-19,371.240,6.460,371.240,6.460,-120.864,351.014
32,-18,371.872,6.460,371.872,6.460,-114.915,353.671
32,-17,372.619,6.460,372.619,6.460,-108.943,356.337
32,-16,373.484,6.460,373.484,6.460,-102.946,359.015
32,-15,374.466,6.460,374.466,6.460,-96.919,361.707
32,-14,375.569,6.460,375.569,6.460,-90.858,364.413
32,-13,376.793,6.460,376.793,6.460,-84.760,367.136
32,-12,378.141,6.460,378.141,6.460,-78.620,369.878
32,-11,381.673,6.829,381.673,6.829,-72.827,374.661
32,-10,388.022,6.829,388.022,6.829,-67.379,382.127
32,-9,394.708,6.829,394.708,6.829,-61.746,389.848
32,-8,399.574,6.899,399.574,6.899,-55.610,395.685
32,-7,399.597,6.840,399.597,6.840,-48.699,396.618
32,-6,399.608,6.859,399.608,6.859,-41.770,397.419
32,-5,399.538,6.739,399.538,6.739,-34.822,398.018
32,-4,399.556,6.710,399.556,6.710,-27.872,398.583
32,-3,399.559,6.780,399.559,6.780,-20.911,399.011
32,-2,399.507,6.719,399.507,6.719,-13.943,399.263
32,-1,399.523,6.630,399.523,6.630,-6.973,399.462
32,0,399.524,6.689,399.524,6.689,0.000,399.524
32,1,399.570,6.730,399.570,6.730,6.973,399.509
32,2,399.607,6.760,399.607,6.760,13.946,399.363
32,3,399.554,6.730,399.554,6.730,20.911,399.007
32,4,399.535,6.730,399.535,6.730,27.870,398.561
32,5,399.489,6.739,399.489,6.739,34.818,397.969
32,6,399.574,6.699,399.574,6.699,41.767,397.385
32,7,399.567,6.850,399.567,6.850,48.695,396.589
32,8,399.624,6.809,399.624,6.809,55.617,395.735
32,9,394.729,6.749,394.729,6.749,61.749,389.869
32,10,387.920,6.749,387.920,6.749,67.362,382.026
32,11,384.817,6.720,384.817,6.720,73.427,377.747
32,12,383.335,6.720,383.335,6.720,79.700,374.958
32,13,381.980,6.720,381.980,6.720,85.927,372.190
32,14,380.750,6.720,380.750,6.720,92.112,369.440
32,15,379.643,6.720,379.643,6.720,98.259,366.707
32,16,378.658,6.720,378.658,6.720,104.372,363.990
32,17,377.793,6.720,377.793,6.720,110.456,361.285
32,18,377.046,6.720,377.046,6.720,116.514,358.592
32,19,376.417,6.720,376.417,6.720,122.549,355.909
32,20,375.903,6.720,375.903,6.720,128.567,353.234
32,21,375.506,6.720,375.506,6.720,134.569,350.565
32,22,375.174,6.779,375.174,6.779,140.543,347.855
32,23,373.021,6.779,373.021,6.779,145.751,343.368
32,24,371.005,6.779,371.005,6.779,150.901,338.930
32,25,369.123,6.779,369.123,6.779,155.998,334.539
32,26,367.371,6.779,367.371,6.779,161.045,330.190
32,27,365.746,6.779,365.746,6.779,166.045,325.882
32,28,364.246,6.779,364.246,6.779,171.003,321.610
32,29,355.405,6.710,355.405,6.710,172.304,310.845
32,30,350.928,6.600,350.928,6.600,175.464,303.912
32,31,349.753,6.600,349.753,6.600,180.136,299.797
32,32,348.692,6.600,348.692,6.600,184.779,295.708
32,33,347.743,6.600,347.743,6.600,189.395,291.642
32,34,346.905,6.600,346.905,6.600,193.987,287.597
32,35,346.176,6.600,346.176,6.600,198.558,283.571
32,36,345.555,6.600,345.555,6.600,203.112,279.560
32,37,345.041,6.600,345.041,6.600,207.651,275.562
32,38,344.634,6.600,344.634,6.600,212.178,271.575
32,39,344.332,6.600,344.332,6.600,216.695,267.596
32,40,344.135,6.600,344.135,6.600,221.206,263.623
32,41,344.044,6.600,344.044,6.600,225.713,259.653
32,42,344.057,6.600,344.057,6.600,230.219,255.684
32,43,344.175,6.600,344.175,6.600,234.727,251.714
32,44,344.398,6.600,344.398,6.600,239.239,247.739
32,45,344.649,7.079,344.649,7.079,243.704,243.704
680,-45,331.144,7.564,331.144,7.564,-234.154,234.154
680,-44,336.966,7.581,336.966,7.581,-234.076,242.393
680,-43,337.383,7.581,337.383,7.581,-230.094,246.746
680,-42,337.904,7.581,337.904,7.581,-226.102,251.111
680,-41,338.529,7.581,338.529,7.581,-222.095,255.491
680,-40,339.261,7.581,339.261,7.581,-218.073,259.889
680,-39,340.100,7.581,340.100,7.581,-214.032,264.307
680,-38,341.047,7.581,341.047,7.581,-209.970,268.749
680,-37,342.104,7.581,342.104,7.581,-205.883,273.216
680,-36,343.272,7.581,343.272,7.581,-201.770,277.713
680,-35,344.554,7.581,344.554,7.581,-197.628,282.242
680,-34,345.951,7.581,345.951,7.581,-193.453,286.806
680,-33,347.466,7.581,347.466,7.581,-189.243,291.409
680,-32,349.100,7.581,349.100,7.581,-184.995,296.054
680,-31,350.858,7.581,350.858,7.581,-180.705,300.744
680,-30,352.741,7.581,352.741,7.581,-176.371,305.483
680,-29,354.754,7.581,354.754,7.581,-171.988,310.275
680,-28,356.899,7.581,356.899,7.581,-167.554,315.123
680,-27,359.180,7.581,359.180,7.581,-163.064,320.031
680,-26,361.601,7.581,361.601,7.581,-158.515,325.005
680,-25,364.167,7.581,364.167,7.581,-153.903,330.047
680,-24,366.882,7.581,366.882,7.581,-149.224,335.163
680,-23,369.751,7.581,369.751,7.581,-144.473,340.357
680,-22,372.780,7.581,372.780,7.581,-139.646,345.635
680,-21,374.552,7.602,374.552,7.602,-134.227,349.674
680,-20,375.966,7.602,375.966,7.602,-128.588,353.292
680,-19,377.505,7.602,377.505,7.602,-122.904,356.938
680,-18,379.174,7.602,379.174,7.602,-117.171,360.616
680,-17,380.974,7.602,380.974,7.602,-111.386,364.327
680,-16,382.908,7.602,382.908,7.602,-105.544,368.075
680,-15,384.980,7.602,384.980,7.602,-99.640,371.862
680,-14,386.366,7.550,386.366,7.550,-93.470,374.890
680,-13,387.304,7.550,387.304,7.550,-87.125,377.378
680,-12,388.365,7.550,388.365,7.550,-80.746,379.879
680,-11,389.551,7.550,389.551,7.550,-74.330,382.394
680,-10,390.864,7.550,390.864,7.550,-67.873,384.926
680,-9,392.305,7.550,392.305,7.550,-61.370,387.475
680,-8,393.878,7.550,393.878,7.550,-54.817,390.045
680,-7,395.584,7.550,395.584,7.550,-48.210,392.635
680,-6,397.427,7.550,397.427,7.550,-41.542,395.249
680,-5,399.409,7.550,399.409,7.550,-34.811,397.889
680,-4,401.533,7.550,401.533,7.550,-28.010,400.555
680,-3,403.805,7.550,403.805,7.550,-21.133,403.251
680,-2,405.665,7.551,405.665,7.551,-14.158,405.418
680,-1,403.223,7.551,403.223,7.551,-7.037,403.161
680,0,400.930,7.551,400.930,7.551,0.000,400.930
680,1,398.785,7.551,398.785,7.551,6.960,398.724
680,2,396.783,7.551,396.783,7.551,13.848,396.541
680,3,394.920,7.551,394.920,7.551,20.669,394.379
680,4,393.194,7.551,393.194,7.551,27.428,392.236
680,5,391.602,7.551,391.602,7.551,34.130,390.111
680,6,390.140,7.551,390.140,7.551,40.781,388.003
680,7,388.808,7.551,388.808,7.551,47.384,385.910
680,8,387.603,7.551,387.603,7.551,53.944,383.831
680,9,386.522,7.551,386.522,7.551,60.465,381.763
680,10,385.565,7.551,385.565,7.551,66.953,379.707
680,11,384.729,7.551,384.729,7.551,73.410,377.660
680,12,384.013,7.551,384.013,7.551,79.841,375.621
680,13,383.417,7.551,383.417,7.551,86.250,373.590
680,14,382.939,7.551,382.939,7.551,92.641,371.564
680,15,382.578,7.551,382.578,7.551,99.019,369.542
680,16,382.335,7.551,382.335,7.551,105.386,367.524
680,17,382.208,7.551,382.208,7.551,111.747,365.507
680,18,382.198,7.551,382.198,7.551,118.106,363.492
680,19,382.304,7.551,382.304,7.551,124.466,361.476
680,20,379.850,7.464,379.850,7.464,129.916,356.942
680,21,375.958,7.464,375.958,7.464,134.731,350.987
680,22,372.257,7.464,372.257,7.464,139.450,345.150
680,23,368.739,7.464,368.739,7.464,144.078,339.426
680,24,365.398,7.464,365.398,7.464,148.621,333.808
680,25,362.226,7.464,362.226,7.464,153.083,328.288
680,26,359.217,7.464,359.217,7.464,157.470,322.862
680,27,356.365,7.464,356.365,7.464,161.786,317.524
680,28,353.665,7.464,353.665,7.464,166.036,312.268
680,29,351.112,7.464,351.112,7.464,170.223,307.090
680,30,348.701,7.464,348.701,7.464,174.351,301.984
680,31,346.428,7.464,346.428,7.464,178.423,296.947
680,32,344.288,7.464,344.288,7.464,182.445,291.973
680,33,342.278,7.464,342.278,7.464,186.418,287.059
680,34,340.395,7.464,340.395,7.464,190.346,282.200
680,35,338.634,7.464,338.634,7.464,194.233,277.393
680,36,336.994,7.464,336.994,7.464,198.080,272.634
680,37,335.472,7.464,335.472,7.464,201.892,267.920
680,38,334.064,7.464,334.064,7.464,205.671,263.246
680,39,332.770,7.464,332.770,7.464,209.419,258.611
680,40,331.585,7.464,331.585,7.464,213.139,254.009
680,41,330.510,7.464,330.510,7.464,216.834,249.439
680,42,329.541,7.464,329.541,7.464,220.506,244.897
680,43,328.679,7.464,328.679,7.464,224.158,240.380
680,44,327.920,7.464,327.920,7.464,227.792,235.886
680,45,327.264,7.464,327.264,7.464,231.410,231.410
2392,-45,314.691,18.177,314.691,18.177,-222.520,222.520
2392,-44,314.736,18.177,314.736,18.177,-218.634,226.402
2392,-43,314.878,18.177,314.878,18.177,-214.746,230.287
2392,-42,315.116,18.177,315.116,18.177,-210.853,234.176
2392,-41,315.450,18.177,315.450,18.177,-206.954,238.073
2392,-40,315.881,18.177,315.881,18.177,-203.044,241.979
2392,-39,316.410,18.177,316.410,18.177,-199.123,245.897
2392,-38,317.037,18.177,317.037,18.177,-195.188,249.829
2392,-37,317.764,18.177,317.764,18.177,-191.235,253.778
2392,-36,318.592,18.177,318.592,18.177,-187.264,257.746
2392,-35,319.521,18.177,319.521,18.177,-183.270,261.737
2392,-34,320.554,18.177,320.554,18.177,-179.252,265.751
2392,-33,321.692,18.177,321.692,18.177,-175.206,269.794
2392,-32,322.937,18.177,322.937,18.177,-171.130,273.866
2392,-31,324.290,18.177,324.290,18.177,-167.022,277.971
2392,-30,325.569,18.187,325.569,18.187,-162.785,281.951
2392,-29,326.505,18.187,326.505,18.187,-158.293,285.567
2392,-28,327.545,18.187,327.545,18.187,-153.773,289.205
2392,-27,328.693,18.187,328.693,18.187,-149.224,292.868
2392,-26,329.950,18.187,329.950,18.187,-144.640,296.557
2392,-25,331.317,18.187,331.317,18.187,-140.021,300.275
2392,-24,332.798,18.187,332.798,18.187,-135.361,304.026
2392,-23,356.241,27.887,356.241,27.887,-139.194,327.921
2392,-22,358.714,24.021,358.714,24.021,-134.377,332.594
2392,-21,360.666,24.021,360.666,24.021,-129.251,336.711
2392,-20,362.751,24.021,362.751,24.021,-124.068,340.874
2392,-19,364.971,24.021,364.971,24.021,-118.823,345.087
2392,-18,367.332,24.021,367.332,24.021,-113.512,349.353
2392,-17,369.837,24.021,369.837,24.021,-108.130,353.676
2392,-16,372.490,24.021,372.490,24.021,-102.672,358.060
2392,-15,375.297,24.021,375.297,24.021,-97.134,362.509
2392,-14,378.262,24.021,378.262,24.021,-91.510,367.026
2392,-13,381.392,24.021,381.392,24.021,-85.794,371.617
2392,-12,384.692,24.021,384.692,24.021,-79.982,376.286
2392,-11,387.738,9.211,387.738,9.211,-73.984,380.614
2392,-10,391.403,9.211,391.403,9.211,-67.966,385.456
2392,-9,395.259,9.211,395.259,9.211,-61.832,390.393
2392,-8,399.315,9.211,399.315,9.211,-55.574,395.429
2392,-7,403.579,9.211,403.579,9.211,-49.184,400.570
2392,-6,408.060,9.211,408.060,9.211,-42.654,405.825
2392,-5,410.937,9.261,410.937,9.261,-35.816,409.373
2392,-4,410.370,9.261,410.370,9.261,-28.626,409.371
2392,-3,409.930,9.261,409.930,9.261,-21.454,409.368
2392,-2,409.615,9.261,409.615,9.261,-14.295,409.365
2392,-1,409.425,9.261,409.425,9.261,-7.145,409.362
2392,0,409.360,9.261,409.360,9.261,0.000,409.360
2392,1,409.419,9.261,409.419,9.261,7.145,409.357
2392,2,409.604,9.261,409.604,9.261,14.295,409.354
2392,3,409.913,9.261,409.913,9.261,21.453,409.351
2392,4,410.348,9.261,410.348,9.261,28.624,409.349
2392,5,410.909,9.261,410.909,9.261,35.813,409.346
2392,6,411.598,9.261,411.598,9.261,43.024,409.343
2392,7,412.414,9.261,412.414,9.261,50.261,409.340
2392,8,413.360,9.261,413.360,9.261,57.529,409.337
2392,9,414.437,9.261,414.437,9.261,64.832,409.335
2392,10,415.646,9.261,415.646,9.261,72.176,409.332
2392,11,410.862,9.474,410.862,9.474,78.396,403.313
2392,12,404.780,9.474,404.780,9.474,84.158,395.935
2392,13,402.271,9.451,402.271,9.451,90.491,391.961
2392,14,396.865,9.451,396.865,9.451,96.010,385.077
2392,15,391.720,9.451,391.720,9.451,101.385,378.373
2392,16,386.823,9.451,386.823,9.451,106.623,371.838
2392,17,382.162,9.451,382.162,9.451,111.733,365.464
2392,18,377.726,9.451,377.726,9.451,116.724,359.239
2392,19,373.504,9.451,373.504,9.451,121.601,353.155
2392,20,369.486,9.451,369.486,9.451,126.372,347.204
2392,21,369.446,9.470,369.446,9.470,132.397,344.907
2392,22,370.651,6.375,370.651,6.375,138.848,343.661
2392,23,367.128,6.375,367.128,6.375,143.448,337.943
2392,24,363.782,6.375,363.782,6.375,147.963,332.331
2392,25,360.605,6.375,360.605,6.375,152.398,326.819
2392,26,357.591,6.375,357.591,6.375,156.757,321.400
2392,27,354.734,6.375,354.734,6.375,161.046,316.070
2392,28,352.028,6.375,352.028,6.375,165.267,310.823
2392,29,349.470,6.375,349.470,6.375,169.426,305.653
2392,30,347.053,6.375,347.053,6.375,173.526,300.557
2392,31,344.774,6.375,344.774,6.375,177.572,295.529
2392,32,342.628,6.375,342.628,6.375,181.565,290.565
2392,33,340.611,6.375,340.611,6.375,185.510,285.661
2392,34,338.721,6.375,338.721,6.375,189.411,280.813
2392,35,336.954,6.375,336.954,6.375,193.269,276.017
2392,36,335.307,6.375,335.307,6.375,197.089,271.269
2392,37,333.777,6.375,333.777,6.375,200.872,266.566
2392,38,332.362,6.375,332.362,6.375,204.622,261.905
2392,39,331.059,6.375,331.059,6.375,208.342,257.281
2392,40,329.866,6.375,329.866,6.375,212.034,252.692
2392,41,328.782,6.375,328.782,6.375,215.700,248.135
2392,42,327.804,6.375,327.804,6.375,219.344,243.606
2392,43,326.932,6.375,326.932,6.375,222.967,239.103
2392,44,326.163,6.375,326.163,6.375,226.572,234.622
2392,45,325.496,6.375,325.496,6.375,230.161,230.161
2394,-45,341.257,7.983,341.257,7.983,-241.305,241.305
2394,-44,341.516,6.369,341.516,6.369,-237.237,245.666
2394,-43,341.648,6.369,341.648,6.369,-233.003,249.866
2394,-42,341.884,6.369,341.884,6.369,-228.765,254.069
2394,-41,342.225,6.369,342.225,6.369,-224.520,258.280
2394,-40,342.671,6.369,342.671,6.369,-220.265,262.501
2394,-39,343.223,6.369,343.223,6.369,-215.997,266.734
2394,-38,343.881,6.369,343.881,6.369,-211.714,270.982
2394,-37,344.647,6.369,344.647,6.369,-207.414,275.248
2394,-36,345.523,6.369,345.523,6.369,-203.093,279.534
2394,-35,346.508,6.369,346.508,6.369,-198.749,283.843
2394,-34,347.605,6.369,347.605,6.369,-194.378,288.178
2394,-33,348.816,6.369,348.816,6.369,-189.979,292.542
2394,-32,350.142,6.369,350.142,6.369,-185.547,296.937
2394,-31,351.586,6.369,351.586,6.369,-181.080,301.368
2394,-30,353.150,6.369,353.150,6.369,-176.575,305.837
2394,-29,354.836,6.369,354.836,6.369,-172.028,310.347
2394,-28,356.648,6.369,356.648,6.369,-167.436,314.901
2394,-27,358.588,6.369,358.588,6.369,-162.796,319.504
2394,-26,360.660,6.369,360.660,6.369,-158.103,324.159
2394,-25,362.867,6.369,362.867,6.369,-153.354,328.869
2394,-24,365.213,6.369,365.213,6.369,-148.546,333.639
2394,-23,367.703,6.369,367.703,6.369,-143.673,338.472
2394,-22,370.340,6.369,370.340,6.369,-138.732,343.373
2394,-21,373.130,6.369,373.130,6.369,-133.718,348.347
2394,-20,376.077,6.369,376.077,6.369,-128.626,353.397
2394,-19,379.188,6.369,379.188,6.369,-123.452,358.529
2394,-18,382.468,6.369,382.468,6.369,-118.189,363.749
2394,-17,385.925,6.369,385.925,6.369,-112.833,369.062
2394,-16,389.564,6.369,389.564,6.369,-107.378,374.472
2394,-15,393.393,6.369,393.393,6.369,-101.818,379.988
2394,-14,397.420,6.369,397.420,6.369,-96.145,385.615
2394,-13,401.655,6.369,401.655,6.369,-90.353,391.360
2394,-12,406.105,6.369,406.105,6.369,-84.434,397.231
2394,-11,410.782,6.369,410.782,6.369,-78.381,403.235
2394,-10,415.696,6.369,415.696,6.369,-72.185,409.381
2394,-9,420.859,6.369,420.859,6.369,-65.837,415.677
2394,-8,424.699,8.059,424.699,8.059,-59.107,420.566
2394,-7,423.873,8.059,423.873,8.059,-51.657,420.714
2394,-6,423.179,8.059,423.179,8.059,-44.234,420.861
2394,-5,422.616,8.059,422.616,8.059,-36.833,421.008
2394,-4,422.183,8.059,422.183,8.059,-29.450,421.155
2394,-3,421.879,8.059,421.879,8.059,-22.079,421.301
2394,-2,421.704,8.059,421.704,8.059,-14.717,421.447
2394,-1,421.657,8.059,421.657,8.059,-7.359,421.593
2394,0,421.739,8.059,421.739,8.059,0.000,421.739
2394,1,421.950,8.059,421.950,8.059,7.364,421.886
2394,2,422.289,8.059,422.289,8.059,14.738,422.032
2394,3,422.758,8.059,422.758,8.059,22.125,422.179
2394,4,423.357,8.059,423.357,8.059,29.532,422.326
2394,5,424.087,8.059,424.087,8.059,36.962,422.473
2394,6,424.949,8.059,424.949,8.059,44.419,422.622
2394,7,425.945,8.059,425.945,8.059,51.910,422.770
2394,8,427.076,8.059,427.076,8.059,59.437,422.920
2394,9,428.344,8.059,428.344,8.059,67.008,423.070
2394,10,427.295,13.102,427.295,13.102,74.199,420.803
2394,11,422.225,13.102,422.225,13.102,80.564,414.468
2394,12,417.401,13.102,417.401,13.102,86.782,408.279
2394,13,412.809,13.102,412.809,13.102,92.862,402.229
2394,14,408.441,13.102,408.441,13.102,98.811,396.308
2394,15,404.285,13.102,404.285,13.102,104.637,390.510
2394,16,400.335,13.102,400.335,13.102,110.347,384.826
2394,17,396.580,13.102,396.580,13.102,115.949,379.251
2394,18,393.014,13.102,393.014,13.102,121.448,373.778
2394,19,389.629,13.102,389.629,13.102,126.851,368.401
2394,20,386.418,13.102,386.418,13.102,132.163,363.114
2394,21,379.064,11.997,379.064,11.997,135.845,353.887
2394,22,369.173,10.256,369.173,10.256,138.295,342.292
2394,23,362.916,8.116,362.916,8.116,141.802,334.066
2394,24,360.448,8.116,360.448,8.116,146.607,329.286
2394,25,358.122,8.116,358.122,8.116,151.349,324.569
2394,26,355.934,8.116,355.934,8.116,156.031,319.911
2394,27,353.879,8.116,353.879,8.116,160.658,315.309
2394,28,351.955,8.116,351.955,8.116,165.233,310.758
2394,29,350.157,8.116,350.157,8.116,169.760,306.254
2394,30,348.483,8.116,348.483,8.116,174.242,301.796
2394,31,346.931,8.116,346.931,8.116,178.683,297.378
2394,32,345.497,8.116,345.497,8.116,183.085,292.998
2394,33,344.179,8.116,344.179,8.116,187.453,288.653
2394,34,342.975,8.116,342.975,8.116,191.789,284.339
2394,35,341.884,8.116,341.884,8.116,196.096,280.055
2394,36,340.903,8.116,340.903,8.116,200.378,275.796
2394,37,340.030,8.116,340.030,8.116,204.635,271.560
2394,38,339.266,8.116,339.266,8.116,208.873,267.345
2394,39,338.607,8.116,338.607,8.116,213.092,263.147
2394,40,338.054,8.116,338.054,8.116,217.297,258.965
2394,41,337.606,8.116,337.606,8.116,221.489,254.794
2394,42,337.261,8.116,337.261,8.116,225.672,250.634
2394,43,336.800,5.803,336.800,5.803,229.697,246.320
2394,44,333.787,9.115,333.787,9.115,231.868,240.106
2394,45,328.249,9.115,328.249,9.115,232.107,232.107
2395,-45,339.772,8.114,339.772,8.114,-240.255,240.255
2395,-44,342.388,8.114,342.388,8.114,-237.843,246.293
2395,-43,345.150,8.114,345.150,8.114,-235.392,252.427
2395,-42,347.978,10.999,347.978,10.999,-232.843,258.598
2395,-41,349.013,8.134,349.013,8.134,-228.973,263.404
2395,-40,348.950,8.134,348.950,8.134,-224.301,267.311
2395,-39,348.993,8.134,348.993,8.134,-219.628,271.218
2395,-38,349.147,8.118,349.147,8.118,-214.956,275.131
2395,-37,349.546,8.118,349.546,8.118,-210.362,279.160
2395,-36,350.051,8.053,350.051,8.053,-205.755,283.197
2395,-35,350.539,8.053,350.539,8.053,-201.061,287.144
2395,-34,351.135,8.053,351.135,8.053,-196.352,291.104
2395,-33,351.841,8.053,351.841,8.053,-191.626,295.078
2395,-32,352.657,8.053,352.657,8.053,-186.880,299.070
2395,-31,353.585,8.053,353.585,8.053,-182.110,303.081
2395,-30,354.626,8.053,354.626,8.053,-177.313,307.115
2395,-29,355.782,8.053,355.782,8.053,-172.487,311.174
2395,-28,357.055,8.053,357.055,8.053,-167.627,315.261
2395,-27,358.446,8.053,358.446,8.053,-162.731,319.378
2395,-26,359.959,8.053,359.959,8.053,-157.796,323.529
2395,-25,361.595,8.053,361.595,8.053,-152.816,327.716
2395,-24,363.356,8.053,363.356,8.053,-147.790,331.943
2395,-23,365.248,8.053,365.248,8.053,-142.714,336.212
2395,-22,367.271,8.053,367.271,8.053,-137.582,340.528
2395,-21,369.430,8.053,369.430,8.053,-132.392,344.892
2395,-20,371.728,8.053,371.728,8.053,-127.139,349.310
2395,-19,374.170,8.053,374.170,8.053,-121.818,353.785
2395,-18,376.760,8.053,376.760,8.053,-116.425,358.320
2395,-17,379.502,8.053,379.502,8.053,-110.956,362.919
2395,-16,382.402,8.053,382.402,8.053,-105.404,367.588
2395,-15,385.464,8.053,385.464,8.053,-99.765,372.330
2395,-14,388.696,8.053,388.696,8.053,-94.034,377.150
2395,-13,392.102,8.053,392.102,8.053,-88.204,382.053
2395,-12,395.691,8.053,395.691,8.053,-82.269,387.044
2395,-11,398.687,6.785,398.687,6.785,-76.073,391.362
2395,-10,397.381,6.785,397.381,6.785,-69.004,391.343
2395,-9,396.203,6.785,396.203,6.785,-61.980,391.325
2395,-8,395.153,6.785,395.153,6.785,-54.995,391.307
2395,-7,394.227,6.785,394.227,6.785,-48.044,391.289
2395,-6,393.426,6.785,393.426,6.785,-41.124,391.271
2395,-5,392.748,6.785,392.748,6.785,-34.230,391.253
2395,-4,392.191,6.785,392.191,6.785,-27.358,391.235
2395,-3,391.754,6.785,391.754,6.785,-20.503,391.217
2395,-2,391.438,6.785,391.438,6.785,-13.661,391.200
2395,-1,391.241,6.785,391.241,6.785,-6.828,391.182
2395,0,391.164,6.785,391.164,6.785,0.000,391.164
2395,1,391.206,6.785,391.206,6.785,6.827,391.146
2395,2,391.367,6.785,391.367,6.785,13.659,391.129
2395,3,391.647,6.785,391.647,6.785,20.497,391.111
2395,4,392.048,6.785,392.048,6.785,27.348,391.093
2395,5,392.569,6.785,392.569,6.785,34.215,391.075
2395,6,393.211,6.785,393.211,6.785,41.102,391.057
2395,7,393.976,6.785,393.976,6.785,48.014,391.039
2395,8,394.864,6.785,394.864,6.785,54.954,391.021
2395,9,395.877,6.785,395.877,6.785,61.929,391.003
2395,10,397.016,6.785,397.016,6.785,68.941,390.985
2395,11,398.284,6.785,398.284,6.785,75.996,390.966
2395,12,399.682,6.785,399.682,6.785,83.099,390.948
2395,13,401.212,6.785,401.212,6.785,90.253,390.929
2395,14,402.878,6.785,402.878,6.785,97.465,390.911
2395,15,404.681,6.785,404.681,6.785,104.739,390.892
2395,16,406.625,6.785,406.625,6.785,112.081,390.873
2395,17,408.712,6.785,408.712,6.785,119.496,390.853
2395,18,410.947,6.785,410.947,6.785,126.990,390.834
2395,19,413.333,6.785,413.333,6.785,134.568,390.814
2395,20,410.375,20.593,410.375,20.593,140.357,385.627
2395,21,402.271,20.593,402.271,20.593,144.161,375.552
2395,22,394.599,20.593,394.599,20.593,147.819,365.866
2395,23,387.329,20.593,387.329,20.593,151.342,356.538
2395,24,380.436,20.593,380.436,20.593,154.737,347.546
2395,25,373.897,20.593,373.897,20.593,158.016,338.865
2395,26,367.688,20.593,367.688,20.593,161.184,330.476
2395,27,363.336,20.608,363.336,20.608,164.951,323.734
2395,28,363.680,20.608,363.680,20.608,170.738,321.111
2395,29,363.638,24.062,363.638,24.062,176.295,318.045
2395,30,358.363,23.911,358.363,23.911,179.181,310.351
2395,31,353.351,23.911,353.351,23.911,181.989,302.881
2395,32,348.582,23.911,348.582,23.911,184.720,295.614
2395,33,344.043,23.911,344.043,23.911,187.379,288.539
2395,34,339.724,23.911,339.724,23.911,189.971,281.644
2395,35,335.612,23.911,335.612,23.911,192.499,274.917
2395,36,331.698,23.911,331.698,23.911,194.967,268.350
2395,37,327.974,23.911,327.974,23.911,197.380,261.932
2395,38,324.430,23.911,324.430,23.911,199.739,255.654
2395,39,321.058,23.911,321.058,23.911,202.049,249.509
2395,40,317.852,23.911,317.852,23.911,204.311,243.489
2395,41,314.804,23.911,314.804,23.911,206.530,237.586
2395,42,311.908,23.911,311.908,23.911,208.707,231.793
2395,43,309.158,23.911,309.158,23.911,210.845,226.104
2395,44,306.549,23.911,306.549,23.911,212.947,220.513
2395,45,304.075,23.911,304.075,23.911,215.014,215.014
2602,-45,328.018,11.340,328.018,11.340,-231.944,231.944
2602,-44,331.851,11.340,331.851,11.340,-230.523,238.714
2602,-43,335.878,11.340,335.878,11.340,-229.068,245.646
2602,-42,337.820,11.340,337.820,11.340,-226.046,251.049
2602,-41,338.056,11.340,338.056,11.340,-221.785,255.134
2602,-40,338.083,11.295,338.083,11.295,-217.315,258.987
2602,-39,338.573,11.295,338.573,11.295,-213.071,263.121
2602,-38,339.169,11.295,339.169,11.295,-208.813,267.269
2602,-37,339.870,11.295,339.870,11.295,-204.539,271.432
2602,-36,340.678,11.295,340.678,11.295,-200.246,275.614
2602,-35,341.594,11.295,341.594,11.295,-195.930,279.818
2602,-34,342.620,11.295,342.620,11.295,-191.591,284.045
2602,-33,343.757,11.295,343.757,11.295,-187.224,288.299
2602,-32,345.007,11.295,345.007,11.295,-182.826,292.583
2602,-31,346.372,11.295,346.372,11.295,-178.395,296.899
2602,-30,347.855,11.295,347.855,11.295,-173.927,301.251
2602,-29,349.457,11.295,349.457,11.295,-169.420,305.642
2602,-28,351.181,11.295,351.181,11.295,-164.869,310.074
2602,-27,353.030,11.295,353.030,11.295,-160.272,314.552
2602,-26,355.008,11.295,355.008,11.295,-155.625,319.079
2602,-25,357.118,11.295,357.118,11.295,-150.924,323.658
2602,-24,359.362,11.295,359.362,11.295,-146.166,328.294
2602,-23,361.746,11.295,361.746,11.295,-141.346,332.989
2602,-22,364.274,11.295,364.274,11.295,-136.459,337.749
2602,-21,366.950,11.295,366.950,11.295,-131.503,342.577
2602,-20,369.778,11.295,369.778,11.295,-126.472,347.478
2602,-19,372.765,11.295,372.765,11.295,-121.361,352.457
2602,-18,375.917,11.295,375.917,11.295,-116.165,357.518
2602,-17,379.238,11.295,379.238,11.295,-110.879,362.667
2602,-16,383.078,11.400,383.078,11.400,-105.591,368.238
2602,-15,386.812,11.400,386.812,11.400,-100.114,373.631
2602,-14,387.219,11.416,387.219,11.416,-93.677,375.717
2602,-13,387.637,7.582,387.637,7.582,-87.199,377.702
2602,-12,388.142,7.582,388.142,7.582,-80.699,379.660
2602,-11,388.768,7.582,388.768,7.582,-74.180,381.625
2602,-10,389.514,7.582,389.514,7.582,-67.638,383.596
2602,-9,390.382,7.582,390.382,7.582,-61.069,385.576
2602,-8,391.373,7.582,391.373,7.582,-54.469,387.565
2602,-7,392.243,7.672,392.243,7.672,-47.802,389.320
2602,-6,393.495,7.672,393.495,7.672,-41.131,391.339
2602,-5,394.875,7.672,394.875,7.672,-34.416,393.373
2602,-4,396.387,7.672,396.387,7.672,-27.651,395.421
2602,-3,398.031,7.672,398.031,7.672,-20.831,397.486
2602,-2,399.812,7.672,399.812,7.672,-13.953,399.568
2602,-1,401.731,7.672,401.731,7.672,-7.011,401.670
2602,0,403.793,7.672,403.793,7.672,0.000,403.793
2602,1,402.107,7.613,402.107,7.613,7.018,402.046
2602,2,399.709,7.613,399.709,7.613,13.950,399.466
2602,3,397.460,7.613,397.460,7.613,20.801,396.915
2602,4,395.355,7.613,395.355,7.613,27.579,394.392
2602,5,393.392,7.613,393.392,7.613,34.286,391.895
2602,6,391.557,7.638,391.557,7.638,40.929,389.412
2602,7,389.495,7.638,389.495,7.638,47.468,386.592
2602,8,387.573,7.638,387.573,7.638,53.940,383.801
2602,9,385.786,7.638,385.786,7.638,60.350,381.037
2602,10,384.110,7.601,384.110,7.601,66.700,378.275
2602,11,381.909,7.601,381.909,7.601,72.872,374.892
2602,12,379.848,7.601,379.848,7.601,78.975,371.548
2602,13,377.924,7.601,377.924,7.601,85.014,368.238
2602,14,376.134,7.601,376.134,7.601,90.995,364.961
2602,15,374.473,7.601,374.473,7.601,96.921,361.713
2602,16,372.375,7.573,372.375,7.573,102.640,357.950
2602,17,370.343,7.573,370.343,7.573,108.278,354.161
2602,18,368.445,7.573,368.445,7.573,113.856,350.412
2602,19,366.677,7.573,366.677,7.573,119.378,346.700
2602,20,365.037,7.585,365.037,7.585,124.850,343.022
2602,21,363.253,7.582,363.253,7.582,130.178,339.125
2602,22,361.226,7.582,361.226,7.582,135.318,334.923
2602,23,359.332,7.582,359.332,7.582,140.402,330.766
2602,24,357.565,7.582,357.565,7.582,145.435,326.652
2602,25,355.923,7.582,355.923,7.582,150.420,322.576
2602,26,354.404,7.582,354.404,7.582,155.361,318.537
2602,27,352.477,7.627,352.477,7.627,160.021,314.060
2602,28,350.598,7.627,350.598,7.627,164.596,309.559
2602,29,348.844,7.627,348.844,7.627,169.123,305.106
2602,30,347.212,7.627,347.212,7.627,173.606,300.695
2602,31,345.701,7.627,345.701,7.627,178.049,296.324
2602,32,344.215,7.661,344.215,7.661,182.406,291.911
2602,33,342.354,7.661,342.354,7.661,186.459,287.122
2602,34,340.616,7.661,340.616,7.661,190.470,282.384
2602,35,338.999,7.661,338.999,7.661,194.442,277.691
2602,36,337.499,7.661,337.499,7.661,198.377,273.042
2602,37,336.114,7.661,336.114,7.661,202.279,268.433
2602,38,334.842,7.661,334.842,7.661,206.150,263.859
2602,39,333.681,7.661,333.681,7.661,209.993,259.319
2602,40,332.630,7.661,332.630,7.661,213.810,254.809
2602,41,331.685,7.661,331.685,7.661,217.605,250.326
2602,42,330.547,7.564,330.547,7.564,221.179,245.645
2602,43,328.256,7.564,328.256,7.564,223.870,240.072
2602,44,326.095,7.564,326.095,7.564,226.525,234.573
2602,45,324.632,11.724,324.632,11.724,229.549,229.549
2680,-45,334.553,4.610,334.553,4.610,-236.565,236.565
2680,-44,333.193,4.610,333.193,4.610,-231.455,239.679
2680,-43,334.210,4.530,334.210,4.530,-227.930,244.425
2680,-42,335.849,4.530,335.849,4.530,-224.727,249.584
2680,-41,337.608,4.530,337.608,4.530,-221.491,254.796
2680,-40,339.489,4.530,339.489,4.530,-218.219,260.064
2680,-39,340.598,6.320,340.598,6.320,-214.345,264.694
2680,-38,341.266,6.320,341.266,6.320,-210.105,268.922
2680,-37,342.042,6.320,342.042,6.320,-205.846,273.167
2680,-36,342.926,6.320,342.926,6.320,-201.567,277.433
2680,-35,343.919,6.320,343.919,6.320,-197.264,281.722
2680,-34,345.023,6.320,345.023,6.320,-192.935,286.037
2680,-33,346.241,6.320,346.241,6.320,-188.576,290.382
2680,-32,347.573,6.320,347.573,6.320,-184.186,294.759
2680,-31,349.022,6.320,349.022,6.320,-179.760,299.170
2680,-30,350.591,6.320,350.591,6.320,-175.295,303.621
2680,-29,352.282,6.320,352.282,6.320,-170.789,308.112
2680,-28,354.097,6.320,354.097,6.320,-166.238,312.649
2680,-27,356.040,6.320,356.040,6.320,-161.639,317.234
2680,-26,358.114,6.320,358.114,6.320,-156.987,321.871
2680,-25,360.323,6.320,360.323,6.320,-152.279,326.564
2680,-24,362.671,6.320,362.671,6.320,-147.512,331.317
2680,-23,365.162,6.320,365.162,6.320,-142.680,336.133
2680,-22,367.799,6.320,367.799,6.320,-137.780,341.017
2680,-21,370.589,6.320,370.589,6.320,-132.807,345.975
2680,-20,373.536,6.320,373.536,6.320,-127.757,351.009
2680,-19,376.646,6.320,376.646,6.320,-122.624,356.126
2680,-18,379.925,6.320,379.925,6.320,-117.403,361.330
2680,-17,383.379,6.320,383.379,6.320,-112.089,366.627
2680,-16,386.521,6.400,386.521,6.400,-106.539,371.547
2680,-15,388.306,6.370,388.306,6.370,-100.501,375.075
2680,-14,390.188,7.480,390.188,7.480,-94.395,378.597
2680,-13,392.178,7.480,392.178,7.480,-88.221,382.126
2680,-12,394.309,7.480,394.309,7.480,-81.981,385.692
2680,-11,396.584,7.480,396.584,7.480,-75.672,389.298
2680,-10,395.450,7.450,395.450,7.450,-68.669,389.442
2680,-9,394.315,7.450,394.315,7.450,-61.684,389.460
2680,-8,393.306,7.450,393.306,7.450,-54.738,389.478
2680,-7,392.421,7.450,392.421,7.450,-47.824,389.496
2680,-6,391.659,7.450,391.659,7.450,-40.940,389.513
2680,-5,391.019,7.450,391.019,7.450,-34.080,389.531
2680,-4,390.500,7.450,390.500,7.450,-27.240,389.549
2680,-3,393.662,7.409,393.662,7.409,-20.603,393.122
2680,-2,395.753,7.360,395.753,7.360,-13.812,395.512
2680,-1,395.593,7.360,395.593,7.360,-6.904,395.532
2680,0,395.553,7.360,395.553,7.360,0.000,395.553
2680,1,395.633,7.360,395.633,7.360,6.905,395.573
2680,2,395.834,7.360,395.834,7.360,13.814,395.593
2680,3,396.156,7.360,396.156,7.360,20.733,395.613
2680,4,396.599,7.360,396.599,7.360,27.665,395.633
2680,5,397.165,7.360,397.165,7.360,34.615,395.653
2680,6,397.853,7.360,397.853,7.360,41.587,395.674
2680,7,398.775,5.530,398.775,5.530,48.598,395.803
2680,8,398.904,5.560,398.904,5.560,55.517,395.022
2680,9,398.608,5.560,398.608,5.560,62.356,393.700
2680,10,398.433,5.560,398.433,5.560,69.187,392.380
2680,11,398.380,5.560,398.380,5.560,76.014,391.061
2680,12,398.448,5.560,398.448,5.560,82.842,389.741
2680,13,398.638,5.560,398.638,5.560,89.674,388.421
2680,14,396.692,5.580,396.692,5.580,95.968,384.908
2680,15,394.553,5.580,394.553,5.580,102.118,381.109
2680,16,392.556,5.580,392.556,5.580,108.203,377.349
2680,17,390.698,5.580,390.698,5.580,114.229,373.626
2680,18,388.380,7.538,388.380,7.538,120.016,369.371
2680,19,385.032,7.538,385.032,7.538,125.354,364.055
2680,20,381.857,7.538,381.857,7.538,130.603,358.829
2680,21,378.849,7.538,378.849,7.538,135.767,353.686
2680,22,376.001,7.538,376.001,7.538,140.852,348.622
2680,23,373.308,7.538,373.308,7.538,145.863,343.632
2680,24,370.766,7.538,370.766,7.538,150.804,338.711
2680,25,368.369,7.538,368.369,7.538,155.680,333.856
2680,26,366.115,7.538,366.115,7.538,160.494,329.062
2680,27,363.997,7.538,363.997,7.538,165.251,324.324
2680,28,362.014,7.538,362.014,7.538,169.955,319.640
2680,29,360.162,7.538,360.162,7.538,174.610,315.005
2680,30,358.437,7.538,358.437,7.538,179.218,310.415
2680,31,356.836,7.538,356.836,7.538,183.784,305.868
2680,32,355.358,7.538,355.358,7.538,188.311,301.360
2680,33,353.999,7.538,353.999,7.538,192.802,296.888
2680,34,352.757,7.538,352.757,7.538,197.259,292.449
2680,35,351.631,7.538,351.631,7.538,201.687,288.040
2680,36,350.619,7.538,350.619,7.538,206.089,283.657
2680,37,349.719,7.538,349.719,7.538,210.466,279.298
2680,38,348.929,7.538,348.929,7.538,214.822,274.960
2680,39,343.691,7.579,343.691,7.579,216.292,267.098
2680,40,336.474,7.579,336.474,7.579,216.281,257.754
2680,41,329.652,7.579,329.652,7.579,216.271,248.792
2680,42,323.198,7.579,323.198,7.579,216.262,240.183
2680,43,322.392,7.139,322.392,7.139,219.871,235.783
2680,44,322.327,7.139,322.327,7.139,223.907,231.863
2680,45,321.601,9.100,321.601,9.100,227.406,227.406
2681,-45,328.602,9.790,328.602,9.790,-232.357,232.357
2681,-44,333.595,9.790,333.595,9.790,-231.735,239.968
2681,-43,333.920,9.760,333.920,9.760,-227.733,244.213
2681,-42,334.174,9.760,334.174,9.760,-223.606,248.340
2681,-41,334.531,9.760,334.531,9.760,-219.472,252.474
2681,-40,334.990,9.760,334.990,9.760,-215.328,256.617
2681,-39,335.554,9.760,335.554,9.760,-211.171,260.774
2681,-38,336.221,9.760,336.221,9.760,-206.998,264.946
2681,-37,336.994,9.760,336.994,9.760,-202.808,269.136
2681,-36,337.874,9.760,337.874,9.760,-198.598,273.346
2681,-35,338.862,9.760,338.862,9.760,-194.364,277.580
2681,-34,339.960,9.760,339.960,9.760,-190.103,281.840
2681,-33,341.169,9.760,341.169,9.760,-185.814,286.129
2681,-32,342.492,9.760,342.492,9.760,-181.493,290.449
2681,-31,343.930,9.760,343.930,9.760,-177.137,294.805
2681,-30,345.485,9.760,345.485,9.760,-172.743,299.199
2681,-29,347.161,9.760,347.161,9.760,-168.307,303.634
2681,-28,348.961,9.760,348.961,9.760,-163.827,308.114
2681,-27,350.886,9.760,350.886,9.760,-159.299,312.642
2681,-26,352.941,9.760,352.941,9.760,-154.719,317.221
2681,-25,355.129,9.760,355.129,9.760,-150.084,321.856
2681,-24,357.454,9.760,357.454,9.760,-145.389,326.550
2681,-23,359.919,9.760,359.919,9.760,-140.632,331.308
2681,-22,362.531,9.760,362.531,9.760,-135.806,336.133
2681,-21,365.292,9.760,365.292,9.760,-130.909,341.030
2681,-20,368.209,9.760,368.209,9.760,-125.935,346.003
2681,-19,371.287,9.760,371.287,9.760,-120.879,351.059
2681,-18,374.532,9.760,374.532,9.760,-115.737,356.201
2681,-17,377.950,9.760,377.950,9.760,-110.502,361.435
2681,-16,381.548,9.760,381.548,9.760,-105.169,366.768
2681,-15,385.334,9.760,385.334,9.760,-99.732,372.204
2681,-14,386.114,12.089,386.114,12.089,-93.410,374.645
2681,-13,384.482,12.089,384.482,12.089,-86.490,374.627
2681,-12,382.979,12.089,382.979,12.089,-79.626,374.610
2681,-11,381.946,12.169,381.946,12.169,-72.879,374.929
2681,-10,386.521,12.169,386.521,12.169,-67.119,380.649
2681,-9,391.327,12.169,391.327,12.169,-61.217,386.509
2681,-8,396.376,12.169,396.376,12.169,-55.165,392.519
2681,-7,401.664,16.999,401.664,16.999,-48.951,398.670
2681,-6,407.178,16.999,407.178,16.999,-42.562,404.947
2681,-5,408.122,5.649,408.122,5.649,-35.570,406.569
2681,-4,406.543,5.649,406.543,5.649,-28.359,405.553
2681,-3,405.100,5.649,405.100,5.649,-21.201,404.545
2681,-2,403.789,5.649,403.789,5.649,-14.092,403.543
2681,-1,402.609,5.649,402.609,5.649,-7.027,402.548
2681,0,401.558,5.649,401.558,5.649,0.000,401.558
2681,1,400.634,5.649,400.634,5.649,6.992,400.573
2681,2,399.836,5.649,399.836,5.649,13.954,399.592
2681,3,399.162,5.649,399.162,5.649,20.891,398.615
2681,4,398.612,5.649,398.612,5.649,27.806,397.641
2681,5,398.185,5.649,398.185,5.649,34.704,396.669
2681,6,397.879,5.649,397.879,5.649,41.590,395.699
2681,7,397.695,5.649,397.695,5.649,48.467,394.731
2681,8,397.632,5.649,397.632,5.649,55.340,393.763
2681,9,397.691,5.649,397.691,5.649,62.213,392.794
2681,10,397.870,5.649,397.870,5.649,69.089,391.826
2681,11,397.549,12.549,397.549,12.549,75.856,390.245
2681,12,392.985,12.549,392.985,12.549,81.706,384.397
2681,13,388.642,12.549,388.642,12.549,87.425,378.681
2681,14,384.509,12.549,384.509,12.549,93.021,373.087
2681,15,380.578,12.549,380.578,12.549,98.501,367.610
2681,16,376.840,12.549,376.840,12.549,103.871,362.242
2681,17,373.287,12.549,373.287,12.549,109.139,356.976
2681,18,369.913,12.549,369.913,12.549,114.309,351.808
2681,19,366.709,12.549,366.709,12.549,119.389,346.730
2681,20,363.671,12.549,363.671,12.549,124.383,341.739
2681,21,360.791,12.549,360.791,12.549,129.296,336.827
2681,22,358.065,12.549,358.065,12.549,134.133,331.992
2681,23,355.487,12.549,355.487,12.549,138.900,327.227
2681,24,353.053,12.549,353.053,12.549,143.599,322.530
2681,25,350.758,12.549,350.758,12.549,148.237,317.894
2681,26,348.598,12.549,348.598,12.549,152.815,313.318
2681,27,346.569,12.549,346.569,12.549,157.339,308.796
2681,28,344.669,12.549,344.669,12.549,161.812,304.324
2681,29,342.893,12.549,342.893,12.549,166.238,299.901
2681,30,341.238,12.549,341.238,12.549,170.619,295.521
2681,31,339.703,12.549,339.703,12.549,174.960,291.182
2681,32,338.284,12.549,338.284,12.549,179.263,286.881
2681,33,336.979,12.549,336.979,12.549,183.532,282.614
2681,34,335.785,12.549,335.785,12.549,187.769,278.379
2681,35,334.702,12.549,334.702,12.549,191.977,274.172
2681,36,333.727,12.549,333.727,12.549,196.160,269.991
2681,37,332.859,12.549,332.859,12.549,200.320,265.833
2681,38,332.096,12.549,332.096,12.549,204.459,261.695
2681,39,331.438,12.549,331.438,12.549,208.580,257.575
2681,40,330.882,12.549,330.882,12.549,212.687,253.471
2681,41,330.429,12.549,330.429,12.549,216.781,249.378
2681,42,330.078,12.549,330.078,12.549,220.865,245.296
2681,43,329.828,12.549,329.828,12.549,224.942,241.221
2681,44,329.678,12.549,329.678,12.549,229.014,237.151
2681,45,329.629,12.549,329.629,12.549,233.083,233.083
2756,-45,325.244,10.507,325.244,10.507,-229.982,229.982
2756,-44,327.327,10.501,327.327,10.501,-227.380,235.459
2756,-43,329.231,10.533,329.231,10.533,-224.535,240.784
2756,-42,331.206,10.536,331.206,10.536,-221.620,246.134
2756,-41,333.252,10.536,333.252,10.536,-218.633,251.508
2756,-40,335.349,10.410,335.349,10.410,-215.558,256.892
2756,-39,337.539,10.410,337.539,10.410,-212.420,262.317
2756,-38,339.832,10.330,339.832,10.330,-209.222,267.791
2756,-37,342.261,10.330,342.261,10.330,-205.978,273.342
2756,-36,344.668,10.539,344.668,10.539,-202.591,278.842
2756,-35,347.188,10.539,347.188,10.539,-199.139,284.399
2756,-34,349.724,10.496,349.724,10.496,-195.563,289.934
2756,-33,352.339,10.496,352.339,10.496,-191.897,295.496
2756,-32,355.049,10.433,355.049,10.433,-188.147,301.099
2756,-31,357.850,10.548,357.850,10.548,-184.306,306.737
2756,-30,360.637,10.548,360.637,10.548,-180.318,312.320
2756,-29,363.579,10.548,363.579,10.548,-176.266,317.993
2756,-28,364.685,7.103,364.685,7.103,-171.209,321.998
2756,-27,364.810,7.103,364.810,7.103,-165.620,325.048
2756,-26,365.047,7.103,365.047,7.103,-160.026,328.102
2756,-25,365.395,7.103,365.395,7.103,-154.423,331.161
2756,-24,365.856,7.103,365.856,7.103,-148.807,334.226
2756,-23,366.429,7.103,366.429,7.103,-143.175,337.300
2756,-22,367.117,7.103,367.117,7.103,-137.524,340.385
2756,-21,367.919,7.103,367.919,7.103,-131.850,343.482
2756,-20,368.837,7.103,368.837,7.103,-126.150,346.593
2756,-19,369.873,7.103,369.873,7.103,-120.419,349.722
2756,-18,371.028,7.103,371.028,7.103,-114.654,352.868
2756,-17,372.304,7.103,372.304,7.103,-108.851,356.036
2756,-16,373.703,7.103,373.703,7.103,-103.006,359.226
2756,-15,375.227,7.103,375.227,7.103,-97.116,362.441
2756,-14,376.879,7.103,376.879,7.103,-91.175,365.684
2756,-13,378.662,7.103,378.662,7.103,-85.180,368.957
2756,-12,380.578,7.103,380.578,7.103,-79.127,372.261
2756,-11,382.630,7.103,382.630,7.103,-73.009,375.600
2756,-10,384.823,7.103,384.823,7.103,-66.824,378.976
2756,-9,387.159,7.103,387.159,7.103,-60.565,382.393
2756,-8,389.644,7.103,389.644,7.103,-54.228,385.852
2756,-7,392.281,7.103,392.281,7.103,-47.807,389.357
2756,-6,395.075,7.103,395.075,7.103,-41.297,392.910
2756,-5,398.031,7.103,398.031,7.103,-34.691,396.516
2756,-4,401.155,7.103,401.155,7.103,-27.983,400.178
2756,-3,404.452,7.103,404.452,7.103,-21.167,403.898
2756,-2,401.934,7.038,401.934,7.038,-14.027,401.690
2756,-1,399.072,7.038,399.072,7.038,-6.965,399.011
2756,0,396.369,7.038,396.369,7.038,0.000,396.369
2756,1,393.822,7.038,393.822,7.038,6.873,393.762
2756,2,391.426,7.038,391.426,7.038,13.661,391.188
2756,3,389.177,7.038,389.177,7.038,20.368,388.644
2756,4,387.071,7.038,387.071,7.038,27.001,386.128
2756,5,385.104,7.038,385.104,7.038,33.564,383.639
2756,6,383.273,7.038,383.273,7.038,40.063,381.173
2756,7,381.575,7.038,381.575,7.038,46.502,378.731
2756,8,380.008,7.038,380.008,7.038,52.887,376.309
2756,9,378.568,7.038,378.568,7.038,59.221,373.907
2756,10,377.253,7.038,377.253,7.038,65.509,371.522
2756,11,376.062,7.038,376.062,7.038,71.756,369.153
2756,12,374.992,7.038,374.992,7.038,77.965,366.797
2756,13,374.042,7.038,374.042,7.038,84.141,364.455
2756,14,373.210,7.038,373.210,7.038,90.288,362.124
2756,15,372.494,7.038,372.494,7.038,96.409,359.802
2756,16,371.895,7.038,371.895,7.038,102.508,357.488
2756,17,371.411,7.038,371.411,7.038,108.590,355.182
2756,18,371.040,7.038,371.040,7.038,114.658,352.880
2756,19,370.783,7.038,370.783,7.038,120.715,350.583
2756,20,370.640,7.038,370.640,7.038,126.766,348.287
2756,21,370.609,7.038,370.609,7.038,132.814,345.993
2756,22,370.691,7.038,370.691,7.038,138.863,343.699
2756,23,370.887,7.038,370.887,7.038,144.917,341.403
2756,24,371.195,7.038,371.195,7.038,150.979,339.104
2756,25,371.181,7.175,371.181,7.175,156.868,336.405
2756,26,367.485,7.175,367.485,7.175,161.095,330.294
2756,27,363.972,7.175,363.972,7.175,165.240,324.302
2756,28,360.634,7.175,360.634,7.175,169.307,318.421
2756,29,357.465,7.175,357.465,7.175,173.302,312.646
2756,30,354.458,7.175,354.458,7.175,177.229,306.969
2756,31,351.607,7.175,351.607,7.175,181.091,301.386
2756,32,348.907,7.175,348.907,7.175,184.893,295.890
2756,33,346.353,7.175,346.353,7.175,188.637,290.476
2756,34,343.940,7.175,343.940,7.175,192.329,285.139
2756,35,341.664,7.175,341.664,7.175,195.970,279.875
2756,36,339.520,7.175,339.520,7.175,199.565,274.678
2756,37,337.506,7.175,337.506,7.175,203.116,269.544
2756,38,335.617,7.175,335.617,7.175,206.626,264.470
2756,39,333.850,7.175,333.850,7.175,210.098,259.450
2756,40,332.202,7.175,332.202,7.175,213.535,254.481
2756,41,330.956,6.649,330.956,6.649,217.127,249.776
2756,42,329.992,6.649,329.992,6.649,220.808,245.232
2756,43,328.743,6.438,328.743,6.438,224.202,240.428
2756,44,327.224,6.438,327.224,6.438,227.309,235.385
2756,45,325.064,10.103,325.064,10.103,229.855,229.855
2889,-45,335.230,7.542,335.230,7.542,-237.043,237.043
2889,-44,345.055,7.542,345.055,7.542,-239.695,248.211
2889,-43,351.231,7.528,351.231,7.528,-239.539,256.874
2889,-42,351.503,7.528,351.503,7.528,-235.202,261.218
2889,-41,351.883,7.528,351.883,7.528,-230.856,265.569
2889,-40,352.371,7.528,352.371,7.528,-226.500,269.932
2889,-39,352.968,7.528,352.968,7.528,-222.130,274.307
2889,-38,353.675,7.528,353.675,7.528,-217.744,278.699
2889,-37,354.493,7.528,354.493,7.528,-213.339,283.110
2889,-36,355.423,7.528,355.423,7.528,-208.912,287.543
2889,-35,356.467,7.528,356.467,7.528,-204.461,292.000
2889,-34,357.626,7.528,357.626,7.528,-199.982,296.486
2889,-33,358.903,7.528,358.903,7.528,-195.472,301.001
2889,-32,360.299,7.528,360.299,7.528,-190.929,305.551
2889,-31,361.816,7.528,361.816,7.528,-186.349,310.137
2889,-30,363.458,7.528,363.458,7.528,-181.729,314.764
2889,-29,365.226,7.528,365.226,7.528,-177.065,319.434
2889,-28,367.124,7.528,367.124,7.528,-172.354,324.151
2889,-27,369.155,7.528,369.155,7.528,-167.593,328.919
2889,-26,371.322,7.528,371.322,7.528,-162.777,333.742
2889,-25,373.629,7.528,373.629,7.528,-157.902,338.623
2889,-24,376.080,7.528,376.080,7.528,-152.966,343.566
2889,-23,378.680,7.528,378.680,7.528,-147.962,348.577
2889,-22,381.433,7.528,381.433,7.528,-142.887,353.658
2889,-21,384.344,7.528,384.344,7.528,-137.737,358.816
2889,-20,387.419,7.528,387.419,7.528,-132.505,364.055
2889,-19,390.600,7.521,390.600,7.521,-127.167,369.319
2889,-18,390.435,7.532,390.435,7.532,-120.651,371.326
2889,-17,390.494,7.532,390.494,7.532,-114.169,373.431
2889,-16,390.672,7.532,390.672,7.532,-107.684,375.538
2889,-15,390.970,7.532,390.970,7.532,-101.190,377.648
2889,-14,391.387,7.532,391.387,7.532,-94.685,379.761
2889,-13,391.924,7.532,391.924,7.532,-88.164,381.879
2889,-12,392.583,7.532,392.583,7.532,-81.623,384.004
2889,-11,393.364,7.532,393.364,7.532,-75.057,386.137
2889,-10,394.269,7.532,394.269,7.532,-68.464,388.279
2889,-9,395.298,7.532,395.298,7.532,-61.838,390.431
2889,-8,396.454,7.532,396.454,7.532,-55.176,392.596
2889,-7,397.738,7.532,397.738,7.532,-48.472,394.773
2889,-6,399.152,7.532,399.152,7.532,-41.723,396.966
2889,-5,400.699,7.532,400.699,7.532,-34.923,399.175
2889,-4,400.385,7.534,400.385,7.534,-27.929,399.410
2889,-3,399.970,7.534,399.970,7.534,-20.933,399.422
2889,-2,399.677,7.534,399.677,7.534,-13.949,399.434
2889,-1,399.507,7.534,399.507,7.534,-6.972,399.446
2889,0,399.458,7.534,399.458,7.534,0.000,399.458
2889,1,399.531,7.534,399.531,7.534,6.973,399.470
2889,2,399.725,7.534,399.725,7.534,13.950,399.482
2889,3,400.042,7.534,400.042,7.534,20.937,399.494
2889,4,400.481,7.534,400.481,7.534,27.936,399.505
2889,5,400.903,7.509,400.903,7.509,34.941,399.377
2889,6,399.350,7.509,399.350,7.509,41.743,397.162
2889,7,397.930,7.509,397.930,7.509,48.495,394.964
2889,8,396.640,7.509,396.640,7.509,55.202,392.780
2889,9,395.479,7.509,395.479,7.509,61.866,390.610
2889,10,394.444,7.509,394.444,7.509,68.494,388.451
2889,11,393.534,7.509,393.534,7.509,75.090,386.304
2889,12,392.748,7.509,392.748,7.509,81.657,384.165
2889,13,392.084,7.509,392.084,7.509,88.200,382.035
2889,14,391.541,7.509,391.541,7.509,94.722,379.911
2889,15,391.119,7.509,391.119,7.509,101.229,377.792
2889,16,390.817,7.509,390.817,7.509,107.724,375.677
2889,17,390.634,7.509,390.634,7.509,114.210,373.565
2889,18,390.570,7.509,390.570,7.509,120.693,371.454
2889,19,390.812,7.483,390.812,7.483,127.236,369.520
2889,20,387.973,7.560,387.973,7.560,132.695,364.575
2889,21,384.887,7.560,384.887,7.560,137.931,359.323
2889,22,381.965,7.560,381.965,7.560,143.086,354.151
2889,23,379.201,7.560,379.201,7.560,148.166,349.057
2889,24,376.591,7.560,376.591,7.560,153.173,344.033
2889,25,374.130,7.560,374.130,7.560,158.114,339.077
2889,26,371.814,7.560,371.814,7.560,162.992,334.184
2889,27,369.638,7.560,369.638,7.560,167.812,329.349
2889,28,367.598,7.560,367.598,7.560,172.577,324.570
2889,29,365.692,7.560,365.692,7.560,177.291,319.841
2889,30,363.915,7.560,363.915,7.560,181.958,315.160
2889,31,362.266,7.560,362.266,7.560,186.581,310.523
2889,32,360.741,7.560,360.741,7.560,191.164,305.926
2889,33,359.338,7.560,359.338,7.560,195.709,301.366
2889,34,358.054,7.560,358.054,7.560,200.221,296.840
2889,35,356.887,7.560,356.887,7.560,204.702,292.345
2889,36,355.837,7.560,355.837,7.560,209.156,287.878
2889,37,354.900,7.560,354.900,7.560,213.584,283.436
2889,38,354.076,7.560,354.076,7.560,217.991,279.015
2889,39,353.363,7.560,353.363,7.560,222.378,274.614
2889,40,352.760,7.560,352.760,7.560,226.750,270.230
2889,41,352.266,7.560,352.266,7.560,231.107,265.858
2889,42,349.228,7.475,349.228,7.475,233.679,259.527
2889,43,344.257,7.475,344.257,7.475,234.783,251.774
2889,44,339.528,7.475,339.528,7.475,235.856,244.236
2889,45,335.027,7.475,335.027,7.475,236.900,236.900
3289,-45,334.896,12.219,334.944,12.219,-236.807,236.807
3289,-44,336.474,8.156,336.918,9.859,-233.735,242.039
3289,-43,338.568,8.156,338.962,9.869,-230.903,247.613
3289,-42,340.792,8.156,341.138,9.869,-228.035,253.258
3289,-41,343.151,8.156,343.448,9.869,-225.128,258.980
3289,-40,345.649,8.156,345.896,9.869,-222.179,264.783
3289,-39,348.291,8.156,348.485,9.869,-219.187,270.673
3289,-38,351.081,8.156,351.221,9.869,-216.147,276.656
3289,-37,354.025,8.156,354.110,9.869,-213.057,282.737
3289,-36,357.128,8.156,357.155,9.869,-209.914,288.923
3289,-35,357.211,8.190,357.211,8.190,-204.888,292.610
3289,-34,357.211,8.190,357.211,8.190,-199.750,296.141
3289,-33,357.320,8.190,357.320,8.190,-194.610,299.674
3289,-32,357.538,8.190,357.538,8.190,-189.466,303.209
3289,-31,357.670,8.090,357.671,8.090,-184.214,306.583
3289,-30,357.778,8.090,357.779,8.090,-178.889,309.844
3289,-29,357.994,8.090,357.996,8.090,-173.559,313.109
3289,-28,358.320,8.090,358.323,8.090,-168.221,316.378
3289,-27,358.756,8.090,358.759,8.090,-162.872,319.654
3289,-26,359.302,8.090,359.306,8.090,-157.508,322.939
3289,-25,359.960,8.090,359.965,8.090,-152.126,326.235
3289,-24,360.730,8.090,360.736,8.090,-146.722,329.544
3289,-23,361.615,8.090,361.621,8.090,-141.294,332.868
3289,-22,362.614,8.090,362.621,8.090,-135.838,336.210
3289,-21,363.730,8.090,363.738,8.090,-130.349,339.571
3289,-20,364.964,8.090,364.973,8.090,-124.825,342.954
3289,-19,366.319,8.090,366.329,8.090,-119.262,346.361
3289,-18,367.796,8.090,367.807,8.090,-113.655,349.795
3289,-17,369.495,8.170,369.495,8.170,-108.030,353.350
3289,-16,371.656,8.170,371.563,8.190,-102.442,357.259
3289,-15,373.957,8.170,373.869,8.190,-96.787,361.215
3289,-14,376.402,8.170,376.319,8.190,-91.060,365.222
3289,-13,378.996,8.170,378.918,8.190,-85.256,369.282
3289,-12,381.743,8.170,381.670,8.190,-79.369,373.401
3289,-11,384.647,8.170,384.581,8.190,-73.394,377.580
3289,-10,387.716,8.170,387.655,8.190,-67.326,381.825
3289,-9,390.953,8.170,390.899,8.190,-61.159,386.140
3289,-8,394.367,8.170,394.318,8.190,-54.885,390.529
3289,-7,397.963,8.170,397.920,8.190,-48.499,394.996
3289,-6,401.748,8.170,401.713,8.190,-41.994,399.547
3289,-5,405.731,8.170,405.702,8.190,-35.362,404.187
3289,-4,408.775,8.289,408.772,8.289,-28.515,407.779
3289,-3,408.343,8.289,408.327,8.289,-21.371,407.783
3289,-2,408.036,8.289,408.008,8.289,-14.240,407.788
3289,-1,407.854,8.289,407.813,8.289,-7.118,407.792
3289,0,407.796,8.289,407.743,8.289,0.000,407.796
3289,1,407.863,8.289,407.796,8.289,7.118,407.801
3289,2,408.054,8.289,407.975,8.289,14.241,407.805
3289,3,408.369,8.289,408.277,8.289,21.372,407.809
3289,4,407.666,8.260,407.665,8.260,28.437,406.673
3289,5,404.134,8.260,404.131,8.260,35.223,402.596
3289,6,400.783,8.260,400.778,8.260,41.893,398.588
3289,7,397.608,8.260,397.601,8.260,48.456,394.644
3289,8,394.602,8.260,394.592,8.260,54.918,390.762
3289,9,391.759,8.260,391.748,8.260,61.285,386.936
3289,10,389.075,8.260,389.061,8.260,67.562,383.164
3289,11,386.544,8.260,386.529,8.260,73.756,379.443
3289,12,384.163,8.260,384.145,8.260,79.872,375.768
3289,13,381.926,8.260,381.906,8.260,85.915,372.137
3289,14,379.830,8.260,379.808,8.260,91.889,368.547
3289,15,377.871,8.260,377.848,8.260,97.800,364.995
3289,16,376.046,8.260,376.021,8.260,103.652,361.479
3289,17,374.353,8.260,374.326,8.260,109.450,357.995
3289,18,372.787,8.260,372.759,8.260,115.198,354.542
3289,19,371.348,8.260,371.318,8.260,120.899,351.116
3289,20,370.031,8.260,370.000,8.260,126.558,347.716
3289,21,368.836,8.260,368.803,8.260,132.179,344.338
3289,22,367.830,7.629,367.755,7.690,137.792,341.046
3289,23,368.638,7.629,368.597,7.690,144.038,339.333
3289,24,369.563,7.629,369.557,7.690,150.315,337.613
3289,25,370.606,7.629,370.634,7.690,156.625,335.883
3289,26,371.768,7.629,371.832,7.690,162.972,334.143
3289,27,373.051,7.629,373.151,7.690,169.362,332.391
3289,28,374.315,7.950,374.315,7.950,175.730,330.501
3289,29,371.120,7.950,371.120,7.950,179.922,324.589
3289,30,368.090,7.950,368.090,7.950,184.045,318.775
3289,31,365.219,7.950,365.219,7.950,188.102,313.054
3289,32,362.502,7.950,362.502,7.950,192.097,307.419
3289,33,359.934,7.950,359.934,7.950,196.034,301.866
3289,34,357.511,7.950,357.511,7.950,199.918,296.390
3289,35,355.227,7.950,355.227,7.950,203.750,290.985
3289,36,353.080,7.950,353.080,7.950,207.535,285.647
3289,37,351.064,7.950,351.064,7.950,211.276,280.372
3289,38,348.023,8.170,348.027,8.170,214.264,274.246
3289,39,342.100,8.170,342.118,8.170,215.291,265.862
3289,40,336.477,8.170,336.508,8.170,216.283,257.756
3289,41,334.773,8.190,334.777,8.190,219.631,252.656
3289,42,333.359,8.190,333.368,8.190,223.061,247.734
3289,43,332.059,8.190,332.072,8.190,226.464,242.853
3289,44,330.869,8.190,330.886,8.190,229.841,238.007
3289,45,329.788,8.190,329.949,8.190,233.195,233.195
3309,-45,336.358,8.746,336.358,8.746,-237.841,237.841
3309,-44,337.865,8.746,337.865,8.746,-234.701,243.040
3309,-43,339.489,8.746,339.489,8.746,-231.531,248.287
3309,-42,341.233,8.746,341.233,8.746,-228.330,253.586
3309,-41,343.101,8.746,343.101,8.746,-225.094,258.942
3309,-40,345.095,8.746,345.095,8.746,-221.823,264.358
3309,-39,347.218,8.746,347.218,8.746,-218.511,269.839
3309,-38,349.475,8.746,349.475,8.746,-215.158,275.390
3309,-37,351.869,8.746,351.869,8.746,-211.760,281.015
3309,-36,354.405,8.746,354.405,8.746,-208.314,286.720
3309,-35,357.087,8.746,357.087,8.746,-204.817,292.509
3309,-34,359.921,8.746,359.921,8.746,-201.265,298.388
3309,-33,362.911,8.746,362.911,8.746,-197.656,304.363
3309,-32,366.064,8.746,366.064,8.746,-193.985,310.440
3309,-31,368.101,8.904,368.101,8.904,-189.586,315.524
3309,-30,367.876,8.904,367.876,8.904,-183.938,318.590
3309,-29,370.516,7.761,370.516,7.761,-179.630,324.061
3309,-28,370.568,7.761,370.568,7.761,-173.971,327.192
3309,-27,370.732,7.761,370.732,7.761,-168.309,330.325
3309,-26,371.010,7.761,371.010,7.761,-162.640,333.462
3309,-25,371.402,7.761,371.402,7.761,-156.961,336.604
3309,-24,371.907,7.761,371.907,7.761,-151.268,339.754
3309,-23,372.528,7.761,372.528,7.761,-145.558,342.914
3309,-22,373.264,7.761,373.264,7.761,-139.827,346.085
3309,-21,374.118,7.761,374.118,7.761,-134.072,349.269
3309,-20,375.090,7.761,375.090,7.761,-128.288,352.469
3309,-19,376.182,7.761,376.182,7.761,-122.473,355.687
3309,-18,377.396,7.761,377.396,7.761,-116.622,358.925
3309,-17,378.733,7.761,378.733,7.761,-110.731,362.184
3309,-16,380.196,7.761,380.196,7.761,-104.796,365.468
3309,-15,381.787,7.761,381.787,7.761,-98.814,368.778
3309,-14,382.660,8.881,382.660,8.881,-92.574,371.293
3309,-13,377.650,8.984,377.650,8.984,-84.953,367.971
3309,-12,379.114,8.969,379.114,8.969,-78.822,370.829
3309,-11,381.212,8.969,381.212,8.969,-72.739,374.208
3309,-10,383.450,8.969,383.450,8.969,-66.585,377.625
3309,-9,385.833,8.969,385.833,8.969,-60.358,381.083
3309,-8,388.365,8.969,388.365,8.969,-54.050,384.586
3309,-7,391.051,8.969,391.051,8.969,-47.657,388.136
3309,-6,393.894,8.969,393.894,8.969,-41.173,391.737
3309,-5,396.901,8.969,396.901,8.969,-34.592,395.391
3309,-4,400.077,8.969,400.077,8.969,-27.908,399.103
3309,-3,403.429,8.969,403.429,8.969,-21.114,402.876
3309,-2,406.962,8.969,406.962,8.969,-14.203,406.714
3309,-1,405.398,7.583,405.398,7.583,-7.075,405.336
3309,0,402.255,7.583,402.255,7.583,0.000,402.255
3309,1,399.282,7.583,399.282,7.583,6.968,399.221
3309,2,396.472,7.583,396.472,7.583,13.837,396.230
3309,3,393.820,7.583,393.820,7.583,20.611,393.281
3309,4,391.323,7.583,391.323,7.583,27.297,390.369
3309,5,388.974,7.583,388.974,7.583,33.901,387.494
3309,6,386.576,13.681,386.576,13.681,40.408,384.458
3309,7,384.449,13.681,384.449,13.681,46.853,381.584
3309,8,382.462,13.681,382.462,13.681,53.228,378.740
3309,9,380.611,13.681,380.611,13.681,59.541,375.925
3309,10,378.893,13.681,378.893,13.681,65.794,373.136
3309,11,377.304,13.681,377.304,13.681,71.993,370.372
3309,12,375.842,13.681,375.842,13.681,78.142,367.629
3309,13,374.506,13.681,374.506,13.681,84.246,364.907
3309,14,373.292,13.681,373.292,13.681,90.308,362.204
3309,15,372.199,13.681,372.199,13.681,96.332,359.517
3309,16,371.226,13.681,371.226,13.681,102.324,356.845
3309,17,370.369,13.681,370.369,13.681,108.286,354.186
3309,18,369.629,13.681,369.629,13.681,114.222,351.539
3309,19,369.005,13.681,369.005,13.681,120.136,348.901
3309,20,368.494,13.681,368.494,13.681,126.032,346.271
3309,21,368.097,13.681,368.097,13.681,131.914,343.648
3309,22,367.813,13.681,367.813,13.681,137.785,341.030
3309,23,367.640,13.681,367.640,13.681,143.649,338.415
3309,24,367.580,13.681,367.580,13.681,149.508,335.801
3309,25,367.632,13.681,367.632,13.681,155.368,333.188
3309,26,367.796,13.681,367.796,13.681,161.231,330.573
3309,27,368.073,13.681,368.073,13.681,167.102,327.955
3309,28,368.462,13.681,368.462,13.681,172.982,325.332
3309,29,368.964,13.681,368.964,13.681,178.877,322.703
3309,30,369.581,13.681,369.581,13.681,184.790,320.066
3309,31,370.312,13.681,370.312,13.681,190.725,317.420
3309,32,367.878,7.457,367.878,7.457,194.946,311.978
3309,33,364.419,7.457,364.419,7.457,198.477,305.627
3309,34,361.133,7.457,361.133,7.457,201.943,299.393
3309,35,358.014,7.457,358.014,7.457,205.349,293.268
3309,36,355.056,7.457,355.056,7.457,208.697,287.247
3309,37,352.253,7.457,352.253,7.457,211.991,281.322
3309,38,349.599,7.457,349.599,7.457,215.235,275.488
3309,39,347.090,7.457,347.090,7.457,218.431,269.740
3309,40,344.721,7.457,344.721,7.457,221.582,264.072
3309,41,342.488,7.457,342.488,7.457,224.692,258.479
3309,42,340.386,7.457,340.386,7.457,227.763,252.956
3309,43,338.412,7.457,338.412,7.457,230.797,247.499
3309,44,336.564,7.457,336.564,7.457,233.797,242.104
3309,45,334.873,16.102,334.873,16.102,236.791,236.791
3312,-45,338.255,7.756,338.255,7.756,-239.182,239.182
3312,-44,338.693,7.756,338.693,7.756,-235.276,243.635
3312,-43,339.235,7.756,339.235,7.756,-231.358,248.101
3312,-42,339.883,7.756,339.883,7.756,-227.426,252.583
3312,-41,340.638,7.756,340.638,7.756,-223.478,257.083
3312,-40,341.500,7.756,341.500,7.756,-219.512,261.604
3312,-39,342.471,7.756,342.471,7.756,-215.524,266.150
3312,-38,343.552,7.756,343.552,7.756,-211.512,270.723
3312,-37,344.746,7.756,344.746,7.756,-207.473,275.327
3312,-36,346.054,7.756,346.054,7.756,-203.405,279.964
3312,-35,347.478,7.756,347.478,7.756,-199.305,284.637
3312,-34,349.021,7.756,349.021,7.756,-195.170,289.351
3312,-33,350.684,7.756,350.684,7.756,-190.996,294.109
3312,-32,352.472,7.756,352.472,7.756,-186.782,298.913
3312,-31,354.386,7.756,354.386,7.756,-182.522,303.768
3312,-30,356.430,7.756,356.430,7.756,-178.215,308.678
3312,-29,358.608,7.756,358.608,7.756,-173.857,313.646
3312,-28,360.924,7.756,360.924,7.756,-169.443,318.677
3312,-27,363.381,7.756,363.381,7.756,-164.971,323.774
3312,-26,365.983,7.756,365.983,7.756,-160.436,328.944
3312,-25,368.737,7.756,368.737,7.756,-155.835,334.189
3312,-24,371.646,7.756,371.646,7.756,-151.162,339.515
3312,-23,374.717,7.756,374.717,7.756,-146.413,344.928
3312,-22,376.925,7.803,376.925,7.803,-141.199,349.479
3312,-21,378.053,7.803,378.053,7.803,-135.482,352.943
3312,-20,379.303,7.803,379.303,7.803,-129.729,356.428
3312,-19,380.678,7.803,380.678,7.803,-123.937,359.938
3312,-18,382.180,7.803,382.180,7.803,-118.100,363.475
3312,-17,383.811,7.803,383.811,7.803,-112.215,367.040
3312,-16,385.574,7.803,385.574,7.803,-106.279,370.637
3312,-15,387.472,7.803,387.472,7.803,-100.285,374.269
3312,-14,389.507,7.803,389.507,7.803,-94.230,377.937
3312,-13,391.685,7.803,391.685,7.803,-88.110,381.646
3312,-12,394.007,7.803,394.007,7.803,-81.919,385.397
3312,-11,396.479,7.803,396.479,7.803,-75.652,389.194
3312,-10,399.104,7.803,399.104,7.803,-69.304,393.041
3312,-9,401.887,7.803,401.887,7.803,-62.869,396.939
3312,-8,404.834,7.803,404.834,7.803,-56.342,400.894
3312,-7,407.949,7.803,407.949,7.803,-49.717,404.908
3312,-6,410.019,7.780,410.019,7.780,-42.859,407.772
3312,-5,408.672,7.780,408.672,7.780,-35.618,407.117
3312,-4,407.458,7.780,407.458,7.780,-28.423,406.465
3312,-3,406.375,7.780,406.375,7.780,-21.268,405.818
3312,-2,405.420,7.780,405.420,7.780,-14.149,405.173
3312,-1,404.593,7.780,404.593,7.780,-7.061,404.531
3312,0,403.892,7.780,403.892,7.780,0.000,403.892
3312,1,403.316,7.780,403.316,7.780,7.039,403.255
3312,2,402.864,7.780,402.864,7.780,14.060,402.619
3312,3,402.536,7.780,402.536,7.780,21.067,401.985
3312,4,402.331,7.780,402.331,7.780,28.065,401.351
3312,5,402.248,7.780,402.248,7.780,35.058,400.718
3312,6,402.288,7.780,402.288,7.780,42.051,400.085
3312,7,400.998,23.125,400.998,23.125,48.869,398.009
3312,8,397.704,23.125,397.704,23.125,55.350,393.834
3312,9,394.584,23.125,394.584,23.125,61.726,389.726
3312,10,391.630,23.125,391.630,23.125,68.006,385.680
3312,11,388.838,23.125,388.838,23.125,74.194,381.694
3312,12,386.202,23.125,386.202,23.125,80.296,377.763
3312,13,383.718,23.125,383.718,23.125,86.318,373.883
3312,14,381.381,23.125,381.381,23.125,92.264,370.052
3312,15,379.187,23.125,379.187,23.125,98.141,366.266
3312,16,377.132,23.125,377.132,23.125,103.952,362.523
3312,17,375.213,23.125,375.213,23.125,109.702,358.818
3312,18,373.427,23.125,373.427,23.125,115.395,355.150
3312,19,371.771,23.125,371.771,23.125,121.037,351.516
3312,20,370.241,23.125,370.241,23.125,126.630,347.913
3312,21,368.836,23.125,368.836,23.125,132.179,344.338
3312,22,367.553,23.125,367.553,23.125,137.688,340.789
3312,23,366.390,23.125,366.390,23.125,143.160,337.264
3312,24,365.345,23.125,365.345,23.125,148.599,333.759
3312,25,362.235,23.109,362.235,23.109,153.087,328.296
3312,26,359.254,23.109,359.254,23.109,157.486,322.895
3312,27,356.429,23.109,356.429,23.109,161.815,317.580
3312,28,345.321,21.830,345.321,21.830,162.118,304.900
3312,29,343.545,21.830,343.545,21.830,166.554,300.472
3312,30,341.892,21.830,341.892,21.830,170.946,296.087
3312,31,340.357,21.830,340.357,21.830,175.297,291.743
3312,32,338.939,21.830,338.939,21.830,179.610,287.436
3312,33,337.635,21.830,337.635,21.830,183.889,283.164
3312,34,336.443,21.830,336.443,21.830,188.136,278.924
3312,35,335.361,21.830,335.361,21.830,192.355,274.711
3312,36,334.387,21.830,334.387,21.830,196.548,270.525
3312,37,333.521,21.830,333.521,21.830,200.718,266.362
3312,38,332.760,21.830,332.760,21.830,204.868,262.219
3312,39,332.104,21.830,332.104,21.830,209.000,258.093
3312,40,332.117,20.417,332.117,20.417,213.481,254.416
3312,41,331.063,20.417,331.063,20.417,217.197,249.857
3312,42,330.116,20.417,330.116,20.417,220.891,245.324
3312,43,329.275,20.417,329.275,20.417,224.565,240.816
3312,44,328.537,20.417,328.537,20.417,228.221,236.330
3312,45,327.903,20.417,327.903,20.417,231.862,231.862
3313,-45,317.471,8.020,317.471,8.020,-224.486,224.486
3313,-44,323.212,8.020,323.212,8.020,-224.522,232.499
3313,-43,327.745,8.050,327.745,8.050,-223.522,239.698
3313,-42,332.275,8.050,332.275,8.050,-222.336,246.929
3313,-41,336.643,8.100,336.643,8.100,-220.858,254.068
3313,-40,340.217,8.100,340.217,8.100,-218.688,260.622
3313,-39,343.974,8.100,343.974,8.100,-216.470,267.318
3313,-38,347.198,8.100,347.198,8.100,-213.756,273.595
3313,-37,350.095,8.100,350.095,8.100,-210.692,279.598
3313,-36,353.150,8.100,353.150,8.100,-207.576,285.704
3313,-35,354.579,8.025,354.579,8.025,-203.378,290.454
3313,-34,355.732,8.025,355.732,8.025,-198.923,294.915
3313,-33,357.002,8.025,357.002,8.025,-194.437,299.407
3313,-32,358.391,8.025,358.391,8.025,-189.918,303.933
3313,-31,359.900,8.025,359.900,8.025,-185.362,308.495
3313,-30,361.533,8.025,361.533,8.025,-180.767,313.097
3313,-29,363.292,8.025,363.292,8.025,-176.127,317.742
3313,-28,365.180,8.025,365.180,8.025,-171.442,322.435
3313,-27,367.200,8.025,367.200,8.025,-166.705,327.178
3313,-26,369.356,8.025,369.356,8.025,-161.915,331.975
3313,-25,371.651,8.025,371.651,8.025,-157.066,336.830
3313,-24,374.089,8.025,374.089,8.025,-152.156,341.747
3313,-23,376.675,8.025,376.675,8.025,-147.179,346.731
3313,-22,379.413,8.025,379.413,8.025,-142.131,351.786
3313,-21,382.309,8.025,382.309,8.025,-137.007,356.917
3313,-20,385.368,8.025,385.368,8.025,-131.804,362.128
3313,-19,388.595,8.025,388.595,8.025,-126.514,367.424
3313,-18,391.717,8.079,391.717,8.079,-121.047,372.545
3313,-17,393.404,8.079,393.404,8.079,-115.020,376.214
3313,-16,395.226,8.079,395.226,8.079,-108.939,379.916
3313,-15,397.187,8.079,397.187,8.079,-102.799,383.653
3313,-14,399.289,8.079,399.289,8.079,-96.597,387.429
3313,-13,401.537,8.079,401.537,8.079,-90.326,391.246
3313,-12,403.849,8.130,403.849,8.130,-83.965,395.024
3313,-11,404.656,8.130,404.656,8.130,-77.212,397.221
3313,-10,405.590,8.130,405.590,8.130,-70.430,399.428
3313,-9,406.234,8.140,406.234,8.140,-63.549,401.232
3313,-8,406.812,8.140,406.812,8.140,-56.617,402.853
3313,-7,407.516,8.140,407.516,8.140,-49.664,404.478
3313,-6,408.173,8.000,408.173,8.000,-42.666,405.937
3313,-5,408.601,8.000,408.601,8.000,-35.612,407.046
3313,-4,409.034,8.070,409.034,8.070,-28.533,408.038
3313,-3,408.570,8.070,408.570,8.070,-21.383,408.010
3313,-2,408.231,8.070,408.231,8.070,-14.247,407.982
3313,-1,408.017,8.070,408.017,8.070,-7.121,407.955
3313,0,407.891,8.140,407.891,8.140,0.000,407.891
3313,1,406.838,8.140,406.838,8.140,7.100,406.776
3313,2,405.914,8.140,405.914,8.140,14.166,405.667
3313,3,405.118,8.140,405.118,8.140,21.202,404.563
3313,4,404.414,8.079,404.414,8.079,28.210,403.428
3313,5,402.848,8.079,402.848,8.079,35.111,401.315
3313,6,401.416,8.079,401.416,8.079,41.959,399.217
3313,7,400.116,8.079,400.116,8.079,48.762,397.134
3313,8,398.752,8.210,398.752,8.210,55.496,394.871
3313,9,395.129,8.210,395.129,8.210,61.812,390.265
3313,10,391.690,8.210,391.690,8.210,68.016,385.740
3313,11,388.428,8.210,388.428,8.210,74.116,381.291
3313,12,385.336,8.210,385.336,8.210,80.116,376.915
3313,13,382.408,8.210,382.408,8.210,86.023,372.607
3313,14,379.639,8.210,379.639,8.210,91.843,368.363
3313,15,375.758,8.100,375.758,8.100,97.253,362.954
3313,16,372.052,8.100,372.052,8.100,102.551,357.639
3313,17,368.530,8.100,368.530,8.100,107.748,352.427
3313,18,365.184,8.100,365.184,8.100,112.848,347.311
3313,19,362.008,8.100,362.008,8.100,117.858,342.285
3313,20,358.995,8.100,358.995,8.100,122.783,337.345
3313,21,356.139,8.100,356.139,8.100,127.629,332.484
3313,22,353.435,8.100,353.435,8.100,132.399,327.699
3313,23,350.878,8.100,350.878,8.100,137.099,322.985
3313,24,348.463,8.100,348.463,8.100,141.733,318.337
3313,25,346.185,8.100,346.185,8.100,146.304,313.751
3313,26,344.042,8.100,344.042,8.100,150.818,309.223
3313,27,342.028,8.100,342.028,8.100,155.278,304.749
3313,28,340.141,8.100,340.141,8.100,159.687,300.327
3313,29,338.377,8.100,338.377,8.100,164.048,295.951
3313,30,336.733,8.100,336.733,8.100,168.367,291.620
3313,31,335.207,8.100,335.207,8.100,172.644,287.329
3313,32,333.796,8.100,333.796,8.100,176.885,283.075
3313,33,332.497,8.100,332.497,8.100,181.091,278.856
3313,34,331.309,8.100,331.309,8.100,185.266,274.668
3313,35,330.230,8.100,330.230,8.100,189.412,270.509
3313,36,329.258,8.100,329.258,8.100,193.533,266.375
3313,37,328.391,8.100,328.391,8.100,197.631,262.265
3313,38,327.628,8.100,327.628,8.100,201.708,258.175
3313,39,326.968,8.100,326.968,8.100,205.768,254.102
3313,40,326.323,7.627,326.323,7.627,209.756,249.978
3313,41,324.603,7.627,324.603,7.627,212.959,244.981
3313,42,322.948,7.559,322.948,7.559,216.095,239.997
3313,43,320.209,7.559,320.209,7.559,218.382,234.186
3313,44,317.554,7.548,317.554,7.548,220.591,228.429
3313,45,313.469,7.548,313.469,7.548,221.656,221.656
4169,-45,343.771,10.954,343.771,10.954,-243.083,243.083
4169,-44,345.369,11.084,345.369,11.084,-239.913,248.438
4169,-43,346.989,11.084,346.989,11.084,-236.646,253.771
4169,-42,348.730,11.084,348.730,11.084,-233.346,259.157
4169,-41,350.597,11.084,350.597,11.084,-230.012,264.599
4169,-40,352.580,11.063,352.580,11.063,-226.634,270.092
4169,-39,354.137,6.474,354.137,6.474,-222.865,275.216
4169,-38,355.614,6.405,355.614,6.405,-218.938,280.228
4169,-37,357.203,6.394,357.203,6.394,-214.970,285.275
4169,-36,358.994,6.450,358.994,6.450,-211.012,290.433
4169,-35,360.824,6.509,360.824,6.509,-206.960,295.570
4169,-34,362.595,6.519,362.595,6.519,-202.761,300.605
4169,-33,363.993,6.599,363.993,6.599,-198.245,305.270
4169,-32,365.513,6.585,365.513,6.585,-193.692,309.972
4169,-31,367.177,6.585,367.177,6.585,-189.110,314.732
4169,-30,368.969,6.585,368.969,6.585,-184.485,319.537
4169,-29,370.846,6.639,370.846,6.639,-179.790,324.349
4169,-28,372.118,6.639,372.118,6.639,-174.699,328.561
4169,-27,373.514,6.639,373.514,6.639,-169.572,332.804
4169,-26,375.035,6.639,375.035,6.639,-164.405,337.079
4169,-25,376.684,6.639,376.684,6.639,-159.193,341.391
4169,-24,378.462,6.639,378.462,6.639,-153.935,345.743
4169,-23,379.904,6.615,379.904,6.615,-148.440,349.703
4169,-22,381.202,6.615,381.202,6.615,-142.801,353.445
4169,-21,382.566,10.957,382.566,10.957,-137.099,357.156
4169,-20,384.039,10.984,384.039,10.984,-131.349,360.878
4169,-19,385.675,11.017,385.675,11.017,-125.563,364.662
4169,-18,387.246,11.091,387.246,11.091,-119.666,368.293
4169,-17,388.496,11.049,388.496,11.049,-113.585,371.521
4169,-16,389.365,11.049,389.365,11.049,-107.324,374.282
4169,-15,389.819,11.113,389.819,11.113,-100.893,376.537
4169,-14,390.024,10.985,390.024,10.985,-94.355,378.439
4169,-13,389.887,10.865,389.887,10.865,-87.705,379.894
4169,-12,390.371,10.929,390.371,10.929,-81.163,381.841
4169,-11,391.696,11.039,391.696,11.039,-74.739,384.500
4169,-10,394.010,11.017,394.010,11.017,-68.419,388.024
4169,-9,397.369,11.044,397.369,11.044,-62.162,392.477
4169,-8,402.137,11.105,402.137,11.105,-55.967,398.224
4169,-7,403.064,8.204,403.064,8.204,-49.121,400.060
4169,-6,402.263,8.204,402.263,8.204,-42.048,400.059
4169,-5,401.587,8.204,401.587,8.204,-35.001,400.059
4169,-4,401.035,8.204,401.035,8.204,-27.975,400.058
4169,-3,400.607,8.204,400.607,8.204,-20.966,400.057
4169,-2,399.727,8.144,399.727,8.144,-13.950,399.483
4169,-1,397.937,8.144,397.937,8.144,-6.945,397.877
4169,0,396.284,8.144,396.284,8.144,0.000,396.284
4169,1,394.764,8.144,394.764,8.144,6.890,394.704
4169,2,393.375,8.144,393.375,8.144,13.729,393.135
4169,3,392.286,8.074,392.286,8.074,20.531,391.748
4169,4,391.528,8.098,391.528,8.098,27.312,390.574
4169,5,390.653,8.054,390.653,8.054,34.048,389.166
4169,6,389.316,8.054,389.316,8.054,40.695,387.183
4169,7,388.257,8.070,388.257,8.070,47.317,385.363
4169,8,387.625,8.070,387.625,8.070,53.947,383.852
4169,9,387.092,8.058,387.092,8.058,60.555,382.326
4169,10,386.677,8.058,386.677,8.058,67.146,380.802
4169,11,386.380,8.058,386.380,8.058,73.725,379.281
4169,12,386.202,8.058,386.202,8.058,80.296,377.762
4169,13,386.141,8.058,386.141,8.058,86.863,376.244
4169,14,386.198,8.058,386.198,8.058,93.430,374.726
4169,15,386.372,8.058,386.372,8.058,100.001,373.207
4169,16,386.665,8.058,386.665,8.058,106.579,371.686
4169,17,385.605,8.080,385.605,8.080,112.740,368.756
4169,18,384.393,8.080,384.393,8.080,118.784,365.579
4169,19,383.305,8.080,383.305,8.080,124.792,362.422
4169,20,382.339,8.080,382.339,8.080,130.768,359.281
4169,21,381.494,8.080,381.494,8.080,136.715,356.155
4169,22,380.769,8.080,380.769,8.080,142.638,353.042
4169,23,378.112,8.058,378.112,8.058,147.740,348.054
4169,24,374.614,8.058,374.614,8.058,152.369,342.227
4169,25,371.292,8.058,371.292,8.058,156.915,336.505
4169,26,368.139,8.058,368.139,8.058,161.382,330.882
4169,27,365.150,8.058,365.150,8.058,165.775,325.351
4169,28,362.248,11.056,362.248,11.056,170.065,319.846
4169,29,359.590,11.017,359.590,11.017,174.333,314.505
4169,30,357.100,11.008,357.100,11.008,178.550,309.258
4169,31,354.670,11.022,354.670,11.022,182.669,304.012
4169,32,352.464,10.898,352.464,10.898,186.777,298.906
4169,33,350.469,10.898,350.469,10.898,190.879,293.928
4169,34,348.603,10.898,348.603,10.898,194.936,289.005
4169,35,346.554,6.519,346.554,6.519,198.775,283.880
4169,36,344.805,6.564,344.805,6.564,202.672,278.953
4169,37,343.226,6.564,343.226,6.564,206.558,274.112
4169,38,341.764,6.564,341.764,6.564,210.411,269.314
4169,39,340.418,6.564,340.418,6.564,214.232,264.555
4169,40,339.186,6.564,339.186,6.564,218.024,259.831
4169,41,338.065,6.564,338.065,6.564,221.791,255.141
4169,42,337.054,6.564,337.054,6.564,225.533,250.480
4169,43,336.151,6.564,336.151,6.564,229.254,245.845
4169,44,335.354,6.564,335.354,6.564,232.957,241.234
4169,45,334.535,11.006,334.535,11.006,236.552,236.552
4705,-45,334.673,5.487,334.673,5.487,-236.649,236.649
4705,-44,336.919,5.487,336.919,5.487,-234.044,242.359
4705,-43,339.300,5.487,339.300,5.487,-231.402,248.148
4705,-42,341.820,5.487,341.820,5.487,-228.722,254.022
4705,-41,344.483,5.487,344.483,5.487,-226.001,259.985
4705,-40,347.294,5.487,347.294,5.487,-223.237,266.043
4705,-39,350.260,5.487,350.260,5.487,-220.426,272.203
4705,-38,353.385,5.487,353.385,5.487,-217.565,278.471
4705,-37,356.676,5.487,356.676,5.487,-214.653,284.854
4705,-36,360.139,5.487,360.139,5.487,-211.684,291.359
4705,-35,363.782,5.487,363.782,5.487,-208.657,297.993
4705,-34,367.613,5.487,367.613,5.487,-205.567,304.765
4705,-33,371.640,5.487,371.640,5.487,-202.410,311.684
4705,-32,375.872,5.487,375.872,5.487,-199.182,318.758
4705,-31,377.159,8.177,377.159,8.177,-194.251,323.288
4705,-30,377.615,8.177,377.615,8.177,-188.807,327.024
4705,-29,378.186,8.177,378.186,8.177,-183.348,330.769
4705,-28,378.876,8.177,378.876,8.177,-177.871,334.527
4705,-27,379.683,8.177,379.683,8.177,-172.373,338.300
4705,-26,380.610,8.177,380.610,8.177,-166.849,342.090
4705,-25,381.659,8.177,381.659,8.177,-161.296,345.900
4705,-24,382.830,8.177,382.830,8.177,-155.711,349.732
4705,-23,384.125,8.177,384.125,8.177,-150.090,353.589
4705,-22,384.860,8.196,384.860,8.196,-144.171,356.836
4705,-21,384.982,8.196,384.982,8.196,-137.965,359.412
4705,-20,385.222,8.196,385.222,8.196,-131.754,361.990
4705,-19,385.579,8.196,385.579,8.196,-125.532,364.572
4705,-18,386.055,8.196,386.055,8.196,-119.298,367.160
4705,-17,386.650,8.196,386.650,8.196,-113.045,369.755
4705,-16,387.365,8.196,387.365,8.196,-106.772,372.359
4705,-15,388.201,8.196,388.201,8.196,-100.474,374.973
4705,-14,389.159,8.196,389.159,8.196,-94.146,377.599
4705,-13,390.241,8.196,390.241,8.196,-87.785,380.240
4705,-12,391.449,8.196,391.449,8.196,-81.387,382.895
4705,-11,392.785,8.196,392.785,8.196,-74.947,385.568
4705,-10,394.250,8.196,394.250,8.196,-68.461,388.260
4705,-9,395.847,8.196,395.847,8.196,-61.924,390.973
4705,-8,397.579,8.196,397.579,8.196,-55.332,393.709
4705,-7,399.448,8.196,399.448,8.196,-48.680,396.470
4705,-6,401.457,8.196,401.457,8.196,-41.964,399.258
4705,-5,401.778,8.037,401.778,8.037,-35.017,400.250
4705,-4,401.169,8.037,401.169,8.037,-27.984,400.192
4705,-3,400.684,8.037,400.684,8.037,-20.970,400.135
4705,-2,400.322,8.037,400.322,8.037,-13.971,400.078
4705,-1,400.082,8.037,400.082,8.037,-6.982,400.021
4705,0,399.964,8.037,399.964,8.037,0.000,399.964
4705,1,399.968,8.037,399.968,8.037,6.980,399.907
4705,2,400.094,8.037,400.094,8.037,13.963,399.850
4705,3,400.342,8.037,400.342,8.037,20.952,399.793
4705,4,400.712,8.037,400.712,8.037,27.952,399.736
4705,5,401.206,8.037,401.206,8.037,34.967,399.679
4705,6,401.072,8.073,401.072,8.073,41.923,398.875
4705,7,398.399,8.073,398.399,8.073,48.553,395.430
4705,8,395.882,8.073,395.882,8.073,55.096,392.030
4705,9,393.516,8.073,393.516,8.073,61.559,388.671
4705,10,391.296,8.073,391.296,8.073,67.948,385.351
4705,11,389.219,8.073,389.219,8.073,74.267,382.068
4705,12,387.281,8.073,387.281,8.073,80.520,378.818
4705,13,385.480,8.073,385.480,8.073,86.714,375.600
4705,14,383.811,8.073,383.811,8.073,92.852,372.410
4705,15,382.525,15.543,382.525,15.543,99.005,369.491
4705,16,381.113,15.543,381.113,15.543,105.049,366.349
4705,17,379.826,15.543,379.826,15.543,111.050,363.230
4705,18,378.663,15.543,378.663,15.543,117.013,360.130
4705,19,377.622,15.543,377.622,15.543,122.942,357.048
4705,20,376.701,15.543,376.701,15.543,128.839,353.983
4705,21,375.898,15.543,375.898,15.543,134.710,350.931
4705,22,375.213,15.543,375.213,15.543,140.557,347.892
4705,23,372.973,15.559,372.973,15.559,145.732,343.323
4705,24,369.226,15.559,369.226,15.559,150.178,337.305
4705,25,365.664,15.559,365.664,15.559,154.536,331.404
4705,26,362.279,15.559,362.279,15.559,158.813,325.614
4705,27,359.065,15.559,359.065,15.559,163.012,319.929
4705,28,356.014,15.559,356.014,15.559,167.139,314.342
4705,29,353.122,15.559,353.122,15.559,171.197,308.848
4705,30,350.382,15.559,350.382,15.559,175.191,303.440
4705,31,347.790,15.559,347.790,15.559,179.125,298.114
4705,32,345.340,15.559,345.340,15.559,183.002,292.865
4705,33,343.028,15.559,343.028,15.559,186.827,287.688
4705,34,340.850,15.559,340.850,15.559,190.601,282.578
4705,35,338.802,15.559,338.802,15.559,194.329,277.531
4705,36,336.881,15.559,336.881,15.559,198.014,272.542
4705,37,335.082,15.559,335.082,15.559,201.658,267.609
4705,38,333.404,15.559,333.404,15.559,205.264,262.726
4705,39,331.843,15.559,331.843,15.559,208.836,257.891
4705,40,330.397,15.559,330.397,15.559,212.375,253.099
4705,41,329.063,15.559,329.063,15.559,215.885,248.347
4705,42,327.840,15.559,327.840,15.559,219.368,243.632
4705,43,326.724,15.559,326.724,15.559,222.825,238.951
4705,44,325.715,15.559,325.715,15.559,226.261,234.300
4705,45,324.811,15.559,324.811,15.559,229.676,229.676
5325,-45,328.201,8.032,328.201,8.032,-232.073,232.073
5325,-44,332.157,8.032,332.157,8.032,-230.735,238.933
5325,-43,336.312,8.032,336.312,8.032,-229.364,245.963
5325,-42,340.678,8.032,340.678,8.032,-227.958,253.173
5325,-41,345.266,8.032,345.266,8.032,-226.515,260.575
5325,-40,350.087,8.032,350.087,8.032,-225.031,268.182
5325,-39,355.154,8.032,355.154,8.032,-223.505,276.006
5325,-38,360.481,8.032,360.481,8.032,-221.934,284.063
5325,-37,366.084,8.032,366.084,8.032,-220.315,292.367
5325,-36,371.978,8.032,371.978,8.032,-218.643,300.937
5325,-35,371.649,8.016,371.649,8.016,-213.169,304.437
5325,-34,371.130,8.016,371.130,8.016,-207.533,307.681
5325,-33,370.725,8.016,370.725,8.016,-201.911,310.916
5325,-32,370.434,8.016,370.434,8.016,-196.300,314.146
5325,-31,370.256,8.016,370.256,8.016,-190.696,317.372
5325,-30,370.191,8.016,370.191,8.016,-185.096,320.595
5325,-29,370.239,8.016,370.239,8.016,-179.496,323.819
5325,-28,370.400,8.016,370.400,8.016,-173.892,327.044
5325,-27,370.674,8.016,370.674,8.016,-168.282,330.273
5325,-26,371.061,8.016,371.061,8.016,-162.662,333.507
5325,-25,371.562,8.016,371.562,8.016,-157.029,336.750
5325,-24,372.179,8.016,372.179,8.016,-151.379,340.002
5325,-23,372.911,8.016,372.911,8.016,-145.708,343.266
5325,-22,373.760,8.016,373.760,8.016,-140.013,346.544
5325,-21,374.727,8.016,374.727,8.016,-134.290,349.838
5325,-20,375.814,8.016,375.814,8.016,-128.536,353.150
5325,-19,377.023,8.016,377.023,8.016,-122.747,356.482
5325,-18,380.533,8.025,380.533,8.025,-117.591,361.908
5325,-17,387.133,6.359,387.133,6.359,-113.187,370.217
5325,-16,388.758,6.359,388.758,6.359,-107.156,373.698
5325,-15,390.517,6.359,390.517,6.359,-101.073,377.210
5325,-14,392.411,6.359,392.411,6.359,-94.933,380.755
5325,-13,394.445,6.359,394.445,6.359,-88.731,384.335
5325,-12,396.621,6.359,396.621,6.359,-82.462,387.954
5325,-11,398.944,6.359,398.944,6.359,-76.122,391.614
5325,-10,401.417,6.359,401.417,6.359,-69.705,395.319
5325,-9,404.045,6.359,404.045,6.359,-63.207,399.070
5325,-8,406.832,6.359,406.832,6.359,-56.620,402.873
5325,-7,409.376,8.003,409.376,8.003,-49.890,406.324
5325,-6,408.545,8.003,408.545,8.003,-42.705,406.307
5325,-5,407.842,8.003,407.842,8.003,-35.546,406.290
5325,-4,407.265,8.003,407.265,8.003,-28.409,406.273
5325,-3,406.813,8.003,406.813,8.003,-21.291,406.256
5325,-2,406.486,8.003,406.486,8.003,-14.186,406.239
5325,-1,406.284,8.003,406.284,8.003,-7.091,406.222
5325,0,406.205,8.003,406.205,8.003,0.000,406.205
5325,1,406.250,8.003,406.250,8.003,7.090,406.188
5325,2,406.418,8.003,406.418,8.003,14.184,406.171
5325,3,406.711,8.003,406.711,8.003,21.286,406.154
5325,4,407.128,8.003,407.128,8.003,28.400,406.137
5325,5,407.671,8.003,407.671,8.003,35.531,406.120
5325,6,408.339,8.003,408.339,8.003,42.683,406.102
5325,7,409.135,8.003,409.135,8.003,49.861,406.085
5325,8,410.059,8.003,410.059,8.003,57.069,406.068
5325,9,410.688,6.329,410.688,6.329,64.246,405.632
5325,10,405.596,6.329,405.596,6.329,70.431,399.434
5325,11,400.749,6.329,400.749,6.329,76.466,393.386
5325,12,396.135,6.329,396.135,6.329,82.361,387.479
5325,13,391.745,6.329,391.745,6.329,88.123,381.704
5325,14,387.567,6.329,387.567,6.329,93.761,376.055
5325,15,383.594,6.329,383.594,6.329,99.281,370.523
5325,16,379.815,6.329,379.815,6.329,104.691,365.102
5325,17,376.224,6.329,376.224,6.329,109.997,359.785
5325,18,372.812,6.329,372.812,6.329,115.205,354.566
5325,19,374.023,6.381,374.023,6.381,121.770,353.646
5325,20,377.753,6.381,377.753,6.381,129.199,354.972
5325,21,379.241,9.804,379.241,9.804,135.908,354.052
5325,22,376.366,9.804,376.366,9.804,140.989,348.961
5325,23,373.648,9.804,373.648,9.804,145.996,343.945
5325,24,371.082,9.804,371.082,9.804,150.932,339.000
5325,25,368.661,9.804,368.661,9.804,155.803,334.121
5325,26,366.383,9.804,366.383,9.804,160.612,329.303
5325,27,364.244,9.804,364.244,9.804,165.363,324.543
5325,28,359.542,7.973,359.542,7.973,168.795,317.456
5325,29,357.695,7.973,357.695,7.973,173.414,312.847
5325,30,355.975,7.973,355.975,7.973,177.987,308.283
5325,31,354.378,7.973,354.378,7.973,182.518,303.762
5325,32,352.903,7.973,352.903,7.973,187.010,299.279
5325,33,351.547,7.973,351.547,7.973,191.466,294.832
5325,34,350.308,7.973,350.308,7.973,195.890,290.418
5325,35,349.183,7.973,349.183,7.973,200.283,286.034
5325,36,348.171,7.973,348.171,7.973,204.650,281.677
5325,37,347.271,7.973,347.271,7.973,208.993,277.343
5325,38,346.480,7.973,346.480,7.973,213.315,273.030
5325,39,345.799,7.973,345.799,7.973,217.618,268.736
5325,40,342.690,8.009,342.690,8.009,220.277,262.515
5325,41,337.952,8.009,337.952,8.009,221.716,255.056
5325,42,333.444,8.009,333.444,8.009,223.117,247.797
5325,43,329.153,8.009,329.153,8.009,224.482,240.727
5325,44,325.069,8.009,325.069,8.009,225.812,233.835
5325,45,321.182,8.009,321.182,8.009,227.110,227.110
2536,-45,328.451,8.506,328.451,8.506,-232.25,232.25
2536,-44,329.369,8.506,329.369,8.506,-228.799,236.928
2536,-43,330.335,8.819,330.335,8.819,-225.288,241.592
2536,-42,331.303,8.819,331.303,8.819,-221.685,246.206
2536,-41,332.378,8.819,332.378,8.819,-218.06,250.849
2536,-40,333.563,8.819,333.563,8.819,-214.41,255.524
2536,-39,334.858,8.819,334.858,8.819,-210.733,260.233
2536,-38,336.266,8.819,336.266,8.819,-207.026,264.981
2536,-37,337.789,8.819,337.789,8.819,-203.286,269.77
2536,-36,339.429,8.819,339.429,8.819,-199.512,274.604
2536,-35,341.191,8.819,341.191,8.819,-195.699,279.487
2536,-34,343.076,8.819,343.076,8.819,-191.845,284.423
2536,-33,345.087,8.819,345.087,8.819,-187.948,289.414
2536,-32,347.228,8.819,347.228,8.819,-184.003,294.466
2536,-31,349.504,8.819,349.504,8.819,-180.008,299.583
2536,-30,351.917,8.819,351.917,8.819,-175.959,304.769
2536,-29,354.473,8.819,354.473,8.819,-171.852,310.029
2536,-28,357.175,8.819,357.175,8.819,-167.684,315.367
2536,-27,360.03,8.819,360.03,8.819,-163.45,320.789
2536,-26,363.042,8.819,363.042,8.819,-159.147,326.3
2536,-25,366.217,8.819,366.217,8.819,-154.77,331.905
2536,-24,369.562,8.819,369.562,8.819,-150.314,337.612
2536,-23,373.084,8.819,373.084,8.819,-145.775,343.425
2536,-22,376.245,8.609,376.245,8.609,-140.944,348.848
2536,-21,378.24,8.609,378.24,8.609,-135.549,353.117
2536,-20,380.166,8.623,380.166,8.623,-130.024,357.239
2536,-19,381.944,8.623,381.944,8.623,-124.349,361.135
2536,-18,383.752,8.647,383.752,8.647,-118.586,364.97
2536,-17,385.206,8.647,385.206,8.647,-112.623,368.374
2536,-16,386.788,8.647,386.788,8.647,-106.613,371.805
2536,-15,388.503,8.647,388.503,8.647,-100.552,375.265
2536,-14,389.887,8.635,389.887,8.635,-94.322,378.306
2536,-13,391.282,8.635,391.282,8.635,-88.019,381.254
2536,-12,392.573,8.674,392.573,8.674,-81.621,383.995
2536,-11,393.79,8.674,393.79,8.674,-75.139,386.555
2536,-10,394.734,8.584,394.734,8.584,-68.545,388.738
2536,-9,395.686,8.487,395.686,8.487,-61.899,390.814
2536,-8,396.51,8.571,396.51,8.571,-55.184,392.651
2536,-7,397.185,8.494,397.185,8.494,-48.405,394.224
2536,-6,397.791,8.494,397.791,8.494,-41.58,395.612
2536,-5,398.521,8.494,398.521,8.494,-34.733,397.004
2536,-4,399.138,8.648,399.138,8.648,-27.842,398.166
2536,-3,399.598,8.43,399.598,8.43,-20.913,399.05
2536,-2,399.703,8.43,399.703,8.43,-13.949,399.46
2536,-1,399.713,8.365,399.713,8.365,-6.976,399.652
2536,0,399.716,8.492,399.716,8.492,0,399.716
2536,1,399.571,8.462,399.571,8.462,6.973,399.51
2536,2,398.948,8.565,398.948,8.565,13.923,398.705
2536,3,397.938,8.622,397.938,8.622,20.826,397.393
2536,4,396.434,8.622,396.434,8.622,27.654,395.469
2536,5,394.5,12.705,394.5,12.705,34.383,392.999
2536,6,390.813,12.571,390.813,12.571,40.851,388.672
2536,7,387.472,12.365,387.472,12.365,47.221,384.584
2536,8,384.144,12.435,384.144,12.435,53.462,380.405
2536,9,381.106,12.489,381.106,12.489,59.618,376.414
2536,10,378.092,12.503,378.092,12.503,65.655,372.348
2536,11,375.502,12.326,375.502,12.326,71.649,368.603
2536,12,372.989,12.464,372.989,12.464,77.549,364.838
2536,13,370.567,12.412,370.567,12.412,83.359,361.069
2536,14,368.29,12.538,368.29,12.538,89.097,357.35
2536,15,366.248,12.519,366.248,12.519,94.792,353.768
2536,16,364.214,12.397,364.214,12.397,100.391,350.105
2536,17,362.188,12.346,362.188,12.346,105.894,346.363
2536,18,360.533,12.547,360.533,12.547,111.411,342.887
2536,19,358.903,12.547,358.903,12.547,116.847,339.349
2536,20,357.396,12.547,357.396,12.547,122.237,335.842
2536,21,356.009,12.547,356.009,12.547,127.582,332.363
2536,22,354.741,12.547,354.741,12.547,132.888,328.91
2536,23,352.698,12.105,352.698,12.105,137.81,324.661
2536,24,350.474,12.179,350.474,12.179,142.55,320.174
2536,25,348.218,12.179,348.218,12.179,147.163,315.593
2536,26,346.097,12.136,346.097,12.136,151.719,311.07
2536,27,344.104,12.294,344.104,12.294,156.22,306.599
2536,28,342.23,12.294,342.23,12.294,160.667,302.171
2536,29,340.48,12.294,340.48,12.294,165.068,297.79
2536,30,338.85,12.294,338.85,12.294,169.425,293.453
2536,31,337.299,12.239,337.299,12.239,173.722,289.122
2536,32,335.865,12.239,335.865,12.239,177.981,284.829
2536,33,334.544,12.239,334.544,12.239,182.206,280.572
2536,34,333.322,12.345,333.322,12.345,186.391,276.337
2536,35,332.204,12.345,332.204,12.345,190.544,272.125
2536,36,331.194,12.345,331.194,12.345,194.671,267.941
2536,37,330.381,12.172,330.381,12.172,198.828,263.854
2536,38,329.675,12.172,329.675,12.172,202.968,259.787
2536,39,329.031,12.304,329.031,12.304,207.066,255.705
2536,40,328.459,12.304,328.459,12.304,211.129,251.614
2536,41,327.988,12.304,327.988,12.304,215.18,247.536
2536,42,327.619,12.304,327.619,12.304,219.22,243.468
2536,43,327.35,12.304,327.35,12.304,223.252,239.409
2536,44,327.216,12.31,327.216,12.31,227.303,235.379
2536,45,327.246,12.31,327.246,12.31,231.398,231.398`;

const toRow = (d: any) => ({
  venueId: +d.venueid,
  angle: +d.deg,
  x: +d.x_hr_line,
  y: +d.y_hr_line,
  z: +d.height_top_of_wall,
  distance: +d.distance_top_of_wall,
  height: +d.height_top_of_wall,
  hrLineDistance: +d.distance_hr_line,
  hrLineHeight: +d.height_hr_line,
});

export const fencesLidar = csvParse(csv, toRow);

export const fenceMap = _mapValues(_groupBy(fencesLidar, "venueId"), (points) =>
  _orderBy(points, "angle")
);

fenceMap[3140] = poloGroundsFencePoints;
