export const venues = [
  {
    venueId: 15,
    abbrev: "ARI",
  },
  {
    venueId: 4705,
    abbrev: "ATL",
  },
  {
    venueId: 2,
    abbrev: "BAL",
  },
  {
    venueId: 3,
    abbrev: "BOS",
  },
  {
    venueId: 2756,
    abbrev: "BUF",
  },
  {
    venueId: 17,
    abbrev: "CHC",
  },
  {
    venueId: 2602,
    abbrev: "CIN",
  },
  {
    venueId: 5,
    abbrev: "CLE",
  },
  {
    venueId: 19,
    abbrev: "COL",
  },
  {
    venueId: 4,
    abbrev: "CWS",
  },
  {
    venueId: 2394,
    abbrev: "DET",
  },
  {
    venueId: 2536,
    abbrev: "DUN",
  },
  {
    venueId: 2392,
    abbrev: "HOU",
  },
  {
    venueId: 7,
    abbrev: "KCR",
  },
  {
    venueId: 1,
    abbrev: "LAA",
  },
  {
    venueId: 22,
    abbrev: "LAD",
  },
  {
    venueId: 4169,
    abbrev: "MIA",
  },
  {
    venueId: 32,
    abbrev: "MIL",
  },
  {
    venueId: 3312,
    abbrev: "MIN",
  },
  {
    venueId: 3289,
    abbrev: "NYM",
  },
  {
    venueId: 3313,
    abbrev: "NYY",
  },
  {
    venueId: 10,
    abbrev: "OAK",
  },
  {
    venueId: 2681,
    abbrev: "PHI",
  },
  {
    venueId: 31,
    abbrev: "PIT",
  },
  {
    venueId: 2680,
    abbrev: "SDP",
  },
  {
    venueId: 680,
    abbrev: "SEA",
  },
  {
    venueId: 2395,
    abbrev: "SFG",
  },
  {
    venueId: 2889,
    abbrev: "STL",
  },
  {
    venueId: 12,
    abbrev: "TBR",
  },
  {
    // venueId: 13,
    venueId: 5325,
    abbrev: "TEX",
  },
  {
    venueId: 14,
    abbrev: "TOR",
  },
  {
    venueId: 3309,
    abbrev: "WSH",
  },
  {
    venueId: 3140,
    abbrev: "PG",
  },
];
