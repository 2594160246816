import React from "react";
import * as THREE from "three";
import { HALF_PI } from "../../constants/math";
import { poleColor } from "../../constants/colors";

const poleRadius = 0.5;

interface Props {
  points: any[];
  color?: string | number;
}

export const PolesGroup: React.FC<Props> = ({ points, color = poleColor }) => {
  let leftPoint = points[0];
  let rightPoint = points[1];
  let leftVector = new THREE.Vector2(-1, 1)
    .normalize()
    .multiplyScalar(leftPoint.distance)
    // .add(new THREE.Vector2(.5* poleRadius, .5 * poleRadius))
    .add(new THREE.Vector2(-0.5 * poleRadius, 2.5 * poleRadius));
  let rightVector = new THREE.Vector3(1, 1, 0)
    .normalize()
    .multiplyScalar(rightPoint.distance)
    // .add(new THREE.Vector2(-.5* poleRadius, .5 * poleRadius))
    .add(new THREE.Vector3(0.5 * poleRadius, 2.5 * poleRadius, 0));

  return (
    <group name="poles">
      <mesh
        name="left-foul-pole"
        rotation={[HALF_PI, 0, 0]}
        position={[leftVector.x, leftVector.y, leftPoint.z]}
      >
        <cylinderBufferGeometry
          attach="geometry"
          args={[poleRadius, poleRadius, 2 * leftPoint.z, 16]}
        />
        <meshStandardMaterial attach="material" color={color} />
      </mesh>
      <mesh
        name="right-foul-pole"
        rotation={[HALF_PI, 0, 0]}
        position={[rightVector.x, rightVector.y, rightPoint.z]}
      >
        <cylinderBufferGeometry
          attach="geometry"
          args={[poleRadius, poleRadius, 2 * rightPoint.z, 16]}
        />
        <meshStandardMaterial attach="material" color={color} />
      </mesh>
    </group>
  );
};
