import React from "react";
import _find from "lodash/find";
import { RefPoint } from "./flyCamTween";

const radius = 0.5; // 1' diameter

interface KeyframeMarksProps {
  refPoints: RefPoint[];
  startLookAtY: number;
  startLookAtZ: number;
}

export const ReferenceMarks = ({
  refPoints,
  startLookAtY,
  startLookAtZ,
}: KeyframeMarksProps) => {
  let pLaunch = _find(refPoints, { name: "launchBallPos" })?.p;
  let rampEndpoint = _find(refPoints, { name: "rampEndpoint" })?.p;
  let pMidpoint = _find(refPoints, { name: "midpointBallPos" })?.p;
  let pRest = _find(refPoints, { name: "restBallPos" })?.p;

  return (
    <group>
      {pLaunch && (
        <mesh position={[pLaunch.x, pLaunch.z, -pLaunch.y]}>
          <sphereBufferGeometry attach="geometry" args={[radius, 16, 8]} />
          <meshStandardMaterial
            attach="material"
            color="#0f0"
            transparent
            opacity={0.6}
          />
        </mesh>
      )}

      {rampEndpoint && (
        <mesh position={[rampEndpoint.x, rampEndpoint.z, -rampEndpoint.y]}>
          <sphereBufferGeometry attach="geometry" args={[radius, 16, 8]} />
          <meshStandardMaterial
            attach="material"
            color="#060"
            transparent
            opacity={0.7}
          />
        </mesh>
      )}

      {pMidpoint && (
        <mesh position={[pMidpoint.x, pMidpoint.z, -pMidpoint.y]}>
          <sphereBufferGeometry attach="geometry" args={[radius, 16, 8]} />
          <meshStandardMaterial
            attach="material"
            color="#00f"
            transparent
            opacity={0.6}
          />
        </mesh>
      )}

      {pRest && (
        <mesh position={[pRest.x, pRest.z, -pRest.y]}>
          <sphereBufferGeometry attach="geometry" args={[radius, 16, 8]} />
          <meshStandardMaterial
            attach="material"
            color="#f00"
            transparent
            opacity={0.6}
          />
        </mesh>
      )}

      <mesh position={[0, startLookAtZ, -startLookAtY]}>
        <sphereBufferGeometry attach="geometry" args={[0.5 * radius, 16, 8]} />
        <meshStandardMaterial
          attach="material"
          color="#f0f"
          transparent
          opacity={0.8}
        />
      </mesh>
    </group>
  );
};
