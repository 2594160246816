import React, { useMemo } from "react";
import { MeshProps } from "@react-three/fiber";
import { HALF_PI } from "../../constants/math";

const padColor = "#FFC0CB";

export const defaultPadRadius = (0.5 * 2.9) / 12;

interface Props extends MeshProps {
  color?: any;
  radius?: number;
}

export const BallPad = (props: Props) => {
  let { color = "#FFC0CB", radius = defaultPadRadius, ...otherProps } = props;
  let material = useMemo(
    () => (
      <meshStandardMaterial
        attach="material"
        transparent={true}
        opacity={0.5}
        color={color || padColor}
      />
    ),
    [color]
  );

  return (
    <mesh {...otherProps} rotation-x={HALF_PI}>
      <cylinderBufferGeometry
        attach="geometry"
        args={[radius, radius, 0.0075, 64]}
      />
      {material}
    </mesh>
  );
};
