import React from "react";
//@ts-ignore
import Device from "device";
import { EffectComposer, DepthOfField } from "@react-three/postprocessing";

interface Props {
  useEffectsStore: any;
}

const device = new Device();

export const Effects = (props: Props) => {
  const { useEffectsStore } = props;

  const {
    depthOfFieldEnabled,
    focusDistance,
    focalLength,
    bokehScale,
  } = useEffectsStore();

  const effects = (
    <React.Fragment>
      {depthOfFieldEnabled ? (
        <DepthOfField
          focusDistance={focusDistance}
          focalLength={focalLength}
          bokehScale={bokehScale}
        />
      ) : null}
    </React.Fragment>
  );

  return !(device.is.ios || device.is.safari) ? (<EffectComposer>{effects}</EffectComposer>) : null;
};
