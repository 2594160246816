import React, { useMemo } from "react";
import * as THREE from "three";
import { pairs as d3_pairs } from "d3-array";
import { Vector3 } from "three";

const toSegments = (points: any) => {
  let segments = [];
  let segment: any[] = [];
  let z: number | null = null;

  points.forEach((p: any) => {
    if (p.z === z) {
      segment.push(p);
    } else {
      if (segment.length > 0) {
        segments.push(segment);
      }
      segment = [p];
      z = p.z;
    }
  });
  if (segment.length > 0) {
    segments.push(segment);
  }

  return segments;
};

const toCappedSegments = (segments: any[]) => {
  let copy = [...segments];

  let segmentPairs = d3_pairs(copy);

  segmentPairs.forEach((pair) => {
    let segment0 = pair[0];
    let segment1 = pair[1];
    let p = segment0[segment0.length - 1];
    let q = segment1[0];
    let x = 0.5 * (p.x + q.x);
    let y = 0.5 * (p.y + q.y);
    segment0.push({
      x,
      y,
      z: p.z,
    });
    segment1.unshift({
      x,
      y,
      z: q.z,
    });
  });

  return copy;
};

const addFenceVertices = ({
  vertices,
  points
}: {
  vertices: Vector3[];
  points: {
    venueId: number,
    angles: number,
    x: number,
    y: number,
    z: number
  }[];
}) => {
  let pointPairs = d3_pairs(points);

  pointPairs.forEach((pair) => {
    vertices.push(
      new THREE.Vector3(pair[0].x, pair[0].y, 3* pair[0].z),
      new THREE.Vector3(pair[0].x, pair[0].y, pair[0].z + .25),
      new THREE.Vector3(pair[1].x, pair[1].y, 3 * pair[1].z),

      new THREE.Vector3(pair[1].x, pair[1].y, 3 * pair[1].z),
      new THREE.Vector3(pair[0].x, pair[0].y, pair[0].z + .25),
      new THREE.Vector3(pair[1].x, pair[1].y, pair[1].z + .25),
    );
  });
};

const toFenceGeometry = ({ points }: {
  points: {
    venueId: number,
    angles: number,
    x: number,
    y: number,
    z: number
  }[];
  squaredSegmentJoins: boolean;
}) => {
  let segments = toSegments(points);
  let geometry = new THREE.BufferGeometry();
  const vertices: Vector3[] = [];

  let cappedSegments = toCappedSegments(segments);

  cappedSegments.forEach((segment) => {
    addFenceVertices({ vertices, points: segment });
  });

  geometry.setFromPoints(vertices);
  geometry.computeVertexNormals();

  return geometry;
};

interface Props {
  points: any[];
  material: any;
  squaredSegmentJoins?: boolean;
}

export const FenceMate = ({
  points,
  material,
  squaredSegmentJoins = true,
}: Props) => {
  let {
    // type,
    color,
    // emissive,
    // metalness,
    // roughness,
    // wireframe,
    // wireframeLinewidth,
    transparent = false,
    opacity = 1
  } = material;
  let geometry = useMemo(
    () => toFenceGeometry({ points, squaredSegmentJoins }),
    [points, squaredSegmentJoins]
  );

  // TODOHI  why does using meshStandardMaterial here affect the plate rendering?
  return (
    <mesh name="fence" geometry={geometry}>
      <meshLambertMaterial
        attach="material"
        color={color}
        transparent={transparent}
        opacity={opacity}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};
