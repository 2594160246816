import React from "react";
import { toBatterLabel, toPitcherLabel, toHalfInningLabel } from "../../util";
import { StatsApiNS } from "field-of-things/src/types";
import {
  getGumboPlay,
  getGameDate,
  toAbbrevTeamAtTeam,
} from "field-of-things/src/util/gumbo-util";

interface IPlaySummaryProps {
  playData: StatsApiNS.PlayData;
  gumbo: any;
}

export const PlayLabel = ({ playData, gumbo }: IPlaySummaryProps) => {
  const { playId } = playData;
  const gumboPlay = gumbo && getGumboPlay({ gumbo, playId });

  let text = "---";
  let style = {
    cursor: "pointer",
  };

  if (gumboPlay) {
    let { matchup } = gumboPlay;
    let halfInningLabel = gumboPlay ? toHalfInningLabel(gumboPlay) : "---";
    let pitcherLabel = toPitcherLabel(matchup);
    let batterLabel = toBatterLabel(matchup);
    let description = playData.metaData.stat.play.details.call.description;
    text = `${getGameDate(gumbo)} -- ${toAbbrevTeamAtTeam(
      gumbo
    )} -- ${halfInningLabel} ${pitcherLabel} ${batterLabel} – ${description}`;
  }

  return <div style={style}>{text}</div>;
};
