import { useRef, useMemo } from "react";
import _find from "lodash/find";
import { Vector3 } from "three";
import { timeToFrame } from "../../util/tracking-util";
import { toBallPointSeries, toPointInSeries } from "../../util/polynomial-util";
import { toReleaseTime } from "../../util/time-util";

// TODO    switch to r3f's camera rig model? would require broader refactoring
// https://github.com/pmndrs/react-three-fiber/blob/master/markdown/recipes.md#using-your-own-camera-rig

export interface IFigureSideCamParams {
  positionId: number;
  pivotType: string;
  pivotName: string;
  pivot: any;
  distance: number;
  cameraHeight: number;
  lookAtHeight: number;
}

interface Props {
  camera: any;
  playData: any;
  playTracking: any;
  time: number;
  figureSideCamParams: IFigureSideCamParams;
}

export const FigureSideCamera = (props: Props) => {
  let { camera, playData, playTracking, time, figureSideCamParams } = props;
  let {
    positionId,
    pivotType,
    pivot,
    distance,
    cameraHeight,
    lookAtHeight,
  } = figureSideCamParams;
  // create scratch vector objects once and reuse
  let vPivotRef = useRef(new Vector3());
  let vFigureRef = useRef(new Vector3());
  let vSideRef = useRef(new Vector3());
  let vCameraRef = useRef(new Vector3());
  let vPivot = vPivotRef.current;
  let vFigure = vFigureRef.current;
  let vSide = vSideRef.current;
  let vCamera = vCameraRef.current;
  let frame = timeToFrame({ time, playTracking });
  let figure = _find(frame.positions, { positionId });

  let segments = useMemo(() => playData?.ballSegments?.genericSegments || [], [
    playData?.ballSegments?.genericSegments,
  ]);
  let series = useMemo(() => toBallPointSeries({ segments }), [segments]);
  let releaseTime = toReleaseTime({ playData });

  if (!figure) {
    return null;
  }

  let { location } = figure;
  let p = [location.x, location.y, location.z];
  vFigure.set(p[0], p[1], 0); // mlb coords

  switch (pivotType) {
    case "Location":
      vPivot.set(pivot.x, pivot.y, 0);
      break;
    case "Player":
      let player = _find(frame.positions, { positionId: pivot });
      if (!player) {
        console.log("No such player");
        break;
      }
      let { location } = player;
      let pl = [location.x, location.y, location.z];
      vPivot.set(pl[0], pl[1], 0);
      break;
    case "Ball":
      let releaseRelativeTimeSec = (time - releaseTime) / 1000;
      let ballPoint = toPointInSeries({ t: releaseRelativeTimeSec, series });
      vPivot.set(ballPoint.x, ballPoint.y, 0);
      break;
    default:
      vPivot.set(0, 30, 0); //Mid-Pitch default
      break;
  }

  // extrapolate from vPivot through vFigure to vCamera
  vSide.subVectors(vFigure, vPivot).normalize();
  vCamera.copy(vFigure).addScaledVector(vSide, distance);
  vCamera.setZ(cameraHeight);

  // set camera position and lookAt, converting to gl coords
  camera.position.set(vCamera.x, vCamera.z, -vCamera.y);
  camera.lookAt(p[0], lookAtHeight, -p[1]);
  camera.up.set(0, 1, 0);
  camera.updateMatrix();

  return null;
};
