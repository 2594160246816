import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import _ from "lodash";
import { StatsApiNS } from "field-of-things/src/types";

interface Props {
  data: StatsApiNS.FigureFrameTimeSeries[];
  skeleton: { [key: string]: string };
}

const presentColor = "#fff";
const absentColor = "#000";
const svgScaleFactor = 3;
const toAllJointIndexes = (skeleton: { [key: string]: string }) =>
  _.uniq(_.flatten(_.keys(skeleton)));

const toJointData = (
  data: StatsApiNS.FigureFrameTimeSeries[],
  jointIndex: string,
  skeleton: { [key: string]: string }
) => {
  return data.map((frame) => {
    const { figure, timeStamp } = frame;
    const joints = figure !== undefined ? figure.joints : null;
    const point = joints
      ? joints.find((joint) => joint.id === parseInt(jointIndex))
      : null;
    const isJointPresent = !_.isNil(point);

    return {
      joint: skeleton[jointIndex],
      timeStamp,
      point,
      isJointPresent,
    };
  });
};

const appendJointTimeline = (g: any, data: any, y: any, height: any) => {
  g.selectAll("rect")
    .data(data)
    .enter()
    .append("rect")
    .attr("x", (d: any, i: any) => {
      return i * svgScaleFactor;
    })
    .attr("y", y)
    .attr("width", 1)
    .attr("height", height)
    .style("fill", (d: any) => (d.isJointPresent ? presentColor : absentColor));
};

const jointNameColumnWidth = 100;
const rowHeight = 16;

export const JointTimeline = ({ data, skeleton }: Props) => {
  const labelSide = "left";
  //@ts-ignore
  const svgRef: React.MutableRefObject<SVGAElement> = useRef();
  // TODOHI  need to ensure skeleton is not filtered?

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const jointList = toAllJointIndexes(skeleton);
    const jointData = jointList.map((joint) =>
      toJointData(data, joint, skeleton)
    );
    const rowCount = jointData.length;
    const frameNumber = data.length;
    const width = frameNumber * svgScaleFactor + jointNameColumnWidth;
    const height = rowCount * rowHeight;
    const tx = jointNameColumnWidth;
    const labelX = -5;
    const textAnchor = "end";

    svg.attr("width", width).attr("height", height).attr("align", "center");

    // Clear out previous joint data
    svg.selectAll("g.joint").remove();

    const gJoint = svg
      .selectAll("g.joint")
      .data(jointData)
      .enter()
      .append("g")
      .classed("joint", true)
      .attr("data-joint", (d) => d[0].joint)
      .attr("transform", (d, i) => `translate(${tx}, ${i * rowHeight})`);

    gJoint.each((d, i, nodes) => {
      const g = d3.select(nodes[i]);

      // add joint label
      g.append("text")
        .text(d[0].joint)
        .attr("text-anchor", textAnchor)
        .attr("x", labelX)
        .attr("y", rowHeight - 2)
        .style("fill", "#aaa")
        .style("font-size", 12);

      // add joint timeline
      appendJointTimeline(g, d, 1, rowHeight - 2);
    });
  }, [data, labelSide, skeleton]);
  return (
    //@ts-ignore
    <svg ref={svgRef} />
  );
};
