import React, { useEffect, useState, useMemo } from "react";

import _isEmpty from "lodash/isEmpty"

import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Switch from "@material-ui/core/Switch";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { TabPanel } from "./Knobs";

import { Vector3 } from "three";

import { UseStore } from "zustand";
import { CameraStore } from "field-of-things/src/stores/cameraStore";
import { FigureAppearanceStore } from "field-of-things/src/stores/figureAppearanceStore";
import { FigurePoseStore, JointsLengthType } from "field-of-things/src/stores/figurePoseStore";

import { allPositions } from "field-of-things/src/models/positions";

import { boneLengthWindow } from "field-of-things/src/util/tracking-data-util";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "block",
    paddingBottom: 15,
  },
  table: {
    minWidth: 120,
  },
}));

const dispPrec = 3;

interface Props {
  value: any;
  index: number;
  useFigureAppearanceStore: UseStore<FigureAppearanceStore>;
  useFigurePoseStore: UseStore<FigurePoseStore>;
  useCameraStore: UseStore<CameraStore>;
  trackingData: any;
}

export const FigurePoseKnobsPanel = (props: Props) => {
  let {
    index,
    value,
    useFigureAppearanceStore,
    useFigurePoseStore,
    useCameraStore,
    trackingData,
  } = props;

  const {
    setVisiblePositionIds,
  } = useFigureAppearanceStore();

  const {
    poseModeEnabled,
    setPoseModeEnabled,
    posedPositionId,
    setPosedPositionId,
    centralizePoints,
    setCentralizePoints,
    riggedBoneLengths,
    rawBoneLengths,
    setRawBoneLengths,
    riggedPoints,
    rawPoints,
  } = useFigurePoseStore();

  let {
    setCameraTypeOrbitControls,
    setCameraTypeFigureSpinCam,
    gameCamParams,
    setGameCamParams,
  } = useCameraStore();

  const classes = useStyles();

  const [rig] = useState(() => new Vector3())
  const [raw] = useState(() => new Vector3())

  let [rigError, setRigError] = useState<JointsLengthType>({});
  useEffect(() => {
    let error = {} as JointsLengthType;
    for (let bname in rawPoints) {
      if (!riggedPoints.hasOwnProperty(bname) || !rawPoints.hasOwnProperty(bname)) {
        continue;
      }

      rig.set(
          riggedPoints[bname][0],
          riggedPoints[bname][1],
          riggedPoints[bname][2],
        );
      raw.set(
          rawPoints[bname][0],
          rawPoints[bname][1],
          rawPoints[bname][2],
        );

        error[bname] = raw.distanceTo(rig);
    }

    setRigError(error);
  }, [riggedPoints, rawPoints, rig, raw]);

  let [boneErrorList, boneErrorSum] = useMemo(() => {
    let list: JointsLengthType = {} as JointsLengthType;
    let sum = 0;

    if (!riggedBoneLengths) {
      return [list, sum];
    }

    for (let name in rawBoneLengths) {
      if (riggedBoneLengths.hasOwnProperty(name)) {
        list[name] =
          Math.abs((rawBoneLengths[name] - riggedBoneLengths[name]));
        sum += list[name];
      }
    }

    return [list, sum];
  }, [rawBoneLengths, riggedBoneLengths]);

  const updateBoneLengths = (trackingData: any, id: number) => {
    if (!trackingData.hasOwnProperty("skeletalData")) {
      return;
    }
    let skelLen = trackingData.skeletalData.skeletalLen;

    if (!_isEmpty(skelLen[id]) && (skelLen[id].frameCount >= boneLengthWindow)) {
      setRawBoneLengths(skelLen[id].lengths);
    }
  }

  const trackablePlayers = allPositions.filter(
    (position) => position.type === "defense" || position.type === "runner"
  );

  const togglePoseMode = () => {
    setPoseModeEnabled(!poseModeEnabled);

    let toggledOn = !poseModeEnabled;

    if (toggledOn) {
      if (posedPositionId === 0) {
        setPosedPositionId(parseInt(trackablePlayers[0].id));
        setVisiblePositionIds([trackablePlayers[0].id.toString()]);
      }
      else {
        setVisiblePositionIds([posedPositionId.toString()]);
      }
      updateBoneLengths(trackingData, posedPositionId);

      // side camera
      setCameraTypeFigureSpinCam();
      setGameCamParams({
        ...gameCamParams,
        positionId: +posedPositionId,
      });
    }
    else {
      setVisiblePositionIds(allPositions.map((position) => position.id));
      setCameraTypeOrbitControls();
    }
  };

  const handlePosedPlayerChange = (event: any) => {
    let value = event.target.value;

    if (poseModeEnabled && (value !== 0)) {
      updateBoneLengths(trackingData, value);
      setVisiblePositionIds([value.toString()]);
      setPosedPositionId(value);
      setGameCamParams({
        ...gameCamParams,
        positionId: +value,
      });
    }
  }

  return (
    <TabPanel value={value} index={index}>
      <FormControl component="fieldset">
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                checked={poseModeEnabled}
                onChange={togglePoseMode}
                name="pose-mode"
                color="primary"
                size="small"
              />
            }
            label="Pose Debug Mode"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="strobe-type-select">Position to View</InputLabel>
          <NativeSelect
            disabled={!poseModeEnabled}
            value={posedPositionId.toString()}
            onChange={handlePosedPlayerChange}
            name="posed-player"
          >
            {
              trackablePlayers.map((position) => (
                <option key={position.id} value={position.id}>
                  {position.label}
                </option>
              ))
            }
          </NativeSelect>
        </FormControl>

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Switch
                checked={centralizePoints}
                onChange={() => setCentralizePoints(!centralizePoints)}
                name="centralize"
                color="primary"
                size="small"
              />
            }
            label="Centralize Points"
          />
        </FormControl>
      </FormControl>

      <br />
      <Divider />
      <br />

      <FormControl component="fieldset">
        <Table className={classes.table} aria-label="bone table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Bone Head</StyledTableCell>
              <StyledTableCell align="right">
                Raw Length (ft)
              </StyledTableCell>
              <StyledTableCell align="right">
                Rig Length (ft)
              </StyledTableCell>
              <StyledTableCell align="right">
                Error (ft)
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow key="total">
              <StyledTableCell component="th" scope="row">
                TOTAL
              </StyledTableCell>
              <StyledTableCell align="right">
                -
              </StyledTableCell>
              <StyledTableCell align="right">
                -
              </StyledTableCell>
              <StyledTableCell align="right">
                {boneErrorSum.toFixed(dispPrec)}
              </StyledTableCell>
            </StyledTableRow>
            {Object.keys(rawBoneLengths).sort().map((name, _) => (
              <StyledTableRow key={name}>
                <StyledTableCell component="th" scope="row">
                  {name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {(rawBoneLengths[name]).toFixed(dispPrec)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {
                    (riggedBoneLengths && (riggedBoneLengths.hasOwnProperty(name)))
                      ? (riggedBoneLengths[name]).toFixed(dispPrec) : null
                  }
                </StyledTableCell>
                <StyledTableCell align="right">
                  {
                    (boneErrorList && (boneErrorList.hasOwnProperty(name)))
                      ? (boneErrorList[name]).toFixed(dispPrec) : null
                  }
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

        <br />
        <Divider />
        <br />

        <Table className={classes.table} aria-label="common table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Common Point</StyledTableCell>
              <StyledTableCell align="right">
                Error (ft)
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow key="total">
              <StyledTableCell component="th" scope="row">
                TOTAL
              </StyledTableCell>
              <StyledTableCell align="right">
                {
                  Object.values(rigError).reduce((a, b) => a + b, 0).toFixed(dispPrec)
                }
              </StyledTableCell>
            </StyledTableRow>
            {Object.keys(rigError).sort().map((name, _) => (
              <StyledTableRow key={name}>
                <StyledTableCell component="th" scope="row">
                  {name}
                </StyledTableCell>
                <StyledTableCell align="right">{rigError[name].toFixed(dispPrec)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

        <br />
        <Divider />
        <br />

        <Table className={classes.table} aria-label="rig points table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Rig Points</StyledTableCell>
              <StyledTableCell align="right">x</StyledTableCell>
              <StyledTableCell align="right">y</StyledTableCell>
              <StyledTableCell align="right">z</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(riggedPoints).sort().map((name, _) => (
              <StyledTableRow key={name}>
                <StyledTableCell component="th" scope="row">
                  {name}
                </StyledTableCell>
                <StyledTableCell align="right">{riggedPoints[name][0].toFixed(dispPrec)}</StyledTableCell>
                <StyledTableCell align="right">{riggedPoints[name][1].toFixed(dispPrec)}</StyledTableCell>
                <StyledTableCell align="right">{riggedPoints[name][2].toFixed(dispPrec)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

        <br />
        <Divider />
        <br />

        <Table className={classes.table} aria-label="raw points table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Raw Points</StyledTableCell>
              <StyledTableCell align="right">x</StyledTableCell>
              <StyledTableCell align="right">y</StyledTableCell>
              <StyledTableCell align="right">z</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(rawPoints).sort().map((name, _) => (
              <StyledTableRow key={name}>
                <StyledTableCell component="th" scope="row">
                  {name}
                </StyledTableCell>
                <StyledTableCell align="right">{rawPoints[name][0].toFixed(dispPrec)}</StyledTableCell>
                <StyledTableCell align="right">{rawPoints[name][1].toFixed(dispPrec)}</StyledTableCell>
                <StyledTableCell align="right">{rawPoints[name][2].toFixed(dispPrec)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </FormControl>
    </TabPanel>
  );
};
