import React, { useState } from "react";
import { PhotoshopPicker } from "react-color";

interface Props {
  color: string;
  onChange: (color: string) => void;
}

export const ColorPicker = ({ color, onChange }: Props) => {
  let [colorPickerOpen, setColorPickerOpen] = useState(false);
  let [localColor, setLocalColor] = useState(color);
  let [savedColor, setSavedColor] = useState(color);
  let swatchStyle = {
    display: "inline-block",
    width: 20,
    height: 20,
    borderRadius: 4,
    backgroundColor: color,
  };

  // TODOHI  save original color for cancel

  let handleAccept = () => {
    setColorPickerOpen(false);
  };

  let handleCancel = () => {
    setLocalColor(savedColor);
    onChange(savedColor);
    setColorPickerOpen(false);
  };

  let handleChange = (color: any, event: any) => {
    setLocalColor(color.hex);
    onChange(color.hex);
  };

  let handleChangeComplete = () => {};

  let handleSwatchClick = () => {
    setSavedColor(color);
    setColorPickerOpen(true);
  };

  return colorPickerOpen ? (
    <PhotoshopPicker
      color={localColor}
      onAccept={handleAccept}
      onCancel={handleCancel}
      onChange={handleChange}
      onChangeComplete={handleChangeComplete}
    />
  ) : (
    // @ts-ignore
    <div style={swatchStyle} onClick={handleSwatchClick} />
  );
};
