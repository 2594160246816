import React from "react";
import * as THREE from "three";
import { HALF_PI, QUARTER_PI } from "../../constants/math";
import { chalkColor } from "../../constants/colors";

interface Props {
  points: any[];
}

export const FoulLinesGroup: React.FC<Props> = ({ points }) => {
  let leftPoint = points[0];
  let rightPoint = points[points.length - 1];
  let leftLength = leftPoint.distance - 5;
  let rightLength = rightPoint.distance - 5;
  let leftVector = new THREE.Vector3(-1, 1, 0)
    .normalize()
    .multiplyScalar(0.5 * leftLength);
  let rightVector = new THREE.Vector3(1, 1, 0)
    .normalize()
    .multiplyScalar(0.5 * rightLength);
  let leftTheta = QUARTER_PI;
  let rightTheta = -QUARTER_PI;
  let width = 0.25;
  let thickness = 0.02;
  // offset moves half the object's width in order to align the object's edge with fair/foul boundary
  let offset = Math.sqrt((width * width) / 2);
  let tweak = 3.7; // eyeballed magic number to join lines with batter's boxes

  return (
    <group name="foul-lines">
      <mesh
        receiveShadow
        name="left-foul-line"
        rotation={[HALF_PI, leftTheta, 0]}
        position={[leftVector.x - tweak + offset, leftVector.y + tweak, 0]}
      >
        <boxBufferGeometry
          attach="geometry"
          args={[width, thickness, leftLength]}
        />
        <meshBasicMaterial attach="material" color={chalkColor} />
      </mesh>
      <mesh
        receiveShadow
        name="right-foul-line"
        rotation={[HALF_PI, rightTheta, 0]}
        position={[rightVector.x + tweak - offset, rightVector.y + tweak, 0]}
      >
        <boxBufferGeometry
          attach="geometry"
          args={[width, thickness, rightLength]}
        />
        <meshBasicMaterial attach="material" color={chalkColor} />
      </mesh>
    </group>
  );
};
