import { Timebase } from "../../models/timebase";

import styles from "./PlaybackControls.module.css";

// TODOHI   implement – toggle between sec and ms?
// TODOHI   implement – toggle option: absolute ms (HE/gumbo, or play-json)
const Time = ({ ms, duration }: { ms: number; duration: number }) => {
  let sec = (ms / 1000).toFixed(2);
  let durSec = (duration / 1000).toFixed(2);

  return (
    <div className={styles.container}>
      <div className={styles.playbackControls}>{sec}</div>
      <div className={styles.playbackControls} style={{ opacity: 0.5 }}>
        {durSec}
      </div>
    </div>
  );
};

type PlaybackTimeElapsedProps = {
  timebase: Timebase;
};

export const PlaybackTimeElapsed = (props: PlaybackTimeElapsedProps) => {
  const { timebase } = props;
  let { relativeTime, timeRange } = timebase;

  return (
    <Time
      ms={relativeTime}
      duration={timeRange[1] - timeRange[0] - relativeTime}
    />
  );
};
