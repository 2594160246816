import React from "react";

const HALF_PI = 0.5 * Math.PI;
const material = (
  <meshStandardMaterial
    attach="material"
    color="#000"
    transparent={true}
    opacity={0.4}
  />
);

interface Props {
  top: number;
  bottom: number;
  extension?: number;
}

export const Zone = ({ top, bottom, extension = 0 /*2.9 / 12*/ }: Props) => {
  let plateWidth = 17 / 12;
  let thickness = 0.1 / 12;
  let halfPlateWidth = 0.5 * plateWidth;
  let height = top - bottom;
  let zMid = 0.5 * (bottom + top);
  let width = plateWidth + 2 * extension;
  height = top - bottom + 2 * extension;

  return (
    <group name="zone" position-y={plateWidth}>
      <mesh name="top" position-z={top} rotation-z={HALF_PI}>
        <cylinderBufferGeometry
          attach="geometry"
          args={[thickness, thickness, width, 16]}
        />
        {material}
      </mesh>
      <mesh name="bottom" position-z={bottom} rotation-z={HALF_PI}>
        <cylinderBufferGeometry
          attach="geometry"
          args={[thickness, thickness, width, 16]}
        />
        {material}
      </mesh>
      <mesh
        name="left"
        position={[-halfPlateWidth, 0, zMid]}
        rotation-x={HALF_PI}
      >
        <cylinderBufferGeometry
          attach="geometry"
          args={[thickness, thickness, height, 16]}
        />
        {material}
      </mesh>
      <mesh
        name="right"
        position={[halfPlateWidth, 0, zMid]}
        rotation-x={HALF_PI}
      >
        <cylinderBufferGeometry
          attach="geometry"
          args={[thickness, thickness, height, 16]}
        />
        {material}
      </mesh>
    </group>
  );
};
