import React, { useRef } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { ViewTag } from "../PlayView";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteIcon from "@material-ui/icons/Favorite";
// import PrintIcon from '@material-ui/icons/Print'
import Filter1 from "@material-ui/icons/Filter1";
import Filter2 from "@material-ui/icons/Filter2";
import Filter3 from "@material-ui/icons/Filter3";

const views = [
  { tag: ViewTag.hh, icon: <FavoriteIcon />, name: "HighHome" },
  { tag: ViewTag.cf, icon: <SaveIcon />, name: "CenterField" },
  { tag: ViewTag.um, icon: <FileCopyIcon />, name: "Umpire" },
  { tag: ViewTag.pi, icon: <ShareIcon />, name: "Pitcher" },
  { tag: ViewTag.fb, icon: <Filter1 />, name: "1B" },
  { tag: ViewTag.sb, icon: <Filter2 />, name: "2B" },
  { tag: ViewTag.tb, icon: <Filter3 />, name: "3B" },
  // {tag: ViewTag.oh, icon: <PrintIcon/>, name: 'Overhead'},
  // {tag: ViewTag.he, icon: <PrintIcon/>, name: 'Hawkeye'},
];

interface Props {
  onViewSelected: (viewTag: string) => void;
  hotkeys?: boolean;
}

export const ViewPresetMenu: React.FC<Props> = ({
  onViewSelected,
  hotkeys,
}) => {
  let ref = useRef(null);
  let [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  let handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  let handleClose = () => {
    setAnchorEl(null);
  };

  let handleViewClick = (view: any) => {
    onViewSelected(view);
    setAnchorEl(null);
  };

  return (
    <div ref={ref}>
      <Tooltip title="Camera Position Presets" placement="left">
        <Button
          variant="contained"
          size="small"
          onClick={handleClick}
          aria-controls="view-presets-menu"
          aria-haspopup="true"
        >
          View
        </Button>
      </Tooltip>
      <Menu
        container={ref.current}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {views.map((view) => (
          <MenuItem key={view.tag} onClick={() => handleViewClick(view.tag)}>
            {view.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
