import _ from "lodash";
import {
  alphaFilter,
  TimeSeries,
  toSampleAtTime,
} from "../../util/tracking-util";
import { MathUtils, Vector3 } from "three";
import { SmoothingType } from "../../stores/povCameraStore";
import { convolve, hanning, normalize } from "../../util/dataCleanupUtil";
import { toEventTime } from "../../util/play-util";
import { BALL_WAS_PITCHED_EVENT } from "../../models/playEventTypes";
import {
  timeToSegmentPoint,
  toSegmentAtTime,
} from "../../util/polynomial-util";
import { lerpPoints } from "../../util/geometry-util";

const defaultMovingAverageWindowSize = 11;

export const toMovingAverageSeries = (
  series: number[],
  windowSize = defaultMovingAverageWindowSize
) => {
  let halfWindowSize = Math.round(0.5 * windowSize);
  let endIndex = series.length - 1;

  return series.map((n: number, i: number) => {
    let start = i - halfWindowSize;
    let end = start + windowSize - 1;

    start = Math.max(0, start);
    end = Math.min(endIndex, end);
    let samples = series.slice(start, end + 1);

    return _.mean(samples);
  });
};

let defaultAlpha = 0.1;

let toAlphaSmoothedSeries = (series: number[], alpha = defaultAlpha) => {
  let prev = series[0];

  return series.map((n: number) => {
    let val = alphaFilter(prev, n, alpha);
    prev = n;

    return val;
  });
};

export const toSmoothedVectorSeries = (
  series: Vector3[],
  smoothingType: SmoothingType,
  hannWindowSize = 5,
  alpha = 0.95,
  movingAverageWindowSize = 51
) => {
  let xSeries: number[] = [];
  let ySeries: number[] = [];
  let zSeries: number[] = [];

  // break out into separate coordinate series
  series.forEach((v: any) => {
    xSeries.push(v.x);
    ySeries.push(v.y);
    zSeries.push(v.z);
  });

  let xSmooth: number[];
  let ySmooth: number[];
  let zSmooth: number[];

  // map to a set of smoothed series
  switch (smoothingType) {
    case "alpha":
      xSmooth = toAlphaSmoothedSeries(xSeries, alpha);
      ySmooth = toAlphaSmoothedSeries(ySeries, alpha);
      zSmooth = toAlphaSmoothedSeries(zSeries, alpha);
      break;
    case "moving-average":
      xSmooth = toMovingAverageSeries(xSeries, movingAverageWindowSize);
      ySmooth = toMovingAverageSeries(ySeries, movingAverageWindowSize);
      zSmooth = toMovingAverageSeries(zSeries, movingAverageWindowSize);
      break;
    case "hann":
    default:
      xSmooth = convolve(xSeries, normalize(hanning(hannWindowSize))) || [];
      ySmooth = convolve(ySeries, normalize(hanning(hannWindowSize))) || [];
      zSmooth = convolve(zSeries, normalize(hanning(hannWindowSize))) || [];
      break;
  }

  // combine back into a new vector series
  return xSmooth.map(
    (x: number, i: number) => new Vector3(x, ySmooth[i], zSmooth[i])
  );
};

const _pNeck = new Vector3();
const _pLEar = new Vector3();
const _pREar = new Vector3();
const _pLEye = new Vector3();
const _pREye = new Vector3();
const _pMidEar = new Vector3();
const _pMidEye = new Vector3();
const _vMidEye = new Vector3();
const _vUpNorm = new Vector3();
const _vMidEyeProjection = new Vector3();

// Calculates utility vectors for the given figure position
const toPositionVectors = (position: any, tiltDegrees = 0) => {
  let { positionId, jointsMap } = position;

  // assigning fairly useless defaults here in case any joints are missing, just so we can proceed
  let {
    Neck = [0, 0, 0],
    LEar = [-2, 2, 1],
    REar = [2, 2, 1],
    LEye = [-1, 1, 2],
    REye = [1, 1, 2],
  } = jointsMap;

  _pNeck.fromArray(Neck);
  _pLEar.fromArray(LEar);
  _pREar.fromArray(REar);
  _pLEye.fromArray(LEye);
  _pREye.fromArray(REye);
  _pMidEar.addVectors(_pLEar, _pREar).multiplyScalar(0.5);
  _pMidEye.addVectors(_pLEye, _pREye).multiplyScalar(0.5);
  _vMidEye.subVectors(_pMidEye, _pNeck);
  let vUp = new Vector3().subVectors(_pMidEar, _pNeck);
  _vUpNorm.copy(vUp).normalize();
  _vMidEyeProjection
    .copy(_vUpNorm)
    .multiplyScalar(_vMidEye.clone().dot(_vUpNorm));
  let vForward = new Vector3().subVectors(_vMidEye, _vMidEyeProjection);

  let vSide = new Vector3().crossVectors(vForward, vUp);

  // project slightly in front of midEye to avoid mesh
  // note: vForward assumes head is upright; doesn't yet handle tilted head poses
  let pCameraPosition = _pNeck
    .clone()
    .add(_vMidEyeProjection)
    .addScaledVector(vForward, 3);

  return {
    positionId,
    vectors: {
      pCameraPosition,
      headPose: {
        vUp,
        vForward,
        vSide,
      },
    },
  };
};

/**
 * calculates pCameraPosition, and {vUp, vForward, vSide} for head-pose and ball-gaze
 * returns [{time, positions: [positionId, vectors: {...}]}, ...]
 * @param frames
 * @param tiltDegrees
 */
const toPositionVectorFrames = (frames: TimeSeries, tiltDegrees = 0) => {
  return frames.map((frame: any) => {
    let { time, positions } = frame;
    let positionVectors = positions.map((position: any) =>
      toPositionVectors(position, tiltDegrees)
    );

    return { time, positions: positionVectors };
  });
};

// note: places where _.cloneDeep is used below are not entirely pure since we don't create copies of the
// actual Vector3s. possible to use _.cloneDeepWith and a customizer? https://lodash.com/docs/4.17.15#cloneDeepWith

// converts [{time, positions}, …] to {positionId: frames, …}
const toPositionFramesMap = (frames: TimeSeries) => {
  let map: any = {};

  frames.forEach((frame: any) => {
    let { time, positions } = frame;

    positions.forEach((position: any) => {
      let { positionId, vectors } = position;

      if (!map[positionId]) {
        map[positionId] = [];
      }

      map[positionId].push({
        time,
        vectors: _.cloneDeep(vectors),
      });
    });
  });

  return map;
};

// TODO    need to smooth vUp for each set?
// applies smoothing to vForward (and vUp?) in each position's time series
const toSmoothedVectorsMap = (
  framesMap: any,
  smoothingEnabled: boolean,
  smoothingType: SmoothingType,
  hannWindowSize: number,
  alpha: number,
  movingAverageWindowSize: number
) => {
  let copy = _.cloneDeep(framesMap);

  if (!smoothingEnabled) {
    return copy;
  }

  return _.mapValues(copy, (frames: TimeSeries) => {
    let vUpSeries = frames.map((frame: any) => frame.vectors.headPose.vForward);

    let smoothed = toSmoothedVectorSeries(
      vUpSeries,
      smoothingType,
      hannWindowSize,
      alpha,
      movingAverageWindowSize
    );

    frames.forEach((frame: any, i: number) => {
      frame.vectors.headPose.vForward = smoothed[i];
    });

    return frames;
  });
};

/**
 * tilts the vUp and vForward vectors in each positionId's frame series
 * @param positionFramesMap
 * @param tiltDegrees
 */
const toTiltedMap = (positionFramesMap: any, tiltDegrees = 0) => {
  let tiltRadians = MathUtils.degToRad(tiltDegrees);

  return _.mapValues(_.cloneDeep(positionFramesMap), (frames) => {
    frames.forEach((frame: any) => {
      let { vUp, vForward, vSide } = frame.vectors.headPose;

      let vSideCopy = vSide.clone().normalize();
      let vForwardTilted = vForward.clone();
      let vUpTilted = vUp.clone();

      vForwardTilted.applyAxisAngle(vSideCopy, tiltRadians);
      vUpTilted.applyAxisAngle(vSideCopy, tiltRadians);

      frame.vectors.headPose = {
        vUp: vUpTilted,
        vForward: vForwardTilted,
        vSide: vSideCopy,
      };
    });

    return frames;
  });
};

const toLookAtPointsMap = (positionFramesMap: any) => {
  return _.mapValues(_.cloneDeep(positionFramesMap), (frames: TimeSeries) => {
    frames.forEach((frame: any) => {
      let { pCameraPosition } = frame.vectors;
      let { vForward } = frame.vectors.headPose;

      frame.vectors.headPose.pLookAt = pCameraPosition.clone().add(vForward);
    });

    return frames;
  });
};

const toGLVector3 = (mlbVector: Vector3) =>
  new Vector3(mlbVector.x, mlbVector.z, -mlbVector.y);

/**
 * Converts pCameraPosition, pLookAt, and vUp from MLB to GL coordinates
 * @param map
 */
const toGLCoordinatesMap = (map: any) => {
  return _.mapValues(_.cloneDeep(map), (frames: TimeSeries) => {
    frames.forEach((frame: any) => {
      let { vectors } = frame;
      let { pCameraPosition } = vectors;
      let { vUp, pLookAt } = vectors.headPose;

      vectors.pCameraPositionGL = toGLVector3(pCameraPosition);
      vectors.headPose.pLookAtGL = toGLVector3(pLookAt);
      vectors.headPose.vUpGL = toGLVector3(vUp);
    });

    return frames;
  });
};

interface toPositionMapArgs {
  frames: TimeSeries;
  tiltDegrees: number;
  smoothingEnabled: boolean;
  smoothingType: SmoothingType;
  hannWindowSize: number;
  alpha: number;
  movingAverageWindowSize: number;
}

/**
 * Calculates a map from positionIds to frame series, where each frame holds the camera position and smoothed gaze
 * at each frame time.
 *
 * sequence of calculations:
 * toPositionVectorFrames – calculates pCameraPosition, and {vUp, vForward, vSide}
 * toPositionFramesMap – reshapes to map by positions
 * toTiltedMap – tilts vUp, vForward
 * toSmoothedVectorsMap – smooths vForward across time
 * toLookAtPointsMap – calculates pLookAt
 * toGLCoordinatesMap – converts pCameraPosition, vUp, pLookAt, etc. to GL coordinates
 *
 * @param frames
 * @param tiltDegrees
 * @param smoothingEnabled
 * @param smoothingType
 * @param hannWindowSize
 * @param alpha
 * @param movingAverageWindowSize
 */
export const toPositionIdMap = ({
  frames,
  tiltDegrees,
  smoothingEnabled,
  smoothingType,
  hannWindowSize,
  alpha,
  movingAverageWindowSize,
}: toPositionMapArgs) => {
  let positionVectorFrames = toPositionVectorFrames(frames, tiltDegrees);
  let positionFramesMap = toPositionFramesMap(positionVectorFrames);
  let tiltedMap = toTiltedMap(positionFramesMap, tiltDegrees);
  let smoothedVectorsMap = toSmoothedVectorsMap(
    tiltedMap,
    smoothingEnabled,
    smoothingType,
    hannWindowSize,
    alpha,
    movingAverageWindowSize
  );
  let lookAtPointsMap = toLookAtPointsMap(smoothedVectorsMap);

  return toGLCoordinatesMap(lookAtPointsMap);
};

const toSegmentFrames = ({
  playData,
  playTracking,
}: {
  playData: any;
  playTracking: any;
}) => {
  let segments = playData.ballSegments.genericSegments;
  let { frames } = playTracking.skeletalData;
  let releaseMS = toEventTime(playData, BALL_WAS_PITCHED_EVENT);

  if (!releaseMS) {
    return [];
  }

  if (releaseMS) {
    let ballSeries = frames.map((frame: any) => {
      let { time: frameMS } = frame;
      let t = (frameMS - releaseMS) / 1000;

      if (t) {
        let segment = toSegmentAtTime({ segments, time: t });

        if (segment) {
          let sample = timeToSegmentPoint({ t, segment });
          let { x, y, z /*n*/ } = sample;

          return { time: frameMS, x, y, z };
        }
      }

      return { time: frameMS };
    });

    return ballSeries;
  } else {
    return [];
  }
};

// Fills end-gaps with nearest defined point
const toFilledEndGapsSeries = (series: any[]) => {
  let copy = [...series];

  // fill head gap, if any
  let indexFirst = _.findIndex(series, (el: any) => el.x !== undefined);
  let firstPoint = series[indexFirst];

  for (let i = 0; i < indexFirst; i++) {
    let { time } = series[i];

    copy[i] = { ...firstPoint, time };
  }

  // fill tail gap, if any
  let indexLast = _.findLastIndex(series, (el: any) => el.x !== undefined);
  let lastPoint = series[indexLast];

  for (let i = series.length - 1; i >= indexLast; i--) {
    let { time } = series[i];

    copy[i] = { ...lastPoint, time };
  }

  return copy;
};

// Fills gaps containing undefined points with interpolated points
const toFilledGapsSeries = (series: any[]) => {
  let copy = [...series];
  let i = 0;

  while (i < copy.length - 1) {
    // gapStartIndex = index of first undefined, at or after i
    let gapStartIndex = _.findIndex(copy, (el: any) => el.x === undefined, i);

    if (gapStartIndex === -1) {
      // done
      break;
    }

    let prevIndex = gapStartIndex - 1;

    // nextIndex = index of first !undefined after gapStartIndex
    let nextIndex = _.findIndex(
      copy,
      (el: any) => el.x !== undefined,
      gapStartIndex
    );
    let gapEndIndex = nextIndex - 1;
    let prevPoint = copy[prevIndex];

    console.assert(prevPoint, "prevPoint undefined in toFilledGapsSeries");

    let prevTime = prevPoint.time;
    let nextPoint = copy[nextIndex];
    let nextTime = nextPoint.time;

    // fill from gapStartIndex to gapEndIndex with interpolated points
    for (let j = gapStartIndex; j <= gapEndIndex; j++) {
      let el = copy[j];
      let { time } = el;
      let n = (el.time - prevTime) / (nextTime - prevTime);
      let p = lerpPoints(prevPoint, nextPoint, n);

      copy[j] = { ...p, time };
    }

    i = nextIndex;
  }

  return copy;
};

// Maps tracking frames to a ball position time series. Fills the series with points from the ball segments,
// then fills any gaps
export const toBallSeries = ({
  playData,
  playTracking,
}: {
  playData: any;
  playTracking: any;
}) => {
  let ballSeries = toSegmentFrames({
    playData,
    playTracking,
  });

  return toFilledGapsSeries(toFilledEndGapsSeries(ballSeries));
};

interface toGazeGLArgs {
  frame: any;
  ballSeries: any[];
  time: number;
  ballDirectionMix: number;
}

const _pBallGL = new Vector3();
const _vHeadPoseGL = new Vector3();
const _vBallDirGL = new Vector3();
const _vAxisGL = new Vector3();
const _vGazeGL = new Vector3();
const _vAxisGLNorm = new Vector3();
const _pGazeGL = new Vector3();

export const toGazePointGL = ({
  frame,
  ballSeries,
  time,
  ballDirectionMix,
}: toGazeGLArgs) => {
  if (!frame) {
    return null;
  }

  let { vectors } = frame;
  let { pCameraPositionGL } = vectors;
  let { pLookAtGL } = vectors.headPose;

  // pBall: ball position from ballSeries with time
  let pBall = toSampleAtTime({ time, series: ballSeries });

  _pBallGL.set(pBall.x, pBall.z, -pBall.y);

  _vHeadPoseGL.subVectors(pLookAtGL, pCameraPositionGL);
  _vBallDirGL.subVectors(_pBallGL, pCameraPositionGL);

  // put _vHeadPoseGL at same distance as the ball in order to…?
  let ballDistance = _vBallDirGL.length();

  // _vAxisGL: the rotation axis
  _vAxisGL.crossVectors(_vHeadPoseGL, _vBallDirGL);
  _vAxisGLNorm.copy(_vAxisGL).normalize();

  // theta: the angle between head-pose vector and ball vector
  let theta = _vHeadPoseGL.angleTo(_vBallDirGL);
  let mixedAngle = ballDirectionMix * theta;

  _vGazeGL
    .copy(_vHeadPoseGL)
    .normalize()
    .multiplyScalar(ballDistance)
    .applyAxisAngle(_vAxisGLNorm, mixedAngle);

  _pGazeGL.addVectors(pCameraPositionGL, _vGazeGL);

  return _pGazeGL;
};

const _vGazeNormGL = new Vector3();
const _vBackOffsetGL = new Vector3();
const _vHorizontalOffsetGL = new Vector3();
const _vVerticalOffsetGL = new Vector3(0, 1, 0);

interface toOffsetCameraPositionGLArgs {
  pCameraPositionGL: Vector3;
  pGazeGL: Vector3 | null;
  backOffset: number;
  verticalOffset: number;
  horizontalOffset: number;

  pOffsetCameraPositionGL?: Vector3;
}

// Returns a point with the offsets, if any, applied to pCameraPositionGL
export const toOffsetCameraPositionGL = ({
  pCameraPositionGL,
  pGazeGL,
  backOffset,
  verticalOffset,
  horizontalOffset,
  pOffsetCameraPositionGL = new Vector3(),
}: toOffsetCameraPositionGLArgs) => {
  pOffsetCameraPositionGL.copy(pCameraPositionGL);

  if (!pGazeGL) {
    return pOffsetCameraPositionGL;
  }

  // add offsets to pOffsetCameraPositionGL

  _vGazeNormGL.subVectors(pGazeGL, pCameraPositionGL).normalize();

  _vBackOffsetGL.copy(_vGazeNormGL).negate().multiplyScalar(backOffset);
  pOffsetCameraPositionGL.add(_vBackOffsetGL);

  _vVerticalOffsetGL.set(0, 1, 0).multiplyScalar(verticalOffset);
  pOffsetCameraPositionGL.add(_vVerticalOffsetGL);

  _vHorizontalOffsetGL
    .set(-_vGazeNormGL.z, 0, _vGazeNormGL.x)
    .multiplyScalar(horizontalOffset);
  pOffsetCameraPositionGL.add(_vHorizontalOffsetGL);

  return pOffsetCameraPositionGL;
};
