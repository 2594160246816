import React, { useEffect, useRef } from "react";
import styles from "./DiagnosticsSpace.module.css";
import { SpaceSelect } from "../SpaceSelect";
import { JointTimelines } from "../JointTimelines";
import { PlayTime } from "field-of-things/src/components/PlayTime";
import { PlayView } from "field-of-things/src/components/PlayView";
import { Timebase } from "field-of-things/src/models/timebase";
import { useTimebaseState } from "field-of-things/src/hooks";
import { toTimeRange } from "field-of-things/src/util/trackedEvent-util";

interface Props {
  rootStore: any;
  appSpace: string;
  onAppSpaceChange: (space: string) => void;
  venueId: number;
  playTracking: any;
  playData: any;
  onPlayClicked?: () => void;
}

export const DiagnosticsSpace = (props: Props) => {
  const {
    rootStore,
    appSpace,
    onAppSpaceChange,
    venueId,
    playTracking,
    playData,
  } = props;

  const timebaseRef = useRef(new Timebase());
  const timebase = timebaseRef.current;
  const { trackedEvents } = playData;
  const selectedPlayerPositionId = rootStore.useFigureAppearanceStore(
    ($: any) => $.selectedPlayerPositionId
  );

  // init timebase
  useEffect(() => {
    timebase.timeRange = toTimeRange(trackedEvents);
    timebase.loop = true;
  }, [trackedEvents, timebase]);

  useTimebaseState(timebase);

  return (
    <div className={styles.diagnosticsSpace}>
      <div className={styles.midTop}>
        <h3>Diagnostics</h3>
      </div>

      <div className={styles.main}>
        <div className={styles.playViewContainer}>
          <PlayView
            timebase={timebase}
            playTracking={playTracking}
            playData={playData}
            rootStore={rootStore}
            venueId={venueId ? venueId : playData.metaData.venue.id}
          />
        </div>

        <PlayTime
          timebase={timebase}
          trackedEvents={trackedEvents}
          rootStore={rootStore}
        />

        {selectedPlayerPositionId ? (
          <JointTimelines
            playTracking={playTracking}
            positionId={selectedPlayerPositionId}
          />
        ) : null}
      </div>
      <br />
      <div className={styles.midRight}>
        <SpaceSelect space={appSpace} onChange={onAppSpaceChange} />
      </div>
    </div>
  );
};
