import { MathUtils, Vector3 } from "three";

interface toCameraStartPositionArgs {
  batSide: string;
  startPosDistance: number;
  startPosAngle: number;
  startPosHeight: number;
  startPos?: Vector3;
}

export const toCameraStartPosition = ({
  batSide,
  startPosDistance,
  startPosAngle,
  startPosHeight,
  startPos = new Vector3(),
}: toCameraStartPositionArgs) => {
  let angle = batSide === "R" ? startPosAngle : -startPosAngle;
  let radians = MathUtils.degToRad(angle);
  let x = startPosDistance * Math.sin(radians);
  let y = startPosDistance * -Math.cos(radians);

  startPos.set(x, y, startPosHeight);

  return startPos;
};

interface toStartTargetPosArgs {
  startLookAtY: number;
  startLookAtZ: number;
  startTarget?: Vector3;
}

export const toStartTargetPos = ({
  startLookAtY,
  startLookAtZ,
  startTarget = new Vector3(),
}: toStartTargetPosArgs) => {
  startTarget.set(0, startLookAtY, startLookAtZ);

  return startTarget;
};
