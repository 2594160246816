// produces a set of polynomial terms

import _range from "lodash/range";
import _findIndex from "lodash/findIndex";
import { lerpPoints } from "./geometry-util";

const terms = (n: number, coef: number[]) =>
  coef.map((c, i) => c * Math.pow(n, i));

// sums polynomial terms
export const toValue = (n: number, coef: number[]) =>
  terms(n, coef).reduce((sum, n) => sum + n);

interface toPointProps {
  n: number;
  xCoef: number[];
  yCoef: number[];
  zCoef: number[];
}

// calculates the polynomial value at n for each coordinate
export const toPoint = ({ n, xCoef, yCoef, zCoef }: toPointProps) => ({
  n,
  x: toValue(n, xCoef),
  y: toValue(n, yCoef),
  z: toValue(n, zCoef),
});

interface toPointsProps {
  n1: number;
  n2: number;
  step: number;
  xCoef: number[];
  yCoef: number[];
  zCoef: number[];
}

// produces a series of points spaced by step, starting at n1 and ending at n2
export const toPoints = (props: toPointsProps) => {
  let { n1, n2, step, xCoef, yCoef, zCoef } = props;
  let values = _range(n1, n2, step);

  values.push(n2);

  return values.map((n) => toPoint({ n, xCoef, yCoef, zCoef }));
};

const defaultStep = 0.0303030303; // ~33 Hz

export const segmentToPoints = (segment: any, step = defaultStep) => {
  let n1 = segment.startData.time;
  let n2 = segment.endData.time;
  let xCoef = segment.trajectoryPolynomialX;
  let yCoef = segment.trajectoryPolynomialY;
  let zCoef = segment.trajectoryPolynomialZ;

  return toPoints({ n1, n2, step, xCoef, yCoef, zCoef });
};

interface toSegmentPointProps {
  t: number;
  segment: any;
}

export const timeToSegmentPoint = ({ t, segment }: toSegmentPointProps) => {
  let xCoef = segment.trajectoryPolynomialX;
  let yCoef = segment.trajectoryPolynomialY;
  let zCoef = segment.trajectoryPolynomialZ;

  // return toPoint({ n: t, xCoef, yCoef, zCoef });
  return toPoint({ n: t, xCoef, yCoef, zCoef });
};

export const toSegmentAtTime = ({
  segments,
  time, // seconds, relative to pitch release
}: {
  segments: any[];
  time: number;
}) => {
  let foundSegment: any;
  let i = 0;

  while (!foundSegment && i < segments.length) {
    let segment = segments[i];
    if (segment.startData.time <= time && time <= segment.endData.time) {
      foundSegment = segment;
    }

    i++;
  }

  return foundSegment;
};

interface toSampleProps {
  t: number;
  segments: any[];
}

export const toSample = ({ t, segments }: toSampleProps) => {
  let sample; //= {};

  for (let [i, segment] of segments.entries()) {
    // check if in segment
    if (segment.startData.time <= t && t <= segment.endData.time) {
      // inside segment -> generate sample from polynomials
      sample = timeToSegmentPoint({ t, segment });

      // TODO    verify that we don't need to reference the segment in the sample
      // // @ts-ignore
      // sample.segment = segment;

      // sample.t = sample.n;
      //   delete sample.n;
      //
      // return sample;

      let { x, y, z, n } = sample;

      return { t: n, x, y, z };
    } else {
      // check if between this segment and next segment
      let nextSegment = segments[i + 1];

      if (segment.endData.time < t && t < nextSegment.startData.time) {
        // between segments -> interpolate between segment endpoints
        let t1 = segment.endData.time;
        let t2 = nextSegment.startData.time;
        let p = timeToSegmentPoint({ t: t1, segment });
        let q = timeToSegmentPoint({ t: t2, segment: nextSegment });

        // return { ...lerpPoints(p, q, (t - t1) / (t2 - t1)), n: t };
        return { t, ...lerpPoints(p, q, (t - t1) / (t2 - t1)) };
      }
    }
  }

  return sample;
};

interface toSampleTimesProps {
  segments: any[];
  interval?: number; // ms
}

/**
 * Returns a series of relative times
 * @param segments
 * @param interval
 */
const toSampleTimes = ({ segments, interval = 33 }: toSampleTimesProps) => {
  let tStart = segments[0].startData.time;
  let tEnd = segments[segments.length - 1].endData.time;
  let step = interval / 1000;

  return _range(tStart, tEnd, step);
};

interface toBallSeriesProps {
  segments: any[];
  interval?: number; // ms
}

export const toBallPointSeries = ({
  segments,
  interval = 33,
}: toBallSeriesProps): any[] => {
  if (segments.length === 0) {
    return [];
  }

  let times = toSampleTimes({ segments, interval });

  return times.map((t) => toSample({ t, segments }));
};

const defaultExtensionHeightThreshold = 10;

interface toExtendedBallSeriesProps extends toBallSeriesProps {
  extensionHeightThreshold?: number;
}

/**
 * Ensures that the resulting series extends to a point at or below the given height threshold.
 * @param segments
 * @param interval
 * @param extensionHeightThreshold
 */
export const toExtendedBallPointSeries = ({
  segments,
  interval = 33,
  extensionHeightThreshold = defaultExtensionHeightThreshold,
}: toExtendedBallSeriesProps): any[] => {
  if (segments.length === 0) {
    return [];
  }
  let series = toBallPointSeries({ segments, interval });
  let segment = segments[segments.length - 1];

  while (series[series.length - 1].z > extensionHeightThreshold) {
    let t = series[series.length - 1].t + interval / 1000;
    let p = timeToSegmentPoint({ t, segment });
    let { n, x, y, z } = p;
    let q = { t: n, x, y, z };

    series.push(q);
  }

  return series;
};

interface toPointInSeriesProps {
  t: number;
  series: any[];
  // clamp?: boolean,
}

/**
 * Returns a point from a time series of points for the given time. if the time falls between two points,
 * we interpolate between the points.
 * @param t
 * @param series
 * @param clamp
 */
export const toPointInSeries = ({
  t,
  series /*, clamp = true*/,
}: toPointInSeriesProps) => {
  // TODO    implement optional clamping

  let lastIndex = series.length - 1;

  // find the first sample, u, where its time <= t
  let j = _findIndex(series, (u, i) => {
    if (i === lastIndex) {
      return true;
    } else {
      let v = series[i + 1];

      return u.t <= t && t < v.t;
    }
  });

  if (j === lastIndex) {
    return { ...series[j] };
  } else {
    let p = series[j];
    let q = series[j + 1];
    let n = (t - p.t) / (q.t - p.t);

    return { ...lerpPoints(p, q, n), t };
  }
};
