export const plateWidth = 17 / 12;
export const halfPlateWidth = 0.5 * plateWidth;
export const plateLength = 17 / 12;
export const halfPlateLength = 0.5 * plateWidth;

export const CHALK_WIDTH = 0.25;
export const CHALK_THICKNESS = 0.01;

export const ballDiameter = 2.9 / 12;
export const ballRadius = 0.5 * ballDiameter;
