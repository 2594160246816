import React from "react";
// import Tooltip from '@material-ui/core/Tooltip'
import { IconContext } from "react-icons";
import { FaFlask } from "react-icons/fa";

export const LabIcon = () => (
  // <Tooltip title='work in progress' placement='top'>
  <IconContext.Provider value={{ color: "#c1c100" }}>
    <span role="img" aria-label="lab flask">
      <FaFlask />
    </span>
  </IconContext.Provider>
  // </Tooltip>
);
