import React, { useRef, useLayoutEffect } from "react";
import { BufferGeometry, Matrix4, Vector3 } from "three";
import { HALF_PI } from "../../../constants/math";
// tried refactoring to .tsx, but couldn't get past several TS errors related to mesh and geometry here

let defaultRadius = 0.05;
let radiusSegments = 16;
let heightSegments = 1;
const defaultColor = "#333";

// TODOHI  ok to share a material in three?
const sharedMaterial = (
  <meshBasicMaterial attach="material" color={defaultColor} />
);

// using the technique for aligning a cylinder to connect two points borrowed from here
// https://stackoverflow.com/questions/15139649/three-js-two-points-one-cylinder-align-issue
//
// another technique here
// https://stackoverflow.com/questions/15316127/three-js-line-vector-to-cylinder
//
// originally borrowed from ArrowHelper
// https://github.com/mrdoob/three.js/blob/master/src/helpers/ArrowHelper.js#L71

// TODOHI  are these reused scratch elements causing the issues with CylinderSpans not always updating correctly?
const v1 = new Vector3();
const v2 = new Vector3();
const vDir = new Vector3();
const position = new Vector3();
const yUnit = new Vector3(0, 1, 0);
const offsetRotation = new Matrix4();
const cylinderGeometry = new BufferGeometry();

offsetRotation.makeRotationX(HALF_PI); // rotate 90 degrees about x axis
let orientation = new Matrix4(); // a new orientation matrix to offset pivot

/*
 * Creates a mesh with a cylinder that spans the given points
 * @param p1 - [number, number, number]
 * @param p2 - [number, number, number]
 * @param radius - optional
 * @param material - optional
 */
export const CylinderSpan = (props: {
  p1: number[];
  p2: number[];
  radius: number;
  material: JSX.Element;
}) => {
  let { p1, p2, radius = defaultRadius, material = sharedMaterial } = props;
  let geometryRef = useRef(cylinderGeometry);

  // TODO    how to use spread (more concise and less prone to typo) and keep TS happy?
  // v1.set(...p1)
  // v2.set(...p2)
  v1.set(p1[0], p1[1], p1[2]);
  v2.set(p2[0], p2[1], p2[2]);

  vDir.copy(v2);
  vDir.sub(v1);

  let length = vDir.length();

  position.copy(v2).add(v1).divideScalar(2);

  // TODOHI  possible to reuse orientation matrix?
  // TODOHI  probably need to figure out how to apply orientation declaratively instead of in useUpdate

  orientation.lookAt(v1, v2, yUnit); // look at destination
  orientation.multiply(offsetRotation); // combine orientation with rotation transformations

  useLayoutEffect(() => {
    geometryRef.current.applyMatrix4(orientation);
  });

  return (
    <mesh position={position}>
      <cylinderBufferGeometry
        ref={geometryRef}
        attach="geometry"
        args={[radius, radius, length, radiusSegments, heightSegments]}
      />
      {material}
    </mesh>
  );
};
