import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { TabPanel } from "./Knobs";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { StrobeUniformKnobs } from "./StrobeUniformKnobs";
import { StrobeHitterReactionKnobs } from "./StrobeHitterReactionKnobs";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  sliderRoot: {
    width: 200,
  },
  multiSelect: {
    width: 160,
  },
  multiSelectControl: {
    alignItems: "flex-start",
  },
  figureButton: {
    margin: theme.spacing(0.5),
  },
}));

interface Props {
  value: any;
  index: number;
  timebase: any;
  useFigureStrobeStore: any;
}

export const FigureStrobeKnobsPanel = (props: Props) => {
  let { index, value, timebase, useFigureStrobeStore } = props;

  const classes = useStyles();

  let {
    strobesEnabled,
    setStrobesEnabled,
    strobeFigures,
    setStrobeFigures,
    strobeType,
    setStrobeType,
  } = useFigureStrobeStore();

  let handleSelectedStrobeTypeChange = (event: any) => {
    setStrobeType(event.target.value);
  };

  let handleSelectedStrobeFiguresChange = (event: any) => {
    setStrobeFigures(event.target.value);
  };

  return (
    <TabPanel value={value} index={index}>
      <FormControlLabel
        control={
          <Switch
            checked={strobesEnabled}
            onChange={() => setStrobesEnabled(!strobesEnabled)}
            name="figures-enabled-toggle"
            color="secondary"
            size="small"
          />
        }
        label="Figure Strobing"
      />
      <br />

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="strobe-type-select">Strobe Type</InputLabel>
        <NativeSelect
          value={strobeType}
          onChange={handleSelectedStrobeTypeChange}
          inputProps={{
            name: "strobe-type-select",
            id: "strobe-type-select",
          }}
        >
          <option value="uniform-intervals">Uniform Intervals</option>
          <option value="hitter-reaction">Hitter Reaction</option>
        </NativeSelect>
      </FormControl>

      <br />

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="strobe-type-select">Strobe Figure(s)</InputLabel>
        <NativeSelect
          value={strobeFigures}
          onChange={handleSelectedStrobeFiguresChange}
          inputProps={{
            name: "strobe-figures-select",
            id: "strobe-figures-select",
          }}
        >
          <option value="stick-figures">Stick Figures</option>
          <option value="figure-models">Figure Models</option>
          <option value="both">Both</option>
        </NativeSelect>
      </FormControl>

      <br />
      <br />

      {strobeType === "uniform-intervals" && (
        <StrobeUniformKnobs
          timebase={timebase}
          useFigureStrobeStore={useFigureStrobeStore}
        />
      )}

      {strobeType === "hitter-reaction" && (
        <StrobeHitterReactionKnobs
          useFigureStrobeStore={useFigureStrobeStore}
        />
      )}
    </TabPanel>
  );
};
