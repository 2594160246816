/**
 * Renders an SVG <g> element for a tag
 */

// @ts-ignore
import React from "react";
// @ts-ignore
import styles from "./TagGroup.module.css";

interface ITagGroupProps {
  id?: any;
  text: string;
  x: number;
  highlight?: boolean;
  color: string;
  backgroundColor: string;
  outlineColor: string;
  onClick?: any;
}

const anchorY = [10, 30];
const boxDims = [30, 16];

export const TagGroup = ({
  id,
  text,
  x,
  onClick,
  highlight = false,
  color,
  backgroundColor,
  outlineColor,
}: ITagGroupProps) => {
  let handleClick = () => {
    onClick && onClick(id);
  };

  return (
    <g
      className={styles.tag}
      transform={`translate(${x} 0)`}
      onMouseDown={handleClick}
    >
      <circle className={styles.tagAnchor} cx={0} cy={anchorY[0]} r={2} />
      <line
        className={styles.tagAnchorLine}
        x1={0}
        y1={anchorY[1]}
        x2={0}
        y2={anchorY[0]}
      />
      {/*<circle className={styles.tagBack} cx={0} cy={20} r={16} fill='#ffc' onClick={handleClick}/>*/}
      <rect
        className={highlight ? styles.tagBackHighlighted : styles.tagBack}
        x={-boxDims[0] / 2}
        y={boxDims[1]}
        width={boxDims[0]}
        height={boxDims[1]}
        rx={3}
        ry={3}
        style={{ fill: backgroundColor, stroke: outlineColor }}
      />
      <text
        className={highlight ? styles.textHighlighted : styles.text}
        style={{ fill: color }}
        x={0}
        y={12 + boxDims[1]}
      >
        {text}
      </text>
    </g>
  );
};
