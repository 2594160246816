import create from "zustand";

export interface GlStatesStore {
  glStatsVisible: boolean;
  toggleGlStatsVisible(): void;
  glRenderStatsVisible: boolean;
  toggleGlRenderStatsVisible(): void;
}

// TODOHI  add an initial values argument to createGlStatsStore
export const createGlStatsStore = () =>
  create<GlStatesStore>((set) => ({
    glStatsVisible: false,
    toggleGlStatsVisible: () =>
      set(($) => ({ glStatsVisible: !$.glStatsVisible })),

    glRenderStatsVisible: false,
    toggleGlRenderStatsVisible: () =>
      set(($) => ({ glRenderStatsVisible: !$.glRenderStatsVisible })),
  }));
