import React, { useMemo, useRef } from "react";
import { Timebase } from "../../models/timebase";
import { eventTypeLabel, positionIdLabel } from "../../constants/play";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
// import styles from './PlayEventList.module.css'
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const isFieldingPositionId = (positionId: number) =>
  1 <= positionId && positionId <= 9;
const isHittingPositionId = (positionId: number) =>
  10 <= positionId && positionId <= 13;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      // justifyContent: 'center',
      // justifyContent: 'right',
      justifyContent: "left",
      alignItems: "start",
      flexDirection: "column",
      flexWrap: "wrap",
      "& > *": {
        // margin: theme.spacing(0.5),
        margin: theme.spacing(0.125),
      },
    },
    batting: {
      backgroundColor: "#d72323",
    },
    fielding: {
      backgroundColor: "#2b60b0",
    },
    battingFuture: {
      backgroundColor: "#d72323",
      opacity: futureOpacity,
    },
    fieldingFuture: {
      backgroundColor: "#2b60b0",
      opacity: futureOpacity,
    },
    defaultFuture: {
      opacity: futureOpacity,
    },
  })
);

const futureOpacity = 0.3;

interface EventItemProps {
  event: any;
  classes: any;
  onClick: (event: any) => void;
  timebase: Timebase;
}

const EventItem: React.FC<EventItemProps> = ({
  event,
  classes,
  onClick,
  timebase,
}) => {
  let ref = useRef();
  let time = useMemo(() => new Date(`${event.timeStamp}Z`).getTime(), [event]);
  let { playEvent, positionId } = event;
  let positionLabel = positionIdLabel[positionId];
  let eventLabel = eventTypeLabel[playEvent];
  let color = isFieldingPositionId(positionId)
    ? "primary"
    : isHittingPositionId(positionId)
    ? "secondary"
    : "default";
  let classname = isFieldingPositionId(positionId)
    ? "fielding"
    : isHittingPositionId(positionId)
    ? "batting"
    : "default";

  let avatar = positionLabel !== "" ? <Avatar>{positionLabel}</Avatar> : null;

  // TODOHI  add useEffect and ref to set opacity based on time? no difference in performance?
  // useEffect(() => {
  //     if (ref.current) {
  //         // @ts-ignore
  //         ref.current.style.opacity = timebase.time >= time? 1 : futureOpacity// ref.current.style.opacity = `${timebase.time >= time? 1 : .2}`
  //     }
  // }, [timebase.time])

  if (timebase.time < time) {
    if (classname === "fielding") {
      classname = "fieldingFuture";
    } else if (classname === "batting") {
      classname = "battingFuture";
    } else {
      classname = "defaultFuture";
    }
  }

  return (
    // @ts-ignore
    <Chip
      ref={ref}
      className={classes[classname]}
      // variant='outlined'
      size="small"
      avatar={avatar}
      label={eventLabel}
      color={color}
      onClick={() => onClick(event)}
    />
  );
};

interface Props {
  playData: any;
  timebase: Timebase;
}

export const PlayEventList: React.FC<Props> = ({ playData, timebase }) => {
  let classes = useStyles({ time: timebase.time });
  let { trackedEvents } = playData;

  let handleClick = (event: any) => {
    let time = new Date(`${event.timeStamp}Z`).getTime();

    timebase.slipToTime(time);
  };

  let eventItems = trackedEvents.map((event: any, i: number) => (
    <EventItem
      key={i}
      event={event}
      classes={classes}
      onClick={handleClick}
      timebase={timebase}
    />
  ));

  return <div className={classes.root}>{eventItems}</div>;
};
