import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useAxios from "axios-hooks";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { PlayPicker } from "./components/PlayPicker";
import Snackbar from "@material-ui/core/Snackbar";
import { ScoutFrame } from "./components/ScoutFrame";
import { ApiClient } from "./api";
import {
  GamePlay,
  PlayTrackingState,
} from "field-of-things/src/types/statsApi";
import CssBaseline from "@material-ui/core/CssBaseline";
import { toGumboUrl } from "./util/gameUtil";
import type { PlayFrameState } from "field-of-things/src/components/PlayFrame";
import { createPlayViewRootStore } from "field-of-things/src/stores";
import useHashParam from "./util/use-hash-param-wrapper";
import { appSpaces } from "./components/SpaceSelect";
import {
  WrappedDiagnosticsSpace,
  WrappedStoryboardSpace,
} from "./components/App";
import "./App.css";
import { VenueStore } from "field-of-things/src/stores/venueStore";
import { StatsApiStore } from "field-of-things/src/stores/statsApiStore";
import { useOktaAuth } from "@okta/okta-react";
import { FigureModelStore } from "field-of-things/src/stores/figureModelStore";

import { prepTracking } from "field-of-things/src/util/tracking-data-util";

const defaultGamePlay = {
  gamePk: "634431",
  playId: "8665df72-d655-47ed-b7bd-1bbd605c229c",
};

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

const defaultPlayTracking: PlayTrackingState = {
  isReady: false,
  play: null,
  boxscore: undefined,
  tracking: undefined,
  isLoading: false,
  error: null,
};

export function App() {
  const pathExpression = /\/games\/([0-9]+)\/plays\/(.*)/;
  const { pathname } = window.location;
  const match = pathname.match(pathExpression);
  const defaultGamePk =
    match && match.length === 3 ? match[1] : defaultGamePlay.gamePk;
  const defaultPlayId =
    match && match.length === 3 ? match[2] : defaultGamePlay.playId;

  const [gamePk, setGamePk] = useHashParam("game", defaultGamePk);
  const [playId, setPlayId] = useHashParam("play", defaultPlayId);
  const [timeHashParam, setTimeHashParam] = useHashParam("time", undefined);
  const [cameraHashParam, setCameraHashParam] = useHashParam("cam", undefined);
  const [appSpace, setAppSpace] = useState(appSpaces.main);
  const initialPlayFrameStateRef = useRef({
    time: timeHashParam !== undefined ? +timeHashParam : timeHashParam,
    cam: cameraHashParam,
  });

  const [isPlayPickerOpen, setIsPlayPickerOpen] = useState(false);
  // TODO    rename to selectedGamePlay?
  const [displayGamePlay, setDisplayGamePlay] = useState<GamePlay>({
    gamePk,
    playId,
  });
  // TODO    rename to simply playTracking?
  const [playTrackingState, setPlayTrackingState] = useState<PlayTrackingState>(
    defaultPlayTracking
  );
  const [isPlayLoadingNoticeOpen, setIsPlayLoadingNoticeOpen] = useState(true);
  const rootStore = useMemo(() => createPlayViewRootStore(), []);
  const useVenueStore = rootStore?.useVenueStore;
  const useFigureModelStore = rootStore?.useFigureModelStore;
  const useStatsApiStore = rootStore?.useStatsApiStore;
  const selectedVenueId = useVenueStore(($: VenueStore) => $.selectedVenueId);
  const playDataVenueId = useVenueStore(($: VenueStore) => $.playDataVenueId);
  const setPlayDataVenueId = useVenueStore(
    ($: VenueStore) => $.setPlayDataVenueId
  );
  const setBoxscoreData = useStatsApiStore(
    ($: StatsApiStore) => $.setBoxscoreData
  );
  const setPlayData = useStatsApiStore(($: StatsApiStore) => $.setPlayData);
  const setTrackingData = useStatsApiStore(
    ($: StatsApiStore) => $.setTrackingData
  );
  const setVenueModelUrlDomain = useVenueStore(
    ($: VenueStore) => $.setVenueModelUrlDomain
  );
  const setFigureModelUrlDomain = useFigureModelStore(
    ($: FigureModelStore) => $.setFigureModelUrlDomain
  );

  const apiClientRef = useRef<ApiClient>();
  const { authState } = useOktaAuth();

  useEffect(() => {
    if (authState && authState.accessToken) {
      if (!apiClientRef.current) {
        apiClientRef.current = new ApiClient(authState.accessToken.value);
      } else {
        apiClientRef.current.updateInterceptor(authState.accessToken.value);
      }
    }
  }, [authState]);

  useEffect(() => {
    if (apiClientRef.current) {
      apiClientRef.current.fetchGamePlay({
        gamePlay: displayGamePlay,
        playTrackingState,
        setPlayTrackingState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClientRef, displayGamePlay]);

  useEffect(() => {
    if (apiClientRef.current) {
      apiClientRef.current.get3dModelUrlDomain().then((url) => {
        setFigureModelUrlDomain(url.data);
        setVenueModelUrlDomain(url.data);
      });
    }
  }, [apiClientRef, setVenueModelUrlDomain, setFigureModelUrlDomain]);

  const openPlayPicker = () => {
    setIsPlayPickerOpen(true);
  };

  const handlePlayPickerClose = () => {
    setIsPlayPickerOpen(false);
  };

  const playPicker = useMemo(() => {
    const handlePlaySelected = (selGamePk: string, selPlayId: string) => {
      setGamePk(selGamePk);
      setPlayId(selPlayId);
      setDisplayGamePlay({ gamePk: selGamePk, playId: selPlayId });
      setIsPlayPickerOpen(false);
      setIsPlayLoadingNoticeOpen(true);
    };

    return (
      <PlayPicker
        open={isPlayPickerOpen}
        onClose={handlePlayPickerClose}
        onPlaySelected={handlePlaySelected}
      />
    );
  }, [isPlayPickerOpen, setGamePk, setPlayId]);

  var playTracking = playTrackingState.tracking;
  var playData = playTrackingState.play;

  let { jointCleanup, upsample } = rootStore.useFigurePositionStore();
  let { truncatedHitExtensionEnabled } = rootStore.useBallStore();

  [playTracking, playData] = useMemo(
    () =>
      prepTracking({
        playData,
        playTracking,
        jointCleanup,
        upsample,
        truncatedHitExtensionEnabled,
      }),
    [
      playData,
      playTracking,
      jointCleanup,
      upsample,
      truncatedHitExtensionEnabled,
    ]
  );

  setPlayData(playData);
  setTrackingData(playTracking);
  setBoxscoreData(playTrackingState.boxscore);

  // loading gumbo here to get gumboPlay to pass to ScoutFrame and on to PlayLabel
  // TODO    need to coordinate with play-tracking fetch?
  const [
    { data: gumbo, /*loading:gumboLoading,*/ error: gumboError } /*refetch*/,
  ] = useAxios(toGumboUrl(gamePk));

  const handlePlayFrameStateChange = useCallback(
    (state: PlayFrameState) => {
      state.time !== undefined && setTimeHashParam(state.time);
      setCameraHashParam(state.cam);
    },
    [setCameraHashParam, setTimeHashParam]
  );

  let scoutFrame = null;
  if (playData && playTracking) {
    setPlayDataVenueId(playData.metaData.venue.id);
    const isSelectedPlay = playId === displayGamePlay.playId;
    const venueId = !selectedVenueId ? playDataVenueId : selectedVenueId;

    scoutFrame = appSpace === appSpaces.main && venueId && (
      <ScoutFrame
        rootStore={rootStore}
        appSpace={appSpace}
        onAppSpaceChange={setAppSpace}
        venueId={venueId}
        playTracking={playTracking}
        playData={playData}
        gumbo={gumbo}
        onPlayClicked={openPlayPicker}
        initialPlayFrameState={initialPlayFrameStateRef.current}
        onPlayFrameStateChange={handlePlayFrameStateChange}
      />
    );

    // hide play-loading-notice when this is the selected play
    if (isPlayLoadingNoticeOpen && isSelectedPlay) {
      setIsPlayLoadingNoticeOpen(false);
    }
  }

  const storyboardSpace = appSpace === appSpaces.storyboard && (
    <WrappedStoryboardSpace
      rootStore={rootStore}
      appSpace={appSpace}
      setAppSpace={setAppSpace}
      playData={playData}
      playTracking={playTracking}
      openPlayPicker={openPlayPicker}
    />
  );

  const diagnosticsSpace = appSpace === appSpaces.diagnostics && (
    <WrappedDiagnosticsSpace
      rootStore={rootStore}
      appSpace={appSpace}
      setAppSpace={setAppSpace}
      playData={playData}
      playTracking={playTracking}
      openPlayPicker={openPlayPicker}
    />
  );

  // TODOHI  handle gumboError
  gumboError && console.log("gumboError", gumboError);

  // TODOHI  handle null playTracking and playData in PlayView

  return (
    <CssBaseline>
      <ThemeProvider theme={darkTheme}>
        <div>
          {/*{playTrackingState.isLoading && <div>isLoading</div>}*/}
          {playTrackingState.error && <div>error</div>}
          <div className="App" style={{ height: "100vh" }}>
            {scoutFrame}
            {storyboardSpace}
            {diagnosticsSpace}
          </div>
        </div>

        {playPicker}

        <Snackbar
          open={isPlayLoadingNoticeOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          message="Loading Play"
        />
      </ThemeProvider>
    </CssBaseline>
  );
}
