// This is where the name and number are compositted on the texture

import * as THREE from "three";

export default class PlayerTexture extends THREE.Texture {
  constructor(teamImage, globalOptions, teamOptions, playerOptions) {
    let canvas = document.createElement("canvas");
    canvas.width = teamImage.width;
    canvas.height = teamImage.height;

    super(canvas);

    this.teamImage = teamImage;
    this.globalOptions = globalOptions;
    this.teamOptions = teamOptions;
    this.playerOptions = playerOptions;

    this.canvas = canvas;
    this.flipY = false;
    this.encoding = THREE.sRGBEncoding;

    this.update();
  }

  update() {
    const ctx = this.canvas.getContext("2d");
    ctx.globalCompositeOperation = "source-over";
    ctx.globalAlpha = 1;
    ctx.drawImage(this.teamImage, 0, 0);

    // Draw player skintone
    // ctx.globalCompositeOperation = 'darken';
    // ctx.globalAlpha = Math.max(0, this.skintone);
    // ctx.drawImage(this.teamMap.images.skintone, 0, 0);

    this.drawNumber(ctx);

    this.drawName(ctx);

    this.needsUpdate = true;
  }

  drawNumber(ctx) {
    ctx.textBaseline = "alphabetic";
    ctx.textBaseline = "middle";
    ctx.font = `${this.globalOptions.number.fontWeight} ${this.globalOptions.number.fontSize}px ${this.globalOptions.number.fontFamily}`;
    ctx.textAlign = "center";

    let number = Math.round(this.playerOptions.number).toString();

    const metrics = measureTextWithSpacing(
      ctx,
      number,
      this.globalOptions.number.x,
      this.globalOptions.number.y,
      this.globalOptions.number.letterSpacing
    );

    if (this.globalOptions.number.outlineWidth > 0 && this.teamOptions.number.outlineColor !== null) {
      ctx.lineWidth = this.globalOptions.number.outlineWidth;
      ctx.strokeStyle = this.teamOptions.number.outlineColor;
      metrics.letters.forEach((letter) => {
        ctx.strokeText(letter.char, letter.x, letter.y);
      });
    }

    metrics.letters.forEach((letter) => {
      ctx.lineWidth = this.globalOptions.number.borderWidth;
      ctx.strokeStyle = this.teamOptions.number.borderColor;
      ctx.fillStyle = this.teamOptions.number.color;
      ctx.fillText(letter.char, letter.x, letter.y);
      if (this.globalOptions.number.borderWidth > 0 && this.teamOptions.number.borderColor !== null) {
        ctx.strokeText(letter.char, letter.x, letter.y);
      }
    });
  }

  drawName(ctx) {
    const x = this.globalOptions.name.x;
    const y = this.globalOptions.name.y;

    ctx.textBaseline = "middle";
    ctx.font = `${this.globalOptions.name.fontWeight} ${this.globalOptions.name.fontSize}px ${this.globalOptions.name.fontFamily}`;
    ctx.textAlign = "center";
    ctx.fillStyle = this.teamOptions.name.color;

    const name = this.playerOptions.name.toUpperCase();

    const scale = getTextScaleToFit(
      ctx,
      name,
      0,
      this.globalOptions.name.maxWidth
    );
    ctx.font = `${this.globalOptions.name.fontWeight} ${
      this.globalOptions.name.fontSize * scale
    }px ${this.globalOptions.name.fontFamily}`;

    const metrics = measureTextWithSpacing(
      ctx,
      name,
      x,
      y,
      this.globalOptions.name.letterSpacing
    );

    // rotate metrics
    const radius = this.globalOptions.name.radius;
    const circumference = 2 * Math.PI * radius;
    const radians = (metrics.width * 2 * Math.PI) / circumference;
    metrics.letters.forEach((letter, i) => {
      let fraction = (letter.x - metrics.x) / metrics.width;
      let angleRad = fraction * radians - Math.PI / 2 - radians / 2;
      const point = polar(radius, angleRad);
      letter.x = x + point.x;
      letter.y = y + point.y + radius;
      letter.rotation = angleRad + Math.PI / 2;
    });

    if (this.globalOptions.name.outlineWidth > 0 && this.teamOptions.name.outlineColor !== null) {
      ctx.strokeStyle = this.teamOptions.name.outlineColor;
      ctx.lineWidth = this.globalOptions.name.outlineWidth;
      metrics.letters.forEach((letter, i) => {
        ctx.save();
        ctx.translate(letter.x, letter.y);
        ctx.rotate(letter.rotation);
        ctx.strokeText(letter.char, 0, 0);
        ctx.restore();
      });
    }

    metrics.letters.forEach((letter, i) => {
      ctx.save();
      ctx.translate(letter.x, letter.y);
      ctx.rotate(letter.rotation);
      ctx.fillText(letter.char, 0, 0);
      ctx.restore();
    });
  }
}

function polar(len, radians) {
  return { x: len * Math.cos(radians), y: len * Math.sin(radians) };
}

function getTextScaleToFit(ctx, text, letterSpacing, maxWidth) {
  const totalWidth =
    ctx.measureText(text).width + letterSpacing * (text.length - 1);
  let scale = 1;
  if (totalWidth > maxWidth) {
    scale = maxWidth / totalWidth;
  }
  return scale;
}

function measureTextWithSpacing(ctx, text, x, y, letterSpacing) {
  const totalWidth =
    ctx.measureText(text).width + letterSpacing * (text.length - 1);
  switch (ctx.textAlign) {
    case "right":
      x -= totalWidth;
      break;
    case "center":
    default:
      x -= totalWidth / 2;
      break;
  }
  const measurements = {
    x: x,
    width: totalWidth,
    letters: [],
  };
  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);
    const letterSize = ctx.measureText(char).width;
    let spaceBefore = 0;
    let spaceAfter = letterSize;
    switch (ctx.textAlign) {
      case "right":
        spaceBefore = letterSize;
        spaceAfter = 0;
        break;
      case "center":
      default:
        spaceBefore = letterSize / 2;
        spaceAfter = letterSize / 2;
        break;
    }
    x += spaceBefore;
    measurements.letters.push({ x, y, char });
    x += spaceAfter + letterSpacing;
  }
  return measurements;
}
