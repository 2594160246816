import { useMemo } from "react";
import { Timebase } from "../../models/timebase";
import { TaggedTimeline } from "../TaggedTimeline";
import { PlaybackControls, PlaybackTimeElapsed } from "../PlaybackControls";
import {
  getTrackedEventAbbreviation,
  toTrackedEventTimeStampAsMillis,
} from "../../util/trackedEvent-util";
import { useHotkeys } from "react-hotkeys-hook";
import styles from "./PlayTime.module.css";
import { RootStore } from "../../stores";

interface Props {
  timebase: Timebase;
  trackedEvents: any[];
  rootStore: RootStore;
}

export const PlayTime = (props: Props) => {
  let { timebase, trackedEvents, rootStore } = props;

  const { useTimelineStore } = rootStore;
  const { hotkeysEnabled, tagsEnabled } = useTimelineStore();

  let tags: any[] = useMemo(
    () =>
      tagsEnabled && trackedEvents
        ? trackedEvents.map((event: any) => {
            let { /*playEventType,*/ playEventId: id } = event;
            let time = toTrackedEventTimeStampAsMillis(event);
            let text = getTrackedEventAbbreviation(event);

            return {
              id,
              time,
              text,
            };
          })
        : [],
    [trackedEvents, tagsEnabled]
  );

  // stops playback and jumps to event's time
  let handleTagClick = (id: any) => {
    let event = trackedEvents.find((event: any) => event.playEventId === id);
    let time = toTrackedEventTimeStampAsMillis(event);

    // timebase.stop()
    // timebase.time = time
    timebase.slipToTime(time);
  };

  let slipToRelease = () => {
    let pTag = tags.find((tag) => tag.text === "P");

    pTag && timebase.slipToTime(pTag.time);
  };

  useHotkeys("p", () => hotkeysEnabled && slipToRelease(), [
    hotkeysEnabled,
    tags,
  ]);

  // TODOHI  style 100% width (and height?)
  return (
    <div className={[styles.touchAction, styles.noUserSelect].join(" ")}>
      <PlaybackTimeElapsed timebase={timebase} />
      <TaggedTimeline
        timebase={timebase}
        tags={tags}
        onTagClick={handleTagClick}
      />
      <PlaybackControls
        timebase={timebase}
        hotkeys={hotkeysEnabled}
        rootStore={rootStore}
      />
    </div>
  );
};
