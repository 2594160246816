// export type ViewTag = 'hh' | 'cf' | 'um' | 'pi'

// TODOHI  need lookAt and fov too?
export const viewMap: { [index: string]: any } = {
  hh: {
    tag: "hh",
    position: [0, 33, 37],
  },
  cf: {
    tag: "cf",
    position: [-3, 10, -90],
  },
  um: {
    tag: "um",
    position: [0, 6, 10],
  },
  pi: {
    tag: "pi",
    position: [0, 6, -65],
  },
  oh: {
    // overhead
    tag: "oh",
    position: [0, 55, -30],
  },
  fb: {
    tag: "fb",
    position: [95, 10, -85],
  },
  sb: {
    tag: "sb",
    position: [0, 10, -160],
  },
  tb: {
    tag: "tb",
    position: [-90, 10, -85],
  },
  he: {
    tag: "he",
    position: [57.28, 28.96, -434.35],
  },
};

export const ViewTags = Object.keys(viewMap);

// TODO    calculate ViewTag from ViewTags
export const ViewTag = {
  hh: "hh",
  cf: "cf",
  um: "um",
  pi: "pi",
  oh: "oh",
  fb: "fb",
  sb: "sb",
  tb: "tb",
  he: "he",
};

export const toCamera = (viewTag: string) => {
  // resort to hh for unexpected viewTag
  if (!ViewTags.includes(viewTag)) {
    viewTag = ViewTag.hh;
  }

  return viewMap[viewTag];
};
