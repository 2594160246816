import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import { allPositions } from "field-of-things/src/models/positions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  sliderRoot: {
    width: 200,
  },
  multiSelect: {
    width: 160,
  },
  multiSelectControl: {
    alignItems: "flex-start",
  },
  figureButton: {
    margin: theme.spacing(0.5),
  },
}));

const ballTypesMap = [
  {
    type: "omni",
    label: "Omni",
    hint: "Always Showing",
  },
  {
    type: "leading",
    label: "Leading",
    hint: "Visible until time reached",
  },
  {
    type: "trailing",
    label: "Trailing",
    hint: "Revealed when time reached",
  },
];

interface Props {
  timebase: any;
  useFigureStrobeStore: any;
}

export const StrobeUniformKnobs = ({
  timebase,
  useFigureStrobeStore,
}: Props) => {
  let {
    strobeInTime,
    setStrobeInTime,
    strobeOutTime,
    setStrobeOutTime,
    setStrobeAnchorTime,
    strobeInterval,
    setStrobeInterval,
    strobeOpacity,
    setStrobeOpacity,
    strobeVisibilityType,
    setStrobeVisibilityType,
    strobeShadowsEnabled,
    setStrobeShadowsEnabled,
    visiblePositionIds,
    setVisiblePositionIds,
  } = useFigureStrobeStore();

  const classes = useStyles();

  let handleStrobeIntervalChange = (event: any, value: any) => {
    setStrobeInterval(value);
  };

  let handleStrobeOpacityChange = (event: any, value: any) => {
    setStrobeOpacity(value);
  };

  let handleSetAnchorButtonClick = () => {
    let { time } = timebase;

    setStrobeAnchorTime(time);
  };

  let handleSetInTimeButtonClick = () => {
    let { time } = timebase;

    setStrobeInTime(time);
  };

  let handleClearInTimeButtonClick = () => {
    setStrobeInTime(-1);
  };

  let handleSetOutTimeButtonClick = () => {
    let { time } = timebase;

    setStrobeOutTime(time);
  };

  let handleClearOutTimeButtonClick = () => {
    setStrobeOutTime(-1);
  };

  const handleMultiSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    cb: (values: string[]) => void
  ) => {
    const { options } = event.target as HTMLSelectElement;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    cb(value);
  };

  return (
    // <div style={{ marginLeft: "1.5rem" }}>
    <div>
      <FormControl className={classes.formControl}>
        <FormControl component="fieldset">
          <FormControl className={classes.formControl}>
            <InputLabel id="visible-player-multiple-label">
              Position(s)
            </InputLabel>
            <Select
              labelId="visible-player-multiple-label"
              multiple
              native
              input={<Input />}
              className={classes.multiSelect}
              onChange={(e) =>
                handleMultiSelectChange(e, setVisiblePositionIds)
              }
              value={visiblePositionIds}
              name="enabled-figures"
              color="secondary"
            >
              {allPositions.map((position) => (
                <option key={position.id} value={position.id}>
                  {position.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormHelperText>Select one or many</FormHelperText>
          <br />
          <Tooltip
            title="Set Anchor to the Play's Current Time"
            placement="top"
          >
            <Button
              onClick={handleSetAnchorButtonClick}
              className={classes.figureButton}
              variant="contained"
              size="small"
            >
              Set Anchor Time
            </Button>
          </Tooltip>
          <FormHelperText>
            Anchor Time is the 'zero' time that intervals are relative to.{" "}
            <br />
            Defaults to pitch release when each play first loads.
          </FormHelperText>
          <br />
          <div>
            <div>
              <Tooltip title="Begin Time of Strobe Timespan" placement="top">
                <Button
                  onClick={handleSetInTimeButtonClick}
                  className={classes.figureButton}
                  size="small"
                  variant="contained"
                >
                  Set In Time
                </Button>
              </Tooltip>
              <Button
                disabled={strobeInTime === -1}
                onClick={handleClearInTimeButtonClick}
                className={classes.figureButton}
                size="small"
                variant="contained"
              >
                Clear In Time
              </Button>
            </div>
          </div>
          <div>
            <div>
              <Tooltip title="End Time of Strobe Timespan" placement="top">
                <Button
                  onClick={handleSetOutTimeButtonClick}
                  className={classes.figureButton}
                  size="small"
                  variant="contained"
                >
                  Set Out Time
                </Button>
              </Tooltip>
              <Button
                disabled={strobeOutTime === -1}
                onClick={handleClearOutTimeButtonClick}
                className={classes.figureButton}
                size="small"
                variant="contained"
              >
                Clear Out Time
              </Button>
            </div>
          </div>
          <br />
          <br />

          <div className={classes.sliderRoot}>
            {/*Strobe Interval (ms)*/}
            {`Strobe Interval: ${strobeInterval} ms`}
            <Slider
              min={15}
              max={5000}
              value={strobeInterval}
              aria-labelledby="strobe-interval"
              step={5}
              valueLabelDisplay="auto"
              onChange={handleStrobeIntervalChange}
            />
          </div>
          <FormHelperText>
            Caution: short intervals + many players strobing ➜ performance
            impact
          </FormHelperText>
          <br />

          <div className={classes.sliderRoot}>
            Strobed Figure Opacity
            <Slider
              min={0}
              max={1}
              value={strobeOpacity}
              aria-labelledby="strobe-opacity"
              step={0.01}
              valueLabelDisplay="auto"
              onChange={handleStrobeOpacityChange}
            />
          </div>
          <br />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="strobe-visibility-type-select">
              Strobe Visibility
            </InputLabel>
            <Tooltip
              title="How strobed figures are revealed or hidden as the play progresses"
              placement="top"
            >
              <NativeSelect
                value={strobeVisibilityType}
                onChange={(e) => setStrobeVisibilityType(e.target.value)}
                inputProps={{
                  name: "strobe-visibility-type-select",
                  id: "strobe-visibility-type-select",
                }}
              >
                {ballTypesMap.map((v: any, i: number) => (
                  <option key={i} value={v.type}>
                    {`${v.label} – ${v.hint}`}
                  </option>
                ))}
              </NativeSelect>
            </Tooltip>
          </FormControl>
          <br />
          <FormControlLabel
            control={
              <Switch
                checked={strobeShadowsEnabled}
                onChange={() => setStrobeShadowsEnabled(!strobeShadowsEnabled)}
                name="strobe-shadows-enabled-toggle"
                color="secondary"
                size="small"
              />
            }
            label="Shadows"
          />
        </FormControl>
      </FormControl>
    </div>
  );
};
