import create from "zustand";

export type figureColoringType = "none" | "position" | string; //| 'team'

export interface FigureAppearanceStoreDefaults {
  playerNamePlatesEnabled?: boolean;
}

export interface FigureAppearanceStore {
  figuresEnabled: boolean;

  setFiguresEnabled(figuresEnabled: boolean): void;

  figureColoring: figureColoringType;

  setFigureColoring(figureColoring: figureColoringType): void;

  selectedPlayerPositionId?: number;

  setSelectedPlayerPositionId(positionId: number): void;

  figureNodeSize: number;

  setFigureNodeSize(figureNodeSize: number): void;

  figureStickSize: number;

  setFigureStickSize(figureStickSize: number): void;

  blobHeadEnabled: boolean;

  setBlobHeadEnabled(blobHeadEnabled: boolean): void;

  stickHeadEnabled: boolean;

  setStickHeadEnabled(stickHeadEnabled: boolean): void;

  headPointsEnabled: boolean;

  setHeadPointsEnabled(headPointsEnabled: boolean): void;

  playerNamePlatesEnabled: boolean;

  setPlayerNamePlatesEnabled(playerNamePlatesEnabled: boolean): void;

  playerNamePlatesEnabledPerPlayer: string[];

  setPlayerNamePlatesEnabledPerPlayer(
    playerNamePlatesEnabledPerPlayer: string[]
  ): void;

  playerNamePlateScale: number;

  setPlayerNamePlateScale(playerNamePlateScale: number): void;

  visiblePositionIds: string[];

  setVisiblePositionIds(visiblePositionIds: string[]): void;

  stickFigureOpacity: number;

  setStickFigureOpacity(stickFigureOpacity: number): void;
}

// TODOHI  add an initial values argument to createFigureAppearanceStore?
export const createFigureAppearanceStore = (
  defaults?: FigureAppearanceStoreDefaults
) =>
  create<FigureAppearanceStore>((set) => ({
    figuresEnabled: true,
    setFiguresEnabled: (figuresEnabled: boolean) => set({ figuresEnabled }),

    selectedPlayerPositionId: undefined,
    setSelectedPlayerPositionId: (positionId: number) =>
      set(($) => ({ selectedPlayerPositionId: positionId })),

    figureColoring: "position",
    setFigureColoring: (figureColoring: figureColoringType) =>
      set({ figureColoring }),

    figureNodeSize: 1,
    setFigureNodeSize: (figureNodeSize: number) => set({ figureNodeSize }),

    figureStickSize: 1,
    setFigureStickSize: (figureStickSize: number) => set({ figureStickSize }),

    blobHeadEnabled: true,
    setBlobHeadEnabled: (blobHeadEnabled: boolean) => set({ blobHeadEnabled }),

    stickHeadEnabled: false,
    setStickHeadEnabled: (stickHeadEnabled: boolean) =>
      set({ stickHeadEnabled }),

    headPointsEnabled: false,
    setHeadPointsEnabled: (headPointsEnabled: boolean) =>
      set({ headPointsEnabled }),

    playerNamePlatesEnabled:
      defaults && defaults.playerNamePlatesEnabled !== undefined
        ? defaults.playerNamePlatesEnabled
        : false,
    setPlayerNamePlatesEnabled: (playerNamePlatesEnabled: boolean) =>
      set({ playerNamePlatesEnabled }),

    playerNamePlatesEnabledPerPlayer: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
    ],

    setPlayerNamePlatesEnabledPerPlayer: (
      playerNamePlatesEnabledPerPlayer: string[]
    ) => set({ playerNamePlatesEnabledPerPlayer }),

    playerNamePlateScale: 20,
    setPlayerNamePlateScale: (playerNamePlateScale: number) =>
      set({ playerNamePlateScale }),
    visiblePositionIds: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
    ],
    setVisiblePositionIds: (visiblePositionIds: string[]) =>
      set({ visiblePositionIds }),

    stickFigureOpacity: 1,
    setStickFigureOpacity: (stickFigureOpacity: number) =>
      set({ stickFigureOpacity }),
  }));
