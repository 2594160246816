import { Vector3, Object3D, Quaternion } from "three";
import { setVector3 } from "../../util/three-util";
import { JointsMapType } from "../Figure";
import { eulerGLtoMLB } from "../../util/geometry-util";

let rad2deg = 180 / Math.PI;

// TODOHI  move to figureModelStore?
const defaultSpokeToNose = true; // false defaults to eyes when present

const traceAngleEnabled = false;

const traceAngle = (angle: number) => console.log("angle", angle * rad2deg);

// Note: Most calculations here are in terms of MLB coordinates since tracking is based on that. When getting world
// positions from objects, they are in GL coordinates and need to be transformed to MLB coordinates.

const forceVectorsEnabled = false;

const _vUpGL = new Vector3(0, 1, 0);
const _vMidEar = new Vector3();
const _vNeck = new Vector3();
// const _qWorldHead = new Quaternion();

// calculate vStem in MLB space
const toVStem = (
  Neck: any,
  MidEar: any,
  // qWorldHead: Quaternion,
  vStem = new Vector3()
) => {
  // // vStem = qWorldHead * vUp
  //
  // // TODOHI  need to use _vUpGL instead, since _qWorldHead is in GL space? then rotate vStem to MLB?
  // // _vStem.copy(_vUp).applyQuaternion(_qWorldHead);
  // _vStem.copy(_vUpGL).applyQuaternion(_qWorldHead);
  // // _vStem.copy(_vUpGL).applyQuaternion(head.quaternion);
  //
  // _vStem.applyEuler(new Euler(0.5 * Math.PI, 0, 0));
  //
  // // // _vStem.copy(_vUp);
  // // let q = new Quaternion().setFromEuler(new Euler(0, 0.5 * Math.PI, 0));
  // // _vStem.copy(_vUp).applyQuaternion(q);

  // didn't get above working, so calculating directly with Neck and MidEar for now
  // drawback: may not be in sync with actual head bone when twist or tilt are toggled off?
  // in the typical case both twist and tilt will n=be active. will investigate above when there is time.
  setVector3(_vMidEar, MidEar);
  setVector3(_vNeck, Neck);
  vStem.subVectors(_vMidEar, _vNeck); //.normalize();

  return vStem;
};

const _qWorldNeck = new Quaternion();
const _vForwardGL = new Vector3(0, 0, 1);

/**
 * apply pose orientation to vForward, the T-pose's forward vector
 * @param neckBone
 * @param vForwardPosed
 */
// TODO    would it be better to calculate a neck quaternion from tracking, rather than rely on the posed bone?
const toVForwardPosed = (neckBone: Object3D, vForwardPosed = new Vector3()) => {
  neckBone.getWorldQuaternion(_qWorldNeck);
  vForwardPosed.copy(_vForwardGL).applyQuaternion(_qWorldNeck);

  // transform from GL to MLB coordinates?
  vForwardPosed.applyEuler(eulerGLtoMLB);

  return vForwardPosed;
};

const _bNorm = new Vector3();

/**
 * Projects one vector onto another
 * see https://en.wikipedia.org/wiki/Vector_projection
 * a1 = (a • b^) * b^
 * or
 * ((a • b) / |b|^2) * b
 * or
 * a1 = ((a • b) / (b • b)) * b
 *
 * @param a - the vector to project
 * @param b - nonzero vector to project onto
 * @param a1 - projected vector
 */
const projectVector = (a: Vector3, b: Vector3, a1 = new Vector3()) => {
  _bNorm.copy(b).normalize();
  a1.copy(_bNorm).multiplyScalar(a.dot(_bNorm));

  return a1;
};

// diagnostics: some forced combinations useful for sanity checks
const forceVectors = (vForwardWorld: Vector3, vFwdTracking: Vector3) => {
  // ///////////////////////////
  // // results in right turning head
  // vForwardWorld.set(0, 0, 1);
  // vFwdTracking.set(1, 0, 0);
  // ///////////////////////////
  //
  // ///////////////////////////
  // results in partially right turning head
  vForwardWorld.set(0, 0, 1);
  vFwdTracking.set(1, 0, 1);
  ///////////////////////////
  //
  // // ///////////////////////////
  // // // results in left turning head
  // // vForwardWorld.set(0, 0, -1);
  // // vFwdTracking.set(1, 0, 0);
  // // ///////////////////////////
  //
  // ///////////////////////////
  // // same as no turn ==> vForwardWorld and vFwdTracking same coordinate space
  // vForwardWorld.set(0, 0, -1);
  // vFwdTracking.set(0, 0, -1);
  // ///////////////////////////
  //
  // ///////////////////////////
  // // results in head tilted toward left shoulder
  // vForwardWorld.set(0, 1, 0);
  // vFwdTracking.set(1, 1, 0);
  // ///////////////////////////
  // _vSpoke.set(0, 1, 0); // toward mound in mlb coords?
};

const _vCross = new Vector3();

// TODOHI  does this also work for horizontal figures?
const toAngle = (vFrom: Vector3, vTo: Vector3) => {
  let theta = vFrom.angleTo(vTo);

  // it appears .angleTo may only produce positive angles,
  // so we determine sign from the up/down direction of the cross product
  _vCross.copy(vFrom).cross(vTo);

  return _vCross.z < 0 ? -theta : theta;
};

const _vStem = new Vector3();
const _pNeck = new Vector3();
const _p1 = new Vector3();
const _p2 = new Vector3();
const _p3 = new Vector3();
const _p4 = new Vector3();
const _v1 = new Vector3();
const _vSpoke = new Vector3();
const _vForwardPosed = new Vector3();
const _vForwardPosedR = new Vector3();
const _q = new Quaternion();

// TODO    include defaultSpokeToNose, nose or midEye priority, in args?
export const applyHeadTurn = (
  root: any,
  jointsMap: JointsMapType,
  turnVizEnabled = false
) => {
  let {
    Neck: NeckJoint,
    MidEar: MidEarJoint,
    Nose: NoseJoint,
    MidEye: MidEyeJoint,
  } = jointsMap;
  let p1 = defaultSpokeToNose
    ? NoseJoint || MidEyeJoint
    : MidEyeJoint || NoseJoint;

  if (!NeckJoint || !MidEarJoint || !p1) {
    // can't calculate turn
    return;
  }

  let neckBone = root.getObjectByName("neck");
  let headBone = root.getObjectByName("head");

  setVector3(_p1, p1);
  setVector3(_pNeck, NeckJoint);

  // TODO    use MidShoulder to calculate vStem instead of Neck?
  toVStem(NeckJoint, MidEarJoint, _vStem);

  toVForwardPosed(neckBone, _vForwardPosed);

  // _vForwardPosedR is the 'rejection' of _vForwardPosed from vStem
  _p3.copy(_pNeck).add(_vForwardPosed);
  projectVector(_vForwardPosed, _vStem, _p4);
  _p4.add(_pNeck);
  _vForwardPosedR.subVectors(_p3, _p4);

  // _vSpoke is the 'rejection' of v1 from vStem
  _v1.subVectors(_p1, _pNeck);
  projectVector(_v1, _vStem, _p2);
  _p2.add(_pNeck);
  _vSpoke.subVectors(_p1, _p2);

  // diagnostics: forceVectors used to check downstream with contrived vectors
  forceVectorsEnabled && forceVectors(_vForwardPosed, _vSpoke);

  let theta = toAngle(_vForwardPosedR, _vSpoke);

  // diagnostics: to check results
  traceAngleEnabled && traceAngle(theta);

  _q.setFromAxisAngle(_vUpGL, theta);
  headBone.quaternion.premultiply(_q);

  // return calculated values for diagnostic visualization
  // it would thrash the GC less with a reusable turnValuesRec argument, but turnVizEnabled is typically false
  if (turnVizEnabled) {
    return {
      p1: _p1.clone(),
      p2: _p2.clone(),
      p3: _p3.clone(),
      p4: _p4.clone(),
      pNeck: _pNeck.clone(),
      vForwardPosed: _vForwardPosed.clone(),
      vForwardPosedR: _vForwardPosedR.clone(),
      v1: _v1.clone(),
      vSpoke: _vSpoke.clone(),
      vStem: _vStem.clone(),
    };
  }
};
