import React, { useMemo } from "react";
import * as THREE from "three";
import {
  halfPlateWidth,
  halfPlateLength,
  plateLength,
} from "../../constants/dimensions";
import { plateBlackColor } from "../../constants/colors";

export const Plate = () => {
  let plateShape = useMemo(() => {
    let shape = new THREE.Shape();
    let xP1 = 0;
    let xP2 = halfPlateWidth;
    let xP3 = -xP2;
    let yP1 = 0;
    let yP2 = halfPlateLength;
    let yP3 = plateLength;

    shape.moveTo(xP1, yP1); // plate tip (0, 0)
    shape.lineTo(xP2, yP2);
    shape.lineTo(xP2, yP3);
    shape.lineTo(xP3, yP3);
    shape.lineTo(xP3, yP2);
    shape.lineTo(xP1, yP1);

    return shape;
  }, []);

  return (
    <group>
      <mesh receiveShadow name="plate">
        <shapeBufferGeometry attach="geometry" args={[plateShape]} />
        <meshStandardMaterial
          attach="material"
          color="white"
          depthTest={true}
        />
      </mesh>
      <mesh
        name="plate-black"
        scale={[1.1, 1.1, 1]}
        position={[0, -0.08, -0.01]}
      >
        <shapeBufferGeometry attach="geometry" args={[plateShape]} />
        <meshLambertMaterial
          attach="material"
          color={plateBlackColor}
          depthTest={true}
          side={THREE.DoubleSide}
        />
      </mesh>
    </group>
  );
};
