import create from "zustand";

export type BallType = "texmap" | "rbi" | "nexus" | string;

// TODO    move to util or types?
export type BallSegmentType = "BaseballPitch" | "BaseballHit" | "BaseballBounce" | "BaseballThrow";

export interface BallStore {
  ballSize: number;

  setBallSize(ballSize: number): void;

  playDataBallTrackVisible: boolean;

  togglePlayDataBallTrackVisible(): void;

  mergedBallVisible: boolean;

  toggleMergedBallVisible(): void;

  ballSegmentsVisible: boolean;

  toggleBallSegmentsVisible(): void;

  recoBallVisible: boolean;

  toggleRecoBallVisible(): void;

  ballPosition: number | null;

  setBallPosition(ballPosition: number): void;

  ballPadVisible: boolean;

  toggleBallPadVisible(): void;

  ballType: BallType;

  setBallType(ballType: BallType): void;

  ballTrailsEnabled: {
    hit: boolean,
    pitch: boolean,
    bounce: boolean,
    throw: boolean,
  };

  setBallTrailsEnabled(ballTrailsEnabled: any): void;

  visibleSegmentTypes: BallSegmentType[];

  setVisibleSegmentTypes(visibleSegmentTypes: any[]): void;

  ballTrailThickness: number;

  setBallTrailThickness(ballTrailThickness: number): void;

  truncatedHitExtensionEnabled: boolean;

  setTruncatedHitExtensionEnabled(truncatedHitExtensionEnabled: boolean): void;

  ballTrailColor: string;
  setBallTrailColor(ballTrail: string): void;
}

export const createBallStore = () =>
  create<BallStore>((set) => ({
    ballSize: 3,
    setBallSize: (ballSize: number) => set({ ballSize }),

    playDataBallTrackVisible: false,
    togglePlayDataBallTrackVisible: () =>
      set(($) => ({
        playDataBallTrackVisible: !$.playDataBallTrackVisible,
      })),

    mergedBallVisible: true,
    toggleMergedBallVisible: () =>
      set(($) => ({ mergedBallVisible: !$.mergedBallVisible })),

    ballSegmentsVisible: true,
    toggleBallSegmentsVisible: () =>
      set(($) => ({ ballSegmentsVisible: !$.ballSegmentsVisible })),

    recoBallVisible: false,
    toggleRecoBallVisible: () =>
      set(($) => ({ recoBallVisible: !$.recoBallVisible })),

    ballPosition: null,
    setBallPosition: (ballPosition: number) => set({ ballPosition }),

    ballPadVisible: true,
    toggleBallPadVisible: () =>
      set(($) => ({ ballPadVisible: !$.ballPadVisible })),

    ballType: "texmap",
    setBallType: (ballType: BallType) => set({ ballType }),

    ballTrailsEnabled: {
      hit: false,
      pitch: false,
      bounce: false,
      throw: false
    },

    setBallTrailsEnabled: (ballTrailsEnabled: any) => {
      set({ ballTrailsEnabled });
    },

    visibleSegmentTypes: [],

    setVisibleSegmentTypes: (visibleSegmentTypes: any[]) =>
      set({ visibleSegmentTypes }),

    ballTrailThickness: 0.7,

    setBallTrailThickness: (ballTrailThickness: number) =>
      set({ ballTrailThickness }),

    truncatedHitExtensionEnabled: false,

    setTruncatedHitExtensionEnabled: (truncatedHitExtensionEnabled: boolean) =>
      set({ truncatedHitExtensionEnabled }),

    ballTrailColor: "#333",
    setBallTrailColor: (ballTrailColor: string) =>
      set({ ballTrailColor }),
  }));
