import React, { useEffect, useRef } from "react";
import { useThree } from "@react-three/fiber";
// import {DirectionalLightHelper} from 'three'
// import {useHelper} from 'drei'

// let directionalLightColor = '#fcf9ed'

const shadowMapSize = 4096;

interface Props {
  useLightingStore: any;
  shadows?: boolean;
}

export const Lights = ({ useLightingStore, shadows = false }: Props) => {
  let ref = useRef();
  let glClearColor = useLightingStore(($: any) => $.glClearColor);
  let directionalLightParams = useLightingStore(
    ($: any) => $.directionalLightParams
  );
  let {
    color: directionalColor,
    intensity: directionalIntensity,
    position: directionalPosition,
  } = directionalLightParams;
  let ambientLightParams = useLightingStore(($: any) => $.ambientLightParams);
  let { color: ambientColor, intensity: ambientIntensity } = ambientLightParams;
  let { gl } = useThree();

  // useHelper(ref, DirectionalLightHelper, 'teal')

  useEffect(() => {
    gl.setClearColor(glClearColor);
  }, [glClearColor, gl]);

  return (
    <>
      {/*<ambientLight intensity={.6}/>*/}
      <ambientLight
        color={ambientColor}
        // intensity={shadows ? .4 : .6}
        intensity={ambientIntensity}
      />

      {/*<pointLight position={[10, 10, 10]}/>*/}
      {/*<pointLight position={[10, 1000, 10]} intensity={.5}/>*/}
      <directionalLight
        ref={ref}
        // position={[0, 100, 60]}
        // position={[60, 100, 10]}
        // position={[240, 400, 200]}
        position={directionalPosition}
        // color={directionalLightColor}
        color={directionalColor}
        //  // intensity={.6}
        // intensity={shadows ? .8 : .6}
        // intensity={shadows ? .7 : .6}
        intensity={directionalIntensity}
        castShadow={shadows}
        shadow-mapSize-width={shadowMapSize}
        shadow-mapSize-height={shadowMapSize}
        // DirectionalLight uses an OrthographicCamera
        shadow-camera-near={5}
        // shadow-camera-far={300}
        shadow-camera-far={1000}
        shadow-camera-left={-350}
        shadow-camera-right={350}
        // skewed toward top to cover outfields
        // shadow-camera-top={450}
        // shadow-camera-bottom={-50}
        shadow-camera-top={450}
        shadow-camera-bottom={-50}
      />
    </>
  );
};
