import { RootStore } from "../../stores";
import { StatsApiNS } from "../../types";
import { FigureStrobeStore } from "../../stores/figureStrobeStore";
import { FrameFiguresGroup } from "./FrameFiguresGroup";
import React from "react";

interface Props {
  rootStore: RootStore;
  playData: StatsApiNS.PlayData;
  strobeTrackingFrames: any[];
  skeleton: any;
  strobeOpacity?: number;
  visiblePositionIds: string[];
  castShadow?: boolean;
  refs: any[];
}

// a set of FrameFigureGroups, each holding a frame of figures
export const StrobeFramesGroup = ({
  rootStore,
  playData,
  strobeTrackingFrames,
  skeleton,
  strobeOpacity = 0.2,
  visiblePositionIds,
  castShadow = false,
  refs,
}: Props) => {
  let { useFigureStrobeStore } = rootStore;

  let strobeFigures = useFigureStrobeStore(
    ($: FigureStrobeStore) => $.strobeFigures
  );
  let stickFigureEnabled =
    strobeFigures === "stick-figures" || strobeFigures === "both";
  let figureModelEnabled =
    strobeFigures === "figure-models" || strobeFigures === "both";

  let playMetadata = playData.metaData;
  let positionMap = playData.positionMap;
  let battingTeamId = playMetadata.battingTeam.id;
  let fieldingTeamId = playMetadata.fieldingTeam.id;
  let homeTeamId = playMetadata.homeTeam.id;
  let batSide = playMetadata.stat.play.details.batSide.code;

  let strobeFrameGroups = strobeTrackingFrames.map((frame: any, i) => (
    <FrameFiguresGroup
      stickFigureEnabled={stickFigureEnabled}
      figureModelEnabled={figureModelEnabled}
      homeTeamId={homeTeamId}
      battingTeamId={battingTeamId}
      fieldingTeamId={fieldingTeamId}
      batSide={batSide}
      positionMap={positionMap}
      key={frame.time}
      ref={refs[i]}
      frame={frame}
      skeleton={skeleton}
      opacity={strobeOpacity}
      rootStore={rootStore}
      visiblePositionIds={visiblePositionIds}
      castShadow={castShadow}
      nameplateEnabled={false}
    />
  ));

  return <group>{strobeFrameGroups}</group>;
};
