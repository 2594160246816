import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import {
  toClosestFrameTimes,
  toFramesFromTimes,
  toFrameTimes,
} from "./strobe-util";
import { toEventTime } from "../../util/play-util";
import {
  BEGIN_OF_PLAY_EVENT,
  END_OF_PLAY_EVENT,
} from "../../models/playEventTypes";
import { RootStore } from "field-of-things/src/stores";
import { StatsApiNS } from "../../types";
import { StrobeFramesGroup } from "./StrobeFramesGroup";

interface toStrobeTimesArgs {
  inTime: number;
  outTime: number;
  strobeAnchorTime: number;
  strobeInterval: number;
}

// Finds a series of strobe times based on intervals from the anchor time
const toIntervalStrobeTimes = ({
  inTime,
  outTime,
  strobeAnchorTime,
  strobeInterval,
}: toStrobeTimesArgs) => {
  let strobeTimes = [strobeAnchorTime];
  let t = strobeAnchorTime - strobeInterval;

  while (t >= inTime) {
    strobeTimes.unshift(t);
    t -= strobeInterval;
  }

  t = strobeAnchorTime + strobeInterval;
  while (t <= outTime) {
    strobeTimes.push(t);
    t += strobeInterval;
  }

  return strobeTimes;
};

interface toStrobeFrameTimesArgs {
  playData: any;
  playTracking: any;
  strobeInTime?: number;
  strobeOutTime?: number;
  strobeAnchorTime: number;
  strobeInterval: number;
}

// Finds the times of frames closest to the time series defined by strobeAnchorTime and strobeInterval
const toStrobeFrameTimes = ({
  playData,
  playTracking,
  strobeInTime = -1,
  strobeOutTime = -1,
  strobeAnchorTime,
  strobeInterval,
}: toStrobeFrameTimesArgs) => {
  let bopTime = toEventTime(playData, BEGIN_OF_PLAY_EVENT);
  let eopTime = toEventTime(playData, END_OF_PLAY_EVENT);
  let inTime = strobeInTime > -1 ? strobeInTime : bopTime;
  let outTime = strobeOutTime > -1 ? strobeOutTime : eopTime;

  let strobeTimes = toIntervalStrobeTimes({
    inTime,
    outTime,
    strobeAnchorTime,
    strobeInterval,
  });

  // TODO   change to toFramesAtTimes instead?
  // get the closest frame time, within some epsilon to each strobe time
  let frameTimes = toFrameTimes(playTracking);

  return toClosestFrameTimes({ strobeTimes, frameTimes });
};

interface Props {
  rootStore: RootStore;
  playData: StatsApiNS.PlayData;
  playTracking: StatsApiNS.SkeletalData;
  time: number;
  skeleton: any;
}

export const UniformIntervalStrobes = ({
  rootStore,
  playData,
  playTracking,
  time,
  skeleton,
}: Props) => {
  let { useFigureStrobeStore } = rootStore;
  let {
    strobeInTime,
    strobeOutTime,
    strobeAnchorTime,
    strobeInterval,
    strobeOpacity,
    strobeVisibilityType,
    strobeShadowsEnabled,
    visiblePositionIds,
  } = useFigureStrobeStore();
  // render a strobeFrame for each frame in strobeTrackingFrames
  let { strobeTimes, strobeFrames, refs } = useMemo(() => {
    // get the set of frame times for given figureStrobeStore settings
    let strobeFrameTimes = toStrobeFrameTimes({
      playData,
      playTracking,
      strobeInTime,
      strobeOutTime,
      strobeAnchorTime,
      strobeInterval,
    });
    let strobeTimes: number[] = _.uniq(_.compact(strobeFrameTimes));
    // get the set of frames for strobeTimes
    let strobeTrackingFrames = toFramesFromTimes(strobeTimes, playTracking);
    let refs = strobeTrackingFrames.map(() => React.createRef());

    let strobeFramesGroup = (
      <StrobeFramesGroup
        rootStore={rootStore}
        playData={playData}
        strobeTrackingFrames={strobeTrackingFrames}
        skeleton={skeleton}
        strobeOpacity={strobeOpacity}
        visiblePositionIds={visiblePositionIds}
        castShadow={strobeShadowsEnabled}
        refs={refs}
      />
    );

    return { strobeTimes, strobeFrames: strobeFramesGroup, refs };
  }, [
    playData,
    playTracking,
    rootStore,
    skeleton,
    strobeInTime,
    strobeOutTime,
    strobeAnchorTime,
    strobeInterval,
    strobeOpacity,
    visiblePositionIds,
    strobeShadowsEnabled,
  ]);

  // update visibilities
  useEffect(() => {
    refs.forEach((ref: any, i: number) => {
      if (ref.current) {
        let strobeTime = strobeTimes[i];
        let isVisible;

        switch (strobeVisibilityType) {
          case "leading":
            isVisible = strobeTime > time;
            break;
          case "trailing":
            isVisible = strobeTime < time;
            break;
          case "omni":
          default:
            isVisible = strobeTime !== time;
        }

        ref.current.visible = isVisible;
      }
    });
  }, [time, strobeTimes, strobeVisibilityType, refs]);

  return strobeFrames;
};
