import _uniq from "lodash/uniq";

export const toHalfInningLabel = (play: any) => {
  let { isTopInning, inning } = play.about;
  let half = isTopInning ? "▲" : "▼";

  return `${inning} ${half}`;
};

export const toPitcherLabel = (matchup: any) => {
  let { pitcher, pitchHand } = matchup;
  let { fullName } = pitcher;
  let handLabel = pitchHand.code === "L" ? "LHP" : "RHP";

  return `${fullName} (${handLabel})`;
};

export const toBatterLabel = (matchup: any) => {
  let { batter, batSide } = matchup;
  let { fullName } = batter;
  let handLabel = batSide.code === "L" ? "LHB" : "RHB";

  return `${fullName} (${handLabel})`;
};

export const toPitchers = (pas: any[]) => {
  let pitcherLabels = _uniq(pas.map((pa) => toPitcherLabel(pa.matchup)));

  return pitcherLabels.join(", ");
};
