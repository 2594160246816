import React, { useMemo, useRef } from "react";
import { FixedField } from "./FixedField";
import { VenueFieldGroup } from "../VenueFieldGroup/VenueFieldGroup";
import { Zone } from "./Zone";
import { GLTFField } from "./GLTFField";
import { FenceMate } from "../VenueFieldGroup/FenceMate";
import { fenceMap } from "../VenueFieldGroup/fences-lidar";
import { ReferencePolesGroup } from "../VenueFieldGroup/ReferencePolesGroup";
import { ThreeEvent } from "@react-three/fiber";
import { RootStore } from "../../stores";

interface Props {
  venueId: number;
  rootStore: RootStore;
  zone?: any;
  children?: any;
  shadows?: boolean;
}

const SUPPORTED_VENUES = [
  1,
  2,
  3,
  4,
  5,
  7,
  10,
  12,
  13,
  14,
  15,
  17,
  19,
  22,
  31,
  32,
  680,
  2392,
  2394,
  2395,
  2602,
  2680,
  2681,
  2889,
  3289,
  3309,
  3312,
  3313,
  4169,
  4705,
  5325,
  3140, // Polo Grounds
  2536, // Dunedin
  2756, // BUF, Sahlen Field
];

export const FieldGroup = (props: Props) => {
  let { venueId, rootStore, shadows, zone } = props;

  let { useCameraStore, useFieldAppearanceStore, useVenueStore } = rootStore;

  const useNexusModel = useVenueStore(($: any) => $.useNexusModel);
  const setGltfDownloadedPercentage = useVenueStore(
    ($: any) => $.setGltfDownloadedPercentage
  );
  const venueModelOverrideUrl = useVenueStore(
    ($: any) => $.venueModelOverrideUrl
  );
  const venueModelUrlDomain = useVenueStore(($: any) => $.venueModelUrlDomain);
  let zoneGroup = useMemo(
    () => zone && <Zone top={zone.top} bottom={zone.bottom} />,
    [zone]
  );
  let venueFieldGroup = useMemo(
    () => (
      <VenueFieldGroup
        venueId={SUPPORTED_VENUES.includes(venueId) ? venueId : undefined}
        useFieldAppearanceStore={useFieldAppearanceStore}
        shadows={shadows}
      />
    ),
    [venueId, useFieldAppearanceStore, shadows]
  );
  const showLidarReference = useVenueStore(($: any) => $.showLidarReference);
  const referencePolesEnabled = useVenueStore(
    ($: any) => $.referencePolesEnabled
  );

  const cameraType = useCameraStore(($: any) => $.cameraType);
  const orbitControlsParams = useCameraStore(($: any) => $.orbitControlsParams);
  const setOrbitControlsParams = useCameraStore(
    ($: any) => $.setOrbitControlsParams
  );

  const clickCnt = useRef(0);

  let handleDblClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();

    setTimeout(() => (clickCnt.current = 0), 300);
    clickCnt.current++;

    if (clickCnt.current === 2 && cameraType === "orbit-controls") {
      let { relocDest, relocating } = orbitControlsParams;

      if (!relocating) {
        relocDest.copy(e.point);
        setOrbitControlsParams({
          ...orbitControlsParams,
          relocating: true,
          relocStadium: true,
        });
      }
    }
  };

  const fieldModelUrl = venueModelOverrideUrl
    ? venueModelOverrideUrl
    : `https://storage.googleapis.com/mlb-bdata-npd-us-east4-fieldvision-data/fallback-ballparks/${venueId}/stadium.gltf`;
  const fallbackFieldModelUrl = `${venueModelUrlDomain}/venues/stadiumLow_v07.glb`;

  return (
    <group onClick={(e) => handleDblClick(e)}>
      <GLTFField
        visible={useNexusModel}
        fieldModelUrl={fieldModelUrl}
        fallbackFieldModelUrl={fallbackFieldModelUrl}
        onProgress={setGltfDownloadedPercentage}
      />
      {showLidarReference ? (
        <FenceMate
          points={fenceMap[venueId]}
          material={{ color: "red", transparent: true, opacity: 0.5 }}
        />
      ) : null}
      {referencePolesEnabled ? <ReferencePolesGroup venueId={venueId} /> : null}
      {!useNexusModel ? (
        <React.Fragment>
          {venueFieldGroup}
          <FixedField
            useFieldAppearanceStore={useFieldAppearanceStore}
            shadows={shadows}
          />
        </React.Fragment>
      ) : null}
      {/*<Zone top={zone.top} bottom={zone.bottom}/>*/}
      {zoneGroup}
      {/*<FixedField shadows={shadows}/>*/}
    </group>
  );
};
