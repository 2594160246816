import { MathUtils, Quaternion, Vector3 } from "three";
import _clamp from "lodash/clamp";
import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _findLastIndex from "lodash/findLastIndex";
import _minBy from "lodash/minBy";
import _range from "lodash/range";
import { bisector } from "d3-array";
import {
  easeCubicInOut,
  easeExpInOut,
  easePolyInOut,
  easeSinInOut,
} from "d3-ease";
import {
  getGenericSegment,
  mlbToGl,
  TimeSpan,
  XYZT,
  XYZTSeries,
} from "./flycam-util";
import { toCameraStartPosition, toStartTargetPos } from "./cameraStart";
import { toCameraMidpoint } from "./cameraMidpoint";
import { toCameraRestPos, toRestTime } from "./cameraRest";
import { toBallPointSeries } from "../../util/polynomial-util";
import { lerpPoints } from "../../util/geometry-util";
import { Easing } from "../../stores/flyCamStore";

// note: all times here in milliseconds relative to BoP
// play-relative seconds could work too, but stayed with milliseconds since toBallPointSeries handles ms

// TODO    move to a util
const toBatSide = (playData: any) =>
  playData.metaData.stat.play.details.batSide.code;

const distance = (d: XYZT) => Math.sqrt(d.x * d.x + d.y * d.y + d.z * d.z);

export interface RefPoint {
  name: string;
  p: XYZT;
}

interface toPositionSpansArgs {
  hitSeries: XYZTSeries;
  batSide: string;
  params: any;
}

export interface SpansRefPoints {
  spans: TimeSpan[];
  refPoints: RefPoint[];
}

const toPositionKeyframeSpans = ({
  hitSeries,
  batSide,
  params,
}: toPositionSpansArgs): SpansRefPoints => {
  let {
    startPosDistance,
    startPosAngle,
    startPosHeight,
    launchDistance,
    midpointCameraDistance,
    restDuration,
    restCameraDistance,
    launchRampDuration,
  } = params;
  let spans: TimeSpan[] = [];
  let refPoints: RefPoint[] = [];
  let tEnd = hitSeries[hitSeries.length - 1].t;

  // find the time when the launch distance is reached in hitSeries
  let launchBallPos = _find(
    hitSeries,
    (d: any) => distance(d) >= launchDistance
  );
  if (!launchBallPos) {
    return { spans, refPoints };
  }
  let tLaunch = launchBallPos?.t || 0;

  // get rampEndpoint for possible use by ReferenceMarks
  let tRampOut = tLaunch + launchRampDuration;
  let rampEndpoint = {
    ..._minBy(hitSeries, (d: XYZT) => Math.abs(d.t - tRampOut)),
  };

  let startPos = toCameraStartPosition({
    batSide,
    startPosDistance,
    startPosAngle,
    startPosHeight,
  });
  let {
    t: tMidpoint,
    p: midpointPos,
    ballPos: midpointBallPos,
  } = toCameraMidpoint({
    hitSeries,
    midpointCameraDistance,
  });
  let { t: tRest, p: restPos, ballPos: restBallPos } = toCameraRestPos({
    hitSeries,
    restDuration,
    restCameraDistance,
  });

  spans = [
    {
      name: "start",
      tIn: 0,
      tOut: tLaunch,
      markIn: startPos,
      markOut: startPos,
      tween: "lerp",
    },
    {
      name: "ascent",
      tIn: tLaunch,
      tOut: tMidpoint,
      markIn: startPos,
      markOut: midpointPos,
      tween: "lerp",
    },
    {
      name: "descent",
      tIn: tMidpoint,
      tOut: tRest,
      markIn: midpointPos,
      markOut: restPos,
      tween: "lerp",
    },
    {
      name: "rest",
      tIn: tRest,
      tOut: tEnd,
      markIn: restPos,
      markOut: restPos,
      tween: "lerp",
    },
  ];

  refPoints = [
    {
      name: "launchBallPos",
      p: launchBallPos,
    },
    {
      name: "rampEndpoint",
      p: rampEndpoint as XYZT,
    },
    {
      name: "midpointBallPos",
      p: midpointBallPos,
    },
    {
      name: "restBallPos",
      p: restBallPos,
    },
  ];

  return { spans, refPoints };
};

const easePolynomial = easePolyInOut.exponent(5);

const ease = (t: number, easingType: Easing = "sine") => {
  switch (easingType) {
    case "cubic":
      return easeCubicInOut(t);
    case "exponential":
      return easeExpInOut(t);
    case "sine":
      return easeSinInOut(t);
    case "^5 polynomial":
      return easePolynomial(t);
    case "none":
    default:
      return t;
  }
};

interface toSlicedKeyframeSpansArgs {
  span: TimeSpan;
  pBallIn: XYZT;
  pBallOut: XYZT;
  hitSeries: XYZTSeries;
  interval?: number;
  easingType?: Easing;
}

const _vX = new Vector3(1, 0, 0);

/**
 * Generates a series of slice spans from a keyframe span by slerping the camera orientation to the ball
 * and lerping the camera distance to the ball.
 */
const toSliceSpans = ({
  span,
  pBallIn,
  pBallOut,
  hitSeries,
  interval = 33, // ms
  easingType = "sine",
}: toSlicedKeyframeSpansArgs) => {
  let vBall1 = new Vector3(pBallIn.x, pBallIn.y, pBallIn.z);
  let v1 = new Vector3().subVectors(span.markIn, vBall1);
  let d1 = v1.length();
  v1.normalize();
  let q1 = new Quaternion().setFromUnitVectors(_vX, v1);

  let vBall2 = new Vector3(pBallOut.x, pBallOut.y, pBallOut.z);
  let v2 = new Vector3().subVectors(span.markOut, vBall2);
  let d2 = v2.length();
  v2.normalize();
  let q2 = new Quaternion().setFromUnitVectors(_vX, v2);

  let { tIn, tOut } = span;

  tOut += interval; // add interval in order to include a tail element in ballPositions

  // get ballPositions corresponding to the span
  let ballPositions = [
    ...hitSeries.filter((d: XYZT) => tIn <= d.t && d.t < tOut),
  ];

  let data = ballPositions.map((pBall: XYZT) => {
    let n = (pBall.t - tIn) / (tOut - tIn);
    n = ease(n, easingType);
    let { t } = pBall;
    let vBall = new Vector3().set(pBall.x, pBall.y, pBall.z);
    let d = MathUtils.lerp(d1, d2, n);
    let q = new Quaternion();

    q.slerpQuaternions(q1, q2, n);

    return { n, t, vBall, q, d };
  });

  // map to camera position at each time
  let keyframes = data.map((k: any, i: number) => {
    let { t, vBall, q, d } = k;
    let vCam = new Vector3().copy(_vX).multiplyScalar(d).applyQuaternion(q);

    return {
      t,
      pCam: vBall.clone().add(vCam),
    };
  });

  let spanKeyframes = keyframes.slice(0, keyframes.length - 1);

  // map keyframes to slice spans
  return spanKeyframes.map((k: KeyFrame, i: number) => {
    let j = keyframes[i + 1];

    return {
      tIn: k.t,
      tOut: j.t,
      markIn: k.pCam,
      markOut: j.pCam,
    };
  });
};

type KeyFrame = {
  t: number;
  pCam: Vector3;
};

/**
 * Generates sliced position spans from the ascent and descent spans in keyframe position spans by
 * using quaternions to interpolate camera orientation to the ball, and lerping camera distance to the ball.
 *
 * @param hitSeries
 * @param batSide
 * @param params
 */
const toPositionSliceSpans = ({
  hitSeries,
  batSide,
  params,
}: toPositionSpansArgs): SpansRefPoints => {
  let { spans: keyframeSpans, refPoints } = toPositionKeyframeSpans({
    hitSeries,
    batSide,
    params,
  });

  let { easingType } = params;
  let spans = [...keyframeSpans];
  let ascentSpanIndex = _findIndex(spans, { name: "ascent" });
  let ascentSpan = spans[ascentSpanIndex];
  let descentSpanIndex = _findIndex(spans, { name: "descent" });
  let descentSpan = spans[descentSpanIndex];
  let pBallLaunch = _find(refPoints, { name: "launchBallPos" })?.p;
  let pBallMidpoint = _find(refPoints, { name: "midpointBallPos" })?.p;
  let pBallRest = _find(refPoints, { name: "restBallPos" })?.p;

  if (!pBallLaunch || !pBallMidpoint || !pBallRest) {
    return { spans, refPoints };
  }

  let ascentSpanSeries = toSliceSpans({
    span: ascentSpan as TimeSpan,
    pBallIn: pBallLaunch as XYZT,
    pBallOut: pBallMidpoint as XYZT,
    hitSeries,
    easingType,
  });

  let descentSpanSeries = toSliceSpans({
    span: descentSpan as TimeSpan,
    pBallIn: pBallMidpoint as XYZT,
    pBallOut: pBallRest as XYZT,
    hitSeries,
    easingType,
  });

  spans.splice(descentSpanIndex, 1, ...descentSpanSeries);
  spans.splice(ascentSpanIndex, 1, ...ascentSpanSeries);

  return { spans, refPoints };
};

interface spliceRampIntervalArgs {
  flightSeries: XYZT[];
  duration: number;
  vStartTarget: Vector3;
}

/**
 * Mixes between a linear ramp and flightSeries across duration, updating v in place
 * @param flightSeries
 * @param duration
 * @param vStartTarget
 */
const mixRampInterval = ({
  flightSeries,
  duration,
  vStartTarget,
}: spliceRampIntervalArgs) => {
  let tStart = flightSeries[0].t;

  // find inclusive endIndex
  let tEnd = flightSeries[0].t + duration;
  let endIndex = _findLastIndex(flightSeries, (d: XYZT) => d.t <= tEnd); // TODO    <-- check!
  let indexRange = _range(0, endIndex);

  let pEnd = flightSeries[endIndex];
  let vEnd = new Vector3(pEnd.x, pEnd.y, pEnd.z);
  // lerpRamp: a parallel series from 0 to endIndex that linear interpolates between vStartTarget and vEnd
  let lerpRamp = indexRange.map((i: number) => {
    let n = i / endIndex;

    return lerpPoints(vStartTarget, vEnd, n);
  });

  //  inline replace flightSeries[i] with lerp(lerpRamp[i], flightSeries[i], n)
  indexRange.forEach((i: number) => {
    let p = flightSeries[i];
    let n = (p.t - tStart) / duration;

    let q = lerpPoints(lerpRamp[i], p, n);

    p.x = q.x;
    p.y = q.y;
    p.z = q.z as number;
  });
};

interface toTargetSpansArgs {
  hitSeries: XYZTSeries;
  params: any;
}

const toTargetSpans = ({ hitSeries, params }: toTargetSpansArgs) => {
  let {
    startLookAtY,
    startLookAtZ,
    launchDistance,
    launchRampDuration,
    restDuration,
  } = params;
  // find the time when the launch distance is reached in hitSeries
  let launchIndex = _findIndex(
    hitSeries,
    (d: any) => distance(d) >= launchDistance
  );

  if (launchIndex === -1) {
    // hitSeries too short to use
    return [];
  }

  let { t: tRest } = toRestTime({ hitSeries, restDuration });
  let restIndex = _findIndex(hitSeries, (d: any) => d.t >= tRest);

  if (restIndex === -1) {
    // hitSeries too short to use
    return [];
  }

  let vStartTarget = toStartTargetPos({ startLookAtY, startLookAtZ });
  let flightSeries: XYZT[] = _cloneDeep(
    hitSeries.slice(launchIndex, restIndex + 1)
  );

  if (flightSeries.length === 0) {
    return [];
  }

  // TODO   mix from vStartTarget to flightSeries across the ramp interval

  mixRampInterval({
    flightSeries,
    duration: launchRampDuration,
    vStartTarget,
  });

  let flightSeriesHead = flightSeries.slice(0, -1);
  let flightSpans: TimeSpan[] = flightSeriesHead.map((p: any, i: number) => {
    let q = flightSeries[i + 1];
    let markIn = new Vector3(p.x, p.y, p.z);
    let markOut = new Vector3(q.x, q.y, q.z);

    return {
      tIn: p.t,
      tOut: q.t,
      markIn,
      markOut,
    };
  });

  flightSpans.unshift({
    tIn: 0,
    tOut: flightSeries[0].t,
    markIn: vStartTarget,
    markOut: vStartTarget,
  });

  return flightSpans;
};

/**
 * Converts release-relative Seconds to BoP-relative ms
 * @param sample - {x, y, z, t}
 */
const toPlayRelativeMs = (sample: XYZT) => {
  let { x, y, z, t } = sample;

  t = Math.round(3000 + 1000 * t);

  return { x, y, z, t };
};

interface toCameraStateSpansArgs {
  playData: any;
  params: any;
}

/**
 * Generates position and target spans to be used in timeToCameraState. Called once to specify the spans of
 * camera state marks for the given parameters and play data.
 *
 * The results of toCameraStateSpans typically should be memoized by the caller.
 *
 * @param playData
 * @param params - flycam parameters
 * @return a set of span series for each camera state property
 */
export const toCameraStateSpans = ({
  playData,
  params,
}: toCameraStateSpansArgs) => {
  let batSide = toBatSide(playData);
  let hitSegment = getGenericSegment(playData, "BaseballHit");

  if (hitSegment) {
    let hitSeries: XYZTSeries = toBallPointSeries({
      segments: [hitSegment],
    }).map(toPlayRelativeMs);

    let { spans, refPoints } = toPositionSliceSpans({
      hitSeries,
      batSide,
      params,
    });
    let positionSpans = spans;
    let targetSpans = toTargetSpans({ hitSeries, params });

    return {
      positionSpans,
      targetSpans,
      refPoints,
    };
  } else {
    return {
      positionSpans: [],
      targetSpans: [],
      refPoints: [],
    };
  }
};

const vDefaultPosition = new Vector3(0, -50, 50);

interface timeToVectorArgs {
  spans: TimeSpan[];
  time: number;
  vector?: Vector3;
}

const timeToVector = ({
  spans,
  time,
  vector = new Vector3(),
}: timeToVectorArgs) => {
  let targetSpanIndex = _clamp(toTimeSpan(spans, time), 0, spans.length - 1);

  let span = spans[targetSpanIndex];

  if (!span) {
    return vDefaultPosition;
  }

  let n = _clamp((time - span.tIn) / (span.tOut - span.tIn), 0, 1);
  let { markIn, markOut, tween } = span;

  switch (tween) {
    case "lerp":
    case null:
    case undefined:
    default:
      vector.lerpVectors(markIn, markOut, n);
    // mlbToGl(_vTarget, _vTarget);
  }

  return vector;
};

const bisectTime = bisector((d: TimeSpan) => d.tIn).right;
const toTimeSpan = (spans: TimeSpan[], t: number) => bisectTime(spans, t) - 1;

const _vPosition = new Vector3();
const _v = new Vector3();
const _vTarget = new Vector3();
const _vDefaultTarget = new Vector3(0, 30, 5);

interface timeToCameraStateArgs {
  time: number;
  spanSet: any;
}

/**
 * Maps given play-relative time to the camera's position and target state. Called each frame
 * to update camera state for the current time.
 * @param time
 * @param spanSet - set of position and target series
 */
export const timeToCameraState = ({ time, spanSet }: timeToCameraStateArgs) => {
  let { positionSpans, targetSpans } = spanSet;

  timeToVector({ spans: positionSpans, time, vector: _v });
  mlbToGl(_v, _vPosition);

  if (targetSpans.length > 0) {
    timeToVector({ spans: targetSpans, time, vector: _vTarget });
  } else {
    _vTarget.copy(_vDefaultTarget);
  }

  console.assert(_vTarget.z >= 0, "_vTarget.z negative in timeToCameraState");

  return {
    time,
    position: _vPosition,
    target: _vTarget,
  };
};
