import React, { useMemo, useState } from "react";

import { Figure } from "./Figure";
import { FigureBoundingBox } from "./FigureBoundingBox";
import { NamePlate } from "./NamePlate";
import { FigureCaption } from "../FigureCaption";
import { toColor } from "./figureColor";
import { figureModelUrl } from "../FigureModel/assetUrlBuilder";
import { FigureModel } from "../FigureModel";
import { StatsApiNS } from "../../types";

import teamColorOptions from "../FigureModel/teamColorOptions.json";
import { ColorManager } from "../FigureModel/ColorManager";

import { RootStore } from "../../stores";

export type JointsMapType = { [key: string]: [number, number, number] };

export const groupNamePrefix = "figure-group-";

const boundingBoxVisible = false;

const pickingEnabled = false;

const pickSize = 2;

const colorManager = new ColorManager(teamColorOptions);

interface Props {
  frameNum: number;
  positionId: number;
  rootStore: RootStore;
  stickFigureEnabled?: boolean;
  figureModelEnabled?: boolean;
  figureData?: any;
  playerInfo: StatsApiNS.Player;
  jointsMap: any;
  skeleton: any;
  homeTeamId: number;
  battingTeamId: number;
  fieldingTeamId: number;
  batSide: string;
  onClick?: (id: string | number) => void;
  onHoverChange?: (hover: boolean) => void;
  stickFigureOpacity?: number;
  modelOpacity?: number;
  castShadow?: boolean;
  captionsEnabled?: boolean;
  nameplateEnabled?: boolean;
}

export const FigureGroup = (props: Props) => {
  let {
    frameNum,
    positionId,
    rootStore,
    stickFigureEnabled = true,
    figureModelEnabled = true,
    jointsMap,
    skeleton,
    figureData,
    playerInfo,
    homeTeamId,
    battingTeamId,
    fieldingTeamId,
    batSide,
    onClick,
    stickFigureOpacity = 1,
    modelOpacity = 1,
    castShadow = true,
    captionsEnabled = true,
    nameplateEnabled = true,
  } = props;

  let {
    useBallStore,
    useFigureAppearanceStore,
    useFigureModelStore,
  } = rootStore;

  let [hover, setHover] = useState(false);
  let [pick, setPick] = useState(false);
  let { ballPosition } = useBallStore();
  let {
    figureNodeSize,
    figureStickSize,
    figureColoring,
    setSelectedPlayerPositionId,
    playerNamePlatesEnabled,
    playerNamePlateScale,
    playerNamePlatesEnabledPerPlayer,
    visiblePositionIds,
  } = useFigureAppearanceStore();
  let color = toColor({
    figureData,
    figureColoring,
    homeTeamId,
    battingTeamId,
    fieldingTeamId,
    hover,
    pick,
  });

  let { figureModelUrlDomain } = useFigureModelStore();

  let handleClick = () => {
    setSelectedPlayerPositionId(positionId);
    pickingEnabled && setPick(!pick);
    onClick && onClick(positionId);
  };

  let handleHoverChange = (hoverValue: boolean) => {
    setHover(hoverValue);
  };

  // TODO    memoize? maybe not?
  // @ts-ignore
  let figureCaption = captionsEnabled && (
    <FigureCaption figureProps={props} ballPosition={ballPosition} />
  );

  let filter = visiblePositionIds.includes(positionId.toString());
  const playersParentTeamId = playerInfo && playerInfo.parentTeamId;

  let teamFigureUrl = useMemo(
    () =>
      figureModelUrl(
        positionId,
        battingTeamId,
        fieldingTeamId,
        homeTeamId,
        batSide,
        playersParentTeamId,
        figureModelUrlDomain
      ),
    [
      positionId,
      batSide,
      battingTeamId,
      fieldingTeamId,
      homeTeamId,
      playersParentTeamId,
      figureModelUrlDomain,
    ]
  );

  const homeOrAway: "home" | "away" =
    playersParentTeamId === homeTeamId ? "home" : "away";

  return (
    <group name={`${groupNamePrefix}${positionId}`}>
      {playerNamePlatesEnabled &&
        playerNamePlatesEnabledPerPlayer &&
        playerNamePlatesEnabledPerPlayer.includes(positionId.toString()) &&
        nameplateEnabled &&
        filter && (
          <NamePlate
            positionId={positionId}
            joints={jointsMap}
            playerInfo={playerInfo}
            side={homeOrAway}
            colorManager={colorManager}
            playerNamePlateScale={playerNamePlateScale}
          />
        )}

      {stickFigureEnabled && filter && (
        <Figure
          useFigureAppearanceStore={useFigureAppearanceStore}
          positionId={positionId}
          figureData={figureData}
          joints={jointsMap}
          skeleton={skeleton}
          color={color}
          nodeSize={pick ? pickSize : figureNodeSize}
          stickSize={pick ? pickSize : figureStickSize}
          opacity={stickFigureOpacity}
          castShadow={castShadow}
        />
      )}

      {figureModelEnabled && filter && (
        <FigureModel
          frameNum={frameNum}
          positionId={positionId}
          colorManager={colorManager}
          side={homeOrAway}
          teamPlayerModelUrl={teamFigureUrl}
          useFigureModelStore={useFigureModelStore}
          figureData={figureData}
          joints={jointsMap}
          playerInfo={playerInfo}
          opacity={modelOpacity}
          castShadow={castShadow}
        />
      )}

      <FigureBoundingBox
        joints={jointsMap}
        rootStore={rootStore}
        visible={boundingBoxVisible}
        figureData={figureData}
        onClick={handleClick}
        onHoverChange={handleHoverChange}
      >
        {figureCaption}
      </FigureBoundingBox>
    </group>
  );
};
