// export const defaultDirtColor = '#dcd7d0'
// export const defaultDirtColor = '#807b74'

export const baseColor = "#fafafa"; // white,

export const chalkColor = "#eee";

export const plateBlackColor = "#000";

// export const poleColor = '#ff0'
export const poleColor = "#ccc";

// // export const GRASS_COLOR = '#424242'
// export const GRASS_COLOR = '#a8a8a8'
//
// // export const SAND_COLOR = '#ababab'
// export const SAND_COLOR = '#cdcdcd'

// export const GRASS_COLOR = '#424242'
// export const GRASS_COLOR = '#7a7a7a'
// export const GRASS_COLOR = '#8E8E8E'
// export const GRASS_COLOR = '#919191'
export const GRASS_COLOR = "#788478";

// export const SAND_COLOR = '#ababab'
// export const SAND_COLOR = '#b5b5b5'
export const SAND_COLOR = "#BEBAAA";
