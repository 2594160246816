import create from "zustand";

export interface PlaySummaryStore {
  playSummaryEnabled: boolean;
  setPlaySummaryEnabled(playSummaryEnabled: boolean): void;
  togglePlaySummaryEnabled(): void;
  pathsEnabled: boolean;
  setPathsEnabled(pathsEnabled: boolean): void;
  togglePathsEnabled(): void;
  stepsEnabled: boolean;
  setStepsEnabled(stepsEnabled: boolean): void;
  toggleStepsEnabled(): void;
  pathOpacity: number;
  stepDotRadius: number;
  battingPathColor: string;
  setBattingPathColor(battingPathColor: string): void;
  fieldingPathColor: string;
  setFieldingPathColor(fieldingPathColor: string): void;
  genericPathColor: string;
  setGenericPathColor(genericPathColor: string): void;
  playerOverrideId: number;
  setPlayerOverrideId(playerOverrideId: number): void;
  playerOverridePathColor: string;
  setPlayerOverridePathColor(playerOverridePathColor: string): void;
  leftStepOpacity: number;
  rightStepOpacity: number;
}

export const createPlaySummaryStore = () =>
  create<PlaySummaryStore>((set) => ({
    playSummaryEnabled: false,
    setPlaySummaryEnabled: (playSummaryEnabled: boolean) =>
      set({ playSummaryEnabled }),
    togglePlaySummaryEnabled: () =>
      set(($) => ({ playSummaryEnabled: !$.playSummaryEnabled })),

    pathsEnabled: false,
    setPathsEnabled: (pathsEnabled: boolean) => set({ pathsEnabled }),
    togglePathsEnabled: () => set(($) => ({ pathsEnabled: !$.pathsEnabled })),

    stepsEnabled: false,
    setStepsEnabled: (stepsEnabled: boolean) => set({ stepsEnabled }),
    toggleStepsEnabled: () => set(($) => ({ stepsEnabled: !$.stepsEnabled })),
    pathOpacity: 0.7,
    stepDotRadius: 0.4,
    battingPathColor: "#98191d",
    setBattingPathColor: (battingPathColor: string) =>
      set({ battingPathColor }),
    fieldingPathColor: "#175e80",
    setFieldingPathColor: (fieldingPathColor: string) =>
      set({ fieldingPathColor }),
    genericPathColor: "#555",
    setGenericPathColor: (genericPathColor: string) =>
      set({ genericPathColor }),
    playerOverrideId: 0,
    setPlayerOverrideId: (playerOverrideId: number) =>
      set({ playerOverrideId }),
    playerOverridePathColor: "#FFF",
    setPlayerOverridePathColor: (playerOverridePathColor: string) =>
      set({ playerOverridePathColor }),
    leftStepOpacity: 0.5,
    rightStepOpacity: 0.99,
  }));
