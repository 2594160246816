import React from "react";
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox"
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Slider from "@material-ui/core/Slider";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import { TabPanel } from "./Knobs";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import { BallStore } from "field-of-things/src/stores/ballStore";
import { ColorPicker } from "../ColorPicker";
import { UseStore } from "zustand";
import { IoIosBaseball as BB } from "react-icons/io";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  ballSliderRoot: {
    width: 200,
  },
}));

const marks = [
  {
    value: 1,
    label: "1x",
  },
  {
    value: 2,
    label: "2x",
  },
  {
    value: 3,
    label: "3x",
  },
  {
    value: 4,
    label: "4x",
  },
  {
    value: 5,
    label: "5x",
  },
  {
    value: 6,
    label: "6x",
  },
  {
    value: 7,
    label: "7x",
  },
  {
    value: 8,
    label: "8x",
  },
  {
    value: 9,
    label: "9x",
  },
  {
    value: 10,
    label: "10x",
  },
];

const ballTypesMap = [
  {
    type: "texmap",
    label: "Texture Map",
  },
  {
    type: "rbi",
    label: "RBI Model",
  },
  {
    type: "nexus",
    label: "Nexus Model",
  },
];

interface Props {
  value: any;
  index: number;
  useBallStore: UseStore<BallStore>;
}

export const BallKnobsPanel = (props: Props) => {
  let { index, value, useBallStore } = props;
  let classes = useStyles();
  let {
    ballType,
    setBallType,
    playDataBallTrackVisible,
    togglePlayDataBallTrackVisible,
    mergedBallVisible,
    toggleMergedBallVisible,
    ballSegmentsVisible,
    toggleBallSegmentsVisible,
    recoBallVisible,
    toggleRecoBallVisible,
    ballSize,
    setBallSize,
    ballPadVisible,
    toggleBallPadVisible,
    ballTrailsEnabled,
    setBallTrailsEnabled,
    ballTrailThickness,
    setBallTrailThickness,
    truncatedHitExtensionEnabled,
    setTruncatedHitExtensionEnabled,
    ballTrailColor,
    setBallTrailColor,
    setVisibleSegmentTypes
  } = useBallStore();

  let handleBallTrailThicknessChange = (event: any, value: any) => {
    setBallTrailThickness(+value);
  };

  let handleTrailsChange = (event: any, value: any) => {
    const newTrails = {...ballTrailsEnabled, [event.target.name]: event.target.checked};
    setBallTrailsEnabled(newTrails);

    let trails = [];
    if (newTrails.hit) trails.push("BaseballHit");
    if (newTrails.pitch) trails.push("BaseballPitch");
    if (newTrails.bounce) trails.push("BaseballBounce");
    if (newTrails.throw) trails.push("BaseballThrow");
    setVisibleSegmentTypes(trails);
  }

  return (
    <TabPanel value={value} index={index}>
      <FormControl component="fieldset">
        <div className={classes.ballSliderRoot}>
          Ball Size
          <Slider
            min={1}
            max={10}
            value={ballSize}
            aria-labelledby="ball-size-slider"
            step={null}
            valueLabelDisplay="auto"
            marks={marks}
            onChange={(e, value) => setBallSize(+value)}
          />
          <FormHelperText>Multiple of Standard Ball Size</FormHelperText>
        </div>
      </FormControl>
      <br />
      <br />
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="ball-type-select">Ball Type</InputLabel>
        <NativeSelect
          value={ballType}
          onChange={(e) => setBallType(e.target.value)}
          inputProps={{
            name: "ball-type-select",
            id: "ball-type-select",
          }}
        >
          {ballTypesMap.map((v: any, i: number) => (
            <option key={i} value={v.type}>
              {v.label}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <br />
      <br />
      <FormControlLabel
        control={
          <Switch
            checked={mergedBallVisible}
            onChange={toggleMergedBallVisible}
            name="merged-ball"
            color="secondary"
            size="small"
          />
        }
        label="Merged Ball"
      />
      <FormHelperText>Merged Positional Data</FormHelperText>
      <br />
      <FormControlLabel
        control={
          <Switch
            checked={recoBallVisible}
            onChange={toggleRecoBallVisible}
            name="reco-ball"
            color="secondary"
            size="small"
          />
        }
        label="Smooth Ball"
      />
      <FormHelperText>
        Reconstructed from polynomials for 60 fps smooth motion
      </FormHelperText>
      <br />
      <br />
      <FormControlLabel
        control={
          <Switch
            checked={playDataBallTrackVisible}
            onChange={togglePlayDataBallTrackVisible}
            name="merged-ball-track"
            color="secondary"
            size="small"
          />
        }
        label="Merged Ball-Track"
      />
      <FormHelperText>Merged Positional Data</FormHelperText>
      <br />
      <FormControlLabel
        control={
          <Switch
            checked={ballSegmentsVisible}
            onChange={toggleBallSegmentsVisible}
            name="ball-segments"
            color="secondary"
            size="small"
          />
        }
        label="Ball Segment Tracks"
      />
      <FormHelperText>
        Line Tracks from Generic Segment Polynomials
      </FormHelperText>
      <br />
      <FormControlLabel
        control={
          <Switch
            checked={truncatedHitExtensionEnabled}
            onChange={() =>
              setTruncatedHitExtensionEnabled(!truncatedHitExtensionEnabled)
            }
            name="hit-segment-extension"
            color="secondary"
            size="small"
          />
        }
       label="Extend Truncated Hit Segments"
      />
      <FormHelperText>
        Extends Hit Polynomials to 10' Height at Tail
      </FormHelperText>
      <br />
      
      Ball Trails
      <FormGroup row>
        <FormControlLabel 
          control={ <Checkbox icon={<BB/>}  checkedIcon={<BB color="#fff"/>} checked={ballTrailsEnabled.pitch} onChange={handleTrailsChange} name="pitch" /> }
          label="Pitch"
        />
        <FormControlLabel 
          control={ <Checkbox icon={<BB/>}  checkedIcon={<BB color="#67d24c"/>} checked={ballTrailsEnabled.hit} onChange={handleTrailsChange} name="hit" /> }
          label="Hit"
        />
        <FormControlLabel 
          control={ <Checkbox icon={<BB/>}  checkedIcon={<BB color="#ec4d4d"/>} checked={ballTrailsEnabled.bounce} onChange={handleTrailsChange} name="bounce" /> }
          label="Bounce"
        />
        <FormControlLabel 
          control={ <Checkbox icon={<BB/>}  checkedIcon={<BB color="#e2d808"/>} checked={ballTrailsEnabled.throw} onChange={handleTrailsChange} name="throw" /> }
          label="Throw"
        />
      </FormGroup>

      <br />
      <h3>Trail color</h3>
        <FormControl component="fieldset">
          <ColorPicker
            color={ballTrailColor}
            onChange={color => setBallTrailColor(color)}
          />
        </FormControl>
      <br />
      <br />
      Trail Thickness (fraction of ball size)
      <Slider
        min={0.05}
        max={1}
        step={0.01}
        value={ballTrailThickness}
        onChange={handleBallTrailThicknessChange}
        valueLabelDisplay="auto"
        aria-labelledby="ball-trail-thickness"
      />
      <br />
      <FormControlLabel
        control={
          <Switch
            checked={ballPadVisible}
            onChange={toggleBallPadVisible}
            name="ball-pad"
            color="secondary"
            size="small"
          />
        }
        label="Ball Pad"
      />
      <FormHelperText>
        Mark on ground tracks the x,y position of the ball
      </FormHelperText>
      <br />
      <br />
    </TabPanel>
  );
};
