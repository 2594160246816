import React, { useEffect, useMemo, useRef, useState } from "react";

import { traceTimes } from "./util";
import {
  PlayFrame,
  PlayFrameState,
} from "field-of-things/src/components/PlayFrame";
import { PlayLabel } from "../PlayLabel";
import { PlayContext } from "../PlayContext";
import { Knobs } from "../Knobs";
import IconButton from "@material-ui/core/IconButton";
import TuneIcon from "@material-ui/icons/Tune";
import { FaLightbulb } from "react-icons/fa";
import { isiPad } from "../../util";
import styles from "./ScoutFrame.module.css";
import { TipsDialog } from "../TipsDialog";
import Tooltip from "@material-ui/core/Tooltip";
import { SpaceSelect } from "../SpaceSelect";
import { Timebase } from "field-of-things/src/models/timebase";
import { RootStore } from "field-of-things/src/stores";
import { StatsApiNS } from "field-of-things/src/types";

const doTraceTimes = false;

interface ScoutFrameProps {
  rootStore: RootStore;
  appSpace: string;
  onAppSpaceChange: (space: string) => void;
  venueId: number;
  playTracking: StatsApiNS.SkeletalData;
  trackingTimeOffset?: number;
  playData: StatsApiNS.PlayData;
  gumbo: any;
  onPlayClicked: () => void;
  initialPlayFrameState?: PlayFrameState;
  onPlayFrameStateChange?: (state: PlayFrameState) => void;
}

export const ScoutFrame: React.FC<ScoutFrameProps> = (props) => {
  const {
    rootStore,
    appSpace,
    onAppSpaceChange,
    venueId,
    playTracking,
    trackingTimeOffset = 0,
    playData,
    gumbo,
    onPlayClicked,
    initialPlayFrameState,
    onPlayFrameStateChange,
  } = props;
  let [isControlsOpen, setIsControlsOpen] = useState(false);
  let [isTipsOpen, setIsTipsOpen] = useState(false);
  const timebaseRef = useRef(new Timebase());
  const timebase = timebaseRef.current;

  // let [view, setView] = useState('hh')
  // let [showFullscreen, toggleFullscreen] = useToggle(false)
  // // const isFullscreen = useFullscreen(ref, showFullscreen, {onClose: () => toggleFullscreen(false)})
  // useFullscreen(ref, showFullscreen, {onClose: () => toggleFullscreen(false)})

  // // note: creating a root store here keeps open the possibility for multiple ScoutFrames in one app
  // let rootStore = useMemo(() => createPlayViewRootStore(), [])

  const playLabel = useMemo(
    () =>
      (playData && <PlayLabel playData={playData} gumbo={gumbo} />) || "---",
    [gumbo, playData]
  );
  const playContext = useMemo(
    () => playData && <PlayContext playData={playData} />,
    [playData]
  );
  const onIPad = isiPad();
  const scoutFrameStyle = onIPad
    ? styles.scoutFrameIPad
    : styles.scoutFrameGrid;
  const platform = onIPad ? "mobile" : undefined;

  // TODOHI  temp
  useEffect(() => {
    if (doTraceTimes && playTracking && playData) {
      console.log("playTracking, playData", playTracking, playData);
      traceTimes({ playTracking, playData });
    }
  }, [playTracking, playData]);

  const { useTimelineStore } = rootStore;
  const { setHotKeysEnabled } = useTimelineStore();

  return (
    <div id="scout-frame" className={scoutFrameStyle}>
      <div className={styles.summary} style={{ padding: "0 10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={onPlayClicked}
          >
            {playLabel}
          </div>
          {playContext}
        </div>
      </div>

      <div className={styles.knobs}>
        <Tooltip title="Settings" placement="bottom">
          <IconButton
            size="small"
            aria-label="open controls"
            onMouseDown={() => {
              setHotKeysEnabled(false);
              setIsControlsOpen(true);
            }}
          >
            <TuneIcon />
          </IconButton>
        </Tooltip>
      </div>

      <div className={styles.midRight}>
        <SpaceSelect space={appSpace} onChange={onAppSpaceChange} />
      </div>

      <div className={styles.playFrameContainer}>
        <PlayFrame
          timebase={timebase}
          rootStore={rootStore}
          playTracking={playTracking}
          playData={playData}
          venueId={venueId}
          trackingTimeOffset={trackingTimeOffset}
          layout={"stacked"}
          fullscreenLayout={"hud"}
          platform={platform}
          initialState={initialPlayFrameState}
          onStateChange={onPlayFrameStateChange}
        />
      </div>

      <Tooltip title="Tips" placement="top">
        <IconButton
          className={onIPad ? styles.tipsIPad : styles.tips}
          size="small"
          aria-label="open tips"
          onClick={() => setIsTipsOpen(true)}
        >
          <FaLightbulb />
        </IconButton>
      </Tooltip>

      <Knobs
        timebase={timebase}
        playData={playData}
        playTracking={playTracking}
        isOpen={isControlsOpen}
        onClose={() => {
          setHotKeysEnabled(true);
          setIsControlsOpen(false);
        }}
        rootStore={rootStore}
      />

      {isTipsOpen && <TipsDialog onClose={() => setIsTipsOpen(false)} />}
    </div>
  );
};
