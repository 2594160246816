import React, { useMemo } from "react";
import { toFiguresTracking } from "../../util/tracking-util";
import {
  toKeyPositionIds,
  isPlayerPositionId,
  isBattingTeamPositionId,
} from "../../util/play-util";
import { FigurePathGroup } from "./FigurePathGroup";
import { StatsApiNS } from "field-of-things/src/types";
import { PlaySummaryStore } from "field-of-things/src/stores/playSummaryStore";
import { UseStore } from "zustand";

const anyPlayerPositionIds = (figuresTracking: any[]) => {
  return figuresTracking.some((f) => isPlayerPositionId(f.positionId));
};

type positionIdType = number | null;

export type PlayRole =
  | "generic"
  | "batting"
  | "fielding"
  | "secondaryFielding"
  | "playerOverride";

// TODO: re-vist the typing below, having trouble getting this to be happy when setting them to "RoleMaterial"
export interface RoleMaterials {
  generic: any;
  batting: any;
  fielding: any;
  secondaryFielding: any;
}

interface toPlayRoleArgs {
  hasPlayerPositionIds: boolean;
  positionId: positionIdType;
  playerOverrideId: positionIdType;
  keyPositionIds?: number[];
}

interface DotProps {
  position: [number, number, number];
  material: any;
  [key: string]: any;
}

interface PlaySummaryProps {
  playTracking: StatsApiNS.SkeletalData;
  playData: StatsApiNS.PlayData;
  time: number;
  usePlaySummaryStore: UseStore<PlaySummaryStore>;
}

/**
 * Determines the playRole for a positionId
 * @param hasPlayerPositionIds – whether the play has any positionIds in the player range of 1 to 13
 * @param positionId
 * @param keyPositionIds – set of positionIds in the key playEvents
 */
const toPlayRole = ({
  hasPlayerPositionIds,
  positionId,
  playerOverrideId,
  keyPositionIds,
}: toPlayRoleArgs): PlayRole => {
  if (playerOverrideId === positionId) {
    return "playerOverride";
  } else if (!hasPlayerPositionIds) {
    return "generic";
  }

  if (positionId && isPlayerPositionId(positionId)) {
    let isBattingTeam = isBattingTeamPositionId(positionId);

    if (isBattingTeam) {
      // always identify batting positions, regardless of keyPositionIds
      return "batting";
    } else {
      // must be fielding
      if (keyPositionIds && keyPositionIds.includes(positionId)) {
        return "fielding";
      } else {
        return "secondaryFielding";
      }
    }
  }

  return "generic";
};

const Dot: React.FC<DotProps> = ({
  position,
  material,
  stepDotRadius,
  ...other
}) => (
  <mesh position={position} rotation={[Math.PI / 2, 0, 0]} {...other}>
    <cylinderBufferGeometry
      attach="geometry"
      args={[stepDotRadius, stepDotRadius, 0.01, 16]}
    />
    {material}
  </mesh>
);

const defaultPathPosition = [0, 0, 0];
const raisedPathPosition = [0, 0, 0.042]; // lifted 1/2" so red paths are not coplanar with blue paths

export const PlaySummary: React.FC<PlaySummaryProps> = ({
  playTracking,
  playData,
  time,
  usePlaySummaryStore,
}) => {
  let keyPositionIds = useMemo(() => toKeyPositionIds({ playData }), [
    playData,
  ]);
  let figuresTracking = useMemo(() => toFiguresTracking({ playTracking }), [
    playTracking,
  ]);
  let hasPlayerPositionIds = anyPlayerPositionIds(figuresTracking);
  let pathsEnabled = usePlaySummaryStore(
    ($: PlaySummaryStore) => $.pathsEnabled
  );
  let stepsEnabled = usePlaySummaryStore(
    ($: PlaySummaryStore) => $.stepsEnabled
  );
  let leftStepOpacity = usePlaySummaryStore(
    ($: PlaySummaryStore) => $.leftStepOpacity
  );
  let rightStepOpacity = usePlaySummaryStore(
    ($: PlaySummaryStore) => $.rightStepOpacity
  );
  let pathOpacity = usePlaySummaryStore(($: PlaySummaryStore) => $.pathOpacity);
  let battingPathColor = usePlaySummaryStore(
    ($: PlaySummaryStore) => $.battingPathColor
  );
  let fieldingPathColor = usePlaySummaryStore(
    ($: PlaySummaryStore) => $.fieldingPathColor
  );
  let genericPathColor = usePlaySummaryStore(
    ($: PlaySummaryStore) => $.genericPathColor
  );
  let playerOverrideId = usePlaySummaryStore(
    ($: PlaySummaryStore) => $.playerOverrideId
  );
  let playerOverridePathColor = usePlaySummaryStore(
    ($: PlaySummaryStore) => $.playerOverridePathColor
  );
  let stepDotRadius = usePlaySummaryStore(
    ($: PlaySummaryStore) => $.stepDotRadius
  );

  const roleMaterials = useMemo(
    () => ({
      generic: {
        stepMaterial: (
          <meshStandardMaterial attach="material" color={genericPathColor} />
        ),

        leftStepMaterial: (
          <meshStandardMaterial
            attach="material"
            color={genericPathColor}
            transparent={true}
            opacity={leftStepOpacity}
          />
        ),
        rightStepMaterial: (
          <meshStandardMaterial
            attach="material"
            color={genericPathColor}
            transparent={true}
            opacity={rightStepOpacity}
          />
        ),

        pathMaterial: (
          <meshStandardMaterial
            attach="material"
            color={genericPathColor}
            transparent={true}
            opacity={pathOpacity}
          />
        ),
        pathEndpointMaterial: (
          <meshStandardMaterial attach="material" color={genericPathColor} />
        ),
      },
      batting: {
        stepMaterial: (
          <meshStandardMaterial attach="material" color={battingPathColor} />
        ),

        leftStepMaterial: (
          <meshStandardMaterial
            attach="material"
            color={battingPathColor}
            transparent={true}
            opacity={leftStepOpacity}
          />
        ),
        rightStepMaterial: (
          <meshStandardMaterial
            attach="material"
            color={battingPathColor}
            transparent={true}
            opacity={rightStepOpacity}
          />
        ),
        pathMaterial: (
          <meshStandardMaterial
            attach="material"
            color={battingPathColor}
            transparent={true}
            opacity={pathOpacity}
          />
        ),
        pathEndpointMaterial: (
          <meshStandardMaterial attach="material" color={battingPathColor} />
        ),
      },
      fielding: {
        stepMaterial: (
          <meshStandardMaterial attach="material" color={fieldingPathColor} />
        ),

        leftStepMaterial: (
          <meshStandardMaterial
            attach="material"
            color={fieldingPathColor}
            transparent={true}
            opacity={leftStepOpacity}
          />
        ),
        rightStepMaterial: (
          <meshStandardMaterial
            attach="material"
            color={fieldingPathColor}
            transparent={true}
            opacity={rightStepOpacity}
          />
        ),
        pathMaterial: (
          <meshStandardMaterial
            attach="material"
            color={fieldingPathColor}
            transparent={true}
            opacity={pathOpacity}
          />
        ),
        pathEndpointMaterial: (
          <meshStandardMaterial attach="material" color={fieldingPathColor} />
        ),
      },
      secondaryFielding: {
        stepMaterial: (
          <meshStandardMaterial attach="material" color={genericPathColor} />
        ),

        leftStepMaterial: (
          <meshStandardMaterial
            attach="material"
            color={genericPathColor}
            transparent={true}
            opacity={leftStepOpacity}
          />
        ),
        rightStepMaterial: (
          <meshStandardMaterial
            attach="material"
            color={genericPathColor}
            transparent={true}
            opacity={rightStepOpacity}
          />
        ),
        pathMaterial: (
          <meshStandardMaterial
            attach="material"
            color={genericPathColor}
            transparent={true}
            opacity={pathOpacity}
          />
        ),
        pathEndpointMaterial: (
          <meshStandardMaterial attach="material" color={genericPathColor} />
        ),
      },
      playerOverride: {
        stepMaterial: (
          <meshStandardMaterial
            attach="material"
            color={playerOverridePathColor}
          />
        ),

        leftStepMaterial: (
          <meshStandardMaterial
            attach="material"
            color={playerOverridePathColor}
            transparent={true}
            opacity={leftStepOpacity}
          />
        ),
        rightStepMaterial: (
          <meshStandardMaterial
            attach="material"
            color={playerOverridePathColor}
            transparent={true}
            opacity={rightStepOpacity}
          />
        ),
        pathMaterial: (
          <meshStandardMaterial
            attach="material"
            color={playerOverridePathColor}
            transparent={true}
            opacity={pathOpacity}
          />
        ),
        pathEndpointMaterial: (
          <meshStandardMaterial
            attach="material"
            color={playerOverridePathColor}
          />
        ),
      },
    }),
    [
      battingPathColor,
      fieldingPathColor,
      genericPathColor,
      playerOverridePathColor,
      leftStepOpacity,
      pathOpacity,
      rightStepOpacity,
    ]
  );

  // create a group of path and step-dots for each figure (memoized on figuresTracking and time)
  let figureTracks = useMemo(() => {
    return figuresTracking.map((figureTracking) => {
      let { positionId, frames, steps } = figureTracking;
      let playRole = toPlayRole({
        hasPlayerPositionIds,
        positionId,
        playerOverrideId,
        keyPositionIds,
      });

      // skip when hasPlayerPositionIds && !player
      if (hasPlayerPositionIds && playRole === "generic") {
        return null;
      }

      let materials = roleMaterials[playRole];
      let { leftStepMaterial, rightStepMaterial } = materials;
      let clippedSteps =
        stepsEnabled && steps.filter((f: any) => f.time <= time);

      // TODOHI  handle option to use MPD when positionId available?

      return (
        <group key={figureTracking.positionId} userData={figureTracking}>
          {pathsEnabled && (
            <FigurePathGroup
              positionId={positionId}
              playRole={playRole}
              frames={frames}
              playData={playData}
              time={time}
              roleMaterials={roleMaterials}
              position={
                positionId <= 9 ? defaultPathPosition : raisedPathPosition
              }
            />
          )}
          {stepsEnabled &&
            clippedSteps.map((step: any, i: number) => {
              let material =
                step.joint === "LAnkle" ? leftStepMaterial : rightStepMaterial;

              return (
                <Dot
                  key={i}
                  position={[step.p[0], step.p[1], step.p[2]]}
                  material={material}
                  stepDotRadius={stepDotRadius}
                />
              );
            })}
        </group>
      );
    });
  }, [
    playData,
    figuresTracking,
    time,
    hasPlayerPositionIds,
    keyPositionIds,
    playerOverrideId,
    pathsEnabled,
    stepsEnabled,
    roleMaterials,
    stepDotRadius,
  ]);

  return <group>{figureTracks}</group>;
};
