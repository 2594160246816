import React, { useMemo } from "react";
import { toVenuePathShapes } from "../../venues/venue-svg-util";
import {
  meshBasicMaterialTypeName /*, meshStandardMaterialTypeName*/,
} from "../../stores/fieldApperanceStore";
import { Vector3 } from "three";

const defaultVenueId = "5";

const extrudeSettings = {
  depth: 0.1,
  bevelEnabled: true,
  bevelSegments: 2,
  steps: 2,
  bevelSize: 0.1,
  bevelThickness: 0.1,
};

export const VenueFieldShapeGroup = (props: any) => {
  let { venueId = defaultVenueId, useFieldAppearanceStore } = props;
  let pathShapes = useMemo(() => toVenuePathShapes({ venueId }), [venueId]);
  let grassMaterial = useFieldAppearanceStore(($: any) => $.grassMaterial);
  let {
    type: grassMaterialType,
    color: grassColor,
    wireframe: grassWireframe,
    metalness: grassMetalness,
    roughness: grassRoughness,
  } = grassMaterial;
  let sandMaterial = useFieldAppearanceStore(($: any) => $.sandMaterial);
  let {
    type: sandMaterialType,
    color: sandColor,
    wireframe: sandWireframe,
    metalness: sandMetalness,
    roughness: sandRoughness,
  } = sandMaterial;
  let meshes = useMemo(() => {
    return pathShapes.map((pathShape, i) => {
      let materialType =
        pathShape.type === "grass" ? grassMaterialType : sandMaterialType;
      let isBasicMaterial = materialType === meshBasicMaterialTypeName;
      let color = pathShape.type === "grass" ? grassColor : sandColor;
      let metalness =
        pathShape.type === "grass" ? grassMetalness : sandMetalness;
      let roughness =
        pathShape.type === "grass" ? grassRoughness : sandRoughness;
      let wireframe =
        pathShape.type === "grass" ? grassWireframe : sandWireframe;
      // default scale to identity when none given
      let rotate = pathShape.rotate || 0;
      let scale = pathShape.scale
        ? new Vector3(
            pathShape.scale[0],
            pathShape.scale[1],
            pathShape.scale[2]
          )
        : new Vector3(1, 1, 1);
      let translate = new Vector3(
        pathShape.translate[0],
        pathShape.translate[1],
        pathShape.translate[2]
      );

      return (
        <mesh
          receiveShadow
          key={i}
          position={translate}
          scale={scale}
          rotation={[0, 0, rotate]}
        >
          <extrudeBufferGeometry
            attach="geometry"
            args={[pathShape.shape, extrudeSettings]}
          />
          {isBasicMaterial ? (
            <meshBasicMaterial
              attach={"material"}
              color={color}
              depthTest={true}
              wireframe={wireframe}
            />
          ) : (
            <meshStandardMaterial
              attach={"material"}
              color={color}
              metalness={metalness}
              roughness={roughness}
              depthTest={true}
              wireframe={wireframe}
            />
          )}
        </mesh>
      );
    });
  }, [
    pathShapes,
    grassMaterialType,
    grassColor,
    grassMetalness,
    grassRoughness,
    grassWireframe,
    sandMaterialType,
    sandColor,
    sandMetalness,
    sandRoughness,
    sandWireframe,
  ]);

  return (
    <group
      name="venue-field"
      scale={[2.4, -2.4, 2.4]}
      position={[-285, 425.5, -0.5]}
    >
      {meshes}
    </group>
  );
};
