import React, { useCallback, useRef, useMemo, useEffect } from "react";
import { extend, useFrame, useThree } from "@react-three/fiber";
import _find from "lodash/find";
import { Vector2, Vector3 } from "three";
// keeping this commented out import as a reminder to check the OC copy distributed with three
// import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls' // pan up/down => Statcast y
import { usePrevious } from "../../hooks";
import OrbitControls from "../../lib/OrbitControls";
import { FigureChaseCamera } from "../FigureChaseCamera";
import { BallChaseCamera } from "../BallChaseCamera";
import { IFigureSideCamParams, FigureSideCamera } from "../FigureSideCam";
import { GameCamera } from "../GameCam";
import { alphaFilter, timeToFrame } from "../../util/tracking-util";
import { FlyCam } from "../FlyCam";
import { POVCamera } from "../POVCamera";

extend({ OrbitControls });

interface Props {
  rootStore: any;
  playData?: any;
  playTracking?: any;
  time?: number;
  positionId?: number;
}

const targetV = new Vector3();
const tempV = new Vector3();
const endCamPos = new Vector3();
const rAlpha = 0.05;
const rEpsilon = 0.05;
const assumeStadiumHeight = 2; // ft
const defaultCamHeight = 8; // ft
const defaultCamDist = 28; // ft

export const CameraControls = (props: Props) => {
  let { rootStore, playData, playTracking, time = 0 } = props;
  let { useCameraStore, useFlyCamStore, usePOVCameraStore } = rootStore;
  let ocRef = useRef();
  let { camera, gl } = useThree();

  let {
    setCameraMatrix,
    cameraType,
    orbitControlsParams,
    setOrbitControlsParams,
    figureChaseCamParams,
    figureSideCamParams,
    gameCamParams,
    setGameCamParams,
    ballChaseCamParams,
  } = useCameraStore();

  let {
    autoRotate,
    autoRotateSpeed,
    relocDest,
    relocating,
    relocStadium,
    target,
    positionId
  } = orbitControlsParams;

  let isCameraTypeOrbitControls = cameraType === "orbit-controls";
  let isCameraTypeFigureChaseCam = cameraType === "figure-chase-cam";
  let isCameraTypeFigureSideCam = cameraType === "figure-side-cam";
  let isCameraTypeFigureSpinCam = cameraType === "figure-spin-cam";
  let isCameraTypeBallChaseCam = cameraType === "ball-chase-cam";
  let isCameraTypeFlyCam = cameraType === "fly-cam";
  let isCameraTypePOVCam = cameraType === "pov-cam";
  const prevPositionId = usePrevious(positionId);
  const positionIdChanged: boolean = positionId !== prevPositionId;

  target = useMemo(() => {
    /*
     * If a positionId is selected as the orbit camera's target, we'll need to update the
     * value of 'target' to the coordinates of the position player selected.
     */
    if (positionId) {
      let frame = timeToFrame({ time, playTracking });
      let figure = _find(frame.positions, { positionId });
      if (!figure) {
        return undefined;
      } else {
        let { location } = figure;
        if (
          Number.isNaN(location.x) ||
          Number.isNaN(location.y) ||
          Number.isNaN(location.z)
        ) {
          console.error("figure position isn't trackable");
          return undefined;
        }
        return [location.x, 3, -location.y];
      }
    } else {
      return target;
    }
  }, [positionId, target, time, playTracking]);

  useEffect(() => {
    if (!relocating && target && (!positionId || positionIdChanged)) {
      // Place the camera there and arbitrarily set the height to 8 feet.
      cameraReposition(target, camera.position);
    }
  }, [positionId, positionIdChanged, relocating, target, camera.position]);

  let relocatingPrev = usePrevious(relocating);

  useEffect(() => {
    // relocation animation
    if (relocating)
    {
      if (relocating !== relocatingPrev)
      {
        if (relocStadium && (relocDest.y > assumeStadiumHeight)) {
          tempV.copy(relocDest).normalize().multiplyScalar(
            defaultCamDist * 1.1);
          relocDest.sub(tempV);
        }
      }
      cameraReposition(target, endCamPos);

      targetV.set(
        alphaFilter(target[0], relocDest.x, rAlpha),
        alphaFilter(target[1], relocDest.y, rAlpha),
        alphaFilter(target[2], relocDest.z, rAlpha));
      camera.position.set(
        alphaFilter(camera.position.x, endCamPos.x, rAlpha),
        alphaFilter(camera.position.y, endCamPos.y, rAlpha),
        alphaFilter(camera.position.z, endCamPos.z, rAlpha));

      let incomplete = (targetV.distanceTo(relocDest) < rEpsilon)
        ? false
        : true;

      setOrbitControlsParams(
        {
          ...orbitControlsParams,
          target: [targetV.x, targetV.y, targetV.z],
          relocating: incomplete
        }
      );
    }
  },
    [
      camera,
      relocating,
      relocatingPrev,
      relocStadium,
      relocDest,
      setOrbitControlsParams,
      orbitControlsParams,
      target
    ]);

  let handleControlsEndEvent = useCallback(
    ({ target: controls }: { target: any }) => {
      let { object: cam } = controls;
      let matrixArray = cam.matrix.toArray().map((n: number) => +n.toFixed(2));
      setCameraMatrix(matrixArray);
    },
    [setCameraMatrix]
  );

  let oc = isCameraTypeOrbitControls && (
    // @ts-ignore
    <orbitControls
      ref={ocRef}
      args={[camera, gl.domElement]}
      // for temporarily toggling to for testing:
      // target={[0, 5, -30]}  // gl coords
      target={target}
      maxDistance={500}
      autoRotate={autoRotate}
      enableKeys={false}
      enableDamping={true}
      dampingFactor={0.9}
      maxPolarAngle={0.5 * Math.PI}
      zoomSpeed={1.2}
      autoRotateSpeed={autoRotateSpeed}
      onUpdate={(self: OrbitControls) => {
        //@ts-ignore
        self.removeEventListener("end", handleControlsEndEvent);
        //@ts-ignore
        self.addEventListener("end", handleControlsEndEvent);
      }}
    />
  );
  let ffc = isCameraTypeFigureChaseCam && (
    <FigureChaseCamera
      camera={camera}
      playTracking={playTracking}
      time={time}
      figureChaseCamParams={figureChaseCamParams}
    />
  );

  let fsc = isCameraTypeFigureSideCam && (
    <FigureSideCamera
      camera={camera}
      playData={playData}
      playTracking={playTracking}
      time={time}
      figureSideCamParams={figureSideCamParams as IFigureSideCamParams}
    />
  );

  let fspin = isCameraTypeFigureSpinCam && (
    <GameCamera
      camera={camera}
      renderer={gl}
      setGameCamParams={setGameCamParams}
      playTracking={playTracking}
      time={time}
      gameCamParams={gameCamParams}
    />
  );

  let povc = isCameraTypePOVCam && (
    <POVCamera
      camera={camera}
      playData={playData}
      playTracking={playTracking}
      time={time}
      usePOVCameraStore={usePOVCameraStore}
    />
  );

  useFrame(() => {
    if (isCameraTypeOrbitControls) {
      // @ts-ignore
      ocRef?.current?.update();
    }
  });

  let bcc = isCameraTypeBallChaseCam && (
    <BallChaseCamera
      camera={camera}
      time={time}
      playData={playData}
      ballChaseCamParams={ballChaseCamParams}
    />
  );

  let fc = isCameraTypeFlyCam && (
    <FlyCam
      camera={camera}
      time={time}
      playData={playData}
      useFlyCamStore={useFlyCamStore}
    />
  );

  return <>{oc || ffc || fsc || fspin || bcc || fc || povc}</>;
};

function cameraReposition(
  target: number[],
  pos: Vector3)
{
  const [x, , z] = target;
  // Moving the plate *slightly* so that the Plate target still works in the correct direction.
  const plate2D = new Vector2(0, -0.1);
  const target2D = new Vector2(x, z);
  // Calculate delta x and z to figure out how to place camera 28 feet away from the target while pointing at the plate.
  const { x: dx, y: dz } = new Vector2()
    .subVectors(target2D, plate2D)
    .normalize()
    .setLength(defaultCamDist);

    pos.set(x + dx, defaultCamHeight, z + dz);
}
