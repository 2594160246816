import {BatGroup} from './BatTrackGroup'
import React from 'react'

const defaultBatCylinderRadius = .05

interface Props {
    batSeries: any[],
    time: number,
    radius?: number,
}

export const BatAtTime = ({batSeries, time, radius = defaultBatCylinderRadius}: Props) => {
    let bat = batSeries.find(b => b.time === time)

    return bat
        ? <BatGroup bat={bat} radius={radius}/>
        : null
}
