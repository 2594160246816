import React from "react";
import * as THREE from "three";
import { Color } from "three";
// import {randomColor} from '../util/color'

const defaultMaxCount = 20;
const defaultRadius = 0.05;
const defaultEmissive: Color = new Color("#000");

// scratch items
const _obj = new THREE.Object3D();
let _axis = new THREE.Vector3();
let _v1 = new THREE.Vector3();
let _dir = new THREE.Vector3();

let updateMesh = (mesh: any, pointPairs: any) => {
  let count = pointPairs.length;

  mesh.count = count;

  for (let i = 0; i < count; ++i) {
    let pointPair = pointPairs[i];

    _v1.set(pointPair[0][0], pointPair[0][1], pointPair[0][2]);
    _dir.set(pointPair[1][0], pointPair[1][1], pointPair[1][2]);
    _dir.sub(_v1);

    let length = _dir.length();

    _obj.scale.set(1, length, 1);

    _dir.normalize();

    if (_dir.y > 0.99999) {
      _obj.quaternion.set(0, 0, 0, 1);
    } else if (_dir.y < -0.99999) {
      _obj.quaternion.set(1, 0, 0, 0);
    } else {
      let radians = Math.acos(_dir.y);

      _axis.set(_dir.z, 0, -_dir.x).normalize();
      _obj.quaternion.setFromAxisAngle(_axis, radians);
    }

    _obj.position.set(pointPair[0][0], pointPair[0][1], pointPair[0][2]);

    _obj.translateY(length / 2);

    _obj.updateMatrix();
    mesh.setMatrixAt(i, _obj.matrix);
  }
  mesh.instanceMatrix.needsUpdate = true;
  mesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
};

interface Props {
  name: string;
  pointPairs: any[];
  radius?: number;
  color?: Color;
  material: any;
  maxCount?: number;
  castShadow?: boolean;
}

export const InstancedMeshCylinderSpans = (props: Props) => {
  let {
    name = "",
    pointPairs = [],
    radius = defaultRadius,
    color = defaultEmissive,
    material,
    maxCount = defaultMaxCount,
    castShadow = true,
  } = props;
  if (pointPairs.length === 0) {
    return null;
  }

  material = material || (
    <meshStandardMaterial
      attach="material"
      emissive={color}
      roughness={0}
      metalness={1}
    />
  );

  return (
    <instancedMesh
      castShadow={castShadow}
      name={name}
      //@ts-ignore
      args={[null, null, maxCount]}
      onUpdate={(mesh) => updateMesh(mesh, pointPairs)}
    >
      <cylinderBufferGeometry
        attach="geometry"
        args={[radius, radius, 1, 16]}
      />
      {/*<meshStandardMaterial attach='material' emissive={color} roughness={0} metalness={1}/>*/}
      {material}
    </instancedMesh>
  );
};
